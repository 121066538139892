/* eslint-disable indent */
import React from 'react';
import { TextInput, Tickbox } from 'components/FormControl';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
export default function GeneralEvaluateTable({
  control,
  setValue,
  // eslint-disable-next-line no-unused-vars
  getValues,
  watch,
  errors,
  disabled,
}) {
  const { t } = useTranslation();
  const handleCheck = (name, value) => {
    const token = name.split('.');
    const [key] = token;
    let otherKeys = [];
    switch (key) {
      case 'generalEvaluate':
        otherKeys = ['isNotQualified', 'otherOpinionTickbox'];
        break;
      case 'isNotQualified':
        otherKeys = [
          'generalEvaluate',
          'nationalOrientation',
          'cooperateOrientation',
          'vnuOrientation',
        ];
        break;
      default:
        otherKeys = [];
        break;
    }
    if (otherKeys?.length && value === true) {
      otherKeys.forEach((otherKey) => {
        setValue(`${otherKey}`, false);
      });
    }
  };
  const criterias = [
    'criteria-1',
    'criteria-2',
    'criteria-3',
    'criteria-4',
    'criteria-5',
    'criteria-6',
    'criteria-7',
    'criteria-8',
    'criteria-9',
    'criteria-10',
  ];
  return (
    <div className="m01b-b form-table">
      <h4>{t('council.m01b.b.label')}</h4>
      <table>
        <thead>
          <tr>
            <th>{t('council.m01b.b.table.header')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <div className="flex my-2">
                  <Tickbox
                    icon="pi pi-check"
                    name="generalEvaluate"
                    control={control}
                    errors={errors}
                    onCustomCheck={handleCheck}
                    disabled={disabled}
                  />
                  <span className="ml-1">
                    {t('council.m01b.b.table.qualifiedPre')}
                    <i>{t('council.m01b.b.table.qualifiedExplain')}</i>
                    {t('council.m01b.b.table.qualifiedPost')}
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ padding: 0 }}>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ width: '40%' }}>
                      {t('council.m01b-2024.b.table.subTable.headerScaleTopic')}
                    </th>
                    <th style={{ width: '60%' }}>
                      {t('council.m01b-2024.b.table.subTable.headerProgramType')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="m-2">
                      <div style={{ whiteSpace: 'pre-wrap' }}>
                        {t('council.m01b-2024.b.table.subTable.criteriaScaleTopic')}
                      </div>
                      <div>{t('council.m01b-2024.b.table.subTable.expenditureExpect')}</div>
                    </td>
                    <td className="m-2" style={{ padding: 0 }}>
                      {criterias.map((criteria) => (
                        <tr>
                          <td className="sub-col">
                            {t(`council.m01b-2024.b.table.subTable.${criteria}`)}
                          </td>
                        </tr>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div className="flex my-2">
                <Tickbox
                  icon="pi pi-check"
                  name="isNotQualified"
                  control={control}
                  errors={errors}
                  onCustomCheck={handleCheck}
                  disabled={disabled}
                />
                <span className="ml-1">{t('council.m01b.b.table.notQualified')}</span>
              </div>
              <div>
                <div className="flex my-2">
                  <Tickbox
                    icon="pi pi-check"
                    name="otherOpinionTickbox"
                    control={control}
                    errors={errors}
                    onCustomCheck={handleCheck}
                    disabled={disabled}
                  />
                  <span className="ml-1">
                    {t('council.m01b.b.table.otherOpinion')}
                    <i>{t('council.m01b.b.table.notQualifiedExplain')}</i>
                  </span>
                </div>
                <TextInput
                  name="otherOpinion"
                  control={control}
                  errors={errors}
                  disabled={disabled || !watch('otherOpinionTickbox')}
                  readOnly={disabled}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

GeneralEvaluateTable.propTypes = {
  control: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func,
  errors: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

GeneralEvaluateTable.defaultProps = {
  disabled: false,
  watch: null,
};
