import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function RecommendedExpertsFields({ recommendedExperts }) {
  // #region Data
  const { t } = useTranslation();
  const data = [];
  recommendedExperts?.forEach((item, index) => {
    data.push({ ...item, id: index + 1 });
  });
  // #endregion Data

  return (
    <>
      <h4>{t('projectProposal.general.recommendedExpert.label')}</h4>
      <div className="grid">
        <div className="col-12">
          <DataTable
            value={data}
            className="p-datatable-gridlines"
            showGridlines
            dataKey="email"
            responsiveLayout="scroll"
          >
            <Column
              field="id"
              header={t('formLayout.numericalOrder')}
              style={{ textAlign: 'center' }}
            />
            <Column
              field="fullname"
              header={t('projectProposal.general.recommendedExpert.fullname')}
              style={{ minWidth: '12rem' }}
            />
            <Column
              field="gender"
              header={t('projectProposal.general.a9.fields.gender')}
              style={{ minWidth: '8rem' }}
            />
            <Column
              field="researchLine"
              header={t('projectProposal.general.recommendedExpert.researchLine')}
              style={{ minWidth: '12rem' }}
            />
            <Column
              field="workplace"
              header={t('projectProposal.general.recommendedExpert.workplace')}
              style={{ minWidth: '12rem' }}
            />
            <Column
              field="phone"
              header={t('projectProposal.general.recommendedExpert.phone')}
              dataType="numeric"
              style={{ minWidth: '10rem' }}
            />
          </DataTable>
        </div>
      </div>
    </>
  );
}

RecommendedExpertsFields.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  recommendedExperts: PropTypes.array.isRequired,
};
