import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { downloadDocxFile, downloadPdfFile, downloadZip } from 'apis/projectProposals.api';

import Loading from 'components/Loading';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';

import { TOAST } from 'constant';
import { toast } from 'layout';
import {
  getAcadamicLevelShortHand,
  modifyProjectProposalExpenditureReviewData,
  modifyProjectProposalGeneralReviewData,
  modifyProjectProposalResearchResultReviewData,
  toNonAccentVietnamese,
} from 'utils/func';

import Documents from './Documents';
import GeneralInfo from './GeneralInfo';
import ResearchResult from './ResearchResult';
import Signature from './Signature';

export default function ReviewForm({
  researchFields,
  researchTypes,
  organizations,
  projectRoles,
  researchKpis,
  expenditureTypes,
  watch,
  getValues,
  handleDownloadFile,
  handleDownloadScientificProfileFile,
  projectProposalResearchKpis,
}) {
  // #region Data
  const { t } = useTranslation();
  const { id } = useParams();
  const projectProposalDetailRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenGuideline, setIsOpenGuideLine] = useState(false);

  const generalFormData = watch('general');
  const researchResultFormData = watch('researchResult');
  const expenditureFormData = watch('expenditure');
  const researchDescriptionFormData = watch('researchDescription');

  const submittedAt = watch('submittedAt');

  const data = useMemo(() => {
    const generalData = modifyProjectProposalGeneralReviewData(
      generalFormData,
      researchFields,
      researchTypes,
      organizations,
      projectRoles
    );
    const researchResultData = modifyProjectProposalResearchResultReviewData(
      researchResultFormData,
      researchKpis,
      projectProposalResearchKpis,
      submittedAt
    );

    const expenditureData = modifyProjectProposalExpenditureReviewData(
      expenditureFormData,
      expenditureTypes
    );

    const expenditureDetailFileUrl = getValues('expenditure.uploadedFileUrl');
    const researchDescriptionFileUrl = researchDescriptionFormData?.uploadedFileUrl;
    const otherProofsAndAppendicesFiles =
      researchDescriptionFormData?.otherProofsAndAppendicesFiles;

    return {
      general: generalData,
      researchResult: researchResultData,
      expenditure: expenditureData,
      document: {
        expenditureDetailFileUrl,
        researchDescriptionFileUrl,
        otherProofsAndAppendicesFiles,
      },
    };
  });

  const {
    refetch: handleDownloadZip,
    isLoading: isDownloadZipLoading,
    isFetching: isDownloadZipFetching,
  } = useQuery(
    ['downloadProjectProposalZip', id ?? getValues('id')],
    () => downloadZip(id ?? getValues('id')),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const owner = data.general?.owner;
          const academicLevelShorthand = getAcadamicLevelShortHand(
            owner?.academicRank,
            owner?.academicDegree,
            t,
            true
          );
          const filename = `De tai DHQG - ${academicLevelShorthand}${toNonAccentVietnamese(
            owner?.fullname
          )}.zip`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.downloadZipErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxFile,
    isLoading: isDownloadDocxFileLoading,
    isFetching: isDownloadDocxFileFetching,
  } = useQuery(['downloadProjectProposalDocxFile'], () => downloadDocxFile(id ?? getValues('id')), {
    enabled: false,
    onSuccess: (res) => {
      if (res?.data) {
        const file = new Blob([res.data]);
        const filename = 'Don đang ky de tai.docx';
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = filename;

        link.click();
      }
    },
    onError: () => {
      toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
    },
  });

  const {
    refetch: handleDownloadPdfFile,
    isLoading: isDownloadPdfFileLoading,
    isFetching: isDownloadPdfFileFetching,
  } = useQuery(['downloadProjectProposalPdfFile'], () => downloadPdfFile(id ?? getValues('id')), {
    enabled: false,
    onSuccess: (res) => {
      if (res?.data) {
        const file = new Blob([res.data]);
        const filename = 'Don đang ky de tai.pdf';
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = filename;

        link.click();
      }
    },
    onError: () => {
      toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
    },
  });
  // #endregion Data

  // #region Event
  const reactToPrintContent = useCallback(
    () => projectProposalDetailRef.current,
    [projectProposalDetailRef.current]
  );

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `project-proposal_${new Date().toJSON().slice(0, 10)}`,
    bodyClass: 'project-proposal-form',
    onBeforeGetContent: () =>
      new Promise((resolve) => {
        onBeforeGetContentResolve.current = resolve;
        setIsLoading(true);
      }),
    onAfterPrint: () => {
      onBeforeGetContentResolve.current = null;
      setIsLoading(false);
    },
    removeAfterPrint: true,
  });

  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);

  const getPageMargins = () => '@page { margin: 0.5in 0.5in 0.2in 0.2in  !important; }';
  // #endregion Event

  return (
    <>
      <div className="flex justify-content-end gap-2">
        <Button
          type="button"
          label={t('projectProposal.review.downloadZip')}
          icon={`pi ${
            isDownloadZipLoading || isDownloadZipFetching ? 'pi-spin pi-spinner' : 'pi-download'
          }`}
          className="block w-auto h-3rem"
          severity="warning"
          onClick={handleDownloadZip || isDownloadZipFetching}
        />
        <Button
          type="button"
          label={t('projectProposal.review.exportDocx')}
          icon={`pi ${
            isDownloadDocxFileLoading || isDownloadDocxFileFetching
              ? 'pi-spin pi-spinner'
              : 'pi-file-word'
          }`}
          className="hidden w-auto h-3rem" // hide exportDocx button
          severity="info"
          onClick={handleDownloadDocxFile}
          disabled={isDownloadDocxFileLoading || isDownloadDocxFileFetching}
        />
        <Button
          type="button"
          label={t('projectProposal.review.exportPdf')}
          icon={`pi ${
            isDownloadPdfFileLoading || isDownloadPdfFileFetching
              ? 'pi-spin pi-spinner'
              : 'pi-file-pdf'
          }`}
          className="block w-auto h-3rem border-none"
          style={{ backgroundColor: 'var(--red-400)' }}
          onClick={handleDownloadPdfFile}
          disabled={isDownloadPdfFileLoading || isDownloadPdfFileFetching}
        />
        {/* Hidden printPdf button */ }
        {false && (
          <div className="flex flex-column align-items-end">
            <Button
              type="button"
              label={t('projectProposal.review.printPdf')}
              icon="pi pi-print"
              className="block w-auto"
              severity="info"
              onClick={handlePrint}
            />
            <span
              className="cursor-pointer text-sm text-blue-400 hover:text-blue-500 mt-1"
              onClick={() => setIsOpenGuideLine(true)}
            >
              {t('projectProposal.review.printGuideline')}
            </span>
          </div>
        )}
      </div>

      <Dialog
        visible={isOpenGuideline}
        style={{ maxWidth: '750px' }}
        onHide={() => setIsOpenGuideLine(false)}
      >
        <div className="relative">
          <video
            id="printGuideline"
            width="700"
            height="380"
            preload="auto"
            src="/layout/videos/print-guideline.m4v"
            type="video/mp4"
            autoPlay
            loop
          >
            <track kind="captions" />
          </video>
        </div>
      </Dialog>

      {isLoading && <Loading />}

      <div
        id="projectProposalDetail"
        ref={projectProposalDetailRef}
        className="m-0 bg-white p-5 p-fluid detail-info-project-proposal"
      >
        <style>{getPageMargins()}</style>
        <div className="mb-5 flex justify-content-between align-items-center">
          <img src="/logo.png" alt="VNU-HCM logo" height="75px" className="ml-3" />
          <div>
            <table style={{ borderCollapse: 'collapse' }} className="text-black-alpha-90">
              <tbody>
                <tr>
                  <td colSpan="2" className="p-1">
                    {t('projectProposal.viewDetail.form')} R01 -{' '}
                    {t('projectProposal.viewDetail.update')} 2022
                  </td>
                </tr>
                <tr>
                  <td className="border-dotted border-1 p-1 w-10rem">
                    {t('projectProposal.viewDetail.dateOfReceivingFile')}
                  </td>
                  <td className="border-dotted border-1 p-1 w-8rem"> </td>
                </tr>
                <tr>
                  <td className="border-dotted border-1 p-1 w-10rem">
                    {t('projectProposal.viewDetail.projectId')}
                  </td>
                  <td className="border-dotted border-1 p-1 w-8rem"> </td>
                </tr>
                <tr>
                  <td colSpan="2" className="border-dotted border-1 p-1 text-center">
                    <span className="font-italic">
                      ({t('projectProposal.viewDetail.recordedBy')})
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h3 className="mb-5 line-height-3 text-4xl text-center font-bold">
          {t('projectProposal.viewDetail.application')}
          <br />
          <span className="font-normal">{t('projectProposal.viewDetail.sciTechProject')}</span>
        </h3>

        <div className="ml-4">
          <GeneralInfo
            generalInfoData={data.general}
            expenditureData={data.expenditure}
            handleDownloadFile={handleDownloadFile}
            organizations={organizations}
            handleDownloadScientificProfileFile={handleDownloadScientificProfileFile}
          />
          <ResearchResult data={data.researchResult} />
          <h4>{t('projectProposal.researchDescription.viewLabel')}</h4>
          <h4>
            B1 - B5.{' '}
            {data.document.researchDescriptionFileUrl && (
              <span
                className="p-link text-primary underline font-normal"
                onClick={() => handleDownloadFile(data.document.researchDescriptionFileUrl)}
              >
                {t('projectProposal.researchDescription.attachment')}
              </span>
            )}
          </h4>
          <Documents data={data.document} handleDownloadFile={handleDownloadFile} />
        </div>

        <Signature />
      </div>
    </>
  );
}

ReviewForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  researchFields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  researchTypes: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organizations: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectRoles: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  researchKpis: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  expenditureTypes: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectProposalResearchKpis: PropTypes.array,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
  handleDownloadScientificProfileFile: PropTypes.func.isRequired,
};

ReviewForm.defaultProps = {
  projectProposalResearchKpis: [],
};
