import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import { useState } from 'react';
import { Fieldset } from 'primereact/fieldset';

import { Slider } from 'primereact/slider';
import { useMutation } from 'react-query';
import Loading from 'components/Loading';
import { uploadImage } from 'apis/scientificProfiles.api';

export default function UploadAvatarDialog({
  image,
  setImagePreview,
  visible,
  setVisible,
  showSuccess,
  showError,
}) {
  // #region Data
  const { t } = useTranslation();
  const [editor, setEditor] = useState(null);
  const [zoom, setZoom] = useState(150);
  const [rotate, setRotate] = useState(0);
  // #endregion Data

  // #region Event

  const handleClose = () => {
    // Reset state when dialog is closed
    setEditor(null);
    setVisible(false);
  };

  const { mutate, isLoading } = useMutation(uploadImage);

  async function url2Blob(url) {
    const b = await (await fetch(url)).blob();
    return b;
  }
  const handleSave = async () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      const imageUrl = canvas.toDataURL();
      // Convert data URL to blob
      const imageBlob = await url2Blob(imageUrl);

      if (imageBlob) {
        const formData = new FormData();
        formData.append('avatar', imageBlob);

        mutate(formData, {
          onSuccess: () => {
            showSuccess(t('scientificProfile.updateSuccess'));
            handleClose();
            setImagePreview(imageUrl);
          },
          onError: (error) => {
            showError(error.response.data.message);
            handleClose();
          },
        });
      }
    }
  };

  const handleZoomChange = (e) => {
    setZoom(e.value);
  };

  // handle rotate left
  const handleRotateLeft = () => {
    setRotate(rotate - 90);
  };

  // handle rotate right
  const handleRotateRight = () => {
    setRotate(rotate + 90);
  };
  // #endregion Event

  return (
    <Dialog
      header="Edit Photo"
      visible={visible}
      style={{ maxWidth: '700px' }}
      onHide={() => {
        setVisible(false);
      }}
    >
      <div className="flex-col">
        <Fieldset>
          <AvatarEditor
            ref={(editorr) => setEditor(editorr)}
            image={image ? URL.createObjectURL(new Blob([image])) : null}
            width={250}
            height={250}
            border={50}
            color={[255, 255, 255, 0.75]} // RGBA
            scale={zoom / 100} // Adjust scale based on zoom value
            borderRadius={125}
            rotate={rotate}
          />
        </Fieldset>

        <div className="mt-4 flex justify-center items-center">
          <div style={{ width: '100%' }}>
            <label htmlFor="zoomSlider" className="text-base">
              Zoom
            </label>
            <Slider
              min={100}
              max={200}
              className="mt-2"
              id="zoomSlider"
              value={zoom}
              onChange={handleZoomChange}
              style={{ width: '70%' }}
            />
          </div>
          <div className="flex">
            <Button icon="pi pi-replay" onClick={handleRotateLeft} />
            <Button className="ml-2 mr-2" icon="pi pi-refresh" onClick={handleRotateRight} />
          </div>
        </div>

        <Button
          label={t('formLayout.action.save')}
          className="p-button-success mt-4"
          style={{ width: '100%' }}
          onClick={handleSave}
        />
      </div>
      {isLoading && <Loading />}
    </Dialog>
  );
}

UploadAvatarDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.string,
  setImagePreview: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};

UploadAvatarDialog.defaultProps = {
  image: '',
};
