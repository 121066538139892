/* eslint-disabled no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import './style.scss';

import PropTypes from 'prop-types';
import Form from './Form';
import GeneralInfo from './GeneralInfo';

function FinancialAdjustmentForm({
  readOnly,
  watch,
  project,
  control,
  errors,
  financialAdjustmentId,
}) {
  // #region Data

  return (
    <div className="bg-white p-5 financial-adjustment">
      <GeneralInfo data={project} financialAdjustmentId={financialAdjustmentId} />
      <form>
        <Form watch={watch} readOnly={readOnly} control={control} errors={errors} />
      </form>
    </div>
  );
}

FinancialAdjustmentForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  watch: PropTypes.func.isRequired,
  project: PropTypes.shape({}).isRequired,
  financialAdjustmentId: PropTypes.string,
};

FinancialAdjustmentForm.defaultProps = {
  readOnly: false,
  financialAdjustmentId: '',
};

export default FinancialAdjustmentForm;
