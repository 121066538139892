/* eslint-disable react/no-danger */
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { defaultValues } from 'features/ProjectProposal/defaultValues';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { getResearchKpiGroup, formatFileNameProduct, getProductDetail } from 'utils/func';

import { PRODUCT_TYPE } from 'constant/summary-report.constant';
import ProductDialog from '../ProductDialog';

export default function IntellectualPropertyTable({
  researchKpis,
  researchKpiGroups,
  disabled,
  control,
  // eslint-disable-next-line no-unused-vars
  errors,
  projectCode,
  handleDownloadFile,
}) {
  // #region Data
  const { t } = useTranslation();
  const intellectualPropertyRef = useRef();

  const { fields, insert, remove, update } = useFieldArray({
    control,
    name: 'researchResult.intellectualProperty',
    keyName: 'customId',
  });
  const researchKpiGroupOptions = getResearchKpiGroup(researchKpis);

  // #endregion Data
  return (
    <>
      <h4>{t('r05.b.b2.no2')}</h4>

      <div className="form-table p-fluid">
        <table className="mb-2">
          <thead>
            <tr>
              <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                {t('formLayout.numericalOrder')}
              </th>
              <th className="text-center" style={{ minWidth: 200, width: 200 }}>
                {t('r05.b.b2.table.productName')}
              </th>
              <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                {t('r05.b.b2.table.kpiType')}
              </th>
              <th className="text-center" style={{ minWidth: 200, width: 200 }}>
                {t('r05.b.b2.table.kpiName')}
              </th>
              <th className="text-center" style={{ minWidth: 250, width: 250 }}>
                {t('r05.b.b2.table.productDetail')}
              </th>
              <th className="text-center" style={{ minWidth: 200, width: 200 }}>
                {t('r05.b.b2.table.proof')}
              </th>
              {!disabled && (
                <th rowSpan={3} className="float text-center" style={{ minWidth: 60, width: 60 }}>
                  {t('projectProposal.action')}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {fields?.map((item, index) => (
              <tr key={item.id}>
                <td className="text-center">{index + 1}</td>
                <td>{item?.productDetail?.title}</td>
                <td>
                  {researchKpiGroupOptions?.find((i) => i.id === item?.researchKpiGroupId)?.name}
                </td>
                <td>{researchKpis?.find((i) => i.id === item?.researchKpiId)?.name}</td>
                <td
                  dangerouslySetInnerHTML={{ __html: getProductDetail(t, item?.productDetail) }}
                />
                <td>
                  {!item?.file?.name ? (
                    item?.fileUrl && (
                      <span
                        className="p-button p-button-text p-button-sm mt-1 p-0"
                        onClick={
                          () =>
                            handleDownloadFile(
                              item?.fileUrl,
                              formatFileNameProduct(
                                item?.fileUrl || '',
                                projectCode,
                                PRODUCT_TYPE.INTELLECTUAL_PROPERTY.short,
                                index + 1
                              )
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      >
                        <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                        <span className="text-blue-500 underline font-italic ml-1">
                          {formatFileNameProduct(
                            item?.fileUrl || '',
                            projectCode,
                            PRODUCT_TYPE.INTELLECTUAL_PROPERTY.short,
                            index + 1
                          )}
                        </span>
                      </span>
                    )
                  ) : (
                    <span>
                      {formatFileNameProduct(
                        item?.file?.name || '',
                        projectCode,
                        PRODUCT_TYPE.INTELLECTUAL_PROPERTY.short,
                        index + 1
                      )}
                    </span>
                  )}
                </td>
                {!disabled && (
                  <td className="float text-center p-0">
                    <div className="">
                      <i
                        onClick={() => {
                          intellectualPropertyRef.current.open({
                            projectCode,
                            index,
                            value: fields[index],
                            update,
                            type: 'UPDATE',
                          });
                        }}
                        className="edit-icon pi pi-pencil text-primary cursor-pointer mt-1"
                        data-pr-tooltip={t('formLayout.action.edit')}
                        data-pr-position="left"
                      />
                      <Tooltip target=".edit-icon" />
                    </div>

                    <i
                      onClick={() => remove(index)}
                      className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                      data-pr-tooltip={t('formLayout.action.remove')}
                      data-pr-position="left"
                    />
                    <Tooltip target=".remove-icon" />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!disabled && (
        <div className="flex justify-content-center mt-2">
          <i
            onClick={() => {
              intellectualPropertyRef.current.open({
                index: fields?.length ?? 0,
                value: defaultValues.hardProduct,
                projectCode,
                insert,
                type: 'INSERT',
              });
            }}
            className="add-hard-product pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
            data-pr-tooltip={t('formLayout.action.add')}
            data-pr-position="left"
          />
          <Tooltip target=".add-hard-product" />
        </div>
      )}

      <ProductDialog
        researchKpis={researchKpis}
        researchKpiGroups={researchKpiGroups}
        productTypeShort={PRODUCT_TYPE.INTELLECTUAL_PROPERTY.short}
        ref={intellectualPropertyRef}
        handleDownloadFile={handleDownloadFile}
      />
    </>
  );
}

IntellectualPropertyTable.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  researchKpis: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  researchKpiGroups: PropTypes.array.isRequired,
  projectCode: PropTypes.string.isRequired,
  handleDownloadFile: PropTypes.func,
};

IntellectualPropertyTable.defaultProps = {
  disabled: false,
  handleDownloadFile: () => null,
};
