import instance from 'utils/axios/instance.axios';
import { CONTACTS } from './_constant';

export const getAll = () => instance.get(CONTACTS.GET_ALL);

export const getByEmail = (data = {}) => instance.post(CONTACTS.GET_BY_EMAIL, data);

export const getMyContact = () => instance.get(CONTACTS.GET_MY_CONTACT);

export const getBySearchEmail = (searchQuery) =>
  instance.get(CONTACTS.GET_BY_SEARCH_EMAIL, {
    params: {
      email: searchQuery,
    },
  });

export const create = (data = {}) => instance.post(CONTACTS.CREATE, data);

export const update = (data = {}) => instance.patch(CONTACTS.UPDATE, data);
