import * as Yup from 'yup';

const researcherSchema = Yup.object({
  projectRoleId: Yup.string().required('formControl.validation.required'),
  email: Yup.string().email('formControl.validation.emailInvalid'),
  fullname: Yup.string().required('formControl.validation.required'),
  contact: Yup.lazy((value) => {
    if (typeof value === 'object') {
      return Yup.object({
        id: Yup.string().required('formControl.validation.required'),
        fullname: Yup.string('formControl.validation.required'),
      })
        .required('formControl.validation.required')
        .nullable();
    }
    if (typeof value === 'string') {
      return Yup.string().email('formControl.validation.emailInvalid');
    }
    return Yup.mixed();
  }),
  academicDegree: Yup.string().required('formControl.validation.required'),
  researchLines: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('formControl.validation.required'),
    })
  ),
  organizationId: Yup.string().required('formControl.validation.required'),
  organizationEnglishName: Yup.string().nullable(),
  specificOrganizationName: Yup.string()
    .nullable()
    .when('organizationEnglishName', {
      is: 'Other',
      then: Yup.string().required('formControl.validation.required'),
    }),
  assignedTask: Yup.string().required('formControl.validation.required'),
});

export default researcherSchema;
