import { useTranslation } from 'react-i18next';
import { TIMEZONE_VN } from 'constant';

import PropTypes from 'prop-types';

export default function OwnerFields({
  partner,
  representative
}) {
  // #region Data
  const { t } = useTranslation();
  // #endregion Data

  return (
    <div className="flex ml-5 flex-column gap-10">
      {/* for partner */}
      <div className="flex ml-5 flex-column gap-2">
        <div>
          <span className="font-semibold">{t('pubProjectProposal.general.a6.owner.partner.name')}: </span>
          <span>
            {partner?.name}
          </span>
        </div>
        <div>
          <span className="font-semibold">{t('pubProjectProposal.general.a6.owner.partner.taxCode')}: </span>
          <span>{partner?.taxCode}</span>
        </div>
        <div className="flex flex-wrap">
          <div className="w-30rem">
            <span className="font-semibold">{t('pubProjectProposal.general.a6.owner.partner.email')}: </span>
            <span>{partner?.email}</span>
          </div>
          <div className="w-30rem">
            <span className="font-semibold">{t('pubProjectProposal.general.a6.owner.partner.phone')}: </span>
            <span>{partner?.phone}</span>
          </div>
        </div>
        <div className="w-30rem">
          <span className="font-semibold">{t('pubProjectProposal.general.a6.owner.partner.address')}: </span>
          <span>{partner?.address}</span>
        </div>
      </div>
      {/* for representative */}
      <div className="mb-3 mt-2 flex gap-2 align-items-center">
        <h4 className="mr-2">{t('pubProjectProposal.general.a6.subLabel')}</h4>
      </div>
      <div className="flex ml-5 flex-column">
        <div className="mb-2">
          <span className="font-semibold">{t('pubProjectProposal.general.a6.owner.name')}: </span>
          <span>{representative?.fullname}</span>
        </div>
        <div className="mb-2">
          <span className="font-semibold">{t('pubProjectProposal.general.a6.owner.dob')}: </span>
          <span>
            {representative?.dob
              ? new Date(representative?.dob).toLocaleDateString('en-GB', { timeZone: TIMEZONE_VN })
              : ''}
          </span>
        </div>
        <div className="flex flex-wrap">
          <div className="w-30rem mb-2">
            <span className="font-semibold">
              {t('pubProjectProposal.general.a6.owner.citizenId')}:{' '}
            </span>
            <span>{representative?.citizenId}</span>
          </div>
          <div className="w-20rem mb-2">
            <span className="font-semibold">
              {t('pubProjectProposal.general.a6.owner.placeOfIssue')}:{' '}
            </span>
            <span>{representative?.placeOfIssue}</span>
          </div>
          <div className="mb-2">
            <span className="font-semibold">
              {t('pubProjectProposal.general.a6.owner.dateOfIssue')}:{' '}
            </span>
            <span>
              {representative?.dateOfIssue
                ? new Date(representative?.dateOfIssue).toLocaleDateString('en-GB', {
                  timeZone: TIMEZONE_VN,
                })
                : ''}
            </span>
          </div>
        </div>
        <div className="mb-2">
          <span className="font-semibold">
            {t('pubProjectProposal.general.a6.owner.taxIdentificationNumber')}:{' '}
          </span>
          <span>{representative?.taxIdentificationNumber}</span>
        </div>
        <div className="flex flex-wrap">
          <div className="w-30rem mb-2">
            <span className="font-semibold">
              {t('pubProjectProposal.general.a6.owner.bankAccount')}:{' '}
            </span>
            <span>{representative?.bankAccount}</span>
          </div>
          <div className="mb-2">
            <span className="font-semibold">{t('pubProjectProposal.general.a6.owner.bank')}: </span>
            <span>{representative?.bank}</span>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="mb-2">
            <span className="font-semibold">
              {t('pubProjectProposal.general.a6.owner.workplace')}:{' '}
            </span>
            <span>{representative?.workplace}</span>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="w-30rem mb-2">
            <span className="font-semibold">
              {t('pubProjectProposal.general.a6.owner.mobile')}:{' '}
            </span>
            <span>{representative?.mobile}</span>
          </div>
          <div className="mb-2">
            <span className="font-semibold">Email: </span>
            <span>{representative?.email}</span>
          </div>
        </div>
        <div className="mb-2">
          <div>
            <span className="font-semibold">
              {t('pubProjectProposal.general.a6.owner.summarizedExperience')}:{' '}
            </span>
          </div>
          <p>{representative?.experience}</p>
        </div>
      </div>
    </div>
  );
}

OwnerFields.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  partner: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  representative: PropTypes.array.isRequired
};
