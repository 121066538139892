// eslint-disable-no-unused-vars

import 'styles/layout/_catalogue.scss';
import { useTranslation } from 'react-i18next';

import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

function CataloguePage() {
  const { t } = useTranslation();
  return (
    <LayoutProvider>
      <Layout
        style={{
          backgroundColor: 'white',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
        }}
      >
        <section className="catalogue-table">
          <h3 className="m-0 text-center font-semibold">{t('catalogue.title')}</h3>
          <p className="text-center">
            <i>{t('catalogue.description')}</i>
          </p>

          <table>
            <tr>
              <th>STT</th>
              <th style={{ width: '8%' }}>{t('catalogue.symbol')}</th>
              <th>{t('catalogue.name')}</th>
            </tr>
            <tr>
              <td>1</td>
              <td>R01B</td>
              <td>
                {t('catalogue.R01B.title')}:
                <ul>
                  <li>
                    {t('projectProposal.researchDescription.nationalUniversityTypeABC')}:
                    <a href="/files/R01_Thuyet-minh-VNU-cap-nhat%202022-kh4-Muc-B.docx">
                      {t('formLayout.action.download')}
                      <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                  <li>
                    {t('projectProposal.researchDescription.nationalUniversityScienceProject')}:
                    <a href="/files/R01_DHQG_DeAn.docx">
                      {t('formLayout.action.download')}
                      <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                  <li>
                    {t('projectProposal.researchDescription.nationalUniversityResearchGroup')}:
                    <a href="/files/R01_DHQG_NhomNghienCuuManh.docx">
                      {t('formLayout.action.download')}
                      <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                  <li>
                    {t('projectProposal.researchDescription.nationalUniversityRegularDuty')}:
                    <a href="/files/R01_DHQG_NhiemVuThuongXuyen.docx">
                      {t('formLayout.action.download')}
                      <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                  <li>
                    {t('projectProposal.researchDescription.nationalUniversityMaintainProject')}:
                    <a href="/files/R01_DHQG_DuyTuBaoDuong.docx">
                      {t('formLayout.action.download')}
                      <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                  <li>
                    {t('projectProposal.researchDescription.nationalLevelA1')}:
                    <a href="/files/CapQuocGia-Mau-A1-De-tai.docx">
                      {t('formLayout.action.download')} <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                  <li>
                    {t('projectProposal.researchDescription.nationalLevelA2')}:
                    <a href="/files/CapQuocGia-Mau-A2-DA-SXTN.docx">
                      {t('formLayout.action.download')} <i className="pi pi-download ml-1" />
                    </a>{' '}
                  </li>
                  <li>
                    {t('projectProposal.researchDescription.nationalLevelA3')}:
                    <a href="/files/CapQuocGia-Mau-A3-De-an.docx">
                      {t('formLayout.action.download')} <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>R01C</td>
              <td>
                {t('catalogue.R01C.title')}:
                <ul>
                  <li>
                    {t('projectProposal.researchDescription.nationalUniversityTypeABC')}:
                    <a href="/files/R01C_Giai-trinh-khoan-chi.xlsx">
                      {t('formLayout.action.download')} <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                  <li>
                    {t('projectProposal.researchDescription.nationalUniversityScienceProject')}:
                    <a href="/files/R01C_Du_toan_De_An.xlsx">
                      {t('formLayout.action.download')}
                      <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                  <li>
                    {t('projectProposal.researchDescription.nationalUniversityResearchGroup')}:
                    <a href="/files/R01C_Du_toan_nhom_nghien_cuu_manh.xlsx">
                      {t('formLayout.action.download')}
                      <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                  <li>
                    {t('projectProposal.researchDescription.nationalUniversityRegularDuty')}:
                    <a href="/files/R01C_Giai-trinh-khoan-chi.xlsx">
                      {t('formLayout.action.download')}
                      <i className="pi pi-download ml-1" />
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>R03</td>
              <td>
                {t('scientificProfile.label')}:
                <a href="/files/R03-LY-LICH-KHOA-HOC.docx">
                  {t('formLayout.action.download')} <i className="pi pi-download ml-1" />
                </a>{' '}
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>R04</td>
              <td>
                {t('catalogue.R04')}:
                <a href="/files/R04-XAC-NHAN-PHOI-HOP-THUC-HIEN.docx">
                  {t('formLayout.action.download')} <i className="pi pi-download ml-1" />
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>R05</td>
              <td>
                {t('catalogue.R05')}:
                <a href="/files/R05-BAO-CAO-TOM-TAT.docx">
                  {t('formLayout.action.download')} <i className="pi pi-download ml-1" />
                </a>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>R08</td>
              <td>
                {t('catalogue.R08')}:
                <a href="/files/R08-BAO-CAO-TONG-KET.docx">
                  {t('formLayout.action.download')} <i className="pi pi-download ml-1" />
                </a>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>R09</td>
              <td>
                {t('catalogue.R09')}:
                <a href="/files/R09-DANG-KY-KET-QUA.docx">
                  {t('formLayout.action.download')} <i className="pi pi-download ml-1" />
                </a>
              </td>
            </tr>
          </table>
        </section>
      </Layout>
    </LayoutProvider>
  );
}

export default CataloguePage;
