import { EditorInput } from 'components/FormControl';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function Review({ control, errors, readOnly }) {
  const { t } = useTranslation();
  return (
    <div>
      <h4 className="my-2">{t('r05.b.b5.title')}</h4>
      <h6 className="my-2">{t('r05.b.b5.no1')}</h6>
      <EditorInput name="contentReview" control={control} errors={errors} readOnly={readOnly} />
      <h6 className="my-2">{t('r05.b.b5.no2')}</h6>
      <EditorInput name="productReview" control={control} errors={errors} readOnly={readOnly} />
      <h6 className="my-2">{t('r05.b.b5.no3')}</h6>
      <EditorInput name="processReview" control={control} errors={errors} readOnly={readOnly} />
      <h6 className="my-2">{t('r05.b.b5.no4')}</h6>
      <EditorInput name="suggestion" control={control} errors={errors} readOnly={readOnly} />
    </div>
  );
}

Review.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
};

Review.defaultProps = {
  readOnly: false,
};
