import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { TextareaInput } from 'components/FormControl';
import PropTypes from 'prop-types';
import { calculateAdditionalProposedExpenditureByKpis } from 'utils/func';

import HardProductsTable from './HardProductsTable';
import KPITable from './KPITable';
import SoftProductsTable from './SoftProductsTable';
import ResearchContributions from './ResearchContributions';

export default function ResearchResultForm({
  researchFields,
  researchTypes,
  researchKpis,
  projectProposalResearchKpis,
  watch,
  setValue,
  disabled,
  errors,
  control,
}) {
  // #region Data
  const { t } = useTranslation();

  const submittedAt = watch('submittedAt');
  const kpis = watch('researchResult.projectProposalResearchKpis');
  const proposedExpenditureByKpis = watch('researchResult.proposedExpenditureByKpis');
  const projectProposalResearchFields = watch('general.projectProposalResearchFields');

  const kpiTable = useMemo(() => {
    // if projectProposal is not submitted
    // => use researchKpis with latest cost (cost in researchKpiGroup)
    if (!submittedAt) {
      return Object.entries(
        researchKpis.reduce(
          (obj, cur) => ({
            ...obj,
            [cur.researchKpiGroup.name]: {
              id: cur.researchKpiGroup.id,
              order: cur.researchKpiGroup.order,
              cost: cur.researchKpiGroup.cost,
              kpis: [
                ...(obj[cur.researchKpiGroup.name]?.kpis ?? []),
                {
                  id: cur.id,
                  name: cur.name,
                  englishName: cur.englishName,
                  order: cur.order,
                  code: cur.code,
                },
              ],
            },
          }),
          {}
        ) ?? {}
      );
    }
    // else if projectProposal is submitted
    // => use projectProposalResearchKpis with corresponding cost (cost in researchKpiGroupCost)
    return Object.entries(
      projectProposalResearchKpis.reduce(
        (obj, cur) => ({
          ...obj,
          [cur.researchKpi.researchKpiGroup.name]: {
            id: cur.researchKpi.researchKpiGroup.id,
            order: cur.researchKpi.researchKpiGroup.order,
            // researchKpiGroupCosts is sorted DESC by 'date'
            cost: cur.researchKpi.researchKpiGroup.researchKpiGroupCosts.find(
              (rkgc) => new Date(rkgc.date) <= new Date(submittedAt)
            )?.cost,
            kpis: [
              ...(obj[cur.researchKpi.researchKpiGroup.name]?.kpis ?? []),
              {
                id: cur.researchKpi.id,
                name: cur.researchKpi.name,
                englishName: cur.researchKpi.englishName,
                order: cur.researchKpi.order,
                code: cur.researchKpi.code,
              },
            ],
          },
        }),
        {}
      ) ?? {}
    );
  }, [researchKpis, projectProposalResearchKpis, submittedAt]);

  useEffect(() => {
    if (!submittedAt) {
      if (kpis && researchKpis) {
        const total = researchKpis.reduce((sum, cur) => {
          if (kpis[cur.id]?.quantity) {
            return sum + (kpis[cur.id]?.quantity ?? 0) * (cur.researchKpiGroup.cost ?? 0);
          }
          return sum;
        }, 0);

        const additionalExpenditureByKpis = calculateAdditionalProposedExpenditureByKpis(
          projectProposalResearchFields,
          kpis,
          researchFields,
          researchKpis
        );

        setValue(
          'researchResult.proposedExpenditureByKpis',
          (total + additionalExpenditureByKpis) * 1000000
        );
      }
    } else if (kpis && projectProposalResearchKpis) {
      const total = projectProposalResearchKpis.reduce((sum, cur) => {
        if (kpis[cur.researchKpi.id]?.quantity) {
          const correspondingCost = cur.researchKpi.researchKpiGroup.researchKpiGroupCosts.find(
            (rkgc) => new Date(rkgc.date) <= new Date(submittedAt)
          )?.cost;
          return sum + (kpis[cur.researchKpi.id]?.quantity ?? 0) * (correspondingCost ?? 0);
        }
        return sum;
      }, 0);

      const additionalExpenditureByKpis = calculateAdditionalProposedExpenditureByKpis(
        projectProposalResearchFields,
        kpis,
        researchFields,
        researchKpis
      );

      setValue(
        'researchResult.proposedExpenditureByKpis',
        (total + additionalExpenditureByKpis) * 1000000
      );
    }
  }, [
    JSON.stringify(kpis),
    researchKpis,
    projectProposalResearchKpis,
    submittedAt,
    JSON.stringify(projectProposalResearchFields),
  ]);

  // #endregion

  // #region Event
  // #endregion
  return (
    <div>
      <h4 className="text-center mb-4">{t('projectProposal.researchResult.label')}</h4>
      <h4>{t('projectProposal.researchResult.a10_1.label')}</h4>

      <SoftProductsTable disabled={disabled} control={control} errors={errors} />
      <HardProductsTable disabled={disabled} control={control} errors={errors} />

      <h4>{t('projectProposal.researchResult.a10_1.qualityComparison.label')}</h4>
      <p>({t('projectProposal.researchResult.a10_1.qualityComparison.note')})</p>
      <div className="grid">
        <div className="col-12">
          <TextareaInput
            name="researchResult.qualityComparison"
            control={control}
            errors={errors}
          />
        </div>
      </div>

      <KPITable
        kpiTable={kpiTable}
        proposedExpenditure={proposedExpenditureByKpis}
        control={control}
        errors={errors}
      />

      <ResearchContributions
        control={control}
        errors={errors}
        disabled={disabled}
        watch={watch}
        researchTypes={researchTypes}
      />
    </div>
  );
}

ResearchResultForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  researchFields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  researchTypes: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  researchKpis: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectProposalResearchKpis: PropTypes.array,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
};

ResearchResultForm.defaultProps = {
  disabled: false,
  projectProposalResearchKpis: [],
};
