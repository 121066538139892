import { forwardRef, useImperativeHandle, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NumberInput, Select, TextInput, TextareaInput } from 'components/FormControl';

import { yupResolver } from '@hookform/resolvers/yup';
import { TOAST } from 'constant';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { internationalBookSchema } from 'features/ScientificProfile/validation';

const formOptions = { resolver: yupResolver(internationalBookSchema) };

const InternationalBookDialog = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [internationalBookControl, setInternationalBookControl] = useState();
  const [visible, setVisible] = useState(false);

  const contributorRoles = [
    { value: 'author', name: t('contributorRole.author') },
    { value: 'coAuthor', name: t('contributorRole.coAuthor') },
  ];

  const {
    control,
    watch,
    setValue,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({
    ...formOptions,
    mode: 'onChange',
  });

  // #region Event

  useImperativeHandle(
    ref,
    () => ({
      open: (_internationalBookControl) => {
        setInternationalBookControl(_internationalBookControl);
        setVisible(true);
        if (_internationalBookControl.type === 'INSERT') {
          reset({});
        } else if (_internationalBookControl.type === 'UPDATE') {
          reset(_internationalBookControl.value);
        }
      },
    }),
    []
  );

  const handleAddInternationalBook = async () => {
    setValue('book.publicationType', 'INTERNATIONAL');

    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = watch();

    // const { role, pseudonym, book } = data;

    const { insert, update, index, type } = internationalBookControl;

    if (type === 'INSERT' && insert) {
      insert(index, data);
    } else if (type === 'UPDATE' && update) {
      update(index, data);
    }
    reset({});
    setVisible(false);
  };

  // const handleTitleSelect = (title, value) => {
  //   reset({
  //     role: '',
  //     pseudonym: '',
  //     internationalBook: { id: value.id, title: value.title },
  //     book: {
  //       id: value.id,
  //       title: value.title,
  //       projectCode: value.projectCode,
  //       publisher: value.publisher,
  //       year: value.year,
  //       publicationType: value.publicationType,
  //     },
  //   });
  //   setIsCreateNewBook(false);
  // };

  // const handleTitleRemove = () => {
  //   setIsCreateNewBook(true);
  //   setQueryTitle('');
  //   reset({});
  // };

  // const handleTitleBlur = (value) => {
  //   if (!value?.id) {
  //     if (internationalBooksData?.data?.length > 0) {
  //       const internationBook = internationalBooksData?.data.find((e) => e.title === value);

  //       if (internationBook) {
  //         reset({
  //           role: '',
  //           pseudonym: '',
  //           internationalBook: { id: internationBook.id, title: internationBook.title },
  //           book: {
  //             id: internationBook.id,
  //             title: internationBook.title,
  //             projectCode: internationBook.projectCode,
  //             publisher: internationBook.publisher,
  //             year: internationBook.year,
  //             publicationType: internationBook.publicationType,
  //           },
  //         });
  //         setIsCreateNewBook(false);
  //       } else {
  //         setIsCreateNewBook(true);
  //         reset({
  //           role: '',
  //           pseudonym: '',
  //           internationalBook: { title: typeof value === 'string' ? value : queryTitle },
  //           book: {
  //             title: typeof value === 'string' ? value : queryTitle,
  //           },
  //         });
  //       }
  //     } else {
  //       setIsCreateNewBook(true);
  //       reset({
  //         role: '',
  //         pseudonym: '',
  //         internationalBook: { title: typeof value === 'string' ? value : queryTitle },
  //         book: {
  //           title: typeof value === 'string' ? value : queryTitle,
  //         },
  //       });
  //     }
  //   }
  // };
  // #endregion Event

  return (
    <>
      <Dialog
        header={
          internationalBookControl?.type === 'INSERT'
            ? t('scientificProfile.scientificWork.book.international.add')
            : t('scientificProfile.scientificWork.book.international.update')
        }
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        draggable={false}
        className="w-full sm:w-7 lg:w-6"
      >
        <div className="grid p-fluid">
          <div className="col-12">
            <TextInput
              placeholder={t('scientificProfile.scientificWork.enterTitle')}
              label={t('scientificProfile.scientificWork.book.title')}
              name="book.title"
              control={control}
              errors={errors}
              isRequired
            />
          </div>
          <div className="col-12">
            <TextInput
              label={t('scientificProfile.scientificWork.book.projectCode')}
              name="book.projectCode"
              control={control}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextareaInput
              label={t('scientificProfile.scientificWork.book.publisher')}
              name="book.publisher"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          <div className="col-6">
            <NumberInput
              label={t('scientificProfile.scientificWork.book.year')}
              name="book.year"
              control={control}
              errors={errors}
              min={0}
              isRequired
            />
          </div>

          <div className="col-6">
            <Select
              label={t('scientificProfile.scientificWork.book.authorOrCoauthor')}
              name="role"
              options={contributorRoles}
              control={control}
              errors={errors}
              inputField="value"
              isRequired
            />
          </div>
          <div className="col-6">
            <TextareaInput
              label={t('scientificProfile.scientificWork.book.pseudonym')}
              name="pseudonym"
              control={control}
              errors={errors}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-content-end mt-4">
          <Button
            label={t('formLayout.action.cancel')}
            severity="danger"
            onClick={() => {
              setVisible(false);
            }}
            className="mr-1 w-8rem"
          />
          <Button
            label={
              internationalBookControl?.type === 'INSERT'
                ? t('formLayout.action.add')
                : t('formLayout.action.update')
            }
            severity="info"
            type="submit"
            className="w-8rem"
            onClick={handleAddInternationalBook}
            disabled={!Object.keys(dirtyFields).length}
          />
        </div>
      </Dialog>

      {/* {isCreateBookLoading && <Loading />} */}
    </>
  );
});

InternationalBookDialog.propTypes = {};

InternationalBookDialog.defaultProps = {};

export default InternationalBookDialog;
