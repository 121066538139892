import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import Proptypes from 'prop-types';
import { PROJECT_ROLES } from 'constant';
import { getMonth, getYear } from 'utils/func';
import { getProjectById } from 'apis/project.api';

export default function GeneralInfo({ isDetail }) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { data: project } = useQuery(['getProjectById', projectId], () =>
    getProjectById(projectId)
  );
  const owner = useMemo(
    () =>
      project?.data?.projectProposal?.projectProposalContacts?.find(
        (pp) => pp.projectRole?.code === PROJECT_ROLES.OWNER
      ) ?? null,
    [project]
  );
  return (
    <>
      <h5 className="font-semibold my-2">
        {t('extendImplementationTime.R07.projectName')}{' '}
        <span className="font-normal">{project?.data?.projectProposal?.vietnameseName}</span>
      </h5>
      <h5 className="font-semibold my-2">
        {t('extendImplementationTime.R07.projectCode')}{' '}
        <span className="font-normal">{project?.data?.topicCode}</span>
      </h5>
      <h5 className="font-semibold my-2">
        {t('extendImplementationTime.R07.projectOwner')}{' '}
        <span className="font-normal">{owner?.fullname}</span>
      </h5>

      {isDetail && (
        <h5 className="font-semibold my-2">
          {t('extendImplementationTime.R07.totalExpenditure.title')}
          <span className="font-normal">
            {project?.data?.totalExpenditure?.toLocaleString('de-DE')}{' '}
            {t('extendImplementationTime.R07.totalExpenditure.sub')}{' '}
            {project?.data?.expenditureFromVNU?.toLocaleString('de-DE')}{' '}
            {t('extendImplementationTime.R07.totalExpenditure.unit')}
          </span>
        </h5>
      )}

      <h5 className="font-semibold my-2">
        {t('extendImplementationTime.R07.implementationTimeFollowContract.title')}
        <span className="font-normal">
          {Math.ceil(moment(project?.data?.endDate).diff(project?.data?.startDate, 'months', true))}{' '}
          {t('extendImplementationTime.R07.implementationTimeFollowContract.sub')}{' '}
          {`${getMonth(project?.data?.startDate)}/${getYear(project?.data?.startDate)}`}{' '}
          {t('extendImplementationTime.R07.implementationTimeFollowContract.to')}{' '}
          {`${getMonth(project?.data?.endDate)}/${getYear(project?.data?.endDate)}`}
        </span>
      </h5>
    </>
  );
}

GeneralInfo.propTypes = {
  isDetail: Proptypes.bool,
};

GeneralInfo.defaultProps = {
  isDetail: false,
};
