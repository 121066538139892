import instance from 'utils/axios/instance.axios';
import { NOTIFICATION } from './_constant';

export const getNotification = (page, rowsPerPage, type) =>
  instance.get(NOTIFICATION.GET_ALL, { params: { page, rowsPerPage, type } });

export const getNotificationById = (id) => instance.get(NOTIFICATION.GET_BY_ID(id));

export const getUnreadNotification = () => instance.get(NOTIFICATION.GET_UNREAD);

export const readNotification = (id) => instance.post(NOTIFICATION.READ_NOTIFICATION(id));

export const unreadNotification = (id) => instance.post(NOTIFICATION.UNREAD_NOTIFICATION(id));

export const readAllNotification = () => instance.post(NOTIFICATION.READ_ALL);

export const deleteNotification = (id) => instance.delete(NOTIFICATION.DELETE_NOTIFICATION(id));
