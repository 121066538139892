import { Select } from 'components/FormControl';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

export default function SecurityInformation({ disabled, control, errors }) {
  // #region Data
  const { t } = useTranslation();
  const securityInformation = [
    { value: 'popular', name: t('r09.securityInformation.popular') },
    { value: 'limited', name: t('r09.securityInformation.limited') },
    { value: 'security', name: t('r09.securityInformation.security') },
  ];

  // #endregion Data
  return (
    <div className="ml-4">
      <div className="col-12 sm:col-6 md:col-4">
        <Select
          name="securitySeverity"
          isRequired
          options={securityInformation}
          inputField="value"
          disabled={disabled}
          control={control}
          errors={errors}
        />
      </div>
    </div>
  );
}

SecurityInformation.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
};

SecurityInformation.defaultProps = {
  disabled: false,
};
