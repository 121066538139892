import { useFieldArray, useFormContext } from 'react-hook-form';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { classNames } from 'primereact/utils';
import ResearchActivityDialog from './ResearchActivityDialog';

export default function ResearchActivitiesForm({ control, shouldUpdateFromDocx }) {
  // #region Data
  const { t } = useTranslation();
  const { dirtyFields } = useFormContext();

  const researchActivityRef = useRef();

  const { fields, insert, update, remove } = useFieldArray({
    control,
    name: 'researchActivities',
  });

  const [expandedRows, setExpandedRows] = useState(null);
  // #endregion Data

  // #region Event
  // #endregion Event

  // #region Render
  const formatIndex = (_, { rowIndex }) => rowIndex + 1;

  const formatManagementLevel = (rowData) =>
    rowData?.managementLevel ? t(`managementLevel.${rowData?.managementLevel}`) : '';

  const formatTimePeriod = (rowData) =>
    `${rowData?.start ? new Date(rowData?.start).toLocaleDateString('en-GB') : ''} - ${
      rowData?.end ? new Date(rowData?.end).toLocaleDateString('en-GB') : ''
    }`;

  const formatRole = (rowData) => (rowData?.role ? t(`projectRole.${rowData?.role}`) : '');

  const formatAcceptanceDate = (rowData) =>
    rowData?.acceptanceDate ? new Date(rowData?.acceptanceDate).toLocaleDateString('en-GB') : '';

  const formatResult = (rowData) => (rowData?.result ? t(`result.${rowData?.result}`) : '');

  const formatActions = (_, { rowIndex }) => (
    <div
      // className={`flex flex-column float text-center p-0 border-none ${
      //   rowIndex !== 0 ? 'border-top-1' : ''
      // }`}
      className="flex flex-column float text-center p-0 border-none"
    >
      <i
        onClick={() => {
          researchActivityRef.current.open({
            insert,
            index: rowIndex + 1,
            type: 'INSERT',
          });
        }}
        className="insert-icon pi pi-plus text-primary cursor-pointer my-1"
        data-pr-tooltip={t('formLayout.action.insert')}
        data-pr-position="left"
      />
      <Tooltip target=".insert-icon" />
      <i
        onClick={() => {
          researchActivityRef.current.open({
            update,
            index: rowIndex,
            value: fields[rowIndex],
            type: 'UPDATE',
          });
        }}
        className="edit-icon pi pi-pencil text-primary cursor-pointer mb-1"
        data-pr-tooltip={t('formLayout.action.edit')}
        data-pr-position="left"
      />
      <Tooltip target=".edit-icon" />
      <i
        onClick={() => remove(rowIndex)}
        className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
        data-pr-tooltip={t('formLayout.action.remove')}
        data-pr-position="left"
      />
      <Tooltip target=".remove-icon" />
    </div>
  );

  const rowExpansionTemplate = (rowData) => (
    <div className="m-2 text-lg">
      <div className="grid">
        <div className="col-12">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.researchActivity.projectName')}:{' '}
          </strong>
          {rowData.name}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.researchActivity.projectCode')}:{' '}
          </strong>
          {rowData.code}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.researchActivity.managementLevel')}:{' '}
          </strong>
          {rowData.managementLevel ? t(`managementLevel.${rowData.managementLevel}`) : ''}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.researchActivity.start')}:{' '}
          </strong>
          {rowData.start ? new Date(rowData.start).toLocaleDateString('en-GB') : ''}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.researchActivity.end')}:{' '}
          </strong>
          {rowData.end ? new Date(rowData.end).toLocaleDateString('en-GB') : ''}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.researchActivity.expenditure')}:{' '}
          </strong>
          {rowData.expenditure}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.researchActivity.role')}:{' '}
          </strong>
          {rowData.role ? t(`projectRole.${rowData.role}`) : ''}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.researchActivity.acceptanceDate')}:{' '}
          </strong>
          {rowData.acceptanceDate
            ? new Date(rowData.acceptanceDate).toLocaleDateString('en-GB')
            : ''}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.researchActivity.result')}:{' '}
          </strong>
          {rowData.result ? t(`result.${rowData.result}`) : ''}
        </div>
      </div>
    </div>
  );

  const rowHighlight = () => {
    let className = '';
    if (shouldUpdateFromDocx && dirtyFields?.researchActivities) {
      className = 'highlighted-uncertain';
    }
    return className;
  };

  // #endregion Render

  return (
    <>
      <h4 className="text-center mb-4">{t('scientificProfile.researchActivity.label')}</h4>

      <DataTable
        value={fields}
        className={classNames('p-datatable-gridlines header-table', {
          highlighted: shouldUpdateFromDocx && dirtyFields.researchActivities?.[0]?.name,
        })}
        rowClassName={rowHighlight}
        showGridlines
        filterDisplay="menu"
        loading={typeof fields?.length !== 'number'}
        scrollable
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        emptyMessage=" "
      >
        <Column expander style={{ width: '2rem' }} />
        <Column
          header={t('formLayout.numericalOrder')}
          headerClassName="text-center  "
          className="text-center"
          body={formatIndex}
          style={{ minWidth: 50, width: 50 }}
          expander
        />
        <Column
          header={t('scientificProfile.researchActivity.projectName')}
          headerClassName="text-center"
          field="name"
          style={{ minWidth: 210, width: '21%' }}
        />
        <Column
          header={t('scientificProfile.researchActivity.projectCode')}
          headerClassName="text-center"
          field="code"
          style={{ minWidth: 120, width: '12%' }}
        />
        <Column
          header={t('scientificProfile.researchActivity.managementLevel')}
          headerClassName="text-center"
          body={formatManagementLevel}
          style={{ minWidth: 110, width: '11%' }}
        />
        <Column
          header={t('scientificProfile.researchActivity.timePeriod')}
          headerClassName="text-center"
          body={formatTimePeriod}
          style={{ minWidth: 80, width: '8%' }}
        />
        <Column
          header={t('scientificProfile.researchActivity.expenditure')}
          headerClassName="text-center"
          field="expenditure"
          style={{ minWidth: 90, width: '9%' }}
        />
        <Column
          header={t('scientificProfile.researchActivity.ownOrParticipate')}
          headerClassName="text-center"
          body={formatRole}
          style={{ minWidth: 110, width: '11%' }}
        />
        <Column
          header={t('scientificProfile.researchActivity.acceptanceDate')}
          headerClassName="text-center"
          body={formatAcceptanceDate}
          style={{ minWidth: 90, width: '9%' }}
        />
        <Column
          header={t('scientificProfile.researchActivity.result')}
          headerClassName="text-center"
          body={formatResult}
          style={{ minWidth: 70, width: '7%' }}
        />

        <Column
          header={t('formLayout.action.label')}
          headerClassName="text-center"
          style={{ minWidth: 80, width: 80 }}
          body={formatActions}
          frozen
          alignFrozen="right"
        />
      </DataTable>

      {fields?.length === 0 && (
        <div className="flex justify-content-center my-2">
          <i
            onClick={() => {
              researchActivityRef.current.open({
                insert,
                index: 0,
                type: 'INSERT',
              });
            }}
            className="add-research-activity pi pi-plus text-primary
              cursor-pointer bg-primary p-1 border-circle"
            data-pr-tooltip={t('formLayout.action.add')}
            data-pr-position="left"
          />
          <Tooltip target=".add-research-activity" />
        </div>
      )}

      <ResearchActivityDialog ref={researchActivityRef} />
    </>
  );
}

ResearchActivitiesForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  shouldUpdateFromDocx: PropTypes.bool,
};

ResearchActivitiesForm.defaultProps = {
  shouldUpdateFromDocx: false,
};
