import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { yupResolver } from '@hookform/resolvers/yup';

import { updatePassword } from 'apis/users.api';

import TextInput from 'components/FormControl/TextInput';
import Loading from 'components/Loading';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { mapError } from 'utils/func';

import { updatePasswordValidationSchema } from '../validation';

const formOptions = { resolver: yupResolver(updatePasswordValidationSchema) };

export default function UpdatePassword() {
  // #region Data
  const toast = useRef();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm(formOptions);

  const { mutate, isLoading } = useMutation(updatePassword);
  // #endregion Data

  // #region Event
  const showSuccess = () => {
    toast.current.show({
      severity: 'success',
      summary: t('updatePassword.success.summary'),
      detail: t('updatePassword.success.detail'),
      life: 4000,
    });
  };

  const showError = (error) => {
    toast.current.show({
      severity: 'error',
      summary: t('updatePassword.error.summary'),
      detail: mapError(t, error),
      life: 4000,
    });
  };

  const onSubmit = async (updatePassParam) => {
    mutate(updatePassParam, {
      onSuccess: () => {
        showSuccess();
        reset();
      },
      onError: (error) => showError(error),
    });
  };
  // #endregion Event

  return (
    <div className="flex align-items-center justify-content-center" style={{ height: '80vh' }}>
      <Toast position="bottom-left" ref={toast} />
      <div
        className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
        style={{ maxWidth: '400px' }}
      >
        <div className="text-center mb-5">
          <img src="/logo.png" alt="hyper" height={50} className="mb-3" />
          <div className="text-900 text-3xl font-medium mb-3">{t('updatePassword.title')}</div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <TextInput
            label={t('updatePassword.currentPassword')}
            name="currentPassword"
            type="password"
            autoFocus
            isRequired
            control={control}
            errors={errors}
          />

          <TextInput
            label={t('updatePassword.newPassword')}
            name="newPassword"
            type="password"
            isRequired
            control={control}
            errors={errors}
          />

          <TextInput
            label={t('updatePassword.confirmPassword')}
            name="confirmPassword"
            type="password"
            isRequired
            control={control}
            errors={errors}
          />

          <Button
            label={t('updatePassword.submit')}
            icon="pi pi-key"
            className="w-full bg-cyan-600 border-cyan-600 mt-6"
            type="submit"
          />
        </form>
      </div>
      {isLoading && <Loading />}
    </div>
  );
}
