import {
  EditorInput,
} from 'components/FormControl';
// import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

export default function EvaluationForm({ control, errors }) {
  // #region Data
  const { t } = useTranslation();

  // #endregion

  return (
    <div>
      {/* Section A */}
      <div className="mt-5">
        <h4>{t('council.m08.a.label')}</h4>

        {/* A1 */}
        <div className="text-lg mt-3">
          <h5>{t('council.m08.a.a1')}</h5>
          <p className="font-italic">{t('council.m08.a.a1_note')}</p>
          <EditorInput name="reviewMeet" control={control} errors={errors} />
        </div>

        {/* A2 */}
        <div className="text-lg mt-3">
          <h5 className="mb-2">{t('council.m08.a.a2')}</h5>
          <EditorInput name="reviewProduct" control={control} errors={errors} />
        </div>

        {/* A3 */}
        <div className="text-lg mt-3">
          <h5>{t('council.m08.a.a3')}</h5>
          <p className="font-italic">{t('council.m08.a.a3_note')}</p>
          <EditorInput name="reviewReportQuality" control={control} errors={errors} />
        </div>

      </div>

      {/* Section B */}

    </div>
  );
}

EvaluationForm.propTypes = {
//   setValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  watch: PropTypes.func,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};

EvaluationForm.defaultProps = {
  watch: null
};
