// import { Column } from 'primereact/column';
// import { DataTable } from 'primereact/datatable';
import './style.scss';

import { Dialog } from 'primereact/dialog';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function MidtermDocumentDialog({ midtermDocuments, visible, setVisible }) {
  const { t } = useTranslation();
  const formatName = (value) => (
    <div>
      <Link
        to={`/project/${midtermDocuments?.[0]?.projectId}/submit-midterm-document/edit/${value?.id}`}
      >
        {t('project.viewList.midtermDocumentDialog.versionText')} {value?.version}
      </Link>
    </div>
  );
  const formatSubmitDate = (value) => (
    <div>
      {value?.submittedAt && new Date(value?.submittedAt).toLocaleDateString()}
      {!value?.submittedAt && t('project.viewList.midtermDocumentDialog.everSubmit')}
    </div>
  );
  const formatCouncilResult = (value) => {
    const qualified = value?.council?.projectProposalCouncils?.[0]?.councilStatus;
    return (
      <div>
        {qualified && t(`project.viewList.midtermDocumentDialog.councilResult.${qualified}`)}
        {!qualified && t('project.viewList.midtermDocumentDialog.councilResult.everCouncilResult')}
      </div>
    );
  };
  return (
    <Dialog
      header={t('project.viewList.midtermDocumentDialog.title')}
      visible={visible}
      onHide={() => setVisible(false)}
      draggable={false}
      style={{ maxWidth: '50rem' }}
      className="w-full"
    >
      <table className="w-full view-history-midterm-table">
        <thead>
          <tr>
            <th style={{ width: '4%' }}>{t('project.viewList.midtermDocumentDialog.no')}</th>
            <th style={{ width: '40%' }}>{t('project.viewList.midtermDocumentDialog.version')}</th>
            <th style={{ width: '15%' }}>
              {t('project.viewList.midtermDocumentDialog.submitDate')}
            </th>
            <th style={{ width: '18%' }}>
              {t('project.viewList.midtermDocumentDialog.councilResult.label')}
            </th>
          </tr>
        </thead>
        <tbody>
          {midtermDocuments?.length > 0 ? (
            midtermDocuments.map((midtermDocument, index) => (
              <tr key={midtermDocument.id}>
                <td className="text-center">{index + 1}</td>
                <td>{formatName(midtermDocument)}</td>
                <td>{formatSubmitDate(midtermDocument)}</td>
                <td>{formatCouncilResult(midtermDocument)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>{t('project.viewList.midtermDocumentDialog.noMidtermDocument')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Dialog>
  );
}

MidtermDocumentDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  midtermDocuments: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
export default MidtermDocumentDialog;
