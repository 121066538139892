/* eslint-disable indent */
import './style.scss';

import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';

import {
  downloadFile,
  downloadZip,
  downloadExplanation,
  getDocumentsById,
  getExpenditureById,
  getGeneralInfoById,
  getResearchResultById,
  getReviseLogs,
  getAllVersions,
} from 'apis/pubProjectProposals.api';

import {
  Documents,
  Expenditure,
  GeneralInfo,
  ResearchResult,
  Signature,
} from 'features/PubProjectProposal/components/Detail';

import ErrorDialog from 'components/ErrorDialog';
import Loading from 'components/Loading';

import { defaultValues } from 'features/PubProjectProposal/defaultValues';

import { PROJECT_ROLES } from 'constant';
import { formatFileName, getAcadamicLevelShortHand, toNonAccentVietnamese } from 'utils/func';

export default function PubProjectProposalDetail() {
  // #region Data
  const { t } = useTranslation();
  const { projectProposalId } = useParams();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const { mutate: mutateDownloadFile } = useMutation(downloadFile);

  const {
    mutate: mutateDownloadZip,
    isLoading: isDownloadZipLoading,
    isError: isDownloadZipError,
    error: downloadZipError,
  } = useMutation(downloadZip);

  const {
    mutate: mutateDownloadExplanation,
    isLoading: isDownloadExplanationLoading,
    isError: isDownloadExplanationError,
    error: downloadExplanationError,
  } = useMutation(downloadExplanation);

  const {
    data: reviseLogData,
    isLoading: isReviseLogLoading,
  } = useQuery(['pubProjectProposalReviseLogs', projectProposalId, 'ALL_REVISE_LOG'], () =>
    getReviseLogs({
      projectProposalId,
      types: [
        'REQUEST_EXPLANATION_PREQUALIFICATION',
        'REQUEST_EXPLANATION_SPECIALIZATION',
        'REQUEST_EXPLANATION_FINANCE',
      ],
    })
  );
  const { data: allProjectProposalVersionsData } = useQuery(
    ['allProjectProposalVersions', projectProposalId],
    () => getAllVersions(projectProposalId)
  );

  const latestVersion = useMemo(() => {
    if (allProjectProposalVersionsData?.data?.length > 0) {
      return (
        allProjectProposalVersionsData?.data?.reduce((a, b) => (a?.version > b?.version ? a : b)) ??
        null
      );
    }
    return null;
  }, [allProjectProposalVersionsData]);
  const {
    data: generalInfoData,
    isLoading: isGeneralInfoLoading,
    isError: isGeneralInfoError,
    error: generalInfoError,
  } = useQuery(
    ['pubProjectProposal-generalInfo', latestVersion?.id],
    () => getGeneralInfoById(latestVersion?.id),
    {
      enabled: !!latestVersion?.id,
    }
  );
  const generalInfo = useMemo(
    () => generalInfoData?.data ?? defaultValues.generalInfo,
    [generalInfoData]
  );

  const {
    data: researchResultData,
    isLoading: isResearchResultLoading,
    isError: isResearchResultError,
    error: researchResultError,
  } = useQuery(
    ['pubProjectProposal-researchResult', latestVersion?.id],
    () => getResearchResultById(latestVersion?.id),
    {
      enabled: !!latestVersion?.id,
    }
  );
  const researchResult = useMemo(
    () => researchResultData?.data ?? defaultValues.researchResult,
    [researchResultData]
  );

  const {
    data: expenditureData,
    isLoading: isExpenditureLoading,
    isError: isExpenditureError,
    error: expenditureError,
  } = useQuery(
    ['pubProjectProposal-expenditure', latestVersion?.id],
    () => getExpenditureById(latestVersion?.id),
    { enabled: !!latestVersion?.id }
  );

  const expenditure = useMemo(() => {
    if (expenditureData?.data) {
      const firstSummary = expenditureData?.data?.proposedExpenditureSummaries[0] ?
                            expenditureData?.data?.proposedExpenditureSummaries[0] : '';
      return {
        proposedExpenditureSummaries: firstSummary
      };
    }
    return defaultValues.expenditure;
  });

  const {
    data: documentsData,
    isLoading: isDocumentsLoading,
  } = useQuery(
    ['pubProjectProposal-documents', latestVersion?.id],
    () => getDocumentsById(latestVersion?.id),
    { enabled: !!latestVersion?.id }
  );
  const documents = useMemo(() => documentsData?.data ?? {}, [documentsData]);

  // #endregion Data

  // #region Event
  const handleDownloadFile = (path) => {
    mutateDownloadFile(
      { projectProposalId: latestVersion?.id, path },
      {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = formatFileName(path);

            link.click();
          }
        },
      }
    );
  };

  const handleDownloadZip = () => {
    // Call api download files
    // param:selectedItems,unselectedItems,isSelectedAll,isUnselectedSome,researchFieldId,statusName
    mutateDownloadZip(
      {
        selectedItems: [latestVersion?.id],
        unselectedItems: [],
        isSelectedAll: false,
        isUnselectedSome: false,
      },
      {
        onSuccess: (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const owner = generalInfo?.projectProposalContacts.find(
              (e) => PROJECT_ROLES.OWNER === e.projectRole.code
            );
            const academicLevelShorthand = getAcadamicLevelShortHand(
              owner?.academicRank,
              owner?.academicDegree,
              t,
              true
            );
            const contentDisposition = res?.headers['content-disposition'];
            const filenameMatch = contentDisposition?.match(/filename='(.+)'/);
            const filename = filenameMatch
              ? filenameMatch[1]
              : `De tai DHQG - ${academicLevelShorthand}${toNonAccentVietnamese(
                  owner?.fullname
                )}.zip`;
            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = filename;

            link.click();
          }
        },
        onError: () => {
          setIsErrorDialogOpen(true);
        },
      }
    );
  };

  const handleDownloadExplanation = () => {
    mutateDownloadExplanation(projectProposalId, {
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const owner = generalInfo?.projectProposalContacts.find(
            (e) => PROJECT_ROLES.OWNER === e.projectRole.code
          );
          const academicLevelShorthand = getAcadamicLevelShortHand(
            owner?.academicRank,
            owner?.academicDegree,
            t,
            true
          );
          const contentDisposition = res?.headers['content-disposition'];
          const filenameMatch = contentDisposition?.match(/filename='(.+)'/);
          const filename = filenameMatch
            ? filenameMatch[1]
            : `Danh sach cac giai trinh de tai ĐHQG - ${academicLevelShorthand}${toNonAccentVietnamese(
                owner?.fullname
              )}.zip`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;

          link.click();
        }
      },
      onError: () => {
        setIsErrorDialogOpen(true);
      },
    });
  };
  // #endregion Event

  // #region Render
  if (isGeneralInfoError) {
    return (
      <ErrorDialog
        title={t('pubProjectProposal.generalInfoErrorTitle')}
        isError={isGeneralInfoError}
        error={generalInfoError}
        visible
        isNavigate
      />
    );
  }
  if (isResearchResultError) {
    return (
      <ErrorDialog
        title={t('pubProjectProposal.researchResultErrorTitle')}
        isError={isResearchResultError}
        error={researchResultError}
        visible
        isNavigate
      />
    );
  }
  if (isExpenditureError) {
    return (
      <ErrorDialog
        title={t('pubProjectProposal.expenditureErrorTitle')}
        isError={isExpenditureError}
        error={expenditureError}
        visible
        isNavigate
      />
    );
  }

  return (
    <div className="bg-white p-5">
      <div className="flex justify-content-end">
        <Button
          type="button"
          label={t('pubProjectProposal.viewList.tooltip.download')}
          icon={classNames(
            'pi',
            { 'pi-spin pi-spinner': isDownloadZipLoading },
            { 'pi pi-download': !isDownloadZipLoading }
          )}
          severity="info"
          onClick={handleDownloadZip}
        />
        {reviseLogData?.data?.length > 0 && (
          <Button
            type="button"
            label="Tải giải trình đề tài"
            icon={classNames(
              'pi',
              { 'pi-spin pi-spinner': isDownloadExplanationLoading },
              { 'pi pi-file': !isDownloadExplanationLoading }
            )}
            className="ml-1"
            severity="info"
            onClick={handleDownloadExplanation}
          />
        )}
      </div>

      {(isReviseLogLoading ||
        isGeneralInfoLoading ||
        isResearchResultLoading ||
        isExpenditureLoading ||
        isDocumentsLoading) && <Loading />}
      <div className="m-0 bg-white p-5 p-fluid detail-info-project-proposal">
        <div className="mb-5 flex justify-content-between align-items-center">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="VNU-HCM logo"
            height="75px"
            className="ml-3"
          />
          <div>
            <table style={{ borderCollapse: 'collapse' }} className="text-black-alpha-90">
              <tbody>
                <tr>
                  <td colSpan="2" className="p-1">
                    {t('pubProjectProposal.viewDetail.form')} R01 -{' '}
                    {t('pubProjectProposal.viewDetail.update')} 2022
                  </td>
                </tr>
                <tr>
                  <td className="border-dotted border-1 p-1 w-10rem">
                    {t('pubProjectProposal.viewDetail.dateOfReceivingFile')}
                  </td>
                  <td className="border-dotted border-1 p-1 w-8rem"> </td>
                </tr>
                <tr>
                  <td className="border-dotted border-1 p-1 w-10rem">
                    {t('pubProjectProposal.viewDetail.projectId')}
                  </td>
                  <td className="border-dotted border-1 p-1 w-8rem"> </td>
                </tr>
                <tr>
                  <td colSpan="2" className="border-dotted border-1 p-1 text-center">
                    <span className="font-italic">
                      ({t('pubProjectProposal.viewDetail.recordedBy')})
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h3 className="mb-5 line-height-3 text-4xl text-center font-bold">
          {t('pubProjectProposal.viewDetail.application')}
          <br />
          <span className="font-normal">{t('pubProjectProposal.viewDetail.sciTechProject')}</span>
        </h3>

        <div className="ml-4">
          <GeneralInfo
            data={generalInfo}
            isLoading={isGeneralInfoLoading}
            handleDownloadFile={handleDownloadFile}
          />
          <ResearchResult data={researchResult} researchType={generalInfo?.researchType} />
          <Expenditure
            data={expenditure}
            researchDescriptionFileUrl={documents?.researchDescriptionFileUrl}
            handleDownloadFile={handleDownloadFile}
          />
          <Documents data={documents} handleDownloadFile={handleDownloadFile} />
        </div>

        <Signature />
      </div>
      <ErrorDialog
        title={t('pubProjectProposal.downloadZipErrorTitle')}
        isError={isDownloadZipError}
        error={downloadZipError}
        visible={isErrorDialogOpen}
        setVisible={setIsErrorDialogOpen}
      />
      <ErrorDialog
        title={t('pubProjectProposal.downloadZipErrorTitle')}
        isError={isDownloadExplanationError}
        error={downloadExplanationError}
        visible={isErrorDialogOpen}
        setVisible={setIsErrorDialogOpen}
      />
    </div>
  );
  // #endregion Render
}
