import { TextareaInput, Select } from 'components/FormControl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import { forwardRef, useImperativeHandle, useRef, useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  formatFileNameProduct,
  getEnableProductDetailPropList,
  isEnableProductDetailProp as isEnable,
  getResearchKpiGroup,
  filterReasearchKpiGroups,
} from 'utils/func';
import CustomFileInput from 'components/FormControl/CustomFileInput';
import { addBaseProductSchema, addProductSchema } from 'features/Project/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  PRODUCT_DETAIL_FIELD as ENUM,
  PRODUCT_TYPE as TYPE,
} from 'constant/summary-report.constant';
import { productDefaultValues } from 'features/Project/defaultValues';

const ProductDialog = forwardRef(
  ({ researchKpiGroups, researchKpis, handleDownloadFile, productTypeShort }, ref) => {
    // #region Data
    const { t } = useTranslation();
    const toast = useRef(null);

    const [productControl, setSoftProductControl] = useState();
    const [selectedGroup, setSelectedGroup] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [productSchema, setProductSchema] = useState(addBaseProductSchema);
    const {
      control,
      getValues,
      reset,
      watch,
      trigger,
      formState: { errors, dirtyFields },
    } = useForm({
      mode: 'onChange',
      resolver: yupResolver(productSchema),
      defaultValues: productDefaultValues,
    });
    const kpiGroupId = watch('researchKpiGroupId');
    const kpiId = watch('researchKpiId');
    const enableFields = useMemo(
      () => getEnableProductDetailPropList(kpiGroupId, kpiId, researchKpiGroups),
      [kpiGroupId, kpiId]
    );

    const researchKpiGroupOptions = useMemo(
      () =>
        filterReasearchKpiGroups(
          getResearchKpiGroup(researchKpis),
          TYPE.findKpiGroupsByShort(productTypeShort)
        ),
      [productTypeShort, researchKpis]
    );
    // #endregion Data

    // #region Event
    const showError = (message) => {
      toast.current.show({
        severity: 'error',
        summary: t('toast.error'),
        detail: message,
        life: 4000,
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        open: (_productControl) => {
          setSoftProductControl(_productControl);
          setVisible(true);
          if (_productControl.type === 'INSERT') {
            reset({});
          } else if (_productControl.type === 'UPDATE') {
            reset(_productControl.value);
          }
        },
      }),
      []
    );

    const handleAddProduct = async () => {
      const isValidTrigger = await trigger();
      if (!isValidTrigger) {
        showError(t('errorMessage.validationErrorMessage'));
        return;
      }

      const data = getValues();

      const { insert, update, index, type } = productControl;

      if (type === 'INSERT' && insert) {
        insert(index, data);
      } else if (type === 'UPDATE' && update) {
        update(index, data);
      }
      reset({});
      setVisible(false);
    };

    useEffect(() => {
      // Lọc dữ liệu dựa trên giá trị của ô thứ nhất
      const filtered = researchKpis.filter((item) => item?.researchKpiGroup.id === selectedGroup);
      setFilteredData(filtered);
    }, [selectedGroup]);

    useEffect(() => {
      setSelectedGroup(kpiGroupId);
    }, [kpiGroupId]);

    useEffect(() => {
      setProductSchema(addProductSchema(productTypeShort, enableFields));
    }, [enableFields]);

    // #endregion Event

    return (
      <>
        <Dialog
          header={
            productControl?.type === 'INSERT'
              ? t('submitDocument.productDialog.insert.title')
              : t('submitDocument.productDialog.update.title')
          }
          visible={visible}
          onHide={() => {
            setVisible(false);
          }}
          draggable={false}
          className="w-full sm:w-7 md:w-6 lg:w-5"
        >
          <div className="p-fluid">
            {isEnable(ENUM.doi, enableFields) && (
              <div className="grid">
                <div className="col-2">
                  <TextareaInput
                    name="productDetail.doi"
                    label={t('submitDocument.product.productDetail.doi')}
                    isRequired
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
            )}
            <div className="grid">
              <div className="col-3">
                <Select
                  name="researchKpiGroupId"
                  label={t('submitDocument.product.kpiGroup')}
                  options={researchKpiGroupOptions}
                  isRequired
                  control={control}
                  errors={errors}
                />
              </div>
              <div className="col-9">
                <Select
                  name="researchKpiId"
                  label={t('submitDocument.product.kpi')}
                  options={filteredData}
                  isRequired
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            {isEnable(ENUM.title, enableFields) && (
              <div className="grid">
                <div className="col-12">
                  <TextareaInput
                    name="productDetail.title"
                    label={t('submitDocument.product.productDetail.title')}
                    isRequired
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
            )}
            <div className="grid">
              {isEnable(ENUM.author, enableFields) && (
                <div className="col-4">
                  <TextareaInput
                    name="productDetail.author"
                    label={t('submitDocument.product.productDetail.author')}
                    isRequired
                    control={control}
                    errors={errors}
                  />
                </div>
              )}

              {isEnable(ENUM.publishedYear, enableFields) && (
                <div className="col-4">
                  <TextareaInput
                    name="productDetail.publishedYear"
                    label={t('submitDocument.product.productDetail.publishedYear')}
                    isRequired
                    control={control}
                    errors={errors}
                  />
                </div>
              )}
              {isEnable(ENUM.rank, enableFields) && (
                <div className="col-4">
                  <TextareaInput
                    name="productDetail.rank"
                    label={t('submitDocument.product.productDetail.rank')}
                    isRequired
                    control={control}
                    errors={errors}
                  />
                </div>
              )}
              {isEnable(ENUM.conference, enableFields) && (
                <div className="col-4">
                  <TextareaInput
                    name="productDetail.conference"
                    label={t('submitDocument.product.productDetail.conference')}
                    isRequired
                    control={control}
                    errors={errors}
                  />
                </div>
              )}
              {isEnable(ENUM.ISSN_ISBN, enableFields) && (
                <div className="col-4">
                  <TextareaInput
                    name="productDetail.ISSN_ISBN"
                    label={t('submitDocument.product.productDetail.ISSN_ISBN')}
                    isRequired
                    control={control}
                    errors={errors}
                  />
                </div>
              )}
              {isEnable(ENUM.patentCode, enableFields) && (
                <div className="col-4">
                  <TextareaInput
                    name="productDetail.patentCode"
                    label={t('submitDocument.product.productDetail.patentCode')}
                    isRequired
                    control={control}
                    errors={errors}
                  />
                </div>
              )}
            </div>
            {isEnable(ENUM.object, enableFields) && (
              <div className="grid">
                <div className="col-12">
                  <TextareaInput
                    name="productDetail.object"
                    label={t('submitDocument.product.productDetail.object')}
                    isRequired
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
            )}

            <div className="grid">
              <div className="col-12">
                <TextareaInput
                  name="productDetail.note"
                  label={t('submitDocument.product.productDetail.note')}
                  isRequired
                  control={control}
                  errors={errors}
                  row={3}
                />
              </div>
            </div>
            <div className="grid">
              <div className="col-12">
                <CustomFileInput
                  name="file"
                  label={t('submitDocument.product.evidenceFile')}
                  defaultNameFile={`${formatFileNameProduct(
                    'a.pdf',
                    productControl?.projectCode,
                    productTypeShort,
                    !productControl?.index ? 1 : parseInt(productControl?.index, 10) + 1
                  )}`}
                  isRequired
                  control={control}
                  errors={errors}
                  accept=".pdf"
                />
                {productControl?.value?.fileUrl ? (
                  <span
                    className="p-button p-button-text p-button-sm mt-1 p-0"
                    onClick={
                      () =>
                        handleDownloadFile(
                          productControl?.value?.fileUrl,
                          formatFileNameProduct(
                            productControl?.value?.fileUrl || '',
                            productControl?.projectCode,
                            productTypeShort,
                            !productControl?.index ? 1 : parseInt(productControl?.index, 10) + 1
                          )
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                    <span className="text-blue-500 underline font-italic ml-1">
                      {formatFileNameProduct(
                        productControl?.value?.fileUrl || '',
                        productControl?.projectCode,
                        productTypeShort,
                        !productControl?.index ? 1 : parseInt(productControl?.index, 10) + 1
                      )}
                    </span>
                  </span>
                ) : (
                  <span>
                    {productControl?.value?.file?.name &&
                      formatFileNameProduct(
                        productControl?.value?.file?.name || '',
                        productControl?.projectCode,
                        productTypeShort,
                        !productControl?.index ? 1 : parseInt(productControl?.index, 10) + 1
                      )}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-content-end mt-4">
            <Button
              label={t('formLayout.action.cancel')}
              severity="danger"
              onClick={() => {
                setVisible(false);
              }}
              className="mr-1 w-8rem"
            />
            <Button
              label={
                productControl?.type === 'INSERT'
                  ? t('formLayout.action.add')
                  : t('formLayout.action.update')
              }
              type="submit"
              severity="info"
              onClick={handleAddProduct}
              className="w-8rem"
              disabled={!Object.keys(dirtyFields)?.length}
            />
          </div>
        </Dialog>
        <Toast ref={toast} />
      </>
    );
  }
);

ProductDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  researchKpis: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  researchKpiGroups: PropTypes.array.isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
  productTypeShort: PropTypes.string,
};

ProductDialog.defaultProps = {
  productTypeShort: TYPE.SOFT_PRODUCT.short,
};
export default ProductDialog;
