import PropTypes from 'prop-types';
import { FinancialAdjustment } from 'features/Project/pages';
import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

function ViewFinancialAdjustmentPage({ readOnly }) {
  return (
    <LayoutProvider>
      <Layout>
        <FinancialAdjustment readOnly={readOnly} />
      </Layout>
    </LayoutProvider>
  );
}

ViewFinancialAdjustmentPage.propTypes = {
  readOnly: PropTypes.bool,
};

ViewFinancialAdjustmentPage.defaultProps = {
  readOnly: false,
};

export default ViewFinancialAdjustmentPage;
