/* eslint-disable react/jsx-curly-newline */
import {
  TextareaInput,
  NumberInput,
  DatePicker,
  CurrencyInput,
  Select,
} from 'components/FormControl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatFileNameProduct } from 'utils/func';
import CustomFileInput from 'components/FormControl/CustomFileInput';
import { addTransferTechnologySchema } from 'features/Project/validation';
import { yupResolver } from '@hookform/resolvers/yup';

const TransferTechnologyDialog = forwardRef((props, ref) => {
  // #region Data
  const { t } = useTranslation();
  const toast = useRef(null);

  const [transferTechnologyControl, setTransferTechnologyControl] = useState();
  const [visible, setVisible] = useState(false);

  const {
    control,
    getValues,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({ mode: 'onChange', resolver: yupResolver(addTransferTechnologySchema) });
  const researchKpiGroupOptions = useMemo(
    () => props.researchKpiGroups.filter((rkGroup) => rkGroup.name === 'K10'),
    [props.researchKpiGroups]
  );
  // console.log('researchKpiGroupOptions', researchKpiGroupOptions);
  // #endregion Data

  // #region Event
  const showError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: t('toast.error'),
      detail: message,
      life: 4000,
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      open: (_transferTechnologyControl) => {
        setTransferTechnologyControl(_transferTechnologyControl);
        setVisible(true);
        if (_transferTechnologyControl.type === 'INSERT') {
          reset({});
        } else if (_transferTechnologyControl.type === 'UPDATE') {
          reset(_transferTechnologyControl.value);
        }
      },
    }),
    []
  );

  const handleAddTrainingResult = async () => {
    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      showError(t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = getValues();

    const { insert, update, index, type } = transferTechnologyControl;

    if (type === 'INSERT' && insert) {
      insert(index, data);
    } else if (type === 'UPDATE' && update) {
      update(index, data);
    }
    reset({});
    setVisible(false);
  };

  // #endregion Event

  return (
    <>
      <Dialog
        header={
          transferTechnologyControl?.type === 'INSERT'
            ? t('submitDocument.transferTechnologyDialog.insert.title')
            : t('submitDocument.transferTechnologyDialog.update.title')
        }
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        draggable={false}
        className="w-full sm:w-7 md:w-6 lg:w-5"
      >
        <div className="p-fluid">
          <div className="grid">
            <div className="col-3">
              <Select
                name="researchKpiGroupId"
                label={t('submitDocument.product.kpiGroup')}
                options={researchKpiGroupOptions}
                isRequired
                control={control}
                errors={errors}
              />
            </div>
            <div className="col-9">
              <Select
                name="researchKpiId"
                label={t('submitDocument.product.kpi')}
                options={researchKpiGroupOptions[0]?.researchKpis}
                isRequired
                control={control}
                errors={errors}
              />
            </div>
          </div>

          <div className="grid">
            <div className="col-12">
              <TextareaInput
                name="name"
                label={t('r05.b.b2.technologyOrSolution.name')}
                isRequired
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <div className="grid">
            <div className="col-3">
              <NumberInput
                name="transferredYear"
                label={t('r05.b.b2.technologyOrSolution.year')}
                isRequired
                control={control}
                errors={errors}
              />
            </div>
            <div className="col-9">
              <TextareaInput
                name="partner"
                label={t('r05.b.b2.technologyOrSolution.partner')}
                isRequired
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <div className="grid">
            <div className="col-3">
              <DatePicker
                name="contractedDate"
                label={t('r05.b.b2.technologyOrSolution.date')}
                isRequired
                control={control}
                errors={errors}
              />
            </div>
            <div className="col-9">
              <CurrencyInput
                name="revenue"
                label={t('r05.b.b2.technologyOrSolution.revenue')}
                isRequired
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <div className="grid">
            <div className="col-12">
              <TextareaInput
                name="scale"
                label={t('r05.b.b2.technologyOrSolution.scale')}
                isRequired
                control={control}
                errors={errors}
                row={3}
              />
            </div>
          </div>
          <div className="grid">
            <div className="col-12">
              <CustomFileInput
                name="file"
                label="File minh chứng"
                defaultNameFile={`${formatFileNameProduct(
                  'a.pdf',
                  transferTechnologyControl?.projectCode,
                  'CGCN',
                  !transferTechnologyControl?.index
                    ? 1
                    : parseInt(transferTechnologyControl?.index, 10) + 1
                )}`}
                isRequired
                control={control}
                errors={errors}
                accept=".pdf"
              />
              {transferTechnologyControl?.value?.fileUrl ? (
                <span
                  className="p-button p-button-text p-button-sm mt-1 p-0"
                  onClick={() =>
                    props?.handleDownloadFile(
                      transferTechnologyControl?.value?.fileUrl,
                      formatFileNameProduct(
                        transferTechnologyControl?.value?.fileUrl || '',
                        transferTechnologyControl?.projectCode,
                        'CGCN',
                        !transferTechnologyControl?.index
                          ? 1
                          : parseInt(transferTechnologyControl?.index, 10) + 1
                      )
                    )
                  }
                >
                  <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                  <span className="text-blue-500 underline font-italic ml-1">
                    {formatFileNameProduct(
                      transferTechnologyControl?.value?.fileUrl || '',
                      transferTechnologyControl?.projectCode,
                      'CGCN',
                      !transferTechnologyControl?.index
                        ? 1
                        : parseInt(transferTechnologyControl?.index, 10) + 1
                    )}
                  </span>
                </span>
              ) : (
                <span>
                  {transferTechnologyControl?.value?.file?.name &&
                    formatFileNameProduct(
                      transferTechnologyControl?.value?.file?.name || '',
                      transferTechnologyControl?.projectCode,
                      'CGCN',
                      !transferTechnologyControl?.index
                        ? 1
                        : parseInt(transferTechnologyControl?.index, 10) + 1
                    )}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-content-end mt-4">
          <Button
            label={t('formLayout.action.cancel')}
            severity="danger"
            onClick={() => {
              setVisible(false);
            }}
            className="mr-1 w-8rem"
          />
          <Button
            label={
              transferTechnologyControl?.type === 'INSERT'
                ? t('formLayout.action.add')
                : t('formLayout.action.update')
            }
            type="submit"
            severity="info"
            onClick={handleAddTrainingResult}
            className="w-8rem"
            disabled={!Object.keys(dirtyFields)?.length}
          />
        </div>
      </Dialog>
      <Toast ref={toast} />
    </>
  );
});

TransferTechnologyDialog.propTypes = {
  handleDownloadFile: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  researchKpiGroups: PropTypes.array.isRequired,
};

export default TransferTechnologyDialog;
