export const formDefaultValue = {
  general: {},
};

export const defaultValues = {
  foreignLanguage: {
    name: '',
    listening: null,
    speaking: null,
    writing: null,
    reading: null,
  },
  academicHistory: {
    start: null,
    end: null,
    address: '',
    specialty: '',
    thesisName: '',
    academicDegree: '',
  },
  workHistory: {
    start: null,
    end: null,
    address: '',
    position: '',
  },
  project: {
    name: '',
    code: '',
    managementLevel: '',
    implementationMonths: 0,
    expenditure: 0,
    role: '',
    acceptanceDate: null,
    result: '',
  },
  thesisGuidance: {
    studentTarget: '',
    studentName: '',
    studentId: '',
    thesisName: '',
    graduationYear: new Date().getFullYear(),
    academicDegree: '',
    projectCode: '',
  },

  domesticBook: {
    title: '',
    projectCode: '',
    publisher: '',
    year: new Date().getFullYear(),
    authors: '',
    pseudonym: '',
    publicationType: 'DOMESTIC',
  },

  domesticPaper: {
    title: '',
    projectCode: '',
    year: new Date().getFullYear(),
    authors: '',
    journal: '',
    journalAbbrev: '',
    pages: '',
    issn: '',
    isbn: '',
    isIsi: null,
    if: null,
    pseudonym: '',
    publicationType: 'DOMESTIC',
  },
  award: {
    name: '',
    content: '',
    placeOfIssue: '',
    yearOfIssue: new Date().getFullYear(),
  },
  patent: {
    name: '',
    projectCode: '',
    number: '',
    placeOfIssue: '',
    yearOfIssue: new Date().getFullYear(),
    contributorRole: '',
  },
  usefulSolution: {
    name: '',
    projectCode: '',
    number: '',
    placeOfIssue: '',
    yearOfIssue: new Date().getFullYear(),
    contributorRole: '',
  },
  transferredSolution: {
    name: '',
    form: '',
    scale: '',
    address: '',
    yearOfTransfer: new Date().getFullYear(),
    projectCode: '',
  },
};
