// eslint-disable-next-line import/prefer-default-export
export const maxPoints = {
  scoreResponse: 40,
  scoreTarget: 10,
  scoreContent: 10,
  scoreApproach: 10,
  scoreProcess: 10,
  scoreQuantityAndQuality: 50,
  scoreApplicationProduct: 30,
  scorePublishedProduct: 10,
  scoreTrainingProduct: 10,
  scoreReportQuality: 10,
};
