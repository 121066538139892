import * as Yup from 'yup';

export const academicHistorySchema = Yup.object().shape({
  start: Yup.string().required('formControl.validation.required'),
  end: Yup.string().required('formControl.validation.required'),
  address: Yup.string().required('formControl.validation.required'),
  major: Yup.string().required('formControl.validation.required'),
  thesisName: Yup.string().nullable(),
  academicDegree: Yup.string().required('formControl.validation.required'),
});

export const workHistorySchema = Yup.object().shape({
  start: Yup.string().required('formControl.validation.required'),
  end: Yup.string().required('formControl.validation.required'),
  address: Yup.string().required('formControl.validation.required'),
  position: Yup.string().nullable(),
});

export const researchActivitySchema = Yup.object().shape({
  name: Yup.string().required('formControl.validation.required'),
  code: Yup.string().required('formControl.validation.required'),
  managementLevel: Yup.string()
    .oneOf(['state', 'ministry', 'vnuhcm', 'province', 'university'])
    .required('formControl.validation.required'),
  // implementationMonths: Yup.number().min(0).required('formControl.validation.required'),
  start: Yup.string().required('formControl.validation.required'),
  end: Yup.string().nullable(),
  expenditure: Yup.number().min(0).required('formControl.validation.required'),
  //   role: Yup.string().oneOf(['chair', 'participate'])
  //   .required('formControl.validation.required'),
  role: Yup.string()
    .oneOf([
      'owner',
      'secretary',
      'mainParticipant',
      'participant',
      'technician',
      'domesticExpert',
      'internationalExpert',
    ])
    .required('formControl.validation.required'),
  acceptanceDate: Yup.string().required('formControl.validation.required'),
  result: Yup.string().oneOf(['excellent', 'veryGood', 'good', 'pass', 'fail']).nullable(),
});

export const guidingActivitySchema = Yup.object().shape({
  studentTarget: Yup.string()
    .oneOf(['student', 'postgraduateStudent', 'researchStudent'])
    .notRequired('formControl.validation.required'),
  studentName: Yup.string().required('formControl.validation.required'),
  studentId: Yup.string().notRequired('formControl.validation.required'),
  thesisName: Yup.string().required('formControl.validation.required'),
  graduationYear: Yup.number('formControl.validation.required')
    .integer()
    .min(0)
    .required('formControl.validation.required'),
  academicDegree: Yup.string().required('formControl.validation.required'),
  projectCode: Yup.string().notRequired('formControl.validation.required'),
});

// export const bookSchema = Yup.object().shape({
//   title: Yup.string().required('formControl.validation.required'),
//   projectCode: Yup.string().required('formControl.validation.required'),
//   publisher: Yup.string().required('formControl.validation.required'),
//   year: Yup.number('formControl.validation.required')
//     .integer()
//     .min(0)
//     .required('formControl.validation.required'),
//   authors: Yup.string().required('formControl.validation.required'),
//   // authors: Yup.string().oneOf(['author', 'coAuthor'])
//   // .required('formControl.validation.required'),
//   pseudonym: Yup.string().required('formControl.validation.required'),
//   publicationType: Yup.string()
//     .oneOf(['DOMESTIC', 'INTERNATIONAL'])
//     .required('formControl.validation.required'),
// });

export const domesticBookSchema = Yup.object().shape({
  domesticBook: Yup.object()
    .test('titleRequired', 'formControl.validation.required', (data) => !data || data?.title)
    .nullable(),
  book: Yup.object()
    .shape({
      title: Yup.string().required('formControl.validation.required').nullable(),
      projectCode: Yup.string().nullable(),
      publisher: Yup.string().required('formControl.validation.required').nullable(),
      year: Yup.number('formControl.validation.required')
        .integer()
        .min(0)
        .required('formControl.validation.required')
        .nullable(),
      publicationType: Yup.string()
        .oneOf(['DOMESTIC', 'INTERNATIONAL'])
        .required('formControl.validation.required')
        .nullable(),
    })
    .nullable(),
  role: Yup.string().oneOf(['author', 'coAuthor']).required('formControl.validation.required'),
  // authors: Yup.string().oneOf(['author', 'coAuthor'])
  // .required('formControl.validation.required'),
  pseudonym: Yup.string().nullable(),
});

export const internationalBookSchema = Yup.object().shape({
  internationalBook: Yup.object()
    .test('titleRequired', 'formControl.validation.required', (data) => !data || data?.title)
    .nullable(),
  book: Yup.object()
    .shape({
      title: Yup.string().required('formControl.validation.required').nullable(),
      projectCode: Yup.string().nullable(),
      publisher: Yup.string().required('formControl.validation.required').nullable(),
      year: Yup.number('formControl.validation.required')
        .integer()
        .min(0)
        .required('formControl.validation.required')
        .nullable(),
      publicationType: Yup.string()
        .oneOf(['DOMESTIC', 'INTERNATIONAL'])
        .required('formControl.validation.required')
        .nullable(),
    })
    .nullable(),
  role: Yup.string().oneOf(['author', 'coAuthor']).required('formControl.validation.required'),
  // authors: Yup.string().oneOf(['author', 'coAuthor'])
  // .required('formControl.validation.required'),
  pseudonym: Yup.string().nullable(),
});

// export const paperSchema = Yup.object().shape({});

export const domesticPaperSchema = Yup.object().shape({
  domesticPaper: Yup.object()
    .test('titleRequired', 'formControl.validation.required', (data) => !data || data?.title)
    .nullable(),
  paper: Yup.object()
    .shape({
      title: Yup.string().required('formControl.validation.required'),
      authors: Yup.string().required('formControl.validation.required'),
      // authors: Yup.string().oneOf(['author', 'coAuthor'])
      // .required('formControl.validation.required'),
      journal: Yup.string().required('formControl.validation.required'),
      journalAbbrev: Yup.string().required('formControl.validation.required'),
      pages: Yup.string().required('formControl.validation.required'),
      year: Yup.number('formControl.validation.required')
        .integer()
        .min(0)
        .required('formControl.validation.required'),
      projectCode: Yup.string().nullable(),
      issn: Yup.string().nullable(),
      publicationType: Yup.string()
        .oneOf(['DOMESTIC', 'INTERNATIONAL', 'DOMESTIC_CONFERENCE', 'INTERNATIONAL_CONFERENCE'])
        .required('formControl.validation.required')
        .nullable(),
    })
    .nullable(),
  note: Yup.string().nullable(),
});

export const internationalPaperSchema = Yup.object().shape({
  internationalPaper: Yup.object()
    .test('titleRequired', 'formControl.validation.required', (data) => !data || data?.title)
    .nullable(),
  paper: Yup.object()
    .shape({
      title: Yup.string().required('formControl.validation.required'),
      authors: Yup.string().required('formControl.validation.required'),
      // authors: Yup.string().oneOf(['author', 'coAuthor'])
      // .required('formControl.validation.required'),
      journal: Yup.string().required('formControl.validation.required'),
      journalAbbrev: Yup.string().required('formControl.validation.required'),
      year: Yup.number('formControl.validation.required')
        .integer()
        .min(0)
        .required('formControl.validation.required'),
      doi: Yup.string().nullable(),
      projectCode: Yup.string().nullable(),
      issn: Yup.string(),
      // isIsi: Yup.number('formControl.validation.required')
      //   .oneOf([0, 1])
      //   .required('formControl.validation.required'),
      isIsi: Yup.bool('formControl.validation.required')
        .oneOf([true, false])
        .required('formControl.validation.required'),
      if: Yup.number('formControl.validation.required').nullable(),
      publicationType: Yup.string()
        .oneOf(['DOMESTIC', 'INTERNATIONAL', 'DOMESTIC_CONFERENCE', 'INTERNATIONAL_CONFERENCE'])
        .required('formControl.validation.required')
        .nullable(),
    })
    .nullable(),
});

export const domesticConferencePaperSchema = Yup.object().shape({
  domesticConferencePaper: Yup.object()
    .test('titleRequired', 'formControl.validation.required', (data) => !data || data?.title)
    .nullable(),
  paper: Yup.object()
    .shape({
      // title: Yup.string().required('formControl.validation.required'),
      authors: Yup.string().required('formControl.validation.required'),
      // authors: Yup.string().oneOf(['author', 'coAuthor'])
      // .required('formControl.validation.required'),
      conference: Yup.string().required('formControl.validation.required'),
      start: Yup.string().required('formControl.validation.required'),
      end: Yup.string().required('formControl.validation.required'),
      venue: Yup.string().required('formControl.validation.required'),
      projectCode: Yup.string().nullable(),
      isbn: Yup.string().nullable(),
      publicationType: Yup.string()
        .oneOf(['DOMESTIC', 'INTERNATIONAL', 'DOMESTIC_CONFERENCE', 'INTERNATIONAL_CONFERENCE'])
        .required('formControl.validation.required')
        .nullable(),
    })
    .nullable(),
  note: Yup.string().nullable(),
});

export const internationalConferencePaperSchema = Yup.object().shape({
  internationalConferencePaper: Yup.object()
    .test('titleRequired', 'formControl.validation.required', (data) => !data || data?.title)
    .nullable(),
  paper: Yup.object()
    .shape({
      title: Yup.string().required('formControl.validation.required'),
      authors: Yup.string().required('formControl.validation.required'),
      // authors: Yup.string().oneOf(['author', 'coAuthor'])
      // .required('formControl.validation.required'),
      conference: Yup.string().required('formControl.validation.required'),
      start: Yup.string().required('formControl.validation.required'),
      end: Yup.string().required('formControl.validation.required'),
      venue: Yup.string().required('formControl.validation.required'),
      projectCode: Yup.string().nullable(),
      isbn: Yup.string().nullable(),
      publicationType: Yup.string()
        .oneOf(['DOMESTIC', 'INTERNATIONAL', 'DOMESTIC_CONFERENCE', 'INTERNATIONAL_CONFERENCE'])
        .required('formControl.validation.required')
        .nullable(),
    })
    .nullable(),
  note: Yup.string().nullable(),
});

export const awardSchema = Yup.object().shape({
  name: Yup.string().required('formControl.validation.required'),
  description: Yup.string().required('formControl.validation.required'),
  placeOfIssue: Yup.string().required('formControl.validation.required'),
  yearOfIssue: Yup.number('formControl.validation.required')
    .integer()
    .min(0)
    .required('formControl.validation.required'),
});

export const patentSchema = Yup.object().shape({
  name: Yup.string().required('formControl.validation.required'),
  projectCode: Yup.string().required('formControl.validation.required'),
  number: Yup.string().required('formControl.validation.required'),
  yearOfIssue: Yup.number('formControl.validation.required')
    .integer()
    .min(0)
    .required('formControl.validation.required'),
  placeOfIssue: Yup.string().required('formControl.validation.required'),
  contributorRole: Yup.string()
    .oneOf(['author', 'coAuthor'])
    .required('formControl.validation.required'),
});

export const usefulSolutionSchema = Yup.object().shape({
  name: Yup.string().required('formControl.validation.required'),
  projectCode: Yup.string().required('formControl.validation.required'),
  number: Yup.string().required('formControl.validation.required'),
  yearOfIssue: Yup.number('formControl.validation.required')
    .integer()
    .min(0)
    .required('formControl.validation.required'),
  placeOfIssue: Yup.string().required('formControl.validation.required'),
  contributorRole: Yup.string()
    .oneOf(['author', 'coAuthor'])
    .required('formControl.validation.required'),
});

export const transferredSolutionSchema = Yup.object().shape({
  name: Yup.string().required('formControl.validation.required'),
  form: Yup.string().required('formControl.validation.required'),
  scale: Yup.string().required('formControl.validation.required'),
  address: Yup.string().required('formControl.validation.required'),
  yearOfTransfer: Yup.number('formControl.validation.required')
    .integer()
    .min(0)
    .required('formControl.validation.required'),
  projectCode: Yup.string().required('formControl.validation.required'),
});

export const programAttendanceSchema = Yup.object().shape({
  start: Yup.string().required('formControl.validation.required'),
  end: Yup.string().required('formControl.validation.required'),
  programName: Yup.string().required('formControl.validation.required'),
  position: Yup.string().required('formControl.validation.required'),
});

export const conferenceAttendanceSchema = Yup.object().shape({
  start: Yup.string().required('formControl.validation.required'),
  end: Yup.string().required('formControl.validation.required'),
  conferenceName: Yup.string().required('formControl.validation.required'),
  position: Yup.string().required('formControl.validation.required'),
});

export const universityVisitingStaffSchema = Yup.object().shape({
  start: Yup.string().required('formControl.validation.required'),
  end: Yup.string().required('formControl.validation.required'),
  universityName: Yup.string().required('formControl.validation.required'),
  participationContent: Yup.string().required('formControl.validation.required'),
});

export const updateScientificProfileValidationSchema = Yup.object().shape({
  general: Yup.object().shape({}),
});
