import { PubProjectProposalDetail } from 'features/PubProjectProposal/pages';
import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

function PubProjectProposalDetailPage() {
  return (
    <LayoutProvider>
      <Layout>
        <PubProjectProposalDetail />
      </Layout>
    </LayoutProvider>
  );
}

export default PubProjectProposalDetailPage;
