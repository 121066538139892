import { useTranslation } from 'react-i18next';

export default function FooterInfo() {
  const { t } = useTranslation();
  return (
    <div className="flex justify-content-between mt-4 ">
      <h5 className="m-0 font-semibold">
        {t('extendImplementationTime.R07.signature.managementAgency')}
      </h5>
      <h5 className="m-0 font-semibold">
        {t('extendImplementationTime.R07.signature.leadingOrganization')}
      </h5>
      <div className="flex flex-column align-items-center">
        <p className="m-0">{t('extendImplementationTime.R07.signature.date')}</p>
        <h5 className="m-0 font-semibold">
          {t('extendImplementationTime.R07.signature.projectOwner')}
        </h5>
        <span>{t('extendImplementationTime.R07.signature.note')}</span>
      </div>
    </div>
  );
}
