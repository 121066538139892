import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import AwardsTable from './AwardsTable';
import ConferenceAttendancesTable from './ConferenceAttendancesTable';
import PatentsTable from './PatentsTable';
import ProgramAttendancesTable from './ProgramAttendancesTable';
import TransferredSolutionsTable from './TransferredSolutionsTable';
import UniversityVisitingStaffTable from './UniversityVisitingStaffTable';
import UsefulSolutionsTable from './UsefulSolutionsTable';

export default function OtherInfoForm({ control, errors, shouldUpdateFromDocx }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion Data

  // #region Event
  // #endregion Event
  return (
    <div>
      <h4 className="text-center mb-4">{t('scientificProfile.otherInfo.label')}</h4>

      <p className="m-0 p-0">{t('scientificProfile.otherInfo.award.label')}</p>
      <AwardsTable control={control} errors={errors} shouldUpdateFromDocx={shouldUpdateFromDocx} />
      <p className="m-0 p-0">{t('scientificProfile.otherInfo.patent.label')}</p>
      <PatentsTable control={control} errors={errors} shouldUpdateFromDocx={shouldUpdateFromDocx} />
      <p className="m-0 p-0">{t('scientificProfile.otherInfo.usefulSolution.label')}</p>
      <UsefulSolutionsTable
        control={control}
        errors={errors}
        shouldUpdateFromDocx={shouldUpdateFromDocx}
      />
      <p className="m-0 p-0">{t('scientificProfile.otherInfo.transferredSolution.label')}</p>
      <TransferredSolutionsTable
        control={control}
        errors={errors}
        shouldUpdateFromDocx={shouldUpdateFromDocx}
      />

      {/*  */}

      <p className="m-0 p-0">{t('scientificProfile.otherInfo.programAttendance.label')}</p>
      <ProgramAttendancesTable
        control={control}
        errors={errors}
        shouldUpdateFromDocx={shouldUpdateFromDocx}
      />
      <p className="m-0 p-0">{t('scientificProfile.otherInfo.conferenceAttendance.label')}</p>
      <ConferenceAttendancesTable
        control={control}
        errors={errors}
        shouldUpdateFromDocx={shouldUpdateFromDocx}
      />
      <p className="m-0 p-0">{t('scientificProfile.otherInfo.universityVisitingStaff.label')}</p>
      <UniversityVisitingStaffTable
        control={control}
        errors={errors}
        shouldUpdateFromDocx={shouldUpdateFromDocx}
      />
    </div>
  );
}

OtherInfoForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  shouldUpdateFromDocx: PropTypes.bool,
};

OtherInfoForm.defaultProps = {
  shouldUpdateFromDocx: false,
};
