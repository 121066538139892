import './style.scss';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  downloadDocxFile,
  downloadDocxTemplate,
  downloadPdfFile,
  downloadPdfTemplate,
  getReviewerInfo,
  getReviewDetail,
  submitAcceptanceReview,
  updateAcceptanceReview,
} from 'apis/acceptanceReviews.api';

import ErrorDialog from 'components/ErrorDialog';
import Loading from 'components/Loading';
import { TOAST } from 'constant';
import { toast } from 'layout';

import { DetailInfo } from 'features/FinancialReview/components';
import { EvaluationForm, EvaluateTable } from 'features/AcceptanceReview/components';
import { createAcceptanceReviewValidationSchema } from 'features/AcceptanceReview/validation';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { EditorInput } from 'components/FormControl';

import {
  isMemberAllowedReview,
  getAcadamicLevelShortHand,
  toNonAccentVietnamese,
} from 'utils/func';

export default function AcceptanceReviewFormEdit() {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');
  const downloadButtonRef = useRef();
  const downloadTemplateButtonRef = useRef();

  const [isVisibleErrorDialog, setIsVisibleDialogError] = useState(false);
  const [isVisibleSubmitDialog, setIsVisibleSubmitDialog] = useState(false);

  const {
    data: acceptanceReviewData,
    isLoading: isLoadingAcceptanceReview,
    refetch: refetchAcceptanceReview,
  } = useQuery(['acceptance-review', councilMemberId, councilId, projectProposalId], () =>
    getReviewDetail(councilMemberId, projectProposalId, councilId)
  );
  const acceptanceReview = useMemo(() => acceptanceReviewData?.data, [acceptanceReviewData]);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(createAcceptanceReviewValidationSchema) });

  const { isDirty, dirtyFields } = useFormState({ control });

  const {
    mutate: mutateUpdate,
    error: errorRes,
    isError: isErrorRes,
    isLoading: isLoadingCreate,
  } = useMutation(updateAcceptanceReview);

  const {
    mutate: mutateSubmit,
    error: errorSubmit,
    isError: isErrorSubmit,
  } = useMutation(submitAcceptanceReview);

  const onConfirmSubmit = () => {
    const submitData = {
      projectProposalId,
      councilId,
      councilMemberId,
    };

    mutateSubmit(submitData, {
      onSuccess: () => {
        reset({}, { keepValues: true });
        toast(TOAST.SUCCESS, t('council.review.updateSuccess'));
        setIsVisibleSubmitDialog(false);
        setTimeout(() => {
          navigate('/project-proposal-defense');
        }, 1500);
      },
      onError: () => {
        setIsVisibleDialogError(true);
      },
    });
  };

  const footerConfirmSubmit = () => (
    <div>
      <Button
        label={t('formLayout.action.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={() => setIsVisibleSubmitDialog(false)}
        autoFocus
      />
      <Button
        label={t('formLayout.action.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirmSubmit}
      />
    </div>
  );

  // Fill available data to form
  useEffect(() => {
    if (acceptanceReview?.acceptanceReview) {
      const { acceptanceReview: review } = acceptanceReview;
      const {
        scoreTarget,
        scoreContent,
        scoreApproach,
        scoreProcess,
        scoreApplicationProduct,
        scorePublishedProduct,
        scoreTrainingProduct,
        scoreReportQuality,
      } = review;
      const scoreResponse = scoreTarget + scoreContent + scoreApproach + scoreProcess;
      const scoreQuantityAndQuality = parseInt(
        scoreApplicationProduct + scorePublishedProduct + scoreTrainingProduct,
        10
      );
      setValue('reviewMeet', review.reviewMeet);
      setValue('reviewProduct', review.reviewProduct);
      setValue('reviewReportQuality', review.reviewReportQuality);
      setValue('reviewConclusion', review.reviewConclusion);
      setValue('score.scoreApproach', review.scoreApproach);
      setValue('score.scoreTarget', scoreTarget);
      setValue('score.scoreContent', scoreContent);
      setValue('score.scoreProcess', scoreProcess);
      setValue('score.scoreResponse', scoreResponse);
      setValue('score.scoreApplicationProduct', scoreApplicationProduct);
      setValue('score.scorePuslishedProduct', scorePublishedProduct);
      setValue('score.scoreTrainingProduct', scoreTrainingProduct);
      setValue('score.scoreQuantityAndQuality', scoreQuantityAndQuality);
      setValue('score.scoreReportQuality', scoreReportQuality);

      reset({}, { keepValues: true });
    }
  }, [acceptanceReview, acceptanceReview?.acceptanceReview, setValue]);

  // A. General Information
  const { data: infoData } = useQuery(['acceptance-reviewer'], () =>
    getReviewerInfo(projectProposalId, councilId)
  );
  const info = useMemo(() => infoData?.data, [infoData]);
  // #endregion

  // #region Events
  const onSubmit = async (data) => {
    const reviewMeet = data?.reviewMeet;
    const reviewProduct = data?.reviewProduct;
    const reviewReportQuality = data?.reviewReportQuality;
    const reviewConclusion = data?.reviewConclusion;
    const scoreTarget = data?.score?.scoreTarget;
    const scoreContent = data?.score?.scoreContent;
    const scoreApproach = data?.score?.scoreApproach;
    const scoreProcess = data?.score?.scoreProcess;
    const scoreApplicationProduct = data?.score?.scoreApplicationProduct;
    const scorePublishedProduct = data?.score?.scorePublishedProduct;
    const scoreTrainingProduct = data?.score?.scoreTrainingProduct;
    const scoreReportQuality = data?.score?.scoreReportQuality;

    const submitData = {
      projectProposalId,
      councilId,
      councilMemberId,
      reviewMeet,
      reviewProduct,
      reviewReportQuality,
      reviewConclusion,
      scoreTarget,
      scoreContent,
      scoreApproach,
      scoreProcess,
      scoreApplicationProduct,
      scorePublishedProduct,
      scoreTrainingProduct,
      scoreReportQuality,
    };

    mutateUpdate(submitData, {
      onSuccess: () => {
        reset({}, { keepValues: true });
        toast(TOAST.SUCCESS, t('council.review.updateSuccess'));
        refetchAcceptanceReview();
      },
      onError: () => {
        setIsVisibleDialogError(true);
      },
    });
  };

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('formLayout.action.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('formLayout.action.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );

  const {
    refetch: handleDownloadPdfFile,
    isLoading: isDownloadPdfFileLoading,
    isFetching: isDownloadPdfFileFetching,
  } = useQuery(
    ['downloadPdfFileAcceptanceReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const academicLevelShorthand = getAcadamicLevelShortHand(
            info?.user?.scientificProfile?.academicRank,
            info?.user?.scientificProfile?.academicDegree,
            t,
            true
          );
          const filename = `Phieu nhan xet danh gia nghiem thu - ${academicLevelShorthand}${toNonAccentVietnamese(
            info?.user?.fullname
          )}.pdf`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxFile,
    isLoading: isDownloadDocxFileLoading,
    isFetching: isDownloadDocxFileFetching,
  } = useQuery(
    ['downloadDocxFileAcceptanceReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const academicLevelShorthand = getAcadamicLevelShortHand(
            info?.user?.scientificProfile?.academicRank,
            info?.user?.scientificProfile?.academicDegree,
            t,
            true
          );
          const filename = `Phieu nhan xet danh gia nghiem thu - ${academicLevelShorthand}${toNonAccentVietnamese(
            info?.user?.fullname
          )}.docx`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadPdfTemplate,
    isLoading: isDownloadPdfTemplateLoading,
    isFetching: isDownloadPdfTemplateFetching,
  } = useQuery(
    ['downloadPdfTemplateAcceptanceReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M08 - Phieu nhan xet danh gia nghiem thu.pdf';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxTemplate,
    isLoading: isDownloadDocxTemplateLoading,
    isFetching: isDownloadDocxTemplateFetching,
  } = useQuery(
    ['downloadDocxTemplateAcceptanceReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M08 - Phieu nhan xet danh gia nghiem thu.docx';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );
  // #endregion

  return (
    <>
      {isLoadingAcceptanceReview && isLoadingCreate && <Loading />}

      {isErrorRes && (
        <ErrorDialog
          title={t('council.review.update')}
          isError={isErrorRes}
          error={errorRes}
          visible={isVisibleErrorDialog}
          setVisible={setIsVisibleDialogError}
        />
      )}

      {isErrorSubmit && (
        <ErrorDialog
          title={t('council.review.create')}
          isError={isErrorSubmit}
          error={errorSubmit}
          visible={isVisibleErrorDialog}
          setVisible={setIsVisibleDialogError}
        />
      )}

      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields).length}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>

      <Dialog
        visible={isVisibleSubmitDialog}
        header={<p className="text-blue-500">{t('council.submit.confirmationTitle')}</p>}
        position="center"
        style={{ width: '410px' }}
        onHide={() => setIsVisibleSubmitDialog(false)}
        footer={() => footerConfirmSubmit()}
      >
        <p className="text-lg py-3">{t('council.submit.confirmationSubmitMessage')}</p>
      </Dialog>

      <div className="m-0 bg-white px-4 sm:px-4 md:px-4 lg:px-6 xl:px-8 mb-3 py-5 p-fluid relative acceptance-review-edit">
        <div className="flex gap-1 justify-content-end">
          <Button
            label={t('council.review.exportFile')}
            icon={`pi ${
              isDownloadPdfFileLoading ||
              isDownloadPdfFileFetching ||
              isDownloadDocxFileLoading ||
              isDownloadDocxFileFetching
                ? 'pi-spin pi-spinner'
                : 'pi-file'
            }`}
            className="block w-auto h-3rem border-none"
            severity="info"
            onClick={(event) => downloadButtonRef.current.toggle(event)}
            disabled={
              isDownloadPdfFileLoading ||
              isDownloadPdfFileFetching ||
              isDownloadDocxFileLoading ||
              isDownloadDocxFileFetching
            }
          />
          <Button
            // type="button"
            label={t('council.review.downloadTemplate')}
            icon={`pi ${
              isDownloadPdfTemplateLoading ||
              isDownloadPdfTemplateFetching ||
              isDownloadDocxTemplateLoading ||
              isDownloadDocxTemplateFetching
                ? 'pi-spin pi-spinner'
                : 'pi-file'
            }`}
            className="block w-auto h-3rem border-none"
            severity="info"
            onClick={(event) => downloadTemplateButtonRef.current.toggle(event)}
            disabled={
              isDownloadPdfTemplateLoading ||
              isDownloadPdfTemplateFetching ||
              isDownloadDocxTemplateLoading ||
              isDownloadDocxTemplateFetching
            }
          />
        </div>
        <Menu
          ref={downloadButtonRef}
          model={[
            {
              label: t('council.review.exportPdf'),
              icon: 'pi pi-file-pdf',
              command: handleDownloadPdfFile,
              disabled: isDownloadPdfFileLoading || isDownloadPdfFileFetching,
            },
            {
              label: t('council.review.exportDocx'),
              icon: 'pi pi-file-word',
              command: handleDownloadDocxFile,
              disabled: isDownloadDocxFileLoading || isDownloadDocxFileFetching,
            },
          ]}
          popup
        />
        <Menu
          ref={downloadTemplateButtonRef}
          model={[
            {
              label: t('council.review.downloadPdfTemplate'),
              icon: 'pi pi-file-pdf',
              command: handleDownloadPdfTemplate,
              disabled: isDownloadPdfTemplateLoading || isDownloadPdfTemplateFetching,
            },
            {
              label: t('council.review.downloadDocxTemplate'),
              icon: 'pi pi-file-word',
              command: handleDownloadDocxTemplate,
              disabled: isDownloadDocxTemplateLoading || isDownloadDocxTemplateFetching,
            },
          ]}
          popup
        />

        <div className="mb-5 flex justify-content-between align-items-center">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="VNU-HCM logo"
            className="ml-3 h-4rem md:h-5rem"
          />
          <div className="pl-2">
            {t('projectProposal.viewDetail.form')} M08 <br />
            {t('council.m08.projectCode')}
          </div>
        </div>
        <div className="text-center mb-6">
          <h3 className="font-bold mb-0">{t('council.m08.title')}</h3>
          <h3 className="mt-0">{t('council.m08.sciTechProject')}</h3>
        </div>

        <DetailInfo info={info} />

        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <EvaluationForm
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            control={control}
            errors={errors}
          />

          <EvaluateTable
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            control={control}
            errors={errors}
          />

          <h5 className="font-bold">{t('council.m08.b.rank.label')}</h5>
          <ul>
            <li>{t('council.m08.b.rank.excellent')}</li>
            <li>{t('council.m08.b.rank.veryGood')}</li>
            <li>{t('council.m08.b.rank.good')}</li>
            <li>{t('council.m08.b.rank.reached')}</li>
            <li>{t('council.m08.b.rank.notReached')}</li>
          </ul>
          <div className="text-lg mt-3">
            <h5>{t('council.m08.c.conclusion')}</h5>
            <EditorInput name="reviewConclusion" control={control} errors={errors} />
          </div>
          <div className="grid text-lg mt-3">
            <div className="col-7 px-8">
              <span>{t('council.m01.c.commitment')}</span>
            </div>
            <div className="col-5 text-center">
              <p className="mb-0">{t('signature.date')}</p>
              <p className="font-semibold mb-0">{t('signature.reviewer')}</p>
              <p className="mb-7">{t('signature.fullnameAndSignature')}</p>
              <p className="font-bold">{info?.user?.fullname}</p>
            </div>
          </div>

          <div className="flex gap-2 justify-content-end mt-4">
            <Button
              label={t('formLayout.action.close')}
              severity="danger"
              type="button"
              className="w-8rem"
              onClick={() => navigate('/project-proposal-defense')}
            />
            <Button
              label={t('formLayout.action.save')}
              severity="info"
              type="submit"
              className="w-8rem"
              disabled={
                !(isDirty && Object.keys(dirtyFields).length) ||
                !isMemberAllowedReview(info?.user?.councilMembers?.[0]) ||
                acceptanceReview?.reviewDeadline < new Date().toISOString()
              }
              onClick={() => {
                if (Object.keys(errors || {}).length) {
                  toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
                }
              }}
            />
            <Button
              label={t('formLayout.submit')}
              severity="success"
              type="button"
              className="w-8rem"
              disabled={
                (isDirty && Object.keys(dirtyFields).length) ||
                acceptanceReview?.reviewDeadline < new Date().toISOString() ||
                !isMemberAllowedReview(info?.user?.councilMembers?.[0])
              }
              onClick={() => setIsVisibleSubmitDialog(true)}
            />
          </div>
        </form>
      </div>
    </>
  );
}
