import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';

import { modifyScientificProfilePreviewData } from 'utils/func';
import AwardsSection from './AwardsSection';
import GeneralInfoSection from './GeneralInfoSection';
import OtherInfoSection from './OtherInfoSection';
import ResearchAndGuidingActivitiesSection from './ResearchAndGuidingActivitiesSection';
import ScientificWorksSection from './ScientificWorksSection';
import SignatureSection from './SignatureSection';

export default function PreviewForm({ watch }) {
  // #region Data
  const { t } = useTranslation();
  const scientificProfilePreviewRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const [isOpenGuideline, setIsOpenGuideLine] = useState(false);

  const data = watch();
  const previewData = useMemo(() => modifyScientificProfilePreviewData(data) ?? {}, [data]);

  // #endregion Data

  // #region Event

  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);

  const getPageMargins = () => '@page { margin: 0.5in 0.5in 0.2in 0.2in !important; }';
  // #endregion Event

  return (
    <>
      {/* <div className="flex justify-content-end">
        <Button
          type="button"
          label={t('scientificProfile.preview.printPdf')}
          icon="pi pi-print"
          className="block w-auto"
          severity="info"
          onClick={() => null}
        />
      </div> */}

      <Dialog
        visible={isOpenGuideline}
        style={{ maxWidth: '750px' }}
        onHide={() => setIsOpenGuideLine(false)}
      >
        <div className="relative">
          <video
            id="printGuideline"
            width="700"
            height="380"
            preload="auto"
            src="/layout/videos/print-guideline.m4v"
            type="video/mp4"
            autoPlay
            loop
          >
            <track kind="captions" />
          </video>
        </div>
      </Dialog>

      <div
        id="scientificProfilePreview"
        ref={scientificProfilePreviewRef}
        className="m-0 bg-white p-5 p-fluid preview-scientific-profile"
      >
        <style>{getPageMargins()}</style>
        <div className="mb-5 flex justify-content-between">
          <img src="/logo.png" alt="VNU-HCM logo" height="75px" className="ml-3" />
          <span className="text-lg">({t('scientificProfile.preview.form')} 2)</span>
        </div>

        <h3 className="mb-5 text-4xl text-center font-bold">
          {t('scientificProfile.label').toUpperCase()}
          <br />
          <span className="font-normal text-lg text-red-500 font-italic">
            {t('scientificProfile.preview.note')}
          </span>
        </h3>

        {/* <div className="ml-4"> */}
        {/* </div> */}
        <GeneralInfoSection data={previewData?.generalInfoSection} />
        <ResearchAndGuidingActivitiesSection
          data={previewData?.researchAndGuidingActivitiesSection}
        />
        <ScientificWorksSection data={previewData?.scientificWorksSection} />
        <AwardsSection data={previewData?.awardsSection} />
        <OtherInfoSection data={previewData?.otherInfoSection} />

        <SignatureSection />
      </div>
    </>
  );
}

PreviewForm.propTypes = {
  watch: PropTypes.func.isRequired,
};

PreviewForm.defaultProps = {};
