import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

export default function HardProductFields({ products, qualityComparison }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion

  return (
    <div className="mb-4">
      <h5 className="font-semibold">
        {t('projectProposal.researchResult.a10_1.hardProduct.label')}
      </h5>
      <p className="font-italic">{t('projectProposal.researchResult.a10_1.hardProduct.note')}</p>

      <div className="grid">
        <div className="col-12">
          <div
            className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines"
            data-scrollselectors=".p-datatable-wrapper"
          >
            <div className="p-datatable-wrapper">
              <table className="p-datatable-table">
                <thead className="p-datatable-thead">
                  <tr>
                    <th style={{ textAlign: 'center' }} rowSpan="3">
                      {t('projectProposal.researchResult.a10_1.hardProduct.fields.order')}
                    </th>
                    <th style={{ minWidth: '15rem', textAlign: 'center' }} rowSpan="3">
                      {t('projectProposal.researchResult.a10_1.hardProduct.fields.productName')}
                    </th>
                    <th style={{ maxWidth: '10rem', textAlign: 'center' }} rowSpan="3">
                      {t('projectProposal.researchResult.a10_1.hardProduct.fields.qualityCriteria')}
                    </th>
                    <th style={{ maxWidth: '10rem', textAlign: 'center' }} rowSpan="3">
                      {t('projectProposal.researchResult.a10_1.hardProduct.fields.measure')}
                    </th>
                    <th style={{ textAlign: 'center' }} colSpan="3">
                      {t('projectProposal.researchResult.a10_1.hardProduct.fields.qualityLevel')}
                    </th>
                    <th style={{ maxWidth: '10rem', textAlign: 'center' }} rowSpan="3">
                      {t(
                        'projectProposal.researchResult.a10_1.hardProduct.fields.expectedQuantity'
                      )}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'center' }} rowSpan="2">
                      {t(
                        'projectProposal.researchResult.a10_1.hardProduct.fields.evaluativeCriteria'
                      )}
                    </th>
                    <th style={{ textAlign: 'center' }} colSpan="2">
                      {t('projectProposal.researchResult.a10_1.hardProduct.fields.similarModel')}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      {t('projectProposal.researchResult.a10_1.hardProduct.fields.domestic')}
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      {t('projectProposal.researchResult.a10_1.hardProduct.fields.international')}
                    </th>
                  </tr>
                </thead>
                <tbody className="p-datatable-tbody">
                  {products?.map((product, index) => (
                    <tr key={product?.id ?? index}>
                      <td style={{ textAlign: 'center' }}>{index + 1}</td>
                      <td>{product?.name}</td>
                      <td>{product?.qualityCriteria}</td>
                      <td style={{ textAlign: 'center' }}>{product?.measure}</td>
                      <td>{product?.evaluativeCriteria}</td>
                      <td>{product?.domesticModelQuality}</td>
                      <td>{product?.internationalModelQuality}</td>
                      <td style={{ textAlign: 'center' }}>{product?.expectedQuantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 mb-1">
        <div className="font-semibold text-xl">
          {t('projectProposal.researchResult.a10_1.qualityComparison.label')}
        </div>
        <div className="font-italic mb-1">
          ({t('projectProposal.researchResult.a10_1.qualityComparison.note')})
        </div>
        <div>{qualityComparison}</div>
      </div>
    </div>
  );
}

HardProductFields.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  products: PropTypes.array.isRequired,
  qualityComparison: PropTypes.string,
};

HardProductFields.defaultProps = {
  qualityComparison: '',
};
