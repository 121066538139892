import { useFieldArray, useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import InternationalConferencePaperDialog from './InternationalConferencePaperDialog';
import TableScholarDataDialog from '../Dialog/tableScholarData';

export default function InternationalConferencePapersTable({ control, shouldUpdateFromDocx }) {
  // #region Data
  const { t } = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [scholarData, setScholarData] = useState([]);
  const { dirtyFields } = useFormContext();
  const internationalConferencePaperRef = useRef();

  const { fields, insert, update, remove } = useFieldArray({
    control,
    name: 'scientificWorks.internationalConferencePapers',
  });
  // #endregion

  // #region Event
  // #endregion

  return (
    <>
      <div className="form-table mt-2">
        <table className="mb-2">
          <thead>
            <tr>
              <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                {t('formLayout.numericalOrder')}
              </th>
              <th className="text-center" style={{ minWidth: 180, width: '18%' }}>
                {t('scientificProfile.scientificWork.paper.title')}
              </th>
              <th className="text-center" style={{ minWidth: 100, width: '14%' }}>
                {t('scientificProfile.scientificWork.paper.authors')}
              </th>
              <th className="text-center" style={{ minWidth: 100, width: '20%' }}>
                {t('scientificProfile.scientificWork.paper.conference')}
              </th>
              <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                {t('scientificProfile.scientificWork.paper.timePeriod')}
              </th>
              <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                {t('scientificProfile.scientificWork.paper.venue')}
              </th>
              <th className="text-center" style={{ minWidth: 80, width: '6%' }}>
                {t('scientificProfile.scientificWork.paper.projectCode')}
              </th>
              <th className="text-center" style={{ minWidth: 70, width: '7%' }}>
                {t('scientificProfile.scientificWork.paper.isbn')}
              </th>
              <th className="text-center" style={{ minWidth: 80, width: '5%' }}>
                {t('scientificProfile.scientificWork.paper.note')}
              </th>
              <th className="text-center float" style={{ minWidth: 80, width: 80 }}>
                {t('formLayout.action.label')}
              </th>
            </tr>
          </thead>
          <tbody>
            {fields?.map((item, index) => (
              <tr
                key={item.id ?? index}
                className={classNames({
                  'highlighted-uncertain':
                    shouldUpdateFromDocx &&
                    dirtyFields.scientificWorks?.internationalConferencePapers,
                })}
              >
                <td className="text-center">{index + 1}</td>
                <td>{item?.paper?.title}</td>
                <td>{item?.paper?.authors}</td>
                <td>{item?.paper?.conference}</td>
                <td className="text-center">
                  {item?.paper?.start
                    ? new Date(item?.paper?.start).toLocaleDateString('en-GB')
                    : ''}{' '}
                  - {item?.paper?.end ? new Date(item?.paper?.end).toLocaleDateString('en-GB') : ''}
                </td>
                <td>{item?.paper?.venue}</td>
                <td>{item?.paper?.projectCode}</td>
                <td>{item?.paper?.isbn}</td>
                <td>{item?.note}</td>

                <td
                  className={`flex flex-column float text-center p-0 border-none ${
                    index !== 0 ? 'border-top-1' : ''
                  }`}
                >
                  <i
                    onClick={() => {
                      internationalConferencePaperRef.current.open({
                        insert,
                        index: index + 1,
                        type: 'INSERT',
                      });
                    }}
                    className="insert-icon-international-conference-paper pi pi-plus text-primary cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.insert')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".insert-icon-international-conference-paper" />

                  <i
                    onClick={() => {
                      internationalConferencePaperRef.current.open({
                        update,
                        index,
                        value: fields[index],
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon-international-conference-paper pi pi-pencil text-primary cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon-international-conference-paper" />

                  <i
                    onClick={() => remove(index)}
                    className="remove-icon-international-conference-paper pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon-international-conference-paper" />

                  {/* Scholar check status */}
                  {item?.paper?.scopusId && (
                    <td>
                      <Tooltip target=".tag" mouseTrack mouseTrackLeft={10} va />
                      <a
                        href={'https://doi.org/'.concat(item?.paper?.doi)}
                        target="_blank"
                        rel="noreferrer"
                        data-pr-tooltip="This paper is verified by Scopus"
                        data-pr-position="left"
                        className={classNames({
                          'verification-tag': true,
                          tag: true,
                        })}
                      >
                        {t('scientificProfile.scientificWork.verifiedLabel')}
                        <i
                          className="pi pi-check-circle text-white text-center ml-1"
                          style={{ fontSize: '0.7rem' }}
                        />
                      </a>
                    </td>
                  )}

                  {item?.paper?.scholarVerification?.status === 'UNCERTAIN' && (
                    <td>
                      <Tooltip target=".verification-tag" mouseTrack mouseTrackLeft={10} />
                      <span
                        onClick={() => {
                          setScholarData(item?.paper?.scholarVerification?.data);
                          setIsDialogOpen(true);
                        }}
                        data-pr-tooltip={t('scientificProfile.scientificWork.viewVerifyPaper')}
                        data-pr-position="left"
                        className={classNames({
                          'uncertain-tag': true,
                        })}
                      >
                        {t('scientificProfile.scientificWork.uncertainLabel')}
                        <i
                          className="pi pi-external-link text-white text-center ml-1"
                          style={{ fontSize: '0.7rem' }}
                        />
                      </span>
                      <TableScholarDataDialog
                        isOpen={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        scholarData={scholarData}
                      />
                    </td>
                  )}

                  {item?.paper?.scholarVerification?.status === 'NOT_FOUND' && (
                    <td>
                      <Tooltip target=".verification-tag" mouseTrack mouseTrackLeft={10} />
                      <span
                        data-pr-tooltip={t('scientificProfile.scientificWork.viewVerifyPaper')}
                        data-pr-position="left"
                        className={classNames({
                          'not-found-tag': true,
                        })}
                      >
                        {t('scientificProfile.scientificWork.notFoundLabel')}
                        <i
                          className="pi pi-search text-white text-center ml-1"
                          style={{ fontSize: '0.7rem' }}
                        />
                      </span>
                    </td>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {fields?.length === 0 && (
          <div className="flex justify-content-center my-2">
            <i
              onClick={() => {
                internationalConferencePaperRef.current.open({
                  insert,
                  index: 0,
                  type: 'INSERT',
                });
              }}
              className="add-international-conference-paper pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
              data-pr-tooltip={t('formLayout.action.add')}
              data-pr-position="left"
            />
            <Tooltip target=".add-international-conference-paper" />
          </div>
        )}
      </div>

      <InternationalConferencePaperDialog ref={internationalConferencePaperRef} />
    </>
  );
}

InternationalConferencePapersTable.propTypes = {
  control: PropTypes.shape({}).isRequired,
  shouldUpdateFromDocx: PropTypes.bool,
};

InternationalConferencePapersTable.defaultProps = {
  shouldUpdateFromDocx: false,
};
