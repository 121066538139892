import { useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { login, resendEmail } from 'apis/users.api';

import TextInput from 'components/FormControl/TextInput';
import LanguageSelect from 'components/LanguageSelect';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { mapError } from 'utils/func';

import { loginValidationSchema } from '../validation';

const formOptions = { resolver: yupResolver(loginValidationSchema) };

function Login() {
  // #region Data
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isVerified = searchParams.get('verify');
  const navigate = useNavigate();
  const toast = useRef(null);
  const [isInactiveEmail, setIsInactiveEmail] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    trigger,
    formState: { errors, isValid },
  } = useForm(formOptions);

  const { mutate, isLoading } = useMutation(login);
  const {
    mutate: mutateResend,
    isLoading: isResendLoading,
    isSuccess: isResendSuccess,
    isError: isResendError,
    error: resendError,
  } = useMutation(resendEmail);
  // #endregion Data

  // #region Event
  const showError = (errorMessage) => {
    toast.current.show({
      severity: 'error',
      summary: t('toast.error'),
      detail: errorMessage,
      life: 4000,
    });
  };
  const onSubmit = async (loginParam) => {
    mutate(loginParam, {
      onSuccess: () => {
        queryClient.invalidateQueries('projectProposals', { exact: true });
        navigate('/');
      },
      onError: (_error) => {
        showError(mapError(t, _error));
        if (_error.response.data.code === 'accountDeactivated') {
          setIsInactiveEmail(true);
        }
      },
    });
  };
  const handleResendEmail = async () => {
    const email = getValues('email');
    if (email) {
      mutateResend({ email });
    } else {
      await trigger('email');
      showError(t('login.validationErrors.emailRequired'));
    }
  };
  const checkValidation = () => {
    if (!isValid) {
      showError(t('errorMessage.validationErrorMessage'));
    }
  };
  // #endregion Event

  return (
    <>
      <div className="p-4 fixed right-0">
        <LanguageSelect />
      </div>
      <div className="flex align-items-center justify-content-center" style={{ height: '98vh' }}>
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: '420px' }}
        >
          <div className="text-center mb-5">
            <img src="/logo.png" alt="hyper" height={50} />
            <p className="text-900 text-xl font-medium mb-3 uppercase">{t('websiteName')}</p>
            <h4 className="text-900 text-3xl font-medium mb-3 uppercase">{t('login.login')}</h4>
            <span to="/signup" className="text-600 font-medium line-height-3">
              {t('login.dontHaveAccount')}
            </span>
            <Link
              to="/signup"
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
            >
              {t('login.createAccount')}
            </Link>
            {isVerified && (
              <h5 className="text-green-700 bg-green-100 p-2">
                {t('login.verifySuccess')}
                <br /> {t('login.loginAgain')}
              </h5>
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <TextInput
              label="Email"
              name="email"
              type="text"
              autoFocus
              control={control}
              errors={errors}
              isRequired
            />

            <TextInput
              label={t('login.password')}
              name="password"
              type="password"
              control={control}
              errors={errors}
              isRequired
            />

            {isInactiveEmail && (
              <div className="mb-2">
                <p className="text-red-500 font-semibold text-center">
                  {t('errorMessage.accountDeactivated')}
                </p>
                <Button
                  label={t('verify.resend')}
                  icon={classNames(
                    'pi',
                    { 'pi-spin pi-spinner': isResendLoading },
                    { 'pi-check': isResendSuccess },
                    { 'pi-undo': !isResendSuccess }
                  )}
                  severity="info"
                  onClick={handleResendEmail}
                  type="button"
                />
                {isResendError && (
                  <p className="text-red-400 text-base mt-2">{mapError(t, resendError)}</p>
                )}
              </div>
            )}

            <div className="flex justify-content-end mb-6">
              <Link
                to="/forgot-password"
                className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
              >
                {t('login.forgotPassword')}
              </Link>
            </div>

            <Button
              label={t('login.login')}
              icon="pi pi-user"
              className="w-full bg-cyan-600 border-cyan-600 hover:bg-cyan-700"
              type="submit"
              onClick={checkValidation}
            />
          </form>
          <div className="flex flex-column align-items-center">
            <p className="m-1 pl-4 font-semibold">{t('login.or')}</p>
            <a
              href={`${process.env.REACT_APP_API_URL}/users/auth/google`}
              className="flex p-button w-full justify-content-between"
            >
              <i className="pi pi-google" />
              <span className="flex flex-1 justify-content-center font-bold">
                {t('login.googleLogin')}
              </span>
            </a>
          </div>
        </div>
        {isLoading && <Loading />}
        <Toast ref={toast} />
      </div>
    </>
  );
}

export default Login;
