export const modifyPublicProfileAbout = (data) => {
  if (!data) return null;
  const contact = {
    fullname: data?.fullname,
    organizationId: data?.organization?.id,
    organizationName: data?.organization?.name,
    organizationEnglishName: data?.organization?.englishName,
  };

  const universities = data?.scientificProfile?.academicHistory?.universities?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));
  const masters = data?.scientificProfile?.academicHistory?.masters?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));
  const doctorates = data?.scientificProfile?.academicHistory?.doctorates?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));
  const scienceDoctorates = data?.scientificProfile?.academicHistory?.scienceDoctorates?.map(
    (item) => ({
      ...item,
      start: item?.start ? new Date(item?.start) : null,
      end: item?.end ? new Date(item?.end) : null,
    })
  );

  const workHistory = data?.scientificProfile?.workHistory?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));

  const general = {
    ...data?.scientificProfile,
    dob: data?.scientificProfile?.dob ? new Date(data?.scientificProfile?.dob) : null,
    academicHistory: {
      universities,
      masters,
      doctorates,
      scienceDoctorates,
    },
    workHistory,
  };

  return { ...contact, ...general };
};

export const modifyPublicProfileResearchAndTeaching = (data) => {
  if (!data) return null;

  const researchActivities = data?.scientificProfile?.projects?.map((item) => ({
    ...item,
    acceptanceDate: item?.acceptanceDate ? new Date(item?.acceptanceDate) : null,
  }));

  const guidingActivities = data?.scientificProfile?.thesisGuidances?.map((item) => ({
    ...item,
  }));

  return { researchActivities, guidingActivities };
};

export const modifyPublicScientificWorksData = (data) => {
  if (!data) return null;

  // books
  const internationalBooks = data?.scientificProfile?.scientificProfileBooks
    ?.filter((item) => item?.book?.publicationType === 'INTERNATIONAL')
    .map((item) => ({
      ...item,
      internationalBook: {
        id: item?.book?.id,
        title: item?.book?.title,
      },
      book: {
        id: item?.book?.id,
        title: item?.book?.title,
        projectCode: item?.book?.projectCode,
        publisher: item?.book?.publisher,
        year: item?.book?.year,
        publicationType: item?.book?.publicationType,
      },
    }));
  const domesticBooks = data?.scientificProfile?.scientificProfileBooks
    ?.filter((item) => item?.book?.publicationType === 'DOMESTIC')
    .map((item) => ({
      ...item,
      domesticBook: {
        id: item?.book?.id,
        title: item?.book?.title,
      },
      book: {
        id: item?.book?.id,
        title: item?.book?.title,
        projectCode: item?.book?.projectCode,
        publisher: item?.book?.publisher,
        year: item?.book?.year,
        publicationType: item?.book?.publicationType,
      },
    }));

  // papers
  const internationalPapers = data?.scientificProfile?.scientificProfilePapers
    ?.filter((item) => item?.paper?.publicationType === 'INTERNATIONAL')
    .map((item) => ({
      ...item,
      internationalPaper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
      },
      paper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
        journal: item?.paper?.journal,
        journalAbbrev: item?.paper?.journalAbbrev,
        pages: item?.paper?.pages,
        authors: item?.paper?.authors,
        year: item?.paper?.year,
        projectCode: item?.paper?.projectCode,
        issn: item?.paper?.issn,
        isIsi: item?.paper?.isIsi,
        if: item?.paper?.if,
        publicationType: item?.paper?.publicationType,
        doi: item?.paper?.doi,
        citationCount: item?.paper?.citationCount,
        eid: item?.paper?.eid,
        pageRange: item?.paper?.pageRange,
        scopusId: item?.paper?.scopusId,
        pii: item?.paper?.pii,
      },
    }));
  const domesticPapers = data?.scientificProfile?.scientificProfilePapers
    ?.filter((item) => item?.paper?.publicationType === 'DOMESTIC')
    .map((item) => ({
      ...item,
      domesticPaper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
      },
      paper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
        journal: item?.paper?.journal,
        journalAbbrev: item?.paper?.journalAbbrev,
        pages: item?.paper?.pages,
        authors: item?.paper?.authors,
        year: item?.paper?.year,
        projectCode: item?.paper?.projectCode,
        issn: item?.paper?.issn,
        publicationType: item?.paper?.publicationType,
        scholarVerification: item?.paper?.scholarVerification,
      },
    }));
  const internationalConferencePapers = data?.scientificProfile?.scientificProfilePapers
    ?.filter((item) => item?.paper?.publicationType === 'INTERNATIONAL_CONFERENCE')
    .map((item) => ({
      ...item,
      internationalConferencePaper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
      },
      paper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
        authors: item?.paper?.authors,
        conference: item?.paper?.conference,
        pages: item?.paper?.pages,
        start: item?.paper?.start ? new Date(item?.paper?.start) : null,
        end: item?.paper?.end ? new Date(item?.paper?.end) : null,
        venue: item?.paper?.venue,
        projectCode: item?.paper?.projectCode,
        isbn: item?.paper?.isbn,
        publicationType: item?.paper?.publicationType,
        doi: item?.paper?.doi,
        citationCount: item?.paper?.citationCount,
        eid: item?.paper?.eid,
        pageRange: item?.paper?.pageRange,
        scopusId: item?.paper?.scopusId,
        pii: item?.paper?.pii,
      },
    }));
  const domesticConferencePapers = data?.scientificProfile?.scientificProfilePapers
    ?.filter((item) => item?.paper?.publicationType === 'DOMESTIC_CONFERENCE')
    .map((item) => ({
      ...item,
      domesticConferencePaper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
      },
      paper: {
        id: item?.paper?.id,
        title: item?.paper?.title,
        authors: item?.paper?.authors,
        conference: item?.paper?.conference,
        pages: item?.paper?.pages,
        start: item?.paper?.start ? new Date(item?.paper?.start) : null,
        end: item?.paper?.end ? new Date(item?.paper?.end) : null,
        venue: item?.paper?.venue,
        projectCode: item?.paper?.projectCode,
        isbn: item?.paper?.isbn,
        publicationType: item?.paper?.publicationType,
        scholarVerification: item?.paper?.scholarVerification,
      },
    }));

  return {
    internationalBooks,
    domesticBooks,
    papers: [
      ...internationalPapers,
      ...domesticPapers,
      ...internationalConferencePapers,
      ...domesticConferencePapers,
    ],
  };
};
export const modifyPublicAwardsData = (data) => {
  if (!data) return null;

  const awards = data?.scientificProfile?.awards?.map((item) => ({ ...item }));

  const patents = data?.scientificProfile?.patents?.map((item) => ({ ...item }));

  const usefulSolutions = data?.scientificProfile?.usefulSolutions?.map((item) => ({ ...item }));

  const transferredSolutions = data?.scientificProfile?.transferredSolutions?.map((item) => ({
    ...item,
  }));

  return {
    awards,
    patents,
    usefulSolutions,
    transferredSolutions,
  };
};

export const modifyPublicProfileOthersInfo = (data) => {
  if (!data) return null;

  const programAttendances = data?.scientificProfile?.programAttendances?.map((item) => ({
    ...item,
  }));

  const conferenceAttendances = data?.scientificProfile?.conferenceAttendances?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));

  const universityVisitingStaff = data?.scientificProfile?.universityVisitingStaff?.map((item) => ({
    ...item,
    start: item?.start ? new Date(item?.start) : null,
    end: item?.end ? new Date(item?.end) : null,
  }));

  return {
    programAttendances,
    conferenceAttendances,
    universityVisitingStaff,
  };
};
