import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { downloadFile } from 'apis/projectProposals.api';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { formatFileName, toCamelCaseString } from 'utils/func';

import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CompleteExplanation from '../CompleteExplanation';

const ViewRequests = forwardRef(({ projectProposalId, refetchProjectProposals }, ref) => {
  // #region Data
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [requestControl, setRequestControl] = useState(null);
  const { mutate: mutateDownloadFile } = useMutation(downloadFile);
  const dialogCompleteExplanationRef = useRef();
  // #endregion Data

  // #region Event
  useImperativeHandle(
    ref,
    () => ({
      open: (_requestControl) => {
        setRequestControl(_requestControl);
        setVisible(true);
      },
    }),
    []
  );
  // eslint-disable-next-line no-unused-vars
  const handleDownloadFile = (path) => {
    mutateDownloadFile(
      {
        id: projectProposalId,
        path,
      },
      {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = formatFileName(path);

            link.click();
          }
        },
      }
    );
  };
  // #endregion Event

  // #region Render
  const formatIndex = (_, { rowIndex }) => rowIndex + 1;

  const formatCommentFileUrls = (value) => {
    if (value?.commentFileUrls?.length > 0) {
      return (
        <ul className="list-disc">
          {value?.commentFileUrls?.map((item, index) => (
            <li className="-ml-4" key={item?.id ?? index}>
              <span
                className="p-button p-button-text p-button-sm p-0"
                onClick={() => handleDownloadFile(item?.name)}
              >
                <span className="text-blue-500 underline font-italic text-base">
                  {formatFileName(item?.name)}
                </span>
              </span>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const formatRequestType = (value) => {
    if (value?.type) {
      return t(`projectProposal.viewHistory.status.${toCamelCaseString(value.type)}`);
    }

    return null;
  };
  const formatActions = (value) => (
    <div className="flex justify-content-center">
      {value?.type !== 'REQUEST_UPDATE' && (
        <Button
          icon="pi pi-file"
          tooltip="Giải trình"
          tooltipOptions={{ position: 'right', at: 'right top' }}
          onClick={() => {
            dialogCompleteExplanationRef.current.open({ value });
          }}
        />
      )}
      {['REQUEST_UPDATE'].includes(value?.type) && requestControl?.value?.isEditable === 1 && (
        <div className="border-round-lg ml-1" style={{ backgroundColor: '#F59E0B' }}>
          <Tooltip target=".edit-project-proposal" />
          <Link
            className="edit-project-proposal flex align-items-center justify-content-center
            font-bold text-white border-round m-2 w-2rem h-2rem"
            data-pr-tooltip={t('projectProposal.viewList.edit')}
            data-pr-position="right"
            data-pr-at="right top"
            data-pr-my="left center-2"
            to={`/project-proposal/edit/${requestControl?.value?.id}?reviseLogId=${value?.id}`}
          >
            <i className="pi pi-pencil" />
          </Link>
        </div>
      )}
    </div>
  );
  // #endregion Render

  return (
    <>
      <Dialog
        header="Các yêu cầu giải trình"
        visible={visible}
        style={{ width: '900px' }}
        onHide={() => setVisible(false)}
      >
        <DataTable
          value={requestControl?.value?.projectProposalReviseLogs ?? []}
          showGridlines
          scrollable
          emptyMessage={t('projectProposal.requestUpdate.emptyMessage')}
        >
          <Column
            header={t('formLayout.numericalOrder')}
            body={formatIndex}
            className="w-3rem text-center"
          />
          <Column
            header={t('projectProposal.requestUpdate.comment')}
            field="comment"
            className="w-30rem"
            style={{ minWidth: '15rem', whiteSpace: 'pre-wrap' }}
          />
          <Column
            header={t('projectProposal.requestUpdate.commentFile')}
            body={formatCommentFileUrls}
            className="w-20rem"
            style={{ minWidth: '15rem' }}
          />
          <Column
            header={t('projectProposal.requestType')}
            body={formatRequestType}
            className="w-15rem"
            style={{ minWidth: '10rem' }}
          />
          <Column
            header={t('formLayout.action.label')}
            body={formatActions}
            className="w-8rem"
            style={{ minWidth: '8rem' }}
          />
        </DataTable>
      </Dialog>

      <CompleteExplanation
        ref={dialogCompleteExplanationRef}
        refetchProjectProposals={refetchProjectProposals}
        setViewRequestsVisible={setVisible}
      />
    </>
  );
});

export default ViewRequests;

ViewRequests.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  projectProposalId: PropTypes.string,
  refetchProjectProposals: PropTypes.func.isRequired,
};

ViewRequests.defaultProps = {
  projectProposalId: '',
};
