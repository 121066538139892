/* eslint-disable indent */
import React from 'react';
import { EditorInput, Tickbox } from 'components/FormControl';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
export default function ResearchProductTable({
  control,
  setValue,
  // eslint-disable-next-line no-unused-vars
  getValues,
  errors,
  disabled,
  // eslint-disable-next-line no-unused-vars
  projectType,
}) {
  const { t } = useTranslation();
  const handleCheck = (name, value) => {
    const token = name.split('.');
    const [, key] = token;
    let otherKeys = [];
    switch (key) {
      case 'isQualified':
        otherKeys = ['isNotQualified'];
        break;
      case 'isNotQualified':
        otherKeys = ['isQualified'];
        break;
      default:
        otherKeys = [];
        break;
    }
    if (otherKeys?.length && value === true) {
      otherKeys.forEach((otherKey) => {
        setValue(`researchProduct.${otherKey}`, false);
      });
    }
  };
  return (
    <div className="form-table">
      <h5>{t('council.m01b.a.a2.label')}</h5>
      <div>{t('council.m01b.a.a2.note')}</div>
      <table>
        <thead>
          <tr>
            <th style={{ width: '40%' }}>{t('council.m01b.a.a2.table.headerA')}</th>
            <th style={{ width: '40%' }}>{t('council.m01b.a.a2.table.headerB')}</th>
            <th style={{ width: '20%' }}>{t('council.m01b.evaluates.header')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="m-2" style={{ whiteSpace: 'pre-wrap' }}>
                {t('council.m01b-2024.a.a2.table.criteriaA')}
              </div>
            </td>
            <td>
              <div className="m-2 " style={{ whiteSpace: 'pre-wrap' }}>
                {t('council.m01b-2024.a.a2.table.criteriaB')}
              </div>
            </td>
            <td>
              <div>
                <div className="flex my-2">
                  <Tickbox
                    icon="pi pi-check"
                    name="researchProduct.isQualified"
                    control={control}
                    errors={errors}
                    onCustomCheck={handleCheck}
                    disabled={disabled}
                  />
                  <span className="ml-1">{t('council.m01b.evaluates.qualified')}</span>
                </div>
                <div className="flex my-2">
                  <Tickbox
                    icon="pi pi-check"
                    name="researchProduct.isNotQualified"
                    control={control}
                    errors={errors}
                    onCustomCheck={handleCheck}
                    disabled={disabled}
                  />
                  <span className="ml-1">{t('council.m01b.evaluates.notQualified')}</span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <div className="mt-1">
                <span className=" text-xl font-bold">{t('council.m01b.review')}</span>
                <EditorInput
                  name="researchProductReview"
                  control={control}
                  errors={errors}
                  style={{ height: 'auto', minHeight: '200px' }}
                  disabled={disabled}
                  readOnly={disabled}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

ResearchProductTable.propTypes = {
  control: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  projectType: PropTypes.string,
};

ResearchProductTable.defaultProps = {
  disabled: false,
  projectType: '',
};
