import './style.scss';

import { useMemo, useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { Button } from 'primereact/button';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { getProjectByProjectProposalId } from 'apis/project.api';
import { PROJECT_TYPES, TOAST } from 'constant';
import { toast } from 'layout';
import { mapError } from 'utils/func';

import Loading from 'components/Loading';
import Form from '../../components/Form';

function CreateContract() {
  // #region Data
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const projectProposalId = searchParams.get('projectProposalId');
  const { control, watch, setValue, getValues, reset } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const { errors, isDirty, dirtyFields, isValid } = useFormState({
    control,
  });

  const {
    data: projectData,
    isLoading: isProjectDataLoading,
    isError,
    error: errorProject,
  } = useQuery(['project', projectProposalId], () =>
    getProjectByProjectProposalId(projectProposalId)
  );
  const project = useMemo(() => projectData?.data, [projectData]);

  const items = [
    {
      label: t('projectProposal.general.label'),
    },
    {
      label: t('contract.name'),
    },
  ];

  // eslint-disable-next-line no-unused-expressions
  project?.projectProposal?.projectType !== PROJECT_TYPES.C &&
    items.push({
      label: t('contract.form.annex'),
    });

  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
    }
  };
  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />

      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );

  useEffect(() => {
    setValue('totalExpenditure', project?.totalExpenditure);
    setValue('expenditureFromVNU', project?.expenditureFromVNU);
    setValue('fixedExpenditure', project?.fixedExpenditure);
    setValue('flexibleExpenditure', project?.flexibleExpenditure);
    setValue('expenditureFromFunding', project?.expenditureFromFunding);
    setValue('projectCode', project?.projectCode);
    setValue('topicCode', project?.topicCode);
    setValue('signingDate', project?.signingDate ? new Date(project?.signingDate) : null);
    setValue('startDate', project?.startDate ? new Date(project?.startDate) : null);
    setValue('endDate', project?.endDate ? new Date(project?.endDate) : null);
    setValue('fileUrl', project?.fileUrl);
    setValue('projectAppendixes', project?.projectAppendixes);
    setValue('numberMonthImplementation', project?.implementationMonths ?? '');
    setValue('projectId', project?.id);
  }, [project]);

  if (isProjectDataLoading) {
    return <Loading />;
  }

  if (isError) {
    return <h4 className="text-red-500">{mapError(t, errorProject)}</h4>;
  }

  return (
    <div className="tab-view-create-contract">
      <Form
        control={control}
        errors={errors}
        watch={watch}
        reset={reset}
        setValue={setValue}
        getValues={getValues}
        disabled
        checkValidation={checkValidation}
        checkKeyDown={checkKeyDown}
        isDirty={isDirty}
        dirtyFields={dirtyFields}
        footerConfirmationPrompt={footerConfirmationPrompt}
        projectProposalId={projectProposalId}
      />
    </div>
  );
}

export default CreateContract;
