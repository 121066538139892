import * as Yup from 'yup';

const mapRules = (map, rule) => {
  if (!map) return {};
  return Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});
};

const expenditureSchema = Yup.lazy((obj) =>
  Yup.object(
    mapRules(
      obj,
      Yup.object({
        expenditureFromVNUHCM: Yup.number().nullable(),
        expenditureFromFunding: Yup.number().nullable(),
      })
    )
  )
);

const kpiSchema = Yup.lazy((obj) =>
  Yup.object(
    mapRules(
      obj,
      Yup.object({
        quantity: Yup.number().nullable(),
        note: Yup.string().test({
          name: 'quantity',
          exclusive: false,
          params: {},
          message: 'formControl.validation.required',
          test: (value, context) => {
            const { quantity } = context.parent;
            if (!quantity) return true;
            return value;
          },
        }),
      })
    )
  )
);

// eslint-disable-next-line import/prefer-default-export
export const createFinancialReviewValidationSchema = Yup.object().shape({
  // B1
  specializeRequestReview: Yup.string().nullable(),

  // B2
  financialReviewExpenditureSummaries: expenditureSchema,

  // B3
  financialReviewResearchKpis: kpiSchema,

  // B4
  totalSuggestionExpenditureFromVNUHCMOfReviewer: Yup.number()
    .nullable()
    .required('formControl.validation.required'),

  // B5
  inappropriateExpenses: Yup.string().nullable(),

  // C
  totalSuggestionExpenditureFromVNUHCM: Yup.number().nullable(),
  totalSuggestionExpenditureFromMobilizedCapital: Yup.number().nullable(),
  ownerEdittingRequest: Yup.string().nullable(),
});
