const formDefaultValues = {
  general: {
    projectProposalResearchFields: [
      {
        priority: 1,
        researchLine: '',
        researchFieldId: '',
      },
      {
        priority: 2,
        researchLine: '',
        researchFieldId: '',
      },
      {
        priority: 3,
        researchLine: '',
        researchFieldId: '',
      },
    ],
    researchTypesId: '',
    lead: {
      organizationId: '',
      name: '',
      chief: '',
      phone: '',
      fax: '',
      email: '',
      bank: '',
      partnerContribution: '',
      type: 'LEAD',
    },
    partners: [],
    owners: [],
    scientificSecretaries: [],
    mainParticipants: [],
    participants: [],
    technicians: [],
    domesticExperts: [],
    internationalExperts: [],
  },
};

export const defaultValues = {
  recommendedExpert: {
    fullname: '',
    researchLine: '',
    workplace: '',
    email: '',
    phone: '',
  },
  softProduct: {
    name: '',
    quantityTarget: undefined,
    note: '',
  },
  hardProduct: {
    name: '',
    qualityCriteria: '',
    measure: '',
    evaluativeCriteria: undefined,
    domesticModelQuality: '',
    internationalModelQuality: '',
    expectedQuantity: undefined,
  },
  generalInfo: {
    vietnameseName: '',
    englishName: '',
    projectType: '',
    projectProposalResearchFields: [],
    recommendedExperts: [],
    researchType: {},
    implementationMonths: '',
    totalExpenditure: '',
    nationalUniversityTotalExpenditure: '',
    nationalUniversityAllocatedExpenditure: '',
    nationalUniversityNonallocatedExpenditure: '',
    fundingExpenditure: '',
    fundingExpenditureSelf: '',
    fundingExpenditureSelfFileUrl: '',
    fundingExpenditureOther: '',
    fundingExpenditureOtherFileUrl: '',
    otherSponsorOrganization: '',
    projectProposalOrganizations: [],
    projectProposalContacts: [],
  },
  expenditure: {
    proposedExpenditureSummaries: [],
  },
  researchResult: {
    softProducts: [],
    hardProducts: [],
    qualityComparison: ''
  }
};

export default formDefaultValues;
