import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

import ScientificProfileEdit from 'features/ScientificProfile/pages/Edit';
import ScientificProfileEditByFile from 'features/ScientificProfile/pages/EditDataFromFile';

function ScientificProfileEditPage(isEditByFile = false) {
  return (
    <LayoutProvider>
      <Layout
        style={{
          // height: '80vh',
          maxHeight: 'calc(100vh - 9rem)',
          overflowY: 'auto',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
        }}
      >
        {isEditByFile ? <ScientificProfileEditByFile /> : <ScientificProfileEdit />}
      </Layout>
    </LayoutProvider>
  );
}

export default ScientificProfileEditPage;
