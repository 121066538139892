import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { googleAuthCallback } from 'apis/users.api';

import LanguageSelect from 'components/LanguageSelect';

import { mapError } from 'utils/func';
import TokenService from 'utils/axios/token.axios';

function GoogleAuthCallback() {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { refetch, isLoading, isError, error } = useQuery(
    ['googleAuthCallback', search],
    () => googleAuthCallback(search),
    {
      enabled: false,
      onSuccess: (res) => {
        const user = { ...res.data };
        delete user.status;
        TokenService.setUser(user);
        if (res.data.status === 'pending') {
          navigate('/auth/complete-profile');
        } else {
          navigate('/');
        }
      },
    }
  );
  // #endregion Data

  // #region Event
  useEffect(() => {
    if (search) {
      refetch();
    }
  }, [search]);

  // #endregion Event

  return (
    <>
      <div className="p-4 fixed right-0">
        <LanguageSelect />
      </div>
      <div className="flex align-items-center justify-content-center" style={{ height: '80vh' }}>
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: '400px' }}
        >
          <div className="text-center mb-5">
            <img src="/logo.png" alt="hyper" height={50} className="mb-3" />
            <div className="text-900 text-3xl font-medium my-3">
              {isLoading && <p className="text-green-500">{t('googleCallback.pleaseWait')}</p>}
              {isError && <p className="text-red-400">{mapError(t, error)}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GoogleAuthCallback;
