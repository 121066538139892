import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

import UpdatePassword from 'features/Authentication/UpdatePassword';

function UpdatePasswordPage() {
  return (
    <LayoutProvider>
      <Layout style={{ height: '80vh' }}>
        <UpdatePassword />
      </Layout>
    </LayoutProvider>
  );
}

export default UpdatePasswordPage;
