import { forwardRef, useImperativeHandle, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NumberInput, Select, TextInput, TextareaInput } from 'components/FormControl';

import { yupResolver } from '@hookform/resolvers/yup';
import { TOAST } from 'constant';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { internationalPaperSchema } from 'features/ScientificProfile/validation';

const formOptions = { resolver: yupResolver(internationalPaperSchema) };

const InternationalPaperDialog = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [internationalPaperControl, setInternationalPaperControl] = useState();
  const [visible, setVisible] = useState(false);

  const {
    control,
    watch,
    setValue,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({ ...formOptions, mode: 'onChange' });

  // #region Event

  useImperativeHandle(
    ref,
    () => ({
      open: (_internationalPaperControl) => {
        setInternationalPaperControl(_internationalPaperControl);
        setVisible(true);
        if (_internationalPaperControl.type === 'INSERT') {
          reset({});
        } else if (_internationalPaperControl.type === 'UPDATE') {
          reset(_internationalPaperControl.value);
        }
      },
    }),
    []
  );

  const handleAddInternationalPaper = async () => {
    setValue('paper.publicationType', 'INTERNATIONAL');

    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = watch();

    const { insert, update, index, type } = internationalPaperControl;

    if (type === 'INSERT' && insert) {
      insert(index, data);
    } else if (type === 'UPDATE' && update) {
      update(index, data);
    }
    reset({});
    setVisible(false);
  };

  // #endregion Event

  return (
    <>
      <Dialog
        header={
          internationalPaperControl?.type === 'INSERT'
            ? t('scientificProfile.scientificWork.paper.international.add')
            : t('scientificProfile.scientificWork.paper.international.update')
        }
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        draggable={false}
        className="w-full sm:w-7 lg:w-6"
      >
        <div className="grid p-fluid">
          <div className="col-12">
            <TextInput
              label={t('scientificProfile.scientificWork.paper.title')}
              name="paper.title"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          <div className="col-6">
            <TextareaInput
              label={t('scientificProfile.scientificWork.paper.journal')}
              name="paper.journal"
              control={control}
              errors={errors}
              isRequired
            />
          </div>
          <div className="col-6">
            <TextInput
              label={t('scientificProfile.scientificWork.paper.journalAbbrev')}
              name="paper.journalAbbrev"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          <div className="col-6">
            <TextareaInput label="DOI" name="paper.doi" control={control} errors={errors} />
          </div>
          <div className="col-6">
            <TextareaInput
              label={t('scientificProfile.scientificWork.paper.authors')}
              name="paper.authors"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          <div className="col-6">
            <NumberInput
              label={t('scientificProfile.scientificWork.paper.year')}
              name="paper.year"
              control={control}
              errors={errors}
              min={0}
              isRequired
            />
          </div>
          <div className="col-6">
            <TextInput
              label={t('scientificProfile.scientificWork.paper.projectCode')}
              name="paper.projectCode"
              control={control}
              errors={errors}
            />
          </div>

          <div className="col-6">
            <TextInput
              label={t('scientificProfile.scientificWork.paper.issn')}
              name="paper.issn"
              control={control}
              errors={errors}
              isRequired
            />
          </div>
          <div className="col-6">
            <Select
              label={t('scientificProfile.scientificWork.paper.isIsi')}
              name="paper.isIsi"
              control={control}
              errors={errors}
              options={[
                {
                  value: true,
                  name: t('scientificProfile.scientificWork.paper.international.yes'),
                },
                {
                  value: false,
                  name: t('scientificProfile.scientificWork.paper.international.no'),
                },
              ]}
              inputField="value"
              isRequired
            />
          </div>

          <div className="col-6">
            <NumberInput
              label={t('scientificProfile.scientificWork.paper.if')}
              name="paper.if"
              control={control}
              errors={errors}
              min={0}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-content-end mt-4">
          <Button
            label={t('formLayout.action.cancel')}
            severity="danger"
            onClick={() => {
              setVisible(false);
            }}
            className="mr-1 w-8rem"
          />
          <Button
            label={
              internationalPaperControl?.type === 'INSERT'
                ? t('formLayout.action.add')
                : t('formLayout.action.update')
            }
            severity="info"
            type="submit"
            className="w-8rem"
            onClick={handleAddInternationalPaper}
            disabled={!Object.keys(dirtyFields).length}
          />
        </div>
      </Dialog>

      {/* {isCreatePaperLoading && <Loading />} */}
    </>
  );
});

InternationalPaperDialog.propTypes = {};

InternationalPaperDialog.defaultProps = {};

export default InternationalPaperDialog;
