import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { completeExplanationRequest, downloadFile } from 'apis/projectProposals.api';

import { completeExplanationValidationSchema } from 'features/ProjectProposal/validation';

import { FileInput, TextareaInput } from 'components/FormControl';
import Loading from 'components/Loading';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { yupResolver } from '@hookform/resolvers/yup';
import { TOAST } from 'constant';
import { toast } from 'layout';
import PropTypes from 'prop-types';
import { formatFileName, mapError } from 'utils/func';

const formOptions = { resolver: yupResolver(completeExplanationValidationSchema) };

const CompleteExplanation = forwardRef(
  (
    { refetchProjectProposals, setViewRequestsVisible, refetchRequests, refetchProjectVersions },
    ref
  ) => {
    // #region Data
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [requestControl, setRequestControl] = useState(null);

    const {
      handleSubmit,
      control,
      trigger,
      reset,
      setValue,
      watch,
      formState: { errors },
    } = useForm({ ...formOptions, mode: 'onChange' });

    const oldOwnerCommentFileUrls = watch('oldOwnerCommentFileUrls');

    const {
      mutate: mutateCompleteExplanationRequest,
      isLoading: isCompleteExplanationRequestLoading,
    } = useMutation(completeExplanationRequest);

    const { mutate: mutateDownloadFile } = useMutation(downloadFile);

    // #endregion

    // #region Event
    useImperativeHandle(
      ref,
      () => ({
        open: (_requestControl) => {
          setRequestControl(_requestControl);
          setVisible(true);
        },
      }),
      []
    );

    useEffect(() => {
      if (requestControl?.value) {
        reset({
          ownerComment: requestControl?.value?.ownerComment ?? null,
          oldOwnerCommentFileUrls: requestControl?.value?.ownerCommentFileUrls ?? [],
          ownerCommentFiles: null,
        });
      }
    }, [requestControl]);

    const onSubmit = (data) => {
      const formData = new FormData();
      if (data?.ownerCommentFiles?.length > 0) {
        data?.ownerCommentFiles?.forEach((commentfile) =>
          formData.append('ownerCommentFiles', commentfile)
        );
      }
      if (data?.ownerComment) {
        formData.append('ownerComment', data?.ownerComment);
      }
      if (requestControl?.value?.id) {
        formData.append('projectProposalReviseLogId', requestControl?.value?.id);
      }
      if (requestControl?.value?.projectProposalId) {
        formData.append('referenceProjectProposalId', requestControl?.value?.projectProposalId);
      }

      formData.append('oldOwnerCommentFileUrls', JSON.stringify(oldOwnerCommentFileUrls ?? []));

      mutateCompleteExplanationRequest(formData, {
        onSuccess: () => {
          toast(TOAST.SUCCESS, t('projectProposal.completeExplanationRequest.success'));
          reset();
          setVisible(false);
          setViewRequestsVisible(false);
          setConfirmDialogVisible(false);
          refetchProjectProposals();
          refetchRequests();
          refetchProjectVersions();
        },
        onError: (error) => {
          toast(TOAST.ERROR, mapError(t, error));
        },
      });
    };

    const checkFormValid = async () => {
      const isValid = await trigger();
      if (!isValid) {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      } else {
        setConfirmDialogVisible(true);
      }
    };

    const handleDownloadFile = (path) => {
      mutateDownloadFile(
        {
          id: requestControl?.value?.projectProposalId,
          path,
        },
        {
          onSuccess: async (res) => {
            if (res?.data) {
              const file = new Blob([res.data]);

              const link = document.createElement('a');
              link.href = URL.createObjectURL(file);
              link.download = formatFileName(path);

              link.click();
            }
          },
        }
      );
    };
    // #endregion

    return (
      <Dialog
        header={t('projectProposal.completeExplanationRequest.title')}
        visible={visible}
        style={{ width: '700px' }}
        onHide={() => setVisible(false)}
        closable={!isCompleteExplanationRequestLoading}
      >
        <div className="relative">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-fluid flex flex-column"
            autoComplete="off"
          >
            <div className="grid">
              <div className="col-12 mb-2">
                <TextareaInput
                  label={t('projectProposal.requestUpdate.comment')}
                  name="ownerComment"
                  control={control}
                  errors={errors}
                  row={5}
                  isRequired
                  disabled={isCompleteExplanationRequestLoading}
                />
              </div>

              <div className="col-12 mb-2">
                <label htmlFor="commentFiles">
                  {t('projectProposal.requestUpdate.commentFile')}{' '}
                </label>
                {oldOwnerCommentFileUrls?.map((fileUrl, index) => (
                  <div key={fileUrl?.id ?? index} className="flex justify-content-between m-1 p-1">
                    <span
                      className="text-blue-500 underline font-italic text-base cursor-pointer"
                      onClick={() => {
                        handleDownloadFile(fileUrl?.name);
                      }}
                    >
                      {formatFileName(fileUrl?.name)}
                    </span>
                    <i
                      className="pi pi-times text-red-500 text-xl ml-2"
                      onClick={() => {
                        const fileUrls = [...oldOwnerCommentFileUrls];
                        fileUrls.splice(index, 1);
                        setValue('oldOwnerCommentFileUrls', fileUrls, { shouldDirty: true });
                      }}
                    />
                  </div>
                ))}
                <FileInput
                  name="ownerCommentFiles"
                  control={control}
                  errors={errors}
                  accept=".pdf"
                  acceptOnly="pdfOnly"
                  multiple
                  disabled={isCompleteExplanationRequestLoading}
                />
              </div>
            </div>

            <div className="grid mt-2">
              <div className="col-6">
                <Button
                  label={t('formLayout.action.cancel')}
                  type="button"
                  severity="danger"
                  onClick={() => {
                    reset();
                    setVisible(false);
                  }}
                  disabled={isCompleteExplanationRequestLoading}
                />
              </div>
              <div className="col-6">
                <Button
                  label={t('projectProposal.completeExplanationRequest.completeExplanation')}
                  className="bg-cyan-400 border-cyan-400 hover:bg-cyan-500"
                  type="button"
                  onClick={checkFormValid}
                  disabled={isCompleteExplanationRequestLoading}
                />
              </div>
            </div>

            {/*
              confirm dialog: use this instead of using .requestSubmit() of form,
              avoid browser compatibility error
            */}
            <div
              className={` ${
                confirmDialogVisible ? '' : 'hidden '
              } p-dialog-mask p-dialog-center p-component-overlay p-component-overlay-enter p-dialog-visible p-dialog-resizable p-dialog-draggable`}
            >
              <div
                className="p-dialog p-component p-dialog-default p-dialog-enter-done"
                role="dialog"
                aria-modal="true"
                style={{ width: '450px' }}
              >
                {isCompleteExplanationRequestLoading && <Loading />}

                <div className="p-dialog-header">
                  <div className="p-dialog-title">
                    <p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>
                  </div>
                  <div className="p-dialog-header-icons">
                    <Button
                      type="button"
                      className="p-dialog-header-icon p-dialog-header-close p-link"
                      aria-label="Close"
                      onClick={() => setConfirmDialogVisible(false)}
                      disabled={isCompleteExplanationRequestLoading}
                    >
                      <span className="p-dialog-header-close-icon pi pi-times" aria-hidden="true" />
                      <span role="presentation" className="p-ink" />
                    </Button>
                  </div>
                </div>
                <div className="p-dialog-content">
                  <p className="text-lg">
                    {t('projectProposal.completeExplanationRequest.confirmationMessage')}
                  </p>
                </div>
                <div className="p-dialog-footer">
                  <div>
                    <Button
                      label={t('projectProposal.cancel')}
                      className="w-7rem"
                      severity="danger"
                      onClick={() => setConfirmDialogVisible(false)}
                      autoFocus
                      disabled={isCompleteExplanationRequestLoading}
                      type="button"
                    />
                    <Button
                      label={t('projectProposal.ok')}
                      className="w-7rem"
                      severity="info"
                      disabled={isCompleteExplanationRequestLoading}
                      type="submit"
                    />
                  </div>
                </div>
                <span className="p-resizable-handle" style={{ zIndex: 90 }} />
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    );
  }
);

export default CompleteExplanation;

CompleteExplanation.propTypes = {
  refetchProjectProposals: PropTypes.func,
  refetchRequests: PropTypes.func,
  refetchProjectVersions: PropTypes.func,
  setViewRequestsVisible: PropTypes.func,
};

CompleteExplanation.defaultProps = {
  refetchProjectProposals: () => null,
  refetchRequests: () => null,
  refetchProjectVersions: () => null,
  setViewRequestsVisible: () => null,
};
