import '../../style.scss';

import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import PropTypes from 'prop-types';

const titleCustomized = (icon, label) => (
  <div className="flex align-items-center">
    <span className={icon} />
    <span className="font-bold text-lg">{label}</span>
  </div>
);

export default function AwardsCard({ awards, label, additionalInfo }) {
  const { t } = useTranslation();

  //   const [isTextShown, setIsTextShown] = useState(false);

  //   const handleShowMoreLess = () => {
  //     setIsTextShown(!isTextShown);
  //   };

  return (
    <Card className="custom-card mt-4 relative" title={titleCustomized('', awards?.name)}>
      <span className="text-base">{label}</span>
      <div className="mt-2">
        <span className="font-nomarl text-base">
          {awards?.yearOfIssue || awards?.yearOfTransfer}
        </span>
        <span
          className="text-center mr-2 ml-2"
          style={{ fontSize: '.5rem', opacity: '0.8', verticalAlign: '25%' }}
        >
          ◆
        </span>
        <span className="font-nomarl text-base">
          {awards?.contributorRole ? t(`contributorRole.${awards?.contributorRole}`) : awards?.form}
        </span>
        <span
          className="text-center mr-2 ml-2"
          style={{ fontSize: '.5rem', opacity: '0.8', verticalAlign: '25%' }}
        >
          ◆
        </span>
        <span className="font-nomarl text-base">{awards?.projectCode}</span>
      </div>

      {additionalInfo}
    </Card>
  );
}

AwardsCard.propTypes = {
  awards: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  additionalInfo: PropTypes.element,
};

AwardsCard.defaultProps = {
  additionalInfo: null,
};
