import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import OwnerFields from './OwnerFields';

export default function GeneralInfo({
  data,
}) {
  // #region Data
  const { t } = useTranslation();

  const owner = data?.owner;
  const contact = data?.projectProposalContact;
  const representative = {
    ...owner,
    ...contact,
  };
  // const partners = useMemo(
  //   () => data?.projectProposalOrganizations?.filter((org) => org.type === 'PARTNER') ?? [],
  //   [data?.projectProposalOrganizations]
  // );
  // #endregion

  // #region Event

  // #endregion Event

  return (
    <div className="pb-2">
      <h4>{t('pubProjectProposal.general.viewLabel')}</h4>
      {/* A1 */}
      <h4 className="mb-2">{t('pubProjectProposal.general.a1.label')}</h4>
      <div className="ml-5 text-lg">
        <div className="flex mb-1">
          <div className="mr-2 font-semibold">{t('pubProjectProposal.general.a1.vietnameseName')}:</div>
          <div className="flex-1">{data?.vietnameseName}</div>
        </div>
        <div className="flex mb-1">
          <div className="mr-2 font-semibold">{t('pubProjectProposal.general.a1.englishName')}: </div>
          <div className="flex-1">{data?.englishName}</div>
        </div>
      </div>

      {/* A2 */}
      <h4>{t('pubProjectProposal.general.a2.label')}</h4>
      {data?.projectProposalResearchFields
        ?.sort((a, b) => {
          if (a.priority === null) return 1;
          if (b.priority === null) return -1;
          if (a.priority === b) return 0;
          return a.priority < b.priority ? -1 : 1;
        })
        .map((item, index) => (
          <div className="flex ml-5 text-lg mb-2" key={item?.researchField.id}>
            <div className="mr-2 font-semibold">
              {t('pubProjectProposal.general.a2.prio.researchField')} {item?.priority ?? index + 1}:{' '}
            </div>
            <div className="w-10rem" style={{ marginRight: '150px' }}>
              {item?.researchField?.code ? t(`researchFields.${item?.researchField?.code}`) : item?.researchField?.name}
            </div>
            <div className="font-semibold mr-2 ">{t('pubProjectProposal.general.a2.researchLine')}: </div>
            <div className="flex-1">{item?.researchLine}</div>
          </div>
        ))}

      {/* A6  */}
      <div className="flex text-lg mt-2 flex-column">
        <h4>{t('pubProjectProposal.general.a6.label')}</h4>
        <OwnerFields partner={data?.partner} representative={representative} />
      </div>

    </div>
  );
}

GeneralInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};
