import './style.scss';

import { useTranslation } from 'react-i18next';

import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';

function ExpenditureTable({ reviewExpenditure }) {
  const { t } = useTranslation();

  return (
    <div className="form-table text-lg">
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ minWidth: '16rem', textAlign: 'center' }} rowSpan={2}>
              {t('council.m01.a.a4.reviewContent')}
              <p className="font-medium font-italic"> {t('council.m01.a.a4.reviewDescription')}</p>
            </th>
            <th style={{ minWidth: '10rem', textAlign: 'center' }} colSpan={3}>
              <span className="p-column-title"> {t('council.m01.a.a4.review')}</span>
            </th>
          </tr>
          <tr>
            <th style={{ minWidth: '4rem', textAlign: 'center' }}>
              <span className="p-column-title"> {t('council.m01.a.a4.hight')}</span>
            </th>
            <th style={{ minWidth: '4rem', textAlign: 'center' }}>
              <span className="p-column-title"> {t('council.m01.a.a4.low')}</span>
            </th>
            <th style={{ minWidth: '4rem', textAlign: 'center' }}>
              <span className="p-column-title"> {t('council.m01.a.a4.suitable')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {reviewExpenditure?.map((expenditure, index) => (
            <tr key={expenditure?.id || index}>
              <td
                className={classNames({
                  'font-bold': expenditure?.expenditureType?.subProposedExpenditureTypeId === null,
                })}
              >
                {t('lang') === 'vi'
                  ? expenditure?.expenditureType?.name
                  : expenditure?.expenditureType?.englishName}
              </td>
              <td className="text-center">{expenditure?.high && 'x'}</td>
              <td className="text-center">{expenditure?.low && 'x'}</td>
              <td className="text-center">{expenditure?.suitable && 'x'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ExpenditureTable;

ExpenditureTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  reviewExpenditure: PropTypes.array.isRequired,
};

ExpenditureTable.defaulProps = {
  proposedExpenditure: [],
};
