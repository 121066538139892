import '../../style.scss';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import PropTypes from 'prop-types';

import { Tag } from 'primereact/tag';

const titleCustomized = (icon, label, doi) => (
  <div className="flex align-items-center">
    <span className={icon} />
    {doi && (
      <a
        className="font-bold text-lg cursor-pointer hover:underline"
        href={'https://www.doi.org/'.concat(doi)}
        title="Link to more information about this paper"
        target="_blank"
        rel="noopener noreferrer"
      >
        {label}
      </a>
    )}
    {!doi && <span className="font-bold text-lg">{label}</span>}
  </div>
);

export default function PaperCard({ paper, label, additionalInfo }) {
  const { t } = useTranslation();

  const [isTextShown, setIsTextShown] = useState(false);

  const handleShowMoreLess = () => {
    setIsTextShown(!isTextShown);
  };

  const itemGenneralInfo = (icon, label1, content) => (
    <div className="text-base mt-2">
      <span className={`${icon} text-gray-900`} />
      <span className="text-gray-900 text-base font-medium">{label1}: </span>
      {isTextShown || typeof content !== 'string' || content.length <= 50
        ? content
        : `${content?.substring(0, 50)}...`}
      {typeof content === 'string' && content.length > 50 && (
        <button
          type="button"
          style={{ border: '0', background: 'none', cursor: 'pointer' }}
          className="hover:underline text-gray-900 font-medium text-base"
          onClick={handleShowMoreLess}
        >
          {isTextShown ? 'Show Less' : 'More'}
        </button>
      )}
    </div>
  );

  const formatDOI = (doi) => {
    if (!doi) return null;
    if (doi.includes('https://doi.org/')) {
      return doi;
    }
    return 'https://doi.org/'.concat(doi);
  };

  return (
    <Card
      className="custom-card mt-4 lg:relative"
      title={titleCustomized('', paper?.title, paper?.doi)}
    >
      <span className="text-base">{label}</span>
      <div className="mt-2">
        <span className="font-nomarl text-base">{paper?.year}</span>
        <span
          className="text-center mr-2 ml-2"
          style={{ fontSize: '.5rem', opacity: '0.8', verticalAlign: '25%' }}
        >
          ◆
        </span>
        <span className="font-nomarl text-base">{paper?.journal || paper?.conference}</span>
        <span
          className="text-center mr-2 ml-2"
          style={{ fontSize: '.5rem', opacity: '0.8', verticalAlign: '25%' }}
        >
          ◆
        </span>
        <span className="font-nomarl text-base">{paper.journalAbbrev}</span>
      </div>

      {itemGenneralInfo(
        'pi pi-users mr-2',
        t('scientificProfile.scientificWork.paper.authors'),
        paper?.authors
      )}
      {additionalInfo}
      {paper?.doi && (
        <div className="text-sm mt-3">
          <span className="pi pi-globe mr-2 text-gray text-sm" />
          <a
            className="text-gray-900 text-sm font-nomarl custom-link-paper cursor-pointer hover:underline"
            href={formatDOI(paper?.doi) || '#'}
            title="DOI"
            target="_blank"
            rel="noopener noreferrer"
          >
            {paper?.doi}
          </a>
        </div>
      )}
      {paper?.scopusId && (
        <div className="lg:absolute lg:right-0 mt-2 lg:mt-0" style={{ bottom: '10px' }}>
          <Tag
            className="mr-2 google-scholar-tooltip cursor-default"
            icon="pi pi-tag"
            severity="info"
            title={'This paper is cited by '
              .concat(paper?.citationCount)
              .concat(' articles in Scopus')}
            style={{ cursor: 'default' }}
            value={'Citations: '.concat(paper?.citationCount)}
          />
          <Tag
            className="mr-2 google-scholar-tooltip"
            icon="pi pi-check-circle"
            severity="success"
            title="This paper is verified by Scopus"
            style={{ cursor: 'default' }}
            value={t('scientificProfile.scientificWork.verifiedLabel')}
          />
        </div>
      )}
    </Card>
  );
}

PaperCard.propTypes = {
  paper: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  additionalInfo: PropTypes.element,
};

PaperCard.defaultProps = {
  additionalInfo: null,
};
