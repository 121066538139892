import Layout from 'layout';
import { ProjectViewList } from 'features/Project/pages';
import { LayoutProvider } from '../layout/context/layoutcontext';

function ProjectListPage() {
  return (
    <LayoutProvider>
      <Layout>
        <ProjectViewList />
      </Layout>
    </LayoutProvider>
  );
}

export default ProjectListPage;
