import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextareaInput, TextInput } from 'components/FormControl';

import { defaultValues } from 'features/ProjectProposal/defaultValues';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import HardProductDialog from './HardProductDialog';

export default function HardProductsTable({ disabled, control, errors }) {
  // #region Data
  const { t } = useTranslation();
  const hardProductRef = useRef();

  const { fields, insert, remove, update } = useFieldArray({
    control,
    name: 'researchResult.hardProducts',
  });

  // #endregion Data
  return (
    <>
      <h4>{t('projectProposal.researchResult.a10_1.hardProduct.label')}</h4>
      <p>({t('projectProposal.researchResult.a10_1.hardProduct.note')})</p>

      <div className="form-table">
        <table className="mb-2">
          <thead>
            <tr>
              <th rowSpan={3} className="text-center" style={{ minWidth: 50, width: 50 }}>
                {t('formLayout.numericalOrder')}
              </th>
              <th rowSpan={3} className="text-center" style={{ minWidth: 220, width: 220 }}>
                {t('projectProposal.researchResult.a10_1.hardProduct.fields.productName')}
              </th>
              <th rowSpan={3} className="text-center" style={{ minWidth: 200, width: 200 }}>
                {t('projectProposal.researchResult.a10_1.hardProduct.fields.qualityCriteria')}
              </th>
              <th rowSpan={3} className="text-center" style={{ minWidth: 80, width: 80 }}>
                {t('projectProposal.researchResult.a10_1.hardProduct.fields.measure')}
              </th>
              <th colSpan={3} className="text-center" style={{ minWidth: 330, width: '100%' }}>
                {t('projectProposal.researchResult.a10_1.hardProduct.fields.qualityLevel')}
              </th>
              <th rowSpan={3} className="text-center" style={{ minWidth: 100, width: 100 }}>
                {t('projectProposal.researchResult.a10_1.hardProduct.fields.expectedQuantity')}
              </th>
              {!disabled && (
                <th rowSpan={3} className="float text-center" style={{ minWidth: 80, width: 80 }}>
                  {t('projectProposal.action')}
                </th>
              )}
            </tr>
            <tr>
              <th rowSpan={2} style={{ minWidth: 80, width: 80 }}>
                {t('projectProposal.researchResult.a10_1.hardProduct.fields.evaluativeCriteria')}
              </th>
              <th colSpan={2}>
                {t('projectProposal.researchResult.a10_1.hardProduct.fields.similarModel')}
              </th>
            </tr>
            <tr>
              <th>{t('projectProposal.researchResult.a10_1.hardProduct.fields.domestic')}</th>
              <th>{t('projectProposal.researchResult.a10_1.hardProduct.fields.international')}</th>
            </tr>
          </thead>
          <tbody>
            {fields?.map((item, index) => (
              <tr key={item.id}>
                <td className="text-center">{index + 1}</td>
                <td>
                  <TextareaInput
                    name={`researchResult.hardProducts.${index}.name`}
                    control={control}
                    errors={errors}
                    disabled
                  />
                </td>
                <td>
                  <TextareaInput
                    name={`researchResult.hardProducts.${index}.qualityCriteria`}
                    control={control}
                    errors={errors}
                    disabled
                  />
                </td>
                <td>
                  <TextInput
                    name={`researchResult.hardProducts.${index}.measure`}
                    control={control}
                    errors={errors}
                    disabled
                  />
                </td>
                <td>
                  <TextareaInput
                    name={`researchResult.hardProducts.${index}.evaluativeCriteria`}
                    control={control}
                    errors={errors}
                    disabled
                  />
                </td>
                <td>
                  <TextareaInput
                    name={`researchResult.hardProducts.${index}.domesticModelQuality`}
                    control={control}
                    errors={errors}
                    disabled
                  />
                </td>
                <td>
                  <TextareaInput
                    name={`researchResult.hardProducts.${index}.internationalModelQuality`}
                    control={control}
                    errors={errors}
                    disabled
                  />
                </td>
                <td>
                  <TextareaInput
                    name={`researchResult.hardProducts.${index}.expectedQuantity`}
                    control={control}
                    errors={errors}
                    disabled
                  />
                </td>
                {!disabled && (
                  <td className="float text-center p-0">
                    <div className="">
                      <i
                        onClick={() => {
                          hardProductRef.current.open({
                            index,
                            value: fields[index],
                            update,
                            type: 'UPDATE',
                          });
                        }}
                        className="edit-icon pi pi-pencil text-primary cursor-pointer mt-1"
                        data-pr-tooltip={t('formLayout.action.edit')}
                        data-pr-position="left"
                      />
                      <Tooltip target=".edit-icon" />
                    </div>

                    <i
                      onClick={() => remove(index)}
                      className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                      data-pr-tooltip={t('formLayout.action.remove')}
                      data-pr-position="left"
                    />
                    <Tooltip target=".remove-icon" />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!disabled && (
        <div className="flex justify-content-center mt-2">
          <i
            onClick={() => {
              hardProductRef.current.open({
                index: fields?.length ?? 0,
                value: defaultValues.hardProduct,
                insert,
                type: 'INSERT',
              });
            }}
            className="add-hard-product pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
            data-pr-tooltip={t('formLayout.action.add')}
            data-pr-position="left"
          />
          <Tooltip target=".add-hard-product" />
        </div>
      )}

      <HardProductDialog ref={hardProductRef} />
    </>
  );
}

HardProductsTable.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
};

HardProductsTable.defaultProps = {
  disabled: false,
};
