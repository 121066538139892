import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { getAll as getAllPrograms } from 'apis/programs.api';
import { getAll as getAllProjectProposals, updateStatus } from 'apis/projectProposals.api';
import Loading from 'components/Loading';

import { TIMEZONE_VN, TOAST } from 'constant';
import { toast } from 'layout';
import { mapError } from 'utils/func';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Link } from 'react-router-dom';

function ProgramList() {
  // #region Data
  const { t } = useTranslation();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [submitProjectId, setSubmitProjectId] = useState();
  const [selectedProgramId, setSelectedProgramId] = useState();

  const {
    data: programsData,
    isLoading: isProgramsLoading,
    isError: isProgramsError,
    error: programsError,
  } = useQuery('programs', getAllPrograms);
  const programs = useMemo(() => programsData?.data ?? [], [programsData]);

  const {
    data: projectProposalsData,
    isLoading: isProjectProposalsLoading,
    isError: isProjectProposalsError,
    error: projectProposalsError,
    refetch: refetchProjectProposal,
  } = useQuery(['projectProposals'], getAllProjectProposals);
  // eslint-disable-next-line no-unused-vars
  const projectProposals = useMemo(
    () => projectProposalsData?.data.filter((e) => e.status.name === 'new') ?? [],
    [projectProposalsData]
  );

  const {
    mutate: mutateUpdateStatus,
    isLoading: isUpdateStatusLoading,
    isError: isUpdateStatusError,
    error: updateStatusError,
  } = useMutation(updateStatus);
  // #endregion Data

  // #region Event
  const handleProjectSubmitClick = (program) => {
    if (!program?.startingTime || !program?.endingTime) {
      toast(TOAST.ERROR, t('program.programHasNoTimeErrorTitle'));
      return;
    }
    const startingTime = new Date(program.startingTime).toLocaleString('en-ZA', {
      timeZone: TIMEZONE_VN,
    });
    const deadline = new Date(program.endingTime).toLocaleString('en-ZA', {
      timeZone: TIMEZONE_VN,
    });
    const currentTime = new Date().toLocaleString('en-ZA', { timeZone: TIMEZONE_VN });
    if (currentTime < startingTime) {
      toast(TOAST.ERROR, t('program.programHasNotStartedErrorTitle'));
      return;
    }
    if (currentTime > deadline) {
      toast(TOAST.ERROR, t('program.overdueSubmissionDeadlineErrorTitle'));
      return;
    }
    setSelectedProgramId(program.id);
    setIsSubmitModalOpen(true);
  };
  // #endregion Event
  // #region Render
  const formatActions = (value) => (
    <span style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div className="flex align-items-center flex-wrap bg-blue-500 border-round-lg mr-1">
        <Button
          icon="pi pi-file"
          severity="success"
          tooltip={t('program.viewList.submit')}
          tooltipOptions={{ position: 'left' }}
          onClick={() => handleProjectSubmitClick(value)}
        />
      </div>
    </span>
  );

  const formatDate = (time) =>
    time ? new Date(time).toLocaleDateString('en-GB', { timeZone: TIMEZONE_VN }) : '';
  const formatStartTime = (value) => formatDate(value.startingTime);
  const formatEndTime = (value) => formatDate(value.endingTime);

  const footerConfirmationSubmit = () => (
    <div>
      <Button
        label={t('program.cancel')}
        className="w-10rem"
        severity="danger"
        onClick={() => {
          setIsSubmitModalOpen(false);
          setSubmitProjectId(null);
        }}
        autoFocus
      />
      <Button
        label={t('program.viewList.submit')}
        className="w-10rem"
        severity="info"
        onClick={() => {
          if (!submitProjectId) {
            toast(TOAST.ERROR, t('program.viewList.unselectProjectErrorMessage'));
          } else if (selectedProgramId) {
            mutateUpdateStatus(
              { projectId: submitProjectId, programId: selectedProgramId },
              {
                onSuccess: () => {
                  setIsSubmitSuccess(true);
                  setSubmitProjectId(null);
                  refetchProjectProposal();
                },
                onError: () => {
                  setIsErrorModalOpen(true);
                },
              }
            );
          }
        }}
        disabled={isUpdateStatusLoading}
      />
    </div>
  );

  if (isProgramsError) {
    return <h4 className="text-red-500">{mapError(t, programsError)}</h4>;
  }
  if (isProjectProposalsError) {
    return <h4 className="text-red-500">{mapError(t, projectProposalsError)}</h4>;
  }
  return (
    <div className="grid">
      {/* #region Loading */}
      <div className="sticky top-0 left-50 z-5">
        <div className="relative">{isProjectProposalsLoading && <Loading />}</div>
      </div>
      <div className="col-12">
        <div className="card">
          <div className="flex justify-content-between align-items-end mb-4 pb-2">
            <h5 className="p-0 m-0">{t('program.viewList.title')}</h5>
          </div>

          <DataTable
            value={programs}
            className="p-datatable-gridlines header-table"
            showGridlines
            dataKey="id"
            filterDisplay="menu"
            scrollable
            loading={isProgramsLoading}
            responsiveLayout="scroll"
            emptyMessage={t('program.viewList.emptyMessage')}
          >
            <Column
              field="name"
              header={t('program.viewList.name')}
              style={{ minWidth: '12rem' }}
            />
            <Column
              field="description"
              header={t('program.viewList.description')}
              headerStyle={{ justifyContent: 'center' }}
              style={{ minWidth: '12rem' }}
            />
            <Column
              field="startingTime"
              header={t('program.viewList.startTime')}
              style={{ minWidth: '4rem', width: '12rem', textAlign: 'center' }}
              body={formatStartTime}
            />
            <Column
              field="endingTime"
              header={t('program.viewList.endTime')}
              style={{ minWidth: '4rem', width: '12rem', textAlign: 'center' }}
              body={formatEndTime}
            />
            <Column
              header={t('program.action')}
              body={formatActions}
              frozen
              alignFrozen="right"
              style={{ width: '6.5rem', textAlign: 'center' }}
            />
          </DataTable>
        </div>
      </div>
      {/* #region Update status */}
      <Dialog
        header={<p className="text-blue-500">{t('program.viewList.submit')}</p>}
        visible={isSubmitModalOpen}
        position="center"
        style={{ width: '400px' }}
        onHide={() => {
          setIsSubmitModalOpen(false);
          setSubmitProjectId(null);
        }}
        draggable={false}
        footer={footerConfirmationSubmit}
      >
        {isUpdateStatusLoading && <Loading />}

        <label htmlFor="projectId" className="inline-block mb-2">
          {t('program.viewList.project')}{' '}
          <span className="text-red-500" style={{ fontWeight: 900 }}>
            *
          </span>
        </label>
        <Dropdown
          id="projectId"
          value={submitProjectId}
          options={projectProposals}
          onChange={(e) => {
            setSubmitProjectId(e.value);
          }}
          optionLabel={t('lang') === 'vi' ? 'vietnameseName' : 'englishName'}
          optionValue="id"
          showClear
          placeholder={t('program.viewList.selectProject')}
          emptyMessage={t('program.viewList.emptyMessageDialog')}
          style={{ width: '100%' }}
        />
        <Message
          className="mt-4 w-full"
          severity="warn"
          text={t('program.confirmationSubmitMessage')}
        />
        <div className="text-right ">
          <Link to="/project-proposal/create">
            <div className="mt-2 underline ">{t('program.viewList.addProjectProposal')}</div>
          </Link>
        </div>
      </Dialog>

      <Dialog
        header={<p className="text-green-500">{t('program.confirmationTitle')}</p>}
        visible={isSubmitSuccess}
        position="center"
        style={{ width: '400px' }}
        onHide={() => {
          setIsSubmitSuccess(false);
          setIsSubmitModalOpen(false);
        }}
        draggable={false}
        // eslint-disable-next-line react/no-unstable-nested-components
        footer={() => (
          <Button
            label={t('program.ok')}
            severity="success"
            onClick={() => {
              setIsSubmitSuccess(false);
              setIsSubmitModalOpen(false);
            }}
          />
        )}
      >
        <p className="text-lg py-3">{t('program.submitSuccessMessage')}</p>
      </Dialog>

      {isUpdateStatusError && (
        <Dialog
          header={<p className="text-red-500">{t('program.submitErrorTitle')}</p>}
          visible={isErrorModalOpen}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setIsErrorModalOpen(false)}
          draggable={false}
        >
          {Array.isArray(updateStatusError?.response.data.message) ? (
            updateStatusError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, updateStatusError)}</p>
          )}
        </Dialog>
      )}
      {/* #endregion Update status */}
    </div>
  );
}
// #endregion
export default ProgramList;
