import instance from 'utils/axios/instance.axios';
import { SUMMARY_REPORT } from './_constant';

export const createSummaryReport = (body = {}) => instance.post(SUMMARY_REPORT.CREATE_REPORT, body);

export const updateSummaryReport = ({ id, body = {} }) =>
  instance.patch(SUMMARY_REPORT.UPDATE_REPORT(id), body);

export const uploadExpenditureFile = (body = {}) =>
  instance.patch(SUMMARY_REPORT.CREATE_OR_UPDATE_EXPENDITURE_FILE, body);

export const updateExpenditureFile = (body = {}) =>
  instance.patch(SUMMARY_REPORT.CREATE_OR_UPDATE_EXPENDITURE_FILE, body);

export const submitSummaryReport = (body = {}) =>
  instance.post(SUMMARY_REPORT.SUBMIT_SUMMARY_REPORT, body);

export const createOrUpdateConference = (body = {}) =>
  instance.post(SUMMARY_REPORT.CREATE_OR_UPDATE_CONFERENCE, body);

export const updateReviewSuggestion = (body = {}) =>
  instance.patch(SUMMARY_REPORT.UPDATE_REVIEW_SUGGESTION, body);

export const updateExpenditure = (body = {}) =>
  instance.patch(SUMMARY_REPORT.UPDATE_EXPENDITURE, body);

export const uploadProductFile = (body = {}) =>
  instance.post(SUMMARY_REPORT.UPLOAD_OR_UPDATE_PRODUCT_FILE, body);

export const updateProductFile = (body = {}) =>
  instance.patch(SUMMARY_REPORT.UPLOAD_OR_UPDATE_PRODUCT_FILE, body);

export const deleteProductFile = (body = {}) =>
  instance.post(SUMMARY_REPORT.DELETE_PRODUCT_FILE, body);

export const getSummaryReport = (id) => instance.get(SUMMARY_REPORT.GET_SUMMARY_REPORT(id));

export const getSummaryReportType = () => instance.get(SUMMARY_REPORT.GET_TYPE_SUMMARY_REPORT);

export const getSummaryReportExpenditureType = () =>
  instance.get(SUMMARY_REPORT.GET_EXPENDITURE_TYPE_SUMMARY_REPORT);

export const getSummaryReportProductType = () =>
  instance.get(SUMMARY_REPORT.GET_SUMMARY_REPORT_PRODUCT_TYPE);

export const getSummaryReportProductGroup = () =>
  instance.get(SUMMARY_REPORT.GET_SUMMARY_REPORT_PRODUCT_GROUPS);

export const getExpenditures = (summaryReportId) =>
  instance.get(SUMMARY_REPORT.GET_EXPENDITURE, { params: { summaryReportId } });

export const downloadFileReport = ({ summaryReportId, filename }) =>
  instance.get(SUMMARY_REPORT.DOWNLOAD_REPORT_FILE, {
    params: { summaryReportId, filename },
    responseType: 'blob',
  });
export const downloadReportPdfFile = (summaryReportId) =>
  instance.get(SUMMARY_REPORT.DOWNLOAD_REPORT_PDF_FILE, {
    params: { summaryReportId },
    responseType: 'blob',
  });

export const downloadReportDocxFile = (summaryReportId) =>
  instance.get(SUMMARY_REPORT.DOWNLOAD_REPORT_DOCX_FILE, {
    params: { summaryReportId },
    responseType: 'blob',
  });
