import { Steps } from 'primereact/steps';
import { useForm, useFormState } from 'react-hook-form';
import { useState } from 'react';
import { TOAST } from 'constant';
import { toast } from 'layout';
import { useTranslation } from 'react-i18next';
import { ScrollTop } from 'primereact/scrolltop';
import MidtermCreateForm from 'features/Project/components/SummaryReportForm/Create';
import MidtermEditForm from 'features/Project/components/SummaryReportForm/Edit';
import { yupResolver } from '@hookform/resolvers/yup';
import createOrUpdateDocument from 'features/Project/validation';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ReactRouterPrompt from 'react-router-prompt';
import { defaultValues } from 'features/Project/defaultValues';
import PropTypes from 'prop-types';

export default function SubmitMidtermDocuments({ isEdit }) {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const { handleSubmit, control, setValue, getValues, watch, reset, trigger } = useForm({
    mode: 'onChange',
    resolver: yupResolver(createOrUpdateDocument),
    defaultValues,
  });
  const { errors, isDirty, dirtyFields, isValid } = useFormState({ control });

  const items = [
    { label: t('r05.tab.generalInfomation') },
    { label: t('r05.tab.product') },
    { label: t('r05.tab.expenditure') },
    { label: t('r05.tab.reviewAndEvaluation') },
    { label: t('r05.tab.viewDetail') },
  ];

  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
    }
  };

  // #Region Event

  const handleTabChange = async (e) => {
    // Can not use switch because of eslint error (conflict Prettier)
    if (currentTab === 1) {
      const isSummaryReportFinishedTasksValid = await trigger('summaryReportFinishedTasks', {
        shouldFocus: true,
      });
      const isSummaryReportUnfinishedTasksValid = await trigger('summaryReportUnfinishedTasks', {
        shouldFocus: true,
      });
      const isSummaryReportConferencesValid = await trigger('summaryReportConferences', {
        shouldFocus: true,
      });
      const isSummaryReportParticipantsValid = await trigger('summaryReportParticipants', {
        shouldFocus: true,
      });

      if (
        isSummaryReportFinishedTasksValid &&
        isSummaryReportUnfinishedTasksValid &&
        isSummaryReportConferencesValid &&
        isSummaryReportParticipantsValid
      ) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 2) {
      const isExpenditureValid = await trigger('expenditure', { shouldFocus: true });
      if (isExpenditureValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 3) {
      const isAppendixFilesValid = await trigger('appendixFiles', {
        shouldFocus: true,
      });
      if (isAppendixFilesValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else {
      setCurrentTab(e.index);
    }
  };

  // #End region event
  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );

  return (
    <>
      <Steps model={items} activeIndex={currentTab} onSelect={handleTabChange} readOnly={false} />
      {isEdit && (
        <MidtermEditForm
          isAcceptanceReport={false}
          handleSubmit={handleSubmit}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          reset={reset}
          control={control}
          errors={errors}
          tab={currentTab}
          setTab={setCurrentTab}
          isDirty={isDirty}
          dirtyFields={dirtyFields}
          checkValidation={checkValidation}
        />
      )}
      {!isEdit && (
        <MidtermCreateForm
          isAcceptanceReport={false}
          handleSubmit={handleSubmit}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          reset={reset}
          control={control}
          errors={errors}
          tab={currentTab}
          setTab={setCurrentTab}
          isDirty={isDirty}
          dirtyFields={dirtyFields}
          checkValidation={checkValidation}
        />
      )}
      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields).length && currentTab !== 4}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>
      <ScrollTop target="parent" />
    </>
  );
}

SubmitMidtermDocuments.propTypes = { isEdit: PropTypes.bool.isRequired };
