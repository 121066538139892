import { useTranslation } from 'react-i18next';

import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { Timeline } from 'primereact/timeline';

import PropTypes from 'prop-types';
import { toCamelCaseString } from 'utils/func';
import { TIMEZONE_VN } from 'constant';

export default function ViewHistory({ data, visible, setVisible, title }) {
  const { t } = useTranslation();

  const formatAction = (value) => {
    const statusColorMapping = {
      new: 'var(--blue-500)',
      revise: 'var(--orange-500)',
      submit: 'var(--green-500)',

      requestUpdate: 'var(--yellow-500)',
      requestUpdateSpecialization: 'var(--yellow-500)',
      requestUpdateFinance: 'var(--yellow-500)',
      requestExplanationPrequalification: 'var(--pink-500)',
      requestExplanationSpecialization: 'var(--pink-500)',
      requestExplanationFinance: 'var(--pink-500)',

      completeUpdateRequest: 'var(--cyan-500)',
      completeUpdateRequestSpecialization: 'var(--cyan-500)',
      completeUpdateRequestFinance: 'var(--cyan-500)',
      completeExplanationRequestPrequalification: 'var(--purple-500)',
      completeExplanationRequestSpecialization: 'var(--purple-500)',
      completeExplanationRequestFinance: 'var(--purple-500)',

      // Project status
      update: 'var(--orange-500)',
      liquidation: 'var(--orange-500)',
    };

    return (
      <Tag
        style={{ background: statusColorMapping[toCamelCaseString(value.actionType)] }}
        value={t(`projectProposal.viewHistory.status.${toCamelCaseString(value.actionType)}`)}
        rounded
      />
    );
  };
  const formatCreatedAt = (value) => {
    const datetime = new Date(value.createdAt);
    const options = {
      timeZone: TIMEZONE_VN,
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour12: false,
    };
    const formatter = new Intl.DateTimeFormat('en-ZA', options);

    if (datetime.toString() !== 'Invalid Date') {
      const parts = formatter?.formatToParts(datetime);
      const formattedDatetime = `${parts.find((part) => part.type === 'hour').value}:${
        parts.find((part) => part.type === 'minute').value
      } ${parts.find((part) => part.type === 'day').value}/${
        parts.find((part) => part.type === 'month').value
      }/${parts.find((part) => part.type === 'year').value}`;

      return <small className="text-color-secondary">{formattedDatetime}</small>;
    }
    return null;
  };

  return (
    <Dialog
      header={<p className="text-blue-500">{title}</p>}
      visible={visible}
      position="center"
      style={{ width: '500px' }}
      onHide={() => setVisible(false)}
      draggable={false}
    >
      <Timeline value={data} opposite={formatAction} content={formatCreatedAt} />
    </Dialog>
  );
}

ViewHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
