import './style.scss';

import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  getGeneralProjectProposalInfo,
  getReviewerInfo,
  downloadPdfTemplate,
  downloadDocxTemplate,
} from 'apis/specializeReviews.api';

import ErrorDialog from 'components/ErrorDialog';
import { EditorInput } from 'components/FormControl';
import Loading from 'components/Loading';

import { TOAST } from 'constant';
import { toast } from 'layout';

import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import PropTypes from 'prop-types';

import { isMemberAllowedReview } from 'utils/func';
// eslint-disable-next-line import/no-cycle
import { DetailInfo, EvaluationForm, ExpenditureForm } from '..';

export default function CreateForm({
  isLoadingCreate,
  isErrorRes,
  errorRes,
  handleSubmit,
  onSubmit,
  control,
  watch,
  setValue,
  getValues,
  isDirty,
  dirtyFields,
  errors,
  isValid,
  isVisibleErrorDialog,
  setIsVisibleDialogError,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');
  const downloadTemplateButtonRef = useRef();
  // #region Data
  // general info projectProposal

  const { data: dataGeneralProjectProposalInfo, isLoading: isLoadingProjectProposal } = useQuery(
    ['specialize-review', 'project-proposal', councilId, projectProposalId],
    ({ queryKey: [, , _councilId, _projectProposalId] }) =>
      getGeneralProjectProposalInfo(_councilId, _projectProposalId)
  );

  const generalProjectProposalInfo = useMemo(
    () => dataGeneralProjectProposalInfo?.data,
    [dataGeneralProjectProposalInfo]
  );

  // reviewer info
  const { data: dataReviewerInfo, isLoading: isLoadingReviewer } = useQuery(
    ['specialize-review', 'reviewer', councilId, projectProposalId],
    ({ queryKey: [, , _councilId, _projectProposalId] }) =>
      getReviewerInfo(_councilId, _projectProposalId)
  );

  const reviewerInfo = useMemo(() => dataReviewerInfo?.data, [dataReviewerInfo]);

  // #end region Data

  // #region Events

  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
    }
  };

  const {
    refetch: handleDownloadPdfTemplate,
    isLoading: isDownloadPdfTemplateLoading,
    isFetching: isDownloadPdfTemplateFetching,
  } = useQuery(
    ['downloadPdfTemplateSpecializationReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M01 - Phieu nhan xet danh gia tham dinh chuyen mon.pdf';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxTemplate,
    isLoading: isDownloadDocxTemplateLoading,
    isFetching: isDownloadDocxTemplateFetching,
  } = useQuery(
    ['downloadDocxTemplateSpecializationReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M01 - Phieu nhan xet danh gia tham dinh chuyen mon.docx';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );

  return (
    <div className="bg-white p-5">
      {(isLoadingCreate || isLoadingReviewer || isLoadingProjectProposal) && <Loading />}
      {isErrorRes && (
        <ErrorDialog
          title={t('council.review.create')}
          isError={isErrorRes}
          error={errorRes}
          visible={isVisibleErrorDialog}
          setVisible={setIsVisibleDialogError}
        />
      )}

      <div className="flex justify-content-end">
        <Button
          label={t('council.review.downloadTemplate')}
          icon={`pi ${
            isDownloadPdfTemplateLoading ||
            isDownloadPdfTemplateFetching ||
            isDownloadDocxTemplateLoading ||
            isDownloadDocxTemplateFetching
              ? 'pi-spin pi-spinner'
              : 'pi-file'
          }`}
          className="block w-auto h-3rem border-none ml-1"
          severity="info"
          onClick={(event) => downloadTemplateButtonRef.current.toggle(event)}
          disabled={
            isDownloadPdfTemplateLoading ||
            isDownloadPdfTemplateFetching ||
            isDownloadDocxTemplateLoading ||
            isDownloadDocxTemplateFetching
          }
        />
      </div>

      <Menu
        ref={downloadTemplateButtonRef}
        model={[
          {
            label: t('council.review.downloadPdfTemplate'),
            icon: 'pi pi-file-pdf',
            command: handleDownloadPdfTemplate,
            disabled: isDownloadPdfTemplateLoading || isDownloadPdfTemplateFetching,
          },
          {
            label: t('council.review.downloadDocxTemplate'),
            icon: 'pi pi-file-word',
            command: handleDownloadDocxTemplate,
            disabled: isDownloadDocxTemplateLoading || isDownloadDocxTemplateFetching,
          },
        ]}
        popup
      />

      <div className="p-fluid specialize-review-create">
        <div className="m-0 bg-white p-5 p-fluid">
          <h3 className="mb-5 text-4xl text-center">
            <span className="line-height-3 font-semibold">{t('council.m01.title')}</span>
            <br />
            <span className="font-normal">{t('council.m01.sciTechProject')}</span>
          </h3>
        </div>
        <DetailInfo
          generalProjectProposalInfo={generalProjectProposalInfo}
          reviewerInfo={reviewerInfo}
        />
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-8 relative">
            <h4>{t('council.m01.a.label')}</h4>
            <h5>{t('council.m01.a.a1.label')}</h5>
            <div className="mt-3 font-italic">{t('council.m01.a.a1.note')}</div>
            <div className="mt-3">
              <EditorInput
                name="reviewImportance"
                isRequired
                control={control}
                errors={errors}
                style={{ height: 'auto', minHeight: '300px' }}
              />
            </div>
            <h5>{t('council.m01.a.a2.label')} </h5>
            <div className="mt-3 font-italic">{t('council.m01.a.a2.note')}</div>
            <div className="mt-3">
              <EditorInput
                name="reviewQuality"
                isRequired
                control={control}
                errors={errors}
                style={{ height: 'auto', minHeight: '300px' }}
              />
            </div>
            <h5>{t('council.m01.a.a3.label')}</h5>
            <div className="mt-3 font-italic">{t('council.m01.a.a3.note')}</div>
            <div className="mt-3">
              <EditorInput
                name="reviewPicapability"
                isRequired
                control={control}
                errors={errors}
                style={{ height: 'auto', minHeight: '300px' }}
              />
            </div>
            <h5>{t('council.m01.a.a4.label')}</h5>
            <div className="mt-3 font-italic">{t('council.m01.a.a4.note')}</div>
            <ExpenditureForm
              setValue={setValue}
              getValues={getValues}
              control={control}
              errors={errors}
            />
            <h5>{t('council.m01.b.label')}</h5>
            <EvaluationForm
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              control={control}
              errors={errors}
            />
            <table className="form-table">
              <thead>
                <tr className="text-center">
                  <th>{t('council.m01.b.rank')}</th>
                  <th>{t('council.m01.b.totalPoint')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>I</td>
                  <td>{t('council.m01.b.rankI')}</td>
                </tr>
                <tr className="text-center">
                  <td>II</td>
                  <td>{t('council.m01.b.rankII')}</td>
                </tr>
                <tr className="text-center">
                  <td>III {t('council.m01.b.statusRankIII')}</td>
                  <td>{t('council.m01.b.rankIII')}</td>
                </tr>
              </tbody>
            </table>
            <h5>{t('council.m01.c.label')}</h5>
            <div className="mt-3">
              <EditorInput
                name="reviewConclusion"
                isRequired
                control={control}
                errors={errors}
                style={{ height: 'auto', minHeight: '300px' }}
              />
            </div>

            <div className="grid text-lg mt-4">
              <div className="col-7 px-8">
                <span>{t('council.m01.c.commitment')}</span>
              </div>
              <div className="col-5 text-center">
                <p className="mb-0">{t('signature.date')}</p>
                <p className="font-semibold mb-0">{t('signature.reviewer')}</p>
                <p className="mb-7">{t('signature.fullnameAndSignature')}</p>
                <p className="font-bold">{reviewerInfo?.fullname}</p>
              </div>
            </div>

            <div className="flex justify-content-end mt-8">
              <Button
                label={t('formLayout.action.close')}
                severity="danger"
                type="button"
                // eslint-disable-next-line quotes
                onClick={() => navigate(`/project-proposal-defense`)}
                className="mr-2 w-8rem"
              />
              <Button
                label={t('formLayout.action.save')}
                severity="info"
                type="submit"
                className="w-8rem"
                onClick={checkValidation}
                disabled={
                  !(isDirty && Object.keys(dirtyFields).length) ||
                  !isMemberAllowedReview(reviewerInfo?.councilMembers?.[0])
                }
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

CreateForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  isLoadingCreate: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isErrorRes: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  errorRes: PropTypes.shape({}),
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  dirtyFields: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  isValid: PropTypes.bool.isRequired,
  isVisibleErrorDialog: PropTypes.bool.isRequired,
  setIsVisibleDialogError: PropTypes.func.isRequired,
};
