import './style.scss';

import { useMutation, useInfiniteQuery } from 'react-query';
import {
  getNotification,
  readAllNotification,
  readNotification,
  deleteNotification,
} from 'apis/notification.api';
import Loading from 'components/Loading';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ScrollTop } from 'primereact/scrolltop';
import { timeAgo } from 'utils/func';
import { useMemo, useState } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { toast } from 'layout';
import { TOAST } from 'constant';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export default function Notification() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [allNotification, setAllNotification] = useState(true);
  const [showDelButton, setShowDelButton] = useState(null);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } = useInfiniteQuery(
    ['notification', 'all'],
    ({ pageParam = 1 }) => getNotification(pageParam, 10),
    {
      getNextPageParam: (lastPage, allPages) =>
        (lastPage?.data?.totalPages ?? 1) > allPages?.length
          ? parseInt(allPages?.length, 10) + 1
          : undefined,
    }
  );

  const {
    data: _data,
    fetchNextPage: _fetchNextPage,
    hasNextPage: _hasNextPage,
    isFetchingNextPage: _isFetchingNextPage,
    refetch: _refetch,
  } = useInfiniteQuery(
    ['notification', 'unread'],
    ({ pageParam = 1 }) => getNotification(pageParam, 10, 'unread'),
    {
      getNextPageParam: (lastPage, allPages) =>
        (lastPage?.data?.totalPages ?? 1) > allPages?.length
          ? parseInt(allPages?.length, 10) + 1
          : undefined,
    }
  );

  const notifications = useMemo(() => {
    if (allNotification) return data?.pages?.map((page) => page?.data?.data) ?? [];
    return _data?.pages?.map((page) => page?.data?.data) ?? [];
  }, [data, _data, allNotification]);

  const { mutate: readNotificationMutate } = useMutation(readNotification);
  const { mutate: readAllNotificationMutate } = useMutation(readAllNotification);
  const { mutate: deleteNotificationMutate } = useMutation(deleteNotification);

  const handleReadNotification = (noti) => {
    readNotificationMutate(noti?.id, {
      onSuccess: () => {
        navigate(noti?.path);
      },
    });
  };

  const handleReadAllNotification = () => {
    readAllNotificationMutate(null, {
      onSuccess: () => {},
    });
  };

  const handleDeleteNotification = (id) => {
    deleteNotificationMutate(id, {
      onSuccess: () => {
        refetch();
        _refetch();
        toast(TOAST.SUCCESS, t('notification.deleteSuccess'));
      },
    });
  };

  const accept = (id) => {
    handleDeleteNotification(id);
  };

  // const reject = () => {};
  const confirmUpdateResult = (id) => {
    confirmDialog({
      message: t('notification.confirmation.message'),
      header: t('notification.confirmation.title'),
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      style: { width: '25vw' },
      accept: () => accept(id),
      acceptLabel: t('formLayout.action.ok'),
      rejectLabel: t('formLayout.action.cancel'),
    });
  };

  return (
    <>
      {isFetchingNextPage && <Loading />}
      <div className="px-6 py-4">
        <div className="grid">
          <div className="col-10">
            <h3>{t('notification.title')}</h3>
          </div>
          <div
            className={`col-2 text-right ${
              !allNotification && notifications?.[0]?.length > 0 ? '' : 'hidden'
            }`}
          >
            <button
              type="button"
              className="p-link mark-read hover:bg-gray-200 text-4xl"
              data-pr-tooltip={t('notification.markAllAsRead')}
              data-pr-position="left"
              data-pr-at="center bottom"
              data-pr-my="right top"
              onClick={handleReadAllNotification}
            >
              <i className="pi pi-check text-4xl px-2" />
              <Tooltip target=".mark-read" />
            </button>
          </div>
        </div>
        <div className="mb-2">
          <button
            type="button"
            className={`p-link text-right p-2 text-xl ${
              allNotification ? 'bg-gray-200 border-round-lg font-semibold' : ''
            }`}
            onClick={() => setAllNotification(true)}
          >
            {t('notification.all')}
          </button>
          <button
            type="button"
            className={`p-link text-right ml-2 p-2 text-xl ${
              !allNotification ? 'bg-gray-200 border-round-lg font-semibold' : ''
            }`}
            onClick={() => setAllNotification(false)}
          >
            {t('notification.unRead')}
          </button>
        </div>
        <hr className="w-full hr-custom" />
        {notifications?.[0]?.length > 0 ? (
          <>
            {notifications?.map((page) =>
              page?.map((notification) => (
                <div
                  className="flex hover:bg-gray-200 align-items-center justify-content-between relative"
                  onMouseEnter={() => setShowDelButton(notification.id)}
                  onMouseLeave={() => setShowDelButton(null)}
                >
                  <Link
                    to={notification?.path}
                    key={notification.id}
                    onClick={(e) => {
                      e.preventDefault();
                      handleReadNotification(notification);
                    }}
                  >
                    <div className="p-3 cursor-pointer hover:bg-gray-200 border-round-lg ">
                      <h5 className="mb-2 text-lg font-semibold ">
                        {t(`notification.${notification?.type}`)}
                        <span className="font-base text-base text-purple-600">
                          &emsp;
                          {`${timeAgo(notification?.createdAt)}`}
                        </span>
                      </h5>
                      <p className="m-0 font-medium">{notification?.message}</p>
                    </div>
                  </Link>
                  <Button
                    icon="pi pi-trash"
                    outlined
                    rounded
                    severity="danger"
                    aria-label="Cancel"
                    className={`m-auto absolute left-50 top-0 z-5 ${
                      showDelButton === notification.id ? '' : 'hidden'
                    }`}
                    type="button"
                    onClick={() => confirmUpdateResult(notification?.id)}
                  />
                </div>
              ))
            )}
            <div
              className={
                (allNotification ? !hasNextPage : !_hasNextPage) || notifications?.[0]?.length === 0
                  ? 'hidden'
                  : ''
              }
            >
              <Button
                label={t('notification.viewMore')}
                loading={isFetchingNextPage || _isFetchingNextPage}
                onClick={allNotification ? fetchNextPage : _fetchNextPage}
                className="w-full"
              />
            </div>
          </>
        ) : (
          <div className="flex flex-column justify-content-center">
            <img
              src="/layout/images/notifications_dark_mode.svg"
              alt="no-noti"
              className="w-1/2 h-12rem mx-auto"
            />
            <h5 className="mb-4 mt-0 text-center font-semibold">{t('notification.noNoti')}</h5>
          </div>
        )}
      </div>
      <ConfirmDialog />
      <ScrollTop target="parent" />
    </>
  );
}
