/* eslint-disabled no-unused-vars */
import './style.scss';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextareaInput, CurrencyInput } from 'components/FormControl';

import AdjustmentForm from './AdjustmentForm';

function Form({ errors, control, watch, readOnly }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="px-5 pb-2">
        <div className="flex mt-2 mb-4 justify-content-between align-items-center">
          <h4>{t('financialAdjustment.title')}</h4>
          <p className="text-right ">{t('financialAdjustment.unit')}</p>
        </div>
        <AdjustmentForm watch={watch} control={control} errors={errors} readOnly={readOnly} />
        <div className="grid p-fluid mt-2">
          <div className="col-12">
            <div className="col-3 p-0">
              <CurrencyInput
                label={t('financialAdjustment.otherExpenditure')}
                name="otherExpenditure"
                control={control}
                errors={errors}
                readOnly={readOnly}
              />
            </div>

            <div className="col-6 p-0">
              <h4>{t('financialAdjustment.commitment')}</h4>
              <TextareaInput
                name="commitment"
                control={control}
                errors={errors}
                readOnly={readOnly}
                row={3}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Form.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  readOnly: PropTypes.bool,
  watch: PropTypes.func.isRequired,
};

Form.defaultProps = {
  readOnly: false,
};

export default Form;
