import '../../style.scss';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import PropTypes from 'prop-types';

const titleCustomized = (icon, label) => (
  <div className="flex align-items-center">
    <span className={icon} />
    <span className="font-bold text-lg">{label}</span>
  </div>
);

export default function OtherInfoCard({ otherInfoData }) {
  const { t } = useTranslation();

  const [isTextShown, setIsTextShown] = useState(false);

  const handleShowMoreLess = () => {
    setIsTextShown(!isTextShown);
  };

  const formatTimePeriod = (rowData) =>
    `${rowData?.start ? new Date(rowData?.start).toLocaleDateString('en-GB') : ''} - ${
      rowData?.end ? new Date(rowData?.end).toLocaleDateString('en-GB') : ''
    }`;

  const itemGenneralInfo = (icon, label1, content) => (
    <div className="text-base mt-2">
      <span className={`${icon} text-gray-900`} />
      <span className="text-gray-900 text-base font-medium">{label1}: </span>
      {isTextShown || typeof content !== 'string' || content.length <= 50
        ? content
        : `${content?.substring(0, 50)}...`}
      {typeof content === 'string' && content.length > 50 && (
        <button
          type="button"
          style={{ border: '0', background: 'none', cursor: 'pointer' }}
          className="hover:underline text-gray-900 font-medium text-base"
          onClick={handleShowMoreLess}
        >
          {isTextShown ? 'Show Less' : 'More'}
        </button>
      )}
    </div>
  );

  return (
    <Card
      className="custom-card mt-4 relative"
      title={titleCustomized(
        '',
        otherInfoData?.programName || otherInfoData?.conferenceName || otherInfoData?.universityName
      )}
    >
      <span className="text-base">
        {otherInfoData?.position || otherInfoData?.participationContent}
      </span>

      {itemGenneralInfo(
        'pi pi-clock mr-2',
        t('scientificProfile.otherInfo.programAttendance.timePeriod'),
        formatTimePeriod(otherInfoData)
      )}
    </Card>
  );
}

OtherInfoCard.propTypes = {
  otherInfoData: PropTypes.shape().isRequired,
};

OtherInfoCard.defaultProps = {};
