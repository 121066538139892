import FinancialReviewFormCreate from 'features/FinancialReview/pages/Create';
import Layout from 'layout';
import { LayoutProvider } from '../layout/context/layoutcontext';

export default function FinancialReviewFormCreatePage() {
  return (
    <LayoutProvider>
      <Layout
        style={{
          maxHeight: 'calc(100vh - 9rem)',
          overflowY: 'auto',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
        }}
      >
        <FinancialReviewFormCreate />
      </Layout>
    </LayoutProvider>
  );
}
