import { useEffect, useState } from 'react';

import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';

// import { formDefaultValue } from 'features/ScientificProfile/defaultValues';
import Form from 'features/ScientificProfile/components/Form';
import { updateScientificProfileValidationSchema } from 'features/ScientificProfile/validation';

import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';

import {
  getAcademicHistory,
  getGeneralInfo,
  getOthersInfo,
  getProjects,
  getScientificWorks,
  getThesisGuidances,
  getWorkHistory,
  update,
} from 'apis/scientificProfiles.api';

import { TOAST } from 'constant';
import { toast } from 'layout';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';

import {
  mapError,
  modifyBindingScientificProfileAcademicHistoryData,
  modifyBindingScientificProfileGeneralData,
  modifyBindingScientificProfileGuidingActivitiesData,
  modifyBindingScientificProfileOthersData,
  modifyBindingScientificProfileResearchActivitiesData,
  modifyBindingScientificProfileScientificWorksData,
  modifyBindingScientificProfileWorkHistoryData,
  modifyScientificProfileAcademicHistoryUpdateData,
  modifyScientificProfileGeneralUpdateData,
  modifyScientificProfileGuidingActivitiesUpdateData,
  modifyScientificProfileOthersUpdateData,
  modifyScientificProfileResearchActivitiesUpdateData,
  modifyScientificProfileScientificWorksUpdateData,
  modifyScientificProfileWorkHistoryUpdateData,
} from 'utils/func';

const formOptions = { resolver: yupResolver(updateScientificProfileValidationSchema) };

export default function ScientificProfileEdit() {
  // #region Data
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);
  const [errorDuplicateDialogVisible, setErrorDuplicateDialogVisible] = useState([false, [], null]);
  const [selectedTabs, setSelectedTabs] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isNextTab, setIsNextTab] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);

  const {
    data: generalInfoData,
    isError: isGeneralInfoError,
    error: generalInfoError,
    isLoading: isGeneralInfoLoading,
    isFetching: isGeneralInfoFetching,
  } = useQuery('scientificProfileGeneralInfo', () => getGeneralInfo(), {
    // Call back api when current tab index = 0 or 7 (tab 7 need data to bind into review)
    // and tab 0 is not selected
    enabled: (currentTab === 0 || currentTab === 7) && !selectedTabs[0],
  });

  const {
    data: academicHistoryData,
    isError: isAcademicHistoryError,
    error: academicHistoryError,
    isLoading: isAcademicHistoryLoading,
    isFetching: isAcademicHistoryFetching,
  } = useQuery('scientificProfileAcademicHistory', () => getAcademicHistory(), {
    enabled: (currentTab === 1 || currentTab === 7) && !selectedTabs[1],
  });

  const {
    data: workHistoryData,
    isError: isWorkHistoryError,
    error: workHistoryError,
    isLoading: isWorkHistoryLoading,
    isFetching: isWorkHistoryFetching,
  } = useQuery('scientificProfileWorkHistory', () => getWorkHistory(), {
    enabled: (currentTab === 2 || currentTab === 7) && !selectedTabs[2],
  });

  const {
    data: projectsData,
    isError: isProjectsError,
    error: projectsError,
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
  } = useQuery('scientificProfileProjects', () => getProjects(), {
    enabled: (currentTab === 3 || currentTab === 7) && !selectedTabs[3],
  });

  const {
    data: thesisGuidancesData,
    isError: isThesisGuidancesError,
    error: thesisGuidancesError,
    isLoading: isThesisGuidancesLoading,
    isFetching: isThesisGuidancesFetching,
  } = useQuery('scientificProfileThesisGuidances', () => getThesisGuidances(), {
    enabled: (currentTab === 4 || currentTab === 7) && !selectedTabs[4],
  });

  const {
    data: scientificWorksData,
    isError: isScientificWorksError,
    error: scientificWorksError,
    isLoading: isScientificWorksLoading,
    isFetching: isScientificWorksFetching,
  } = useQuery('scientificProfileScientificWorks', () => getScientificWorks(), {
    enabled: (currentTab === 5 || currentTab === 7) && !selectedTabs[5],
  });

  const {
    data: othersInfoData,
    isError: isOthersInfoError,
    error: othersInfoError,
    isLoading: isOthersInfoLoading,
    isFetching: isOthersInfoFetching,
  } = useQuery('scientificProfileOthersInfo', () => getOthersInfo(), {
    enabled: (currentTab === 6 || currentTab === 7) && !selectedTabs[6],
  });

  const {
    mutate,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
  } = useMutation(update);

  const { control, handleSubmit, trigger, watch, reset, setValue, getValues } = useForm({
    mode: 'onChange',
    // defaultValues: formDefaultValue,
    ...formOptions,
    shouldFocusError: true,
  });

  const { errors, isDirty, dirtyFields, isValid, isSubmitting } = useFormState({
    control,
  });

  const items = [
    {
      label: t('scientificProfile.generalInfo.label'),
    },
    {
      label: t('scientificProfile.academicHistory.label'),
    },
    {
      label: t('scientificProfile.workHistory.label'),
    },
    {
      label: t('scientificProfile.researchActivity.label'),
    },
    {
      label: t('scientificProfile.guidingActivity.label'),
    },
    {
      label: t('scientificProfile.scientificWork.label'),
    },
    {
      label: t('scientificProfile.otherInfo.label'),
    },
    {
      label: t('scientificProfile.preview.label'),
    },
  ];
  // #endregion Data

  // #region Event
  // Set value into form fields
  useEffect(
    () => {
      // Binding when has data, current tab = 0 or 4 (4 need data to bind into review)
      // tab 0 is not selected and has new fetched data
      if (
        generalInfoData?.data &&
        (currentTab === 0 || currentTab === 7) &&
        !selectedTabs[0] &&
        !isGeneralInfoFetching
      ) {
        const bindingGeneralData = modifyBindingScientificProfileGeneralData(generalInfoData.data);

        setValue('general', bindingGeneralData);
        setValue('general.foreignLanguages', bindingGeneralData?.foreignLanguages ?? []);
        setValue('general.researchLines', bindingGeneralData?.researchLines ?? []);

        // Mark selected tab when current tab = 0 and new fetched data
        // If the data has not been fetched, do not mark it because it will bind when has new data
        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[0] = true;
        setSelectedTabs(newSelectedTabs);
      }

      if (
        academicHistoryData?.data &&
        (currentTab === 1 || currentTab === 7) &&
        !selectedTabs[1] &&
        !isAcademicHistoryFetching
      ) {
        const bindingAcademicHistoryData = modifyBindingScientificProfileAcademicHistoryData(
          academicHistoryData.data
        );

        setValue('academicHistory.universities', bindingAcademicHistoryData?.universities ?? []);
        setValue('academicHistory.masters', bindingAcademicHistoryData?.masters ?? []);
        setValue('academicHistory.doctorates', bindingAcademicHistoryData?.doctorates ?? []);
        setValue(
          'academicHistory.scienceDoctorates',
          bindingAcademicHistoryData?.scienceDoctorates ?? []
        );

        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[1] = true;
        setSelectedTabs(newSelectedTabs);
      }

      if (
        workHistoryData?.data &&
        (currentTab === 2 || currentTab === 7) &&
        !selectedTabs[2] &&
        !isWorkHistoryFetching
      ) {
        const bindingWorkHistoryData = modifyBindingScientificProfileWorkHistoryData(
          workHistoryData.data
        );

        setValue('workHistory', bindingWorkHistoryData ?? []);

        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[2] = true;
        setSelectedTabs(newSelectedTabs);
      }

      if (
        projectsData?.data &&
        (currentTab === 3 || currentTab === 7) &&
        !selectedTabs[3] &&
        !isProjectsFetching
      ) {
        const bindingResearchActivitiesData = modifyBindingScientificProfileResearchActivitiesData(
          projectsData.data
        );

        setValue('researchActivities', bindingResearchActivitiesData ?? []);

        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[3] = true;
        setSelectedTabs(newSelectedTabs);
      }

      if (
        thesisGuidancesData?.data &&
        (currentTab === 4 || currentTab === 7) &&
        !selectedTabs[4] &&
        !isThesisGuidancesFetching
      ) {
        const bindingGuidingActivitiesData = modifyBindingScientificProfileGuidingActivitiesData(
          thesisGuidancesData.data
        );

        setValue('guidingActivities', bindingGuidingActivitiesData ?? []);

        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[4] = true;
        setSelectedTabs(newSelectedTabs);
      }

      if (
        scientificWorksData?.data &&
        (currentTab === 5 || currentTab === 7) &&
        !selectedTabs[5] &&
        !isScientificWorksFetching
      ) {
        const bindingScientificWorksData = modifyBindingScientificProfileScientificWorksData(
          scientificWorksData.data
        );

        setValue(
          'scientificWorks.internationalBooks',
          bindingScientificWorksData?.internationalBooks ?? []
        );
        setValue('scientificWorks.domesticBooks', bindingScientificWorksData?.domesticBooks ?? []);
        setValue(
          'scientificWorks.internationalPapers',
          bindingScientificWorksData?.internationalPapers ?? []
        );
        setValue(
          'scientificWorks.domesticPapers',
          bindingScientificWorksData?.domesticPapers ?? []
        );
        setValue(
          'scientificWorks.internationalConferencePapers',
          bindingScientificWorksData?.internationalConferencePapers ?? []
        );
        setValue(
          'scientificWorks.domesticConferencePapers',
          bindingScientificWorksData?.domesticConferencePapers ?? []
        );

        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[5] = true;
        setSelectedTabs(newSelectedTabs);
      }

      if (
        othersInfoData?.data &&
        (currentTab === 6 || currentTab === 7) &&
        !selectedTabs[6] &&
        !isOthersInfoFetching
      ) {
        const bindingOthersData = modifyBindingScientificProfileOthersData(othersInfoData.data);

        setValue('others.awards', bindingOthersData?.awards ?? []);
        setValue('others.patents', bindingOthersData?.patents ?? []);
        setValue('others.usefulSolutions', bindingOthersData?.usefulSolutions ?? []);
        setValue('others.transferredSolutions', bindingOthersData?.transferredSolutions ?? []);
        setValue('others.programAttendances', bindingOthersData?.programAttendances ?? []);
        setValue('others.conferenceAttendances', bindingOthersData?.conferenceAttendances ?? []);
        setValue(
          'others.universityVisitingStaff',
          bindingOthersData?.universityVisitingStaff ?? []
        );

        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[6] = true;
        setSelectedTabs(newSelectedTabs);
      }

      // If being on Preview tab -> wait for all api fetched -> mark all tabs to 'selected'
      if (
        !(
          isGeneralInfoFetching ||
          isAcademicHistoryFetching ||
          isWorkHistoryFetching ||
          isProjectsFetching ||
          isThesisGuidancesFetching ||
          isScientificWorksFetching ||
          isOthersInfoFetching
        ) &&
        currentTab === 7
      ) {
        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[0] = true;
        newSelectedTabs[1] = true;
        newSelectedTabs[2] = true;
        newSelectedTabs[3] = true;
        newSelectedTabs[4] = true;
        newSelectedTabs[5] = true;
        newSelectedTabs[6] = true;
        newSelectedTabs[7] = true;
        setSelectedTabs(newSelectedTabs);
      }
    },
    // rerender when fetch new data
    [
      isGeneralInfoFetching,
      isAcademicHistoryFetching,
      isWorkHistoryFetching,
      isProjectsFetching,
      isThesisGuidancesFetching,
      isScientificWorksFetching,
      isOthersInfoFetching,
    ]
  );

  const findDuplicatePapersTitles = (articles) => {
    const titleOccurrences = {}; // Object to store title occurrences
    let duplicates = [];
    // Iterate through each article
    articles.forEach((article) => {
      const title = article?.paper?.title; // Get title of the article
      if (titleOccurrences[title]) {
        duplicates = [titleOccurrences[title], article];
      }

      titleOccurrences[title] = article; // Store the article as value for the title
    });
    return duplicates; // If no duplicate titles found, return null
  };

  // book
  const findDuplicateBookTitles = (books) => {
    const titleOccurrences = {}; // Object to store title occurrences
    let duplicates = [];
    // Iterate through each book
    books.forEach((book) => {
      const title = book?.book?.title; // Get title of the book
      if (titleOccurrences[title]) {
        duplicates = [titleOccurrences[title], book];
      }

      titleOccurrences[title] = book; // Store the book as value for the title
    });
    return duplicates; // If no duplicate titles found, return null
  };

  const mapPublicationType = (type) => {
    if (type === 'INTERNATIONAL') {
      return t('scientificProfile.scientificWork.paper.international.label');
    }
    if (type === 'DOMESTIC') {
      return t('scientificProfile.scientificWork.paper.domestic.label');
    }
    if (type === 'INTERNATIONAL_CONFERENCE') {
      return t('scientificProfile.scientificWork.paper.internationalConference.label');
    }
    if (type === 'DOMESTIC_CONFERENCE') {
      return t('scientificProfile.scientificWork.paper.domesticConference.label');
    }
    if (type === 'DOMESTIC_BOOK') {
      return t('scientificProfile.scientificWork.book.domestic.label');
    }
    if (type === 'INTERNATIONAL_BOOK') {
      return t('scientificProfile.scientificWork.book.international.label');
    }
    return '';
  };

  const onSubmit = (data) => {
    if (!(isDirty && Object.keys(dirtyFields).length)) {
      if (isNextTab) {
        setCurrentTab((cur) => cur + 1);
        setIsNextTab(false);
      }
      return;
    }

    const updateGeneral = selectedTabs[0] ? modifyScientificProfileGeneralUpdateData(data) : null;
    const updateAcademicHistory = selectedTabs[1]
      ? modifyScientificProfileAcademicHistoryUpdateData(data)
      : null;
    const updateWorkHistory = selectedTabs[2]
      ? modifyScientificProfileWorkHistoryUpdateData(data)
      : null;
    const updateResearchActivities = selectedTabs[3]
      ? modifyScientificProfileResearchActivitiesUpdateData(data)
      : null;
    const updateGuidingActivities = selectedTabs[4]
      ? modifyScientificProfileGuidingActivitiesUpdateData(data)
      : null;
    const updateScientificWorks = selectedTabs[5]
      ? modifyScientificProfileScientificWorksUpdateData(data)
      : null;
    const updateOthers = selectedTabs[6] ? modifyScientificProfileOthersUpdateData(data) : null;

    // check duplicate papers
    if (updateScientificWorks?.scientificProfilePapers) {
      const duplicates = findDuplicatePapersTitles(updateScientificWorks?.scientificProfilePapers);
      if (duplicates.length > 0) {
        setErrorDuplicateDialogVisible([true, duplicates, 'hasDuplicateScientificProfilePapers']);
        return;
      }
    }

    // check duplicate books
    if (updateScientificWorks?.scientificProfileBooks) {
      const duplicatesBooks = findDuplicateBookTitles(
        updateScientificWorks?.scientificProfileBooks
      );
      if (duplicatesBooks.length > 0) {
        setErrorDuplicateDialogVisible([
          true,
          duplicatesBooks,
          'hasDuplicateScientificProfileBooks',
        ]);
        return;
      }
    }

    let updateData = {};

    updateData = {
      ...updateGeneral,
      ...(updateAcademicHistory !== null && { academicHistory: updateAcademicHistory }),
      ...(updateWorkHistory !== null && { workHistory: updateWorkHistory }),
      ...(updateResearchActivities !== null && { projects: updateResearchActivities }),
      ...(updateGuidingActivities !== null && { thesisGuidances: updateGuidingActivities }),
      ...updateScientificWorks,
      ...updateOthers,
    };

    mutate(updateData, {
      onSuccess: () => {
        reset({ ...data });
        // modify roi reset?

        if (currentTab === 7) {
          toast(TOAST.SUCCESS, t('scientificProfile.updateSuccess'));
          return;
        }

        if (isNextTab) {
          setCurrentTab((cur) => cur + 1);
          setIsNextTab(false);
        } else {
          toast(TOAST.SUCCESS, t('scientificProfile.updateSuccess'));
        }
      },
      onError: () => {
        setErrorDialogVisible(true);
      },
    });
  };

  const nextTab = async () => {
    if (currentTab === 0) {
      const isGeneralInfoValid = await trigger('general', { shouldFocus: true });
      if (isGeneralInfoValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 1) {
      const isAcademicHistoryValid = await trigger('academicHistory', { shouldFocus: true });
      if (isAcademicHistoryValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 2) {
      const isWorkHistoryValid = await trigger('workHistory', { shouldFocus: true });
      if (isWorkHistoryValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 3) {
      const isResearchActivitiesValid = await trigger('researchActivities', { shouldFocus: true });
      if (isResearchActivitiesValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 4) {
      const isGuidingActivitiesValid = await trigger('guidingActivities', { shouldFocus: true });
      if (isGuidingActivitiesValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 5) {
      const isScientificWorksValid = await trigger('scientificWorks', { shouldFocus: true });
      if (isScientificWorksValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 6) {
      const isOthersValid = await trigger('others', { shouldFocus: true });
      if (isOthersValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else {
      setCurrentTab((cur) => cur + 1);
    }
  };

  const handleTabChange = async (e) => {
    // Can not use switch because of eslint error (conflict Prettier)
    if (currentTab === 0) {
      const isGeneralInfoValid = await trigger('general', { shouldFocus: true });
      if (isGeneralInfoValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 1) {
      const isAcademicHistoryValid = await trigger('academicHistory', { shouldFocus: true });
      if (isAcademicHistoryValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 2) {
      const isWorkHistoryValid = await trigger('workHistory', { shouldFocus: true });
      if (isWorkHistoryValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 3) {
      const isResearchActivitiesValid = await trigger('researchActivities', { shouldFocus: true });
      if (isResearchActivitiesValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 4) {
      const isGuidingActivitiesValid = await trigger('guidingActivities', { shouldFocus: true });
      if (isGuidingActivitiesValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 5) {
      const isScientificWorksValid = await trigger('scientificWorks', { shouldFocus: true });
      if (isScientificWorksValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 6) {
      const isOthersValid = await trigger('others', { shouldFocus: true });
      if (isOthersValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else {
      setCurrentTab(e.index);
    }
  };

  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };
  // #endregion Event

  const footerError = (
    <Button
      label={t('projectProposal.ok')}
      className="w-7rem"
      severity="danger"
      onClick={() => navigate(-1)}
      autoFocus
    />
  );

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );

  if (isGeneralInfoError) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, generalInfoError)}</p>
      </Dialog>
    );
  }

  if (isAcademicHistoryError) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, academicHistoryError)}</p>
      </Dialog>
    );
  }

  if (isWorkHistoryError) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, workHistoryError)}</p>
      </Dialog>
    );
  }

  if (isProjectsError) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, projectsError)}</p>
      </Dialog>
    );
  }

  if (isThesisGuidancesError) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, thesisGuidancesError)}</p>
      </Dialog>
    );
  }

  if (isScientificWorksError) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, scientificWorksError)}</p>
      </Dialog>
    );
  }

  if (isOthersInfoError) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, othersInfoError)}</p>
      </Dialog>
    );
  }

  return (
    <>
      {/* #region Loading */}
      <div className="sticky top-0 left-0 z-5">
        <div className="relative">
          {(isSubmitting ||
            isUpdateLoading ||
            isGeneralInfoLoading ||
            isAcademicHistoryLoading ||
            isWorkHistoryLoading ||
            isProjectsLoading ||
            isThesisGuidancesLoading ||
            isScientificWorksLoading ||
            isOthersInfoLoading ||
            isGeneralInfoFetching ||
            isAcademicHistoryFetching ||
            isWorkHistoryFetching ||
            isProjectsFetching ||
            isThesisGuidancesFetching ||
            isScientificWorksFetching ||
            isOthersInfoFetching) && <Loading />}
        </div>
      </div>
      {/* #endregion Loading */}

      <BlockUI
        baseZIndex={3}
        blocked={
          isSubmitting ||
          isUpdateLoading ||
          isGeneralInfoLoading ||
          isAcademicHistoryLoading ||
          isWorkHistoryLoading ||
          isProjectsLoading ||
          isThesisGuidancesLoading ||
          isScientificWorksLoading ||
          isOthersInfoLoading ||
          isGeneralInfoFetching ||
          isAcademicHistoryFetching ||
          isWorkHistoryFetching ||
          isProjectsFetching ||
          isThesisGuidancesFetching ||
          isScientificWorksFetching ||
          isOthersInfoFetching
        }
      >
        <Steps model={items} activeIndex={currentTab} onSelect={handleTabChange} readOnly={false} />
        <FormProvider {...{ control, errors, watch, setValue, getValues, dirtyFields }}>
          <Form
            control={control}
            errors={errors}
            disabledSubmit={!(isDirty && Object.keys(dirtyFields).length)}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            handleSubmit={handleSubmit(onSubmit)}
            tab={currentTab}
            nextTab={nextTab}
            selectedTabs={selectedTabs}
            setSelectedTabs={setSelectedTabs}
            checkValidation={checkValidation}
            checkKeyDown={checkKeyDown}
            isDirty={isDirty}
            footerConfirmationPrompt={footerConfirmationPrompt}
          />
        </FormProvider>
      </BlockUI>

      <ReactRouterPrompt when={Object.keys(dirtyFields)?.length}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('scientificProfile.confirmationSavePromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>

      {/* #region Update */}
      {isUpdateError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.updateErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(updateError?.response.data?.message) ? (
            updateError.response.data?.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, updateError)}</p>
          )}
        </Dialog>
      )}

      <Dialog
        header={<p className="text-red-500">{t('errorMessage.updateScientificProfile')}</p>}
        visible={errorDuplicateDialogVisible[0]}
        position="center"
        style={{ width: '600px' }}
        onHide={() => setErrorDuplicateDialogVisible([false, [], null])}
        draggable={false}
      >
        <p className="text-lg text-red-500">
          {t(`errorMessage.${errorDuplicateDialogVisible[2]}`)}
        </p>
        <div className="flex py-3 content-start">
          <p className="text-lg text-red-500 font-semibold">Title: </p>
          <p className="text-base text-justify">
            {errorDuplicateDialogVisible[2] === 'hasDuplicateScientificProfilePapers'
              ? errorDuplicateDialogVisible[1]?.[0]?.paper?.title
              : errorDuplicateDialogVisible[1]?.[0]?.book?.title}
          </p>
        </div>

        <div className="py-3 content-start">
          <p className="text-lg text-red-500 font-semibold">Duplicate Entries: </p>

          <div>
            <ul className="list-disc">
              {errorDuplicateDialogVisible[1]?.map((item) => (
                <li className="text-base text-justify">
                  {errorDuplicateDialogVisible[2] === 'hasDuplicateScientificProfilePapers'
                    ? mapPublicationType(item?.paper?.publicationType)
                    : mapPublicationType(item?.book?.publicationType)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Dialog>
      {/* #endregion Update */}
    </>
  );
}
