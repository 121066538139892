import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AcademicHistoryDialog from './AcademicHistoryDialog';

export default function AcademicHistoryForm({ control, shouldUpdateFromDocx }) {
  // #region Data
  const { t } = useTranslation();
  const { dirtyFields } = useFormContext(); // retrieve all hook methods

  const academicHistoryRef = useRef();

  const {
    fields: universityFields,
    append: appendUniversity,
    remove: removeUniversity,
    update: updateUniversity,
  } = useFieldArray({
    control,
    name: 'academicHistory.universities',
  });
  const {
    fields: masterFields,
    append: appendMaster,
    remove: removeMaster,
    update: updateMaster,
  } = useFieldArray({
    control,
    name: 'academicHistory.masters',
  });
  const {
    fields: doctorateFields,
    append: appendDoctorate,
    remove: removeDoctorate,
    update: updateDoctorate,
  } = useFieldArray({
    control,
    name: 'academicHistory.doctorates',
  });
  const {
    fields: scienceDoctorateFields,
    append: appendScienceDoctorate,
    remove: removeScienceDoctorate,
    update: updateScienceDoctorate,
  } = useFieldArray({
    control,
    name: 'academicHistory.scienceDoctorates',
  });
  // #endregion

  // #region Event
  // #endregion
  return (
    <>
      <h4 className="text-center mb-4">{t('scientificProfile.academicHistory.label')}</h4>

      <div className="form-table mt-2 mb-3">
        <table className="mb-2">
          <thead>
            <tr className="font-bold">
              {/* <th className="text-center" style={{ minWidth: 120, width: 120 }}>
                Bậc đào tạo
              </th> */}
              <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                {t('formLayout.numericalOrder')}
              </th>
              <th className="text-center" style={{ minWidth: 200, width: '20%' }}>
                {t('scientificProfile.academicHistory.timePeriod')}
              </th>
              <th className="text-center" style={{ minWidth: 250, width: '25%' }}>
                {t('scientificProfile.academicHistory.address')}
              </th>
              <th className="text-center" style={{ minWidth: 200, width: '20%' }}>
                {t('scientificProfile.academicHistory.major')}
              </th>
              <th className="text-center" style={{ minWidth: 250, width: '25%' }}>
                {t('scientificProfile.academicHistory.thesisName')}
              </th>

              <th className="float text-center" style={{ minWidth: 80, width: 80 }}>
                {t('projectProposal.action')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={6}>
                <div>
                  <span className="font-bold">{t('academicDegree.university')}</span>
                  <i
                    onClick={() => {
                      academicHistoryRef.current.open({
                        academicDegree: 'university',
                        append: appendUniversity,
                        type: 'APPEND',
                      });
                    }}
                    className="add-university pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle ml-2"
                    data-pr-tooltip={t('formLayout.action.add')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".add-university" />
                </div>
              </td>
            </tr>

            {universityFields?.map((item, index) => (
              <tr
                key={item.id ?? index}
                className={classNames({
                  highlighted: shouldUpdateFromDocx && dirtyFields.academicHistory?.universities,
                })}
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                  {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                </td>
                <td>{item?.address}</td>
                <td>{item?.major}</td>
                <td>{item?.thesisName}</td>
                <td
                  className={`flex flex-column float text-center p-0 border-none ${
                    index !== 0 ? 'border-top-1' : ''
                  }`}
                >
                  <i
                    onClick={() => {
                      academicHistoryRef.current.open({
                        academicDegree: 'university',
                        update: updateUniversity,
                        index,
                        value: universityFields[index],
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil text-primary cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => removeUniversity(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              </tr>
            ))}

            <tr>
              <td colSpan={6}>
                <div>
                  <span className="font-bold">{t('academicDegree.master')}</span>
                  <i
                    onClick={() => {
                      academicHistoryRef.current.open({
                        academicDegree: 'master',
                        append: appendMaster,
                        type: 'APPEND',
                      });
                    }}
                    className="add-master pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle ml-2"
                    data-pr-tooltip={t('formLayout.action.add')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".add-master" />
                </div>
              </td>
            </tr>

            {masterFields?.map((item, index) => (
              <tr
                key={item.id ?? index}
                className={classNames({
                  highlighted: shouldUpdateFromDocx && dirtyFields.academicHistory?.masters,
                })}
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                  {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                </td>
                <td>{item?.address}</td>
                <td>{item?.major}</td>
                <td>{item?.thesisName}</td>
                <td
                  className={`flex flex-column float text-center p-0 border-none ${
                    index !== 0 ? 'border-top-1' : ''
                  }`}
                >
                  <i
                    onClick={() => {
                      academicHistoryRef.current.open({
                        academicDegree: 'master',
                        update: updateMaster,
                        index,
                        value: masterFields[index],
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil text-primary cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => removeMaster(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              </tr>
            ))}

            <tr>
              <td colSpan={6}>
                <span className="font-bold">{t('academicDegree.doctorate')}</span>
                <i
                  onClick={() => {
                    academicHistoryRef.current.open({
                      academicDegree: 'doctorate',
                      append: appendDoctorate,
                      type: 'APPEND',
                    });
                  }}
                  className="add-doctorate pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle ml-2"
                  data-pr-tooltip={t('formLayout.action.add')}
                  data-pr-position="left"
                />
                <Tooltip target=".add-doctorate" />
              </td>
            </tr>
            {doctorateFields?.map((item, index) => (
              <tr
                key={item.id ?? index}
                className={classNames({
                  highlighted: shouldUpdateFromDocx && dirtyFields.academicHistory?.doctorates,
                })}
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                  {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                </td>
                <td>{item?.address}</td>
                <td>{item?.major}</td>
                <td>{item?.thesisName}</td>
                <td
                  className={`flex flex-column float text-center p-0 border-none ${
                    index !== 0 ? 'border-top-1' : ''
                  }`}
                >
                  <i
                    onClick={() => {
                      academicHistoryRef.current.open({
                        academicDegree: 'doctorate',
                        update: updateDoctorate,
                        index,
                        value: doctorateFields[index],
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil text-primary cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => removeDoctorate(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              </tr>
            ))}

            <tr>
              <td colSpan={6}>
                <span className="font-bold">{t('academicDegree.scienceDoctorate')}</span>
                <i
                  onClick={() => {
                    academicHistoryRef.current.open({
                      academicDegree: 'scienceDoctorate',
                      append: appendScienceDoctorate,
                      type: 'APPEND',
                    });
                  }}
                  className="add-scienceDoctorate pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle ml-2"
                  data-pr-tooltip={t('formLayout.action.add')}
                  data-pr-position="left"
                />
                <Tooltip target=".add-scienceDoctorate" />
              </td>
            </tr>
            {scienceDoctorateFields?.map((item, index) => (
              <tr
                key={item.id ?? index}
                className={classNames({
                  highlighted:
                    shouldUpdateFromDocx && dirtyFields.academicHistory?.scienceDoctorates,
                })}
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                  {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                </td>
                <td>{item?.address}</td>
                <td>{item?.major}</td>
                <td>{item?.thesisName}</td>
                <td
                  className={`flex flex-column float text-center p-0 border-none ${
                    index !== 0 ? 'border-top-1' : ''
                  }`}
                >
                  <i
                    onClick={() => {
                      academicHistoryRef.current.open({
                        academicDegree: 'scienceDoctorate',
                        update: updateScienceDoctorate,
                        index,
                        value: scienceDoctorateFields[index],
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil text-primary cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => removeScienceDoctorate(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AcademicHistoryDialog ref={academicHistoryRef} />
    </>
  );
}

AcademicHistoryForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  shouldUpdateFromDocx: PropTypes.bool,
};

AcademicHistoryForm.defaultProps = {
  shouldUpdateFromDocx: false,
};
