/* eslint-disable no-unused-vars */

import { useFieldArray, useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import InternationalPaperDialog from './InternationalPaperDialog';

export default function InternationalPapersTable({ control, shouldUpdateFromDocx }) {
  // #region Data
  const { t } = useTranslation();

  const { dirtyFields } = useFormContext();

  const internationalPaperRef = useRef();
  const { fields, insert, update, remove } = useFieldArray({
    control,
    name: 'scientificWorks.internationalPapers',
  });

  const [expandedRows, setExpandedRows] = useState(null);

  // #endregion

  // #region Event
  // #endregion
  // #region Render
  const formatIndex = (_, { rowIndex }) => rowIndex + 1;

  const formatIsIsi = (rowData) =>
    rowData?.paper.isIsi
      ? t('scientificProfile.scientificWork.paper.international.yes')
      : t('scientificProfile.scientificWork.paper.international.no');

  const tagPaperScholar = (item) => {
    // Initialize jsxElement with null or any default value
    let jsxElement = null;

    if (item?.paper?.scopusId) {
      jsxElement = (
        <>
          <Tooltip target=".tag" mouseTrack mouseTrackLeft={10} va />
          <a
            href={'https://doi.org/'.concat(item?.paper?.doi)}
            target="_blank"
            rel="noreferrer"
            data-pr-tooltip="This paper is verified by Scopus"
            data-pr-position="left"
            className={classNames({
              'verification-tag': true,
              tag: true,
            })}
          >
            {t('scientificProfile.scientificWork.verifiedLabel')}
            <i
              className="pi pi-check-circle text-white text-center ml-1"
              style={{ fontSize: '0.7rem' }}
            />
          </a>
        </>
      );
    }

    // Return jsxElement or null if no conditions are met
    return jsxElement;
  };

  const formatActions = (rowData, { rowIndex }) => (
    <div className="flex flex-column float text-center p-0 border-none">
      <Tooltip target=".insert-icon-international-paper" />
      <i
        onClick={() => {
          internationalPaperRef.current.open({
            insert,
            index: rowIndex + 1,
            type: 'INSERT',
          });
        }}
        className="insert-icon-international-paper pi pi-plus text-primary cursor-pointer my-1"
        data-pr-tooltip={t('formLayout.action.insert')}
        data-pr-position="left"
        style={{ cursor: 'pointer' }}
      />
      <Tooltip target=".edit-icon-international-paper" />

      <i
        onClick={() => {
          internationalPaperRef.current.open({
            update,
            index: rowIndex,
            value: fields[rowIndex],
            type: 'UPDATE',
          });
        }}
        className="edit-icon-international-paper pi pi-pencil text-primary cursor-pointer mb-1"
        data-pr-tooltip={t('formLayout.action.edit')}
        data-pr-position="left"
        style={{ cursor: 'pointer' }}
      />

      <Tooltip target=".remove-icon-international-paper" />

      <i
        onClick={() => remove(rowIndex)}
        className="remove-icon-international-paper pi pi-times text-red-700 cursor-pointer mb-1"
        data-pr-tooltip={t('formLayout.action.remove')}
        data-pr-position="left"
        style={{ cursor: 'pointer' }}
      />
      {tagPaperScholar(rowData)}
    </div>
  );

  const rowExpansionTemplate = (item) => (
    <div className="m-2 text-lg">
      <div className="grid">
        <div className="col-12">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.title')}
            {': '}
          </strong>
          {item?.paper?.title}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.authors')}
            {': '}
          </strong>
          {item?.paper?.authors}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.journal')}
            {': '}
          </strong>
          {item?.paper?.journal}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.journalAbbrev')}
            {': '}
          </strong>
          {item?.paper?.journalAbbrev}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>{'DOI: '}</strong>
          <a href={'https://doi.org/'.concat(item?.paper?.doi)}>{item?.paper?.doi}</a>
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.year')}
            {': '}
          </strong>
          {item?.paper?.year}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.projectCode')}
            {': '}
          </strong>
          {item?.paper?.projectCode}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.issn')}
            {': '}
          </strong>
          {item?.paper?.issn}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.isIsi')}
            {': '}
          </strong>
          {item?.paper?.isIsi
            ? t('scientificProfile.scientificWork.paper.international.yes')
            : t('scientificProfile.scientificWork.paper.international.no')}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.if')}
            {': '}
          </strong>
          {item?.paper?.if}
        </div>
      </div>
    </div>
  );

  const rowHighlight = (rowData) => {
    let className = '';

    if (rowData?.paper?.scholarVerification?.status === 'VERIFIED') {
      className = 'highlighted-verified';
    } else if (rowData?.paper?.scholarVerification?.status === 'UNCERTAIN') {
      className = 'highlighted-uncertain';
    } else if (rowData?.paper?.scholarVerification?.status === 'NOT_FOUND') {
      className = 'highlighted-not-found';
    }
    return className;
  };

  // #endregion Render

  return (
    <>
      <DataTable
        value={fields}
        showGridlines
        filterDisplay="menu"
        loading={typeof fields?.length !== 'number'}
        scrollable
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        rowClassName={classNames({
          'highlighted-uncertain':
            shouldUpdateFromDocx && dirtyFields.scientificWorks?.internationalPapers,
        })}
        emptyMessage=" "
      >
        <Column expander style={{ width: '2rem' }} />
        <Column
          header={t('formLayout.numericalOrder')}
          headerClassName="text-center  "
          className="text-center"
          body={formatIndex}
          style={{ minWidth: 50, width: 50 }}
          expander
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.title')}
          headerClassName="text-center"
          field="paper.title"
        />

        <Column
          header={t('scientificProfile.scientificWork.paper.authors')}
          headerClassName="text-center"
          field="paper.authors"
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.journal')}
          headerClassName="text-center"
          field="paper.journal"
        />

        <Column
          header={t('scientificProfile.scientificWork.paper.year')}
          headerClassName="text-center"
          field="paper.year"
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.projectCode')}
          headerClassName="text-center"
          field="paper.projectCode"
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.issn')}
          headerClassName="text-center"
          style={{ minWidth: 100 }}
          field="paper.issn"
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.isIsi')}
          headerClassName="text-center"
          field="paper.isIsi"
          body={formatIsIsi}
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.if')}
          headerClassName="text-center"
          field="paper.if"
        />
        <Column
          header={t('formLayout.action.label')}
          headerClassName="text-center"
          style={{ minWidth: 80, width: 80 }}
          body={formatActions}
          frozen
          alignFrozen="right"
        />
      </DataTable>

      {fields?.length === 0 && (
        <div className="flex justify-content-center my-2">
          <i
            onClick={() => {
              internationalPaperRef.current.open({
                insert,
                index: 0,
                type: 'INSERT',
              });
            }}
            className="add-international-paper pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
            data-pr-tooltip={t('formLayout.action.add')}
            data-pr-position="left"
          />
          <Tooltip target=".add-international-paper" />
        </div>
      )}
      <InternationalPaperDialog ref={internationalPaperRef} />
    </>
  );
}

InternationalPapersTable.propTypes = {
  control: PropTypes.shape({}).isRequired,
  shouldUpdateFromDocx: PropTypes.bool,
};

InternationalPapersTable.defaultProps = {
  shouldUpdateFromDocx: false,
};
