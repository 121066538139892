import instance from 'utils/axios/instance.axios';
import { MIDTERM_REVIEW } from './_constant';

export const getGeneralProjectProposalInfo = (councilId, projectProposalId) =>
  instance.get(MIDTERM_REVIEW.GET_PROJECT_PROPOSAL_INFO, {
    params: { councilId, projectProposalId },
  });

export const getReviewerInfo = (councilId, projectProposalId) =>
  instance.get(MIDTERM_REVIEW.GET_REVIEWER_INFO, {
    params: { councilId, projectProposalId },
  });

export const getDetail = (councilMemberId, councilId, projectProposalId) =>
  instance.get(MIDTERM_REVIEW.GET_DETAIL, {
    params: { councilMemberId, councilId, projectProposalId },
  });

export const create = (data = {}) => instance.post(MIDTERM_REVIEW.CREATE, data);

export const update = (data = {}) => instance.patch(MIDTERM_REVIEW.UPDATE, data);

export const submit = (data = {}) => instance.patch(MIDTERM_REVIEW.SUBMIT, data);

// Download review file

export const getDetailFile = (councilMemberId, councilId, projectProposalId) =>
  instance.get(MIDTERM_REVIEW.GET_DETAIL_FILE, {
    params: { councilMemberId, councilId, projectProposalId },
  });

export const downloadFile = (body = {}) =>
  instance.post(MIDTERM_REVIEW.DOWNLOAD_FILE, body, { responseType: 'blob' });

export const downloadPdfFile = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.post(
    MIDTERM_REVIEW.DOWNLOAD_PDF_FILE,
    {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    { responseType: 'blob' }
  );

export const downloadPdfTemplate = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.post(
    MIDTERM_REVIEW.DOWNLOAD_PDF_TEMPLATE,
    {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    {
      responseType: 'blob',
    }
  );

export const downloadDocxFile = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.post(
    MIDTERM_REVIEW.DOWNLOAD_DOCX_FILE,
    {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    {
      responseType: 'blob',
    }
  );

export const downloadDocxTemplate = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.post(
    MIDTERM_REVIEW.DOWNLOAD_DOCX_TEMPLATE,
    {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    {
      responseType: 'blob',
    }
  );
