import { useTranslation } from 'react-i18next';

import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { Timeline } from 'primereact/timeline';

import PropTypes from 'prop-types';
import { toCamelCaseString } from 'utils/func';

export default function ViewHistory({ data, visible, setVisible }) {
  const { t } = useTranslation();

  const formatAction = (value) => {
    // eslint-disable-next-line no-unused-vars
    const statusColorMapping = {
      new: 'var(--blue-500)',
      revise: 'var(--orange-500)',
      submit: 'var(--green-500)',
      approved: 'var(--green-500)',

      requestUpdate: 'var(--yellow-500)',
      requestExplanationPrequalification: 'var(--pink-500)',
      requestExplanationSpecialization: 'var(--pink-500)',
      requestExplanationFinance: 'var(--pink-500)',

      completeUpdateRequest: 'var(--cyan-500)',
      completeExplanationRequestPrequalification: 'var(--purple-500)',
      completeExplanationRequestSpecialization: 'var(--purple-500)',
      completeExplanationRequestFinance: 'var(--purple-500)',
    };

    return (
      <Tag
        style={{ background: statusColorMapping[toCamelCaseString(value.actionType)] }}
        value={t(`projectProposal.viewHistory.status.${toCamelCaseString(value.actionType)}`)}
        rounded
      />
    );
  };
  const formatCreatedAt = (value) => {
    const datetime = new Date(value.createdAt);
    datetime.setHours(datetime.getHours() + 7);
    const formattedDatetime = datetime.toLocaleString('vi-VN', {
      timeZone: 'Asia/Ho_Chi_Minh',
    });
    return <small className="text-color-secondary">{formattedDatetime}</small>;
  };

  return (
    <Dialog
      header={<p className="text-blue-500">{t('projectProposal.viewHistory.title')}</p>}
      visible={visible}
      position="center"
      style={{ width: '500px' }}
      onHide={() => setVisible(false)}
      draggable={false}
    >
      <Timeline value={data} opposite={formatAction} content={formatCreatedAt} />
    </Dialog>
  );
}

ViewHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
