import { useEffect, useState } from 'react';

import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  getExpenditureById,
  getFileUrls,
  getGeneralInfoById,
  getResearchDescriptionById,
  getResearchResultById,
} from 'apis/projectProposals.api';

import Form from 'features/ProjectProposal/components/Form';
import formDefaultValues from 'features/ProjectProposal/defaultValues';
import createProjectProposalValidationSchema from 'features/ProjectProposal/validation';

import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import {
  mapError,
  modifyBindingProjectProposalExpenditureData,
  modifyBindingProjectProposalGeneralData,
  modifyBindingProjectProposalResearchDescriptionData,
  modifyBindingProjectProposalResearchResultData,
} from 'utils/func';

const formOptions = { resolver: yupResolver(createProjectProposalValidationSchema) };

export default function ProjectProposalDetailInformation() {
  // #region Data
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(4);
  const { id } = useParams();

  const {
    data: generalInfoData,
    isError: isGeneralInfoError,
    error: generalInfoError,
    isLoading: isGeneralInfoLoading,
    isFetching: isGeneralInfoFetching,
  } = useQuery(['projectProposalGeneralInfo', id], () => getGeneralInfoById(id));

  const {
    data: researchResultData,
    isError: isResearchResultError,
    error: researchResultError,
    isLoading: isResearchResultLoading,
    isFetching: isResearchResultFetching,
  } = useQuery(['projectProposalResearchResult', id], () => getResearchResultById(id));

  const {
    data: expenditureData,
    isError: isExpenditureError,
    error: expenditureError,
    isLoading: isExpenditureLoading,
    isFetching: isExpenditureFetching,
  } = useQuery(['projectProposalExpenditure', id], () => getExpenditureById(id));

  const {
    data: fileUrlsData,
    isError: isFileUrlsError,
    error: fileUrlsError,
    isLoading: isFileUrlsLoading,
    isFetching: isFileUrlsFetching,
  } = useQuery(['projectProposalFileUrls', id], () => getFileUrls(id));

  const {
    data: researchDescriptionData,
    isError: isResearchDescriptionError,
    error: researchDescriptionError,
    isLoading: isResearchDescriptionLoading,
    isFetching: isResearchDescriptionFetching,
  } = useQuery(['projectProposalResearchDescription', id], () => getResearchDescriptionById(id));

  const { control, watch, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: formDefaultValues,
    ...formOptions,
    shouldFocusError: true,
  });

  const { errors, isDirty, dirtyFields } = useFormState({
    control,
  });

  const items = [
    {
      label: t('projectProposal.general.label'),
    },
    {
      label: t('projectProposal.researchResult.label'),
    },
    {
      label: t('projectProposal.expenditure.label'),
    },
    {
      label: t('projectProposal.researchDescription.label'),
    },
    {
      label: t('projectProposal.viewDetail.label'),
    },
  ];
  // #endregion Data

  // #region Event
  // Set value into form fields
  useEffect(
    () => {
      if (id) {
        setValue('id', id);
      }
      // Binding when has data, current tab = 0 or 4 (4 need data to bind into review)
      // tab 0 is not selected and has new fetched data
      if (id && generalInfoData?.data && !isGeneralInfoFetching) {
        setValue('submittedAt', generalInfoData?.data?.submittedAt);

        const bindingGeneralData = modifyBindingProjectProposalGeneralData(generalInfoData.data);

        setValue('general', bindingGeneralData);
        setValue('general.recommendedExperts', bindingGeneralData.recommendedExperts);
        setValue('general.researchExpense', bindingGeneralData.researchExpense);
        setValue('general.owner.researchLines', bindingGeneralData?.owner?.researchLines ?? []);
        setValue('general.partners', bindingGeneralData.partners);
        setValue('general.scientificSecretaries', bindingGeneralData.scientificSecretaries);
        setValue('general.mainParticipants', bindingGeneralData.mainParticipants);
        setValue('general.participants', bindingGeneralData.participants);
        setValue('general.domesticExperts', bindingGeneralData.domesticExperts);
        setValue('general.internationalExperts', bindingGeneralData.internationalExperts);
      }

      if (id && researchResultData?.data && !isResearchResultFetching) {
        const bindingResearchResultData = modifyBindingProjectProposalResearchResultData(
          researchResultData.data
        );

        setValue(
          'researchResult.projectProposalResearchKpis',
          bindingResearchResultData.projectProposalResearchKpis
        );
        setValue('researchResult.softProducts', bindingResearchResultData.softProducts);
        setValue('researchResult.hardProducts', bindingResearchResultData.hardProducts);
        setValue('researchResult.qualityComparison', bindingResearchResultData.qualityComparison);
        setValue(
          'researchResult.knowledgeContributionLevel',
          bindingResearchResultData.knowledgeContributionLevel
        );
        setValue(
          'researchResult.practicalPolicyImpact',
          bindingResearchResultData.practicalPolicyImpact
        );
        setValue(
          'researchResult.teamDevelopmentLevel',
          bindingResearchResultData.teamDevelopmentLevel
        );
        setValue(
          'researchResult.researchTransferability',
          bindingResearchResultData.researchTransferability
        );
      }

      if (id && expenditureData?.data && !isExpenditureFetching && !isFileUrlsFetching) {
        const bindingExpenditureData = modifyBindingProjectProposalExpenditureData(
          expenditureData.data
        );

        setValue(
          'expenditure.proposedExpenditureSummaries',
          bindingExpenditureData.proposedExpenditureSummaries
        );

        if (fileUrlsData?.data?.expenditureFileUrl) {
          setValue('expenditure.uploadedFileUrl', fileUrlsData?.data?.expenditureFileUrl);
        }
      }

      if (fileUrlsData?.data?.researchDescriptionFileUrl && !isFileUrlsFetching) {
        setValue(
          'researchDescription.uploadedFileUrl',
          fileUrlsData?.data?.researchDescriptionFileUrl
        );
      }

      if (id && researchDescriptionData?.data && !isResearchDescriptionFetching) {
        const bindingResearchDescriptionData = modifyBindingProjectProposalResearchDescriptionData(
          researchDescriptionData.data
        );

        setValue(
          'researchDescription.otherProofsAndAppendicesFiles',
          bindingResearchDescriptionData?.otherProofsAndAppendicesFiles
        );
      }
    },

    // rerender when fetch new data
    [
      id,
      isGeneralInfoFetching,
      isResearchResultFetching,
      isExpenditureFetching,
      isFileUrlsFetching,
      isResearchDescriptionFetching,
    ]
  );

  const nextTab = async () => {
    setCurrentTab((cur) => cur + 1);
  };

  const handleTabChange = async (e) => {
    setCurrentTab(e.index);
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };
  // #endregion Event

  const footerError = (
    <Button
      label={t('projectProposal.ok')}
      className="w-7rem"
      severity="info"
      onClick={() => navigate(-1)}
      autoFocus
    />
  );

  if (isGeneralInfoError && id) {
    return (
      <Dialog
        header="Error"
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0">{mapError(t, generalInfoError)}</p>
      </Dialog>
    );
  }
  if (isResearchResultError && id) {
    return (
      <Dialog
        header="Error"
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0">{mapError(t, researchResultError)}</p>
      </Dialog>
    );
  }
  if (isExpenditureError && id) {
    return (
      <Dialog
        header="Error"
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0">{mapError(t, expenditureError)}</p>
      </Dialog>
    );
  }

  if (isFileUrlsError && id) {
    return (
      <Dialog
        header="Error"
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0">{mapError(t, fileUrlsError)}</p>
      </Dialog>
    );
  }

  if (isResearchDescriptionError && id) {
    return (
      <Dialog
        header="Error"
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0">{mapError(t, researchDescriptionError)}</p>
      </Dialog>
    );
  }

  if (
    (isGeneralInfoLoading ||
      isResearchResultLoading ||
      isExpenditureLoading ||
      isFileUrlsLoading ||
      isResearchDescriptionLoading) &&
    id
  ) {
    return <Loading />;
  }

  return (
    <>
      {/* #region Loading */}
      <Steps model={items} activeIndex={currentTab} onSelect={handleTabChange} readOnly={false} />

      <Form
        control={control}
        errors={errors}
        disabledSave={!(isDirty && Object.keys(dirtyFields).length)}
        watch={watch}
        setValue={setValue}
        getValues={getValues}
        handleSubmit={() => null}
        tab={currentTab}
        nextTab={nextTab}
        selectedTabs={[]}
        checkValidation={() => null}
        checkKeyDown={checkKeyDown}
        projectProposalResearchKpis={researchResultData?.data?.projectProposalResearchKpis}
        disabled
      />
    </>
  );
}
