import i18n from 'i18next';
import axios from 'axios';
import { FORMDATA_HEADER, JSON_HEADER } from './header.axios';
import TokenService from './token.axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { ...JSON_HEADER },
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    if (config.data instanceof FormData) {
      // eslint-disable-next-line no-param-reassign
      config.headers = { ...FORMDATA_HEADER };
    }
    // const token = TokenService.getLocalAccessToken();
    // console.log('token', token);
    // if (token) {
    //   if (config.data instanceof FormData) {
    //     // eslint-disable-next-line no-param-reassign
    //     config.headers = { ...FORMDATA_HEADER };
    //   }
    //   // eslint-disable-next-line no-param-reassign
    //   config.headers.Authorization = `Bearer ${token}`;
    // } else {
    //   // eslint-disable-next-line no-param-reassign
    //   delete config.headers.Authorization;
    // }

    if (config.data instanceof FormData) {
      // eslint-disable-next-line no-param-reassign
      config.headers = { ...FORMDATA_HEADER };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

let isExpired = false;
instance.interceptors.response.use(
  (res) => {
    isExpired = false;
    const { url } = res.config;
    if (url === '/users/login') {
      TokenService.setUser(res.data);
    }
    return res;
  },
  // eslint-disable-next-line consistent-return
  (err) => {
    if (
      (err?.response?.status === 401 && !isExpired) ||
      (err?.response?.status === 403 && !isExpired)
    ) {
      isExpired = true;
      if (TokenService.getUser()?.id) {
        // eslint-disable-next-line no-alert
        alert(i18n.t`error.401.message`);
      }
      TokenService.removeUser();
      window.location = '/login';
    } else return Promise.reject(err);
  }
);

export default instance;
