import instance from 'utils/axios/instance.axios';
import { REGISTER_RESULT } from './_constant';

export const createRegisterResult = (body = {}) =>
  instance.post(REGISTER_RESULT.CREATE_REGITTER_RESULT, body);

export const updateRegisterResult = ({ data, id }) =>
  instance.patch(REGISTER_RESULT.UPDATE_REGITTER_RESULT(id), data);

export const submitRegisterResult = (registerResultId) =>
  instance.patch(REGISTER_RESULT.UPDATE_SUBMIT_REGITTER_RESULT(registerResultId));

export const getRegisterResult = (registerResultId) =>
  instance.get(REGISTER_RESULT.GET_REGITTER_RESULT(registerResultId));

export const getInspectionResult = (registerResultId) =>
  instance.get(REGISTER_RESULT.GET_INSPECTION_RESULT, { params: { projectId: registerResultId } });
