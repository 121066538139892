import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function OtherInfoSection({ data }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion

  // #region Event
  // #endregion
  return (
    <div className="pb-2">
      <h4 className="mb-2">V. {t('scientificProfile.otherInfo.label').toUpperCase()}</h4>

      <div className="text-lg">
        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            1. {t('scientificProfile.otherInfo.programAttendance.label')}:
          </span>
          <div className="form-table my-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 150, width: '20%' }}>
                    {t('scientificProfile.otherInfo.programAttendance.timePeriod')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '48%' }}>
                    {t('scientificProfile.otherInfo.programAttendance.programName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '30%' }}>
                    {t('scientificProfile.otherInfo.programAttendance.position')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.programAttendances?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                      {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                    </td>
                    <td>{item?.programName}</td>
                    <td>{item?.position}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            2. {t('scientificProfile.otherInfo.conferenceAttendance.label')}:
          </span>
          <div className="form-table my-1">
            <table>
              <thead>
                <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                  {t('formLayout.numericalOrder')}
                </th>
                <th className="text-center" style={{ minWidth: 150, width: '20%' }}>
                  {t('scientificProfile.otherInfo.conferenceAttendance.timePeriod')}
                </th>
                <th className="text-center" style={{ minWidth: 100, width: '48%' }}>
                  {t('scientificProfile.otherInfo.conferenceAttendance.conferenceName')}
                </th>
                <th className="text-center" style={{ minWidth: 100, width: '30%' }}>
                  {t('scientificProfile.otherInfo.conferenceAttendance.position')}
                </th>
              </thead>
              <tbody>
                {data?.conferenceAttendances?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                      {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                    </td>
                    <td>{item?.conferenceName}</td>
                    <td>{item?.position}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            3. {t('scientificProfile.otherInfo.universityVisitingStaff.label')}:
          </span>
          <div className="form-table my-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 150, width: '20%' }}>
                    {t('scientificProfile.otherInfo.universityVisitingStaff.timePeriod')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '48%' }}>
                    {t('scientificProfile.otherInfo.universityVisitingStaff.universityName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '30%' }}>
                    {t('scientificProfile.otherInfo.universityVisitingStaff.participationContent')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.universityVisitingStaff?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                      {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                    </td>
                    <td>{item?.universityName}</td>
                    <td>{item?.participationContent}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

OtherInfoSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

OtherInfoSection.defaultProps = {
  data: {},
};
