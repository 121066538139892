import { useRef } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { resetPassword } from 'apis/users.api';

import C404 from 'components/404';
import TextInput from 'components/FormControl/TextInput';
import LanguageSelect from 'components/LanguageSelect';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { mapError } from 'utils/func';

import { resetPasswordValidationSchema } from '../validation';

const formOptions = { resolver: yupResolver(resetPasswordValidationSchema) };

export default function ResetPassword() {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useRef();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || 0;
  const { mutate, isLoading } = useMutation(resetPassword);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(formOptions);
  // #endregion Data

  // #region Event
  const showError = (errorMessage) => {
    toast.current.show({
      severity: 'error',
      summary: t('toast.error'),
      detail: errorMessage,
      life: 4000,
    });
  };

  const showSuccess = () => {
    toast.current.show({
      severity: 'success',
      summary: t('resetPassword.success.summary'),
      detail: t('resetPassword.success.detail'),
      life: 3000,
    });
  };

  const onSubmit = async (resetPassParam) => {
    mutate(
      { ...resetPassParam, token },
      {
        onSuccess: () => {
          showSuccess();
          setTimeout(() => {
            navigate('/login');
          }, 1500);
        },
        onError: (error) => {
          showError(mapError(t, error));
        },
      }
    );
  };
  const isTokenExpired = () => {
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);

    if (currentTime >= exp) return true;
    return false;
  };
  // #endregion Event

  if (isTokenExpired()) return <C404 />;

  return (
    <>
      <div className="p-4 fixed right-0">
        <LanguageSelect />
      </div>
      <div className="flex align-items-center justify-content-center" style={{ height: '80vh' }}>
        <Toast ref={toast} />
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: '400px' }}
        >
          <div className="text-center mb-5">
            <img src="/logo.png" alt="hyper" height={50} className="mb-3" />
            <div className="text-900 text-3xl font-medium mb-3">{t('resetPassword.title')}</div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <TextInput
              label={t('resetPassword.newPassword')}
              name="newPassword"
              type="password"
              isRequired
              autoFocus
              control={control}
              errors={errors}
            />

            <TextInput
              label={t('resetPassword.confirmPassword')}
              name="confirmPassword"
              type="password"
              isRequired
              control={control}
              errors={errors}
            />

            <Button
              label={t('resetPassword.submit')}
              icon="pi pi-key"
              className="w-full bg-cyan-600 border-cyan-600 mt-6"
              type="submit"
            />
          </form>
        </div>
        {isLoading && <Loading />}
      </div>
    </>
  );
}
