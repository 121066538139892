/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Select, TextareaInput } from 'components/FormControl';
import { getProjectTypes } from 'utils/func';
// eslint-disable-next-line import/named
import { updateType as updateTypeProject } from 'apis/projectProposals.api';
import { TOAST, TIMEZONE_VN } from 'constant';
import { toast } from 'layout';

const schema = yup.object().shape({
  projectType: yup.string().required('Trường này không được để trống'),
  ownerComment: yup.string().required('Trường này không được để trống'),
});

const formOptions = { resolver: yupResolver(schema) };

function RequestUpdateType({ isVisible, onHide, projectProposal }) {
  const { t } = useTranslation();
  const projectTypes = getProjectTypes(t);
  const ReviseLog = projectProposal?.projectProposalReviseLogs?.find(
    (item) => item.type === 'REQUEST_UPDATE_PROJECT_TYPE'
  );
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({ ...formOptions, mode: 'onChange' });

  const mutation = useMutation(updateTypeProject, {
    onSuccess: () => {
      toast(TOAST.SUCCESS, t('projectProposal.updateProjectType.success'));
      reset();
      onHide();
    },
    onError: () => {
      toast(TOAST.ERROR, t('projectProposal.updateProjectType.error'));
    },
  });

  const onSubmit = (data) => {
    projectProposal?.projectProposalReviseLogs?.forEach((item) => {
      if (item.type === 'REQUEST_UPDATE_PROJECT_TYPE') {
        // eslint-disable-next-line no-param-reassign
        data = { ...data, projectProposalReviseLogId: item.id };
      }
    });
    // eslint-disable-next-line no-param-reassign
    data = { ...data, projectProposalId: projectProposal?.reference };
    mutation.mutate(data);
  };

  const handleClose = () => {
    onHide();
  };

  const footerContent = (
    <div className="dialog-footer">
      <Button
        label={t('projectProposal.updateProjectType.cancel')}
        severity="danger"
        onClick={handleClose}
      />
      <Button label={t('projectProposal.updateProjectType.ok')} onClick={handleSubmit(onSubmit)} />
    </div>
  );

  const formatDate = (date) =>
    date ? new Date(date).toLocaleDateString('en-GB', { timeZone: TIMEZONE_VN }) : '';

  return (
    <Dialog
      header={t('projectProposal.updateProjectType.label')}
      visible={isVisible}
      style={{ width: '38vw', height: '72vh' }}
      onHide={onHide}
      footer={footerContent}
    >
      <div className="relative">
        <form
          className="p-fluid flex flex-column"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid">
            {/* projectType */}
            <span className="col-12 ">
              <span className="font-semibold">
                {t('projectProposal.updateProjectType.nameProposal')}:
              </span>{' '}
              {projectProposal?.vietnameseName}
            </span>
            <span className="col-12 ">
              <span className="font-semibold">
                {' '}
                {t('projectProposal.updateProjectType.oldProjectType')}:
              </span>{' '}
              {t(`projectTypes.${projectProposal?.projectType}`)}
            </span>
            <span className="col-12 ">
              <span className="font-semibold">
                {' '}
                {t('projectProposal.updateProjectType.deadline')}:
              </span>{' '}
              {formatDate(ReviseLog?.deadline)}
            </span>
            <span className="col-12 ">
              <span className="font-semibold"> {t('projectProposal.updateProjectType.note')}:</span>{' '}
              {ReviseLog?.comment}
            </span>
            <div className="col-12">
              <Select
                label={t('projectProposal.updateProjectType.newProjectType')}
                name="projectType"
                isRequired
                autoFocus
                options={projectTypes}
                inputField="value"
                control={control}
                errors={errors}
              />
            </div>
            {/* comment  */}
            <div className="col-12">
              <TextareaInput
                label={t('projectProposal.updateProjectType.comment')}
                name="ownerComment"
                isRequired
                control={control}
                errors={errors}
                row={3}
              />
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
}

RequestUpdateType.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectProposal: PropTypes.object.isRequired,
};

export default RequestUpdateType;
