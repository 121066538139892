const formDefaultValues = {
  general: {
    projectProposalResearchFields: [
      {
        priority: 1,
        researchLine: '',
        researchFieldId: '',
      },
      {
        priority: 2,
        researchLine: '',
        researchFieldId: '',
      },
      {
        priority: 3,
        researchLine: '',
        researchFieldId: '',
      },
    ],
    researchTypesId: '',
    lead: {
      organizationId: '',
      name: '',
      chief: '',
      phone: '',
      fax: '',
      email: '',
      bank: '',
      partnerContribution: '',
      type: 'LEAD',
    },
    partners: [],
    owners: [],
    scientificSecretaries: [],
    mainParticipants: [],
    participants: [],
    technicians: [],
    domesticExperts: [],
    internationalExperts: [],
  },
  expenditure: {
    file: null,
  },
  researchDescription: {
    file: null,
  },
};

export const defaultValues = {
  recommendedExpert: {
    fullname: '',
    researchLine: '',
    workplace: '',
    email: '',
    phone: '',
  },
  softProduct: {
    name: '',
    quantityTarget: undefined,
    note: '',
  },
  hardProduct: {
    name: '',
    qualityCriteria: '',
    measure: '',
    evaluativeCriteria: undefined,
    domesticModelQuality: '',
    internationalModelQuality: '',
    expectedQuantity: undefined,
  },
};

export default formDefaultValues;
