import { Select, TextInput } from 'components/FormControl';
import { Tooltip } from 'primereact/tooltip';
import { useFieldArray } from 'react-hook-form';
import { classNames } from 'primereact/utils';

import { defaultValues } from 'features/ScientificProfile/defaultValues';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function ForeignLanguagesTable({ control, errors, isHighlighted }) {
  // #region Data
  const { t } = useTranslation();

  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'general.foreignLanguages',
  });

  const capacities = [
    { value: 'veryGood', name: t('languageSkillCapacity.veryGood') },
    { value: 'good', name: t('languageSkillCapacity.good') },
    { value: 'average', name: t('languageSkillCapacity.average') },
  ];
  // #endregion Data

  return (
    <div className={classNames('form-table mt-2', { highlighted: isHighlighted })}>
      <table className="mb-2">
        <thead>
          <tr>
            <th className="text-center" style={{ minWidth: 50, width: 50 }}>
              {t('formLayout.numericalOrder')}
            </th>
            <th className="text-center" style={{ minWidth: 100, width: '25%' }}>
              {t('scientificProfile.generalInfo.foreignLanguage.languageName')}
            </th>
            <th className="text-center" style={{ minWidth: 100, width: '16%' }}>
              {t('scientificProfile.generalInfo.foreignLanguage.listening')}
            </th>
            <th className="text-center" style={{ minWidth: 100, width: '16%' }}>
              {t('scientificProfile.generalInfo.foreignLanguage.speaking')}
            </th>
            <th className="text-center" style={{ minWidth: 100, width: '16%' }}>
              {t('scientificProfile.generalInfo.foreignLanguage.writing')}
            </th>
            <th className="text-center" style={{ minWidth: 100, width: '16%' }}>
              {t('scientificProfile.generalInfo.foreignLanguage.reading')}
            </th>
            <th className="text-center float" style={{ minWidth: 80, width: 80 }}>
              {t('formLayout.action.label')}
            </th>
          </tr>
        </thead>
        <tbody>
          {fields?.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center">{index + 1}</td>
              <td>
                <TextInput
                  name={`general.foreignLanguages.${index}.name`}
                  control={control}
                  errors={errors}
                />
              </td>
              <td>
                <Select
                  name={`general.foreignLanguages.${index}.listening`}
                  options={capacities}
                  control={control}
                  errors={errors}
                  inputField="value"
                />
              </td>
              <td>
                <Select
                  name={`general.foreignLanguages.${index}.speaking`}
                  options={capacities}
                  control={control}
                  errors={errors}
                  inputField="value"
                />
              </td>
              <td>
                <Select
                  name={`general.foreignLanguages.${index}.writing`}
                  options={capacities}
                  control={control}
                  errors={errors}
                  inputField="value"
                />
              </td>
              <td>
                <Select
                  name={`general.foreignLanguages.${index}.reading`}
                  options={capacities}
                  control={control}
                  errors={errors}
                  inputField="value"
                />
              </td>

              <td className="text-center p-0 float">
                <button
                  className="p-button p-button-text p-button-icon-only p-0"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <i
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                </button>
                <Tooltip target=".remove-icon" />

                <div className="">
                  <button
                    className="p-button p-button-text p-button-icon-only p-0"
                    type="button"
                    onClick={() => insert(index + 1, defaultValues.foreignLanguage)}
                  >
                    <i
                      className="insert-icon pi pi-plus text-primary cursor-pointer mt-1"
                      data-pr-tooltip={t('formLayout.action.insert')}
                      data-pr-position="left"
                    />
                  </button>
                  <Tooltip target=".insert-icon" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {fields?.length === 0 && (
        <div className="flex justify-content-center my-2">
          <i
            onClick={() => insert(0, defaultValues.foreignLanguage)}
            className="add-foreign-language pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
            data-pr-tooltip={t('formLayout.action.add')}
            data-pr-position="left"
          />
          <Tooltip target=".add-foreign-language" />
        </div>
      )}
    </div>
  );
}

ForeignLanguagesTable.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  isHighlighted: PropTypes.bool,
};
ForeignLanguagesTable.defaultProps = {
  isHighlighted: false,
};
