import instance from 'utils/axios/instance.axios';
import { SCIENTIFIC_PROFILES } from './_constant';

export const uploadFile = (file) => instance.patch(SCIENTIFIC_PROFILES.UPLOAD_FILE, file);

export const checkFileFormat = () => instance.get(SCIENTIFIC_PROFILES.CHECK_FILE_FORMAT);

export const checkNameEmailScientificProfile = () =>
  instance.get(SCIENTIFIC_PROFILES.CHECK_EMAIL_NAME);

export const uploadImage = (file) => instance.patch(SCIENTIFIC_PROFILES.UPLOAD_IMAGE, file);

export const downloadFile = () =>
  instance.get(SCIENTIFIC_PROFILES.DOWNLOAD_FILE, { responseType: 'blob' });

export const downloadImage = () =>
  instance.get(SCIENTIFIC_PROFILES.DOWNLOAD_IMAGE, { responseType: 'blob' });

export const downloadAvatarById = (id) =>
  instance.get(SCIENTIFIC_PROFILES.DOWNLOAD_AVATAR_BY_ID(id), { responseType: 'blob' });

export const create = (data = {}) => instance.post(SCIENTIFIC_PROFILES.CREATE, data);

export const update = (data = {}) => instance.patch(SCIENTIFIC_PROFILES.UPDATE, data);

export const getGeneralInfo = () => instance.get(SCIENTIFIC_PROFILES.GET_GENERAL_INFO);

export const getAcademicHistory = () => instance.get(SCIENTIFIC_PROFILES.GET_ACADEMIC_HISTORY);

export const getWorkHistory = () => instance.get(SCIENTIFIC_PROFILES.GET_WORK_HISTORY);

export const getProjects = () => instance.get(SCIENTIFIC_PROFILES.GET_PROJECTS);

export const getThesisGuidances = () => instance.get(SCIENTIFIC_PROFILES.GET_THESIS_GUIDANCES);

export const getScientificWorks = () => instance.get(SCIENTIFIC_PROFILES.GET_SCIENTIFIC_WORKS);

export const getOthersInfo = () => instance.get(SCIENTIFIC_PROFILES.GET_OTHERS_INFO);

export const getGeneralInfoByDocx = () =>
  instance.get(SCIENTIFIC_PROFILES.GET_GENERAL_INFO_DOCX_FILE);

export const getAcademicHistoryByDocx = () =>
  instance.get(SCIENTIFIC_PROFILES.GET_ACADEMIC_HISTORY_DOCX_FILE);

export const getWorkHistoryByDocx = () =>
  instance.get(SCIENTIFIC_PROFILES.GET_WORK_HISTORY_DOCX_FILE);

export const getProjectsByDocx = () => instance.get(SCIENTIFIC_PROFILES.GET_PROJECTS_DOCX_FILE);

export const getThesisGuidancesByDocx = () =>
  instance.get(SCIENTIFIC_PROFILES.GET_THESIS_GUIDANCES_DOCX_FILE);

export const getScientificWorksByDocx = (type) =>
  instance.get(SCIENTIFIC_PROFILES.GET_SCIENTIFIC_WORKS_DOCX_FILE, { params: { type } });

export const getOthersInfoByDocx = () =>
  instance.get(SCIENTIFIC_PROFILES.GET_OTHERS_INFO_DOCX_FILE);

export const getPublicAboutInfo = (id) =>
  instance.get(SCIENTIFIC_PROFILES.GET_PUBLIC_ABOUT_INFO(id));

export const getPublicResearchAndTeachingInfo = (id) =>
  instance.get(SCIENTIFIC_PROFILES.GET_PUBLIC_RESEARCH_TEACHING_INFO(id));

export const getPublicScientificWorksInfo = (id) =>
  instance.get(SCIENTIFIC_PROFILES.GET_PUBLIC_SCIENTIFIC_WORKS_INFO(id));

export const getPublicAwardsInfo = (id) =>
  instance.get(SCIENTIFIC_PROFILES.GET_PUBLIC_AWARDS_INFO(id));

export const getPublicOthersInfo = (id) =>
  instance.get(SCIENTIFIC_PROFILES.GET_PUBLIC_OTHERS_INFO(id));

export const createPublicProfileId = (id) =>
  instance.post(SCIENTIFIC_PROFILES.CREATE_PUBLIC_PROFILE_ID(id));

export const checkExistPublicProfileId = (publicProfileId) =>
  instance.get(SCIENTIFIC_PROFILES.CHECK_PUBLIC_PROFILE_ID(publicProfileId));

export const updatePublicProfileId = (scientificProfileId, publicProfileId) =>
  instance.patch(SCIENTIFIC_PROFILES.UPDATE_PUBLIC_PROFILE_ID, {
    scientificProfileId,
    publicProfileId,
  });
