import './style.scss';

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { getAll as getAllPrograms } from 'apis/programs.api';
import {
  getAll as getAllProjectProposals,
  getAllResearchFields
} from 'apis/pubProjectProposals.api';

import { ViewHistory } from 'features/PubProjectProposal/components';
import Pagination from 'components/Pagination';
import { totalExpenditureSchema } from 'features/PubProjectProposal/validation';

function PubProjectProposalViewList() {
  // #region data
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams({ replace: true });
  const [pagination, setPagination] = useState({
    first: parseInt(searchParams.get('first'), 10) || 0,
    rowsPerPage: 10,
  });
  const [viewHistoryVisible, setViewHistoryVisible] = useState(false);
  const [projectHistory, setProjectHistory] = useState([]);
  const [showMode, setShowMode] = useState('hideDeletedProjects');

  // // background filters, sync with localStorage
  const [filters, setFilters] = useState({
    vietnameseName: null,
    projectType: null,
    ownerName: null,
    researchFieldId: null,
    researchTypeId: null,
    totalExpenditure: { min: null, max: null },
    programId: null,
    submittedAt: { start: null, end: null },
    statusName: null,
  });

  // // applied filters, change every time click on Apply and Clear button for update
  const [appliedFilters, setAppliedFilters] = useState({
    vietnameseName: null,
    projectType: null,
    ownerName: null,
    researchFieldId: null,
    researchTypeId: null,
    totalExpenditure: { min: null, max: null },
    programId: null,
    submittedAt: { start: null, end: null },
    statusName: null,
  });

  const {
    data: _data,
    isLoading,
  } = useQuery(
    ['pubProjectProposals', pagination, appliedFilters, showMode],
    ({ queryKey: [, _pagination, _filters, _showMode] }) =>
      getAllProjectProposals(_pagination, _filters, _showMode)
  );
  const data = useMemo(
    () => _data?.data ?? { data: [], pagination: { row: 10, totalRecords: 0 } },
    [_data]
  );

  const { data: researchFieldsData } = useQuery(
    ['pubProjectProposalResearchFields'],
    getAllResearchFields
  );
  const researchFields = useMemo(
    () =>
      researchFieldsData?.data.map((e) => ({
        id: e.id,
        name: e?.code ? t(`researchFields.${e.code}`) : e.name,
      })) ?? [],
    [researchFieldsData, t]
  );

  const { data: programData } = useQuery(['program'], getAllPrograms);
  const programs = useMemo(() => programData?.data ?? [], [programData]);

  useEffect(() => {
    const localFilters = JSON.parse(
      localStorage.getItem(`scientist-${location.pathname.slice(1)}-filters`) ?? '{}'
    );

    const modifySubmittedAt = {};
    if (localFilters?.submittedAt) {
      const { start, end } = localFilters.submittedAt;
      if (start || end) {
        modifySubmittedAt.start = start ? new Date(start) : '';
        modifySubmittedAt.end = end ? new Date(end) : '';
      }
    }

    setFilters({
      ...localFilters,
      submittedAt: modifySubmittedAt,
      totalExpenditure: { min: undefined, max: undefined },
      // statusName: ['open'],
    });
    setAppliedFilters({
      ...localFilters,
      submittedAt: modifySubmittedAt,
      totalExpenditure: { min: undefined, max: undefined },
      // statusName: ['open'],
    });

    // Set rows per page in localStorage
    const localRowsPerPage = parseInt(
      localStorage.getItem(`scientist-${location.pathname.slice(1)}-rowsPerPage`),
      10
    );
    if (localRowsPerPage) {
      setPagination((cur) => ({ ...cur, rowsPerPage: localRowsPerPage }));
    }

    // Set current page (first) with searchParams
    const currentFirst = parseInt(searchParams.get('first'), 10) || 0;
    setPagination((cur) => ({ ...cur, first: currentFirst }));
  }, []);
  // // #endregion Event

  // // #region Render

  const formatName = (value) => {
    if (value?.deletedAt === null) {
      return (
        <Link to={`${value?.reference}`} className="hover:underline">
          {value?.vietnameseName}
        </Link>
      );
    }
    return <span>{value?.vietnameseName}</span>;
  };
  const vietnameseNameFilter = () => (
    <InputText
      value={filters.vietnameseName}
      onChange={(e) => {
        const newFilters = { ...filters, vietnameseName: e.target.value };
        setFilters(newFilters);
      }}
      placeholder={t('pubProjectProposal.viewList.name')}
      className="p-column-filter"
    />
  );

  const formatDate = (date) => (date ? new Date(date).toLocaleDateString('en-GB') : '');

  const formatSubmittedAt = (value) => formatDate(value?.submittedAt);

  const formatResearchFields = (value) => (
    <div>
      {value?.projectProposalResearchFields
        ?.sort((a, b) => {
          if (a.priority === null) return 1;
          if (b.priority === null) return -1;
          if (a.priority === b) return 0;
          return a.priority < b.priority ? -1 : 1;
        })
        ?.map((researchField, index) => (
          <p key={researchField.researchField.id} className="text-left m-0">
            {`(${researchField?.priority ?? index + 1}) ${t(
              `researchFields.${researchField.researchField.code}`
            )}`}
          </p>
        ))}
    </div>
  );
  const researchFieldFilter = () => (
    <MultiSelect
      value={filters.researchFieldId}
      onChange={(e) => {
        const newFilters = { ...filters, researchFieldId: e.value };
        setFilters(newFilters);
      }}
      options={researchFields}
      optionLabel="name"
      optionValue="id"
      filter
      showClear
      placeholder={t('pubProjectProposal.viewList.researchField')}
      maxSelectedLabels={3}
      style={{ maxWidth: '22rem', width: '18.5rem' }}
    />
  );

  const formatTotalExpenditure = (value) => {
    if (value?.totalExpenditure === 0 && value?.proposedExpenditureSummaries?.length > 0) {
      const total = value?.proposedExpenditureSummaries.reduce((sum, cur) => {
        if (cur?.proposedExpenditureType?.subProposedExpenditureTypeId === null) {
          const expenditureFromFunding = cur?.expenditureFromFunding ?? 0;
          const expenditureFromVNUHCM = cur?.expenditureFromVNUHCM ?? 0;

          return sum + expenditureFromFunding + expenditureFromVNUHCM;
        }
        return sum;
      }, 0);
      return total?.toLocaleString('vi');
    }
    return value?.totalExpenditure?.toLocaleString('vi');
  };
  const totalExpenditureFilter = () => (
    <div>
      <div className="flex align-items-center mb-2">
        <span className="w-3">{t('pubProjectProposal.viewList.from')}</span>
        <InputNumber
          value={filters?.totalExpenditure?.min ?? undefined}
          onChange={(e) => {
            const newFilters = {
              ...filters,
              totalExpenditure: { ...filters.totalExpenditure, min: e.value },
            };
            setFilters(newFilters);
          }}
          className={classNames('currency', {
            'p-invalid': !totalExpenditureSchema.isValidSync({
              min: filters?.totalExpenditure?.min ?? undefined,
              max: filters?.totalExpenditure?.max ?? undefined,
            }),
          })}
          tooltip={
            !totalExpenditureSchema.isValidSync({
              min: filters?.totalExpenditure?.min ?? undefined,
              max: filters?.totalExpenditure?.max ?? undefined,
            })
              ? t('pubProjectProposal.viewList.validation.min')
              : ''
          }
          tooltipOptions={{ position: 'bottom' }}
          mode="currency"
          currency="VND"
          locale="vi-VN"
          useGrouping
        />
      </div>
      <div className="flex align-items-center">
        <span className="w-3">{t('pubProjectProposal.viewList.to')}</span>
        <InputNumber
          value={filters?.totalExpenditure?.max ?? undefined}
          onChange={(e) => {
            const newFilters = {
              ...filters,
              totalExpenditure: { ...filters.totalExpenditure, max: e.value },
            };
            setFilters(newFilters);
          }}
          className={classNames('currency', {
            'p-invalid': !totalExpenditureSchema.isValidSync({
              min: filters?.totalExpenditure?.min ?? undefined,
              max: filters?.totalExpenditure?.max ?? undefined,
            }),
          })}
          tooltip={
            !totalExpenditureSchema.isValidSync({
              min: filters?.totalExpenditure?.min ?? undefined,
              max: filters?.totalExpenditure?.max ?? undefined,
            })
              ? t('pubProjectProposal.viewList.validation.max')
              : ''
          }
          tooltipOptions={{ position: 'bottom' }}
          mode="currency"
          currency="VND"
          locale="vi-VN"
          useGrouping
        />
      </div>
    </div>
  );

  const programFilter = () => (
    <MultiSelect
      value={filters.programId}
      onChange={(e) => {
        const newFilters = { ...filters, programId: e.value };
        setFilters(newFilters);
      }}
      options={programs}
      optionLabel="name"
      optionValue="id"
      filter
      showClear
      placeholder={t('pubProjectProposal.viewList.program')}
      style={{ maxWidth: '22rem', width: '18.5rem' }}
    />
  );

  const submittedAtFilter = () => (
    <div>
      <div className="flex align-items-center mb-2">
        <span className="w-2">{t('pubProjectProposal.viewList.from')}</span>
        <Calendar
          showIcon
          showButtonBar
          value={filters?.submittedAt?.start ?? undefined}
          onChange={(e) => {
            const newFilters = {
              ...filters,
              submittedAt: { ...filters.submittedAt, start: e.value },
            };
            setFilters(newFilters);
          }}
          dateFormat="dd/mm/yy"
          locale={t('lang') !== 'lang' ? t('lang') : 'vi'}
        />
      </div>
      <div className="flex align-items-center">
        <span className="w-2">{t('pubProjectProposal.viewList.to')}</span>
        <Calendar
          showIcon
          showButtonBar
          value={filters?.submittedAt?.end ?? undefined}
          onChange={(e) => {
            const newFilters = {
              ...filters,
              submittedAt: { ...filters.submittedAt, end: e.value },
            };
            setFilters(newFilters);
          }}
          dateFormat="dd/mm/yy"
          locale={t('lang') !== 'lang' ? t('lang') : 'vi'}
        />
      </div>
    </div>
  );

  const formatStatus = (value) => {
    const statusColorMapping = {
      pendingReview: 'info',
      pendingChange: 'warning',
      new: 'info',
      approved: 'success',
    };

    return (
      <Tag
        style={{ maxWidth: '8rem' }}
        severity={statusColorMapping[value?.status.name]}
        value={t(`statuses.${value?.status.name}`)}
        rounded
      />
    );
  };

  const formatActions = (value) => (
    <div
      className="flex gap-2 flex-wrap justify-content-center"
      style={{ width: '7rem', maxWidth: '7rem' }}
    >
      {/* eslint-disable indent */}
      <Button
        icon="pi pi-history"
        tooltip={t('pubProjectProposal.viewList.tooltip.viewHistory')}
        tooltipOptions={{ position: 'left' }}
        onClick={() => {
          setProjectHistory(value?.projectLogs ?? []);
          setViewHistoryVisible(true);
        }}
      />

      <Button
        icon="pi pi-check"
        severity="success"
        tooltip={t('pubProjectProposal.viewList.tooltip.createProjectProposal')}
        tooltipOptions={{ position: 'left' }}
        onClick={() => {
          navigate('/project-proposal/create', {
            state: {
              pubProjectProposalId: value?.reference,
              pubProjectProposalName: value?.vietnameseName
            }
          });
        }}
      />
    </div>
  );
  const filterClearTemplate = (options) => (
    <Button
      type="button"
      label={t('employee.viewList.clear')}
      onClick={() => {
        options.filterClearCallback();
      }}
      severity="secondary"
      outlined
    />
  );

  const filterApplyTemplate = (options) => (
    <Button
      type="button"
      label={t('employee.viewList.apply')}
      onClick={() => {
        options.filterApplyCallback();
        localStorage.setItem(
          `scientist-${location.pathname.slice(1)}-filters`,
          JSON.stringify(filters)
        );
      }}
      disabled={
        !totalExpenditureSchema.isValidSync({
          min: filters?.totalExpenditure?.min ?? undefined,
          max: filters?.totalExpenditure?.max ?? undefined,
        })
      }
      severity="success"
    />
  );

  const filterActiveStyle = (field) =>
    filters[field] && (filters[field]?.length > 0 || Object.values(filters[field]).some((e) => e))
      ? { color: '#22C55E' }
      : { color: '#6c757d' };

  return (
    <div className="card relative">
      <div className="flex align-items-center justify-content-between gap-2 pb-4">
        <h4 className="m-0 hidden sm:block text-xl sm:text-2xl">
          {t('pubProjectProposal.viewList.title')}
        </h4>
      </div>

      <DataTable
        value={data?.data}
        className="project-list"
        dataKey="id"
        showGridlines
        scrollable
        showSelectAll={false}
        filterDisplay="menu"
        loading={isLoading}
        emptyMessage={t('pubProjectProposal.viewList.emptyMessage')}
      >
        <Column
          header={t('pubProjectProposal.viewList.name')}
          headerStyle={filterActiveStyle('vietnameseName')}
          body={formatName}
          filter
          filterField="_vietnameseName"
          filterElement={vietnameseNameFilter}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          showFilterMenu
          showFilterMatchModes={false}
          onFilterClear={() => {
            const newFilters = { ...filters, vietnameseName: '' };
            setFilters(newFilters);
            setAppliedFilters(newFilters);
            localStorage.setItem(
              `scientist-${location.pathname.slice(1)}-filters`,
              JSON.stringify(newFilters)
            );
          }}
          onFilterApplyClick={() => {
            setAppliedFilters(filters);
          }}
          style={{ minWidth: '10rem', maxWidth: '16rem' }}
          frozen
          alignFrozen="left"
        />
        <Column
          header={t('pubProjectProposal.viewList.researchField')}
          headerStyle={{ ...filterActiveStyle('researchFieldId'), justifyContent: 'center' }}
          body={formatResearchFields}
          filter
          filterField="_researchField"
          filterElement={researchFieldFilter}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          showFilterMatchModes={false}
          showFilterMenu
          onFilterClear={() => {
            const newFilters = { ...filters, researchFieldId: [] };
            setFilters(newFilters);
            setAppliedFilters(newFilters);
            localStorage.setItem(
              `scientist-${location.pathname.slice(1)}-filters`,
              JSON.stringify(newFilters)
            );
          }}
          onFilterApplyClick={() => {
            setAppliedFilters(filters);
          }}
          style={{ minWidth: '10rem', textAlign: 'center' }}
        />
        <Column
          field="totalExpenditure"
          header={t('pubProjectProposal.viewList.totalExpenditure')}
          headerStyle={{ ...filterActiveStyle('totalExpenditure'), justifyContent: 'center' }}
          body={formatTotalExpenditure}
          filter
          filterField="_totalExpenditure"
          filterElement={totalExpenditureFilter}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          showFilterMatchModes={false}
          showFilterMenu
          onFilterClear={() => {
            const newFilters = { ...filters, totalExpenditure: {} };
            setFilters(newFilters);
            setAppliedFilters(newFilters);
            localStorage.setItem(
              `scientist-${location.pathname.slice(1)}-filters`,
              JSON.stringify(newFilters)
            );
          }}
          onFilterApplyClick={() => {
            const isValid = totalExpenditureSchema.isValidSync({
              min: filters?.totalExpenditure?.min ?? undefined,
              max: filters?.totalExpenditure?.max ?? undefined,
            });
            if (isValid) {
              setAppliedFilters(filters);
            }
          }}
          dataType="numeric"
          style={{ minWidth: '8rem', textAlign: 'center' }}
        />
        <Column
          field="program.name"
          header={t('pubProjectProposal.viewList.program')}
          headerStyle={filterActiveStyle('programId')}
          dataType="date"
          filter
          filterField="_program"
          filterElement={programFilter}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          showFilterMatchModes={false}
          showFilterMenu
          onFilterClear={() => {
            const newFilters = { ...filters, programId: [] };
            setFilters(newFilters);
            setAppliedFilters(newFilters);
            localStorage.setItem(
              `scientist-${location.pathname.slice(1)}-filters`,
              JSON.stringify(newFilters)
            );
          }}
          onFilterApplyClick={() => {
            setAppliedFilters(filters);
          }}
          style={{ minWidth: '8rem' }}
        />
        <Column
          header={t('pubProjectProposal.viewList.submittedAt')}
          headerStyle={filterActiveStyle('submittedAt')}
          body={formatSubmittedAt}
          dataType="date"
          filter
          filterField="_submittedAt"
          filterElement={submittedAtFilter}
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          showFilterMatchModes={false}
          showFilterMenu
          onFilterClear={() => {
            const newFilters = {
              ...filters,
              submittedAt: {},
            };
            setFilters(newFilters);
            setAppliedFilters(newFilters);
            localStorage.setItem(
              `scientist-${location.pathname.slice(1)}-filters`,
              JSON.stringify(newFilters)
            );
          }}
          onFilterApplyClick={() => {
            setAppliedFilters(filters);
          }}
          style={{ minWidth: '4rem' }}
        />
        <Column
          field="status.name"
          header={t('pubProjectProposal.viewList.status')}
          headerStyle={filterActiveStyle('statusName')}
          body={formatStatus}
          style={{ minWidth: '8rem', textAlign: 'center' }}
        />
        <Column
          header={t('pubProjectProposal.action')}
          body={formatActions}
          style={{ maxWidth: '10rem', textAlign: 'center' }}
          frozen
          alignFrozen="right"
        />
      </DataTable>

      <Pagination
        pagination={pagination}
        setPagination={setPagination}
        paginationData={data.pagination}
        showMode={showMode}
        setShowMode={setShowMode}
        pageName="pub-project-proposals-viewlist"
      />

      <ViewHistory
        data={projectHistory}
        visible={viewHistoryVisible}
        setVisible={setViewHistoryVisible}
      />
    </div>
  );
  // #endregion Render
}

export default PubProjectProposalViewList;
