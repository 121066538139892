import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import './styles.scss';
import ParticipantTable from '../ParticipantTable';
import SecurityInformation from '../SecurityInformation';
import Products from '../Products';
import ProductDescription from '../ProductDescription';

export default function RegisterContent({
  control,
  errors,
  // eslint-disable-next-line no-unused-vars
  uploadedFileUrl,
  // eslint-disable-next-line no-unused-vars
  handleDownloadFile,
  disabled,
}) {
  const { t } = useTranslation();
  return (
    <div className="report-form-container">
      <h4 className="font-bold">{t('r09.form.b')}</h4>
      <div className="my-2">
        <h4 className="my-2">{t('r09.form.b1')}</h4>
        <ParticipantTable disabled={disabled} control={control} errors={errors} />
      </div>
      <div>
        <h4>{t('r09.form.b2')}</h4>
        <SecurityInformation disabled={disabled} control={control} errors={errors} />
      </div>
      <div>
        <h4>{t('r09.form.b3')}</h4>
        <Products disabled={disabled} control={control} errors={errors} />
      </div>
      <div>
        <h4>{t('r09.form.b4')}</h4>
        <ProductDescription disabled={disabled} control={control} errors={errors} />
      </div>
    </div>
  );
}

RegisterContent.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  uploadedFileUrl: PropTypes.object,
  handleDownloadFile: PropTypes.func,
  disabled: PropTypes.bool,
};

RegisterContent.defaultProps = {
  uploadedFileUrl: [],
  handleDownloadFile: () => null,
  disabled: false,
};
