import { ScrollTop } from 'primereact/scrolltop';
import DetailCheckResult from 'features/CheckResult/pages/detail';
import Layout from 'layout';
import { LayoutProvider } from '../layout/context/layoutcontext';

export default function DetailCheckResultPage() {
  return (
    <LayoutProvider>
      <Layout
        style={{
          maxHeight: 'calc(100vh - 9rem)',
          overflowY: 'auto',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
        }}
      >
        <DetailCheckResult />
        <ScrollTop target="parent" />
      </Layout>
    </LayoutProvider>
  );
}
