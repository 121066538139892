import './style.scss';

import { useMemo, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  create as createFinancialReview,
  downloadDocxTemplate,
  downloadPdfTemplate,
  getInfo,
} from 'apis/financialReviews.api';

import ErrorDialog from 'components/ErrorDialog';
import Loading from 'components/Loading';
import { TOAST } from 'constant';
import { toast } from 'layout';

import { DetailInfo, EvaluationForm } from 'features/FinancialReview/components';
import { createFinancialReviewValidationSchema } from 'features/FinancialReview/validation';
import { ProjectProposalDefenseDetail } from 'features/ProjectProposalDefense/pages';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { TabPanel, TabView } from 'primereact/tabview';
import { isMemberAllowedReview } from 'utils/func';

export default function FinancialReviewFormCreate() {
  // #region Data
  const { t } = useTranslation();
  const [isVisibleErrorDialog, setIsVisibleDialogError] = useState(false);
  const [tab, setTab] = useState(0);

  const navigate = useNavigate();
  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');
  const downloadTemplateButtonRef = useRef();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(createFinancialReviewValidationSchema) });

  const { isDirty, dirtyFields } = useFormState({ control });

  const {
    mutate,
    error: errorRes,
    isError: isErrorRes,
    isLoading: isLoadingCreate,
  } = useMutation(createFinancialReview);

  // A. General Information
  const { data: infoData } = useQuery(
    ['financial-review', 'project-proposal', councilId, projectProposalId],
    ({ queryKey: [, , _councilId, _projectProposalId] }) => getInfo(_councilId, _projectProposalId)
  );
  const info = useMemo(() => infoData?.data, [infoData]);
  // #endregion

  // #region Events
  const onSubmit = async (data) => {
    // B1
    const specializeRequestReview = data?.specializeRequestReview;

    // B3
    const financialReviewResearchKpis = data?.financialReviewResearchKpis;

    // B4
    const expenditureAndKpiComparison = data?.expenditureAndKpiComparison || 0;
    const totalSuggestionExpenditureFromVNUHCMOfReviewer =
      data?.totalSuggestionExpenditureFromVNUHCMOfReviewer || 0;

    const reviewTotalExpenditureFromVNUHCM = JSON.stringify({
      expenditureAndKpiComparison,
      totalSuggestionExpenditureFromVNUHCMOfReviewer,
    });

    // B5
    const inappropriateExpenses = data?.inappropriateExpenses;

    // C
    const totalSuggestionExpenditureFromVNUHCM = data?.totalSuggestionExpenditureFromVNUHCM || 0;
    const totalSuggestionExpenditureFromMobilizedCapital =
      data?.totalSuggestionExpenditureFromMobilizedCapital || 0;
    const ownerEdittingRequest = data?.ownerEdittingRequest;

    const submitData = {
      projectProposalId,
      councilId,
      specializeRequestReview,
      financialReviewResearchKpis,
      reviewTotalExpenditureFromVNUHCM,
      inappropriateExpenses,
      totalSuggestionExpenditureFromVNUHCM,
      totalSuggestionExpenditureFromMobilizedCapital,
      ownerEdittingRequest,
    };

    mutate(submitData, {
      onSuccess: () => {
        reset({}, { keepValues: true });
        toast(TOAST.SUCCESS, t('council.review.createSuccess'));
        setTimeout(() => {
          const queryParams = `councilId=${councilId}&councilMemberId=${councilMemberId}`;

          navigate(`/financial-review/edit/${projectProposalId}?${queryParams}`);
        }, 1500);
      },
      onError: () => {
        setIsVisibleDialogError(true);
      },
    });
  };

  const {
    refetch: handleDownloadPdfTemplate,
    isLoading: isDownloadPdfTemplateLoading,
    isFetching: isDownloadPdfTemplateFetching,
  } = useQuery(
    ['downloadPdfTemplateFinancialReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M02 - Phieu nhan xet danh gia tham dinh kinh phi.pdf';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxTemplate,
    isLoading: isDownloadDocxTemplateLoading,
    isFetching: isDownloadDocxTemplateFetching,
  } = useQuery(
    ['downloadDocxTemplateFinancialReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M02 - Phieu nhan xet danh gia tham dinh kinh phi.docx';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('formLayout.action.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('formLayout.action.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );
  // #endregion

  return (
    <>
      {isLoadingCreate && <Loading />}

      {isErrorRes && (
        <ErrorDialog
          title={t('council.review.create')}
          isError={isErrorRes}
          error={errorRes}
          visible={isVisibleErrorDialog}
          setVisible={setIsVisibleDialogError}
        />
      )}
      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields).length}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>

      <TabView activeIndex={tab} onTabChange={(e) => setTab(e.index)}>
        <TabPanel header={t('council.review.createOrUpdate')} leftIcon="pi pi-file mr-2">
          <div className="m-0 bg-white px-4 sm:px-4 md:px-4 lg:px-6 xl:px-8 mb-3 py-5 p-fluid relative financial-review-create">
            <div className="flex gap-1 justify-content-end">
              <Button
                label={t('council.review.downloadTemplate')}
                icon={`pi ${
                  isDownloadPdfTemplateLoading ||
                  isDownloadPdfTemplateFetching ||
                  isDownloadDocxTemplateLoading ||
                  isDownloadDocxTemplateFetching
                    ? 'pi-spin pi-spinner'
                    : 'pi-file'
                }`}
                className="block w-auto h-3rem border-none"
                severity="info"
                onClick={(event) => downloadTemplateButtonRef.current.toggle(event)}
                disabled={
                  isDownloadPdfTemplateLoading ||
                  isDownloadPdfTemplateFetching ||
                  isDownloadDocxTemplateLoading ||
                  isDownloadDocxTemplateFetching
                }
              />
            </div>
            <Menu
              ref={downloadTemplateButtonRef}
              model={[
                {
                  label: t('council.review.downloadPdfTemplate'),
                  icon: 'pi pi-file-pdf',
                  command: handleDownloadPdfTemplate,
                  disabled: isDownloadPdfTemplateLoading || isDownloadPdfTemplateFetching,
                },
                {
                  label: t('council.review.downloadDocxTemplate'),
                  icon: 'pi pi-file-word',
                  command: handleDownloadDocxTemplate,
                  disabled: isDownloadDocxTemplateLoading || isDownloadDocxTemplateFetching,
                },
              ]}
              popup
            />

            <div className="mb-5 flex justify-content-between align-items-center">
              <img
                src={`${process.env.PUBLIC_URL}/logo.png`}
                alt="VNU-HCM logo"
                className="ml-3 h-4rem md:h-5rem"
              />
              <div className="pl-2">{t('projectProposal.viewDetail.form')} M02</div>
            </div>
            <div className="text-center mb-6">
              <h3 className="font-bold mb-0">{t('council.m02.title')}</h3>
              <h3 className="mt-0">{t('council.m02.sciTechProject')}</h3>
            </div>

            <DetailInfo info={info} />

            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <EvaluationForm
                setValue={setValue}
                control={control}
                errors={errors}
                proposedExpenditureSummaries={info?.projectProposal?.proposedExpenditureSummaries}
                projectProposalResearchKpis={info?.projectProposal?.projectProposalResearchKpis}
                submittedAt={info?.projectProposal?.submittedAt}
                expenditureCalculatedByKpi={info?.projectProposal?.proposedExpenditureByKpis}
              />

              <div className="grid text-lg mt-3">
                <div className="col-7 px-8">
                  <span>{t('council.m01.c.commitment')}</span>
                </div>
                <div className="col-5 text-center">
                  <p className="mb-0">{t('signature.date')}</p>
                  <p className="font-semibold mb-0">{t('signature.reviewer')}</p>
                  <p className="mb-7">{t('signature.fullnameAndSignature')}</p>
                  <p className="font-bold">{info?.user?.fullname}</p>
                </div>
              </div>

              <div className="flex justify-content-end mt-4">
                <Button
                  label={t('formLayout.action.close')}
                  severity="danger"
                  type="button"
                  className="mr-2 w-8rem"
                />
                <Button
                  label={t('formLayout.action.save')}
                  severity="info"
                  type="submit"
                  className="w-8rem"
                  onClick={() => {
                    if (Object.keys(errors || {}).length) {
                      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
                    }
                  }}
                  disabled={
                    !(isDirty && Object.keys(dirtyFields).length) ||
                    !isMemberAllowedReview(info?.user?.councilMembers?.[0])
                  }
                />
              </div>
            </form>
          </div>
        </TabPanel>
        <TabPanel header={t('council.review.detailProjectProposal')} leftIcon="pi pi-list mr-2">
          <ProjectProposalDefenseDetail />
        </TabPanel>
      </TabView>
    </>
  );
}
