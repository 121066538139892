import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextareaInput } from 'components/FormControl';

import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';

export default function ContentCompletedTable({ disabled, control, errors }) {
  // #region Data
  const { t } = useTranslation();
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'summaryReportFinishedTasks',
  });

  // #endregion Data
  return (
    <div className="form-table">
      <table className="mb-2">
        <thead>
          <tr>
            <th className="text-center" style={{ minWidth: 50, width: 50 }}>
              {t('formLayout.numericalOrder')}
            </th>
            <th className="text-center" style={{ minWidth: 220, width: '33.33%' }}>
              {t('r05.b.b1.no1.registrationContent')}
            </th>
            <th className="text-center" style={{ minWidth: 220, width: '33.33%' }}>
              {t('r05.b.b1.no1.result')}
            </th>
            <th className="text-center" style={{ minWidth: 220, width: '33.33%' }}>
              {t('r05.b.b1.no1.completionLevel')}
            </th>
            {!disabled && (
              <th className="text-center float" style={{ minWidth: 60, width: 60 }}>
                {t('projectProposal.action')}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {fields?.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center">{index + 1}</td>
              <td>
                <TextareaInput
                  name={`summaryReportFinishedTasks.${index}.content`}
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </td>
              <td>
                <TextareaInput
                  name={`summaryReportFinishedTasks.${index}.result`}
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </td>
              <td>
                <TextareaInput
                  name={`summaryReportFinishedTasks.${index}.completionLevel`}
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </td>
              {!disabled && (
                <td className="text-center p-0 float">
                  <button
                    className="p-button p-button-text p-button-icon-only p-0"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    <i
                      className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                      data-pr-tooltip={t('formLayout.action.remove')}
                      data-pr-position="left"
                    />
                  </button>
                  <Tooltip target=".remove-icon" />

                  <div className="">
                    <button
                      className="p-button p-button-text p-button-icon-only p-0"
                      type="button"
                      onClick={() => insert(index + 1)}
                    >
                      <i
                        className="insert-icon pi pi-plus text-primary cursor-pointer mt-1"
                        data-pr-tooltip={t('formLayout.action.insert')}
                        data-pr-position="left"
                      />
                    </button>
                    <Tooltip target=".insert-icon" />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {!disabled && fields?.length === 0 && (
        <div className="flex justify-content-center my-2">
          <i
            onClick={() => insert(0)}
            className="add-recommended-expert pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
            data-pr-tooltip={t('formLayout.action.add')}
            data-pr-position="left"
          />
          <Tooltip target=".add-recommended-expert" />
        </div>
      )}
    </div>
  );
}

ContentCompletedTable.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
};

ContentCompletedTable.defaultProps = {
  disabled: false,
};
