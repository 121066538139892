/* eslint-disable max-len */
import instance from 'utils/axios/instance.axios';
import { PUB_PROJECT_PROPOSALS } from './_constant';

export const getAll = (pagination, filters, showMode) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_ALL, {
    params: { ...pagination, ...filters, showMode },
  });

export const getAllBasicInfo = () => instance.get(PUB_PROJECT_PROPOSALS.GET_BASIC_INFO_ALL);

export const getReferenceByProjectProposalId = (id) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_REFERENCE(id));
export const getAllVersions = (id) => instance.get(PUB_PROJECT_PROPOSALS.GET_ALL_VERSIONS(id));

export const getGeneralInfoById = (id) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_GENERAL_INFO_BY_ID(id));

export const getExpenditureById = (id) => instance.get(PUB_PROJECT_PROPOSALS.GET_EXPENDITURE_BY_ID(id));

export const getResearchResultById = (id) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_RESEARCH_RESULT_BY_ID(id));

export const getDocumentsById = (id) => instance.get(PUB_PROJECT_PROPOSALS.GET_DOCUMENTS_BY_ID(id));

export const getOverviewByIdForCouncil = (id) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_OVERVIEW_BY_ID_FOR_COUNCIL(id));

export const getById = (id) => instance.get(PUB_PROJECT_PROPOSALS.GET_BY_ID(id));

export const getAllResearchTypes = () => instance.get(PUB_PROJECT_PROPOSALS.GET_ALL_RESEARCH_TYPES);

export const getAllResearchFields = () => instance.get(PUB_PROJECT_PROPOSALS.GET_ALL_RESEARCH_FIELDS);

export const getOwner = () => instance.get(PUB_PROJECT_PROPOSALS.GET_OWNER);

export const getAllProjectRoles = () => instance.get(PUB_PROJECT_PROPOSALS.GET_ALL_PROJECT_ROLES);

export const getAllResearchKpis = () => instance.get(PUB_PROJECT_PROPOSALS.GET_ALL_RESEARCH_KPIS);

export const getRetrieveContacts = (searchQuery) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_RETRIEVE_CONTACTS, {
    params: { fullname: searchQuery },
  });

export const getAllOrganizations = () => instance.get(PUB_PROJECT_PROPOSALS.GET_ALL_ORGANIZATIONS);

export const getAllProposedExpenditureTypes = () =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_ALL_PROPOSED_EXPENDITURE_TYPES);

export const getAllStatuses = () => instance.get(PUB_PROJECT_PROPOSALS.GET_ALL_STASTUSES);

export const uploadFiles = ({ projectProposalId, files }) =>
  instance.patch(PUB_PROJECT_PROPOSALS.UPLOAD_FILES(projectProposalId), files);

export const getFileUrls = (id) => instance.get(PUB_PROJECT_PROPOSALS.GET_FILE_URLS(id));

export const downloadFile = (data = {}) =>
  instance.post(PUB_PROJECT_PROPOSALS.DOWNLOAD_FILE, data, { responseType: 'blob' });

export const exportExcel = (data = {}) =>
  instance.post(PUB_PROJECT_PROPOSALS.EXPORT_EXCEL, data, { responseType: 'blob' });

export const sendRemindEmail = (data = {}) =>
  instance.post(PUB_PROJECT_PROPOSALS.SEND_REMIND_EMAIL, data);

export const generalInfoForCouncil = (id) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_GENERAL_INFO_FOR_COUNCIL(id));

export const downloadScientificProfileFile = (id) =>
  instance.post(
    PUB_PROJECT_PROPOSALS.DOWNLOAD_SCIENTIFIC_PROFILE_FILE,
    { id },
    { responseType: 'blob' }
  );

export const downloadZip = (data = {}) =>
  instance.post(PUB_PROJECT_PROPOSALS.DOWNLOAD_ZIP, data, { responseType: 'blob' });

export const downloadExplanation = (id = '') =>
  instance.get(PUB_PROJECT_PROPOSALS.DOWNLOAD_EXPLANATION(id), { responseType: 'blob' });

export const updateStatus = (id) => instance.patch(PUB_PROJECT_PROPOSALS.UPDATE_STATUS(id));

export const getReviseLogs = ({ projectProposalId, types, isOpened }) => {
  if (projectProposalId) {
    return instance.get(PUB_PROJECT_PROPOSALS.GET_REVISE_LOGS(projectProposalId), {
      params: { types, isOpened },
    });
  }
  return null;
};

export const requestUpdate = (data = {}) => instance.post(PUB_PROJECT_PROPOSALS.REQUEST_UPDATE, data);

export const updateUpdateRequest = (data = {}) =>
  instance.post(PUB_PROJECT_PROPOSALS.UPDATE_UPDATE_REQUEST, data);

export const requestExplanation = (data = {}) =>
  instance.post(PUB_PROJECT_PROPOSALS.REQUEST_EXPLANATION, data);

export const updateExplanationRequest = (data = {}) =>
  instance.post(PUB_PROJECT_PROPOSALS.UPDATE_EXPLANATION_REQUEST, data);

export const getEmailLogsById = (id) => instance.get(PUB_PROJECT_PROPOSALS.GET_EMAIL_LOGS_BY_ID(id));

// Compare version
export const getGeneralInfoByVersion = (id, version) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_GENERAL_INFO_BY_VERSION(id), { params: { version } });

export const getResearchResultByVersion = (id, version) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_RESEARCH_RESULT_BY_VERSION(id), { params: { version } });

export const getExpenditureByVersion = (id, version) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_EXPENDITURE_BY_VERSION(id), { params: { version } });

export const getDocumentsByVersion = (id, version) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_DOCUMENTS_BY_VERSION(id), { params: { version } });

export const approveById = (id) => instance.patch(PUB_PROJECT_PROPOSALS.APPROVE(id));

export const deleteById = (id) => instance.delete(PUB_PROJECT_PROPOSALS.DELETE(id));

export const restoreById = (id) => instance.patch(PUB_PROJECT_PROPOSALS.RESTORE(id));

export const getProducts = (projectProposalId) =>
  instance.get(PUB_PROJECT_PROPOSALS.GET_PRODUCTS, { params: { projectProposalId } });
