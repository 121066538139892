/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import './style.scss';

import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { deleteById, getAll as getAllProjectProposals } from 'apis/projectProposals.api';

import { COUNCIL_STATUS, PROJECT_PROPOSAL_STATUSES, PROJECT_ROLES } from 'constant';
import Confirm from 'features/ProjectProposal/components/Confirm';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';

import ViewHistory from 'features/ProjectProposal/components/ViewHistory';
import { isAuthorized, mapError } from 'utils/func';
import ViewRequests from 'features/ProjectProposal/components/ViewRequests';
import RequestUpdateType from 'features/ProjectProposal/components/RequestUpdateType';

function ProjectProposalViewList() {
  // #region Data
  const { t } = useTranslation();
  const { data, isLoading, isError, error, refetch } = useQuery(
    'projectProposals',
    getAllProjectProposals
  );
  const projectProposals = useMemo(() => data?.data ?? [], [data?.data]);

  const [viewHistoryVisible, setViewHistoryVisible] = useState(false);
  const [projectHistory, setProjectHistory] = useState([]);
  const [projectProposal, setProjectProposal] = useState(null);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);

  const statuses = t('projectProposal.viewList.statuses', { returnObjects: true });
  const user = JSON.parse(localStorage.getItem('user'));
  const dialogConfirmDeleteRef = useRef();
  const dialogViewRequestsRef = useRef();

  const { mutate: mutateDelete } = useMutation(deleteById);
  // #endregion

  // #region Render
  const formatActions = (value) => {
    const projectProposalReviseLogs = value?.projectProposalReviseLogs?.filter((item) => item.type !== 'REQUEST_UPDATE_PROJECT_TYPE');
    const checkReviseLogUpdateType = value?.projectProposalReviseLogs?.some((item) => item.type === 'REQUEST_UPDATE_PROJECT_TYPE');
    const reviseLogUpdateType = value?.projectProposalReviseLogs?.find((item) => item.type === 'REQUEST_UPDATE_PROJECT_TYPE');
    return (
      <div className="flex flex-wrap justify-content-center gap-1">
        <div className="flex align-items-center flex-wrap bg-blue-500 border-round-lg">
          <Link to={`/project-proposal/${value.id}`}>
            <Button
              icon="pi pi-eye"
              severity="info"
              tooltip={t('projectProposal.viewList.view')}
              tooltipOptions={{ position: 'left', at: 'left top' }}
              style={{ width: '42px', height: '42px' }}
            />
          </Link>
        </div>

        {(value?.owner?.id === user?.id || isAuthorized(user?.id, value?.projectProposalContacts)) &&
          value?.status?.name === PROJECT_PROPOSAL_STATUSES.NEW ? (
          <div className="flex align-items-center flex-wrap border-round-lg">
            <Link to={`/project-proposal/edit/${value.id}`}>
              <Button
                icon="pi pi-pencil"
                severity="warning"
                tooltip={t('projectProposal.viewList.edit')}
                tooltipOptions={{ position: 'left', at: 'left top' }}
                style={{ width: '42px', height: '42px' }}
              />
            </Link>
          </div>
        ) : null}

        {(value?.owner?.id === user?.id || isAuthorized(user?.id, value?.projectProposalContacts)) &&
          value?.status?.name !== PROJECT_PROPOSAL_STATUSES.NEW &&
          value?.program && (
            <div className="flex align-items-center flex-wrap border-round-lg">
              <Link to={`/project-proposal/request/${value.reference}`}>
                <Button
                  severity="help"
                  className="flex align-items-center justify-content-center"
                  tooltip={t('projectProposal.result')}
                  tooltipOptions={{ position: 'left', at: 'left top' }}
                  style={{ width: '42px', height: '42px' }}
                >
                  <i className="pi pi-file p-overlay-badge">
                    {projectProposalReviseLogs.length > 0 && (
                      <Badge value={value?.projectProposalReviseLogs?.length} severity="danger" />
                    )}
                  </i>
                </Button>
              </Link>
            </div>
          )}

        {value?.owner?.id === user?.id && value?.status?.name === PROJECT_PROPOSAL_STATUSES.NEW && (
          <div className="flex align-items-center flex-wrap border-round-lg">
            <Button
              icon="pi pi-trash"
              severity="danger"
              tooltip={t('projectProposal.viewList.delete')}
              tooltipOptions={{ position: 'left', at: 'left top' }}
              onClick={() => {
                dialogConfirmDeleteRef.current.open({
                  mode: 'delete',
                  mutate: mutateDelete,
                  value,
                });
              }}
              style={{ width: '42px', height: '42px' }}
            />
          </div>
        )}

        <div className="flex align-items-center flex-wrap border-round-lg bg-blue-500">
          <Button
            icon="pi pi-history"
            tooltip={t('projectProposal.viewList.viewHistory')}
            tooltipOptions={{ position: 'left', at: 'left top' }}
            onClick={() => {
              setProjectHistory(value?.projectLogs ?? []);
              setViewHistoryVisible(true);
            }}
            style={{ width: '42px', height: '42px' }}
          />
        </div>
        {checkReviseLogUpdateType && reviseLogUpdateType?.deadline <= Date.now && (
          <div className="flex align-items-center flex-wrap border-round-lg">
            <Button
              severity="success"
              className="flex align-items-center justify-content-center"
              tooltip={t('projectProposal.viewList.updateType')}
              tooltipOptions={{ position: 'left', at: 'left top' }}
              onClick={() => {
                setProjectProposal(value);
                setIsVisibleUpdate(true);
              }}
              style={{ width: '42px', height: '42px' }}
            >
              <i className="pi pi-file p-overlay-badge">
                <Badge value={1} severity="danger" />
              </i>
            </Button>
          </div>
        )}
      </div>
    );
  };

  const formatTotalExpenditure = (value) => value.totalExpenditure?.toLocaleString('vi', '0,0');

  const formatOwner = (value) => {
    const projectProposalContacts = value?.projectProposalContacts;

    if (projectProposalContacts?.length > 0) {
      const owner = projectProposalContacts.find(
        (contact) => contact.projectRole.code === PROJECT_ROLES.OWNER
      );

      return <div>{owner?.fullname}</div>;
    }
    return null;
  };

  const formatStatus = (value) => {
    const statusColorMapping = {
      revise: 'warning',
      new: 'info',
      submit: 'success',
      approved: 'success',
    };

    return (
      <Tag
        style={{ width: '4rem' }}
        severity={statusColorMapping[value.status.name]}
        value={statuses[value.status.name]}
        rounded
      />
    );
  };

  const formatCouncil = (value) => {
    const councilStatusSeverityMap = {
      notReviewed: 'warning',
      notQualified: 'danger',
      qualified: 'success',
    };
    return (
      <div className="flex flex-column">
        {value?.prequalificationCouncilStatus && (
          <>
            <span className="my-1">- {t('council.prequalification')}</span>
            <div className="flex justify-content-center">
              <Tag
                style={{
                  maxWidth: '8rem',
                  minWidth: '4rem',
                  textAlign: 'center',
                }}
                severity={
                  councilStatusSeverityMap[
                  value.prequalificationCouncilStatus ?? COUNCIL_STATUS.NOT_REVIEWED
                  ]
                }
                value={t(
                  `council.viewList.${value.prequalificationCouncilStatus ?? COUNCIL_STATUS.NOT_REVIEWED
                  }`
                )}
                rounded
              />
            </div>
          </>
        )}
        {value?.specializationCouncilStatus && (
          <>
            <span className="my-1">- {t('council.specialization')}</span>
            <div className="flex justify-content-center">
              <Tag
                style={{
                  maxWidth: '8rem',
                  minWidth: '4rem',
                  textAlign: 'center',
                }}
                severity={
                  councilStatusSeverityMap[
                  value.specializationCouncilStatus ?? COUNCIL_STATUS.NOT_REVIEWED
                  ]
                }
                value={t(
                  `council.viewList.${value.specializationCouncilStatus ?? COUNCIL_STATUS.NOT_REVIEWED
                  }`
                )}
                rounded
              />
            </div>
          </>
        )}
        {value?.financeCouncilStatus && (
          <>
            <span className="my-1">- {t('council.finance')}</span>
            <div className="flex justify-content-center">
              <Tag
                style={{
                  maxWidth: '8rem',
                  minWidth: '4rem',
                  textAlign: 'center',
                }}
                severity={
                  councilStatusSeverityMap[
                  value.financeCouncilStatus ?? COUNCIL_STATUS.NOT_REVIEWED
                  ]
                }
                value={t(
                  `council.viewList.${value.financeCouncilStatus ?? COUNCIL_STATUS.NOT_REVIEWED}`
                )}
                rounded
              />
            </div>
          </>
        )}
      </div>
    );
  };
  // #endregion Render

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          {isError && <h3 className="text-red-600">{mapError(t, error)}</h3>}
          <div className="flex justify-content-between align-items-end mb-4 pb-2">
            <h5 className="p-0 m-0">{t('projectProposal.viewList.title')}</h5>
            <Link to="/project-proposal/create">
              <Button
                className="bg-cyan-600 border-cyan-600"
                label={t('projectProposal.viewList.add')}
                icon="pi pi-plus"
              />
            </Link>
          </div>

          <DataTable
            value={projectProposals}
            className="p-datatable-gridlines header-table"
            showGridlines
            dataKey="id"
            filterDisplay="menu"
            loading={isLoading}
            responsiveLayout="scroll"
            emptyMessage={t('projectProposal.viewList.emptyMessage')}
          >
            <Column
              field="vietnameseName"
              header={t('projectProposal.viewList.nameVN')}
              style={{ minWidth: '12rem' }}
            />
            <Column
              field="englishName"
              header={t('projectProposal.viewList.nameEN')}
              style={{ minWidth: '12rem' }}
            />
            <Column
              header={t('projectProposal.viewList.owner')}
              headerStyle={{ justifyContent: 'center' }}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatOwner}
            />
            <Column
              field="implementationMonths"
              header={t('projectProposal.viewList.implementationTime')}
              dataType="numeric"
              style={{ maxWidth: '10rem', textAlign: 'center' }}
            />
            <Column
              field="totalExpenditure"
              header={t('projectProposal.viewList.totalExpenditure')}
              dataType="numeric"
              style={{ minWidth: '10rem', textAlign: 'center' }}
              body={formatTotalExpenditure}
            />
            <Column
              field="status.name"
              header={t('projectProposal.viewList.status')}
              style={{ width: '9rem', textAlign: 'center' }}
              body={formatStatus}
            />
            <Column
              header={t('projectProposal.result')}
              style={{ minWidth: '9.5rem', textAlign: 'center' }}
              body={formatCouncil}
            />
            <Column
              header={t('projectProposal.action')}
              body={formatActions}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              frozen
              alignFrozen="right"
            />
          </DataTable>
        </div>
      </div>

      <Confirm ref={dialogConfirmDeleteRef} refetch={refetch} />
      <ViewRequests ref={dialogViewRequestsRef} refetchProjectProposals={refetch} />

      <ViewHistory
        data={projectHistory}
        visible={viewHistoryVisible}
        setVisible={setViewHistoryVisible}
        title={t('projectProposal.viewHistory.title')}
      />
      <RequestUpdateType
        isVisible={isVisibleUpdate}
        onHide={() => setIsVisibleUpdate(false)}
        projectProposal={projectProposal}
      />
    </div>
  );
}

export default ProjectProposalViewList;
