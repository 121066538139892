import instance from 'utils/axios/instance.axios';
import { PROJECT_PROPOSALS } from './_constant';

export const getAll = () => instance.get(PROJECT_PROPOSALS.GET_ALL);

export const getAllVersions = (id) => instance.get(PROJECT_PROPOSALS.GET_ALL_VERSIONS(id));

export const getGeneralInfoById = (id) =>
  instance.get(PROJECT_PROPOSALS.GET_GENERAL_INFO_BY_ID(id));

export const getExpenditureById = (id) => instance.get(PROJECT_PROPOSALS.GET_EXPENDITURE_BY_ID(id));

export const getResearchResultById = (id) =>
  instance.get(PROJECT_PROPOSALS.GET_RESEARCH_RESULT_BY_ID(id));

export const getResearchDescriptionById = (id) =>
  instance.get(PROJECT_PROPOSALS.GET_RESEARCH_DESCRIPTION_BY_ID(id));

export const getDocumentsById = (id) => instance.get(PROJECT_PROPOSALS.GET_THE_REST_BY_ID(id));

export const getById = (id) => instance.get(PROJECT_PROPOSALS.GET_BY_ID(id));

export const getAllResearchFields = () => instance.get(PROJECT_PROPOSALS.GET_ALL_RESEARCH_FIELDS);

export const getAllResearchTypes = () => instance.get(PROJECT_PROPOSALS.GET_ALL_RESEARCH_TYPES);

export const getOwner = () => instance.get(PROJECT_PROPOSALS.GET_OWNER);

export const getAllProjectRoles = () => instance.get(PROJECT_PROPOSALS.GET_ALL_PROJECT_ROLES);

export const getAllResearchKpis = () => instance.get(PROJECT_PROPOSALS.GET_ALL_RESEARCH_KPIS);
export const getAllResearchKpiGroups = () =>
  instance.get(PROJECT_PROPOSALS.GET_ALL_RESEARCH_KPI_GROUPS);

export const getRetrieveContacts = (searchQuery) =>
  instance.get(PROJECT_PROPOSALS.GET_RETRIEVE_CONTACTS, {
    params: { fullname: searchQuery },
  });

export const getAllOrganizations = () => instance.get(PROJECT_PROPOSALS.GET_ALL_ORGANIZATIONS);

export const getAllProposedExpenditureTypes = () =>
  instance.get(PROJECT_PROPOSALS.GET_ALL_PROPOSED_EXPENDITURE_TYPES);

export const generalInfoForCouncil = (id) =>
  instance.get(PROJECT_PROPOSALS.GET_GENERAL_INFO_FOR_COUNCIL(id));

export const getReviseLogs = ({ projectProposalId, types, isOpened }) => {
  if (projectProposalId) {
    return instance.get(PROJECT_PROPOSALS.GET_REVISE_LOGS(projectProposalId), {
      params: { types, isOpened },
    });
  }
  return null;
};

export const getReviseLogById = ({ projectProposalId, reviseLogId }) => {
  if (projectProposalId) {
    return instance.get(PROJECT_PROPOSALS.GET_REVISE_LOG_BY_ID(projectProposalId, reviseLogId));
  }
  return null;
};

export const create = (data = {}) => instance.post(PROJECT_PROPOSALS.CREATE, data);

export const update = ({ id, data = {} }) => instance.patch(PROJECT_PROPOSALS.UPDATE(id), data);

export const deleteById = (id) => instance.delete(PROJECT_PROPOSALS.DELETE(id));

export const updateStatus = ({ projectId, programId }) =>
  instance.patch(PROJECT_PROPOSALS.UPDATE_STATUS(projectId), { programId });

export const completeUpdateRequests = (data = {}) =>
  instance.post(PROJECT_PROPOSALS.COMPLETE_UPDATE_REQUESTS, data);

export const completeExplanationRequest = (data = {}) =>
  instance.post(PROJECT_PROPOSALS.COMPLETE_EXPLANATION_REQUEST, data);

export const uploadFiles = ({ projectProposalId, files }) =>
  instance.patch(PROJECT_PROPOSALS.UPLOAD_FILES(projectProposalId), files);

export const getFileUrls = (id) => instance.get(PROJECT_PROPOSALS.GET_FILE_URLS(id));

export const downloadFile = (data = {}) =>
  instance.post(PROJECT_PROPOSALS.DOWNLOAD_FILE, data, { responseType: 'blob' });

export const downloadDocxFile = (id) =>
  instance.get(PROJECT_PROPOSALS.DOWNLOAD_DOCX_FILE(id), { responseType: 'blob' });

export const downloadPdfFile = (id) =>
  instance.get(PROJECT_PROPOSALS.DOWNLOAD_PDF_FILE(id), { responseType: 'blob' });

export const downloadZip = (id) =>
  instance.get(PROJECT_PROPOSALS.DOWNLOAD_ZIP(id), { responseType: 'blob' });

export const downloadExplanation = (id) =>
  instance.get(PROJECT_PROPOSALS.DOWNLOAD_EXPLANATION(id), { responseType: 'blob' });

export const downloadScientificProfileFile = (id) =>
  instance.post(
    PROJECT_PROPOSALS.DOWNLOAD_SCIENTIFIC_PROFILE_FILE,
    { id },
    { responseType: 'blob' }
  );

export const createOrUpdateOrganization = ({ projectProposalId, data = {} }) =>
  instance.post(PROJECT_PROPOSALS.CREATE_OR_UPDATE_ORGANIZATION(projectProposalId), data);

export const createOrUpdatePartners = ({ projectProposalId, data = {} }) =>
  instance.patch(PROJECT_PROPOSALS.CREATE_OR_UPDATE_PARTNERS(projectProposalId), data);

export const uploadOtherProofsAndAppendicesFiles = ({ projectProposalId, data }) =>
  instance.patch(
    PROJECT_PROPOSALS.UPLOAD_RESEARCH_DESCRIPTION_PROOF_FILES(projectProposalId),
    data
  );

export const getProducts = (projectProposalId) =>
  instance.get(PROJECT_PROPOSALS.GET_PRODUCTS, { params: { projectProposalId } });

export const updateType = (data = {}) => instance.post(PROJECT_PROPOSALS.UPDATE_PROJECT_TYPE, data);
