import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactRouterPrompt from 'react-router-prompt';
import { Link, useLocation } from 'react-router-dom';

import {
  DatePicker,
  EditorInput,
  MaskInput,
  NumberInput,
  Select,
  TextareaInput,
  TextInput,
} from 'components/FormControl';

import { Dialog } from 'primereact/dialog';

import PropTypes from 'prop-types';
import {
  getAcademicDegrees,
  getAcademicRanks,
  getProjectTypes,
  getYearProposed,
  setOrganizationValues,
} from 'utils/func';
import { PROJECT_PROPOSAL_ORGANIZATION_TYPES } from 'constant';

import PartnerFields from './PartnerFields';
import RecommendedExpertsTable from './RecommendedExpertsTable';
import ResearchersTable from './ResearchersTable';
import ResearchLinesTable from './ResearchLinesTable';

export default function GeneralInfoForm({
  // eslint-disable-next-line react/prop-types
  pubProjectProposals = {},
  researchFields,
  researchTypes,
  organizations,
  projectRoles,
  disabled,
  errors,
  control,
  watch,
  setValue,
  isDirty,
  dirtyFields,
  footerConfirmationPrompt,
  onBindingIntoOwnerFieldClick,
  onBindingIntoLeadOrganizationFieldClick,
  onBindingIntoContactFieldClick,
  handleDownloadFile,
  handleDownloadScientificProfileFile,
}) {
  // #region Data
  const { t } = useTranslation();
  const location = useLocation();
  const [pubProjectProposalId, setPubProposalId] = useState(
    location?.state?.pubProjectProposalId || watch('general.pubProjectProposalId')
  );

  const currentLead = watch('general.lead');
  const projectProposalResearchFields = watch('general.projectProposalResearchFields');

  const partnerOrganizations = useMemo(
    () => organizations?.filter((item) => item?.inVnuHcm === 0) ?? [],
    [organizations]
  );

  const projectTypes = getProjectTypes(t);
  const yearsProposed = getYearProposed();

  const genders = [
    { value: 'male', name: t('gender.male') },
    { value: 'female', name: t('gender.female') },
  ];
  const pubProjectProposalNames = pubProjectProposals.map((item) => ({
    value: item?.id,
    name: item?.vietnamese_name,
  }));

  useEffect(() => {
    setPubProposalId(
      location?.state?.pubProjectProposalId || watch('general.pubProjectProposalId')
    );
  }, [location?.state, watch('general.pubProjectProposalId')]);

  useEffect(() => {
    setValue('general.pubProjectProposalId', pubProjectProposalId);
    setValue('general.pubProjectProposal', {
      id: pubProjectProposalId,
      vietnameseName: pubProjectProposals.find((item) => item.id === pubProjectProposalId)
        ?.vietnamese_name,
    });
  }, [pubProjectProposalId]);

  // #endregion

  // #region Event
  const handleOrganizationChange = (name, id) => {
    const organization = organizations.find((e) => e.id === id);
    if (organization) {
      setOrganizationValues(
        setValue,
        'general.lead',
        organization,
        PROJECT_PROPOSAL_ORGANIZATION_TYPES.LEAD
      );
      setValue('general.lead.specificName', '');
    }
  };

  const handleOrganizationRemove = () => {
    setOrganizationValues(setValue, 'general.lead', null);
    setValue('general.lead.specificName', '');
  };

  const handleResearchFieldRemove = (index) => {
    const newProjectProposalResearchFields = [...projectProposalResearchFields];
    newProjectProposalResearchFields[index].researchFieldId = '';
    newProjectProposalResearchFields[index].researchLine = '';

    setValue('general.projectProposalResearchFields', newProjectProposalResearchFields, {
      shouldDirty: true,
    });
  };
  const handlePubProposalChange = (name, value) => {
    setPubProposalId(value);
    setValue('general.pubProjectProposalId', value);
  };
  // #endregion
  return (
    <div className="relative">
      <h4 className="text-center mb-4">{t('projectProposal.general.label')}</h4>
      <h4>{t('projectProposal.general.a1.label')}</h4>
      <div className="grid">
        <div className="col-12">
          <TextareaInput
            label={t('projectProposal.general.a1.vietnameseName')}
            name="general.vietnameseName"
            isRequired
            control={control}
            errors={errors}
          />
        </div>
        <div className="col-12">
          <TextareaInput
            label={t('projectProposal.general.a1.englishName')}
            name="general.englishName"
            isRequired
            control={control}
            errors={errors}
          />
        </div>
        <div className="col-12">
          <Select
            label={t('projectProposal.general.a1.projectType')}
            name="general.projectType"
            isRequired
            options={projectTypes}
            inputField="value"
            disabled={disabled}
            control={control}
            errors={errors}
          />
        </div>
        {((location?.state && location?.state?.pubProjectProposalName) || pubProjectProposalId) && (
          <div className="col-12 flex items-center">
            <div className="col-6">
              <Select
                label={t('projectProposal.general.a1.pubProposal')}
                name="general.pubProjectProposalId"
                options={pubProjectProposalNames}
                inputField="value"
                disabled={disabled}
                control={control}
                errors={errors}
                onSelectChange={handlePubProposalChange}
                placeholder={location?.state?.pubProjectProposalName}
              />
            </div>
            <div className="col-6 flex items-center justify-center">
              <Link
                target="_blank"
                to={`/pub-project-proposal/${pubProjectProposalId}`}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <i className="pi pi-eye" style={{ fontSize: '1.5rem' }} />
              </Link>
            </div>
          </div>
        )}
      </div>

      <h4>{t('projectProposal.general.a2.label')}</h4>
      <div className="grid">
        <div className="col-12 lg:col-6">
          <Select
            label={t('projectProposal.general.a2.prio1.researchField')}
            name="general.projectProposalResearchFields.0.researchFieldId"
            options={researchFields}
            disabled={disabled}
            control={control}
            errors={errors}
            onSelectRemove={() => handleResearchFieldRemove(0)}
          />
        </div>
        <div className="col-12 lg:col-6">
          <TextareaInput
            label={t('projectProposal.general.a2.researchLine')}
            name="general.projectProposalResearchFields.0.researchLine"
            control={control}
            errors={errors}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12 lg:col-6">
          <Select
            label={t('projectProposal.general.a2.prio2.researchField')}
            name="general.projectProposalResearchFields.1.researchFieldId"
            options={researchFields}
            onSelectRemove={() => handleResearchFieldRemove(1)}
            disabled={disabled}
            control={control}
            errors={errors}
          />
        </div>
        <div className="col-12 lg:col-6">
          <TextareaInput
            label={t('projectProposal.general.a2.researchLine')}
            name="general.projectProposalResearchFields.1.researchLine"
            control={control}
            errors={errors}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12 lg:col-6">
          <Select
            label={t('projectProposal.general.a2.prio3.researchField')}
            name="general.projectProposalResearchFields.2.researchFieldId"
            options={researchFields}
            onSelectRemove={() => handleResearchFieldRemove(2)}
            disabled={disabled}
            control={control}
            errors={errors}
          />
        </div>
        <div className="col-12 lg:col-6">
          <TextareaInput
            label={t('projectProposal.general.a2.researchLine')}
            name="general.projectProposalResearchFields.2.researchLine"
            control={control}
            errors={errors}
          />
        </div>
      </div>

      <RecommendedExpertsTable disabled={disabled} control={control} errors={errors} />

      <h4>{t('projectProposal.general.a3.label')}</h4>
      <div className="grid">
        <div className="col-12 sm:col-6 md:col-4">
          <Select
            label={t('projectProposal.general.a3.researchType')}
            name="general.researchTypesId"
            options={researchTypes}
            isRequired
            disabled={disabled}
            control={control}
            errors={errors}
          />
        </div>
      </div>

      <h4>{t('projectProposal.general.a4.label')}</h4>
      <div className="grid">
        <div className="col-12 sm:col-6 md:col-4">
          <NumberInput
            label={t('projectProposal.general.a4.researchTime')}
            name="general.implementationMonths"
            control={control}
            errors={errors}
            min={0}
            isRequired
          />
        </div>
        <div className="col-12 sm:col-6 md:col-4">
          <Select
            label={t('projectProposal.general.a4.yearProposed')}
            name="general.yearProposed"
            isRequired
            options={yearsProposed}
            inputField="value"
            disabled={disabled}
            control={control}
            errors={errors}
          />
        </div>
      </div>

      <div className=" mb-3 mt-4 flex gap-2 align-items-center">
        <h4 className="mr-2 mb-1">{t('projectProposal.general.a6.label')}</h4>
        {!disabled && (
          <span
            className="text-green-500 text-base cursor-pointer font-medium"
            onClick={onBindingIntoOwnerFieldClick}
          >
            <i className="pi pi-replay mr-1" />
            {t('projectProposal.general.a6.refresh')}
          </span>
        )}
      </div>

      <div className="grid">
        <div className="col-12 lg:col-4">
          <TextareaInput
            label={t('projectProposal.general.a6.owner.name')}
            name="general.owner.fullname"
            control={control}
            errors={errors}
          />
        </div>
        <div className="col-12 lg:col-4">
          <Select
            label={t('projectProposal.general.a6.owner.academicRank')}
            name="general.owner.academicRank"
            control={control}
            errors={errors}
            options={getAcademicRanks(t)}
            inputField="value"
            disabled={disabled}
          />
        </div>
        <div className="col-12 lg:col-4">
          <Select
            label={t('projectProposal.general.a6.owner.academicDegree')}
            name="general.owner.academicDegree"
            control={control}
            errors={errors}
            options={getAcademicDegrees(t)}
            inputField="value"
            disabled={disabled}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <label className="inline-block mb-2">
            {t('projectProposal.general.a9.fields.researchLines')}
          </label>
          <ResearchLinesTable
            name="general.owner.researchLines"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12 lg:col-4">
          <DatePicker
            label={t('projectProposal.general.a6.owner.dob')}
            name="general.owner.dob"
            control={control}
            errors={errors}
          />
        </div>
        <div className="col-12 lg:col-4">
          <Select
            label={t('projectProposal.general.a6.owner.gender')}
            name="general.owner.gender"
            control={control}
            errors={errors}
            options={genders}
            inputField="value"
            disabled={disabled}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12 lg:col-4">
          <MaskInput
            label={t('projectProposal.general.a6.owner.citizenId')}
            name="general.owner.citizenId"
            control={control}
            errors={errors}
            mask={''.padStart(20, '9')}
          />
        </div>
        <div className="col-12 lg:col-4">
          <DatePicker
            label={t('projectProposal.general.a6.owner.dateOfIssue')}
            name="general.owner.dateOfIssue"
            control={control}
            errors={errors}
          />
        </div>
        <div className="col-12 lg:col-4">
          <TextareaInput
            label={t('projectProposal.general.a6.owner.placeOfIssue')}
            name="general.owner.placeOfIssue"
            control={control}
            errors={errors}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12 lg:col-4">
          <MaskInput
            label={t('projectProposal.general.a6.owner.taxIdentificationNumber')}
            name="general.owner.taxIdentificationNumber"
            control={control}
            errors={errors}
            mask={''.padStart(20, '9')}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12 lg:col-4">
          <MaskInput
            label={t('projectProposal.general.a6.owner.bankAccount')}
            name="general.owner.bankAccount"
            control={control}
            errors={errors}
            mask={''.padStart(30, '9')}
          />
        </div>
        <div className="col-12 lg:col-4">
          <TextareaInput
            label={t('projectProposal.general.a6.owner.bank')}
            name="general.owner.bank"
            control={control}
            errors={errors}
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-12">
          <TextareaInput
            label={t('projectProposal.general.a6.owner.workplace')}
            name="general.owner.workplace"
            control={control}
            errors={errors}
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-12 lg:col-6">
          <MaskInput
            label={t('projectProposal.general.a6.owner.mobile')}
            name="general.owner.mobile"
            control={control}
            errors={errors}
            mask={''.padStart(10, '9')}
          />
        </div>
        <div className="col-12 lg:col-6">
          <TextInput
            label={t('projectProposal.general.a6.owner.email')}
            name="general.owner.email"
            control={control}
            errors={errors}
            disabled
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <EditorInput
            label={t('projectProposal.general.a6.owner.summarizedExperience')}
            name="general.owner.experience"
            control={control}
            errors={errors}
            readOnly={disabled}
          />
        </div>
      </div>

      <div className=" mb-3 mt-4 flex align-items-center">
        <h4 className="mb-1 mr-2">
          {t('projectProposal.general.a7.label')}{' '}
          <span className="text-red-500 font-bold text-sm">*</span>
        </h4>
        {!disabled && (
          <span
            className="text-green-500 text-base cursor-pointer font-medium"
            onClick={() => onBindingIntoLeadOrganizationFieldClick(currentLead?.organizationId)}
          >
            <i className="pi pi-replay mr-1" />
            {t('formLayout.action.refresh')}
          </span>
        )}
      </div>

      <div className="grid">
        <div className="col-12">
          <Select
            label={t('projectProposal.general.organization.name')}
            name="general.lead.organizationId"
            options={organizations}
            disabled={disabled}
            control={control}
            errors={errors}
            onSelectChange={handleOrganizationChange}
            onSelectRemove={handleOrganizationRemove}
          />
        </div>
        {currentLead?.englishName === 'Other' && (
          <div className="col-12">
            <TextareaInput
              label={t('projectProposal.general.organization.specificName')}
              name="general.lead.specificName"
              control={control}
              errors={errors}
              disabled={disabled}
            />
          </div>
        )}
        <div className="col-12">
          <TextareaInput
            label={t('projectProposal.general.organization.chief')}
            name="general.lead.chief"
            control={control}
            errors={errors}
            disabled={disabled || currentLead?.englishName !== 'Other'}
          />
        </div>
        <div className="col-12 sm:col-6">
          <TextareaInput
            label={t('projectProposal.general.organization.phone')}
            name="general.lead.phone"
            control={control}
            errors={errors}
            disabled={disabled || currentLead?.englishName !== 'Other'}
          />
        </div>
        <div className="col-12 sm:col-6">
          <TextareaInput
            label={t('projectProposal.general.organization.fax')}
            name="general.lead.fax"
            control={control}
            errors={errors}
            disabled={disabled || currentLead?.englishName !== 'Other'}
          />
        </div>
        <div className="col-12">
          <TextareaInput
            label={t('projectProposal.general.organization.email')}
            name="general.lead.email"
            control={control}
            errors={errors}
            disabled={disabled || currentLead?.englishName !== 'Other'}
          />
        </div>
        <div className="col-12 sm:col-6">
          <TextareaInput
            label={t('projectProposal.general.organization.bankAccount')}
            name="general.lead.bankAccount"
            control={control}
            errors={errors}
            disabled={disabled || currentLead?.englishName !== 'Other'}
          />
        </div>
        <div className="col-12 sm:col-6">
          <TextareaInput
            label={t('projectProposal.general.organization.bank')}
            name="general.lead.bank"
            control={control}
            errors={errors}
            disabled={disabled || currentLead?.englishName !== 'Other'}
          />
        </div>
      </div>

      <PartnerFields
        organizations={partnerOrganizations}
        disabled={disabled}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        handleDownloadFile={handleDownloadFile}
      />

      <h4>{t('projectProposal.general.a9.label')}</h4>
      <ResearchersTable
        organizations={organizations}
        projectRoles={projectRoles}
        watch={watch}
        disabled={disabled}
        control={control}
        errors={errors}
        onBindingIntoContactFieldClick={onBindingIntoContactFieldClick}
        handleDownloadScientificProfileFile={handleDownloadScientificProfileFile}
      />
      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields)}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>
    </div>
  );
}

GeneralInfoForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  researchFields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  researchTypes: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organizations: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectRoles: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dirtyFields: PropTypes.object,
  isDirty: PropTypes.bool,
  footerConfirmationPrompt: PropTypes.func,
  onBindingIntoOwnerFieldClick: PropTypes.func,
  onBindingIntoLeadOrganizationFieldClick: PropTypes.func,
  onBindingIntoContactFieldClick: PropTypes.func,
  handleDownloadFile: PropTypes.func,
  handleDownloadScientificProfileFile: PropTypes.func,
};

GeneralInfoForm.defaultProps = {
  disabled: false,
  dirtyFields: {},
  isDirty: false,
  footerConfirmationPrompt: () => null,
  onBindingIntoOwnerFieldClick: () => null,
  onBindingIntoLeadOrganizationFieldClick: () => null,
  onBindingIntoContactFieldClick: () => null,
  handleDownloadFile: () => null,
  handleDownloadScientificProfileFile: () => null,
};
