import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

function AppMenuItem({ item, root }) {
  const itemClick = (event) => {
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item });
    }
  };

  const subMenu = item.items && item.visible !== false && (
    <CSSTransition
      timeout={{ enter: 1000, exit: 450 }}
      classNames="layout-submenu"
      in={!!root}
      key={item.label}
    >
      <ul>
        {item.items.map((child, i) => (
          <AppMenuItem item={child} index={i} className={child.badgeClass} key={child.label} />
        ))}
      </ul>
    </CSSTransition>
  );

  return (
    <li
      className={classNames({
        'layout-root-menuitem': root,
      })}
    >
      {root && item.visible !== false && (
        <div className="layout-menuitem-root-text">{item.label}</div>
      )}
      {(!item.to || item.items) && item.visible !== false ? (
        <a
          href={item.url}
          onClick={(e) => itemClick(e)}
          className={classNames(item.class, 'p-ripple')}
          target={item.target}
          tabIndex="0"
        >
          <i className={classNames('layout-menuitem-icon', item.icon)} />
          <span className="layout-menuitem-text">{item.label}</span>
          {item.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler" />}
          <Ripple />
        </a>
      ) : null}

      {item.to && !item.items && item.visible !== false ? (
        <NavLink to={item.to}>
          {({ isActive }) => (
            <span
              onClick={(e) => itemClick(e)}
              className={classNames(item.class, 'p-ripple', { active: isActive })}
              target={item.target}
              role="link"
              tabIndex="0"
            >
              <i className={classNames('layout-menuitem-icon', item.icon)} />
              <span className="layout-menuitem-text">{item.label}</span>
              {item.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler" />}
              <Ripple />
            </span>
          )}
        </NavLink>
      ) : null}

      {subMenu}
    </li>
  );
}

AppMenuItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
    to: PropTypes.string,
    target: PropTypes.string,
    disabled: PropTypes.bool,
    command: PropTypes.func,
    visible: PropTypes.bool,
    url: PropTypes.string,
    class: PropTypes.string,
    replaceUrl: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
  }).isRequired,
  root: PropTypes.bool,
};

AppMenuItem.defaultProps = {
  root: false,
};

export default AppMenuItem;
