import { useMemo, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { getAll as getAllOrganizations } from 'apis/organizations.api';
import { signup } from 'apis/users.api';

import { Select, TextInput, TextareaInput } from 'components/FormControl';
import LanguageSelect from 'components/LanguageSelect';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { getAcademicDegrees, getAcademicRanks, mapError } from 'utils/func';
import { signupValidationSchema } from '../validation';

const formOptions = { resolver: yupResolver(signupValidationSchema) };

function Signup() {
  // #region Data
  const { t } = useTranslation();
  const toast = useRef(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm(formOptions);

  const { mutate, isLoading } = useMutation(signup);

  const { data: organizationsData } = useQuery(['organizations'], getAllOrganizations);
  const organizations = useMemo(
    () =>
      organizationsData?.data.map((e) => ({
        ...e,
        name: t('lang') === 'vi' ? e?.name : e?.englishName,
      })) ?? [],
    [organizationsData, t]
  );

  const organizationId = watch('organizationId');
  const currentOrganization = useMemo(() => {
    if (organizations?.length) {
      const result = organizations?.find((item) => item?.id === organizationId) ?? {};
      setValue('organizationEnglishName', result?.englishName ?? '');
      if (result?.englishName !== 'Other') {
        setValue('specificOrganizationName', null);
      }
      return result;
    }
    return null;
  }, [organizations, organizationId]);
  // #endregion

  // #region event
  const showError = (errorMessage) => {
    toast.current.show({
      severity: 'error',
      summary: t('toast.error'),
      detail: errorMessage,
      life: 4000,
    });
  };

  const onSubmit = async (submitData) => {
    const { organizationEnglishName, ...signupParams } = submitData;

    mutate(signupParams, {
      onSuccess: () => {
        setIsSuccessModalOpen(true);
      },
      onError: (error) => {
        showError(mapError(t, error));
      },
    });
  };

  const checkValidation = () => {
    if (!isValid) {
      showError(t('errorMessage.validationErrorMessage'));
    }
  };
  // #endregion
  const dialogFooter = () => (
    <div className="flex justify-content-center">
      <Link to="/login">
        <Button
          className="bg-cyan-600 border-cyan-600 text-center block font-bold"
          style={{ width: 100 }}
        >
          OK
        </Button>
      </Link>
    </div>
  );
  // style={{ height: '98vh' }}
  return (
    <>
      <div className="p-4 fixed right-0">
        <LanguageSelect />
      </div>
      <div className="flex align-items-center justify-content-center py-4">
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: '420px' }}
        >
          <div className="text-center mb-5">
            <img src="/logo.png" alt="hyper" height={50} />
            <p className="text-900 text-xl font-medium mb-3 uppercase">{t('websiteName')}</p>
            <h4 className="text-900 text-3xl font-medium mb-3 uppercase">{t('signup.signup')}</h4>
            <span className="text-600 font-medium line-height-3">{t('signup.hadAccount')}</span>
            <Link
              to="/login"
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
            >
              {t('signup.login')}
            </Link>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <TextInput
              label={`${t('signup.fullname')} ${t('signup.noteFullName')}`}
              name="fullname"
              type="text"
              autoFocus
              control={control}
              errors={errors}
              isRequired
            />

            <div className="grid">
              <div className="col-6">
                <Select
                  label={t('signup.academicRank')}
                  name="academicRank"
                  options={getAcademicRanks(t)}
                  inputField="value"
                  control={control}
                  errors={errors}
                />
              </div>
              <div className="col-6">
                <Select
                  label={t('signup.academicDegree')}
                  name="academicDegree"
                  options={getAcademicDegrees(t)}
                  inputField="value"
                  control={control}
                  errors={errors}
                  isRequired
                />
              </div>
            </div>

            <Select
              label={t('signup.workplace')}
              name="organizationId"
              options={organizations ?? []}
              control={control}
              errors={errors}
              isRequired
            />

            {currentOrganization?.englishName === 'Other' && (
              <div className="mb-2">
                <TextareaInput
                  label={t('signup.specificOrganizationName')}
                  name="specificOrganizationName"
                  type="text"
                  control={control}
                  errors={errors}
                  isRequired
                />
              </div>
            )}

            <TextInput
              label="Email"
              name="email"
              type="text"
              control={control}
              errors={errors}
              isRequired
            />

            <TextInput
              label={t('signup.password')}
              name="password"
              type="password"
              control={control}
              errors={errors}
              isRequired
            />

            <TextInput
              label={t('signup.confirmPassword')}
              name="confirmPassword"
              type="password"
              control={control}
              errors={errors}
              isRequired
            />

            <Button
              label={t('signup.signup')}
              icon="pi pi-user"
              className="w-full bg-cyan-600 border-cyan-600"
              type="submit"
              onClick={checkValidation}
            />
          </form>
          <div className="flex flex-column align-items-center">
            <p className="m-1 pl-4 font-semibold">{t('login.or')}</p>
            <a
              href={`${process.env.REACT_APP_API_URL}/users/auth/google`}
              className="flex p-button w-full justify-content-between"
            >
              <i className="pi pi-google" />{' '}
              <span className="flex flex-1 justify-content-center  font-bold">
                {t('login.googleLogin')}
              </span>
            </a>
          </div>
        </div>
        {isLoading && <Loading />}

        <Toast ref={toast} />

        <Dialog
          header={<h3 className="text-green-400 text-center">{t('signup.success')}</h3>}
          visible={isSuccessModalOpen}
          style={{ width: '350px' }}
          closable={false}
          draggable={false}
          footer={dialogFooter}
        >
          <p className="text-center text-xl font-bold py-3">{t('signup.verifyMessage')}</p>
        </Dialog>
      </div>
    </>
  );
}

export default Signup;
