/* eslint-disable indent */
// import { yupResolver } from '@hookform/resolvers/yup';
import { create as createPrequalificationReview } from 'apis/prequalificationReviews.api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import { toast } from 'layout';
import { TOAST } from 'constant';
import { TabPanel, TabView } from 'primereact/tabview';
import { ProjectProposalDefenseDetail } from 'features/ProjectProposalDefense/pages';
import { PrequalificationForm } from 'features/PrequalificationReview/components';

export default function PrequalificationReviewFormCreate() {
  const { t } = useTranslation();
  const [isVisibleErrorDialog, setIsVisibleDialogError] = useState(false);
  const [tab, setTab] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  // eslint-disable-next-line no-unused-vars
  const councilMemberId = searchParams.get('councilMemberId');

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: {
      errors,
      // , isValid
    },
  } = useForm({
    mode: 'onChange',
    // resolver: yupResolver(createMidtermReviewValidationSchema),
  });
  const { isDirty, dirtyFields } = useFormState({ control });

  const {
    mutate,
    error: errorRes,
    isError: isErrorRes,
    isLoading: isLoadingCreate,
  } = useMutation(createPrequalificationReview);

  const onSubmit = async (data) => {
    const submitData = {
      projectProposalId,
      councilId,
      ...data,
    };

    mutate(submitData, {
      onSuccess: () => {
        reset({}, { keepValues: true });
        toast(TOAST.SUCCESS, t('council.review.createSuccess'));
        setTimeout(() => {
          const queryParams = `councilId=${councilId}&councilMemberId=${councilMemberId}`;
          navigate(`/prequalification-review/edit/${projectProposalId}?${queryParams}`);
        }, 1500);
      },
      onError: () => {
        setIsVisibleDialogError(true);
      },
    });
  };

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('formLayout.action.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('formLayout.action.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );
  // #endregion

  return (
    <>
      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields).length}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>
      <TabView activeIndex={tab} onTabChange={(e) => setTab(e.index)}>
        <TabPanel header={t('council.review.createOrUpdate')} leftIcon="pi pi-file mr-2">
          <PrequalificationForm
            isCreateForm
            isLoading={isLoadingCreate}
            isErrorRes={isErrorRes}
            errorRes={errorRes}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            isDirty={isDirty}
            dirtyFields={dirtyFields}
            errors={errors}
            isValid
            isVisibleErrorDialog={isVisibleErrorDialog}
            setIsVisibleDialogError={setIsVisibleDialogError}
          />
        </TabPanel>
        <TabPanel header={t('council.review.detailProjectProposal')} leftIcon="pi pi-list mr-2">
          <ProjectProposalDefenseDetail />
        </TabPanel>
      </TabView>
    </>
  );
}
