import instance from 'utils/axios/instance.axios';
import { EXTEND_IMPLEMENTATION_TIME } from './_constant';

export const getAll = () => instance.get(EXTEND_IMPLEMENTATION_TIME.GET_ALL);

export const getALlVersion = (id) =>
  instance.get(EXTEND_IMPLEMENTATION_TIME.GET_ALL_VERSIONS, { params: { id } });

export const getById = (id) =>
  instance.get(EXTEND_IMPLEMENTATION_TIME.GET_BY_ID, { params: { id } });

export const createExtendImplementationTime = (body = {}) =>
  instance.post(EXTEND_IMPLEMENTATION_TIME.CREATE, body);

export const uploadExtendFile = (body = {}) =>
  instance.post(EXTEND_IMPLEMENTATION_TIME.UPLOAD_EXTEND_FILE, body);

export const updateExtendFile = (body = {}) =>
  instance.patch(EXTEND_IMPLEMENTATION_TIME.UPLOAD_EXTEND_FILE, body);

export const updateExtendImplementationTime = (body = {}) =>
  instance.patch(EXTEND_IMPLEMENTATION_TIME.UPDATE, body);

export const submitExtendImplementationTime = (id) =>
  instance.post(EXTEND_IMPLEMENTATION_TIME.SUBMIT(id));

export const deleteExtendFile = (body) =>
  instance.post(EXTEND_IMPLEMENTATION_TIME.DELETE_EXTEND_FILE, body);

export const DOWNLOAD_DOCX_FILE = ({ id }) =>
  instance.post(EXTEND_IMPLEMENTATION_TIME.DOWNLOAD_DOCX_FILE, { id }, { responseType: 'blob' });

export const DOWNLOAD_DOCX_TEMPLATE = ({ projectId }) =>
  instance.post(
    EXTEND_IMPLEMENTATION_TIME.DOWNLOAD_DOCX_TEMPLATE,
    { projectId },
    { responseType: 'blob' }
  );

export const DOWNLOAD_PDF_FILE = ({ id }) =>
  instance.post(EXTEND_IMPLEMENTATION_TIME.DOWNLOAD_PDF_FILE, { id }, { responseType: 'blob' });

export const DOWNLOAD_PDF_TEMPLATE = ({ projectId }) =>
  instance.post(
    EXTEND_IMPLEMENTATION_TIME.DOWNLOAD_PDF_TEMPLATE,
    { projectId },
    { responseType: 'blob' }
  );

export const DOWNLOAD_FILE = ({ path }) =>
  instance.post(EXTEND_IMPLEMENTATION_TIME.DOWNLOAD_FILE, { path }, { responseType: 'blob' });
