import { forwardRef, useImperativeHandle, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NumberInput, Select, TextInput, TextareaInput } from 'components/FormControl';

import { yupResolver } from '@hookform/resolvers/yup';
import { TOAST } from 'constant';
import { guidingActivitySchema } from 'features/ScientificProfile/validation';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const formOptions = { resolver: yupResolver(guidingActivitySchema) };

const GuidingActivityDialog = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [guidingActivityControl, setGuidingActivityControl] = useState();
  const [visible, setVisible] = useState(false);

  const studentTargets = [
    { value: 'student', name: t('studentTarget.student') },
    { value: 'postgraduateStudent', name: t('studentTarget.postgraduateStudent') },
    { value: 'researchStudent', name: t('studentTarget.researchStudent') },
  ];

  const academicDegrees = [
    { value: 'university', name: t('academicDegree.university') },
    { value: 'master', name: t('academicDegree.master') },
    { value: 'doctorate', name: t('academicDegree.doctorate') },
  ];

  const {
    control,
    watch,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({ ...formOptions, mode: 'onChange' });
  // #region Event

  useImperativeHandle(
    ref,
    () => ({
      open: (_guidingActivityControl) => {
        setGuidingActivityControl(_guidingActivityControl);
        setVisible(true);
        if (_guidingActivityControl.type === 'INSERT') {
          reset({});
        } else if (_guidingActivityControl.type === 'UPDATE') {
          reset(_guidingActivityControl.value);
        }
      },
    }),
    []
  );

  const handleAddGuidingActivity = async () => {
    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = watch();

    const { insert, update, index, type } = guidingActivityControl;

    if (type === 'INSERT' && insert) {
      insert(index, data);
    } else if (type === 'UPDATE' && update) {
      update(index, data);
    }
    reset({});
    setVisible(false);
  };
  // #endregion Event

  return (
    <Dialog
      header={
        guidingActivityControl?.type === 'INSERT'
          ? t('scientificProfile.guidingActivity.add')
          : t('scientificProfile.guidingActivity.update')
      }
      visible={visible}
      onHide={() => {
        setVisible(false);
      }}
      draggable={false}
      className="w-full sm:w-7 lg:w-6"
    >
      <div className="grid p-fluid">
        <div className="col-6">
          <Select
            label={t('scientificProfile.guidingActivity.studentTarget')}
            name="studentTarget"
            options={studentTargets}
            control={control}
            errors={errors}
            inputField="value"
          />
        </div>
        <div className="col-6">
          <TextareaInput
            label={t('scientificProfile.guidingActivity.fullname')}
            name="studentName"
            control={control}
            errors={errors}
            isRequired
          />
        </div>
        <div className="col-6">
          <TextInput
            label={t('scientificProfile.guidingActivity.studentId')}
            name="studentId"
            control={control}
            errors={errors}
          />
        </div>
        <div className="col-6">
          <NumberInput
            label={t('scientificProfile.guidingActivity.graduationYear')}
            name="graduationYear"
            control={control}
            errors={errors}
            min={0}
            isRequired
          />
        </div>

        <div className="col-12">
          <TextareaInput
            label={t('scientificProfile.guidingActivity.thesisName')}
            name="thesisName"
            control={control}
            errors={errors}
            isRequired
          />
        </div>
        <div className="col-6">
          <Select
            label={t('scientificProfile.guidingActivity.trainingLevel')}
            name="academicDegree"
            options={academicDegrees}
            control={control}
            errors={errors}
            inputField="value"
            isRequired
          />
        </div>
        <div className="col-6">
          <TextInput
            label={t('scientificProfile.guidingActivity.projectCode')}
            name="projectCode"
            control={control}
            errors={errors}
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-content-end mt-4">
        <Button
          label={t('formLayout.action.cancel')}
          severity="danger"
          onClick={() => setVisible(false)}
          className="mr-1 w-8rem"
        />
        <Button
          label={
            guidingActivityControl?.type === 'INSERT'
              ? t('formLayout.action.add')
              : t('formLayout.action.update')
          }
          severity="info"
          type="submit"
          className="w-8rem"
          onClick={handleAddGuidingActivity}
          disabled={!Object.keys(dirtyFields).length}
        />
      </div>
    </Dialog>
  );
});

GuidingActivityDialog.propTypes = {};

GuidingActivityDialog.defaultProps = {};

export default GuidingActivityDialog;
