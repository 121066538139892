/* eslint-disable indent */
import { PRODUCT_TYPE } from 'constant/summary-report.constant';
import * as Yup from 'yup';

const createOrUpdateDocument = Yup.object().shape({
  summaryReportConferences: Yup.array()
    .of(
      Yup.object().shape({
        organizedDate: Yup.date().required('formControl.validation.required'),
        title: Yup.string().required('formControl.validation.required'),
        address: Yup.string().required('formControl.validation.required'),
        result: Yup.string().required('formControl.validation.required'),
      })
    )
    .nullable(),
  summaryReportFinishedTasks: Yup.array()
    .of(
      Yup.object().shape({
        content: Yup.string().required('formControl.validation.required'),
        result: Yup.string().required('formControl.validation.required'),
        completionLevel: Yup.string().required('formControl.validation.required'),
      })
    )
    .nullable(),
  summaryReportUnfinishedTasks: Yup.array()
    .of(
      Yup.object().shape({
        content: Yup.string().required('formControl.validation.required'),
        reason: Yup.string().required('formControl.validation.required'),
        solution: Yup.string().required('formControl.validation.required'),
      })
    )
    .nullable(),
  summaryReportParticipants: Yup.array()
    .of(
      Yup.object().shape({
        fullName: Yup.string().required('formControl.validation.required'),
        joiningDate: Yup.date().required('formControl.validation.required'),
        address: Yup.string().required('formControl.validation.required'),
        content: Yup.string().required('formControl.validation.required'),
        result: Yup.string().required('formControl.validation.required'),
      })
    )
    .nullable(),
});

export const createOrUpdateDocumentRegisterResult = Yup.object().shape({
  registerResultAuthors: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email('formControl.validation.emailInvalid').required('formControl.validation.required'),
      fullName: Yup.string().required('formControl.validation.required'),
      academicDegree: Yup.string().required('formControl.validation.required'),
      organizationId: Yup.string().required('formControl.validation.required'),
    })
  ),
  securitySeverity: Yup.string()
    .required('formControl.validation.required')
});

export const createAcceptance = Yup.object().shape({
  summaryReportFile: Yup.mixed()
    .required('formControl.validation.required')
    .test('fileRequired', 'The file is required', (value) => value),
});

export const appendixFilesSchema = Yup.object().shape({
  appendixFiles: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().when('fileName', {
        is: (value) => !value,
        then: Yup.mixed().test('isRequired', 'formControl.validation.required', (value) => {
          if (value) return true;
          return false;
        }),
      }),
    })
  ),
});

export const baseProductSchema = Yup.object().shape({
  name: Yup.string().required('formControl.validation.required'),
  file: Yup.mixed().when('fileName', {
    is: (value) => !value,
    then: Yup.mixed().test('isRequired', 'formControl.validation.required', (value) => {
      if (value) return true;
      return false;
    }),
  }),
});

export const addBaseProductSchema = Yup.object().shape({
  file: Yup.mixed().when('fileName', {
    is: (value) => !value,
    then: Yup.mixed().test('isRequired', 'formControl.validation.required', (value) => {
      if (value) return true;
      return false;
    }),
  }),
  researchKpiId: Yup.string().required('formControl.validation.required'),
  researchKpiGroupId: Yup.string().required('formControl.validation.required'),
});

export const addProductsSchema = (enableFields) => {
  // console.log('enableFields', enableFields);
  const productDetail = {};
  enableFields.forEach((field) => {
    productDetail[field] = Yup.string().required('formControl.validation.required');
  });
  // console.log('productDetail', productDetail);
  return Yup.object().shape({
    ...addBaseProductSchema.fields,
    productDetail: Yup.object().shape({
      ...productDetail,
    }),
  });
};

export const addTransferTechnologySchema = Yup.object().shape({
  ...baseProductSchema.fields,
  transferredYear: Yup.number().required('formControl.validation.required'),
  partner: Yup.string().required('formControl.validation.required'),
  contractedDate: Yup.string().nullable().required('formControl.validation.required'),
  revenue: Yup.number().required('formControl.validation.required'),
  scale: Yup.string().required('formControl.validation.required'),
});

export const mergedSchema = Yup.object().shape({
  ...createAcceptance.fields,
  ...createOrUpdateDocument.fields,
});

export const acceptanceDocumentSchema = Yup.object().shape({
  // ...createAcceptance.fields,
  ...createOrUpdateDocument.fields,
  ...appendixFilesSchema.fields,
});

// old
export const addHardProductsSchema = Yup.object().shape({
  ...baseProductSchema.fields,
  notes: Yup.string().required('formControl.validation.required'),
});
// old
export const addSoftProductsSchema = Yup.object().shape({
  ...baseProductSchema.fields,
  notes: Yup.string().required('formControl.validation.required'),
});
export default createOrUpdateDocument;

export const addProductSchema = (productTypeShort, enableFields) => {
  switch (productTypeShort) {
    case PRODUCT_TYPE.SOFT_PRODUCT.short:
    case PRODUCT_TYPE.HARD_PRODUCT.short:
    case PRODUCT_TYPE.INTELLECTUAL_PROPERTY.short:
    case PRODUCT_TYPE.TRAINING_RESULT.short:
      return addProductsSchema(enableFields); // vẫn có k10 - mẫu mới
    case PRODUCT_TYPE.TECHNOLOGY_TRANSFER.short: // có mẫu riêng
      return addTransferTechnologySchema;
    default:
      return Yup.object();
  }
};

export const createFinancialAdjustmentSchema = Yup.object().shape({
  commitment: Yup.string().required('formControl.validation.required'),
  otherExpenditure: Yup.number().min(0),
  financialAdjustmentItems: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('formControl.validation.required'),
      expenditureTypeId: Yup.string().required('formControl.validation.required'),
      beforeExpenditureFromVNUHCM: Yup.number().min(0),
      beforeExpenditureFromFunding: Yup.number().min(0),
      afterExpenditureFromVNUHCM: Yup.number().required('formControl.validation.required'),
      afterExpenditureFromFunding: Yup.number().required('formControl.validation.required'),
      reason: Yup.string().test(
        'is-change',
        'formControl.validation.noReasonInExpenditure',
        // eslint-disable-next-line func-names
        function (value) {
          const {
            beforeExpenditureFromVNUHCM,
            beforeExpenditureFromFunding,
            afterExpenditureFromVNUHCM,
            afterExpenditureFromFunding,
            expenditureTypeId,
          } = this.parent;

          return expenditureTypeId === '2aafiog2-b23h-9753-54it-d436e1d4e880'
            ? true
            : (beforeExpenditureFromVNUHCM === afterExpenditureFromVNUHCM || value !== '') &&
            (beforeExpenditureFromFunding === afterExpenditureFromFunding || value !== '');
        }
      ),
    })
  ),
});
