import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrencyInput, FileInput, TextareaInput } from 'components/FormControl';

import PropTypes from 'prop-types';

import { formatFileName } from 'utils/func';
import { toast } from 'layout';
import { TOAST } from 'constant';
import { classNames } from 'primereact/utils';

export default function ExpenditureForm({
  expenditureTypes,
  watch,
  getValues,
  setValue,
  handleDownloadFile,
  disabled,
  control,
  errors,
}) {
  // #region Data
  const currencyOptions = {
    style: 'currency',
    currency: 'VND',
  };

  const { t } = useTranslation();

  const summaries = watch('expenditure.proposedExpenditureSummaries');
  const mapSummaries = useMemo(() => {
    if (expenditureTypes?.length > 0) {
      return expenditureTypes.map((type) => ({
        ...type,
        expenditureFromVNUHCM: summaries?.[type.id]?.expenditureFromVNUHCM ?? 0,
        expenditureFromFunding: summaries?.[type.id]?.expenditureFromFunding ?? 0,
      }));
    }
    return [];
  });

  const totals = useMemo(() => {
    if (mapSummaries) {
      return mapSummaries?.reduce(
        (obj, cur) => {
          if (cur?.subProposedExpenditureTypeId === null) {
            return {
              expenditureFromVNUHCM: obj.expenditureFromVNUHCM + (cur?.expenditureFromVNUHCM ?? 0),
              expenditureFromFunding:
                obj.expenditureFromFunding + (cur?.expenditureFromFunding ?? 0),
            };
          }
          return obj;
        },
        { expenditureFromVNUHCM: 0, expenditureFromFunding: 0 }
      );
    }
    return { expenditureFromVNUHCM: 0, expenditureFromFunding: 0 };
  }, [JSON.stringify(mapSummaries)]);

  const rowTotals = useMemo(() => {
    if (mapSummaries) {
      return mapSummaries?.map(
        (item) => (item?.expenditureFromVNUHCM ?? 0) + (item?.expenditureFromFunding ?? 0)
      );
    }
    return [0, 0, 0, 0];
  }, [JSON.stringify(mapSummaries)]);

  const uploadedFileUrl = useMemo(() => getValues('expenditure.uploadedFileUrl'));

  const a5Expenditures = watch('general.researchExpense');

  // #endregion Data

  // #region Event
  useEffect(() => {
    if (
      summaries &&
      a5Expenditures &&
      totals &&
      // check if any expenditure has been typed to avoid bug expentitures are zeros
      // HAPPEN when in project proposal List
      // view detail => back or choose proposal List in menu (NOT refresh) => edit (not refresh)
      // (BECAUSE expenditureTypes still has value)
      // Just check here for sure, checked in Back-end too
      Object.values(summaries).some(
        (value) => value?.expenditureFromVNUHCM || value?.expenditureFromFunding
      )
    ) {
      // totals
      const totalExpenditureFromVNUHCM = totals?.expenditureFromVNUHCM ?? 0;
      const totalExpenditureFromFunding = totals?.expenditureFromFunding ?? 0;
      // expenditures from VNU
      const nonallocatedExpenditureId =
        expenditureTypes?.find((item) => item.order === 5)?.id ?? null;
      const nationalUniversityNonallocatedExpenditure = nonallocatedExpenditureId
        ? summaries[nonallocatedExpenditureId]?.expenditureFromVNUHCM
        : 0;
      const nationalUniversityAllocatedExpenditure =
        totalExpenditureFromVNUHCM - nationalUniversityNonallocatedExpenditure;

      // expeditures from funding
      const { fundingExpenditureOther, otherSponsorOrganization } = a5Expenditures;

      const fundingExpenditureSelf = totalExpenditureFromFunding - fundingExpenditureOther;

      const researchExpense = {
        nationalUniversityTotalExpenditure: totalExpenditureFromVNUHCM,
        nationalUniversityAllocatedExpenditure,
        nationalUniversityNonallocatedExpenditure,
        fundingExpenditure: totalExpenditureFromFunding,
        fundingExpenditureSelf,
        fundingExpenditureOther,
        totalExpenditure: totalExpenditureFromVNUHCM + totalExpenditureFromFunding,
        otherSponsorOrganization,
      };

      setValue('general.researchExpense', { ...researchExpense });
    }
  }, [summaries, JSON.stringify(a5Expenditures), JSON.stringify(totals)]);

  useEffect(() => {
    if (mapSummaries?.length > 0) {
      const term1 = mapSummaries[0];
      const term12 = mapSummaries[2];
      const term13 = mapSummaries[3];
      if (
        (term1?.expenditureFromVNUHCM ?? 0) -
          (term12?.expenditureFromVNUHCM ?? 0) -
          (term13?.expenditureFromVNUHCM ?? 0) <
        0
      ) {
        setValue(`expenditure.proposedExpenditureSummaries.${term12.id}.expenditureFromVNUHCM`, 0);
        setValue(`expenditure.proposedExpenditureSummaries.${term13.id}.expenditureFromVNUHCM`, 0);
        toast(TOAST.ERROR, t('formControl.validation.canNotGreaterThanTotal'));
        return;
      }
      if (
        (term1?.expenditureFromFunding ?? 0) -
          (term12?.expenditureFromFunding ?? 0) -
          (term13?.expenditureFromFunding ?? 0) <
        0
      ) {
        setValue(`expenditure.proposedExpenditureSummaries.${term12.id}.expenditureFromFunding`, 0);
        setValue(`expenditure.proposedExpenditureSummaries.${term13.id}.expenditureFromFunding`, 0);
        toast(TOAST.ERROR, t('formControl.validation.canNotGreaterThanTotal'));
        return;
      }
      const term11Value = {
        expenditureFromVNUHCM:
          (term1?.expenditureFromVNUHCM ?? 0) -
          (term12?.expenditureFromVNUHCM ?? 0) -
          (term13?.expenditureFromVNUHCM ?? 0),
        expenditureFromFunding:
          (term1?.expenditureFromFunding ?? 0) -
          (term12?.expenditureFromFunding ?? 0) -
          (term13?.expenditureFromFunding ?? 0),
      };
      setValue(
        `expenditure.proposedExpenditureSummaries.${mapSummaries[1].id}.expenditureFromVNUHCM`,
        term11Value?.expenditureFromVNUHCM ?? 0
      );
      setValue(
        `expenditure.proposedExpenditureSummaries.${mapSummaries[1].id}.expenditureFromFunding`,
        term11Value?.expenditureFromFunding ?? 0
      );
    }
  }, [JSON.stringify(mapSummaries)]);
  // #endregion Event

  return (
    <div>
      <h4 className="text-center mb-4">{t('projectProposal.expenditure.label')}</h4>

      <h4>{t('projectProposal.expenditure.a5.label')}</h4>
      <div className="form-table">
        <table className="mb-2">
          <thead>
            <tr>
              <th className="text-center" style={{ minWidth: 260, width: 360 }}>
                {t('projectProposal.expenditure.a5.fields.term')}
              </th>
              <th className="text-center" style={{ minWidth: 130, width: 130 }}>
                {t('projectProposal.expenditure.a5.fields.from_vnu')}
              </th>
              <th className="text-center" style={{ minWidth: 130, width: 130 }}>
                {t('projectProposal.expenditure.a5.fields.from_funding')}
              </th>
              <th className="text-center" style={{ minWidth: 130, width: 130 }}>
                {t('projectProposal.expenditure.a5.fields.total')}
              </th>
            </tr>
          </thead>
          <tbody>
            {expenditureTypes.map((type, index) => (
              <tr key={type.id}>
                <td
                  className={classNames({
                    'font-bold': type?.subProposedExpenditureTypeId === null,
                  })}
                >
                  {t('lang') === 'vi' ? type?.name : type?.englishName}
                </td>
                <td>
                  <CurrencyInput
                    name={`expenditure.proposedExpenditureSummaries.${type.id}.expenditureFromVNUHCM`}
                    control={control}
                    errors={errors}
                    disabled={type.order === 2}
                  />
                </td>
                <td>
                  <CurrencyInput
                    name={`expenditure.proposedExpenditureSummaries.${type.id}.expenditureFromFunding`}
                    control={control}
                    errors={errors}
                    disabled={type.order === 2}
                  />
                </td>
                <td className="text-right pr-3 font-bold">
                  {rowTotals[index]?.toLocaleString('vi', currencyOptions)}
                </td>
              </tr>
            ))}

            <tr>
              <td className="font-bold py-3">{t('projectProposal.expenditure.a5.total')}</td>
              <td className="text-right pr-3 font-bold">
                {totals.expenditureFromVNUHCM.toLocaleString('vi', currencyOptions)}
              </td>
              <td className="text-right pr-3 font-bold">
                {totals.expenditureFromFunding.toLocaleString('vi', currencyOptions)}
              </td>
              <td className="text-right pr-3 font-bold">
                {(totals.expenditureFromVNUHCM + totals.expenditureFromFunding).toLocaleString(
                  'vi',
                  currencyOptions
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="grid mt-2">
        <div className="col-12 sm:col-3">
          <CurrencyInput
            label={t('projectProposal.general.a5.bonusExpenditure')}
            name="general.researchExpense.bonusExpenditure"
            control={control}
            errors={errors}
            disabled
          />
        </div>
        <div className="col-12 sm:col-3">
          <CurrencyInput
            label={t('projectProposal.general.a5.fundingOther')}
            name="general.researchExpense.fundingExpenditureOther"
            control={control}
            errors={errors}
          />
        </div>
        <div className="col-12 sm:col-6">
          <TextareaInput
            label={t('projectProposal.general.a5.otherSponsor')}
            name="general.researchExpense.otherSponsorOrganization"
            control={control}
            errors={errors}
          />
        </div>
      </div>

      <h4>{t('projectProposal.expenditureDetail.label')}</h4>
      <h5>{t('projectProposal.expenditureDetail.requirement')}:</h5>
      <ul className="list-disc">
        <li>
          <span className="font-normal text-lg">
            {t('projectProposal.expenditureDetail.term1.label')}
          </span>
        </li>
        <li>
          <span className="font-normal text-lg">
            {t('projectProposal.expenditureDetail.term2.label')}
          </span>
        </li>
        <li>
          <span className="font-normal text-lg">
            {t('projectProposal.expenditureDetail.term3.label')}
          </span>
        </li>
      </ul>

      {!disabled && (
        <div className="mt-5">
          <span className="text-xl font-semibold">
            {t('projectProposal.expenditureDetail.templateFile')}:{'    '}
          </span>
          <a
            className="text-blue-500 font-italic text-lg font-medium"
            href="/files/R01C_Giai-trinh-khoan-chi.xlsx"
            download
          >
            {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
          </a>

          <FileInput
            name="expenditure.file"
            accept=".xls,.xlsx,.csv"
            control={control}
            errors={errors}
            acceptOnly="excelOnly"
          />
        </div>
      )}

      {uploadedFileUrl && (
        <span
          className="p-button p-button-text p-button-sm mt-1 p-0"
          onClick={() => handleDownloadFile(uploadedFileUrl)}
        >
          <i className="pi pi-file-excel text-green-500 text-xl" />{' '}
          <span className="text-blue-500 underline font-italic text-base">
            {formatFileName(uploadedFileUrl)}
          </span>
        </span>
      )}
    </div>
  );
}

ExpenditureForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  expenditureTypes: PropTypes.array.isRequired,
  getValues: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  handleDownloadFile: PropTypes.func,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

ExpenditureForm.defaultProps = {
  disabled: false,
  handleDownloadFile: () => null,
};
