import { Steps } from 'primereact/steps';
import { useForm, useFormState } from 'react-hook-form';
import { useState } from 'react';
import { TOAST } from 'constant';
import { toast } from 'layout';
import { useTranslation } from 'react-i18next';
import { ScrollTop } from 'primereact/scrolltop';
import { yupResolver } from '@hookform/resolvers/yup';
import { createOrUpdateDocumentRegisterResult } from 'features/Project/validation';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ReactRouterPrompt from 'react-router-prompt';
import RegisterResultForm from 'features/Project/components/RegisterResultForm';

export default function SubmitRegisterResult() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const { handleSubmit, control, setValue, getValues, watch, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(createOrUpdateDocumentRegisterResult),
  });
  const { errors, isDirty, dirtyFields, isValid } = useFormState({ control });
  const items = [
    {
      label: t('r09.general'),
    },
    {
      label: t('r09.contentRegister'),
    },
    {
      label: t('r09.review'),
    },
  ];

  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      return true;
    }
    return false;
  };

  // #Region Event

  const handleTabChange = async (e) => {
    // Can not use switch because of eslint error (conflict Prettier)
    setCurrentTab(e.index);
  };

  // #End region event
  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );

  return (
    <>
      <Steps model={items} activeIndex={currentTab} onSelect={handleTabChange} readOnly={false} />
      <RegisterResultForm
        handleSubmit={handleSubmit}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        reset={reset}
        control={control}
        errors={errors}
        tab={currentTab}
        setTab={setCurrentTab}
        isDirty={isDirty}
        dirtyFields={dirtyFields}
        checkValidation={checkValidation}
      />
      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields).length && currentTab !== 4}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>
      <ScrollTop target="parent" />
    </>
  );
}
