import PropTypes from 'prop-types';

import SoftProductTable from './SoftProductTable';
import HardProductTable from './HardProductTable';
import IntellectualPropertyTable from './IntellectualPropertyTable';
import TrainingResultTable from './TrainingResultTable';
import TransferTechnologyTable from './TransferTechnologyTable';

export default function Product({
  disabled,
  researchKpis,
  researchKpiGroups,
  control,
  errors,
  projectCode,
  handleDownloadFile,
}) {
  return (
    <>
      <SoftProductTable
        disabled={disabled}
        control={control}
        errors={errors}
        researchKpis={researchKpis}
        researchKpiGroups={researchKpiGroups}
        projectCode={projectCode}
        handleDownloadFile={handleDownloadFile}
      />
      <HardProductTable
        disabled={disabled}
        control={control}
        errors={errors}
        researchKpis={researchKpis}
        researchKpiGroups={researchKpiGroups}
        projectCode={projectCode}
        handleDownloadFile={handleDownloadFile}
      />
      <IntellectualPropertyTable
        disabled={disabled}
        researchKpis={researchKpis}
        researchKpiGroups={researchKpiGroups}
        control={control}
        errors={errors}
        projectCode={projectCode}
        handleDownloadFile={handleDownloadFile}
      />
      <TransferTechnologyTable
        disabled={disabled}
        researchKpis={researchKpis}
        researchKpiGroups={researchKpiGroups}
        control={control}
        errors={errors}
        projectCode={projectCode}
        handleDownloadFile={handleDownloadFile}
      />
      <TrainingResultTable
        disabled={disabled}
        researchKpis={researchKpis}
        researchKpiGroups={researchKpiGroups}
        control={control}
        errors={errors}
        projectCode={projectCode}
        handleDownloadFile={handleDownloadFile}
      />
    </>
  );
}

Product.propTypes = {
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  researchKpis: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  researchKpiGroups: PropTypes.array,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  projectCode: PropTypes.string.isRequired,
  handleDownloadFile: PropTypes.func,
};

Product.defaultProps = {
  disabled: false,
  researchKpis: [],
  researchKpiGroups: [],
  handleDownloadFile: () => null,
};
