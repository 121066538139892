import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { TOAST } from 'constant';
import { toast } from 'layout';
import PropTypes from 'prop-types';
import { mapError } from 'utils/func';

const Confirm = forwardRef(({ refetch }, ref) => {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [projectProposalControl, setProjectProposalControl] = useState(null);
  const { mutate, mode, value } = useMemo(
    () => ({
      mode: projectProposalControl?.mode ?? 'delete',
      mutate: projectProposalControl?.mutate ?? null,
      value: projectProposalControl?.value ?? { id: null },
    }),
    [projectProposalControl]
  );

  // states for complete update requests
  const [isCompleteRequestSuccess, setIsCompleteRequestSuccess] = useState(false);
  const [isCompleteRequestError, setIsCompleteRequestError] = useState(false);
  const [completeRequestError, setCompleteRequestError] = useState(null);
  const [completeRequestErrorDialogVisible, setCompleteRequestErrorDialogVisible] = useState(false);
  // #endregion

  // #region Event
  useImperativeHandle(
    ref,
    () => ({
      open: (_projectProposalControl) => {
        setProjectProposalControl({ ..._projectProposalControl });
        setVisible(true);
      },
    }),
    []
  );

  const footerConfirmationSubmit = () => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={() => setVisible(false)}
        autoFocus
      />
      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={() => {
          if (mode === 'delete' && !value?.id) return;
          const params = mode === 'completeUpdateRequest' ? { ...value } : value.id;

          mutate(params, {
            onSuccess: () => {
              if (mode === 'completeUpdateRequest') {
                setIsCompleteRequestSuccess(true);
              } else {
                toast(TOAST.SUCCESS, t(`projectProposal.${mode}.success`));
                setVisible(false);
                refetch();
              }
            },
            onError: (error) => {
              if (mode === 'completeUpdateRequest') {
                setIsCompleteRequestError(() => true);
                setCompleteRequestError(() => error);
                setCompleteRequestErrorDialogVisible(() => true);
              } else {
                toast(TOAST.ERROR, mapError(t, error));
              }
            },
          });
        }}
      />
    </div>
  );
  // #endregion

  return (
    <>
      <Dialog
        header={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <p className={`text-${mode === 'delete' ? 'red' : 'yellow'}-500`}>
            {t(`projectProposal.${mode}.title`)}
          </p>
        }
        visible={visible}
        position="center"
        style={{ width: '450px' }}
        onHide={() => setVisible(false)}
        draggable={false}
        footer={footerConfirmationSubmit}
      >
        {mode === 'delete' && (
          <p className="text-lg font-bold">
            {t('projectProposal.label')}:{' '}
            {t('lang') === 'vi' ? value?.vietnameseName : value?.englishName}
          </p>
        )}
        <p className="text-lg">{t(`projectProposal.${mode}.confirmationMessage`)}</p>
      </Dialog>

      {isCompleteRequestSuccess && (
        <Dialog
          header={<p className="text-green-500">{t('projectProposal.confirmationTitle')}</p>}
          visible
          position="center"
          style={{ width: '400px' }}
          onHide={() => navigate(`/project-proposal/request/${value?.reference}`)}
          draggable={false}
          // eslint-disable-next-line react/no-unstable-nested-components
          footer={() => (
            <Button
              label={t('projectProposal.cancel')}
              severity="danger"
              onClick={() => navigate(`/project-proposal/request/${value?.reference}`)}
            />
          )}
        >
          <p className="text-lg py-3">{t('projectProposal.completeUpdateRequest.success')}</p>
        </Dialog>
      )}
      {isCompleteRequestError && (
        <Dialog
          header={
            <p className="text-red-500">{t('projectProposal.completeUpdateRequest.error')}</p>
          }
          visible={completeRequestErrorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setCompleteRequestErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(completeRequestError?.response.data.message) ? (
            completeRequestError.response.data.message.map((message) => (
              <p key={new Date().toISOString()} className="text-lg text-red-500">
                {message}
              </p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, completeRequestError)}</p>
          )}
        </Dialog>
      )}
    </>
  );
});

export default Confirm;

Confirm.propTypes = {
  refetch: PropTypes.func,
};

Confirm.defaultProps = {
  refetch: () => null,
};
