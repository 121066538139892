import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Signup from 'features/Authentication/Signup';

import TokenService from 'utils/axios/token.axios';

function SignupPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t('websiteName');
  }, [t]);
  // Check user is logged
  useEffect(() => {
    const user = TokenService.getUser();
    if (user?.id) {
      navigate('/');
    }
  }, []);

  return <Signup />;
}

export default SignupPage;
