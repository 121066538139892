import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Fieldset } from 'primereact/fieldset';
import { Card } from 'primereact/card';
import { useState } from 'react';
import '../style.scss';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Paginator } from 'primereact/paginator';
import PaperCard from './Cards/PaperCard';

export default function PublishedScientificWorksTab({ data }) {
  // #region Data
  const { t } = useTranslation();

  const [first, setFirst] = useState([0, 0, 0]);
  const [rows, setRows] = useState([10, 10, 10]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageInputTooltip, setPageInputTooltip] = useState('Press Enter key to go to this page.');

  const totalPapers = data?.papers?.length || 0;
  const totalPages = Math.ceil(totalPapers / rows[0]);

  // #endregion Data

  // #region Event

  const onPageChange = (e, index) => {
    setFirst(first.map((f, i) => (index === i ? e.first : f)));
    setRows(rows.map((r, i) => (index === i ? e.rows : r)));
  };

  const onPageInputChange = (event) => {
    setCurrentPage(event.target.value);
  };

  const onPageInputKeyDown = (event, options) => {
    if (event.key === 'Enter') {
      const page = parseInt(currentPage, 10);

      if (page < 0 || page > options.totalPages) {
        setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
      } else {
        const firstCopy = [...first];

        firstCopy[0] = currentPage ? options.rows * (page - 1) : 0;

        setFirst(firstCopy);
        setPageInputTooltip('Press Enter key to go to this page.');
      }
    }
  };

  // #endregion Event

  // #region Render

  const handleCurentPageReport = (options) => (
    <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
      Go to{' '}
      <InputText
        size="2"
        className="ml-1"
        value={currentPage}
        tooltip={pageInputTooltip}
        onKeyDown={(e) => onPageInputKeyDown(e, options)}
        onChange={onPageInputChange}
      />
    </span>
  );

  const handleRowPerPage = (options) => {
    const dropdownOptions = [
      { label: 5, value: 5 },
      { label: 10, value: 10 },
      { label: 20, value: 20 },
      { label: 'All', value: options.totalRecords },
    ];

    return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
  };

  const handlePageLinks = (options) => {
    if (
      (options.view.startPage === options.page && options.view.startPage !== 0) ||
      (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
    ) {
      const className = classNames(options.className, { 'p-disabled': true });

      return (
        <span className={className} style={{ userSelect: 'none' }}>
          ...
        </span>
      );
    }

    return (
      <button
        type="button"
        className={classNames(options.className, 'text-base')}
        onClick={options.onClick}
      >
        {options.page + 1}
        <Ripple />
      </button>
    );
  };

  const handleNextPageLink = (options) => (
    <button
      type="button"
      className={classNames(options.className, 'border-round', 'text-base')}
      onClick={options.onClick}
      disabled={options.disabled}
    >
      <span className="p-3">Next</span>
      <Ripple />
    </button>
  );

  const handlePrevPageLink = (options) => (
    <button
      type="button"
      className={classNames(options.className, 'border-round', 'text-base')}
      onClick={options.onClick}
      disabled={options.disabled}
    >
      <span className="p-3">Previous</span>
      <Ripple />
    </button>
  );

  const template1 = {
    layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
    PrevPageLink: handlePrevPageLink,
    NextPageLink: handleNextPageLink,
    PageLinks: handlePageLinks,
    RowsPerPageDropdown: handleRowPerPage,
    CurrentPageReport: handleCurentPageReport,
  };

  const formatTimePeriod = (rowData) =>
    `${rowData?.start ? new Date(rowData?.start).toLocaleDateString('en-GB') : ''} - ${
      rowData?.end ? new Date(rowData?.end).toLocaleDateString('en-GB') : ''
    }`;

  const formatRole = (rowData) => (rowData?.role ? t(`contributorRole.${rowData.role}`) : '');

  const legendTemplate = (icon, label) => (
    <div className="flex align-items-center">
      <span className={icon} />
      <span className="font-bold text-base">{label}</span>
    </div>
  );

  const titleCustomized = (icon, label) => (
    <div className="flex align-items-center">
      <span className={icon} />
      <span className="font-bold text-lg">{label}</span>
    </div>
  );

  const itemGenneralInfo = (icon, label, content) => (
    <div className="text-base mt-2">
      <span className={`${icon} text-gray-900`} />
      <span className="text-gray-900 text-base font-medium">{label}: </span>
      {content}
    </div>
  );

  const renderBookCard = (book, label) => (
    <Card className="custom-card mt-4" title={titleCustomized('', book?.book?.title)}>
      <div>
        <span className="font-nomarl text-base">{formatRole(book)}</span>
        <span
          className="text-center mr-2 ml-2"
          style={{ fontSize: '.5rem', opacity: '0.8', verticalAlign: '25%' }}
        >
          ◆
        </span>
        <span className="font-nomarl text-base">{label}</span>
      </div>

      {book?.projectCode &&
        itemGenneralInfo(
          '',
          t('scientificProfile.scientificWork.book.projectCode'),
          book?.projectCode
        )}
      {book?.book.publisher &&
        itemGenneralInfo(
          '',
          t('scientificProfile.scientificWork.book.publisher'),
          book?.book.publisher
        )}
      {book?.year &&
        itemGenneralInfo('', t('scientificProfile.scientificWork.book.year'), book?.year)}
    </Card>
  );

  return (
    <>
      {(data?.domesticBooks?.length > 0 || data?.internationalBooks.length > 0) && (
        <Fieldset
          legend={legendTemplate(
            'pi pi-book mr-2',
            t('scientificProfile.scientificWork.book.label')
          )}
        >
          {data?.domesticBooks?.length > 0 &&
            data?.domesticBooks.map((item) =>
              renderBookCard(item, t('scientificProfile.scientificWork.book.domestic.label'))
            )}

          {data?.internationalBooks?.length > 0 &&
            data?.internationalBooks.map((item) =>
              renderBookCard(item, t('scientificProfile.scientificWork.book.international.label'))
            )}
        </Fieldset>
      )}

      <Fieldset
        className="mt-4"
        legend={legendTemplate(
          'pi pi-book mr-2',
          t('scientificProfile.scientificWork.paper.label')
        )}
      >
        <Paginator
          template={template1}
          first={first[0]}
          rows={rows[0]}
          totalRecords={data?.papers?.length || 0}
          onPageChange={(e) => onPageChange(e, 0)}
          rightContent={
            1 && (
              <div className="">
                <span className="lg:text-lg font-semibold">{`1 - ${totalPages}`}</span>
                <span className="lg:text-lg"> out of </span>
                <span className="lg:text-lg font-semibold">{`${totalPapers}`}</span>
              </div>
            )
          }
        />

        {data?.papers?.length > 0 &&
          data?.papers?.slice(first[0], first[0] + rows[0]).map((item) => {
            if (item?.paper?.publicationType === 'INTERNATIONAL') {
              return (
                <PaperCard
                  paper={item?.paper}
                  label={t('scientificProfile.scientificWork.paper.international.label')}
                  additionalInfo={
                    1 && (
                      <div className="flex" style={{ gap: '20px' }}>
                        {item?.paper?.isIsi &&
                          itemGenneralInfo(
                            '',
                            t('scientificProfile.scientificWork.paper.isIsi'),
                            item?.paper?.isIsi
                              ? t('scientificProfile.scientificWork.paper.international.yes')
                              : t('scientificProfile.scientificWork.paper.international.no')
                          )}
                        {item?.paper?.issn &&
                          itemGenneralInfo(
                            '',
                            t('scientificProfile.scientificWork.paper.issn'),
                            item?.paper?.issn
                          )}
                        {item?.paper?.if &&
                          itemGenneralInfo(
                            '',
                            t('scientificProfile.scientificWork.paper.if'),
                            item?.paper?.if
                          )}
                      </div>
                    )
                  }
                />
              );
            }
            if (item?.paper?.publicationType === 'DOMESTIC') {
              return (
                <PaperCard
                  paper={item?.paper}
                  label={t('scientificProfile.scientificWork.paper.domestic.label')}
                  additionalInfo={
                    1 && (
                      <div className="flex" style={{ gap: '20px' }}>
                        {item?.paper?.issn &&
                          itemGenneralInfo(
                            '',
                            t('scientificProfile.scientificWork.paper.issn'),
                            item?.paper?.issn
                          )}
                      </div>
                    )
                  }
                />
              );
            }
            if (item?.paper?.publicationType === 'INTERNATIONAL_CONFERENCE') {
              return (
                <PaperCard
                  paper={item?.paper}
                  label={t('scientificProfile.scientificWork.paper.internationalConference.label')}
                  additionalInfo={
                    1 && (
                      <div>
                        {item?.paper?.start &&
                          itemGenneralInfo(
                            '',
                            t('scientificProfile.scientificWork.paper.timePeriod'),
                            formatTimePeriod(item?.paper)
                          )}
                        {item?.paper?.venue &&
                          itemGenneralInfo(
                            '',
                            t('scientificProfile.scientificWork.paper.venue'),
                            item?.paper?.venue
                          )}
                        {item?.paper?.isbn &&
                          itemGenneralInfo(
                            '',
                            t('scientificProfile.scientificWork.paper.isbn'),
                            item?.paper?.isbn
                          )}
                      </div>
                    )
                  }
                />
              );
            }
            return (
              <PaperCard
                paper={item?.paper}
                label={t('scientificProfile.scientificWork.paper.domesticConference.label')}
                additionalInfo={
                  1 && (
                    <div>
                      {item?.paper?.start &&
                        itemGenneralInfo(
                          '',
                          t('scientificProfile.scientificWork.paper.timePeriod'),
                          formatTimePeriod(item?.paper)
                        )}
                      {item?.paper?.venue &&
                        itemGenneralInfo(
                          '',
                          t('scientificProfile.scientificWork.paper.venue'),
                          item?.paper?.venue
                        )}
                      {item?.paper?.isbn &&
                        itemGenneralInfo(
                          '',
                          t('scientificProfile.scientificWork.paper.isbn'),
                          item?.paper?.isbn
                        )}
                    </div>
                  )
                }
              />
            );
          })}
      </Fieldset>
    </>
  );
}

PublishedScientificWorksTab.propTypes = {
  data: PropTypes.shape(),
};

PublishedScientificWorksTab.defaultProps = {
  data: {},
};
