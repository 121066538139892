/* eslint-disable indent */
import './style.scss';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getAll as getAllProject } from 'apis/project.api';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { mapError } from 'utils/func';
import { Tag } from 'primereact/tag';
import { TIMEZONE_VN } from 'constant';

function ProjectCompletedViewList() {
  // #region Data
  const { t } = useTranslation();
  const { data, isLoading, isError, error } = useQuery('project', getAllProject);
  const project = useMemo(() => data?.data ?? [], [data?.data]);
  const projectCompleted = project?.data?.filter((item) => item?.projectStatus?.name === 'completed');

  const statuses = t('project.viewList.statuses', { returnObjects: true });

  // #endregion

  // #region Format
  const formatVietnameseName = (value) => (
    <Link
      to={`/project/${value?.id}?projectProposalId=${value?.projectProposal?.id}`}
      className="hover:underline "
    >
      {value?.projectProposal?.vietnameseName}
    </Link>
  );
  const formatOwner = (value) => value?.projectProposal?.owner?.fullname;
  const formatOrganization = (value) =>
    t('lang') === 'vi'
      ? value?.projectProposal?.projectProposalOrganizations[0]?.name
      : value?.projectProposal?.projectProposalOrganizations[0]?.englishName;
  const formatDate = (date) =>
    date ? new Date(date).toLocaleDateString('en-GB', { timeZone: TIMEZONE_VN }) : '';

  const formatStartDate = (value) => formatDate(value?.startDate);

  const formatEndDate = (value) => formatDate(value?.endDate);
  const formatTotalExpenditure = (value) => {
    if (value?.totalExpenditure === 0 && value?.proposedExpenditureSummaries?.length > 0) {
      const total = value?.proposedExpenditureSummaries.reduce((sum, cur) => {
        if (cur?.proposedExpenditureType?.subProposedExpenditureTypeId === null) {
          const expenditureFromFunding = cur?.expenditureFromFunding ?? 0;
          const expenditureFromVNUHCM = cur?.expenditureFromVNUHCM ?? 0;

          return sum + expenditureFromFunding + expenditureFromVNUHCM;
        }
        return sum;
      }, 0);
      return total?.toLocaleString('vi');
    }
    return value?.totalExpenditure?.toLocaleString('vi') || 0;
  };
  // #region Render
  const formatStatus = (value) => {
    const statusColorMapping = {
      new: 'info',
      doing: 'success',
      'mid-term': 'success',
      final: 'success',
      acceptance: 'success',
      submit: 'submit',
      liquidation: 'success',
      completed: 'success',
    };

    return (
      <Tag
        style={{ minWidth: '4rem' }}
        severity={statusColorMapping[value.projectStatus.name]}
        value={statuses[value.projectStatus.name]}
        rounded
      />
    );
  };

  return (
    <div className="grid">
      {/* {true && <Loading />} */}
      <div className="col-12">
        <div className="card">
          {isError && <h3 className="text-red-600">{mapError(t, error)}</h3>}
          <div className="flex justify-content-between align-items-end mb-4 pb-2">
            <h5 className="p-0 m-0">{t('project.viewList.title')}</h5>
          </div>

          <DataTable
            value={projectCompleted}
            className="p-datatable-gridlines header-table"
            showGridlines
            dataKey="id"
            filterDisplay="menu"
            header=""
            scrollable
            loading={isLoading}
            // responsiveLayout="scroll"
            emptyMessage={t('projectProposal.viewList.emptyMessage')}
          >
            <Column
              field="projectProposal.vietnameseName"
              body={formatVietnameseName}
              header={t('project.viewList.vietnameseName')}
              style={{
                minWidth: '10rem',
                maxWidth: '16rem',
                textAlign: 'center',
              }}
              frozen
              alignFrozen="left"
            />
            <Column
              header={t('project.viewList.owner')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatOwner}
            />
            <Column
              header={t('project.viewList.loadOrganization')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatOrganization}
            />
            <Column
              header={t('project.viewList.totalExpenditure')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatTotalExpenditure}
            />
            <Column
              header={t('project.viewList.startDate')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatStartDate}
            />
            <Column
              header={t('project.viewList.endDate')}
              style={{ minWidth: '8rem' }}
              body={formatEndDate}
            />
            <Column
              field="projectStatus.name"
              header={t('project.viewList.status')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatStatus}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export default ProjectCompletedViewList;
