import './style.scss';

import { useTranslation } from 'react-i18next';

import { Dialog } from 'primereact/dialog';

import PropTypes from 'prop-types';

import { formatFileName } from 'utils/func';

export default function ViewDetailOwnerExplanation({ reviseLog, handleClose, handleDownloadFile }) {
  const { t } = useTranslation();

  return (
    <Dialog
      header={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <span className="text-blue-500">
          {t('projectProposal.requestExplanation.ownerExplanation')}
        </span>
      }
      visible={!!reviseLog?.id}
      style={{ width: '700px' }}
      onHide={handleClose}
    >
      <div className="relative flex flex-column">
        <div className="mb-2">
          <span className="font-semibold">{t('projectProposal.requestExplanation.comment')}:</span>
          <p className="my-2 text-justify" style={{ whiteSpace: 'pre-wrap' }}>
            {reviseLog?.ownerComment}
          </p>
        </div>
        <div className="mb-2">
          <span className="mr-2 font-semibold">
            {t('projectProposal.requestExplanation.commentFile')}:
          </span>
          {reviseLog?.ownerCommentFileUrls?.length > 0 && (
            <ul className="list-disc my-1">
              {reviseLog?.ownerCommentFileUrls?.map((item, index) => (
                <li className="-ml-4" key={item?.id ?? index}>
                  <span
                    className="p-button p-button-text p-button-sm p-0"
                    onClick={() => handleDownloadFile(item?.name)}
                  >
                    <span className="text-blue-500 underline font-italic text-base">
                      {formatFileName(item?.name)}
                    </span>
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Dialog>
  );
}

ViewDetailOwnerExplanation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  reviseLog: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
};

ViewDetailOwnerExplanation.defaultProps = {
  reviseLog: null,
};
