import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CurrencyInput, TextareaInput } from 'components/FormControl';

import { getSummaryReportExpenditureType } from 'apis/summaryReport';
import { useQuery } from 'react-query';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import { useMemo } from 'react';

export default function SubExpenditureTable({ disabled, control, errors }) {
  // #region Data
  const { t } = useTranslation();
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'summaryReportExpenditures',
  });

  const { data: _data, isLoading } = useQuery(
    ['summaryReportExpenditureType'],
    getSummaryReportExpenditureType
  );
  const summaryReportExpenditureType = useMemo(() => _data?.data ?? null, [_data]);

  if (isLoading) {
    return <Loading />;
  }

  // #endregion Data
  return (
    <div className="form-table">
      <table className="mb-2">
        <thead>
          <tr>
            <th className="text-center" style={{ minWidth: 40, width: 40 }}>
              {t('formLayout.numericalOrder')}
            </th>
            <th className="text-center" style={{ minWidth: 400, width: 400 }}>
              {t('r05.b.b4.nameContentResolved')}
            </th>
            <th className="text-center" style={{ minWidth: 160, width: 160 }}>
              {t('r05.b.b4.amountOfMoney')}
            </th>
            <th className="text-center" style={{ minWidth: 380, width: 380 }}>
              {t('r05.b.b4.note')}
            </th>
            {!disabled && (
              <th className="text-center float" style={{ minWidth: 60, width: 60 }}>
                {t('projectProposal.action')}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {fields?.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center">
                {index + 1}
                <TextareaInput
                  defaultValue={
                    summaryReportExpenditureType?.filter(
                      (item1) => item1.englishName === 'Used expenditure'
                    )?.[0]?.id
                  }
                  name={`summaryReportExpenditures.${index}.summaryReportExpenditureTypeId`}
                  control={control}
                  errors={errors}
                  hidden
                />
              </td>
              <td>
                <TextareaInput
                  name={`summaryReportExpenditures.${index}.expenseName`}
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </td>
              <td>
                <CurrencyInput
                  defaultValue={0}
                  name={`summaryReportExpenditures.${index}.expenditure`}
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </td>
              <td>
                <TextareaInput
                  name={`summaryReportExpenditures.${index}.notes`}
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </td>
              {!disabled && (
                <td className="text-center p-0 float">
                  <button
                    className="p-button p-button-text p-button-icon-only p-0"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    <i
                      className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                      data-pr-tooltip={t('formLayout.action.remove')}
                      data-pr-position="left"
                    />
                  </button>
                  <Tooltip target=".remove-icon" />

                  <div className="">
                    <button
                      className="p-button p-button-text p-button-icon-only p-0"
                      type="button"
                      onClick={() => insert(index + 1)}
                    >
                      <i
                        className="insert-icon pi pi-plus text-primary cursor-pointer mt-1"
                        data-pr-tooltip={t('formLayout.action.insert')}
                        data-pr-position="left"
                      />
                    </button>
                    <Tooltip target=".insert-icon" />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {!disabled && fields?.length === 0 && (
        <div className="flex justify-content-center my-2">
          <i
            onClick={() => insert(0)}
            className="add-recommended-expert pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
            data-pr-tooltip={t('formLayout.action.add')}
            data-pr-position="left"
          />
          <Tooltip target=".add-recommended-expert" />
        </div>
      )}
    </div>
  );
}

SubExpenditureTable.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
};

SubExpenditureTable.defaultProps = {
  disabled: false,
};
