import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getAcadamicLevelShortHand, getContactOrganizationNameV2 } from 'utils/func';

export default function DetailInfo({ info }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion

  return (
    <section className="mb-3">
      <div className="border-1 border-dashed border-bottom-none p-2">
        <p className="text-lg">
          <span className="font-semibold">{t('council.m02.info.vietnameseName')}: </span>
          {info?.projectProposal?.vietnameseName}
        </p>
        <p className="text-lg">
          <span className="font-semibold">{t('council.m02.info.owner')}: </span>
          {`${getAcadamicLevelShortHand(
            info?.projectProposal?.projectProposalContacts?.[0]?.academicRank,
            info?.projectProposal?.projectProposalContacts?.[0]?.academicDegree,
            t,
            true
          )} ${info?.projectProposal?.projectProposalContacts?.[0]?.fullname ?? ''}`}
        </p>
      </div>
      <div className="border-1 border-dashed p-2">
        <p className="text-lg">
          <span className="font-semibold">{t('council.m02.info.reviewer.fullname')}: </span>
          {`${getAcadamicLevelShortHand(
            info?.user?.scientificProfile?.academicRank,
            info?.user?.scientificProfile?.academicDegree,
            t,
            true
          )} ${info?.user?.fullname ?? ''}`}
        </p>
        <p className="text-lg">
          {t('council.m02.info.reviewer.organization')}:{' '}
          {getContactOrganizationNameV2(info?.user?.organization, info?.user, t)}
        </p>
        <p className="text-lg flex flex-column sm:flex-row">
          <span className="pr-5">
            {t('council.m02.info.reviewer.personalPhone')}:{' '}
            {info?.user?.scientificProfile?.personalPhone ?? ''}
          </span>
          <span>Email: {info?.user?.email ?? ''}</span>
        </p>
        <p className="text-lg flex flex-column sm:flex-row">
          <span className="pr-5">
            {t('council.m02.info.reviewer.citizenId')}:{' '}
            {info?.user?.scientificProfile?.citizenId ?? ''}
          </span>
          <span>
            {t('council.m02.info.reviewer.taxIdentificationNumber')}:{' '}
            {info?.user?.scientificProfile?.taxIdentificationNumber ?? ''}
          </span>
        </p>
        <p className="text-lg flex flex-column sm:flex-row">
          <span className="pr-5">
            {t('council.m02.info.reviewer.bankAccount')}:{' '}
            {info?.user?.scientificProfile?.bankAccount ?? ''}
          </span>
          <span>
            {t('council.m02.info.reviewer.bank')}: {info?.user?.scientificProfile?.bank ?? ''}
          </span>
        </p>
        <p className="text-lg">
          {t('council.m02.info.reviewer.bankBranch')}:{' '}
          {info?.user?.scientificProfile?.bankBranch ?? ''}
        </p>
      </div>
    </section>
  );
}

DetailInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  info: PropTypes.object,
};

DetailInfo.defaultProps = {
  info: {},
};
