import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Fieldset } from 'primereact/fieldset';
import { Card } from 'primereact/card';
import '../style.scss';

export default function ResearchAndTeachingTab({ data }) {
  // #region Data
  const { t } = useTranslation();

  // #endregion Data

  // #region Event

  // #endregion Event

  // #region Render

  const formatManagementLevel = (rowData) =>
    rowData?.managementLevel ? t(`managementLevel.${rowData?.managementLevel}`) : '';

  const formatTrainingLevel = (rowData) =>
    rowData?.academicDegree ? t(`academicDegree.${rowData?.academicDegree}`) : '';

  const formatTimePeriod = (rowData) =>
    `${rowData?.start ? new Date(rowData?.start).toLocaleDateString('en-GB') : ''} - ${
      rowData?.end ? new Date(rowData?.end).toLocaleDateString('en-GB') : ''
    }`;

  const formatRole = (rowData) => (rowData?.role ? t(`projectRole.${rowData?.role}`) : '');

  const formatAcceptanceDate = (rowData) =>
    rowData?.acceptanceDate ? new Date(rowData?.acceptanceDate).toLocaleDateString('en-GB') : '';

  const formatResult = (rowData) => (rowData?.result ? t(`result.${rowData?.result}`) : '');

  const legendTemplate = (icon, label) => (
    <div className="flex align-items-center">
      <span className={icon} />
      <span className="font-bold text-base">{label}</span>
    </div>
  );

  const titleCustomized = (icon, label) => (
    <div className="flex align-items-center">
      <span className={icon} />
      <span className="font-bold text-lg">{label}</span>
    </div>
  );

  const itemGenneralInfo = (icon, label, content) => (
    <div className="text-base mt-2">
      <span className={icon} style={{ color: '#0000ff' }} />
      <span className="text-gray-900 text-base font-medium">{label}: </span>
      {content}
    </div>
  );

  return (
    <>
      {data?.researchActivities?.length > 0 && (
        <Fieldset
          legend={legendTemplate(
            'pi pi-briefcase mr-2',
            t('scientificProfile.researchActivity.project')
          )}
        >
          {data?.researchActivities?.length > 0 &&
            data?.researchActivities.map((item, index) => (
              <Card
                className={index === 0 ? 'custom-card' : 'custom-card mt-4'}
                title={titleCustomized('', item?.name)}
              >
                <div>
                  <span className="font-nomarl text-base">{formatRole(item)}</span>
                  <span
                    className="text-center mr-2 ml-2"
                    style={{ fontSize: '.5rem', opacity: '0.8', verticalAlign: '25%' }}
                  >
                    ◆
                  </span>
                  <span className="font-nomarl text-base">{formatManagementLevel(item)}</span>
                  <span
                    className="text-center mr-2 ml-2"
                    style={{ fontSize: '.5rem', opacity: '0.8', verticalAlign: '25%' }}
                  >
                    ◆
                  </span>
                  <span className="font-nomarl text-base">{item?.code}</span>
                  <span
                    className="text-center mr-2 ml-2"
                    style={{ fontSize: '.5rem', opacity: '0.8', verticalAlign: '25%' }}
                  >
                    ◆
                  </span>
                  <span className="font-nomarl text-base">{formatResult(item)}</span>
                </div>

                {item?.start &&
                  itemGenneralInfo(
                    '',
                    t('scientificProfile.researchActivity.timePeriod'),
                    formatTimePeriod(item)
                  )}
                {item?.acceptanceDate &&
                  itemGenneralInfo(
                    '',
                    t('scientificProfile.researchActivity.acceptanceDate'),
                    formatAcceptanceDate(item)
                  )}
                {/* {item?.expenditure &&
                  itemGenneralInfo(
                    '',
                    t('scientificProfile.researchActivity.expenditure'),
                    `${item?.expenditure} ${t('scientificProfile.researchActivity.currency')}`
                  )} */}
              </Card>
            ))}
        </Fieldset>
      )}

      <Fieldset
        className="mt-4"
        legend={legendTemplate(
          'pi pi-book mr-2',
          t('scientificProfile.guidingActivity.guidingStudent')
        )}
      >
        {data?.guidingActivities?.length > 0 &&
          data?.guidingActivities.map((item, index) => (
            <Card
              className={index === 0 ? 'custom-card' : 'custom-card mt-4'}
              title={titleCustomized('', item?.thesisName)}
            >
              {item?.studentName &&
                itemGenneralInfo(
                  '',
                  t('scientificProfile.guidingActivity.fullname'),
                  item?.studentName
                )}
              {item?.graduationYear &&
                itemGenneralInfo(
                  '',
                  t('scientificProfile.guidingActivity.graduationYear'),
                  item?.graduationYear
                )}
              {item?.academicDegree &&
                itemGenneralInfo(
                  '',
                  t('scientificProfile.guidingActivity.trainingLevel'),
                  formatTrainingLevel(item)
                )}
              {item?.projectCode &&
                itemGenneralInfo(
                  '',
                  t('scientificProfile.guidingActivity.projectCode'),
                  item?.projectCode
                )}
            </Card>
          ))}
      </Fieldset>
    </>
  );
}

ResearchAndTeachingTab.propTypes = {
  data: PropTypes.shape(),
};

ResearchAndTeachingTab.defaultProps = {
  data: {},
};
