// eslint-disable-next-line import/prefer-default-export
export const maxPoints = {
  scoreImportance: 20,
  scoreCreativity: 15,
  scoreSuitableness: 5,
  scoreQuality: 20,
  scoreProduct: 40,
  scoreAnnouncement: 30,
  scoreTraining: 10,
  scoreCapability: 15,
  scoreSubCriteria: 5, // Điểm tối đa khi đạt
};
