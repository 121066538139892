import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FileInput, TextareaInput } from 'components/FormControl';

import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { formatFileName } from 'utils/func';
import { PROJECT_PROPOSAL_ORGANIZATION_TYPES } from 'constant';

export default function PartnerFields({
  organizations,
  disabled,
  control,
  errors,
  watch,
  // eslint-disable-next-line no-unused-vars
  setValue,
  handleDownloadFile,
}) {
  // #region Data
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'general.partners',
  });

  const otherOrganization = useMemo(
    () => organizations?.find((item) => item?.englishName === 'Other') ?? {},
    [organizations]
  );

  const currentPartners = watch('general.partners');
  // #endregion Data

  return (
    <>
      <h4>{t('projectProposal.general.a8.label')}</h4>

      {fields?.map((item, index) => (
        <div key={item.id} className="relative">
          <hr className="border border-1 border-primary my-1" />
          <span className="font-bold">
            {t('formLayout.numericalOrder')}: {index + 1}
          </span>

          <div className="grid">
            {currentPartners[index]?.englishName === 'Other' && (
              <div className="col-12">
                <TextareaInput
                  label={t('projectProposal.general.organization.name')}
                  name={`general.partners.${index}.specificName`}
                  control={control}
                  errors={errors}
                  disabled={disabled}
                  isRequired
                />
              </div>
            )}

            <div className="col-12 -my-3">
              <p className="m-0 p-0 mt-2">
                {t('projectProposal.general.a8.confirmationDocument')}{' '}
                <span className="text-red-500" style={{ fontWeight: 900 }}>
                  *
                </span>
                {watch(`general.partners.${index}.partnerConfirmationUrl`) && (
                  <span
                    className="p-button p-button-text p-button-sm p-0"
                    onClick={() => {
                      handleDownloadFile(watch(`general.partners.${index}.partnerConfirmationUrl`));
                    }}
                  >
                    <i className="pi pi-file text-blue-500 text-xl" />{' '}
                    <span className="text-blue-500 underline font-italic text-base">
                      {formatFileName(watch(`general.partners.${index}.partnerConfirmationUrl`))}
                    </span>
                  </span>
                )}
              </p>
              <FileInput
                name={`general.partners.${index}.file`}
                accept=".docx,.doc,.pdf"
                acceptFileMessage={t('projectProposal.general.a8.acceptFileMessage')}
                control={control}
                errors={errors}
                disabled={disabled}
              />
            </div>

            <div className="col-12">
              <TextareaInput
                label={t('projectProposal.general.organization.chief')}
                name={`general.partners.${index}.chief`}
                control={control}
                errors={errors}
                disabled={disabled || currentPartners[index]?.englishName !== 'Other'}
                isRequired
              />
            </div>
            <div className="col-12 sm:col-6">
              <TextareaInput
                label={t('projectProposal.general.organization.phone')}
                name={`general.partners.${index}.phone`}
                control={control}
                errors={errors}
                disabled={disabled || currentPartners[index]?.englishName !== 'Other'}
                isRequired
              />
            </div>
            <div className="col-12 sm:col-6">
              <TextareaInput
                label={t('projectProposal.general.organization.fax')}
                name={`general.partners.${index}.fax`}
                control={control}
                errors={errors}
                disabled={disabled || currentPartners[index]?.englishName !== 'Other'}
                isRequired
              />
            </div>
            <div className="col-12">
              <TextareaInput
                label={t('projectProposal.general.organization.address')}
                name={`general.partners.${index}.address`}
                control={control}
                errors={errors}
                disabled={disabled || currentPartners[index]?.englishName !== 'Other'}
                isRequired
              />
            </div>
            <div className="col-12">
              <TextareaInput
                label={t('projectProposal.general.organization.email')}
                name={`general.partners.${index}.email`}
                control={control}
                errors={errors}
                disabled={disabled || currentPartners[index]?.englishName !== 'Other'}
              />
            </div>
            <div className="col-12 sm:col-6">
              <TextareaInput
                label={t('projectProposal.general.organization.bankAccount')}
                name={`general.partners.${index}.bankAccount`}
                control={control}
                errors={errors}
                disabled={disabled || currentPartners[index]?.englishName !== 'Other'}
              />
            </div>
            <div className="col-12 sm:col-6">
              <TextareaInput
                label={t('projectProposal.general.organization.bank')}
                name={`general.partners.${index}.bank`}
                control={control}
                errors={errors}
                disabled={disabled || currentPartners[index]?.englishName !== 'Other'}
              />
            </div>
            <div className="col-12">
              <TextareaInput
                label={t('projectProposal.general.organization.partnerContribution')}
                name={`general.partners.${index}.partnerContribution`}
                row={4}
                control={control}
                errors={errors}
              />
            </div>
          </div>

          {!disabled && (
            <button
              className="p-button p-button-text p-button-icon-only p-0 absolute top-0 right-0 mt-2"
              type="button"
              onClick={() => remove(index)}
              disabled={disabled}
            >
              <i type="button" className="pi pi-times text-red-500 text-xl" />
            </button>
          )}
        </div>
      ))}
      {!disabled && (
        <>
          <hr className="border border-1 border-primary mt-1 mb-1" />
          <div className="flex justify-content-center">
            <button
              className="p-button p-button-text p-button-icon-only p-0"
              type="button"
              onClick={() => {
                append({
                  file: null,
                  specificName: '',
                  organizationId: otherOrganization?.id,
                  name: otherOrganization?.name,
                  englishName: otherOrganization?.englishName,
                  chief: '',
                  phone: '',
                  fax: '',
                  email: '',
                  address: '',
                  bank: '',
                  partnerContribution: '',
                  type: PROJECT_PROPOSAL_ORGANIZATION_TYPES.PARTNER,
                });
              }}
              disabled={disabled}
            >
              <i type="button" className="pi pi-fw pi-plus text-primary text-xl" />
            </button>
          </div>
        </>
      )}
    </>
  );
}

PartnerFields.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organizations: PropTypes.array,
  disabled: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
};

PartnerFields.defaultProps = {
  organizations: [],
  disabled: false,
};
