import { getAllProposedExpenditureTypes } from 'apis/projectProposals.api';
import { currencyOptions } from 'constant';
import { Editor } from 'primereact/editor';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export default function EvaluationDetail({
  review,
  proposedExpenditureSummaries,
  projectProposalResearchKpis,
  submittedAt,
  expenditureCalculatedByKpi,
}) {
  // #region Data
  const { t } = useTranslation();

  // B2
  const { data: expenditureTypesData } = useQuery(
    ['projectProposalProposedExpenditureTypes'],
    getAllProposedExpenditureTypes,
    { staleTime: 1000 * 60 * 5 }
  );

  const expenditureTypes = useMemo(() => {
    const types = expenditureTypesData?.data ?? [];

    // For latest M02 form
    return types?.sort((a, b) => a.order - b.order);
  }, [expenditureTypesData]);

  const mapSummaries = useMemo(() => {
    if (proposedExpenditureSummaries?.length > 0) {
      return proposedExpenditureSummaries?.reduce(
        (obj, cur) => {
          // eslint-disable-next-line no-param-reassign
          obj.summaries[cur.proposedExpenditureTypeId] = {
            ...cur,
            total: (cur?.expenditureFromVNUHCM ?? 0) + (cur?.expenditureFromFunding ?? 0),
          };
          if (!cur?.proposedExpenditureType?.subProposedExpenditureTypeId) {
            // eslint-disable-next-line no-param-reassign
            obj.expenditureTotals.fromVNUHCM += cur?.expenditureFromVNUHCM ?? 0;
            // eslint-disable-next-line no-param-reassign
            obj.expenditureTotals.fromFunding += cur?.expenditureFromFunding ?? 0;
          }

          return obj;
        },
        {
          summaries: {},
          expenditureTotals: { fromVNUHCM: 0, fromFunding: 0 },
        }
      );
    }
    return {
      summaries: {},
      expenditureTotals: { fromVNUHCM: 0, fromFunding: 0 },
    };
  }, [proposedExpenditureSummaries]);

  // B3
  const { kpiTable } = useMemo(
    () =>
      // Object.entries(
      projectProposalResearchKpis?.reduce(
        (obj, cur) => {
          // researchKpiGroupCosts is sorted DESC by 'date'
          const correspondingCost = cur.researchKpi.researchKpiGroup.researchKpiGroupCosts.find(
            (rkgc) => new Date(rkgc.date) <= new Date(submittedAt)
          )?.cost;

          // eslint-disable-next-line no-param-reassign
          obj.kpiTable[cur.researchKpi.researchKpiGroup.name] = {
            id: cur.researchKpi.researchKpiGroup.id,
            order: cur.researchKpi.researchKpiGroup.order,
            cost: correspondingCost,
            kpis: [
              ...(obj.kpiTable[cur.researchKpi.researchKpiGroup.name]?.kpis ?? []),
              {
                id: cur.researchKpi.id,
                name: cur.researchKpi.name,
                englishName: cur.researchKpi.englishName,
                order: cur.researchKpi.order,
                code: cur.researchKpi.code,
                quantity: cur.quantity,
                content: cur.content,
              },
            ],
          };

          return obj;
        },
        { kpiTable: {} }
      ) ?? {},
    // ),
    [projectProposalResearchKpis]
  );

  // B4
  const {
    //
    expenditureAndKpiComparison,
    totalSuggestionExpenditureFromVNUHCMOfReviewer,
  } = JSON.parse(review?.reviewTotalExpenditureFromVNUHCM || '{}');
  // #endregion

  return (
    <div>
      {/* Section A */}
      <div className="mt-5">
        <h4>{t('council.m02.a.label')}</h4>

        {/* A1 */}
        <div className="text-lg mt-3">
          <h5>{t('council.m02.a.a1')}</h5>
          <p className="font-italic">{t('council.m02.a.a1_note')}</p>
          <Editor
            value={review?.specializeRequestReview}
            showHeader={false}
            style={{ minHeight: '250px' }}
            readOnly
          />
        </div>

        {/* A2 */}
        <div className="text-lg mt-3">
          <h5>{t('council.m02.a.a2')}</h5>
          <p className="font-italic">{t('council.m02.a.a2_note')}</p>
          <div className="form-table">
            <table className="mb-2">
              <thead>
                <tr>
                  <th className="p-3 text-center" style={{ minWidth: 260, width: 360 }}>
                    {t('projectProposal.expenditure.a5.fields.term')}
                  </th>
                  <th className="text-center" style={{ minWidth: 130, width: 130 }}>
                    {t('projectProposal.expenditure.a5.fields.from_vnu')}
                  </th>
                  <th className="text-center" style={{ minWidth: 130, width: 130 }}>
                    {t('projectProposal.expenditure.a5.fields.from_funding')}
                  </th>
                  <th className="text-center" style={{ minWidth: 130, width: 130 }}>
                    {t('projectProposal.expenditure.a5.fields.total')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Bảng kinh phí ở projectProposal trước đây lưu theo kiểu:
                "cái nào nhập thì mới lưu cái đó vô bảng chung proposedExpenditureSummaries",
                nên phải dựa vào expenditureType để hiển thị hết các loại kinh phí
                */}
                {expenditureTypes?.map((type) => (
                  <tr key={type.id}>
                    <td
                      className={classNames(
                        {
                          'font-bold': type?.subProposedExpenditureTypeId === null,
                        },
                        'p-3'
                      )}
                    >
                      {t('lang') === 'vi' ? type?.name : type?.englishName}
                    </td>
                    <td className="text-right pr-3">
                      {(
                        mapSummaries?.summaries?.[type.id]?.expenditureFromVNUHCM ?? 0
                      )?.toLocaleString('vi', currencyOptions)}
                    </td>
                    <td className="text-right pr-3">
                      {(
                        mapSummaries?.summaries?.[type.id]?.expenditureFromFunding ?? 0
                      )?.toLocaleString('vi', currencyOptions)}
                    </td>
                    <td
                      className={classNames('text-right pr-3', {
                        'font-bold': type?.subProposedExpenditureTypeId === null,
                      })}
                    >
                      {(mapSummaries?.summaries?.[type.id]?.total ?? 0).toLocaleString(
                        'vi',
                        currencyOptions
                      )}
                    </td>
                  </tr>
                ))}

                <tr>
                  <td className="font-bold p-3">{t('projectProposal.expenditure.a5.total')}</td>
                  <td className="text-right pr-3 font-bold">
                    {mapSummaries?.expenditureTotals?.fromVNUHCM?.toLocaleString(
                      'vi',
                      currencyOptions
                    )}
                  </td>
                  <td className="text-right pr-3 font-bold">
                    {mapSummaries?.expenditureTotals?.fromFunding?.toLocaleString(
                      'vi',
                      currencyOptions
                    )}
                  </td>
                  <td className="text-right pr-3 font-bold">
                    {(
                      mapSummaries.expenditureTotals.fromVNUHCM +
                      mapSummaries.expenditureTotals.fromFunding
                    ).toLocaleString('vi', currencyOptions)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* A3 */}
        <div className="text-lg mt-3">
          <h5>{t('council.m02.a.a3')}</h5>
          <p className="font-italic">{t('council.m02.a.a3_note')}</p>
          <div className="form-table">
            <table className="mb-2">
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('projectProposal.researchResult.a10_2.fields.order')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: '25%' }}>
                    {t('projectProposal.researchResult.a10_2.fields.KPI')}
                  </th>
                  <th className="text-center" style={{ minWidth: 150, width: 170 }}>
                    {t('projectProposal.researchResult.a10_2.fields.expenditureNorms')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: 100 }}>
                    {t('projectProposal.researchResult.a10_2.fields.numberRegistration')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: '25%' }}>
                    {t('projectProposal.researchResult.a10_2.fields.content')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: '25%' }}>
                    {t('projectProposal.researchResult.a10_2.fields.note')}
                  </th>
                </tr>
              </thead>
              <tbody className="p-datatable-tbody">
                {Object.entries(kpiTable)?.map((kpiGroup) =>
                  kpiGroup[1]?.kpis?.map((kpi, index) => (
                    <tr key={kpi?.id ?? index}>
                      {index === 0 ? (
                        <td
                          className="max-w-3rem text-center font-bold"
                          rowSpan={kpiGroup[1]?.kpis?.length}
                        >
                          {kpiGroup[0]}
                        </td>
                      ) : (
                        <td className="hidden" />
                      )}
                      <td className="max-w-15rem p-2">
                        {t('lang') === 'vi'
                          ? kpi?.name
                          : kpi?.englishName ||
                            t(`projectProposal.researchResult.a10_2.kpi.${kpi?.code}`)}
                      </td>
                      {index === 0 ? (
                        <td className="text-center" rowSpan={kpiGroup[1].kpis.length}>
                          {kpiGroup[1]?.cost}
                        </td>
                      ) : (
                        <td className="hidden" />
                      )}
                      <td className="max-w-4rem text-center">{kpi?.quantity || ''}</td>
                      <td>{kpi?.content}</td>
                      <td>
                        {
                          (review?.financialReviewResearchKpis ?? [])?.find(
                            (item) => item?.researchKpiId === kpi.id
                          )?.note
                        }
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* A4 */}
        <div className="text-lg mt-3">
          <h5>{t('council.m02.a.a4')}</h5>
          <p className="mb-0 text-right">{t('council.m02.a.unit')}</p>
          <div className="form-table">
            <table className="mb-2">
              <thead>
                <tr>
                  <th rowSpan={2}>{t('council.m02.a.totalProposedExpenditureFromVNUHCM')}</th>
                  <th rowSpan={2}>{t('council.m02.a.calculateByKpi')}</th>
                  <th colSpan={3}>{t('council.m02.a.evaluateTotalExpenditureVsKpiCalculation')}</th>
                  <th rowSpan={2}>{t('council.m02.a.reviewTotalExpenditureFromVNUHCM')}</th>
                </tr>
                <tr>
                  <th>{t('council.m02.a.high')}</th>
                  <th>{t('council.m02.a.low')}</th>
                  <th>{t('council.m02.a.suitable')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-3 text-right">
                    {mapSummaries?.expenditureTotals?.fromVNUHCM?.toLocaleString(
                      'vi',
                      currencyOptions
                    )}
                  </td>
                  <td className="text-right">
                    {expenditureCalculatedByKpi?.toLocaleString('vi', currencyOptions)}
                  </td>
                  <td className="text-center">{expenditureAndKpiComparison?.high && '✕'}</td>
                  <td className="text-center">{expenditureAndKpiComparison?.low && '✕'}</td>
                  <td className="text-center">{expenditureAndKpiComparison?.suitable && '✕'}</td>
                  <td className="text-right">
                    {totalSuggestionExpenditureFromVNUHCMOfReviewer?.toLocaleString(
                      'vi',
                      currencyOptions
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* A5 */}
        <div className="text-lg mt-3">
          <h5>{t('council.m02.a.a5')}</h5>
          <Editor
            value={review?.inappropriateExpenses}
            showHeader={false}
            style={{ minHeight: '250px' }}
            readOnly
          />
        </div>
      </div>

      {/* Section B */}
      <div className="mt-5">
        <h4>{t('council.m02.b.label')}</h4>
        <div className="mb-2 text-lg">
          <span>{t('council.m02.b.totalSuggestionExpenditureFromVNUHCM')} </span>
          <span className="font-semibold">
            {review?.totalSuggestionExpenditureFromVNUHCM?.toLocaleString('vi', currencyOptions)}
          </span>
          <span>, {t('council.m02.b.totalSuggestionExpenditureFromMobilizedCapital')} </span>
          <span className="font-semibold">
            {review?.totalSuggestionExpenditureFromMobilizedCapital?.toLocaleString(
              'vi',
              currencyOptions
            )}
          </span>
          .
        </div>
        <p className="text-lg">{t('council.m02.b.request')}</p>
        <Editor
          value={review?.ownerEdittingRequest}
          showHeader={false}
          style={{ minHeight: '250px' }}
          readOnly
        />
      </div>
    </div>
  );
}

EvaluationDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  review: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  proposedExpenditureSummaries: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  projectProposalResearchKpis: PropTypes.array,
  submittedAt: PropTypes.string,
  expenditureCalculatedByKpi: PropTypes.number.isRequired,
};

EvaluationDetail.defaultProps = {
  review: {},
  proposedExpenditureSummaries: [],
  projectProposalResearchKpis: [],
  submittedAt: null,
};
