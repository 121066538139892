import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';

// import { formDefaultValue } from 'features/ScientificProfile/defaultValues';
import Form from 'features/ScientificProfile/components/Form';
import { updateScientificProfileValidationSchema } from 'features/ScientificProfile/validation';

import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';

import {
  getAcademicHistoryByDocx,
  getGeneralInfoByDocx,
  getOthersInfoByDocx,
  getProjectsByDocx,
  getScientificWorksByDocx,
  getThesisGuidancesByDocx,
  getWorkHistoryByDocx,
  update,
} from 'apis/scientificProfiles.api';

import { TOAST } from 'constant';
import { toast } from 'layout';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';

import {
  mapError,
  modifyBindingScientificProfileAcademicHistoryData,
  modifyBindingScientificProfileGeneralData,
  modifyBindingScientificProfileGuidingActivitiesData,
  modifyBindingScientificProfileOthersData,
  modifyBindingScientificProfileResearchActivitiesData,
  modifyBindingScientificProfileScientificWorksData,
  modifyBindingScientificProfileWorkHistoryData,
  modifyScientificProfileAcademicHistoryUpdateData,
  modifyScientificProfileGeneralUpdateData,
  modifyScientificProfileGuidingActivitiesUpdateData,
  modifyScientificProfileOthersUpdateData,
  modifyScientificProfileResearchActivitiesUpdateData,
  modifyScientificProfileScientificWorksUpdateData,
  modifyScientificProfileWorkHistoryUpdateData,
} from 'utils/func';

const formOptions = { resolver: yupResolver(updateScientificProfileValidationSchema) };

export default function ScientificProfileEditByFile() {
  // #region Data
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [shouldUpdateFromDocx, setShouldUpdateFromDocx] = useState(false);
  const [scientificWorksDataByDocx, setScientificWorksDataByDocx] = useState(null);

  const [currentTab, setCurrentTab] = useState(0);
  const [loadedTabs, setLoadedTabs] = useState([false, false, false, false, false, false, false]);
  const [isNextTab, setIsNextTab] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorDuplicateDialogVisible, setErrorDuplicateDialogVisible] = useState([false, [], null]);
  const [errorFormatDialogVisible, setErrorFormatDialogVisible] = useState(false);

  const items = [
    {
      label: t('scientificProfile.generalInfo.label'),
    },
    {
      label: t('scientificProfile.academicHistory.label'),
    },
    {
      label: t('scientificProfile.workHistory.label'),
    },
    {
      label: t('scientificProfile.researchActivity.label'),
    },
    {
      label: t('scientificProfile.guidingActivity.label'),
    },
    {
      label: t('scientificProfile.scientificWork.label'),
    },
    {
      label: t('scientificProfile.otherInfo.label'),
    },
    {
      label: t('scientificProfile.preview.label'),
    },
  ];

  const {
    data: generalInfoDataByDocx,
    isError: isGeneralInfoErrorByDocx,
    error: generalInfoErrorByDocx,
    isFetching: isGeneralInfoFetchingByDocx,
  } = useQuery('scientificProfileGeneralInfoByDocx', () => getGeneralInfoByDocx(), {
    enabled: shouldUpdateFromDocx && !loadedTabs[0],
    onSuccess: (response) => {
      // check status code 400
      if (response?.data?.status === 400) {
        setErrorFormatDialogVisible(true);
      } else {
        toast(TOAST.SUCCESS, `${items[0].label} ${t('scientificProfile.extractedMessage')}`);
      }
    },
    onError: () => {
      toast(TOAST.WARN, `${items[0].label} ${t('scientificProfile.extractedError')}`);
      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[0] = true;
      setLoadedTabs(newSelectedTabs);
    },
  });

  const {
    data: academicHistoryDataByDocx,
    isError: isAcademicHistoryErrorByDocx,
    error: academicHistoryErrorByDocx,
    isFetching: isAcademicHistoryFetchingByDocx,
  } = useQuery('scientificProfileAcademicHistoryByDocx', () => getAcademicHistoryByDocx(), {
    enabled: shouldUpdateFromDocx && !loadedTabs[1],
    onSuccess: (response) => {
      // check status code 400
      if (response?.data?.status === 400) {
        setErrorFormatDialogVisible(true);
      } else {
        toast(TOAST.SUCCESS, `${items[1].label} ${t('scientificProfile.extractedMessage')}`);
      }
    },
    onError: () => {
      toast(TOAST.WARN, `${items[1].label} ${t('scientificProfile.extractedError')}`);
      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[1] = true;
      setLoadedTabs(newSelectedTabs);
    },
  });

  const {
    data: workHistoryDataByDocx,
    isError: isWorkHistoryErrorByDocx,
    error: workHistoryErrorByDocx,
    isFetching: isWorkHistoryFetchingByDocx,
  } = useQuery('scientificProfileWorkHistoryByDocx', () => getWorkHistoryByDocx(), {
    enabled: shouldUpdateFromDocx && !loadedTabs[2],
    onSuccess: (response) => {
      // check status code 400
      if (response?.data?.status === 400) {
        setErrorFormatDialogVisible(true);
      } else {
        toast(TOAST.SUCCESS, `${items[2].label} ${t('scientificProfile.extractedMessage')}`);
      }
    },
    onError: () => {
      toast(TOAST.WARN, `${items[2].label} ${t('scientificProfile.extractedError')}`);
      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[2] = true;
      setLoadedTabs(newSelectedTabs);
    },
  });

  const {
    data: projectsDataByDocx,
    isError: isProjectsErrorByDocx,
    error: projectsErrorByDocx,
    isFetching: isProjectsFetchingByDocx,
  } = useQuery('scientificProfileProjectsByDocx', () => getProjectsByDocx(), {
    enabled: shouldUpdateFromDocx && !loadedTabs[3],
    onSuccess: (response) => {
      if (response?.data?.status === 400) {
        setErrorFormatDialogVisible(true);
      } else {
        toast(TOAST.SUCCESS, `${items[3].label} ${t('scientificProfile.extractedMessage')}`);
      }
    },
    onError: () => {
      toast(TOAST.WARN, `${items[3].label} ${t('scientificProfile.extractedError')}`);
      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[3] = true;
      setLoadedTabs(newSelectedTabs);
    },
  });

  const {
    data: thesisGuidancesDataByDocx,
    isError: isThesisGuidancesErrorByDocx,
    error: thesisGuidancesErrorByDocx,
    isFetching: isThesisGuidancesFetchingByDocx,
  } = useQuery('scientificProfileThesisGuidancesByDocx', () => getThesisGuidancesByDocx(), {
    enabled: shouldUpdateFromDocx && !loadedTabs[4],
    onSuccess: (response) => {
      if (response?.data?.status === 400) {
        setErrorFormatDialogVisible(true);
      } else {
        toast(TOAST.SUCCESS, `${items[4].label} ${t('scientificProfile.extractedMessage')}`);
      }
    },
    onError: () => {
      toast(TOAST.WARN, `${items[4].label} ${t('scientificProfile.extractedError')}`);
      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[4] = true;
      setLoadedTabs(newSelectedTabs);
    },
  });

  const {
    isError: isScientificWorksErrorByDocx,
    error: scientificWorksErrorByDocx,
    isFetching: isScientificWorksFetchingByDocx,
  } = useQuery(
    'scientificProfileScientificWorksByDocx',
    () =>
      Promise.all([
        getScientificWorksByDocx('INTERNATIONAL').catch(() => null),
        getScientificWorksByDocx('DOMESTIC').catch(() => null),
        getScientificWorksByDocx('INTERNATIONAL_CONFERENCE').catch(() => null),
        getScientificWorksByDocx('DOMESTIC_CONFERENCE').catch(() => null),
        getScientificWorksByDocx('BOOK').catch(() => null),
      ]),
    {
      enabled: shouldUpdateFromDocx && !loadedTabs[5],
      onSuccess: (response) => {
        if (response?.data?.status === 400) {
          toast(TOAST.WARN, `${items[5].label} ${t('scientificProfile.extractedError')}`);
          const newSelectedTabs = [...loadedTabs];
          newSelectedTabs[5] = true;
          setLoadedTabs(newSelectedTabs);
        } else {
          const data = response.splice(1).reduce((acc, res) => {
            const dt = res?.data;
            acc.scientificProfile.scientificProfileBooks = (
              acc.scientificProfile.scientificProfileBooks ?? []
            ).concat(dt?.scientificProfile?.scientificProfileBooks ?? []);
            acc.scientificProfile.scientificProfilePapers = (
              acc.scientificProfile.scientificProfilePapers ?? []
            ).concat(dt?.scientificProfile?.scientificProfilePapers ?? []);
            return acc;
          }, response[0]?.data ?? {});

          setScientificWorksDataByDocx(data);

          toast(TOAST.SUCCESS, `${items[5].label} ${t('scientificProfile.extractedMessage')}`);
        }
      },
    }
  );

  const {
    data: othersInfoDataByDocx,
    isError: isOthersInfoErrorByDocx,
    error: othersInfoErrorByDocx,
    isFetching: isOthersInfoFetchingByDocx,
  } = useQuery('scientificProfileOthersInfoByDocx', () => getOthersInfoByDocx(), {
    enabled: shouldUpdateFromDocx && !loadedTabs[6],
    onSuccess: (response) => {
      if (response?.data?.status === 400) {
        setErrorFormatDialogVisible(true);
      } else {
        toast(TOAST.SUCCESS, `${items[6].label} ${t('scientificProfile.extractedMessage')}`);
      }
    },
    onError: () => {
      toast(TOAST.WARN, `${items[6].label} ${t('scientificProfile.extractedError')}`);
      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[6] = true;
      setLoadedTabs(newSelectedTabs);
    },
  });

  const {
    mutate,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
  } = useMutation(update);

  const { control, handleSubmit, trigger, watch, reset, setValue, getValues } = useForm({
    mode: 'onChange',
    // defaultValues: formDefaultValue,
    ...formOptions,
    shouldFocusError: true,
  });

  const { errors, isDirty, dirtyFields, isValid, isSubmitting } = useFormState({
    control,
  });

  // #endregion Data

  // #region Event
  // useEffect location change
  useEffect(() => {
    if (location.state?.isUpdateScientificProfileByDocx) {
      setShouldUpdateFromDocx(true);
    } else {
      // not found page
      navigate('/404');
    }
  }, []);

  // set data from docx into from fields
  useEffect(() => {
    if (generalInfoDataByDocx?.data && !loadedTabs[0] && !isGeneralInfoFetchingByDocx) {
      const bindingGeneralData = modifyBindingScientificProfileGeneralData(
        generalInfoDataByDocx.data
      );

      setValue('general', bindingGeneralData, { shouldDirty: true, shouldValidate: true });
      setValue('general.foreignLanguages', bindingGeneralData?.foreignLanguages ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('general.researchLines', bindingGeneralData?.researchLines ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });

      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[0] = true;
      setLoadedTabs(newSelectedTabs);
    }

    if (academicHistoryDataByDocx?.data && !loadedTabs[1] && !isAcademicHistoryFetchingByDocx) {
      const bindingAcademicHistoryData = modifyBindingScientificProfileAcademicHistoryData(
        academicHistoryDataByDocx.data
      );
      setValue('academicHistory.universities', bindingAcademicHistoryData?.universities ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('academicHistory.masters', bindingAcademicHistoryData?.masters ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('academicHistory.doctorates', bindingAcademicHistoryData?.doctorates ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(
        'academicHistory.scienceDoctorates',
        bindingAcademicHistoryData?.scienceDoctorates ?? []
      );

      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[1] = true;
      setLoadedTabs(newSelectedTabs);
    }

    if (workHistoryDataByDocx?.data && !loadedTabs[2] && !isWorkHistoryFetchingByDocx) {
      const bindingWorkHistoryData = modifyBindingScientificProfileWorkHistoryData(
        workHistoryDataByDocx.data
      );

      setValue('workHistory', bindingWorkHistoryData ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });

      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[2] = true;
      setLoadedTabs(newSelectedTabs);
    }

    if (projectsDataByDocx?.data && !loadedTabs[3] && !isProjectsFetchingByDocx) {
      const bindingResearchActivitiesData = modifyBindingScientificProfileResearchActivitiesData(
        projectsDataByDocx.data
      );

      setValue('researchActivities', bindingResearchActivitiesData ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });

      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[3] = true;
      setLoadedTabs(newSelectedTabs);
    }

    if (thesisGuidancesDataByDocx?.data && !loadedTabs[4] && !isThesisGuidancesFetchingByDocx) {
      const bindingGuidingActivitiesData = modifyBindingScientificProfileGuidingActivitiesData(
        thesisGuidancesDataByDocx.data
      );

      setValue('guidingActivities', bindingGuidingActivitiesData ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });

      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[4] = true;
      setLoadedTabs(newSelectedTabs);
    }

    if (scientificWorksDataByDocx && !loadedTabs[5] && !isScientificWorksFetchingByDocx) {
      const bindingScientificWorksData =
        modifyBindingScientificProfileScientificWorksData(scientificWorksDataByDocx);

      setValue(
        'scientificWorks.internationalBooks',
        bindingScientificWorksData?.internationalBooks ?? [],
        { shouldDirty: true, shouldValidate: true }
      );
      setValue('scientificWorks.domesticBooks', bindingScientificWorksData?.domesticBooks ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(
        'scientificWorks.internationalPapers',
        bindingScientificWorksData?.internationalPapers ?? [],
        { shouldDirty: true, shouldValidate: true }
      );
      setValue('scientificWorks.domesticPapers', bindingScientificWorksData?.domesticPapers ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(
        'scientificWorks.internationalConferencePapers',
        bindingScientificWorksData?.internationalConferencePapers ?? [],
        { shouldDirty: true, shouldValidate: true }
      );
      setValue(
        'scientificWorks.domesticConferencePapers',
        bindingScientificWorksData?.domesticConferencePapers ?? [],
        { shouldDirty: true, shouldValidate: true }
      );

      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[5] = true;
      setLoadedTabs(newSelectedTabs);
    }

    if (othersInfoDataByDocx?.data && !loadedTabs[6] && !isOthersInfoFetchingByDocx) {
      const bindingOthersData = modifyBindingScientificProfileOthersData(othersInfoDataByDocx.data);

      setValue('others.awards', bindingOthersData?.awards ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('others.patents', bindingOthersData?.patents ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('others.usefulSolutions', bindingOthersData?.usefulSolutions ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('others.transferredSolutions', bindingOthersData?.transferredSolutions ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('others.programAttendances', bindingOthersData?.programAttendances ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('others.conferenceAttendances', bindingOthersData?.conferenceAttendances ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('others.universityVisitingStaff', bindingOthersData?.universityVisitingStaff ?? [], {
        shouldDirty: true,
        shouldValidate: true,
      });

      const newSelectedTabs = [...loadedTabs];
      newSelectedTabs[6] = true;
      setLoadedTabs(newSelectedTabs);
    }
  }, [
    isGeneralInfoFetchingByDocx,
    isAcademicHistoryFetchingByDocx,
    isWorkHistoryFetchingByDocx,
    isThesisGuidancesFetchingByDocx,
    isScientificWorksFetchingByDocx,
    isProjectsFetchingByDocx,
    isOthersInfoFetchingByDocx,
  ]);

  const findDuplicatePapersTitles = (articles) => {
    const titleOccurrences = {}; // Object to store title occurrences
    let duplicates = [];
    // Iterate through each article
    articles.forEach((article) => {
      const title = article?.paper?.title; // Get title of the article
      if (titleOccurrences[title]) {
        duplicates = [titleOccurrences[title], article];
      }

      titleOccurrences[title] = article; // Store the article as value for the title
    });
    return duplicates; // If no duplicate titles found, return null
  };

  // book
  const findDuplicateBookTitles = (books) => {
    const titleOccurrences = {}; // Object to store title occurrences
    let duplicates = [];
    // Iterate through each book
    books.forEach((book) => {
      const title = book?.book?.title; // Get title of the book
      if (titleOccurrences[title]) {
        duplicates = [titleOccurrences[title], book];
      }

      titleOccurrences[title] = book; // Store the book as value for the title
    });
    return duplicates; // If no duplicate titles found, return null
  };

  const mapPublicationType = (type) => {
    if (type === 'INTERNATIONAL') {
      return t('scientificProfile.scientificWork.paper.international.label');
    }
    if (type === 'DOMESTIC') {
      return t('scientificProfile.scientificWork.paper.domestic.label');
    }
    if (type === 'INTERNATIONAL_CONFERENCE') {
      return t('scientificProfile.scientificWork.paper.internationalConference.label');
    }
    if (type === 'DOMESTIC_CONFERENCE') {
      return t('scientificProfile.scientificWork.paper.domesticConference.label');
    }
    if (type === 'DOMESTIC_BOOK') {
      return t('scientificProfile.scientificWork.book.domestic.label');
    }
    if (type === 'INTERNATIONAL_BOOK') {
      return t('scientificProfile.scientificWork.book.international.label');
    }
    return '';
  };

  const onSubmit = (data) => {
    if (!(isDirty && Object.keys(dirtyFields).length)) {
      if (isNextTab) {
        setCurrentTab((cur) => cur + 1);
        setIsNextTab(false);
      }
      return;
    }

    const updateGeneral = loadedTabs[0] ? modifyScientificProfileGeneralUpdateData(data) : null;
    const updateAcademicHistory = loadedTabs[1]
      ? modifyScientificProfileAcademicHistoryUpdateData(data)
      : null;
    const updateWorkHistory = loadedTabs[2]
      ? modifyScientificProfileWorkHistoryUpdateData(data)
      : null;
    const updateResearchActivities = loadedTabs[3]
      ? modifyScientificProfileResearchActivitiesUpdateData(data)
      : null;
    const updateGuidingActivities = loadedTabs[4]
      ? modifyScientificProfileGuidingActivitiesUpdateData(data)
      : null;
    const updateScientificWorks = loadedTabs[5]
      ? modifyScientificProfileScientificWorksUpdateData(data)
      : null;
    const updateOthers = loadedTabs[6] ? modifyScientificProfileOthersUpdateData(data) : null;

    // check duplicate papers
    if (updateScientificWorks?.scientificProfilePapers) {
      const duplicates = findDuplicatePapersTitles(updateScientificWorks?.scientificProfilePapers);
      if (duplicates.length > 0) {
        setErrorDuplicateDialogVisible([true, duplicates, 'hasDuplicateScientificProfilePapers']);
        return;
      }
    }

    // check duplicate books
    if (updateScientificWorks?.scientificProfileBooks) {
      const duplicatesBooks = findDuplicateBookTitles(
        updateScientificWorks?.scientificProfileBooks
      );
      if (duplicatesBooks.length > 0) {
        setErrorDuplicateDialogVisible([
          true,
          duplicatesBooks,
          'hasDuplicateScientificProfileBooks',
        ]);
        return;
      }
    }

    let updateData = {};

    updateData = {
      ...updateGeneral,
      ...(updateAcademicHistory !== null && { academicHistory: updateAcademicHistory }),
      ...(updateWorkHistory !== null && { workHistory: updateWorkHistory }),
      ...(updateResearchActivities !== null && { projects: updateResearchActivities }),
      ...(updateGuidingActivities !== null && { thesisGuidances: updateGuidingActivities }),
      ...updateScientificWorks,
      ...updateOthers,
    };

    mutate(updateData, {
      onSuccess: () => {
        reset({ ...data });

        if (isNextTab) {
          setCurrentTab((cur) => cur + 1);
          setIsNextTab(false);
        } else {
          toast(TOAST.SUCCESS, t('scientificProfile.updateSuccess'));
        }
      },
      onError: () => {
        setErrorDialogVisible(true);
      },
    });
  };

  const handleLoadingForm = () => {
    if (isGeneralInfoFetchingByDocx && currentTab === 0) {
      return false;
    }
    if (isAcademicHistoryFetchingByDocx && currentTab === 1) {
      return false;
    }
    if (isWorkHistoryFetchingByDocx && currentTab === 2) {
      return false;
    }
    if (isProjectsFetchingByDocx && currentTab === 3) {
      return false;
    }
    if (isThesisGuidancesFetchingByDocx && currentTab === 4) {
      return false;
    }
    if (isScientificWorksFetchingByDocx && currentTab === 5) {
      return false;
    }
    if (isOthersInfoFetchingByDocx && currentTab === 6) {
      return false;
    }
    return true;
  };

  const handleLoadedData = () => {
    // check all tab is loaded
    if (loadedTabs.includes(false)) {
      return false;
    }
    return true;
  };

  const nextTab = async () => {
    if (currentTab === 0) {
      const isGeneralInfoValid = await trigger('general', { shouldFocus: true });
      if (isGeneralInfoValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 1) {
      const isAcademicHistoryValid = await trigger('academicHistory', { shouldFocus: true });
      if (isAcademicHistoryValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 2) {
      const isWorkHistoryValid = await trigger('workHistory', { shouldFocus: true });
      if (isWorkHistoryValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 3) {
      const isResearchActivitiesValid = await trigger('researchActivities', { shouldFocus: true });
      if (isResearchActivitiesValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 4) {
      const isGuidingActivitiesValid = await trigger('guidingActivities', { shouldFocus: true });
      if (isGuidingActivitiesValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 5) {
      const isScientificWorksValid = await trigger('scientificWorks', { shouldFocus: true });
      if (isScientificWorksValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 6) {
      const isOthersValid = await trigger('others', { shouldFocus: true });
      if (isOthersValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else {
      setCurrentTab((cur) => cur + 1);
    }
  };

  const handleTabChange = async (e) => {
    // Can not use switch because of eslint error (conflict Prettier)
    if (currentTab === 0) {
      const isGeneralInfoValid = await trigger('general', { shouldFocus: true });
      if (isGeneralInfoValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 1) {
      const isAcademicHistoryValid = await trigger('academicHistory', { shouldFocus: true });
      if (isAcademicHistoryValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 2) {
      const isWorkHistoryValid = await trigger('workHistory', { shouldFocus: true });
      if (isWorkHistoryValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 3) {
      const isResearchActivitiesValid = await trigger('researchActivities', { shouldFocus: true });
      if (isResearchActivitiesValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 4) {
      const isGuidingActivitiesValid = await trigger('guidingActivities', { shouldFocus: true });
      if (isGuidingActivitiesValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 5) {
      const isScientificWorksValid = await trigger('scientificWorks', { shouldFocus: true });
      if (isScientificWorksValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 6) {
      const isOthersValid = await trigger('others', { shouldFocus: true });
      if (isOthersValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else {
      setCurrentTab(e.index);
    }
  };

  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };
  // #endregion Event

  const footerError = (
    <Button
      label={t('projectProposal.ok')}
      className="w-7rem"
      severity="danger"
      onClick={() => navigate(-1)}
      autoFocus
    />
  );

  const footerErrorFormatFile = (
    <div>
      <Button
        label={t('scientificProfile.manualInput')}
        severity="info"
        icon="pi pi-pencil"
        onClick={() => {
          setErrorFormatDialogVisible(false);
          navigate('/scientific-profile/edit');
        }}
        autoFocus
      />

      <Button
        label={t('scientificProfile.uploadFileAgain')}
        severity="success"
        icon="pi pi-refresh"
        onClick={() => {
          navigate('/scientific-profile', { state: { isUploadFile: true } });
        }}
        autoFocus
      />

      <Button
        label={t('projectProposal.cancel')}
        severity="danger"
        onClick={() => {
          navigate(-1);
        }}
        autoFocus
      />
    </div>
  );

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );

  if (isGeneralInfoErrorByDocx) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, generalInfoErrorByDocx)}</p>
      </Dialog>
    );
  }

  if (isAcademicHistoryErrorByDocx) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, academicHistoryErrorByDocx)}</p>
      </Dialog>
    );
  }

  if (isWorkHistoryErrorByDocx) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, workHistoryErrorByDocx)}</p>
      </Dialog>
    );
  }

  if (isProjectsErrorByDocx) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, projectsErrorByDocx)}</p>
      </Dialog>
    );
  }

  if (isThesisGuidancesErrorByDocx) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, thesisGuidancesErrorByDocx)}</p>
      </Dialog>
    );
  }

  if (isScientificWorksErrorByDocx) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, scientificWorksErrorByDocx)}</p>
      </Dialog>
    );
  }

  if (isOthersInfoErrorByDocx) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, othersInfoErrorByDocx)}</p>
      </Dialog>
    );
  }

  return (
    <>
      {/* #region Loading */}
      <div className="sticky top-0 left-0 z-5">
        <div className="relative">{isSubmitting || (isUpdateLoading && <Loading />)}</div>
      </div>
      {/* #endregion Loading */}

      <BlockUI baseZIndex={3} blocked={isSubmitting || isUpdateLoading}>
        <Steps model={items} activeIndex={currentTab} onSelect={handleTabChange} readOnly={false} />

        <FormProvider {...{ control, errors, watch, setValue, getValues, dirtyFields }}>
          <Form
            control={control}
            errors={errors}
            disabledSubmit={!(isDirty && Object.keys(dirtyFields).length)}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            handleSubmit={handleSubmit(onSubmit)}
            tab={currentTab}
            nextTab={nextTab}
            checkValidation={checkValidation}
            checkKeyDown={checkKeyDown}
            isDirty={isDirty}
            footerConfirmationPrompt={footerConfirmationPrompt}
            shouldUpdateFromDocx={shouldUpdateFromDocx}
            handleLoading={handleLoadingForm}
            handleLoadedData={handleLoadedData}
          />
        </FormProvider>
      </BlockUI>

      <ReactRouterPrompt when={Object.keys(dirtyFields)?.length && !errorFormatDialogVisible}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('scientificProfile.confirmationSavePromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>

      {/* #region Update */}
      {isUpdateError && (
        <Dialog
          header={<p className="text-red-500">{t('errorMessage.updateScientificProfile')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(updateError?.response?.data?.message) ? (
            updateError.response?.data?.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, updateError)}</p>
          )}
        </Dialog>
      )}
      <Dialog
        header={<p className="text-red-500">{t('scientificProfile.fileNotCorrectTitle')}</p>}
        visible={errorFormatDialogVisible}
        position="center"
        draggable={false}
        footer={footerErrorFormatFile}
      >
        <div className="flex-col">
          <span className="text-base font-normat">
            {t('scientificProfile.fileNotCorrectFormat')}
          </span>
          <div className="mt-2">
            <span className="text-base font-semibold">
              {t('formLayout.templateFile')}:{'    '}
            </span>
            <a
              className="text-blue-500 font-italic text-base font-medium"
              href="/files/R03-LY-LICH-KHOA-HOC.docx"
              download
            >
              {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
            </a>
          </div>
        </div>
      </Dialog>

      <Dialog
        header={<p className="text-red-500">{t('errorMessage.updateScientificProfile')}</p>}
        visible={errorDuplicateDialogVisible[0]}
        position="center"
        style={{ width: '600px' }}
        onHide={() => setErrorDuplicateDialogVisible([false, [], null])}
        draggable={false}
      >
        <p className="text-lg text-red-500">
          {t(`errorMessage.${errorDuplicateDialogVisible[2]}`)}
        </p>
        <div className="flex py-3 content-start">
          <p className="text-lg text-red-500 font-semibold">Title: </p>
          <p className="text-base text-justify">
            {errorDuplicateDialogVisible[2] === 'hasDuplicateScientificProfilePapers'
              ? errorDuplicateDialogVisible[1]?.[0]?.paper?.title
              : errorDuplicateDialogVisible[1]?.[0]?.book?.title}
          </p>
        </div>

        <div className="py-3 content-start">
          <p className="text-lg text-red-500 font-semibold">Duplicate Entries: </p>

          <div>
            <ul className="list-disc">
              {errorDuplicateDialogVisible[1]?.map((item) => (
                <li className="text-base text-justify">
                  {errorDuplicateDialogVisible[2] === 'hasDuplicateScientificProfilePapers'
                    ? mapPublicationType(item?.paper?.publicationType)
                    : mapPublicationType(item?.book?.publicationType)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Dialog>
      {/* #endregion Update */}
    </>
  );
}
