import { TextInput, TextareaInput } from 'components/FormControl';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function ProductDescription({ disabled, control, errors }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion Data
  return (
    <div>
      <div className="grid">
        <div className="col-4">
          <TextInput
            label={t('r09.form.productName')}
            name="productName"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>

        <div className="col-4 ">
          <TextInput
            label={t('r09.form.productCapacity')}
            name="productCapacity"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
        <div className="col-4 ">
          <TextInput
            label={t('r09.form.productImplementation')}
            name="productImplementation"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
        <div className="col-4 ">
          <TextInput
            label={t('r09.form.productEconomy')}
            name="productEconomy"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
        <div className="col-4 ">
          <TextInput
            label={t('r09.form.productTransmission')}
            name="productTransmission"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
        <div className="col-4 ">
          <TextInput
            label={t('r09.form.productDocumentType')}
            name="productDocumentType"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
        <div className="col-4 ">
          <TextInput
            label={t('r09.form.productOther')}
            name="productOther"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
        <div className="col-4 ">
          <TextInput
            label={t('r09.form.productDescription')}
            name="productDescription"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
        <div className="col-4">
          <TextareaInput
            label={t('r09.form.productAddress')}
            name="productAddress"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="text-sm font-bold">
        {t('r09.form.note')}
        <div>{t('r09.form.note1')}</div>
        <div>{t('r09.form.note2')}</div>
      </div>
    </div>
  );
}

ProductDescription.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
};

ProductDescription.defaultProps = {
  disabled: false,
};
