import { forwardRef, useImperativeHandle, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker, NumberInput, Select, TextareaInput } from 'components/FormControl';

import { yupResolver } from '@hookform/resolvers/yup';
import { TOAST } from 'constant';
import { researchActivitySchema } from 'features/ScientificProfile/validation';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const formOptions = { resolver: yupResolver(researchActivitySchema) };

const ResearchActivityDialog = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [researchActivityControl, setResearchActivityControl] = useState();
  const [visible, setVisible] = useState(false);

  const managementLevels = [
    { value: 'state', name: t('managementLevel.state') },
    { value: 'ministry', name: t('managementLevel.ministry') },
    { value: 'vnuhcm', name: t('managementLevel.vnuhcm') },
    { value: 'province', name: t('managementLevel.province') },
    { value: 'university', name: t('managementLevel.university') },
  ];

  const roles = [
    { value: 'owner', name: t('projectRole.owner') },
    { value: 'secretary', name: t('projectRole.secretary') },
    { value: 'mainParticipant', name: t('projectRole.mainParticipant') },
    { value: 'participant', name: t('projectRole.participant') },
    { value: 'technician', name: t('projectRole.technician') },
    { value: 'domesticExpert', name: t('projectRole.domesticExpert') },
    { value: 'internationalExpert', name: t('projectRole.internationalExpert') },
  ];

  const results = [
    { value: 'excellent', name: t('result.excellent') },
    { value: 'veryGood', name: t('result.veryGood') },
    { value: 'good', name: t('result.good') },
    { value: 'pass', name: t('result.pass') },
    { value: 'fail', name: t('result.fail') },
  ];

  const {
    control,
    watch,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({ ...formOptions, mode: 'onChange' });
  // #region Event

  useImperativeHandle(
    ref,
    () => ({
      open: (_researchActivityControl) => {
        setResearchActivityControl(_researchActivityControl);
        setVisible(true);
        if (_researchActivityControl.type === 'INSERT') {
          reset({});
        } else if (_researchActivityControl.type === 'UPDATE') {
          reset(_researchActivityControl.value);
        }
      },
    }),
    []
  );

  const handleAddResearchActivity = async () => {
    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = watch();

    const { insert, update, index, type } = researchActivityControl;

    if (type === 'INSERT' && insert) {
      insert(index, data);
    } else if (type === 'UPDATE' && update) {
      update(index, data);
    }
    reset({});
    setVisible(false);
  };
  // #endregion Event

  return (
    <Dialog
      header={
        researchActivityControl?.type === 'INSERT'
          ? t('scientificProfile.researchActivity.add')
          : t('scientificProfile.researchActivity.update')
      }
      visible={visible}
      onHide={() => {
        setVisible(false);
      }}
      draggable={false}
      className="w-full sm:w-7 lg:w-6"
    >
      <div className="grid p-fluid">
        <div className="col-12">
          <TextareaInput
            label={t('scientificProfile.researchActivity.projectName')}
            name="name"
            control={control}
            errors={errors}
            isRequired
          />
        </div>

        <div className="col-6">
          <TextareaInput
            label={t('scientificProfile.researchActivity.projectCode')}
            name="code"
            control={control}
            errors={errors}
            isRequired
          />
        </div>
        <div className="col-6">
          <Select
            label={t('scientificProfile.researchActivity.managementLevel')}
            name="managementLevel"
            options={managementLevels}
            control={control}
            errors={errors}
            inputField="value"
            isRequired
          />
        </div>

        {/* <div className="col-6">
          <NumberInput
            label={t('scientificProfile.researchActivity.implementationMonths')}
            name="implementationMonths"
            control={control}
            errors={errors}
            min={0}
            isRequired
          />
        </div> */}
        <div className="col-6">
          <DatePicker
            label={t('scientificProfile.researchActivity.start')}
            name="start"
            control={control}
            errors={errors}
            isRequired
          />
        </div>
        <div className="col-6">
          <DatePicker
            label={t('scientificProfile.researchActivity.end')}
            name="end"
            control={control}
            errors={errors}
          />
        </div>

        <div className="col-6">
          <NumberInput
            label={t('scientificProfile.researchActivity.expenditure')}
            name="expenditure"
            control={control}
            errors={errors}
            min={0}
            isRequired
          />
        </div>

        <div className="col-6">
          <Select
            label={t('scientificProfile.researchActivity.role')}
            name="role"
            options={roles}
            control={control}
            errors={errors}
            inputField="value"
            isRequired
          />
        </div>

        <div className="col-6">
          <DatePicker
            label={t('scientificProfile.researchActivity.acceptanceDate')}
            name="acceptanceDate"
            control={control}
            errors={errors}
            isRequired
          />
        </div>

        <div className="col-6">
          <Select
            label={t('scientificProfile.researchActivity.result')}
            name="result"
            options={results}
            control={control}
            errors={errors}
            inputField="value"
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-content-end mt-4">
        <Button
          label={t('formLayout.action.cancel')}
          severity="danger"
          onClick={() => setVisible(false)}
          className="mr-1 w-8rem"
        />
        <Button
          label={
            researchActivityControl?.type === 'INSERT'
              ? t('formLayout.action.add')
              : t('formLayout.action.update')
          }
          severity="info"
          type="submit"
          className="w-8rem"
          onClick={handleAddResearchActivity}
          disabled={!Object.keys(dirtyFields).length}
        />
      </div>
    </Dialog>
  );
});

ResearchActivityDialog.propTypes = {};

ResearchActivityDialog.defaultProps = {};

export default ResearchActivityDialog;
