import { useFieldArray, useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import TransferredSolutionDialog from './TransferredSolutionDialog';

export default function TransferredSolutionsTable({ control, shouldUpdateFromDocx }) {
  // #region Data
  const { t } = useTranslation();

  const transferredSolutionRef = useRef();
  const { dirtyFields } = useFormContext();

  const { fields, insert, update, remove } = useFieldArray({
    control,
    name: 'others.transferredSolutions',
  });
  // #endregion
  // #region Event

  // #endregion
  return (
    <>
      <div className="form-table mt-2">
        <table className="mb-2">
          <thead>
            <tr>
              <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                {t('formLayout.numericalOrder')}
              </th>
              <th className="text-center" style={{ minWidth: 250, width: '25%' }}>
                {t('scientificProfile.otherInfo.transferredSolution.name')}
              </th>
              <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                {t('scientificProfile.otherInfo.transferredSolution.form')}
              </th>
              <th className="text-center" style={{ minWidth: 150, width: '15%' }}>
                {t('scientificProfile.otherInfo.transferredSolution.scale')}
              </th>
              <th className="text-center" style={{ minWidth: 200, width: '20%' }}>
                {t('scientificProfile.otherInfo.transferredSolution.address')}
              </th>
              <th className="text-center" style={{ minWidth: 70, width: '7%' }}>
                {t('scientificProfile.otherInfo.transferredSolution.yearOfTransfer')}
              </th>
              <th className="text-center" style={{ minWidth: 110, width: '11%' }}>
                {t('scientificProfile.otherInfo.transferredSolution.projectCode')}
              </th>
              <th className="text-center float" style={{ minWidth: 80, width: 80 }}>
                {t('formLayout.action.label')}
              </th>
            </tr>
          </thead>
          <tbody>
            {fields?.map((item, index) => (
              <tr
                key={item.id ?? index}
                className={classNames({
                  highlighted: shouldUpdateFromDocx && dirtyFields.others?.transferredSolutions,
                })}
              >
                <td className="text-center">{index + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.form}</td>
                <td>{item?.scale}</td>
                <td>{item?.address}</td>
                <td>{item?.yearOfTransfer}</td>
                <td>{item?.projectCode}</td>

                <td
                  className={`flex flex-column float text-center p-0 border-none ${
                    index !== 0 ? 'border-top-1' : ''
                  }`}
                >
                  <i
                    onClick={() => {
                      transferredSolutionRef.current.open({
                        insert,
                        index: index + 1,
                        type: 'INSERT',
                      });
                    }}
                    className="insert-icon pi pi-plus text-primary cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.insert')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".insert-icon" />

                  <i
                    onClick={() => {
                      transferredSolutionRef.current.open({
                        update,
                        index,
                        value: fields[index],
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil text-primary cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => remove(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {fields?.length === 0 && (
          <div className="flex justify-content-center my-2">
            <i
              onClick={() => {
                transferredSolutionRef.current.open({
                  insert,
                  index: 0,
                  type: 'INSERT',
                });
              }}
              className="add-transferred-solution pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
              data-pr-tooltip={t('formLayout.action.add')}
              data-pr-position="left"
            />
            <Tooltip target=".add-transferred-solution" />
          </div>
        )}
      </div>
      <TransferredSolutionDialog ref={transferredSolutionRef} />
    </>
  );
}

TransferredSolutionsTable.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  shouldUpdateFromDocx: PropTypes.bool,
};

TransferredSolutionsTable.defaultProps = {
  shouldUpdateFromDocx: false,
};
