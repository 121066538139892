import * as Yup from 'yup';

const updateUserProfileValidationSchema = Yup.object().shape({
  fullname: Yup.string().required('signup.validationErrors.fullnameRequired'),
  organizationId: Yup.string().required('signup.validationErrors.organizationRequired'),
  organizationEnglishName: Yup.string().nullable(),
  specificOrganizationName: Yup.string()
    .nullable()
    .when('organizationEnglishName', {
      is: 'Other',
      then: Yup.string().required('formControl.validation.required'),
    }),
  gender: Yup.string(),
  phone: Yup.string(),
  address: Yup.string(),
  scientificProfile: Yup.object().shape({
    personalEmail: Yup.string().email('formControl.validation.emailInvalid').nullable(),
    officeEmail: Yup.string().email('formControl.validation.emailInvalid').nullable(),
    citizenId: Yup.string().required('formControl.validation.required'),
    dateOfIssue: Yup.string().required('formControl.validation.required').nullable(),
    placeOfIssue: Yup.string().required('formControl.validation.required'),
    taxIdentificationNumber: Yup.string().required('formControl.validation.required'),
    bankAccount: Yup.string().required('formControl.validation.required'),
    bank: Yup.string().required('formControl.validation.required'),
    academicDegree: Yup.string().required('formControl.validation.required').nullable(),
    ORCIDcode: Yup.string().nullable(),
    bankBranch: Yup.string().nullable(),
  }),
});

export default updateUserProfileValidationSchema;
