import { FileInput } from 'components/FormControl';
import Loading from 'components/Loading';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import {
  checkFileFormat,
  checkNameEmailScientificProfile,
  uploadFile,
} from 'apis/scientificProfiles.api';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { formatFileName } from 'utils/func';

export default function ScientificProfileUploadForm({
  fileUrl,
  visible,
  setVisible,
  refetch,
  handleDownloadFile,
  showSuccess,
  showError,
}) {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm();

  const { mutate, isLoading } = useMutation(uploadFile);
  const [isCheckFileFormat, setIsCheckFileFormat] = useState(false);
  const [isCheckEmailName, setIsCheckEmailName] = useState(false);
  const [isOpenCheckEmailNameDialog, setIsOpenCheckEmailNameDialog] = useState(false);
  const [errorFormatDialogVisible, setErrorFormatDialogVisible] = useState(false);
  const [checkRedirect, setCheckRedirect] = useState([false, false]);

  // #endregion Data

  // #region Event

  const footerCheckEmailNameDialog = (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        severity="danger"
        onClick={() => {
          setIsOpenCheckEmailNameDialog(false);
        }}
        autoFocus
      />

      <Button
        label="Tiếp tục"
        severity="info"
        onClick={() => {
          setCheckRedirect((item) => {
            const updatedItem = [...item];
            updatedItem[1] = true;
            return updatedItem;
          });
          setIsOpenCheckEmailNameDialog(false);
        }}
        autoFocus
      />
    </div>
  );

  const footerErrorFormatFile = (
    <div>
      <Button
        label={t('scientificProfile.manualInput')}
        severity="info"
        icon="pi pi-pencil"
        onClick={() => {
          setErrorFormatDialogVisible(false);
          setIsCheckFileFormat(false);
          navigate('/scientific-profile/edit');
        }}
        autoFocus
      />

      <Button
        label={t('scientificProfile.uploadFileAgain')}
        severity="success"
        icon="pi pi-refresh"
        onClick={() => {
          setErrorFormatDialogVisible(false);
          setIsCheckFileFormat(true);
          setIsCheckEmailName(true);
          setVisible(true);
        }}
        autoFocus
      />

      <Button
        label={t('projectProposal.cancel')}
        severity="danger"
        onClick={() => {
          setErrorFormatDialogVisible(false);
          setIsCheckFileFormat(false);
        }}
        autoFocus
      />
    </div>
  );

  const { data: fileFormatData, refetch: refetchFileFormat } = useQuery(
    'checkFileFormat',
    () => checkFileFormat(),
    {
      enabled: isCheckFileFormat,
      onSuccess: (successData) => {
        if (successData?.data?.isCorrect) {
          setCheckRedirect((newState) => {
            const updatedState = [...newState];
            updatedState[0] = true;
            return updatedState;
          });
        } else {
          setErrorFormatDialogVisible(true);
        }
      },
    }
  );

  const { data: checkEmailNameData, refetch: refetchCheckEmailName } = useQuery(
    'checkEmailNameScientificProfile',
    () => checkNameEmailScientificProfile(),
    {
      enabled: isCheckEmailName,
      onSuccess: (successData) => {
        if (successData?.data?.isCorrect) {
          setCheckRedirect((item) => {
            const updatedItem = [...item];
            updatedItem[1] = true;
            return updatedItem;
          });
        } else {
          setIsOpenCheckEmailNameDialog(true);
        }
      },
    }
  );

  const onSubmit = async (data) => {
    if (data.scientific_profile) {
      const formData = new FormData();
      formData.append('scientific_profile', data.scientific_profile);

      mutate(formData, {
        onSuccess: () => {
          showSuccess(t('scientificProfile.updateSuccess'));
          refetch();
          reset();
          setVisible(false);
          setIsCheckFileFormat(true);
          setIsCheckEmailName(true);
          refetchFileFormat();
          refetchCheckEmailName();
        },
        onError: (error) => {
          if (error?.response?.data?.code === 'scientificProfileUploadFailed') {
            showError(t('scientificProfile.uploadFileError'));
          } else {
            showError(error.response.data.message);
          }
        },
      });
    }
  };

  // #endregion Event

  useEffect(() => {
    if (checkRedirect[0] && checkRedirect[1]) {
      navigate('/scientific-profile/editByFile', {
        state: { isUpdateScientificProfileByDocx: true },
      });
    }
  }, [checkRedirect]);

  return (
    <>
      <Dialog
        header={t('scientificProfile.uploadFile')}
        visible={visible}
        style={{ maxWidth: '700px' }}
        onHide={() => {
          reset();
          setVisible(false);
        }}
      >
        <div className="relative">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <span className="text-xl font-semibold">
              {t('formLayout.templateFile')}:{'    '}
            </span>
            <a
              className="text-blue-500 font-italic text-lg font-medium"
              href="/files/R03-LY-LICH-KHOA-HOC.docx"
              download
            >
              {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
            </a>
            <FileInput
              name="scientific_profile"
              accept=".pdf,.docx"
              control={control}
              errors={errors}
            />
            <span className="text-red-500 font-italic font-medium">
              ({t('formControl.docxPdfOnly')})
            </span>

            {fileUrl && (
              <span className="p-button p-button-text p-button-sm" onClick={handleDownloadFile}>
                <i className="pi pi-file-word text-blue-500 text-xl" />{' '}
                <span className="ml-1 text-blue-500 underline font-italic text-base">
                  {formatFileName(fileUrl) ?? `${t('scientificProfile.label')}.pdf`}
                </span>
              </span>
            )}
            <div className="flex flex-column sm:flex-row align-items-center sm:column-gap-3 justify-items-center">
              <Button
                label={t('formLayout.action.cancel')}
                type="button"
                className="mt-4 w-full sm:w-21rem"
                severity="danger"
                onClick={() => {
                  reset();
                  setVisible(false);
                }}
              />
              <Button
                label={t('scientificProfile.saveUploadFile')}
                className="mt-2 sm:mt-4 w-full sm:w-21rem"
                severity="info"
                type="submit"
                disabled={!dirtyFields?.scientific_profile}
              />
            </div>
          </form>
          {isLoading && <Loading />}
        </div>
      </Dialog>

      <Dialog
        header={<p className="text-red-500">{t('scientificProfile.fileNotCorrectTitle')}</p>}
        visible={errorFormatDialogVisible}
        position="center"
        draggable={false}
        footer={footerErrorFormatFile}
        onHide={() => {
          setErrorFormatDialogVisible(false);
          setIsCheckFileFormat(false);
        }}
      >
        <div className="flex-col">
          <span className="text-base font-normat">
            {t('scientificProfile.fileNotCorrectFormat')}
          </span>
          <div className="mt-2">
            <span className="text-base font-semibold">
              {t('formLayout.templateFile')}:{'    '}
            </span>
            <a
              className="text-blue-500 font-italic text-base font-medium"
              href="/files/R03-LY-LICH-KHOA-HOC.docx"
              download
            >
              {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
            </a>

            {!fileFormatData?.data?.isCorrect && (
              <div className="mt-4">
                <span className="text-base font-semibold">
                  {t('scientificProfile.extractedError')}:
                </span>

                {/* Title */}
                <ul className="list-disc list-inside">
                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.generalInfo && (
                    <li className="text-base font-normal">
                      Không tìm thấy mục:{' '}
                      <span className="text-red-700 font-semibold">I. THÔNG TIN CHUNG</span>
                    </li>
                  )}
                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.researchAndTeaching && (
                    <li className="text-base font-normal">
                      Không tìm thấy mục:{' '}
                      <span className="text-red-700 font-semibold">
                        II. NGHIÊN CỨU VÀ GIẢNG DẠY
                      </span>
                    </li>
                  )}
                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.scientificWorks && (
                    <li className="text-base font-normal">
                      Không tìm thấy mục:{' '}
                      <span className="text-red-700 font-semibold">
                        III. CÁC CÔNG TRÌNH ĐÃ CÔNG BỐ
                      </span>
                    </li>
                  )}
                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.awards && (
                    <li className="text-base font-normal">
                      Không tìm thấy mục:{' '}
                      <span className="text-red-700 font-semibold">IV. CÁC GIẢI THƯỞNG</span>
                    </li>
                  )}
                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.other && (
                    <li className="text-base font-normal">
                      Không tìm thấy mục:{' '}
                      <span className="text-red-700 font-semibold">V. THÔNG TIN KHÁC</span>
                    </li>
                  )}
                </ul>

                {/* Content */}
                {/* General Info */}
                {!Object.values(
                  fileFormatData?.data?.checkFormatScientificProfileDto?.IGeneralInfoContent || {}
                ).every(Boolean) && (
                  <p className="ml-4 text-base font-semibold">
                    I. {t('scientificProfile.generalInfo.label')}
                  </p>
                )}
                <ul className="list-disc list-inside">
                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.IGeneralInfoContent
                    .workHistory && (
                    <div>
                      <li className="text-base font-normal">
                        Không tìm thấy mục:{' '}
                        <span className="text-red-700 font-semibold">9. Thời gian công tác</span>
                      </li>
                    </div>
                  )}
                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.IGeneralInfoContent
                    .academicHistory && (
                    <div>
                      <li className="text-base font-normal">
                        Không tìm thấy mục:{' '}
                        <span className="text-red-700 font-semibold">10. Quá trình đào tạo</span>
                      </li>
                    </div>
                  )}

                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.IGeneralInfoContent
                    .fieldsOfSpecialization && (
                    <div>
                      <li className="text-base font-normal">
                        Không tìm thấy mục:{' '}
                        <span className="text-red-700 font-semibold">
                          11. Các lĩnh vực chuyên môn và hướng nghiên cứu
                        </span>
                      </li>
                    </div>
                  )}
                </ul>

                {/* IIResearchAndTeachingContent */}
                {!Object.values(
                  fileFormatData?.data?.checkFormatScientificProfileDto
                    ?.IIResearchAndTeachingContent || {}
                ).every(Boolean) && (
                  <p className="ml-4 text-base font-semibold">
                    II. {t('scientificProfile.preview.sectionII')}
                  </p>
                )}
                <ul className="list-disc list-inside">
                  {!fileFormatData?.data?.checkFormatScientificProfileDto
                    ?.IIResearchAndTeachingContent.projects && (
                    <div>
                      <li className="text-base font-normal">
                        Không tìm thấy mục:{' '}
                        <span className="text-red-700 font-semibold">1. Đề tài/dự án</span>
                      </li>
                    </div>
                  )}

                  {!fileFormatData?.data?.checkFormatScientificProfileDto
                    ?.IIResearchAndTeachingContent.guidingActivities && (
                    <div>
                      <li className="text-base font-normal">
                        Không tìm thấy mục:{' '}
                        <span className="text-red-700 font-semibold">
                          2. Hướng dẫn sinh viên, học viên cao học, nghiên cứu sinh
                        </span>
                      </li>
                    </div>
                  )}
                </ul>

                {/* III. Các công trình đã công bố */}
                {!Object.values(
                  fileFormatData?.data?.checkFormatScientificProfileDto
                    ?.IIIScientificWorksContent || {}
                ).every(Boolean) && (
                  <p className="ml-4 text-base font-semibold">
                    III. {t('scientificProfile.preview.sectionIII')}
                  </p>
                )}
                <ul className="list-disc list-inside">
                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.IIIScientificWorksContent
                    .internationalPaper && (
                    <div>
                      <li className="text-base font-normal">
                        Không tìm thấy mục:{' '}
                        <span className="text-red-700 font-semibold">
                          2.1. Đăng trên tạp chí Quốc tế
                        </span>
                      </li>
                    </div>
                  )}

                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.IIIScientificWorksContent
                    .domesticPaper && (
                    <div>
                      <li className="text-base font-normal">
                        Không tìm thấy mục:{' '}
                        <span className="text-red-700 font-semibold">
                          2.2 Đăng trên tạp chí trong nước
                        </span>
                      </li>
                    </div>
                  )}

                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.IIIScientificWorksContent
                    .domesticConference && (
                    <div>
                      <li className="text-base font-normal">
                        Không tìm thấy mục:{' '}
                        <span className="text-red-700 font-semibold">
                          2.3. Đăng trên kỷ yếu Hội nghị Quốc tế
                        </span>
                      </li>
                    </div>
                  )}

                  {!fileFormatData?.data?.checkFormatScientificProfileDto?.IIIScientificWorksContent
                    .internationalConference && (
                    <div>
                      <li className="text-base font-normal">
                        Không tìm thấy mục:{' '}
                        <span className="text-red-700 font-semibold">
                          2.4. Đăng trên kỷ yếu Hội nghị trong nước
                        </span>
                      </li>
                    </div>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={isOpenCheckEmailNameDialog}
        header={<p className="text-yellow-500">{t('toast.warn')}</p>}
        position="center"
        draggable={false}
        footer={footerCheckEmailNameDialog}
      >
        <div>
          <p className="text-lg font-semibold">
            Email và tên không giống với thông tin trong tài khoản
          </p>
          <div>
            <p>Thông tin trong tài khoản:</p>
            <ul>
              <li>
                <p>
                  Email:{' '}
                  <span className="font-bold text-base text-yellow-500">
                    {checkEmailNameData?.data?.user?.email}
                  </span>
                </p>
              </li>
              <li>
                <p>
                  Tên:{' '}
                  <span className="text-base font-bold text-red-600">
                    {checkEmailNameData?.data?.user?.fullName}
                  </span>
                </p>
              </li>
            </ul>

            <p>Thông tin trong file:</p>
            <ul>
              <li>
                <p>
                  Office Email:{' '}
                  <span className="font-bold text-base text-yellow-500">
                    {checkEmailNameData?.data?.email?.officeEmail}
                  </span>
                </p>
              </li>
              <li>
                <p>
                  Personal Email:{' '}
                  <span className="font-bold text-base text-yellow-500">
                    {checkEmailNameData?.data?.email?.personalEmail}
                  </span>
                </p>
              </li>
              <li>
                <p>
                  Tên:{' '}
                  <span className="font-bold text-base text-red-600">
                    {checkEmailNameData?.data?.fullName}
                  </span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <p className="text-base font-bold">Bạn có muốn tiếp tục không?</p>
      </Dialog>
    </>
  );
}

ScientificProfileUploadForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fileUrl: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};

ScientificProfileUploadForm.defaultProps = {
  fileUrl: '',
};
