import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function AwardsSection({ data }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion

  // #region Event
  // #endregion
  return (
    <div className="pb-2">
      <h4 className="mb-2">IV. {t('scientificProfile.preview.sectionIV').toUpperCase()}</h4>

      <div className="text-lg">
        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            1. {t('scientificProfile.otherInfo.award.label')}:
          </span>
          <div className="form-table my-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 260, width: '26%' }}>
                    {t('scientificProfile.otherInfo.award.name')}
                  </th>
                  <th className="text-center" style={{ minWidth: 400, width: '40%' }}>
                    {t('scientificProfile.otherInfo.award.description')}
                  </th>
                  <th className="text-center" style={{ minWidth: 250, width: '25%' }}>
                    {t('scientificProfile.otherInfo.award.placeOfIssue')}
                  </th>
                  <th className="text-center" style={{ minWidth: 70, width: '7%' }}>
                    {t('scientificProfile.otherInfo.award.yearOfIssue')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.awards?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.content}</td>
                    <td>{item?.placeOfIssue}</td>
                    <td>{item?.yearOfIssue}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            2. {t('scientificProfile.otherInfo.patent.label')}:
          </span>
          <div className="form-table my-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 260, width: '26%' }}>
                    {t('scientificProfile.otherInfo.patent.name')}
                  </th>
                  <th className="text-center" style={{ minWidth: 170, width: '17%' }}>
                    {t('scientificProfile.otherInfo.patent.projectCode')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                    {t('scientificProfile.otherInfo.patent.number')}
                  </th>
                  <th className="text-center" style={{ minWidth: 70, width: '7%' }}>
                    {t('scientificProfile.otherInfo.patent.yearOfIssue')}
                  </th>
                  <th className="text-center" style={{ minWidth: 260, width: '26%' }}>
                    {t('scientificProfile.otherInfo.patent.placeOfIssue')}
                  </th>
                  <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                    {t('scientificProfile.otherInfo.patent.authorOrCoauthor')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.patents?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.projectCode}</td>
                    <td>{item?.number}</td>
                    <td>{item?.yearOfIssue}</td>
                    <td>{item?.placeOfIssue}</td>
                    <td>
                      {item?.contributorRole ? t(`contributorRole.${item?.contributorRole}`) : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            3. {t('scientificProfile.otherInfo.usefulSolution.label')}:
          </span>
          <div className="form-table my-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 260, width: '26%' }}>
                    {t('scientificProfile.otherInfo.usefulSolution.name')}
                  </th>
                  <th className="text-center" style={{ minWidth: 170, width: '17%' }}>
                    {t('scientificProfile.otherInfo.usefulSolution.projectCode')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                    {t('scientificProfile.otherInfo.usefulSolution.number')}
                  </th>
                  <th className="text-center" style={{ minWidth: 70, width: '7%' }}>
                    {t('scientificProfile.otherInfo.usefulSolution.yearOfIssue')}
                  </th>
                  <th className="text-center" style={{ minWidth: 260, width: '26%' }}>
                    {t('scientificProfile.otherInfo.usefulSolution.placeOfIssue')}
                  </th>
                  <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                    {t('scientificProfile.otherInfo.usefulSolution.authorOrCoauthor')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.usefulSolutions?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.projectCode}</td>
                    <td>{item?.number}</td>
                    <td>{item?.yearOfIssue}</td>
                    <td>{item?.placeOfIssue}</td>
                    <td>
                      {item?.contributorRole ? t(`contributorRole.${item?.contributorRole}`) : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            4. {t('scientificProfile.otherInfo.transferredSolution.label')}:
          </span>
          <div className="form-table my-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 260, width: '26%' }}>
                    {t('scientificProfile.otherInfo.transferredSolution.name')}
                  </th>
                  <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                    {t('scientificProfile.otherInfo.transferredSolution.form')}
                  </th>
                  <th className="text-center" style={{ minWidth: 150, width: '15%' }}>
                    {t('scientificProfile.otherInfo.transferredSolution.scale')}
                  </th>
                  <th className="text-center" style={{ minWidth: 210, width: '21%' }}>
                    {t('scientificProfile.otherInfo.transferredSolution.address')}
                  </th>
                  <th className="text-center" style={{ minWidth: 70, width: '7%' }}>
                    {t('scientificProfile.otherInfo.transferredSolution.yearOfTransfer')}
                  </th>
                  <th className="text-center" style={{ minWidth: 170, width: '17%' }}>
                    {t('scientificProfile.otherInfo.transferredSolution.projectCode')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.transferredSolutions?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.form}</td>
                    <td>{item?.scale}</td>
                    <td>{item?.address}</td>
                    <td>{item?.yearOfTransfer}</td>
                    <td>{item?.projectCode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

AwardsSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

AwardsSection.defaultProps = {
  data: {},
};
