/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-danger */
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  setExpenditureTableOfSummaryReport,
  formatFileName,
  formatCurrency,
  formatDate,
  getProductDetail,
} from 'utils/func';
import {
  getSummaryReportType,
  // downloadFileReport,
  downloadReportPdfFile,
  downloadReportDocxFile,
} from 'apis/summaryReport';
import {
  useQuery,
  // , useMutation
} from 'react-query';
import { SUMMARY_REPORT_TYPE, TOAST } from 'constant';
import { Button } from 'primereact/button';
import { toast } from 'layout';
import Loading from 'components/Loading';
import GeneralInfo from '../ReportFormR05/GeneralInfo';
import './styles.scss';
import UploadMultipleFile from '../ReportFormR05/UploadMultipleFile';

export default function ReviewForm({ summaryReport, handleDownloadFile }) {
  const { t } = useTranslation();
  const { control } = useForm({ mode: 'onChange' });
  const { errors } = useFormState({ control });

  const [summaryReportAppendixeArray, setSummaryReportAppendixeArray] = useState([]);

  const {
    data: summaryReportTypesData,
    isLoading: isSummaryReportTypeLoading,
    // isError: isSummaryReportTypeError,
    // refetch
  } = useQuery(['summaryReportType'], getSummaryReportType);

  const summaryReportTypes = useMemo(
    () => summaryReportTypesData?.data || [],
    [summaryReportTypesData]
  );

  const summaryReportAcceptanceTypeId = useMemo(
    () =>
      summaryReportTypes?.find((item) => item.code === SUMMARY_REPORT_TYPE.ACCEPTANCE_DOCUMENT)
        ?.id ?? null,
    [summaryReportTypes]
  );

  const summaryReportFinishedTasks = useMemo(
    () => summaryReport?.summaryReport?.summaryReportFinishedTasks ?? [],
    [summaryReport]
  );

  const summaryReportUnfinishedTasks = useMemo(
    () => summaryReport?.summaryReport?.summaryReportUnfinishedTasks ?? [],
    [summaryReport]
  );

  const softProducts = useMemo(
    () => summaryReport?.summaryReportProducts?.scientificPublications?.softProducts ?? [],
    [summaryReport]
  );

  const hardProducts = useMemo(
    () => summaryReport?.summaryReportProducts?.scientificPublications?.hardProducts ?? [],
    [summaryReport]
  );

  const intellectualPropertys = useMemo(
    () => summaryReport?.summaryReportProducts?.intellectualPropertys ?? [],
    [summaryReport]
  );

  const transferredTechnologies = useMemo(
    () => summaryReport?.summaryReport?.summaryReportTransferredTechnologies ?? [],
    [summaryReport]
  );

  const trainingResults = useMemo(
    () => summaryReport?.summaryReportProducts?.trainingResults ?? [],
    [summaryReport]
  );

  const summaryReportConferences = useMemo(
    () => summaryReport?.summaryReport?.summaryReportConferences ?? [],
    [summaryReport]
  );

  const summaryReportParticipants = useMemo(
    () => summaryReport?.summaryReport?.summaryReportParticipants ?? [],
    [summaryReport]
  );

  const summaryReportExpenditures = useMemo(
    () =>
      summaryReport?.summaryReport?.summaryReportExpenditures?.filter((item) => item?.no === 0) ??
      [],
    [summaryReport]
  );

  const subSummaryReportExpenditures = useMemo(
    () =>
      summaryReport?.summaryReport?.summaryReportExpenditures
        ?.filter((item) => item?.no !== 0)
        .sort((a, b) => a.no - b.no) ?? [],
    [summaryReport]
  );

  const {
    refetch: handleDownloadPdfFile,
    isLoading: isDownloadPdfFileLoading,
    isFetching: isDownloadPdfFileFetching,
  } = useQuery(
    ['downloadProjectR05PdfFile'],
    () => downloadReportPdfFile(summaryReport?.summaryReport?.id),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);
          const filename = res.headers['content-disposition'].split('filename=')[1];
          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  // const { mutate: mutateDownloadFile } = useMutation(downloadFileReport);

  // const handleDownloadFile = (filename, fileNameFormat = '') => {
  //   mutateDownloadFile(
  //     {
  //       summaryReportId,
  //     },
  //     {
  //       onSuccess: async (res) => {
  //         if (res?.data) {
  //           const file = new Blob([res.data]);
  //           // window.open(URL.createObjectURL(file));

  //           const link = document.createElement('a');
  //           link.href = URL.createObjectURL(file);
  //           link.download = fileNameFormat || filename;

  //           link.click();
  //         }
  //       },
  //     }
  //   );
  // };

  const {
    refetch: handleDownloadDocxFile,
    isLoading: isDownloadDocxFileLoading,
    isFetching: isDownloadDocxFileFetching,
  } = useQuery(
    ['downloadProjectR05DocxFile'],
    () => downloadReportDocxFile(summaryReport?.summaryReport?.id),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);
          const filename = res.headers['content-disposition'].split('filename=')[1];
          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );

  useEffect(() => {
    setSummaryReportAppendixeArray(summaryReport?.summaryReportAppendixes || []);
  }, [JSON.stringify(summaryReport)]);
  return (
    <>
      {isSummaryReportTypeLoading && <Loading />}
      <div className="review-form">
        <div className="flex justify-content-end mb-2">
          <Button
            type="button"
            label={t('projectProposal.review.exportDocx')}
            icon={`pi ${
              isDownloadDocxFileLoading || isDownloadDocxFileFetching
                ? 'pi-spin pi-spinner'
                : 'pi-file-word'
            }`}
            className="block w-auto h-3rem mr-4"
            severity="info"
            onClick={handleDownloadDocxFile}
            disabled={
              !summaryReport?.summaryReport ||
              isDownloadDocxFileLoading ||
              isDownloadDocxFileFetching
            }
          />
          <Button
            type="button"
            label={t('projectProposal.review.exportPdf')}
            icon={`pi ${
              isDownloadPdfFileLoading || isDownloadPdfFileFetching
                ? 'pi-spin pi-spinner'
                : 'pi-file-pdf'
            }`}
            className="block w-auto h-3rem border-none"
            style={{ backgroundColor: 'var(--red-400)' }}
            onClick={handleDownloadPdfFile}
            disabled={
              !summaryReport?.summaryReport || isDownloadPdfFileLoading || isDownloadPdfFileFetching
            }
          />
        </div>
        <div className="mb-5">
          <div className="mb-5 px-5 flex justify-content-between align-items-center">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="VNU-HCM logo"
              height="60px"
              className="ml-3"
            />
            <div className="mr-8">
              <span>{t('r05.formR05')}:</span>
              <br />
              <span>{t('r05.projectCode')}:</span>
            </div>
          </div>
          <div className="text-center">
            <p className="font-bold text-2xl my-0">{t('r05.title')}</p>
            <p className="font-bold text-2xl my-0">{t('r05.topic')}</p>
            <p className="font-italic">{t('r05.note')}</p>
          </div>
        </div>
        <form autoComplete="off" className="p-fluid form-midterm-container">
          <GeneralInfo />

          <div>
            <h4 className="my-2">{t('r05.b.title')}</h4>
            <h4 className="my-1">{t('r05.b.b1.title')}</h4>
            {/* finished tasks */}
            <h6 className="my-2 font-bold">{t('r05.b.b1.no1.title')}</h6>
            <div className="form-table">
              <table className="mb-2">
                <thead>
                  <tr>
                    <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                      {t('formLayout.numericalOrder')}
                    </th>
                    <th className="text-center" style={{ minWidth: 220, width: '33.33%' }}>
                      {t('r05.b.b1.no1.registrationContent')}
                    </th>
                    <th className="text-center" style={{ minWidth: 220, width: '33.33%' }}>
                      {t('r05.b.b1.no1.result')}
                    </th>
                    <th className="text-center" style={{ minWidth: 220, width: '33.33%' }}>
                      {t('r05.b.b1.no1.completionLevel')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {summaryReportFinishedTasks.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center py-2">
                        {t('submitDocument.noContent')}
                      </td>
                    </tr>
                  )}
                  {summaryReportFinishedTasks?.map((item, index) => (
                    <tr key={item?.id}>
                      <td className="text-center">{index + 1}</td>
                      <td>{item?.content}</td>
                      <td>{item?.result}</td>
                      <td>{item?.completionLevel}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* unfinished tasks */}
            <h6 className="my-2">{t('r05.b.b1.no2.title')}</h6>
            <div className="form-table">
              <table className="mb-2">
                <thead>
                  <tr>
                    <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                      {t('formLayout.numericalOrder')}
                    </th>
                    <th className="text-center" style={{ minWidth: 220, width: '33.33%' }}>
                      {t('r05.b.b1.no2.incompleteContent')}
                    </th>
                    <th className="text-center" style={{ minWidth: 220, width: '33.33%' }}>
                      {t('r05.b.b1.no2.cause')}
                    </th>
                    <th className="text-center" style={{ minWidth: 220, width: '33.33%' }}>
                      {t('r05.b.b1.no2.remedies')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {summaryReportUnfinishedTasks.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center py-2">
                        {t('submitDocument.noContent')}
                      </td>
                    </tr>
                  )}
                  {summaryReportUnfinishedTasks?.map((item, index) => (
                    <tr key={item?.id}>
                      <td className="text-center">{index + 1}</td>
                      <td>{item?.content}</td>
                      <td>{item?.reason}</td>
                      <td>{item?.solution}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <h4 className="mt-4 mb-2">{t('r05.b.b2.title')}</h4>
          {/* soft product */}
          <h4 className="mt-1">{t('r05.b.b2.no1')}</h4>
          <h4>{t('projectProposal.researchResult.a10_1.softProduct.label')}</h4>
          <p>({t('projectProposal.researchResult.a10_1.softProduct.note')})</p>
          <div className="form-table">
            <table className="mb-2">
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: 200 }}>
                    {t('r05.b.b2.table.productName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('r05.b.b2.table.kpiType')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: 200 }}>
                    {t('r05.b.b2.table.kpiName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 250, width: 250 }}>
                    {t('r05.b.b2.table.productDetail')}
                  </th>
                  <th className="text-center" style={{ minWidth: 220, width: 220 }}>
                    {t('r05.b.b2.table.proof')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {softProducts.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center py-2">
                      {t('submitDocument.noProduct')}
                    </td>
                  </tr>
                )}
                {softProducts?.map((item, index) => (
                  <tr key={item.customId}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.productDetail?.title}</td>
                    <td>{item?.researchKpiGroup?.name}</td>
                    <td>{item?.researchKpi?.name}</td>
                    <td
                      dangerouslySetInnerHTML={{ __html: getProductDetail(t, item?.productDetail) }}
                    />
                    <td>
                      {item?.fileUrl && (
                        <span
                          className="p-button p-button-text p-button-sm mt-1 p-0"
                          onClick={() => handleDownloadFile(formatFileName(item?.fileUrl))}
                        >
                          <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                          <span className="text-blue-500 underline font-italic ml-1">
                            {formatFileName(item?.fileUrl)}
                          </span>
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* hard product */}
          <h4>{t('projectProposal.researchResult.a10_1.hardProduct.label')}</h4>
          <p>({t('projectProposal.researchResult.a10_1.hardProduct.note')})</p>
          <div className="form-table">
            <table className="mb-2">
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: 200 }}>
                    {t('r05.b.b2.table.productName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('r05.b.b2.table.kpiType')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: 200 }}>
                    {t('r05.b.b2.table.kpiName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 250, width: 250 }}>
                    {t('r05.b.b2.table.productDetail')}
                  </th>
                  <th className="text-center" style={{ minWidth: 220, width: 220 }}>
                    {t('r05.b.b2.table.proof')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {hardProducts.length === 0 && (
                  <tr>
                    <td colSpan={5} className="text-center py-2">
                      {t('submitDocument.noProduct')}
                    </td>
                  </tr>
                )}
                {hardProducts?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.productDetail?.title}</td>
                    <td>{item?.researchKpiGroup?.name}</td>
                    <td>{item?.researchKpi?.name}</td>
                    <td
                      dangerouslySetInnerHTML={{ __html: getProductDetail(t, item?.productDetail) }}
                    />
                    <td>
                      {item?.fileUrl && (
                        <span
                          className="p-button p-button-text p-button-sm mt-1 p-0"
                          onClick={() => handleDownloadFile(formatFileName(item?.fileUrl))}
                        >
                          <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                          <span className="text-blue-500 underline font-italic ml-1">
                            {formatFileName(item?.fileUrl)}
                          </span>
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* intellectual property */}
          <h4>{t('r05.b.b2.no2')}</h4>
          <div className="form-table">
            <table className="mb-2">
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 160, width: 200 }}>
                    {t('r05.b.b2.table.productName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 60, width: 60 }}>
                    {t('r05.b.b2.table.kpiType')}
                  </th>
                  <th className="text-center" style={{ minWidth: 160, width: 200 }}>
                    {t('r05.b.b2.table.kpiName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 160, width: 200 }}>
                    {t('r05.b.b2.table.note')}
                  </th>
                  <th className="text-center" style={{ minWidth: 160, width: 200 }}>
                    {t('r05.b.b2.table.proof')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {intellectualPropertys.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center py-2">
                      {t('submitDocument.noProduct')}
                    </td>
                  </tr>
                )}
                {intellectualPropertys?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.productDetail?.title}</td>
                    <td>{item?.researchKpiGroup?.name}</td>
                    <td>{item?.researchKpi?.name}</td>
                    <td
                      dangerouslySetInnerHTML={{ __html: getProductDetail(t, item?.productDetail) }}
                    />
                    <td>
                      {item?.fileUrl && (
                        <span
                          className="p-button p-button-text p-button-sm mt-1 p-0"
                          onClick={() => handleDownloadFile(formatFileName(item?.fileUrl))}
                        >
                          <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                          <span className="text-blue-500 underline font-italic ml-1">
                            {formatFileName(item?.fileUrl)}
                          </span>
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* transfer technology  */}
          <h4>{t('r05.b.b2.technologyOrSolution.title')}</h4>
          <div className="form-table p-fluid">
            <table className="mb-2">
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 180, width: 200 }}>
                    {t('r05.b.b2.technologyOrSolution.name')}
                  </th>
                  <th className="text-center" style={{ minWidth: 60, width: 60 }}>
                    {t('r05.b.b2.technologyOrSolution.year')}
                  </th>
                  <th className="text-center" style={{ minWidth: 140, width: 140 }}>
                    {t('r05.b.b2.technologyOrSolution.partner')}
                  </th>
                  <th className="text-center" style={{ minWidth: 80, width: 100 }}>
                    {t('r05.b.b2.technologyOrSolution.date')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: 100 }}>
                    {t('r05.b.b2.technologyOrSolution.revenue')}
                  </th>
                  <th className="text-center" style={{ minWidth: 140, width: 180 }}>
                    {t('r05.b.b2.technologyOrSolution.scale')}
                  </th>
                  <th className="text-center" style={{ minWidth: 140, width: 160 }}>
                    {t('r05.b.b2.table.proof')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {transferredTechnologies.length === 0 && (
                  <tr>
                    <td colSpan={8} className="text-center py-2">
                      {t('submitDocument.noTransferTechnology')}
                    </td>
                  </tr>
                )}
                {transferredTechnologies?.map((item, index) => (
                  <tr key={item.customId}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.transferredYear}</td>
                    <td>{item.partner}</td>
                    <td>{formatDate(item.contractedDate)}</td>
                    <td className="text-right">{formatCurrency(item.revenue)}</td>
                    <td>{item.scale}</td>
                    <td>
                      {item?.fileUrl && (
                        <span
                          className="p-button p-button-text p-button-sm mt-1 p-0"
                          onClick={() => handleDownloadFile(formatFileName(item?.fileUrl))}
                        >
                          <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                          <span className="text-blue-500 underline font-italic ml-1">
                            {formatFileName(item?.fileUrl)}
                          </span>
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* training result */}
          <h4>{t('r05.b.b2.no3.title')}</h4>
          <div className="form-table p-fluid">
            <table className="mb-2">
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: 200 }}>
                    {t('r05.b.b2.table.productName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: 100 }}>
                    {t('r05.b.b2.table.kpiType')}
                  </th>
                  <th className="text-center" style={{ width: 200, maxWidth: 200 }}>
                    {t('r05.b.b2.table.kpiName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: 200 }}>
                    {t('r05.b.b2.table.productDetail')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: 200 }}>
                    {t('r05.b.b2.table.proof')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {trainingResults.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center py-2">
                      {t('submitDocument.noProduct')}
                    </td>
                  </tr>
                )}
                {trainingResults?.map((item, index) => (
                  <tr key={item.customId}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.productDetail?.title}</td>
                    <td>{item?.researchKpiGroup?.name}</td>
                    <td>{item?.researchKpi?.name}</td>
                    <td
                      dangerouslySetInnerHTML={{ __html: getProductDetail(t, item?.productDetail) }}
                    />
                    <td>
                      {item?.fileUrl && (
                        <span
                          className="p-button p-button-text p-button-sm mt-1 p-0"
                          onClick={() => handleDownloadFile(formatFileName(item?.fileUrl))}
                        >
                          <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                          <span className="text-blue-500 underline font-italic ml-1">
                            {formatFileName(item?.fileUrl)}
                          </span>
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* summary report conference */}
          <h4>{t('r05.b.b3.title')}</h4>
          <div className="form-table">
            <table className="mb-2">
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 220, width: 240 }}>
                    {t('r05.b.b3.no1.name')}
                  </th>
                  <th className="text-center" style={{ minWidth: 80, width: 80 }}>
                    {t('r05.b.b3.no1.time')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: 280 }}>
                    {t('r05.b.b3.no1.location')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: 280 }}>
                    {t('r05.b.b3.no1.result')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {summaryReportConferences.length === 0 && (
                  <tr>
                    <td colSpan={5} className="text-center py-2">
                      {t('submitDocument.noConference')}
                    </td>
                  </tr>
                )}
                {summaryReportConferences?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item.title}</td>
                    <td>{formatDate(item.organizedDate)}</td>
                    <td>{item.address}</td>
                    <td>{item.result}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* summary report participants */}
          <p className="mt-4">{t('r05.b.b3.no2.description')}</p>
          <div className="form-table">
            <table className="mb-2">
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 140, width: 180 }}>
                    {t('r05.b.b3.no2.name')}
                  </th>
                  <th className="text-center" style={{ minWidth: 80, width: 80 }}>
                    {t('r05.b.b3.no2.time')}
                  </th>
                  <th className="text-center" style={{ minWidth: 160, width: 220 }}>
                    {t('r05.b.b3.no2.location')}
                  </th>
                  <th className="text-center" style={{ minWidth: 160, width: 220 }}>
                    {t('r05.b.b3.no2.content')}
                  </th>
                  <th className="text-center" style={{ minWidth: 160, width: 220 }}>
                    {t('r05.b.b3.no2.result')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {summaryReportParticipants.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center py-2">
                      {t('submitDocument.noParticipant')}
                    </td>
                  </tr>
                )}
                {summaryReportParticipants?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item.fullName}</td>
                    <td>{formatDate(item.joiningDate)}</td>
                    <td>{item.address}</td>
                    <td>{item.content}</td>
                    <td>{item.result}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* summary report expenditure */}
          <h4 className="mt-4 mb-1">{t('r05.b.b4.title')}</h4>
          <div className="form-table">
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: 440, width: 440 }}>{t('r05.b.b4.expenditure')}</th>
                  <th style={{ minWidth: 110, width: 160 }}>{t('r05.b.b4.amountOfMoney')}</th>
                  <th style={{ minWidth: 220, width: 440 }}>{t('r05.b.b4.note')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-bold">{t('r05.b.b4.expenditureFromVNU')}</td>
                  <td className="text-right">
                    {formatCurrency(
                      setExpenditureTableOfSummaryReport(
                        summaryReportExpenditures,
                        'Funding for the project is allocated by VNU',
                        'expenditure'
                      )
                    )}
                  </td>
                  <td>
                    {setExpenditureTableOfSummaryReport(
                      summaryReportExpenditures,
                      'Funding for the project is allocated by VNU',
                      'notes'
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">{t('r05.b.b4.expenditureAllocated')}</td>
                  <td className="text-right">
                    {formatCurrency(
                      setExpenditureTableOfSummaryReport(
                        summaryReportExpenditures,
                        'Allocated expenditure',
                        'expenditure'
                      )
                    )}
                  </td>
                  <td>
                    {setExpenditureTableOfSummaryReport(
                      summaryReportExpenditures,
                      'Allocated expenditure',
                      'notes'
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="font-bold mb-0">{t('r05.b.b4.expenditureUsed.description')}</p>
                    <p>{t('r05.b.b4.expenditureUsed.note')}</p>
                  </td>
                  <td className="text-right">
                    {formatCurrency(
                      setExpenditureTableOfSummaryReport(
                        summaryReportExpenditures,
                        'Used expenditure',
                        'expenditure'
                      )
                    )}
                  </td>
                  <td>
                    {setExpenditureTableOfSummaryReport(
                      summaryReportExpenditures,
                      'Used expenditure',
                      'notes'
                    )}
                  </td>
                </tr>
                {/* subExpenditure table */}
                <tr>
                  <td colSpan={3}>
                    <div className="form-table">
                      <table className="mb-1">
                        <thead>
                          <tr>
                            <th className="text-center" style={{ minWidth: 40, width: 40 }}>
                              {t('formLayout.numericalOrder')}
                            </th>
                            <th className="text-center" style={{ minWidth: 340, width: 340 }}>
                              {t('r05.b.b4.nameContentResolved')}
                            </th>
                            <th className="text-center" style={{ minWidth: 140, width: 140 }}>
                              {t('r05.b.b4.amountOfMoney')}
                            </th>
                            <th className="text-center" style={{ minWidth: 380, width: 380 }}>
                              {t('r05.b.b4.note')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {subSummaryReportExpenditures?.map((item, index) => (
                            <tr key={item?.id}>
                              <td className="text-center">{index + 1}</td>
                              <td>{item?.expenseName}</td>
                              <td className="text-right">{formatCurrency(item?.expenditure)}</td>
                              <td>{item?.notes}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="font-bold">{t('r05.b.b4.expenditurePropose')}</p>
                  </td>
                  <td className="text-right">
                    {formatCurrency(
                      setExpenditureTableOfSummaryReport(
                        summaryReportExpenditures,
                        'Suggested expenditures',
                        'expenditure'
                      )
                    )}
                  </td>
                  <td>
                    {setExpenditureTableOfSummaryReport(
                      summaryReportExpenditures,
                      'Suggested expenditures',
                      'notes'
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h6 className="mb-0">{t('r05.b.b4.expenditureProof')}</h6>
          {summaryReport?.summaryReport?.expenditureFileUrl && (
            <span
              className="p-button p-button-text p-button-sm mt-1 mb-2 p-0"
              onClick={() =>
                handleDownloadFile(
                  summaryReport?.summaryReport?.expenditureFileUrl,
                  formatFileName(summaryReport?.summaryReport?.expenditureFileUrl)
                )
              }
            >
              <i className="pi pi-file-pdf text-green-500 text-xl" />{' '}
              <span className="text-blue-500 underline font-italic text-base">
                {formatFileName(summaryReport?.summaryReport?.expenditureFileUrl)}
              </span>
            </span>
          )}
          {/* B5 */}
          <div>
            <h4 className="my-2">{t('r05.b.b5.title')}</h4>
            <h6 className="my-2">{t('r05.b.b5.no1')}</h6>
            <div
              dangerouslySetInnerHTML={{ __html: summaryReport?.summaryReport?.contentReview }}
            />
            <h6 className="my-2">{t('r05.b.b5.no2')}</h6>
            <div
              dangerouslySetInnerHTML={{ __html: summaryReport?.summaryReport?.productReview }}
            />
            <h6 className="my-2">{t('r05.b.b5.no3')}</h6>
            <div
              dangerouslySetInnerHTML={{ __html: summaryReport?.summaryReport?.processReview }}
            />
            <h6 className="my-2">{t('r05.b.b5.no4')}</h6>
            <div dangerouslySetInnerHTML={{ __html: summaryReport?.summaryReport?.suggestion }} />
          </div>
          {/* R08 */}
          {summaryReport?.summaryReport?.summaryReportTypeId === summaryReportAcceptanceTypeId && (
            <>
              <h4 className="mt-2 mb-0">{t('r08.summaryReportFile')}</h4>
              {summaryReport?.summaryReport?.summaryReportFileUrl && (
                <span
                  className="p-button p-button-text p-button-sm mt-1 p-0"
                  onClick={() =>
                    handleDownloadFile(
                      summaryReport?.summaryReport?.summaryReportFileUrl,
                      formatFileName(summaryReport?.summaryReport?.summaryReportFileUrl)
                    )
                  }
                >
                  <i className="pi pi-file-pdf text-red-500 text-xl mr-2" />
                  <span className="text-blue-500 underline font-italic text-base">
                    {formatFileName(summaryReport?.summaryReport?.summaryReportFileUrl)}
                  </span>
                </span>
              )}
              <h4 className="mt-2 mb-0">{t('r08.proofAndAppendices')} </h4>
              <UploadMultipleFile
                disabled
                control={control}
                errors={errors}
                projectCode={summaryReport?.summaryReport?.projectCode}
                summaryReportAppendixeArray={summaryReportAppendixeArray}
                handleDownloadFile={handleDownloadFile}
                setSummaryReportAppendixeArray={setSummaryReportAppendixeArray}
              />
            </>
          )}
        </form>
      </div>
    </>
  );
}

ReviewForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  summaryReport: PropTypes.object,
  handleDownloadFile: PropTypes.func,
};

ReviewForm.defaultProps = {
  summaryReport: {},
  handleDownloadFile: () => {},
};
