import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { downloadLiquidationFile } from 'apis/liquidation.api';
import { DatePicker, TextInput, CurrencyInput, FileInput } from 'components/FormControl';
import { formatFileName } from 'utils/func';
import UploadMultipleFile from 'features/Project/components/ReportFormR05/UploadMultipleFile';

function LiquidationForm({
  control,
  errors,
  handleSubmit,
  checkKeyDown,
  setValue,
  liquidation,
  appendixFilesDelete,
  setAppendixFilesDelete,
  isCompleted,
  readOnly,
}) {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [appendixeArray, setAppendixeArray] = useState([]);

  const { mutate: downloadFileMutate } = useMutation(downloadLiquidationFile);

  const handleDownloadFile = (path) => {
    downloadFileMutate(
      { path },
      {
        onSuccess: (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = formatFileName(path);

            link.click();
          }
        },
      }
    );
  };

  useEffect(() => {
    setAppendixeArray(liquidation?.liquidationAppendices ?? []);
  }, [liquidation]);

  useEffect(() => {
    setValue('projectAppendixDeletedFileIds', appendixFilesDelete ?? []);
  }, [appendixFilesDelete]);

  return (
    <div className="p-5">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form onSubmit={handleSubmit} onKeyDown={checkKeyDown} autoComplete="off">
        <div className="relative">
          <h4 className="text-center mb-4">{t('projectProposal.general.label')}</h4>
          <div className="grid p-fluid form-table">
            <div className="col-4 my-2">
              {readOnly ? (
                <TextInput
                  label={t('liquidation.liquidationDate')}
                  name="liquidationDateDefault"
                  control={control}
                  errors={errors}
                  isRequired
                  readOnly={readOnly}
                  defaultValue={moment(liquidation?.liquidationDate).format('DD/MM/YYYY')}
                />
              ) : (
                <DatePicker
                  label={t('liquidation.liquidationDate')}
                  name="liquidationDate"
                  control={control}
                  errors={errors}
                  isRequired
                  disabled={readOnly}
                />
              )}
            </div>
            <div className="col-4 my-2">
              <TextInput
                label={t('liquidation.memorandumCode')}
                name="code"
                control={control}
                errors={errors}
                isRequired
                readOnly={readOnly}
              />
            </div>
            <div className="col-4 my-2">
              <div className="flex flex-column">
                <label>{t('liquidation.status')}</label>
                <p className="py-3 font-bold">
                  {isCompleted ? t('council.m06.b.reach') : t('council.m06.b.failed')}
                </p>
              </div>
            </div>

            {/* <h4 className="text-primary">Xử lý tài chính</h4> */}
            <table className="mb-2 col-12">
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 260, width: 360 }}>
                    {t('projectProposal.expenditure.label')}
                  </th>
                  <th className="text-center" style={{ minWidth: 130, width: 360 }}>
                    {t('projectProposal.expenditure.a5.total')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-bold">{t('liquidation.table.vnuExpenditure')}</td>
                  <td>
                    <CurrencyInput
                      name="vnuExpenditure"
                      control={control}
                      errors={errors}
                      readOnly={readOnly}
                    />
                  </td>
                </tr>
                {isCompleted ? (
                  <tr>
                    <td className="font-bold">{t('liquidation.table.suggestedExpenditure')}</td>
                    <td>
                      <CurrencyInput
                        name="suggestedExpenditure"
                        control={control}
                        errors={errors}
                        readOnly={readOnly}
                      />
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td className="font-bold">{t('liquidation.table.usedExpenditure')}</td>
                      <td>
                        <CurrencyInput
                          name="usedExpenditure"
                          control={control}
                          errors={errors}
                          readOnly={readOnly}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-bold">{t('liquidation.table.acceptedExpenditure')}</td>
                      <td>
                        <CurrencyInput
                          name="acceptedExpenditure"
                          control={control}
                          errors={errors}
                          readOnly={readOnly}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-bold">
                        {t('liquidation.table.stateReimbursementExpenditure')}
                      </td>
                      <td>
                        <CurrencyInput
                          name="stateReimbursementExpenditure"
                          control={control}
                          errors={errors}
                          readOnly={readOnly}
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>

            <div className="col-12 my-2">
              {readOnly ? (
                <h5>{t('liquidation.decisionFile')}</h5>
              ) : (
                <FileInput
                  label={t('liquidation.decisionFile')}
                  name="liquidationDecisionFile"
                  control={control}
                  errors={errors}
                  accept=".pdf"
                  isRequired
                  disabled={liquidation?.isSentEmail}
                />
              )}

              {liquidation?.decisionFile && (
                <span
                  className="underline "
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => handleDownloadFile(liquidation?.decisionFile)}
                >
                  {formatFileName(liquidation?.decisionFile)}
                </span>
              )}
            </div>
            <div className="col-12">
              <UploadMultipleFile
                disabled={readOnly}
                control={control}
                errors={errors}
                appendixeArray={appendixeArray}
                setAppendixFilesDelete={setAppendixFilesDelete}
                setAppendixeArray={setAppendixeArray}
                handleDownloadFile={handleDownloadFile}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-content-end mt-2 gap-4">
          <Button
            className="w-10rem"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            label={t('contract.comeBack')}
            comeBack
            severity="danger"
            type="button"
            onClick={() => {
              navigate('/project');
            }}
          />
        </div>
      </form>
    </div>
  );
}

LiquidationForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  checkKeyDown: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  liquidation: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  appendixFilesDelete: PropTypes.array,
  setAppendixFilesDelete: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
};

LiquidationForm.defaultProps = {
  liquidation: {},
  appendixFilesDelete: [],
  readOnly: false,
};

export default LiquidationForm;
