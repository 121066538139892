import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { create as createContact, getBySearchEmail } from 'apis/contacts.api';

import { Combobox, Select, TextInput, TextareaInput, Tickbox } from 'components/FormControl';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import { getAcademicDegrees, getAcademicRanks, getGenders } from 'utils/func';
import { TOAST } from 'constant';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import researcherSchema from '../validation';
import ResearchLinesTable from '../../ResearchLinesTable';

const formOptions = { resolver: yupResolver(researcherSchema) };

const ResearcherDialog = forwardRef(({ organizations, ownerId }, ref) => {
  const { t } = useTranslation();

  const [queryEmail, setQueryEmail] = useState('');
  const [contacts, setContacts] = useState([]);
  const [researcherControl, setResearcherControl] = useState();
  const [visible, setVisible] = useState(false);
  const [isCreateNewResearcher, setIsCreateNewResearcher] = useState(false);

  const { mutate: mutateCreateResearcher, isLoading: isCreateResearcherLoading } =
    useMutation(createContact);

  const { data: contactsData } = useQuery(
    ['projectProposalContacts', queryEmail],
    () => getBySearchEmail(queryEmail),
    { staleTime: 0 }
  );

  const {
    control,
    watch,
    setValue,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({ ...formOptions, mode: 'onChange' });

  const organizationId = watch('organizationId');
  const user = JSON.parse(localStorage.getItem('user'));

  const currentOrganization = useMemo(() => {
    if (organizations?.length) {
      const result = organizations?.find((item) => item?.id === organizationId) ?? {};
      setValue('organizationEnglishName', result?.englishName ?? '');
      if (result?.englishName !== 'Other') {
        setValue('specificOrganizationName', null);
      }
      return result;
    }
    return null;
  }, [organizations, organizationId]);
  // #region Event

  const search = (e) => {
    if (e.query) setQueryEmail(e.query);
  };

  useEffect(() => {
    if (contactsData?.data) {
      setContacts(contactsData?.data);
    }
  }, [contactsData]);

  useImperativeHandle(
    ref,
    () => ({
      open: (_researcherControl) => {
        setResearcherControl(_researcherControl);
        setVisible(true);
        if (_researcherControl.type === 'APPEND') {
          reset({});
        } else if (_researcherControl.type === 'UPDATE') {
          reset(_researcherControl.value);
        }
      },
    }),
    [organizations, ownerId]
  );

  const handleAddResearcher = async () => {
    setValue('projectRoleId', researcherControl.projectRoleId);

    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = watch();

    if (!data?.gender) {
      toast(TOAST.ERROR, t('errorMessage.researcherHasNoGender'));
      return;
    }

    const { append, update, index, type } = researcherControl;

    if (isCreateNewResearcher) {
      mutateCreateResearcher(
        {
          email: data.contact,
          organizationId: data.organizationId,
          fullname: data.fullname,
          gender: data?.gender,
          academicRank: data?.academicRank,
          academicDegree: data.academicDegree,
          researchLines: data?.researchLines,
          specificOrganizationName: data?.specificOrganizationName,
        },
        {
          onSuccess: (res) => {
            toast(TOAST.SUCCESS, t('projectProposal.general.a9.researcherDialog.createSuccess'));

            if (type === 'APPEND' && append) {
              append({
                email: res?.data?.email,
                contact: res?.data,
                fullname: data?.fullname,
                gender: data?.gender,
                academicRank: data?.academicRank,
                academicDegree: data?.academicDegree,
                researchLines: data?.researchLines,
                assignedTask: data?.assignedTask,
                isAuthorized: data?.isAuthorized,
                projectRoleId: data?.projectRoleId,
                organizationId: res?.data?.organizationId,
                specificOrganizationName: res?.data?.specificOrganizationName,
              });
              setIsCreateNewResearcher(false);
            } else if (type === 'UPDATE' && update) {
              update(index, {
                email: res?.data?.email,
                contact: res?.data,
                fullname: data?.fullname,
                gender: data?.gender,
                academicRank: data?.academicRank,
                academicDegree: data?.academicDegree,
                researchLines: data?.researchLines,
                assignedTask: data?.assignedTask,
                isAuthorized: data?.isAuthorized,
                projectRoleId: data?.projectRoleId,
                organizationId: res?.data?.organizationId,
                specificOrganizationName: res?.data?.specificOrganizationName,
              });
              setIsCreateNewResearcher(false);
            }
            reset({});
            setVisible(false);
          },
          onError: (error) => {
            if (error?.response?.data?.code) {
              toast(TOAST.ERROR, t(`errorMessage.${error?.response?.data?.code}`));
            } else {
              toast(TOAST.ERROR, t('projectProposal.general.a9.researcherDialog.createError'));
            }
          },
        }
      );
    } else {
      if (type === 'APPEND' && append) {
        append({
          email: data.contact.email,
          contact: data.contact,
          fullname: data.fullname,
          gender: data?.gender,
          academicRank: data?.academicRank,
          academicDegree: data.academicDegree,
          researchLines: data?.researchLines,
          assignedTask: data.assignedTask,
          isAuthorized: data?.isAuthorized,
          projectRoleId: data.projectRoleId,
          organizationId: data.organizationId,
          specificOrganizationName: data?.specificOrganizationName,
        });
        setIsCreateNewResearcher(false);
      } else if (type === 'UPDATE' && update) {
        update(index, {
          email: data.contact.email,
          contact: data.contact,
          fullname: data.fullname,
          gender: data?.gender,
          academicRank: data?.academicRank,
          academicDegree: data.academicDegree,
          researchLines: data?.researchLines,
          assignedTask: data.assignedTask,
          isAuthorized: data?.isAuthorized,
          projectRoleId: data.projectRoleId,
          organizationId: data.organizationId,
          specificOrganizationName: data?.specificOrganizationName,
        });
        setIsCreateNewResearcher(false);
      }
      reset({});
      setVisible(false);
    }
  };

  const handleEmailSelect = (email, value) => {
    setValue('email', value.email);
    setValue('fullname', value.fullname);
    setValue('gender', value?.scientificProfile?.gender);
    setValue('academicRank', value?.scientificProfile?.academicRank);
    setValue('academicDegree', value?.scientificProfile?.academicDegree);
    setValue('organizationId', value.organizationId);
    setValue('specificOrganizationName', value.specificOrganizationName);
    setValue('researchLines', value?.scientificProfile?.researchLines ?? []);
    setValue('assignedTask', '');
    setValue('isAuthorized', false);
    setIsCreateNewResearcher(false);
  };
  const handleEmailRemove = () => {
    setIsCreateNewResearcher(true);
    setQueryEmail('');
    reset({
      contact: null,
      fullname: '',
      gender: '',
      academicRank: '',
      academicDegree: '',
      organizationId: '',
      researchLines: [],
      assignedTask: '',
      isAuthorized: false,
      specificOrganizationName: null,
    });
  };
  const handleEmailBlur = (value) => {
    if (!value?.id) {
      if (contactsData?.data.length) {
        const contact = contactsData?.data.find((e) => e.email === value);

        if (contact) {
          setValue('contact', contact);
          setValue('fullname', contact.fullname);
          setValue('gender', contact?.scientificProfile?.gender);
          setValue('academicRank', contact?.scientificProfile?.academicRank);
          setValue('academicDegree', contact?.scientificProfile?.academicDegree);
          setValue('organizationId', contact.organizationId);
          setValue('specificOrganizationName', contact.specificOrganizationName);
          setValue('researchLines', contact?.scientificProfile?.researchLines ?? []);
          setValue('assignedTask', '');
          setValue('isAuthorized', false);
          setIsCreateNewResearcher(false);
        } else {
          setIsCreateNewResearcher(true);
          reset({
            contact: value,
            fullname: '',
            gender: '',
            academicRank: '',
            academicDegree: '',
            organizationId: '',
            researchLines: [],
            assignedTask: '',
            isAuthorized: false,
            specificOrganizationName: null,
          });
        }
      } else {
        setIsCreateNewResearcher(true);
        reset({
          contact: value,
          fullname: '',
          gender: '',
          academicRank: '',
          academicDegree: '',
          organizationId: '',
          researchLines: [],
          assignedTask: '',
          isAuthorized: false,
          specificOrganizationName: null,
        });
      }
    }
  };
  // #endregion Event

  return (
    <>
      <Dialog
        header={
          researcherControl?.type === 'APPEND'
            ? t('projectProposal.general.a9.addResearcher')
            : t('projectProposal.general.a9.updateResearcher')
        }
        visible={visible}
        onHide={() => {
          setVisible(false);
          setIsCreateNewResearcher(false);
        }}
        draggable={false}
        className="w-full sm:w-7 lg:w-6"
      >
        <div className="grid p-fluid">
          <div className="col-12">
            <div className="relative mt-1 mb-3">
              <Combobox
                placeholder={t('projectProposal.general.a9.researcherPlaceholder')}
                label={t('projectProposal.general.a9.fields.email')}
                isRequired
                showClear
                name="contact"
                renderField="email"
                control={control}
                errors={errors}
                items={contacts}
                search={search}
                itemTemplate={(item) => item.email}
                onSearchChange={handleEmailSelect}
                onSearchRemove={handleEmailRemove}
                onBlur={handleEmailBlur}
              />
            </div>
          </div>
          <div className="col-9">
            <TextInput
              name="fullname"
              label={t('projectProposal.general.a9.fields.name')}
              isRequired
              control={control}
              errors={errors}
              disabled={!isCreateNewResearcher}
            />
          </div>
          <div className="col-3">
            <Select
              label={t('projectProposal.general.a9.fields.gender')}
              name="gender"
              control={control}
              isRequired
              errors={errors}
              options={getGenders(t)}
              inputField="value"
              disabled={!isCreateNewResearcher}
            />
          </div>

          <div className="col-6">
            <Select
              label={t('projectProposal.general.a6.owner.academicRank')}
              name="academicRank"
              control={control}
              errors={errors}
              options={getAcademicRanks(t)}
              inputField="value"
              disabled={!isCreateNewResearcher}
            />
          </div>
          <div className="col-6">
            <Select
              label={t('projectProposal.general.a6.owner.academicDegree')}
              name="academicDegree"
              control={control}
              errors={errors}
              options={getAcademicDegrees(t)}
              isRequired
              inputField="value"
              disabled={!isCreateNewResearcher}
            />
          </div>

          <div className="col-12">
            <Select
              name="organizationId"
              label={t('projectProposal.general.a9.fields.organization')}
              isRequired
              options={organizations}
              control={control}
              errors={errors}
              disabled={!isCreateNewResearcher}
            />
          </div>

          {currentOrganization?.englishName === 'Other' && (
            <div className="col-12">
              <TextareaInput
                label={t('signup.specificOrganizationName')}
                name="specificOrganizationName"
                type="text"
                control={control}
                errors={errors}
                isRequired
                disabled={!isCreateNewResearcher}
              />
            </div>
          )}

          <div className="col-12">
            <label className="inline-block mb-2">
              {t('projectProposal.general.a9.fields.researchLines')}
            </label>
            <ResearchLinesTable
              name="researchLines"
              control={control}
              errors={errors}
              disabled={!isCreateNewResearcher}
            />
          </div>

          <div className="col-12">
            <TextInput
              label={t('projectProposal.general.a9.fields.assignedTask')}
              name="assignedTask"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          {user.id === ownerId && (
            <div className="col-12 flex">
              <div className="mr-2">{t('projectProposal.general.a9.fields.authority')}: </div>
              <Tickbox name="isAuthorized" icon="pi pi-check" control={control} errors={errors} />
            </div>
          )}
        </div>

        <div className="flex flex-wrap justify-content-end mt-4">
          <Button
            label={t('formLayout.action.cancel')}
            severity="danger"
            onClick={() => {
              setVisible(false);
              setIsCreateNewResearcher(false);
            }}
            className="mr-1 w-8rem"
          />
          <Button
            label={
              researcherControl?.type === 'APPEND'
                ? t('formLayout.action.add')
                : t('formLayout.action.update')
            }
            severity="info"
            type="submit"
            className="w-8rem"
            onClick={handleAddResearcher}
            disabled={!Object.keys(dirtyFields).length}
          />
        </div>
      </Dialog>

      {isCreateResearcherLoading && <Loading />}
    </>
  );
});

ResearcherDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organizations: PropTypes.array,
  ownerId: PropTypes.string.isRequired,
};

ResearcherDialog.defaultProps = {
  organizations: [],
};

export default ResearcherDialog;
