import { forwardRef, useImperativeHandle, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker, TextareaInput } from 'components/FormControl';

import { yupResolver } from '@hookform/resolvers/yup';
import { TOAST } from 'constant';
import { workHistorySchema } from 'features/ScientificProfile/validation';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const formOptions = { resolver: yupResolver(workHistorySchema) };

const WorkHistoryDialog = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [workHistoryControl, setWorkHistoryControl] = useState();
  const [visible, setVisible] = useState(false);

  const {
    control,
    watch,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({ ...formOptions, mode: 'onChange' });
  // #region Event

  useImperativeHandle(
    ref,
    () => ({
      open: (_workHistoryControl) => {
        setWorkHistoryControl(_workHistoryControl);
        setVisible(true);
        if (_workHistoryControl.type === 'INSERT') {
          reset({});
        } else if (_workHistoryControl.type === 'UPDATE') {
          reset(_workHistoryControl.value);
        }
      },
    }),
    []
  );

  const handleAddWorkHistory = async () => {
    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = watch();

    const { insert, update, index, type } = workHistoryControl;

    if (type === 'INSERT' && insert) {
      insert(index, data);
    } else if (type === 'UPDATE' && update) {
      update(index, data);
    }
    reset({});
    setVisible(false);
  };

  // #endregion Event

  return (
    <Dialog
      header={
        workHistoryControl?.type === 'INSERT'
          ? t('scientificProfile.workHistory.add')
          : t('scientificProfile.workHistory.update')
      }
      visible={visible}
      onHide={() => {
        setVisible(false);
      }}
      draggable={false}
      className="w-full sm:w-7 lg:w-6"
    >
      <div className="grid p-fluid">
        <div className="col-6">
          <DatePicker
            label={t('scientificProfile.workHistory.start')}
            name="start"
            control={control}
            errors={errors}
            isRequired
          />
        </div>
        <div className="col-6">
          <DatePicker
            label={t('scientificProfile.workHistory.end')}
            name="end"
            control={control}
            errors={errors}
            isRequired
          />
        </div>
        <div className="col-12">
          <TextareaInput
            label={t('scientificProfile.workHistory.address')}
            name="address"
            control={control}
            errors={errors}
            isRequired
          />
        </div>

        <div className="col-12">
          <TextareaInput
            label={t('scientificProfile.workHistory.position')}
            name="position"
            control={control}
            errors={errors}
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-content-end mt-4">
        <Button
          label={t('formLayout.action.cancel')}
          severity="danger"
          onClick={() => setVisible(false)}
          className="mr-1 w-8rem"
        />
        <Button
          label={
            workHistoryControl?.type === 'INSERT'
              ? t('formLayout.action.add')
              : t('formLayout.action.update')
          }
          severity="info"
          type="submit"
          className="w-8rem"
          onClick={handleAddWorkHistory}
          disabled={!Object.keys(dirtyFields).length}
        />
      </div>
    </Dialog>
  );
});

WorkHistoryDialog.propTypes = {};

WorkHistoryDialog.defaultProps = {};

export default WorkHistoryDialog;
