import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

export default function SoftProductFields({ products }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion

  return (
    <div className="mb-4">
      <h5 className="font-semibold">
        {t('projectProposal.researchResult.a10_1.softProduct.label')}
      </h5>
      <p className="font-italic">{t('projectProposal.researchResult.a10_1.softProduct.note')}</p>

      <div className="grid">
        <div className="col-12">
          <div
            className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines"
            data-scrollselectors=".p-datatable-wrapper"
          >
            <div className="p-datatable-wrapper">
              <table className="p-datatable-table">
                <thead className="p-datatable-thead">
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      <span className="p-column-title">
                        {t('projectProposal.researchResult.a10_1.softProduct.fields.order')}
                      </span>
                    </th>
                    <th style={{ minWidth: '12rem', textAlign: 'center' }}>
                      <span className="p-column-title">
                        {t('projectProposal.researchResult.a10_1.softProduct.fields.productName')}
                      </span>
                    </th>
                    <th style={{ minWidth: '12rem', textAlign: 'center' }}>
                      <span className="p-column-title">
                        {t(
                          'projectProposal.researchResult.a10_1.softProduct.fields.evaluativeCriteria'
                        )}
                      </span>
                    </th>
                    <th style={{ minWidth: '12rem', textAlign: 'center' }}>
                      <span className="p-column-title">
                        {t('projectProposal.researchResult.a10_1.softProduct.fields.note')}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="p-datatable-tbody">
                  {products?.map((product, index) => (
                    <tr key={product?.id ?? index}>
                      <td style={{ textAlign: 'center' }}>{index + 1}</td>
                      <td style={{ minWidth: '12rem' }}>{product?.name}</td>
                      <td style={{ minWidth: '12rem' }}>{product?.evaluativeCriteria}</td>
                      <td style={{ minWidth: '12rem' }}>{product?.note}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SoftProductFields.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  products: PropTypes.array.isRequired,
};
