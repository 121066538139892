import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

import ProgramList from 'features/Program';

function ProgramListPage() {
  return (
    <LayoutProvider>
      <Layout>
        <ProgramList />
      </Layout>
    </LayoutProvider>
  );
}

export default ProgramListPage;
