import instance from 'utils/axios/instance.axios';
import { ACCEPTANCE_REVIEW } from './_constant';

export const createAcceptanceReview = (body = {}) =>
  instance.post(ACCEPTANCE_REVIEW.CREATE_REVIEW, body);

export const updateAcceptanceReview = (body = {}) =>
  instance.patch(ACCEPTANCE_REVIEW.UPDATE_REVIEW, body);

export const submitAcceptanceReview = (body = {}) =>
  instance.patch(ACCEPTANCE_REVIEW.SUBMIT_REVIEW, body);

export const getReviewerInfo = (projectProposalId, councilId) =>
  instance.get(ACCEPTANCE_REVIEW.GET_REVIEWER_INFO, {
    params: { projectProposalId, councilId },
  });

export const getReviewDetail = (councilMemberId, projectProposalId, councilId) =>
  instance.get(ACCEPTANCE_REVIEW.GET_REVIEW_DETAIL, {
    params: { councilMemberId, projectProposalId, councilId },
  });

export const getReviewDetailFile = (councilMemberId, projectProposalId, councilId) =>
  instance.get(ACCEPTANCE_REVIEW.GET_REVIEW_DETAIL_FILE, {
    params: { councilMemberId, projectProposalId, councilId },
  });

export const downloadFile = (body = {}) =>
  instance.post(ACCEPTANCE_REVIEW.DOWNLOAD_FILE, body, { responseType: 'blob' });

export const downloadPdfFile = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.post(
    ACCEPTANCE_REVIEW.DOWNLOAD_PDF_FILE,
    {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    {
      responseType: 'blob',
    }
  );

export const downloadPdfTemplate = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.post(
    ACCEPTANCE_REVIEW.DOWNLOAD_PDF_TEMPLATE,
    {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    {
      responseType: 'blob',
    }
  );

export const downloadDocxFile = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.post(
    ACCEPTANCE_REVIEW.DOWNLOAD_DOCX_FILE,
    {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    {
      responseType: 'blob',
    }
  );

export const downloadDocxTemplate = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.post(
    ACCEPTANCE_REVIEW.DOWNLOAD_DOCX_TEMPLATE,
    {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    {
      responseType: 'blob',
    }
  );
