import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function GeneralInfoSection({ data }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion

  // #region Event
  // #endregion
  return (
    <div className="pb-2">
      <h4 className="mb-2">I. {t('scientificProfile.preview.sectionI').toUpperCase()}</h4>
      {/* I. Thông tin chung */}
      <div className="text-lg">
        <div className="flex mb-1">
          <span className="mr-2 font-semibold">
            1. {t('scientificProfile.generalInfo.fullname')}:
          </span>
          <span className="flex-1">{data?.fullname}</span>
        </div>

        <div className="flex mb-1">
          <span className="mr-2 font-semibold">2. {t('scientificProfile.generalInfo.dob')}:</span>
          <span className="flex-1">
            {data?.dob ? new Date(data?.dob).toLocaleDateString('en-GB') : ''}
          </span>
        </div>

        <div className="flex mb-1">
          <span className="mr-2 font-semibold">
            3. {t('scientificProfile.generalInfo.maleOrFemale')}:
          </span>
          <span className="flex-1">{data?.gender ? t(`gender.${data?.gender}`) : ''}</span>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            4. {t('scientificProfile.generalInfo.workingAddress.label')}:
          </span>
          <div>
            <span className="underline font-italic mr-2">
              {t('scientificProfile.generalInfo.workingAddress.institute')}:
            </span>
            {/* <Select name="general.organizationId" options={organizations ?? []} /> */}
            <span>
              {t('lang') === 'vi' ? data?.organization?.name : data?.organization?.englishName}
            </span>
          </div>
          <div>
            <span className="underline font-italic mr-2">
              {t('scientificProfile.generalInfo.workingAddress.faculty')}:
            </span>
            <span>{data?.faculty}</span>
          </div>
          <div>
            <span className="underline font-italic mr-2">
              {t('scientificProfile.generalInfo.workingAddress.department')}:
            </span>
            <span>{data?.department}</span>
          </div>
          <div>
            <span className="underline font-italic mr-2">
              {t('scientificProfile.generalInfo.workingAddress.laboratory')}:
            </span>
            <span>{data?.laboratory}</span>
          </div>
          <div>
            <span className="underline font-italic mr-2">
              {t('scientificProfile.generalInfo.workingAddress.position')}:
            </span>
            <span>{data?.position}</span>
          </div>
        </div>

        <div className="flex mb-1">
          <div className="w-20rem">
            <span className="mr-2 font-semibold">
              5. {t('scientificProfile.generalInfo.academicDegree')}:
            </span>
            <span>{data?.academicDegree ? t(`academicDegree.${data?.academicDegree}`) : ''}</span>
          </div>
          <div>
            <span className="mr-2 font-semibold">
              {t('scientificProfile.generalInfo.academicDegreeYear')}:
            </span>
            <span>{data?.academicDegreeYear}</span>
          </div>
        </div>

        <div className="flex mb-1">
          <div className="w-20rem">
            <span className="mr-2 font-semibold">
              6. {t('scientificProfile.generalInfo.academicRank')}:
            </span>
            <span>{data?.academicRank ? t(`academicRank.${data?.academicRank}`) : ''}</span>
          </div>
          <div>
            <span className="mr-2 font-semibold">
              {t('scientificProfile.generalInfo.academicRankYear')}:
            </span>
            <span>{data?.academicRankYear}</span>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            7. {t('scientificProfile.generalInfo.contact.label')}:
          </span>
          <div className="form-table mt-1">
            <table>
              <thead>
                <tr className="font-bold">
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 140, width: 140 }}>
                    {' '}
                  </th>
                  <th className="text-center" style={{ width: '45%' }}>
                    {t('scientificProfile.generalInfo.contact.office')}
                  </th>
                  <th className="text-center float" style={{ width: '45%' }}>
                    {t('scientificProfile.generalInfo.contact.personal')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">1</td>
                  <td className="text-center font-bold">
                    {t('scientificProfile.generalInfo.contact.address')}
                  </td>
                  <td>{data?.officeAddress}</td>
                  <td>{data?.personalAddress}</td>
                </tr>
                <tr>
                  <td className="text-center">2</td>
                  <td className="text-center font-bold">
                    {t('scientificProfile.generalInfo.contact.phone')}
                  </td>
                  <td>{data?.officePhone}</td>
                  <td>{data?.personalPhone}</td>
                </tr>
                <tr>
                  <td className="text-center">3</td>
                  <td className="text-center font-bold">Email</td>
                  <td>{data?.officeEmail}</td>
                  <td>{data?.personalEmail}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            8. {t('scientificProfile.generalInfo.foreignLanguage.label')}
          </span>
          <div className="form-table mt-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '25%' }}>
                    {t('scientificProfile.generalInfo.foreignLanguage.languageName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '17%' }}>
                    {t('scientificProfile.generalInfo.foreignLanguage.listening')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '17%' }}>
                    {t('scientificProfile.generalInfo.foreignLanguage.speaking')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '17%' }}>
                    {t('scientificProfile.generalInfo.foreignLanguage.writing')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '17%' }}>
                    {t('scientificProfile.generalInfo.foreignLanguage.reading')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.foreignLanguages?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.listening ? t(`languageSkillCapacity.${item?.listening}`) : ''}</td>
                    <td>{item?.speaking ? t(`languageSkillCapacity.${item?.speaking}`) : ''}</td>
                    <td>{item?.writing ? t(`languageSkillCapacity.${item?.writing}`) : ''}</td>
                    <td>{item?.reading ? t(`languageSkillCapacity.${item?.reading}`) : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">9. {t('scientificProfile.workHistory.label')}:</span>
          <div className="form-table mt-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: '20%' }}>
                    {t('scientificProfile.workHistory.timePeriod')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '45%' }}>
                    {t('scientificProfile.workHistory.address')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '30%' }}>
                    {t('scientificProfile.workHistory.position')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.workHistory?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                      {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                    </td>
                    <td>{item?.address}</td>
                    <td>{item?.position}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            10. {t('scientificProfile.academicHistory.label')}:
          </span>
          <div className="form-table mt-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: '20%' }}>
                    {t('scientificProfile.academicHistory.timePeriod')}
                  </th>
                  <th className="text-center" style={{ minWidth: 250, width: '25%' }}>
                    {t('scientificProfile.academicHistory.address')}
                  </th>
                  <th className="text-center" style={{ minWidth: 250, width: '25%' }}>
                    {t('scientificProfile.academicHistory.major')}
                  </th>
                  <th className="text-center" style={{ minWidth: 250, width: '25%' }}>
                    {t('scientificProfile.academicHistory.thesisName')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={6}>
                    <div>
                      <span className="font-bold">{t('academicDegree.university')}</span>
                    </div>
                  </td>
                </tr>

                {data?.academicHistory?.universities?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                      {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                    </td>
                    <td>{item?.address}</td>
                    <td>{item?.major}</td>
                    <td>{item?.thesisName}</td>
                  </tr>
                ))}

                <tr>
                  <td colSpan={6}>
                    <div>
                      <span className="font-bold">{t('academicDegree.master')}</span>
                    </div>
                  </td>
                </tr>

                {data?.academicHistory?.masters?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                      {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                    </td>
                    <td>{item?.address}</td>
                    <td>{item?.major}</td>
                    <td>{item?.thesisName}</td>
                  </tr>
                ))}

                <tr>
                  <td colSpan={6}>
                    <span className="font-bold">{t('academicDegree.doctorate')}</span>
                  </td>
                </tr>
                {data?.academicHistory?.doctorates?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                      {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                    </td>
                    <td>{item?.address}</td>
                    <td>{item?.major}</td>
                    <td>{item?.thesisName}</td>
                  </tr>
                ))}

                <tr>
                  <td colSpan={6}>
                    <span className="font-bold">{t('academicDegree.scienceDoctorate')}</span>
                  </td>
                </tr>
                {data?.academicHistory?.scienceDoctorates?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      {item?.start ? new Date(item?.start).toLocaleDateString('en-GB') : ''} -{' '}
                      {item?.end ? new Date(item?.end).toLocaleDateString('en-GB') : ''}
                    </td>
                    <td>{item?.address}</td>
                    <td>{item?.major}</td>
                    <td>{item?.thesisName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            11. {t('scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.label')}:
          </span>
          <span className="font-italic">
            11.1{' '}
            {t(
              'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.label'
            )}
            :
          </span>
          <div className="ml-5">
            <div>
              <span className="font-semibold mr-2">
                -{' '}
                {t(
                  'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.field'
                )}
                :
              </span>
              <span>{data?.researchField}</span>
            </div>
            <div>
              <span className="font-semibold mr-2">
                -{' '}
                {t(
                  'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.major'
                )}
                :
              </span>
              <span>{data?.researchMajor}</span>
            </div>
            <div>
              <span className="font-semibold mr-2">
                -{' '}
                {t(
                  'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.specialty'
                )}
                :
              </span>
              <span>{data?.researchSpecialty}</span>
            </div>
          </div>
          <span className="font-italic">
            11.2{' '}
            {t(
              'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.researchLines.label'
            )}
            :
          </span>
          {data?.researchLines?.map((item, index) => (
            <div className="ml-5">
              <span className="mr-2">{index + 1}.</span>
              <span className="mr-2">{item?.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

GeneralInfoSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

GeneralInfoSection.defaultProps = {
  data: {},
};
