import { useTranslation } from 'react-i18next';

export default function HeaderInfo() {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-content-between">
        <img src="/logo.png" alt="logo" width="180px" />

        <div className="flex flex-column">
          <span>{t('extendImplementationTime.R07.formR07')}</span>
          <span>{t('extendImplementationTime.R07.topicCode')}: ..........</span>
        </div>
      </div>
      <h3 className="mb-5 line-height-3 text-4xl text-center font-bold">
        {t('extendImplementationTime.R07.title')}
        <br />
        <span className="font-normal">{t('extendImplementationTime.R07.subTitle')}</span>
      </h3>
    </>
  );
}
