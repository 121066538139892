import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import CompleteProfile from 'features/Authentication/CompleteProfile';

function CompleteProfilePage() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('websiteName');
  }, [t]);

  return <CompleteProfile />;
}

export default CompleteProfilePage;
