import Layout from 'layout';
import { ProjectCompletedViewList } from 'features/ProjectCompleted/pages';
import { LayoutProvider } from '../layout/context/layoutcontext';

function ProjectCompletedViewListPage() {
  return (
    <LayoutProvider>
      <Layout>
        <ProjectCompletedViewList />
      </Layout>
    </LayoutProvider>
  );
}

export default ProjectCompletedViewListPage;
