import instance from 'utils/axios/instance.axios';
import { SPECIALIZE_REVIEW } from './_constant';

export const getGeneralProjectProposalInfo = (councilId, projectProposalId) =>
  instance.get(SPECIALIZE_REVIEW.GET_PROJECT_PROPOSAL_INFO, {
    params: { councilId, projectProposalId },
  });

export const getReviewerInfo = (councilId, projectProposalId) =>
  instance.get(SPECIALIZE_REVIEW.GET_REVIEWER_INFO, {
    params: { councilId, projectProposalId },
  });

export const getDetail = (councilMemberId, councilId, projectProposalId) =>
  instance.get(SPECIALIZE_REVIEW.GET_DETAIL, {
    params: { councilMemberId, councilId, projectProposalId },
  });

export const create = (data = {}) => instance.post(SPECIALIZE_REVIEW.CREATE, data);

export const update = (data = {}) => instance.patch(SPECIALIZE_REVIEW.UPDATE, data);

export const submit = (data = {}) => instance.patch(SPECIALIZE_REVIEW.SUBMIT, data);

// Download review file

export const getDetailFile = (councilMemberId, councilId, projectProposalId) =>
  instance.get(SPECIALIZE_REVIEW.GET_DETAIL_FILE, {
    params: { councilMemberId, councilId, projectProposalId },
  });

export const downloadFile = (body = {}) =>
  instance.post(SPECIALIZE_REVIEW.DOWNLOAD_FILE, body, { responseType: 'blob' });

export const downloadPdfFile = ({
  projectProposalId,
  councilId,
  councilMemberId,
  isReviewerHidden = false,
}) =>
  instance.get(SPECIALIZE_REVIEW.DOWNLOAD_PDF_FILE, {
    params: {
      projectProposalId,
      councilId,
      councilMemberId,
      isReviewerHidden,
    },
    responseType: 'blob',
  });

export const downloadPdfTemplate = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.get(SPECIALIZE_REVIEW.DOWNLOAD_PDF_TEMPLATE, {
    params: {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    responseType: 'blob',
  });

export const downloadDocxFile = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.get(SPECIALIZE_REVIEW.DOWNLOAD_DOCX_FILE, {
    params: {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    responseType: 'blob',
  });

export const downloadDocxTemplate = ({ projectProposalId, councilId, councilMemberId }) =>
  instance.get(SPECIALIZE_REVIEW.DOWNLOAD_DOCX_TEMPLATE, {
    params: {
      projectProposalId,
      councilId,
      councilMemberId,
    },
    responseType: 'blob',
  });
