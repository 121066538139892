import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MaskInput, TextareaInput, TextInput } from 'components/FormControl';

import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { defaultValues } from 'features/ProjectProposal/defaultValues';

export default function RecommendedExpertsTable({ disabled, control, errors }) {
  // #region Data
  const { t } = useTranslation();
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'general.recommendedExperts',
  });

  // #endregion Data
  return (
    <>
      <h4>{t('projectProposal.general.recommendedExpert.label')}</h4>
      <div className="form-table">
        <table className="mb-2">
          <thead>
            <tr>
              <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                {t('formLayout.numericalOrder')}
              </th>
              <th className="text-center" style={{ minWidth: 150, width: 150 }}>
                {t('projectProposal.general.recommendedExpert.fullname')}
              </th>
              <th className="text-center" style={{ minWidth: 220, width: '50%' }}>
                {t('projectProposal.general.recommendedExpert.researchLine')}
              </th>
              <th className="text-center" style={{ minWidth: 220, width: '50%' }}>
                {t('projectProposal.general.recommendedExpert.workplace')}
              </th>
              <th className="text-center" style={{ minWidth: 220, width: 220 }}>
                {t('projectProposal.general.recommendedExpert.email')}
              </th>
              <th className="text-center" style={{ minWidth: 120, width: 120 }}>
                {t('projectProposal.general.recommendedExpert.phone')}
              </th>
              {!disabled && (
                <th className="text-center float" style={{ minWidth: 60, width: 60 }}>
                  {t('projectProposal.action')}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {fields?.map((item, index) => (
              <tr key={item.id}>
                <td className="text-center">{index + 1}</td>
                <td>
                  <TextareaInput
                    name={`general.recommendedExperts.${index}.fullname`}
                    control={control}
                    errors={errors}
                  />
                </td>
                <td>
                  <TextareaInput
                    name={`general.recommendedExperts.${index}.researchLine`}
                    control={control}
                    errors={errors}
                  />
                </td>
                <td>
                  <TextareaInput
                    name={`general.recommendedExperts.${index}.workplace`}
                    control={control}
                    errors={errors}
                  />
                </td>
                <td>
                  <TextInput
                    name={`general.recommendedExperts.${index}.email`}
                    control={control}
                    errors={errors}
                  />
                </td>
                <td>
                  <MaskInput
                    name={`general.recommendedExperts.${index}.phone`}
                    control={control}
                    errors={errors}
                    mask={''.padStart(10, '9')}
                  />
                </td>
                {!disabled && (
                  <td className="text-center p-0 float">
                    <button
                      className="p-button p-button-text p-button-icon-only p-0"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <i
                        className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                        data-pr-tooltip={t('formLayout.action.remove')}
                        data-pr-position="left"
                      />
                    </button>
                    <Tooltip target=".remove-icon" />

                    <div className="">
                      <button
                        className="p-button p-button-text p-button-icon-only p-0"
                        type="button"
                        onClick={() => insert(index + 1, defaultValues.recommendedExpert)}
                      >
                        <i
                          className="insert-icon pi pi-plus text-primary cursor-pointer mt-1"
                          data-pr-tooltip={t('formLayout.action.insert')}
                          data-pr-position="left"
                        />
                      </button>
                      <Tooltip target=".insert-icon" />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {!disabled && fields?.length === 0 && (
          <div className="flex justify-content-center my-2">
            <i
              onClick={() => insert(0, defaultValues.recommendedExpert)}
              className="add-recommended-expert pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
              data-pr-tooltip={t('formLayout.action.add')}
              data-pr-position="left"
            />
            <Tooltip target=".add-recommended-expert" />
          </div>
        )}
      </div>
    </>
  );
}

RecommendedExpertsTable.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
};

RecommendedExpertsTable.defaultProps = {
  disabled: false,
};
