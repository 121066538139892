import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

export default function KPITable({ kpis }) {
  // #region Data
  const { t } = useTranslation();

  const groupedKpis = useMemo(() => {
    const groups = [];

    kpis?.forEach((kpi) => {
      const key = kpi?.researchKpi?.researchKpiGroup.name;
      if (!groups[key]) groups[key] = [];
      groups[key].push(kpi);
    });

    return Object.values(groups);
  }, [kpis]);
  // #endregion

  return (
    <div className="grid">
      <div className="col-12">
        <div
          className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines"
          data-scrollselectors=".p-datatable-wrapper"
        >
          <div className="p-datatable-wrapper">
            <table className="p-datatable-table">
              <thead className="p-datatable-thead">
                <tr>
                  <th className="max-w-3rem text-center">
                    {t('projectProposal.researchResult.a10_2.fields.order')}
                  </th>
                  <th className="max-w-15rem text-center">
                    {t('projectProposal.researchResult.a10_2.fields.KPI')}
                  </th>
                  <th className="max-w-6rem text-center">
                    {t('projectProposal.researchResult.a10_2.fields.numberRegistration')}
                  </th>
                  <th className="text-center">
                    {t('projectProposal.researchResult.a10_2.fields.content')}
                  </th>
                </tr>
              </thead>
              {groupedKpis?.map((kpiGroup) => (
                <tbody
                  className="p-datatable-tbody"
                  key={kpiGroup[0]?.researchKpi?.researchKpiGroup?.id}
                >
                  {kpiGroup?.map((kpi, index) => (
                    <tr key={kpi?.id ?? index}>
                      {index === 0 ? (
                        <td className="max-w-3rem text-center font-bold" rowSpan={kpiGroup?.length}>
                          {kpi?.researchKpi?.researchKpiGroup?.name}
                        </td>
                      ) : (
                        <td className="hidden" />
                      )}
                      <td className="max-w-15rem">
                        {t('lang') === 'vi'
                          ? kpi?.researchKpi?.name
                          : kpi?.researchKpi?.englishName ||
                            t(`projectProposal.researchResult.a10_2.kpi.${kpi?.researchKpi?.code}`)}
                      </td>
                      <td className="max-w-4rem text-center">{kpi?.quantity || ''}</td>
                      <td>{kpi?.content}</td>
                    </tr>
                  ))}
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

KPITable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  kpis: PropTypes.array.isRequired,
};
