import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextareaInput } from 'components/FormControl';

import { defaultValues } from 'features/ProjectProposal/defaultValues';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';

export default function SoftProductsTable({ disabled, control, errors }) {
  // #region Data
  const { t } = useTranslation();

  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'researchResult.softProducts',
  });
  // #endregion Data
  return (
    <>
      <h4>{t('projectProposal.researchResult.a10_1.softProduct.label')}</h4>
      <p>({t('projectProposal.researchResult.a10_1.softProduct.note')})</p>

      <div className="form-table">
        <table className="mb-2">
          <thead>
            <tr>
              <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                {t('formLayout.numericalOrder')}
              </th>
              <th className="text-center" style={{ minWidth: 300, width: 300 }}>
                {t('projectProposal.researchResult.a10_1.softProduct.fields.productName')}
              </th>
              <th className="text-center" style={{ minWidth: 300, width: 300 }}>
                {t('projectProposal.researchResult.a10_1.softProduct.fields.evaluativeCriteria')}
              </th>
              <th className="text-center" style={{ minWidth: 300, width: '100%' }}>
                {t('projectProposal.researchResult.a10_1.softProduct.fields.note')}
              </th>
              {!disabled && (
                <th className="float text-center" style={{ minWidth: 80, width: 80 }}>
                  {t('projectProposal.action')}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {fields?.map((item, index) => (
              <tr key={item.id}>
                <td className="text-center">{index + 1}</td>
                <td>
                  <TextareaInput
                    name={`researchResult.softProducts.${index}.name`}
                    control={control}
                    errors={errors}
                  />
                </td>
                <td>
                  <TextareaInput
                    name={`researchResult.softProducts.${index}.evaluativeCriteria`}
                    control={control}
                    errors={errors}
                  />
                </td>
                <td>
                  <TextareaInput
                    name={`researchResult.softProducts.${index}.note`}
                    control={control}
                    errors={errors}
                  />
                </td>
                {!disabled && (
                  <td className="float text-center p-0">
                    <i
                      onClick={() => remove(index)}
                      className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                      data-pr-tooltip={t('formLayout.action.remove')}
                      data-pr-position="left"
                    />
                    <Tooltip target=".remove-icon" />

                    <div className="">
                      <i
                        onClick={() => {
                          insert(index + 1, defaultValues.softProduct);
                        }}
                        className="insert-icon pi pi-plus text-primary cursor-pointer mt-1"
                        data-pr-tooltip={t('formLayout.action.insert')}
                        data-pr-position="left"
                      />
                      <Tooltip target=".insert-icon" />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!disabled && fields?.length === 0 && (
        <div className="flex justify-content-center mt-2">
          <i
            onClick={() => {
              insert(0, defaultValues.softProduct);
            }}
            className="add-soft-product pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
            data-pr-tooltip={t('formLayout.action.add')}
            data-pr-position="left"
          />
          <Tooltip target=".add-soft-product" />
        </div>
      )}
    </>
  );
}

SoftProductsTable.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
};

SoftProductsTable.defaultProps = {
  disabled: false,
};
