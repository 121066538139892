import UserProfile from 'features/UserProfile';
import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

function UserProfilePage() {
  return (
    <LayoutProvider>
      <Layout
        style={{
          position: 'relative',
          height: '80vh',
          overflowY: 'auto',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
        }}
      >
        <UserProfile />
      </Layout>
    </LayoutProvider>
  );
}

export default UserProfilePage;
