import { useFieldArray, useFormContext } from 'react-hook-form';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { classNames } from 'primereact/utils';
import GuidingActivityDialog from './GuidingActivityDialog';

export default function GuidingActivitiesForm({ control, shouldUpdateFromDocx }) {
  // #region Data
  const { t } = useTranslation();
  const { dirtyFields } = useFormContext();

  const guidingActivityRef = useRef();

  const { fields, insert, update, remove } = useFieldArray({
    control,
    name: 'guidingActivities',
  });

  const [expandedRows, setExpandedRows] = useState(null);
  // #endregion Data

  // #region Event
  // #endregion Event

  // #region Render
  const formatIndex = (_, { rowIndex }) => rowIndex + 1;

  const formatTrainingLevel = (rowData) =>
    rowData?.academicDegree ? t(`academicDegree.${rowData?.academicDegree}`) : '';

  const formatActions = (_, { rowIndex }) => (
    <td className="flex flex-column float text-center p-0 border-none">
      <i
        onClick={() => {
          guidingActivityRef.current.open({
            insert,
            index: rowIndex + 1,
            type: 'INSERT',
          });
        }}
        className="insert-icon pi pi-plus text-primary cursor-pointer my-1"
        data-pr-tooltip={t('formLayout.action.insert')}
        data-pr-position="left"
      />
      <Tooltip target=".insert-icon" />

      <i
        onClick={() => {
          guidingActivityRef.current.open({
            update,
            index: rowIndex,
            value: fields[rowIndex],
            type: 'UPDATE',
          });
        }}
        className="edit-icon pi pi-pencil text-primary cursor-pointer mb-1"
        data-pr-tooltip={t('formLayout.action.edit')}
        data-pr-position="left"
      />
      <Tooltip target=".edit-icon" />

      <i
        onClick={() => remove(rowIndex)}
        className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
        data-pr-tooltip={t('formLayout.action.remove')}
        data-pr-position="left"
      />
      <Tooltip target=".remove-icon" />
    </td>
  );

  const rowExpansionTemplate = (rowData) => (
    <div className="m-2 text-lg">
      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.guidingActivity.studentTarget')}:{' '}
          </strong>
          {rowData.studentTarget ? t(`studentTarget.${rowData.studentTarget}`) : ''}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.guidingActivity.fullname')}:{' '}
          </strong>
          {rowData.studentName}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.guidingActivity.studentId')}:{' '}
          </strong>
          {rowData.studentId}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.guidingActivity.graduationYear')}:{' '}
          </strong>
          {rowData.graduationYear}
        </div>
      </div>

      <div className="grid">
        <div className="col-12">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.guidingActivity.thesisName')}:{' '}
          </strong>
          {rowData.thesisName}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.guidingActivity.trainingLevel')}:{' '}
          </strong>
          {rowData.academicDegree ? t(`academicDegree.${rowData.academicDegree}`) : ''}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.guidingActivity.projectCode')}:{' '}
          </strong>
          {rowData.projectCode}
        </div>
      </div>
    </div>
  );

  const rowHighlight = () => {
    let className = '';
    if (shouldUpdateFromDocx && dirtyFields?.guidingActivities) {
      className = 'highlighted-uncertain';
    }
    return className;
  };
  // #endregion Render

  return (
    <>
      <h4 className="text-center mb-4">{t('scientificProfile.guidingActivity.label')}</h4>

      <DataTable
        value={fields}
        className={classNames('p-datatable-gridlines header-table', {
          highlighted: shouldUpdateFromDocx && dirtyFields.guidingActivities?.[0]?.studentName,
        })}
        rowClassName={rowHighlight}
        showGridlines
        filterDisplay="menu"
        loading={typeof fields?.length !== 'number'}
        scrollable
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        emptyMessage=" "
      >
        <Column expander style={{ width: '2rem' }} />
        <Column
          header={t('formLayout.numericalOrder')}
          headerClassName="text-center"
          className="text-center"
          body={formatIndex}
          style={{ minWidth: 50, width: 50 }}
          expander
        />
        <Column
          header={t('scientificProfile.guidingActivity.studentName')}
          headerClassName="text-center"
          field="studentName"
          style={{ minWidth: 180, width: '18%' }}
        />
        <Column
          header={t('scientificProfile.guidingActivity.thesisName')}
          headerClassName="text-center"
          field="thesisName"
          style={{ minWidth: 300, width: '30%' }}
        />
        <Column
          header={t('scientificProfile.guidingActivity.graduationYear')}
          headerClassName="text-center"
          field="graduationYear"
          style={{ minWidth: 120, width: '12%' }}
        />
        <Column
          header={t('scientificProfile.guidingActivity.trainingLevel')}
          headerClassName="text-center"
          body={formatTrainingLevel}
          style={{ minWidth: 100, width: '10%' }}
        />
        <Column
          header={t('scientificProfile.guidingActivity.projectCode')}
          headerClassName="text-center"
          field="projectCode"
          style={{ minWidth: 200, width: '20%' }}
        />
        <Column
          header={t('formLayout.action.label')}
          headerClassName="text-center"
          style={{ minWidth: 80, width: 80 }}
          body={formatActions}
          frozen
          alignFrozen="right"
        />
      </DataTable>

      {fields?.length === 0 && (
        <div className="flex justify-content-center my-2">
          <i
            onClick={() => {
              guidingActivityRef.current.open({
                insert,
                index: 0,
                type: 'INSERT',
              });
            }}
            className="add-guiding-activity pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
            data-pr-tooltip={t('formLayout.action.add')}
            data-pr-position="left"
          />
          <Tooltip target=".add-guiding-activity" />
        </div>
      )}

      <GuidingActivityDialog ref={guidingActivityRef} />
    </>
  );
}

GuidingActivitiesForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  shouldUpdateFromDocx: PropTypes.bool,
};

GuidingActivitiesForm.defaultProps = {
  shouldUpdateFromDocx: false,
};
