export const defaultGeneralInfoValues = {
  vietnameseName: '',
  englishName: '',
  projectType: '',
  projectProposalResearchFields: [],
  recommendedExperts: [],
  researchType: {},
  implementationMonths: '',
  totalExpenditure: '',
  nationalUniversityTotalExpenditure: '',
  nationalUniversityAllocatedExpenditure: '',
  nationalUniversityNonallocatedExpenditure: '',
  fundingExpenditure: '',
  fundingExpenditureSelf: '',
  fundingExpenditureSelfFileUrl: '',
  fundingExpenditureOther: '',
  fundingExpenditureOtherFileUrl: '',
  otherSponsorOrganization: '',
  projectProposalOrganizations: [],
  projectProposalContacts: [],
};

export const defaultResearchResultValues = {
  softProducts: [],
  hardProducts: [],
  qualityComparison: '',
  projectProposalResearchKpis: [],
};

export const defaultExpenditureValues = {
  proposedExpenditureSummaries: [],
};

export const defaultDocumentsValues = {
  researchDescriptionFileUrl: '',
  expenditureFileUrl: '',
};
