import { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FileInput from 'components/FormControl/FileInput';
import { formatFileName } from 'utils/func';

import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

export default function ResearchDescriptionForm({
  getValues,
  disabled,
  handleDownloadFile,
  control,
  errors,
}) {
  // #region Data
  const { t } = useTranslation();
  const uploadedFileUrl = useMemo(() => getValues('researchDescription.uploadedFileUrl'));
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'researchDescription.otherProofsAndAppendicesFiles',
  });
  // #endregion Data

  // #region Event
  // #endregion Event

  return (
    <div>
      <h4 className="text-center mb-4">{t('projectProposal.researchDescription.label')}</h4>

      <h5 className="mb-1">{t('projectProposal.researchDescription.requirement')}:</h5>
      <span className="font-italic text-red-500">
        ({t('projectProposal.researchDescription.note')})
      </span>
      <ul className="list-disc">
        <li>
          <span className="font-normal text-lg">{t('projectProposal.researchDescription.b1')}</span>
        </li>
        <li>
          <span className="font-normal text-lg">{t('projectProposal.researchDescription.b2')}</span>
        </li>
        <li>
          <span className="font-normal text-lg">{t('projectProposal.researchDescription.b3')}</span>
        </li>
        <li>
          <span className="font-normal text-lg">{t('projectProposal.researchDescription.b4')}</span>
        </li>
        <li>
          <span className="font-normal text-lg">{t('projectProposal.researchDescription.b5')}</span>
        </li>
      </ul>
      <div className="pl-4">
        {!disabled && (
          <div className="mt-5">
            <div className="text-xl font-semibold">
              {t('projectProposal.researchDescription.templateFile')}:{'    '}
            </div>
            <ul className="list-disc">
              <li>
                <span className="font-normal text-lg">
                  {t('projectProposal.researchDescription.nationalUniversityTypeABC')}:{' '}
                </span>
                <a
                  className="text-blue-500 font-italic text-lg font-medium"
                  href="/files/R01_Thuyet-minh-VNU-cap-nhat 2022-kh4-Muc-B.docx"
                  download
                >
                  {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
                </a>
              </li>
              <li>
                <span className="font-normal text-lg">
                  {t('projectProposal.researchDescription.nationalUniversityScienceProject')}:{' '}
                </span>
                <a
                  className="text-blue-500 font-italic text-lg font-medium"
                  href="/files/R01_DHQG_DeAn.docx"
                  download
                >
                  {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
                </a>
              </li>
              <li>
                <span className="font-normal text-lg">
                  {t('projectProposal.researchDescription.nationalUniversityResearchGroup')}:{' '}
                </span>
                <a
                  className="text-blue-500 font-italic text-lg font-medium"
                  href="/files/R01_DHQG_NhomNghienCuuManh.docx"
                  download
                >
                  {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
                </a>
              </li>
              <li>
                <span className="font-normal text-lg">
                  {t('projectProposal.researchDescription.nationalUniversityRegularDuty')}:{' '}
                </span>
                <a
                  className="text-blue-500 font-italic text-lg font-medium"
                  href="/files/R01_DHQG_NhiemVuThuongXuyen.docx"
                  download
                >
                  {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
                </a>
              </li>
              <li>
                <span className="font-normal text-lg">
                  {t('projectProposal.researchDescription.nationalUniversityMaintainProject')}:{' '}
                </span>
                <a
                  className="text-blue-500 font-italic text-lg font-medium"
                  href="/files/R01_DHQG_DuyTuBaoDuong.docx"
                  download
                >
                  {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
                </a>
              </li>
              <li>
                <span className="font-normal text-lg">
                  {t('projectProposal.researchDescription.nationalLevelA1')}:{' '}
                </span>
                <a
                  className="text-blue-500 font-italic text-lg font-medium"
                  href="/files/CapQuocGia-Mau-A1-De-tai.docx"
                  download
                >
                  {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
                </a>
              </li>
              <li>
                <span className="font-normal text-lg">
                  {t('projectProposal.researchDescription.nationalLevelA2')}:{' '}
                </span>
                <a
                  className="text-blue-500 font-italic text-lg font-medium"
                  href="/files/CapQuocGia-Mau-A2-DA-SXTN.docx"
                  download
                >
                  {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
                </a>
              </li>
              <li>
                <span className="font-normal text-lg">
                  {t('projectProposal.researchDescription.nationalLevelA3')}:{' '}
                </span>
                <a
                  className="text-blue-500 font-italic text-lg font-medium"
                  href="/files/CapQuocGia-Mau-A3-De-an.docx"
                  download
                >
                  {t('formLayout.action.download')} <i className="pi pi-download text-blue-500" />
                </a>
              </li>
            </ul>

            <FileInput
              name="researchDescription.file"
              accept=".pdf"
              control={control}
              errors={errors}
              acceptOnly="pdfOnly"
            />
          </div>
        )}

        {uploadedFileUrl && (
          <span
            className="p-button p-button-text p-button-sm mt-1 p-0"
            onClick={() => handleDownloadFile(uploadedFileUrl)}
          >
            <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
            <span className="text-blue-500 underline font-italic text-base">
              {formatFileName(uploadedFileUrl)}
            </span>
          </span>
        )}
      </div>

      <h5>{t('projectProposal.researchDescription.otherProofsAndAppendices')}</h5>
      {fields?.map((item, index) => (
        <div className="relative pl-4" key={item.id}>
          {!disabled && <hr className="border border-1 border-primary my-1" />}
          <span className="font-bold">
            {t('formLayout.numericalOrder')}: {index + 1}
          </span>

          {!disabled && (
            <FileInput
              name={`researchDescription.otherProofsAndAppendicesFiles.${index}.file`}
              accept=".pdf"
              control={control}
              errors={errors}
              acceptOnly="pdfOnly"
            />
          )}

          {item?.fileName && (
            <span
              className="p-button p-button-text p-button-sm mt-1 mb-3 p-0"
              onClick={() => handleDownloadFile(item?.fileName)}
            >
              <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
              <span className="text-blue-500 underline font-italic text-base">
                {formatFileName(item?.fileName)}
              </span>
            </span>
          )}

          {!disabled && (
            <button
              className="p-button p-button-text p-button-icon-only p-0 absolute top-0 right-0 mt-2"
              type="button"
              onClick={() => remove(index)}
              disabled={disabled}
            >
              <i type="button" className="pi pi-times text-red-500 text-xl" />
            </button>
          )}
        </div>
      ))}
      {!disabled && fields?.length < 5 && (
        <div className="pl-4">
          <hr className="border border-1 border-primary mt-1 mb-1" />
          <div className="flex justify-content-center">
            <button
              className="p-button p-button-text p-button-icon-only p-0"
              type="button"
              onClick={() => {
                append({
                  id: uuidv4(),
                  file: null,
                });
              }}
              disabled={disabled}
            >
              <i type="button" className="pi pi-fw pi-plus text-primary text-xl" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

ResearchDescriptionForm.propTypes = {
  getValues: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  handleDownloadFile: PropTypes.func,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};

ResearchDescriptionForm.defaultProps = {
  disabled: false,
  handleDownloadFile: () => null,
};
