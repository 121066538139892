import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import PropTypes from 'prop-types';

export default function AboutTab({ data }) {
  // #region Data
  const { t } = useTranslation();

  // #endregion Data

  // #region Event

  // #endregion Event

  // #region Render
  const formatIndex = (_, { rowIndex }) => rowIndex + 1;
  const customTimePeriod = (rowData) => {
    const startDate = rowData?.start ? new Date(rowData?.start).toLocaleDateString('en-GB') : '';
    const endDate = rowData?.end ? new Date(rowData?.end).toLocaleDateString('en-GB') : '';
    return `${startDate} - ${endDate}`;
  };

  const legendTemplate = (icon, label) => (
    <div className="flex align-items-center">
      <span className={icon} />
      <span className="font-bold text-base">{label}</span>
    </div>
  );

  const itemGenneralInfo = (icon, label, content) => (
    <div className="text-base mt-2">
      <span className={icon} style={{ color: '#0000ff' }} />
      <strong style={{ color: '#0000ff' }}>{label}: </strong>
      {content}
    </div>
  );

  // const handleLanguageData = (field) => (rowData) =>
  //   rowData[field] ? t(`languageSkillCapacity.${rowData[field]}`) : 'N/A';

  return (
    <>
      <Fieldset
        legend={legendTemplate('pi pi-user mr-2', t('scientificProfile.generalInfo.label'))}
      >
        {data?.description?.length > 0 && <div>{data?.description}</div>}

        {/* {data?.fullname &&
          itemGenneralInfo(
            'pi pi-user mr-2',
            t('scientificProfile.generalInfo.fullname'),
            data?.fullname
          )}
        {data?.academicDegree &&
          itemGenneralInfo(
            'pi pi-id-card mr-2',
            t('scientificProfile.generalInfo.academicDegree'),
            `${t(`academicDegree.${data?.academicDegree}`)} - ${data?.academicDegreeYear} `
          )}
        {data?.academicRank &&
          itemGenneralInfo(
            'pi pi-id-card mr-2',
            t('scientificProfile.generalInfo.academicRank'),
            `${t(`academicRank.${data?.academicRank}`)} - ${data?.academicRankYear} `
          )} */}
        {/* {data?.gender &&
          itemGenneralInfo(
            'pi pi-key mr-2',
            t('scientificProfile.generalInfo.gender'),
            data?.gender === 'male' ? t('gender.male') : t('gender.female')
          )}
        {data?.dob &&
          itemGenneralInfo(
            'pi pi-calendar mr-2',
            t('scientificProfile.generalInfo.dob'),
            new Date(data?.dob).toLocaleDateString('en-GB')
          )} */}
        <hr
          style={{
            width: '100%',
            marginTop: '1rem',
            marginBottom: '1rem',
            height: '0.5px',
            background: 'darkgray',
            opacity: '0.5',
          }}
        />
        {data?.organizationName &&
          itemGenneralInfo(
            'pi pi-briefcase mr-2',
            t('scientificProfile.generalInfo.workingAddress.institute'),
            t('lang') === 'vi' ? data?.organizationName : data?.organizationEnglishName
          )}
        {data?.faculty &&
          itemGenneralInfo(
            'pi pi-building mr-2',
            t('scientificProfile.generalInfo.workingAddress.faculty'),
            data?.faculty
          )}
        {data?.department &&
          itemGenneralInfo(
            'pi pi-folder mr-2',
            t('scientificProfile.generalInfo.workingAddress.department'),
            data.department
          )}
        {data?.laboratory &&
          itemGenneralInfo(
            'pi pi-tag mr-2',
            t('scientificProfile.generalInfo.workingAddress.laboratory'),
            data?.laboratory
          )}
        {data?.position &&
          itemGenneralInfo(
            'pi pi-id-card mr-2',
            t('scientificProfile.generalInfo.workingAddress.position'),
            data?.position
          )}

        {itemGenneralInfo(
          'pi pi-book mr-2',
          t('scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.label')
        )}
        <div className="flex flex-column">
          <span className="font-bold ml-5 mt-2">
            -{' '}
            {t(
              'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.label'
            )}
            :
          </span>
          <div className="ml-5">
            <ul className="m-1">
              {data?.researchField && (
                <li>
                  <span className="font-semibold mr-2">
                    {t(
                      'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.field'
                    )}
                    :
                  </span>
                  <span>{data?.researchField}</span>
                </li>
              )}
              {data?.researchMajor && (
                <li>
                  <span className="font-semibold mr-2">
                    {t(
                      'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.major'
                    )}
                    :
                  </span>
                  <span>{data?.researchMajor}</span>
                </li>
              )}

              {data?.researchSpecialty && (
                <li>
                  <span className="font-semibold mr-2">
                    {t(
                      'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.specialty'
                    )}
                    :
                  </span>
                  <span>{data?.researchSpecialty}</span>
                </li>
              )}
            </ul>
          </div>
          <span className="font-bold ml-5 mt-2">
            -{' '}
            {t(
              'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.researchLines.label'
            )}
            :
          </span>

          <div className="ml-5">
            <ul className="m-1">
              {data?.researchLines?.map((item) => (
                <li>{item?.name}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* <hr />
        {itemGenneralInfo(
          'pi pi-language mr-2',
          t('scientificProfile.generalInfo.foreignLanguage.label')
        )}

        {data?.foreignLanguages && (
          <DataTable
            className="mt-2"
            size="small"
            value={data?.foreignLanguages}
            showGridlines
            tableStyle={{ fontSize: '1rem' }}
            tabIndex={0}
          >
            <Column header={t('formLayout.numericalOrder')} body={formatIndex} />

            <Column
              field="name"
              header={t('scientificProfile.generalInfo.foreignLanguage.languageName')}
            />
            <Column
              field="listening"
              header={t('scientificProfile.generalInfo.foreignLanguage.listening')}
              body={handleLanguageData('listening')}
            />
            <Column
              field="speaking"
              header={t('scientificProfile.generalInfo.foreignLanguage.speaking')}
              body={handleLanguageData('speaking')}
            />
            <Column
              field="writing"
              header={t('scientificProfile.generalInfo.foreignLanguage.writing')}
              body={handleLanguageData('writing')}
            />
            <Column
              field="reading"
              header={t('scientificProfile.generalInfo.foreignLanguage.reading')}
              body={handleLanguageData('reading')}
            />
          </DataTable>
        )} */}
      </Fieldset>

      {data?.academicHistory && (
        <Fieldset
          className="mt-4"
          legend={legendTemplate('pi pi-book mr-2', t('scientificProfile.academicHistory.label'))}
        >
          {data?.academicHistory?.universities?.length > 0 && (
            <>
              {itemGenneralInfo('', t('academicDegree.university'))}
              <DataTable
                value={data?.academicHistory?.universities}
                className="mt-2"
                size="small"
                showGridlines
                tableStyle={{ fontSize: '1rem' }}
              >
                <Column header={t('formLayout.numericalOrder')} body={formatIndex} />
                <Column
                  body={customTimePeriod}
                  header={t('scientificProfile.academicHistory.timePeriod')}
                />
                <Column field="address" header={t('scientificProfile.academicHistory.address')} />
                <Column field="major" header={t('scientificProfile.academicHistory.major')} />
                <Column
                  field="thesisName"
                  header={t('scientificProfile.academicHistory.thesisName')}
                />
              </DataTable>
            </>
          )}
          {data?.academicHistory?.masters?.length > 0 && (
            <>
              {itemGenneralInfo('', t('academicDegree.master'))}
              <DataTable
                value={data?.academicHistory?.masters}
                className="mt-2"
                size="small"
                showGridlines
                tableStyle={{ fontSize: '1rem' }}
              >
                <Column header={t('formLayout.numericalOrder')} body={formatIndex} />
                <Column
                  body={customTimePeriod}
                  header={t('scientificProfile.academicHistory.timePeriod')}
                />
                <Column field="address" header={t('scientificProfile.academicHistory.address')} />
                <Column field="major" header={t('scientificProfile.academicHistory.major')} />
                <Column
                  field="thesisName"
                  header={t('scientificProfile.academicHistory.thesisName')}
                />
              </DataTable>
            </>
          )}
          {data?.academicHistory?.doctorates?.length > 0 && (
            <>
              {itemGenneralInfo('', t('academicDegree.doctorate'))}
              <DataTable
                value={data?.academicHistory?.doctorates}
                className="mt-2"
                size="small"
                showGridlines
                tableStyle={{ fontSize: '1rem' }}
              >
                <Column header={t('formLayout.numericalOrder')} body={formatIndex} />
                <Column
                  body={customTimePeriod}
                  header={t('scientificProfile.academicHistory.timePeriod')}
                />
                <Column field="address" header={t('scientificProfile.academicHistory.address')} />
                <Column field="major" header={t('scientificProfile.academicHistory.major')} />
                <Column
                  field="thesisName"
                  header={t('scientificProfile.academicHistory.thesisName')}
                />
              </DataTable>
            </>
          )}
          {data?.academicHistory?.scienceDoctorates?.length > 0 && (
            <>
              {itemGenneralInfo('', t('academicDegree.scienceDoctorate'))}
              <DataTable
                value={data?.academicHistory?.scienceDoctorates}
                className="mt-2"
                size="small"
                showGridlines
                tableStyle={{ fontSize: '1rem' }}
              >
                <Column header={t('formLayout.numericalOrder')} body={formatIndex} />
                <Column
                  body={customTimePeriod}
                  header={t('scientificProfile.academicHistory.timePeriod')}
                />
                <Column field="address" header={t('scientificProfile.academicHistory.address')} />
                <Column field="major" header={t('scientificProfile.academicHistory.major')} />
                <Column
                  field="thesisName"
                  header={t('scientificProfile.academicHistory.thesisName')}
                />
              </DataTable>
            </>
          )}
        </Fieldset>
      )}

      {/* <Fieldset
        className="mt-4"
        legend={legendTemplate('pi pi-briefcase mr-2', t('scientificProfile.workHistory.label'))}
        toggleable
      >
        {data?.workHistory && (
          <Timeline
            value={reversedWorkHistory}
            align="alternate"
            className="customized-timeline"
            content={customizedContent}
          />
        )}
      </Fieldset> */}
    </>
  );
}

AboutTab.propTypes = {
  data: PropTypes.shape(),
};

AboutTab.defaultProps = {
  data: {},
};
