import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

import { ProjectProposalCreate } from 'features/ProjectProposal/pages';

import { ScrollTop } from 'primereact/scrolltop';

function ProjectProposalCreatePage() {
  return (
    <LayoutProvider>
      <Layout
        style={{
          maxHeight: 'calc(100vh - 9rem)',
          overflowY: 'auto',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
          backgroundColor: 'white',
        }}
      >
        <>
          <ProjectProposalCreate />
          <ScrollTop target="parent" />
        </>
      </Layout>
    </LayoutProvider>
  );
}

export default ProjectProposalCreatePage;
