/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable indent */
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { useQuery } from 'react-query';
import { useMemo, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { NumberInput, TextInput, Tickbox } from 'components/FormControl';
import { getProjectById } from 'apis/project.api';
import { getInspectionResult } from 'apis/registerResult';
import Loading from 'components/Loading';
import GeneralInfoCheckResult from 'features/CheckResult/components/GeneralInfo';

function DetailCheckResult() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const {
    control,
    setValue,
    formState: { errors },
  } = useForm();

  // #region Data

  const B1Options = Array.from({ length: 13 }, (_, index) => index + 1);
  const B2Options = Array.from({ length: 4 }, (_, index) => index + 1);
  const B3Options = [
    { id: 1, quantity: 4, sub: Array.from({ length: 4 }, (_, index) => index + 1) },
    { id: 2, quantity: 3, sub: Array.from({ length: 3 }, (_, index) => index + 1) },
    { id: 3, quantity: 4, sub: Array.from({ length: 4 }, (_, index) => index + 1) },
    { id: 4, quantity: 2, sub: Array.from({ length: 2 }, (_, index) => index + 1) },
    { id: 5, quantity: 1, sub: Array.from({ length: 1 }, (_, index) => index + 1) },
    { id: 6, quantity: 1, sub: Array.from({ length: 1 }, (_, index) => index + 1) },
    { id: 7, quantity: 1, sub: Array.from({ length: 1 }, (_, index) => index + 1) },
    { id: 8, quantity: 1, sub: Array.from({ length: 1 }, (_, index) => index + 1) },
    { id: 9, quantity: 1, sub: Array.from({ length: 1 }, (_, index) => index + 1) },
    { id: 10, quantity: 1, sub: Array.from({ length: 1 }, (_, index) => index + 1) },
    { id: 11, quantity: 1, sub: Array.from({ length: 1 }, (_, index) => index + 1) },
    { id: 12, quantity: 2, sub: Array.from({ length: 2 }, (_, index) => index + 1) },
  ];

  const { data: _dataRegisterResult, isLoading: isRegisterResultLoading } = useQuery({
    queryKey: ['registerResult', projectId],
    queryFn: () => getInspectionResult(projectId),
  });

  const registerData = useMemo(() => _dataRegisterResult?.data ?? [], [_dataRegisterResult]);

  const { data: _data, isLoading: isProjectDataLoading } = useQuery({
    queryKey: ['projectData', projectId],
    queryFn: () => getProjectById(projectId),
  });

  const projectData = useMemo(() => _data?.data ?? [], [_data]);

  const owner = useMemo(
    () =>
      projectData?.projectProposal?.projectProposalContacts?.filter(
        (ppContact) => ppContact.projectRole.code === 'OWNER' ?? null
      ),
    [projectData]
  );

  // #endregion

  // #region Envent

  const handleConclusion = (conclusion) => {
    if (conclusion === 'qualified') {
      return [true, false];
    }
    if (conclusion === 'notQualified') {
      return [false, true];
    }
    return null;
  };

  // #endregion

  // #region Render
  useEffect(() => {
    const B1 = registerData?.data?.B1;
    if (B1) {
      let index = 1;
      for (const key in B1) {
        setValue(`B1.owner.${index}`, B1[key].owner);
        const conclusion = handleConclusion(B1[key].conclusion);
        if (conclusion) {
          setValue(`B1.qualified.${index}`, conclusion[0]);
          setValue(`B1.notQualified.${index}`, conclusion[1]);
        }
        setValue(`B1.review.${index}`, B1[key].review);
        index += 1;
      }
    }
    const B2 = registerData?.data?.B2;
    if (B2) {
      setValue('B2.owner.14', B2.summaryReport.owner);
      const conclusionSummary = handleConclusion(B2.summaryReport.conclusion);
      if (conclusionSummary) {
        setValue('B2.qualified.14', conclusionSummary[0]);
        setValue('B2.notQualified.14', conclusionSummary[1]);
      }
      setValue('B2.review.14', B2.summaryReport.review);
      let index = 1;
      for (const key in B2?.formR08) {
        setValue(`B2.owner.${index}`, B2.formR08[key].owner);
        const conclusion = handleConclusion(B2.formR08[key].conclusion);
        if (conclusion) {
          setValue(`B2.qualified.${index}`, conclusion[0]);
          setValue(`B2.notQualified.${index}`, conclusion[1]);
        }
        setValue(`B2.review.${index}`, B2.formR08[key].review);
        index += 1;
      }
    }
    const B3 = registerData?.data?.B3;
    if (B3) {
      let index = 1;
      for (const key in B3) {
        if (
          key === 'k5' ||
          key === 'k6' ||
          key === 'k7' ||
          key === 'k8' ||
          key === 'k9' ||
          key === 'k10' ||
          key === 'k11'
        ) {
          setValue(`B3.registerQuantity.${index}.1`, B3[key].registeredQuantity);
          setValue(`B3.reachedQuantity.${index}.1`, B3[key].achievedQuantity);
          setValue(`B3.owner.${index}.1`, B3[key].owner);
          const conclusion = handleConclusion(B3[key].conclusion);
          if (conclusion) {
            setValue(`B3.qualified.${index}.1`, conclusion[0]);
            setValue(`B3.notQualified.${index}.1`, conclusion[1]);
          }
          setValue(`B3.review.${index}.1`, B3[key].review);
          index += 1;
        } else {
          let subIndex = 1;
          for (const subKey in B3[key]) {
            setValue(
              `B3.registerQuantity.${index}.${subIndex}`,
              B3[key][subKey].registeredQuantity
            );
            setValue(`B3.reachedQuantity.${index}.${subIndex}`, B3[key][subKey].achievedQuantity);
            setValue(`B3.owner.${index}.${subIndex}`, B3[key][subKey].owner);
            const conclusion = handleConclusion(B3[key][subKey].conclusion);
            if (conclusion) {
              setValue(`B3.qualified.${index}.${subIndex}`, conclusion[0]);
              setValue(`B3.notQualified.${index}.${subIndex}`, conclusion[1]);
            }
            setValue(`B3.review.${index}.${subIndex}`, B3[key][subKey].review);
            subIndex += 1;
          }
          index += 1;
        }
      }
    }

    const C = registerData?.data?.C;
    if (C) {
      const conclusionC1 = handleConclusion(C.presidingAgencyConclusion);
      if (conclusionC1) {
        setValue('C1.qualified', conclusionC1[0]);
        setValue('C1.notQualified', conclusionC1[1]);
      }
      const conclusionC2 = handleConclusion(C.managingAgencyConclusion);
      if (conclusionC2) {
        setValue('C2.qualified', conclusionC2[0]);
        setValue('C2.notQualified', conclusionC2[1]);
      }
    }
  }, [registerData]);

  // #endregion

  return (
    <>
      {(isProjectDataLoading || isRegisterResultLoading) && <Loading />}
      <div className="m-0 bg-white p-5 p-fluid detail-info-project-proposal">
        <div className="mb-5 flex justify-content-between align-items-center">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="VNU-HCM logo"
            height="75px"
            className="ml-3"
          />
          <div className="text-right mr-4">
            <p className="m-0">{t('checkResult.proposalCode')}</p>
            <p>{t('checkResult.dateCheckDocument')}</p>
          </div>
        </div>
        <h3 className="mb-5 line-height-3 text-4xl text-center font-bold">
          {t('checkResult.title')}
          <br />
          <span className="font-normal">{t('checkResult.subTitle')}</span>
        </h3>
        <div className="ml-4">
          <div className="pb-2">
            <h4>A. {t('checkResult.A.title')}</h4>
            <GeneralInfoCheckResult
              owner={owner?.[0]}
              generalInfoData={projectData?.projectProposal}
            />
            {/* part B */}
            <h4>B. {t('checkResult.B')}</h4>
            <h4>B1. {t('checkResult.B1.title')}</h4>
            <div
              className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines"
              data-scrollselectors=".p-datatable-wrapper"
            >
              <div className="p-datatable-wrapper">
                <table className="p-datatable-table form-table">
                  <thead>
                    <tr>
                      <th rowSpan="2">{t('checkResult.table.checkContent')}</th>
                      <th rowSpan="2">{t('checkResult.table.owner')}</th>
                      <th rowSpan="1" colSpan="2">
                        {t('checkResult.table.conclude')}
                      </th>
                      <th rowSpan="2" style={{ width: '30rem', minWidth: '18rem' }}>
                        {t('checkResult.table.opinion')}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '6rem', minWidth: '3rem' }}>
                        {t('checkResult.table.qualified')}
                      </th>
                      <th style={{ width: '6rem', minWidth: '3rem' }}>
                        {t('checkResult.table.notQualified')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {B1Options.map((item) => (
                      <tr>
                        <td>
                          {item}. {t(`checkResult.B1.${item}`)}
                        </td>
                        <td className="text-center">
                          <Tickbox
                            control={control}
                            errors={errors}
                            name={`B1.owner.${item}`}
                            readOnly
                          />
                        </td>
                        <td className="text-center">
                          <Tickbox
                            control={control}
                            errors={errors}
                            name={`B1.qualified.${item}`}
                            readOnly
                          />
                        </td>
                        <td className="text-center">
                          <Tickbox
                            control={control}
                            errors={errors}
                            name={`B1.notQualified.${item}`}
                            readOnly
                          />
                        </td>
                        <td>
                          <TextInput
                            control={control}
                            errors={errors}
                            name={`B1.review.${item}`}
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <h4 className="mt-4">B2. {t('checkResult.B2.title')}</h4>
            <div
              className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines"
              data-scrollselectors=".p-datatable-wrapper"
            >
              <div className="p-datatable-wrapper">
                <table className="p-datatable-table form-table">
                  <thead>
                    <tr>
                      <th rowSpan="2">{t('checkResult.table.checkContent')}</th>
                      <th rowSpan="2">{t('checkResult.table.owner')}</th>
                      <th rowSpan="1" colSpan="2">
                        {t('checkResult.table.conclude')}
                      </th>
                      <th rowSpan="2" style={{ width: '30rem', minWidth: '18rem' }}>
                        {t('checkResult.table.opinion')}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '6rem', minWidth: '3rem' }}>
                        {t('checkResult.table.qualified')}
                      </th>
                      <th style={{ width: '6rem', minWidth: '3rem' }}>
                        {t('checkResult.table.notQualified')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>14. {t('checkResult.B2.14')}</td>
                      <td className="text-center">
                        <Tickbox control={control} errors={errors} name="B2.owner.14" readOnly />
                      </td>
                      <td className="text-center">
                        <Tickbox
                          control={control}
                          errors={errors}
                          name="B2.qualified.14"
                          readOnly
                        />
                      </td>
                      <td className="text-center">
                        <Tickbox
                          control={control}
                          errors={errors}
                          name="B2.notQualified.14"
                          readOnly
                        />
                      </td>
                      <td>
                        <TextInput control={control} errors={errors} name="B2.review.14" readOnly />
                      </td>
                    </tr>
                    <tr>
                      <td>15. {t('checkResult.B2.15.title')}</td>
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                    {B2Options.map((item) => (
                      <tr>
                        <td>
                          {item === 1 && t('checkResult.B2.15.summary')}
                          {item === 2 && t('checkResult.B2.15.ownerSignature')}
                          {item === 3 && t('checkResult.B2.15.presidentCouncilSignature')}
                          {item === 4 && t('checkResult.B2.15.presidingAgencySignature')}
                        </td>
                        <td className="text-center">
                          <Tickbox
                            control={control}
                            errors={errors}
                            name={`B2.owner.${item}`}
                            readOnly
                          />
                        </td>
                        <td className="text-center">
                          <Tickbox
                            control={control}
                            errors={errors}
                            name={`B2.qualified.${item}`}
                            readOnly
                          />
                        </td>
                        <td className="text-center">
                          <Tickbox
                            control={control}
                            errors={errors}
                            name={`B2.notQualified.${item}`}
                            readOnly
                          />
                        </td>
                        <td>
                          <TextInput
                            control={control}
                            errors={errors}
                            name={`B2.review.${item}`}
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <h4 className="mt-4">B3. {t('checkResult.B3.title')}</h4>
            <div
              className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines"
              data-scrollselectors=".p-datatable-wrapper"
            >
              <div className="p-datatable-wrapper">
                <table className="p-datatable-table form-table">
                  <thead>
                    <tr>
                      <th rowSpan="2">{t('checkResult.table.checkContent')}</th>
                      <th rowSpan="2" style={{ width: '6rem', minWidth: '4rem' }}>
                        {t('checkResult.table.registerQuantity')}
                      </th>
                      <th rowSpan="2" style={{ width: '6rem', minWidth: '4rem' }}>
                        {t('checkResult.table.reachedQuantity')}
                      </th>
                      <th rowSpan="2">{t('checkResult.table.owner')}</th>
                      <th rowSpan="1" colSpan="2">
                        {t('checkResult.table.conclude')}
                      </th>
                      <th rowSpan="2" style={{ width: '30rem', minWidth: '18rem' }}>
                        {t('checkResult.table.opinion')}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '6rem', minWidth: '3rem' }}>
                        {t('checkResult.table.qualified')}
                      </th>
                      <th style={{ width: '6rem', minWidth: '3rem' }}>
                        {t('checkResult.table.notQualified')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {B3Options.map((item) => (
                      <>
                        <tr>
                          <td>
                            {item.id}. {t(`checkResult.B3.${item.id}.title`)}
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        {item.sub.map((subItem) => (
                          <tr>
                            <td>{t(`checkResult.B3.${item.id}.${subItem}`)}</td>
                            <td className="text-center">
                              <NumberInput
                                control={control}
                                errors={errors}
                                name={`B3.registerQuantity.${item.id}.${subItem}`}
                                readOnly
                              />
                            </td>
                            <td className="text-center">
                              <NumberInput
                                control={control}
                                errors={errors}
                                name={`B3.reachedQuantity.${item.id}.${subItem}`}
                                readOnly
                              />
                            </td>
                            <td className="text-center">
                              <Tickbox
                                control={control}
                                errors={errors}
                                name={`B3.owner.${item.id}.${subItem}`}
                                readOnly
                              />
                            </td>
                            <td className="text-center">
                              <Tickbox
                                control={control}
                                errors={errors}
                                name={`B3.qualified.${item.id}.${subItem}`}
                                readOnly
                              />
                            </td>
                            <td className="text-center">
                              <Tickbox
                                control={control}
                                errors={errors}
                                name={`B3.notQualified.${item.id}.${subItem}`}
                                readOnly
                              />
                            </td>
                            <td>
                              <TextInput
                                control={control}
                                errors={errors}
                                name={`B3.review.${item.id}.${subItem}`}
                                readOnly
                              />
                            </td>
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* part C */}
            <h4 className="mt-4">C. {t('checkResult.C.title')}</h4>
            <div className="mb-8">
              <h4>C1. {t('checkResult.C.C1.title')}</h4>
              <div className="flex gap-2">
                <Tickbox control={control} errors={errors} name="C1.qualified" readOnly />
                <span className="mt-1">{t('checkResult.C.C1.qualified')}</span>
              </div>
              <div className="flex gap-2 mt-1">
                <Tickbox control={control} errors={errors} name="C1.notQualified" readOnly />
                <span className="mt-1">{t('checkResult.C.C1.notQualified')}</span>
              </div>
              <div className="flex justify-content-around">
                <h4>{t('checkResult.signature.owner')}</h4>
                <h4>{t('checkResult.signature.specialist')}</h4>
                <h4>{t('checkResult.signature.head')}</h4>
              </div>
            </div>

            <div className="mb-8">
              <h4>C2. {t('checkResult.C.C2.title')}</h4>
              <div className="flex gap-2">
                <Tickbox control={control} errors={errors} name="C2.qualified" readOnly />
                <span className="mt-1">{t('checkResult.C.C2.qualified')}</span>
              </div>
              <div className="flex gap-2 mt-1">
                <Tickbox control={control} errors={errors} name="C2.notQualified" readOnly />
                <span className="mt-1">{t('checkResult.C.C2.notQualified')}</span>
              </div>
              <div className="flex justify-content-around">
                <h4>{t('checkResult.signature.specialist')}</h4>
                <h4>{t('checkResult.signature.head')}</h4>
              </div>
            </div>
            <div className="flex justify-content-end gap-2 mr-8">
              <Link to="/project">
                <Button
                  label={t('formLayout.action.close')}
                  className="w-7rem"
                  type="button"
                  severity="danger"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailCheckResult;
