import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import HardProductFields from './HardProductFields';
import KPITable from './KPITable';
import SoftProductFields from './SoftProductFields';
import ResearchContributions from './ResearchContributions';

export default function ResearchResult({ data }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion

  return (
    <div>
      <h4>{t('projectProposal.researchResult.viewLabel')}</h4>
      <h4>{t('projectProposal.researchResult.a10_1.label')}</h4>
      <SoftProductFields products={data?.softProducts} />
      <HardProductFields
        products={data?.hardProducts}
        qualityComparison={data?.qualityComparison}
      />
      <h4>{t('projectProposal.researchResult.a10_2.label')}</h4>
      <KPITable kpis={data?.projectProposalResearchKpis} />
      <ResearchContributions data={data} />
    </div>
  );
}

ResearchResult.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};
