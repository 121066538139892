import Layout from 'layout';
import { ProjectProposalViewList } from 'features/ProjectProposal/pages';
import { LayoutProvider } from '../layout/context/layoutcontext';

function ProjectProposalListPage() {
  return (
    <LayoutProvider>
      <Layout>
        <ProjectProposalViewList />
      </Layout>
    </LayoutProvider>
  );
}

export default ProjectProposalListPage;
