/* eslint-disable */
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import {
  modifyBindingScientificProfileAcademicHistoryData,
  modifyBindingScientificProfileGeneralData,
  modifyBindingScientificProfileGuidingActivitiesData,
  modifyBindingScientificProfileOthersData,
  modifyBindingScientificProfileResearchActivitiesData,
  modifyBindingScientificProfileScientificWorksData,
  modifyBindingScientificProfileWorkHistoryData,
  modifyScientificProfileHomeData,
  modifyScientificProfilePreviewData,
} from 'utils/func';

import { useQuery } from 'react-query';
import {
  getAcademicHistory,
  getGeneralInfo,
  getOthersInfo,
  getProjects,
  getScientificWorks,
  getThesisGuidances,
  getWorkHistory,
} from 'apis/scientificProfiles.api';
import GeneralInfoSection from '../PreviewForm/GeneralInfoSection';
import ResearchAndGuidingActivitiesSection from '../PreviewForm/ResearchAndGuidingActivitiesSection';
import ScientificWorksSection from '../PreviewForm/ScientificWorksSection';
import AwardsSection from '../PreviewForm/AwardsSection';
import OtherInfoSection from '../PreviewForm/OtherInfoSection';

import './style.scss';

export default function ScientificProfileHome() {
  // #region Data
  const { t } = useTranslation();

  const [isLoaded, setIsLoaded] = useState(false);
  const [previewData, setPreviewData] = useState({});

  // const previewData = useMemo(() => modifyScientificProfilePreviewData(data) ?? {}, [data]);

  const { data: generalInfoData, isFetching: isGeneralInfoFetching } = useQuery(
    'scientificProfileGeneralInfo',
    () => getGeneralInfo(),
    {
      enabled: isLoaded == false,
    }
  );

  const { data: academicHistoryData, isFetching: isAcademicHistoryFetching } = useQuery(
    'scientificProfileAcademicHistory',
    () => getAcademicHistory(),
    {
      enabled: isLoaded === false,
    }
  );

  const { data: workHistoryData, isFetching: isWorkHistoryFetching } = useQuery(
    'scientificProfileWorkHistory',
    () => getWorkHistory(),
    {
      enabled: isLoaded === false,
    }
  );

  const { data: projectsData, isFetching: isProjectsFetching } = useQuery(
    'scientificProfileProjects',
    () => getProjects(),
    {
      enabled: isLoaded === false,
    }
  );

  const { data: thesisGuidancesData, isFetching: isThesisGuidancesFetching } = useQuery(
    'scientificProfileThesisGuidances',
    () => getThesisGuidances(),
    {
      enabled: isLoaded === false,
    }
  );

  const { data: scientificWorksData, isFetching: isScientificWorksFetching } = useQuery(
    'scientificProfileScientificWorks',
    () => getScientificWorks(),
    {
      enabled: isLoaded === false,
    }
  );

  const { data: othersInfoData, isFetching: isOthersInfoFetching } = useQuery(
    'scientificProfileOthersInfo',
    () => getOthersInfo(),
    {
      enabled: isLoaded === false,
    }
  );

  // #endregion Data

  // #region Event

  useEffect(
    () => {
      const bindingGeneralData = generalInfoData?.data
        ? modifyBindingScientificProfileGeneralData(generalInfoData.data)
        : null;
      const bindingAcademicHistoryData = academicHistoryData?.data
        ? modifyBindingScientificProfileAcademicHistoryData(academicHistoryData.data)
        : null;
      const bindingWorkHistoryData = workHistoryData?.data
        ? modifyBindingScientificProfileWorkHistoryData(workHistoryData.data)
        : null;

      const bindingResearchActivitiesData = projectsData?.data
        ? modifyBindingScientificProfileResearchActivitiesData(projectsData.data)
        : null;
      const bindingGuidingActivitiesData = thesisGuidancesData?.data
        ? modifyBindingScientificProfileGuidingActivitiesData(thesisGuidancesData.data)
        : null;
      const bindingScientificWorksData = scientificWorksData?.data
        ? modifyBindingScientificProfileScientificWorksData(scientificWorksData.data)
        : null;
      const bindingOthersData = othersInfoData?.data
        ? modifyBindingScientificProfileOthersData(othersInfoData.data)
        : null;

      const previewData = modifyScientificProfileHomeData(
        bindingGeneralData,
        bindingAcademicHistoryData,
        bindingWorkHistoryData,
        bindingResearchActivitiesData,
        bindingGuidingActivitiesData,
        bindingScientificWorksData,
        bindingOthersData
      );
      setPreviewData(previewData);

      setIsLoaded(true);
    },
    // rerender when fetch new data
    [
      isGeneralInfoFetching,
      isAcademicHistoryFetching,
      isWorkHistoryFetching,
      isProjectsFetching,
      isThesisGuidancesFetching,
      isScientificWorksFetching,
      isOthersInfoFetching,
    ]
  );

  const getPageMargins = () => '@page { margin: 0.5in 0.5in 0.2in 0.2in !important; }';
  // #endregion Event

  return (
    <div
      id="scientificProfilePreview"
      className="m-0 bg-white pt-5 pb-5 p-fluid preview-scientific-profile"
    >
      <style>{getPageMargins()}</style>

      <GeneralInfoSection data={previewData?.generalInfoSection} />
      <ResearchAndGuidingActivitiesSection
        data={previewData?.researchAndGuidingActivitiesSection}
      />
      <ScientificWorksSection data={previewData?.scientificWorksSection} />
      <AwardsSection data={previewData?.awardsSection} />
      <OtherInfoSection data={previewData?.otherInfoSection} />
    </div>
  );
}

ScientificProfileHome.propTypes = {};

ScientificProfileHome.defaultProps = {};
/* eslint-enable */
