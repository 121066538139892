/* eslint-disable indent */
import { Tickbox } from 'components/FormControl';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function EvaluationForm({ setValue, getValues, control, errors }) {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const evaluateTypes = [
    {
      id: '2affedc0-e12b-4744-94fa-f9636d021b8a',
      name: ' Kế hoạch nghiên cứu và phương pháp',
      order: 1,
      englishName: 'Research plan and methods',
      code: 'evaluateResearchPlan',
    },
    {
      id: '2aafiog2-b23h-9753-54it-d436e1d4e880',
      name: ' Kết quả nghiên cứu',
      order: 2,
      englishName: 'Research results',
      code: 'evaluateResearchResult',
    },
    {
      id: '2abkeil4-q18j-6753-78jj-ba6690a6532a',
      name: ' Tiến độ thực hiện',
      order: 3,
      englishName: 'Implementation progress',
      code: 'evaluateProgress',
    },
    {
      id: '2acuely5-t11i-3286-53kb-69316ba3c50c',
      name: ' Tình hình sử dụng kinh phí',
      order: 4,
      englishName: 'Funding usage situation',
      code: 'evaluateExpenditureUsed',
    },
    {
      id: '693f6feb-5467-4cd3-a26c-4eee9fc052cd',
      name: ' Đánh giá chung',
      order: 5,
      englishName: 'Overall Assessments',
      code: 'evaluateGeneral',
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const handleCheck = (name, value) => {
    const token = name.split('.');
    const [, code, key] = token;

    let otherKey = [];

    switch (key) {
      case 'isQualified': {
        otherKey = ['isNotQualified', 'suitable'];
        break;
      }
      case 'isNotQualified': {
        otherKey = ['isQualified', 'suitable'];
        break;
      }
      default: {
        otherKey = [];
        break;
      }
    }

    if (otherKey?.length) {
      const otherValue1 = getValues(`evaluate.${code}.${otherKey[0]}`);
      // const otherValue2 = getValues(`evaluate.${code}.${otherKey[1]}`);
      if (otherValue1) {
        setValue(`evaluate.${code}.${otherKey[0]}`, false);
      }
      // if (otherValue2) {
      //   setValue(`evaluate.${code}.${otherKey[1]}`, false);
      // }
    }
  };

  return (
    <div className="form-table">
      <table className="mb-2">
        <thead>
          <tr>
            <th className="text-center" rowSpan={2} style={{ minWidth: 260, width: 360 }}>
              Nội dung đánh giá <br />{' '}
              <span className="font-italic font-medium">
                (căn cứ theo thuyết minh và hợp đồng đã được phê duyệt)
              </span>
            </th>
            <th className="text-center" colSpan={2}>
              Mức độ hoàn thành
            </th>
          </tr>
          <tr>
            <th className="text-center" style={{ width: 100 }}>
              Đạt
            </th>
            <th className="text-center" style={{ width: 100 }}>
              Không đạt
            </th>
          </tr>
        </thead>
        <tbody>
          {evaluateTypes &&
            evaluateTypes.map((type) => (
              <tr key={type.id}>
                <td
                  className={classNames({
                    'font-bold': type?.order === 5,
                  })}
                >
                  {t('lang') === 'vi' ? type?.name : type?.englishName}
                </td>
                <td>
                  <div className="flex justify-content-center">
                    <Tickbox
                      icon="pi pi-times"
                      name={`evaluate.${type.code}.isQualified`}
                      control={control}
                      errors={errors}
                      onCustomCheck={handleCheck}
                    />
                  </div>
                </td>
                <td>
                  <div className="flex justify-content-center">
                    <Tickbox
                      icon="pi pi-times"
                      name={`evaluate.${type.code}.isNotQualified`}
                      control={control}
                      errors={errors}
                      onCustomCheck={handleCheck}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

EvaluationForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};
