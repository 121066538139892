import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import GoogleAuthCallback from 'features/Authentication/GoogleAuthCallback';

function GoogleAuthCallbackPage() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('websiteName');
  }, [t]);

  return <GoogleAuthCallback />;
}

export default GoogleAuthCallbackPage;
