import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { resendEmail, verify } from 'apis/users.api';

import LanguageSelect from 'components/LanguageSelect';
import { mapError } from 'utils/func';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';

function VerifyAccount() {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate, isLoading, isError, error } = useMutation(verify);
  const {
    mutate: mutateResend,
    isLoading: isResendLoading,
    isSuccess: isResendSuccess,
    isError: isResendError,
    error: resendError,
  } = useMutation(resendEmail);
  // #endregion Data

  // #region Event
  useEffect(() => {
    const confirmationToken = searchParams.get('token');
    if (confirmationToken) {
      mutate(
        { confirmationToken },
        {
          onSuccess: () => {
            navigate('/login?verify=1');
          },
        }
      );
    }
  }, []);

  const handleResendEmail = () => {
    const email = searchParams.get('email');
    if (email) {
      mutateResend({ email });
    }
  };
  // #endregion Event

  return (
    <>
      <div className="p-4 fixed right-0">
        <LanguageSelect />
      </div>
      <div className="flex align-items-center justify-content-center" style={{ height: '80vh' }}>
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: '400px' }}
        >
          <div className="text-center">
            <img src="/logo.png" alt="hyper" height={50} className="mb-3" />
            <div className="text-3xl font-medium my-3">
              {isLoading && <p className="text-green-500">{t('verify.verifying')}</p>}
              {isError && (
                <div>
                  <p className="text-red-400">{mapError(t, error)}</p>
                  <Button
                    label={t('verify.resend')}
                    icon={classNames(
                      'pi',
                      { 'pi-spin pi-spinner': isResendLoading },
                      { 'pi-check': isResendSuccess },
                      { 'pi-undo': !isResendSuccess }
                    )}
                    severity="info"
                    onClick={handleResendEmail}
                  />
                  {isResendError && (
                    <p className="text-red-400 text-base mt-2">{mapError(t, resendError)}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyAccount;
