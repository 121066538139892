const formDefaultValues = {
  scientificPublication: '',
  intellectualProperty: '',
  productDescription: '',
  trainingResult: '',
  contentReview: '',
  productReview: '',
  processReview: '',
  suggestion: '',
};

export const productDefaultValues = {
  researchKpiGroupId: null,
  researchKpiId: null,
  file: null,
  customId: null,
  productDetail: {
    title: null, //
    author: null, //
    doi: null, //
    publishedYear: null, //
    conference: null, //
    ISSN_ISBN: null, //
    rank: null, //
    patentCode: null,
    object: null,
    note: null,
  },
};

export const defaultValues = {
  summaryReportConferences: [],
  summaryReportFinishedTasks: [],
  summaryReportUnfinishedTasks: [],
  summaryReportParticipants: [],
  summaryReportTransferredTechnologies: [],
  researchResult: {
    softProducts: [],
    hardProducts: [],
    intellectualProperty: [],
    trainingResult: [],
  },
  summaryReportExpenditures: [],
};
// export const defaultValues = {
//   summaryReportConferences: {
//     organizedDate: '',
//     title: '',
//     address: '',
//     result: '',
//   },
//   summaryReportExpenditures: {
//     expenseName: '',
//     expenditure: '',
//     notes: '',
//   },
//   summaryReportFinishedTasks: {
//     content: '',
//     result: '',
//     completionLevel: '',
//   },
//   summaryReportUnfinishedTasks: {
//     content: '',
//     reason: '',
//     solution: '',
//   },
//   summaryReportParticipants: {
//     fullName: '',
//     joiningDate: '',
//     address: '',
//     content: '',
//     result: '',
//   },
//   summaryReportTransferredTechnologies: {
//     name: '',
//     transferredYear: undefined,
//     partner: '',
//     contractedDate: '',
//     revenue: undefined,
//     scale: '',
//   },
//   researchResult: {
//     softProducts: {
//       ...productDefaultValues,
//     },
//     hardProducts: {
//       ...productDefaultValues,
//     },
//     intellectualProperty: {
//       ...productDefaultValues,
//     },
//     trainingResult: {
//       ...productDefaultValues,
//     },
//   },
// };

export const defaultFinancialAdjustment = {
  commitment: '',
  otherExpenditure: 0,
  financialAdjustmentItems: [],
};

export const defaultFinancialAdjustmentItem = {
  name: '',
  expenditureTypeId: '',
  beforeExpenditureFromVNUHCM: 0,
  beforeExpenditureFromFunding: 0,
  afterExpenditureFromVNUHCM: 0,
  afterExpenditureFromFunding: 0,
  reason: '',
};

export default formDefaultValues;
