/* eslint-disable import/no-extraneous-dependencies */
import './style.scss';

import PropTypes from 'prop-types';
import ReactRouterPrompt from 'react-router-prompt';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { DatePicker, CurrencyInput, TextInput, NumberInput } from 'components/FormControl';
import { toast } from 'layout';
import { downloadFile } from 'apis/project.api';
import { formatFileName } from 'utils/func';
import moment from 'moment';

function GeneralInfoForm({
  errors,
  control,
  footerConfirmationPrompt,
  isDirty,
  dirtyFields,
  watch,
  setValue,
  getValues,
  disabled,
}) {
  // #region Data
  const { t } = useTranslation();
  const currencyOptions = {
    style: 'currency',
    currency: 'VND',
  };
  const { projectId } = useParams();
  const { mutate: mutateDownloadFile } = useMutation(downloadFile);
  const [totalExpenditure, setTotalExpenditure] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [appendixFilesDelete, setAppendixFilesDelete] = useState([]);

  // #endregion
  const expenditureFromVNUWatch = watch('expenditureFromVNU');
  const flexibleExpenditureWatch = watch('flexibleExpenditure');
  const expenditureFromFundingWatch = watch('expenditureFromFunding');

  // #region Event

  useEffect(() => {
    const expenditureFromVNU = getValues('expenditureFromVNU');
    const flexibleExpenditure = getValues('flexibleExpenditure');
    if (flexibleExpenditure <= expenditureFromVNU) {
      setValue('fixedExpenditure', expenditureFromVNU - flexibleExpenditure);
    }
    if (flexibleExpenditure > expenditureFromVNU) {
      setValue('fixedExpenditure', expenditureFromVNU);
      setTimeout(() => {
        setValue('flexibleExpenditure', 0);
      }, 1);
      toast('error', t('formControl.validation.canNotGreaterThanTotal'));
    }
  }, [JSON.stringify(expenditureFromVNUWatch), JSON.stringify(flexibleExpenditureWatch)]);

  useEffect(() => {
    const expenditureFromVNU = getValues('expenditureFromVNU');
    const expenditureFromFunding = getValues('expenditureFromFunding');
    setValue('totalExpenditure', expenditureFromVNU + expenditureFromFunding ?? 0);
    setTotalExpenditure(expenditureFromVNU + expenditureFromFunding ?? 0);
  }, [JSON.stringify(expenditureFromVNUWatch), JSON.stringify(expenditureFromFundingWatch)]);

  // #endregion

  // #calculate numberMonthImplementation
  // Watch for changes in startDate and endDate
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  useEffect(() => {
    if (startDate && endDate) {
      const numberMonthImplementationResult = Math.ceil(
        moment(endDate).diff(startDate, 'months', true)
      );

      // Set the value of numberMonthImplementation
      setValue('numberMonthImplementation', numberMonthImplementationResult);
    }
  }, [startDate, endDate, setValue]);

  const handleDownloadFile = (id, path) => {
    mutateDownloadFile(
      { projectId: id, path },
      {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = formatFileName(path);

            link.click();
          }
        },
      }
    );
  };

  const fileUrl = watch('fileUrl');
  const projectAppendixes = watch('projectAppendixes');

  return (
    <div className="relative">
      <h4 className="text-center mb-4">
        {t('projectProposal.general.label')} {t('contract.name')}
      </h4>
      <div className="grid p-fluid form-table">
        <table className="mb-2 col-12">
          <thead>
            <tr>
              <th className="text-center" style={{ minWidth: 260, width: 360 }}>
                {t('projectProposal.expenditure.a5.fields.term')}
              </th>
              <th className="text-center" style={{ minWidth: 130, width: 360 }}>
                {t('projectProposal.expenditure.a5.fields.total')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="font-bold">{t('contract.form.expenditureFromVNU')}</td>
              <td>
                <CurrencyInput
                  name="expenditureFromVNU"
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </td>
            </tr>
            <tr>
              <td>{t('contract.form.fixedExpenditure')}</td>
              <td>
                <CurrencyInput name="fixedExpenditure" control={control} errors={errors} disabled />
              </td>
            </tr>
            <tr>
              <td>{t('contract.form.flexibleExpenditure')}</td>
              <td>
                <CurrencyInput
                  name="flexibleExpenditure"
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </td>
            </tr>
            <tr>
              <td className="font-bold">{t('contract.form.expenditureFromFunding')}</td>
              <td>
                <CurrencyInput
                  name="expenditureFromFunding"
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </td>
            </tr>
            <tr>
              <td className="font-bold">{t('contract.form.totalExpenditure')}</td>
              <td className="text-right p-3 font-bold">
                <input type="number" name="totalExpenditure" hidden value={totalExpenditure} />
                {getValues('totalExpenditure')?.toLocaleString('vi', currencyOptions)}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="col-4 my-2">
          <DatePicker
            label={t('contract.form.startDate')}
            name="startDate"
            control={control}
            errors={errors}
            isRequired
            disabled={disabled}
          />
        </div>
        <div className="col-4 my-2">
          <DatePicker
            label={t('contract.form.endDate')}
            name="endDate"
            control={control}
            errors={errors}
            isRequired
            disabled={disabled}
          />
        </div>
        <div className="col-4 my-2">
          <NumberInput
            label={t('contract.form.numberMonthImplementation')}
            name="numberMonthImplementation"
            defaultValue={0.0}
            // min={1}
            control={control}
            errors={errors}
            // isRequired
            disabled
          />
        </div>
        <div className="col-4 my-2">
          <TextInput
            label={t('contract.form.code')}
            name="projectCode"
            control={control}
            errors={errors}
            isRequired
            disabled={disabled}
          />
        </div>
        <div className="col-4 my-2">
          <TextInput
            label={t('contract.form.topicCode')}
            name="topicCode"
            control={control}
            errors={errors}
            isRequired
            disabled={disabled}
          />
        </div>
        <div className="col-4 my-2">
          <DatePicker
            label={t('contract.form.signingDate')}
            name="signingDate"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
        <div className="col-12 my-2">
          <label htmlFor="" className=" " style={{ fontWeight: 'bold' }}>
            {t('contract.form.downloadContract')} {': '}
            {fileUrl && (
              <span
                className="underline cursor-pointer"
                style={{ color: 'blue' }}
                onClick={() => handleDownloadFile(projectId, fileUrl)}
              >
                {formatFileName(typeof fileUrl === 'string' ? fileUrl : fileUrl?.name)}
              </span>
            )}
          </label>
        </div>
        <div className="col-12">
          {projectAppendixes &&
            projectAppendixes.map((item) => (
              <span
                onClick={() => handleDownloadFile(projectId, item.fileUrl)}
                key={item?.fileUrl}
                className="underline"
                style={{ color: 'blue', display: 'block', cursor: 'pointer' }}
              >
                {item &&
                  formatFileName(typeof item?.fileUrl === 'string' ? item?.fileUrl : item?.name)}
              </span>
            ))}
        </div>
      </div>
      {!disabled && (
        <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields)}>
          {({ isActive, onConfirm, onCancel }) => (
            <Dialog
              visible={isActive}
              header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
              position="center"
              style={{ width: '300px' }}
              onHide={onCancel}
              draggable={false}
              footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
            >
              <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
            </Dialog>
          )}
        </ReactRouterPrompt>
      )}
    </div>
  );
}

GeneralInfoForm.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  footerConfirmationPrompt: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  dirtyFields: PropTypes.object,
  isDirty: PropTypes.bool,
  disabled: PropTypes.bool,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
};

GeneralInfoForm.defaultProps = {
  dirtyFields: {},
  isDirty: false,
  disabled: false,
  footerConfirmationPrompt: () => null,
};

export default GeneralInfoForm;
