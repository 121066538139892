import { forwardRef, useImperativeHandle, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker, TextInput, TextareaInput } from 'components/FormControl';

import { yupResolver } from '@hookform/resolvers/yup';
import { TOAST } from 'constant';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { internationalConferencePaperSchema } from 'features/ScientificProfile/validation';

const formOptions = { resolver: yupResolver(internationalConferencePaperSchema) };

const InternationalConferencePaperDialog = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [internationalConferencePaperControl, setInternationalConferencePaperControl] = useState();
  const [visible, setVisible] = useState(false);

  const {
    control,
    watch,
    setValue,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({ ...formOptions, mode: 'onChange' });

  // #region Event

  useImperativeHandle(
    ref,
    () => ({
      open: (_internationalConferencePaperControl) => {
        setInternationalConferencePaperControl(_internationalConferencePaperControl);
        setVisible(true);
        if (_internationalConferencePaperControl.type === 'INSERT') {
          reset({});
        } else if (_internationalConferencePaperControl.type === 'UPDATE') {
          reset(_internationalConferencePaperControl.value);
        }
      },
    }),
    []
  );

  const handleAddInternationalConferencePaper = async () => {
    setValue('paper.publicationType', 'INTERNATIONAL_CONFERENCE');

    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = watch();

    const { insert, update, index, type } = internationalConferencePaperControl;

    if (type === 'INSERT' && insert) {
      insert(index, data);
    } else if (type === 'UPDATE' && update) {
      update(index, data);
    }
    reset({});
    setVisible(false);
  };

  // #endregion Event

  return (
    <>
      <Dialog
        header={
          internationalConferencePaperControl?.type === 'INSERT'
            ? t('scientificProfile.scientificWork.paper.internationalConference.add')
            : t('scientificProfile.scientificWork.paper.internationalConference.update')
        }
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        draggable={false}
        className="w-full sm:w-7 lg:w-6"
      >
        <div className="grid p-fluid">
          <div className="col-12">
            <TextInput
              label={t('scientificProfile.scientificWork.paper.title')}
              name="paper.title"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          <div className="col-12">
            <TextareaInput
              label={t('scientificProfile.scientificWork.paper.authors')}
              name="paper.authors"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          <div className="col-12">
            <TextareaInput
              label={t('scientificProfile.scientificWork.paper.conference')}
              name="paper.conference"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          <div className="col-6">
            <DatePicker
              label={t('scientificProfile.scientificWork.paper.start')}
              name="paper.start"
              control={control}
              errors={errors}
              isRequired
            />
          </div>
          <div className="col-6">
            <DatePicker
              label={t('scientificProfile.scientificWork.paper.end')}
              name="paper.end"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          <div className="col-12">
            <TextareaInput
              label={t('scientificProfile.scientificWork.paper.venue')}
              name="paper.venue"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          <div className="col-6">
            <TextInput
              label={t('scientificProfile.scientificWork.paper.projectCode')}
              name="paper.projectCode"
              control={control}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextInput
              label={t('scientificProfile.scientificWork.paper.isbn')}
              name="paper.isbn"
              control={control}
              errors={errors}
            />
          </div>

          <div className="col-12">
            <TextareaInput
              label={t('scientificProfile.scientificWork.paper.note')}
              name="note"
              control={control}
              errors={errors}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-content-end mt-4">
          <Button
            label={t('formLayout.action.cancel')}
            severity="danger"
            onClick={() => {
              setVisible(false);
            }}
            className="mr-1 w-8rem"
          />
          <Button
            label={
              internationalConferencePaperControl?.type === 'INSERT'
                ? t('formLayout.action.add')
                : t('formLayout.action.update')
            }
            severity="info"
            type="submit"
            className="w-8rem"
            onClick={handleAddInternationalConferencePaper}
            disabled={!Object.keys(dirtyFields).length}
          />
        </div>
      </Dialog>

      {/* {isCreatePaperLoading && <Loading />} */}
    </>
  );
});

InternationalConferencePaperDialog.propTypes = {};

InternationalConferencePaperDialog.defaultProps = {};

export default InternationalConferencePaperDialog;
