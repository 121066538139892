/* eslint-disable indent */
import './style.scss';

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { getAll as getAllProject } from 'apis/project.api';
import ViewHistory from 'features/ProjectProposal/components/ViewHistory';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Badge } from 'primereact/badge';
import { isAuthorized, mapError } from 'utils/func';
import { Tag } from 'primereact/tag';
import { COUNCIL_STATUS, TIMEZONE_VN, PROJECT_ROLES } from 'constant';
import MidtermDocumentDialog from 'features/Project/components/ViewList/MidtermDocumentDialog';

function ProjectViewList() {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useQuery('project', getAllProject);
  const project = useMemo(() => data?.data ?? [], [data?.data]);
  const projectSubmit = project?.data?.filter((item) => item?.projectStatus?.name !== 'completed');
  // const statuses = t('projectProposal.viewList.statuses', { returnObjects: true });
  const [viewHistoryVisible, setViewHistoryVisible] = useState(false);
  const [projectHistory, setProjectHistory] = useState([]);
  const [midtermDocumentDialogVisible, setMidtermDocumentDialogVisible] = useState(false);
  const [midtermSummaryReports, setMidtermSummaryReports] = useState([]);

  const statuses = t('project.viewList.statuses', { returnObjects: true });
  const user = JSON.parse(localStorage.getItem('user'));

  // #endregion

  // #region Format
  const formatVietnameseName = (value) => (
    <Link
      to={`/project/${value?.id}?projectProposalId=${value?.projectProposal?.id}`}
      className="hover:underline "
    >
      {value?.projectProposal?.vietnameseName}
    </Link>
  );

  const formatOwner = (value) => {
    const projectProposalContacts = value?.projectProposal?.projectProposalContacts;

    if (projectProposalContacts?.length > 0) {
      const owner = projectProposalContacts.find(
        (contact) => contact.projectRole.code === PROJECT_ROLES.OWNER
      );

      return <div>{owner?.fullname}</div>;
    }
    return null;
  };

  const formatOrganization = (value) =>
    t('lang') === 'vi'
      ? value?.projectProposal?.projectProposalOrganizations[0]?.name
      : value?.projectProposal?.projectProposalOrganizations[0]?.englishName;
  const formatDate = (date) =>
    date ? new Date(date).toLocaleDateString('en-GB', { timeZone: TIMEZONE_VN }) : '';

  const formatStartDate = (value) => formatDate(value?.startDate);

  const formatEndDate = (value) => formatDate(value?.endDate);
  const formatTotalExpenditure = (value) => {
    if (value?.totalExpenditure === 0 && value?.proposedExpenditureSummaries?.length > 0) {
      const total = value?.proposedExpenditureSummaries.reduce((sum, cur) => {
        if (cur?.proposedExpenditureType?.subProposedExpenditureTypeId === null) {
          const expenditureFromFunding = cur?.expenditureFromFunding ?? 0;
          const expenditureFromVNUHCM = cur?.expenditureFromVNUHCM ?? 0;

          return sum + expenditureFromFunding + expenditureFromVNUHCM;
        }
        return sum;
      }, 0);
      return total?.toLocaleString('vi');
    }
    return value?.totalExpenditure?.toLocaleString('vi') || 0;
  };
  // #region Render
  const formatActions = (value) => (
    <div className="flex flex-wrap justify-content-center gap-1">
      <div className="flex align-items-center flex-wrap border-round-lg">
        <Link to={`/project/${value?.id}?projectProposalId=${value?.projectProposal?.id}`}>
          <Button
            icon="pi pi-eye"
            tooltip={t('project.viewList.actions.viewContract')}
            tooltipOptions={{ position: 'left', at: 'left top' }}
            severity="success"
            style={{ width: '42px', height: '42px' }}
          />
        </Link>
      </div>
      <div className="flex align-items-center flex-wrap border-round-lg">
        <Button
          icon="pi pi-history"
          tooltip={t('project.viewList.actions.viewHistory')}
          tooltipOptions={{ position: 'left', at: 'left top' }}
          onClick={() => {
            setProjectHistory(value?.contractLogs ?? []);
            setViewHistoryVisible(true);
          }}
          style={{ width: '42px', height: '42px' }}
        />
      </div>
      <div className="flex align-items-center flex-wrap border-round-lg">
        <Link to={`/project-proposal/${value?.projectProposal?.id}`}>
          <Button
            icon="pi pi-eye"
            className="bg-blue-500 border-none"
            style={{ width: '42px', height: '42px' }}
            tooltip={t('project.viewList.actions.reviewProposal')}
            tooltipOptions={{ position: 'left', at: 'left top' }}
          />
        </Link>
      </div>
      <div className="flex align-items-center flex-wrap border-round-lg">
        {!value?.acceptanceSummaryReport && (
          <Link to={`/project/${value?.id}/submit-acceptance-document/create`}>
            <Button
              icon="pi pi-file"
              tooltip={t('project.viewList.acceptanceDocument.submit')}
              tooltipOptions={{ position: 'left', at: 'left top' }}
              className="bg-cyan-500 border-none"
              style={{ width: '42px', height: '42px' }}
            />
          </Link>
        )}
        {value?.acceptanceSummaryReport && (
          <Link
            to={`/project/${value?.id}/submit-acceptance-document/edit/${value?.acceptanceSummaryReport?.id}`}
          >
            <Button
              icon={!value?.acceptanceSummaryReport?.isSubmit ? 'pi pi-pencil' : 'pi pi-eye'}
              className={
                !value?.acceptanceSummaryReport?.isSubmit
                  ? 'bg-yellow-500 border-none'
                  : 'bg-purple-500 border-none'
              }
              tooltip={
                !value?.acceptanceSummaryReport?.isSubmit
                  ? t('project.viewList.acceptanceDocument.edit')
                  : t('project.viewList.acceptanceDocument.detail')
              }
              tooltipOptions={{ position: 'left', at: 'left top' }}
              style={{ width: '42px', height: '42px' }}
            />
          </Link>
        )}
      </div>
      {value?.acceptanceCouncilStatus === 'qualified' && (
        <div className="flex align-items-center flex-wrap border-round-lg">
          <Link to={`/project/${value?.id}/submit-register-result`}>
            <Button
              icon="pi pi-file-check"
              tooltip={t('r09.registerResult')}
              tooltipOptions={{ position: 'left', at: 'left top' }}
              style={{ width: '42px', height: '42px' }}
            />
          </Link>
        </div>
      )}

      {(value?.projectProposal?.owner?.id === user?.id ||
        isAuthorized(user?.id, value?.projectProposal?.projectProposalContacts)) &&
        value?.projectProposal?.projectProposalReviseLogs?.length > 0 && (
          <div className="flex align-items-center flex-wrap border-round-lg">
            <Button
              tooltip={t('projectProposal.result')}
              tooltipOptions={{ position: 'left', at: 'left top' }}
              onClick={() => {
                navigate(`/project-proposal/request/${value?.projectProposal?.reference}`);
              }}
              style={{ width: '42px', height: '42px' }}
              className="flex align-items-center justify-content-center"
            >
              <i className="pi pi-file p-overlay-badge">
                {value?.projectProposal?.projectProposalReviseLogs?.length > 0 && (
                  <Badge
                    value={value?.projectProposal?.projectProposalReviseLogs?.length}
                    severity="danger"
                  />
                )}
              </i>
            </Button>
          </div>
        )}

      {value?.extendImplementationTime?.length > 0 && (
        <div className="flex align-items-center flex-wrap border-round-lg">
          <Link
            to={`/project/${value?.id}/detail/extend-implementation-time/${value?.extendImplementationTime?.[0]?.id}`}
          >
            <Button
              icon="pi pi-calendar-plus"
              tooltip={t('project.viewList.viewExtendImplementationTime')}
              tooltipOptions={{ position: 'left', at: 'left top' }}
              style={{ width: '42px', height: '42px' }}
              className="bg-yellow-500 border-none"
            />
          </Link>
        </div>
      )}

      {value?.financialAdjustments?.length > 0 && (
        <div className="flex align-items-center flex-wrap bg-cyan-500 border-round-lg">
          <Link
            to={`/project/${value?.id}/financial-adjustment/view/${value?.financialAdjustments?.[0]?.id}`}
          >
            <Button
              icon="pi pi-dollar"
              severity="info"
              tooltip={t('project.viewList.viewFinancialAdjustment')}
              tooltipOptions={{ position: 'left', at: 'left top' }}
              style={{ width: '42px', height: '42px' }}
            />
          </Link>
        </div>
      )}

      {value?.projectStatus?.name === 'liquidation' && (
        <div className="flex align-items-center flex-wrap border-round-lg">
          <Link to={`/project/${value?.id}/detail/liquidation`}>
            <Button
              className="bg-teal-500 border-none"
              icon="pi pi-external-link"
              tooltip={t('project.viewList.viewLiquidation')}
              tooltipOptions={{ position: 'left', at: 'left top' }}
              style={{ width: '42px', height: '42px' }}
            />
          </Link>
        </div>
      )}

      {value?.inspectionResult?.length > 0 && (
        <div className="flex align-items-center flex-wrap border-round-lg">
          <Link to={`/project/${value?.id}/detail/inspection-register-result`}>
            <Button
              icon="pi pi-file-word"
              tooltip={t('project.viewList.viewRegistration')}
              tooltipOptions={{ position: 'left', at: 'left top' }}
              style={{ width: '42px', height: '42px' }}
              className="bg-red-300 border-none"
            />
          </Link>
        </div>
      )}
    </div>
  );
  const formatStatus = (value) => {
    const statusColorMapping = {
      new: 'info',
      doing: 'success',
      submit: 'success',
      liquidation: 'success',
    };

    return (
      <Tag
        style={{ minWidth: '4rem' }}
        severity={statusColorMapping[value.projectStatus.name]}
        value={statuses[value.projectStatus.name]}
        rounded
      />
    );
  };

  const formatMidtermReport = (value) => {
    const isPermisssionCreate = useMemo(() => {
      // cho phép tạo lần đầu tiên
      if (!value?.midtermSummaryReports?.length) return true;
      const lastVersion = value?.midtermSummaryReports?.reduce(
        (midtermVersionMax, midterm) =>
          midterm.version > midtermVersionMax.version ? midterm : midtermVersionMax,
        value?.midtermSummaryReports?.[0]
      );
      const result = lastVersion?.council?.projectProposalCouncils?.[0]?.councilStatus;
      // chỉ cho phép tạo tiếp khi version cuối đạt hoặc chưa đạt
      // notReview hoặc null thì không cho tạo
      return [COUNCIL_STATUS.QUALIFIED, COUNCIL_STATUS.NOT_QUALIFIED].includes(result);
    }, [value?.midtermSummaryReports]);
    return (
      <div className="flex flex-wrap justify-content-center gap-1">
        <div className="border-round-lg relative">
          <Button
            icon="pi pi-history"
            tooltip={t('project.viewList.midtermDocument.editOrViewOldMidtermDocument')}
            tooltipOptions={{ position: 'left', at: 'left top' }}
            onClick={() => {
              setMidtermSummaryReports(value?.midtermSummaryReports);
              setMidtermDocumentDialogVisible(true);
            }}
            style={{ width: '42px', height: '42px' }}
          />
        </div>
        <div className="flex align-items-center flex-wrap bg-blue-500 border-round-lg">
          {isPermisssionCreate && (
            <Link to={`/project/${value?.id}/submit-midterm-document/create`}>
              <Button
                icon="pi pi-plus"
                className="bg-blue-500 border-none"
                style={{ width: '42px', height: '42px' }}
                tooltip={t('project.viewList.midtermDocument.submitMidtermDocument')}
                tooltipOptions={{ position: 'left', at: 'left top' }}
              />
            </Link>
          )}
          {!isPermisssionCreate && (
            <div className="flex align-items-center flex-wrap border-round-lg">
              <Button
                icon="pi pi-plus"
                severity="secondary"
                tooltip={t('project.viewList.midtermDocument.cannotCreate')}
                tooltipOptions={{ position: 'left', at: 'left top' }}
                className="cursor-auto"
                style={{ width: '42px', height: '42px' }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="grid">
      {/* {true && <Loading />} */}
      <div className="col-12">
        <div className="card">
          {isError && <h3 className="text-red-600">{mapError(t, error)}</h3>}
          <div className="flex justify-content-between align-items-end mb-4 pb-2">
            <h5 className="p-0 m-0">{t('project.viewList.title')}</h5>
          </div>

          <DataTable
            value={projectSubmit}
            className="p-datatable-gridlines header-table"
            showGridlines
            dataKey="id"
            filterDisplay="menu"
            header=""
            scrollable
            loading={isLoading}
            // responsiveLayout="scroll"
            emptyMessage={t('projectProposal.viewList.emptyMessage')}
          >
            <Column
              field="projectProposal.vietnameseName"
              body={formatVietnameseName}
              header={t('project.viewList.vietnameseName')}
              style={{
                minWidth: '10rem',
                maxWidth: '16rem',
                textAlign: 'center',
              }}
              frozen
              alignFrozen="left"
            />
            <Column
              header={t('project.viewList.owner')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatOwner}
            />
            <Column
              header={t('project.viewList.loadOrganization')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatOrganization}
            />
            <Column
              header={t('project.viewList.totalExpenditure')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatTotalExpenditure}
            />
            <Column
              header={t('project.viewList.startDate')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatStartDate}
            />
            <Column
              header={t('project.viewList.endDate')}
              style={{ minWidth: '8rem' }}
              body={formatEndDate}
            />
            <Column
              field="projectStatus.name"
              header={t('project.viewList.status')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatStatus}
            />
            <Column
              field="projectStatus.name"
              header={t('project.viewList.midtermDocument.name')}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatMidtermReport}
              frozen
              alignFrozen="right"
            />
            <Column
              header={t('project.action')}
              body={formatActions}
              style={{ width: '14rem', textAlign: 'center' }}
              frozen
              alignFrozen="right"
            />
          </DataTable>
          <ViewHistory
            data={projectHistory}
            visible={viewHistoryVisible}
            setVisible={setViewHistoryVisible}
            title={t('project.viewHistory.title')}
          />
          <MidtermDocumentDialog
            midtermDocuments={midtermSummaryReports}
            visible={midtermDocumentDialogVisible}
            setVisible={setMidtermDocumentDialogVisible}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectViewList;
