import { useTranslation } from 'react-i18next';

import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';

export default function DownloadScientificProfile({
  contactId,
  contactName,
  academicRank,
  academicDegree,
  handleDownloadFile,
}) {
  const { t } = useTranslation();

  return (
    <span
      className="ml-2 cursor-pointer"
      onClick={() => handleDownloadFile(contactId, contactName, academicRank, academicDegree)}
    >
      <i
        className="download-icon pi pi-download"
        data-pr-tooltip={t('projectProposal.general.a9.downloadScientificProfile')}
        data-pr-position="right"
        style={{ color: 'var(--cyan-600)', fontSize: 15 }}
      />
      <Tooltip target=".download-icon" />
    </span>
  );
}
DownloadScientificProfile.propTypes = {
  contactId: PropTypes.string.isRequired,
  contactName: PropTypes.string,
  academicRank: PropTypes.string,
  academicDegree: PropTypes.string,
  handleDownloadFile: PropTypes.func.isRequired,
};

DownloadScientificProfile.defaultProps = {
  contactName: '',
  academicRank: '',
  academicDegree: '',
};
