import instance from 'utils/axios/instance.axios';
import { USERS } from './_constant';

export const getMe = () => instance.get(USERS.GET_ME);

export const signup = (body = {}) => instance.post(USERS.SIGNUP, body);

export const login = (body = {}) => instance.post(USERS.LOGIN, body);

export const forgotPassword = (body = {}) => instance.post(USERS.FORGOT_PASSWORD, body);

export const resetPassword = (body = {}) => instance.post(USERS.RESET_PASSWORD, body);

export const updatePassword = (body = {}) => instance.post(USERS.UPDATE_PASSWORD, body);

export const verify = (body = {}) => instance.post(USERS.VERIFY, body);

export const resendEmail = (body = {}) => instance.post(USERS.RESEND, body);

export const updateProfile = (body = {}) => instance.patch(USERS.UPDATE_PROFILE, body);

export const googleAuthCallback = (queryString) =>
  instance.get(USERS.GOOGLE_AUTH_CALLBACK(queryString));

export const completeProfile = (body = {}) => instance.patch(USERS.COMPLETE_PROFILE, body);
