import { Tooltip } from 'primereact/tooltip';

import PropTypes from 'prop-types';

export default function Warning({ message, tooltipTarget, tooltipPosition, tooltipAt, fontSize }) {
  return (
    <span className="ml-2">
      <i
        className={`${tooltipTarget} pi pi-exclamation-circle`}
        data-pr-tooltip={message}
        data-pr-position={tooltipPosition}
        data-pr-at={tooltipAt}
        style={{ color: 'var(--red-600)', fontSize }}
      />
      <Tooltip target={`.${tooltipTarget}`} />
    </span>
  );
}

Warning.propTypes = {
  message: PropTypes.string.isRequired,
  tooltipTarget: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.string,
  tooltipAt: PropTypes.string,
  fontSize: PropTypes.number,
};

Warning.defaultProps = {
  tooltipPosition: 'right',
  tooltipAt: '',
  fontSize: 15,
};
