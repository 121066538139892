import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Paginator } from 'primereact/paginator';
import { SelectButton } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';

import PropTypes from 'prop-types';
// import { Can } from 'components/Can';
import { paginationDetailString } from 'utils/func';

export default function Pagination({
  pagination,
  setPagination,
  paginationData,
  showMode,
  setShowMode,
  pageName,
}) {
  // #region Data
  const { t } = useTranslation();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const [currentPageInput, setCurrentPageInput] = useState(1);
  const [pageInputTooltip, setPageInputTooltip] = useState({
    tooltip: t('pagination.tooltip.info'),
    error: false,
  });
  const showModeOptions = [
    { value: 'showAll', label: t('projectProposal.viewList.showAll') },
    { value: 'hideDeletedProjects', label: t('projectProposal.viewList.hideDeletedProjects') },
  ];
  // #endregion Data
  // #region Event
  const onPaginationChange = (e) => {
    // Set current page (first) onto params url
    setSearchParams({ first: e.first }, { replace: true });
    // Save rows per page in localStorage
    localStorage.setItem(`admin-${location.pathname.slice(1)}-rowsPerPage`, e.rows);

    setPagination({ first: e.first, rowsPerPage: e.rows });
  };

  const onPageInputChange = (value) => {
    setCurrentPageInput(value);
  };

  const onPageInputKeyDown = (event, options) => {
    if (event.key === 'Enter') {
      const page = parseInt(currentPageInput, 10);

      if (page < 0 || page > options.totalPages) {
        setPageInputTooltip({
          tooltip: `${t('pagination.tooltip.error')} ${options.totalPages}.`,
          error: true,
        });
      } else {
        const newFirst = currentPageInput ? options.rows * (page - 1) : 0;

        setPagination((p) => ({ ...p, first: newFirst }));
        setPageInputTooltip({
          tooltip: t('pagination.tooltip.info'),
          error: false,
        });
      }
    }
  };

  const currentPageReport = (options) => (
    <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
      {t('pagination.goTo')}{' '}
      <InputNumber
        size="2"
        value={currentPageInput}
        tooltip={pageInputTooltip.tooltip}
        onKeyDown={(e) => onPageInputKeyDown(e, options)}
        onChange={(e) => onPageInputChange(e.value)}
        className={classNames('ml-1', { 'p-invalid': pageInputTooltip.error })}
      />
    </span>
  );
  const rowsPerPageDropdown = (options) => {
    const dropdownOptions = [
      { label: 10, value: 10 },
      { label: 25, value: 25 },
      { label: 50, value: 50 },
      { label: 100, value: 100 },
    ];

    return (
      <div>
        <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
          {t('pagination.rowsPerPage')}:{' '}
        </span>
        <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
      </div>
    );
  };
  // #endregion Event

  // #region Render
  const leftContent = () => (
    <div>
      {pageName === 'projects-revise' && (
        // <Can do="delete" on="project_proposal">
        <div className="">
          <SelectButton
            className="text-sm sm:text-base"
            value={showMode}
            onChange={(e) => (e.value !== null ? setShowMode(e.value) : null)}
            optionLabel="label"
            options={showModeOptions}
          />
        </div>
        // </Can>
      )}
    </div>
  );

  const rightContent = (options) => (
    <span>{paginationDetailString(t, options, paginationData, pagination)}</span>
  );

  const template = {
    layout:
      'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
    CurrentPageReport: currentPageReport, // use this due to keydown,
    RowsPerPageDropdown: rowsPerPageDropdown,
  };

  return (
    <div className="pt-2 border-top-1 border-primary-100">
      <Paginator
        template={template}
        first={pagination.first}
        rows={pagination.rowsPerPage}
        totalRecords={paginationData.totalRecords}
        onPageChange={onPaginationChange}
        leftContent={leftContent}
        rightContent={rightContent}
      />
    </div>
  );
}

Pagination.propTypes = {
  pagination: PropTypes.shape({
    first: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
  paginationData: PropTypes.shape({
    row: PropTypes.number,
    totalRecords: PropTypes.number,
  }),
  showMode: PropTypes.string,
  setShowMode: PropTypes.func,
  pageName: PropTypes.string.isRequired,
};

Pagination.defaultProps = {
  paginationData: {
    row: 10,
    totalRecords: 0,
  },
  showMode: 'hideDeletedProjects',
  setShowMode: () => null,
};
