import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Fieldset } from 'primereact/fieldset';
import '../style.scss';
import AwardsCard from './Cards/awardsCard';

export default function AwadsTab({ data }) {
  // #region Data
  const { t } = useTranslation();

  // #endregion Data

  // #region Event

  // #endregion Event

  // #region Render

  //
  const legendTemplate = (icon, label) => (
    <div className="flex align-items-center">
      <span className={icon} />
      <span className="font-bold text-base">{label}</span>
    </div>
  );

  //   const titleCustomized = (icon, label) => (
  //     <div className="flex align-items-center">
  //       <span className={icon} />
  //       <span className="font-bold text-lg">{label}</span>
  //     </div>
  //   );

  const itemGenneralInfo = (icon, label, content) => (
    <div className="text-base mt-2">
      <span className={icon} style={{ color: '#0000ff' }} />
      <span className="text-gray-900 text-base font-medium">{label}: </span>
      {content}
    </div>
  );

  return (
    <>
      {data?.awards?.length === 0 &&
        data?.patents?.length === 0 &&
        data?.usefulSolutions?.length === 0 &&
        data?.transferredSolutions?.length === 0 && <div>Chưa cập nhật</div>}
      {data?.awards?.length > 0 && (
        <Fieldset
          legend={legendTemplate('pi pi-book mr-2', t('scientificProfile.otherInfo.award.label'))}
        >
          {data?.awards?.length > 0 &&
            data?.awards.map((item) => (
              <AwardsCard
                awards={item}
                additionalInfo={
                  1 && (
                    <div>
                      {itemGenneralInfo(
                        '',
                        t('scientificProfile.otherInfo.award.placeOfIssue'),
                        item?.placeOfIssue
                      )}
                      {itemGenneralInfo(
                        '',
                        t('scientificProfile.otherInfo.award.description'),
                        item?.description
                      )}
                    </div>
                  )
                }
              />
            ))}
        </Fieldset>
      )}

      {data?.patents?.length > 0 && (
        <Fieldset
          className="mt-4"
          legend={legendTemplate('pi pi-book mr-2', t('scientificProfile.otherInfo.patent.label'))}
        >
          {data?.patents?.length > 0 &&
            data?.patents.map((item) => (
              <AwardsCard
                awards={item}
                additionalInfo={
                  1 && (
                    <div>
                      {itemGenneralInfo(
                        '',
                        t('scientificProfile.otherInfo.patent.number'),
                        item?.number
                      )}
                      {itemGenneralInfo(
                        '',
                        t('scientificProfile.otherInfo.patent.placeOfIssue'),
                        item?.placeOfIssue
                      )}
                    </div>
                  )
                }
              />
            ))}
        </Fieldset>
      )}

      {data?.usefulSolutions?.length > 0 && (
        <Fieldset
          className="mt-4"
          legend={legendTemplate(
            'pi pi-book mr-2',
            t('scientificProfile.otherInfo.usefulSolution.label')
          )}
        >
          {data?.usefulSolutions?.length > 0 &&
            data?.usefulSolutions.map((item) => (
              <AwardsCard
                awards={item}
                additionalInfo={
                  1 && (
                    <div>
                      {itemGenneralInfo(
                        '',
                        t('scientificProfile.otherInfo.usefulSolution.number'),
                        item?.number
                      )}
                      {itemGenneralInfo(
                        '',
                        t('scientificProfile.otherInfo.usefulSolution.placeOfIssue'),
                        item?.placeOfIssue
                      )}
                    </div>
                  )
                }
              />
            ))}
        </Fieldset>
      )}

      {data?.transferredSolutions?.length > 0 && (
        <Fieldset
          className="mt-4"
          legend={legendTemplate(
            'pi pi-book mr-2',
            t('scientificProfile.otherInfo.transferredSolution.label')
          )}
        >
          {data?.transferredSolutions?.length > 0 &&
            data?.transferredSolutions.map((item) => (
              <AwardsCard
                awards={item}
                additionalInfo={
                  1 && (
                    <div>
                      {itemGenneralInfo(
                        '',
                        t('scientificProfile.otherInfo.transferredSolution.scale'),
                        item?.scale
                      )}
                      {itemGenneralInfo(
                        '',
                        t('scientificProfile.otherInfo.transferredSolution.address'),
                        item?.address
                      )}
                    </div>
                  )
                }
              />
            ))}
        </Fieldset>
      )}
    </>
  );
}

AwadsTab.propTypes = {
  data: PropTypes.shape(),
};

AwadsTab.defaultProps = {
  data: {},
};
