export const PRODUCT_DETAIL_FIELD = {
  title: 'title',
  author: 'author',
  doi: 'doi',
  publishedYear: 'publishedYear',
  conference: 'conference',
  ISSN_ISBN: 'ISSN_ISBN',
  rank: 'rank',
  patentCode: 'patentCode',
  object: 'object',
  note: 'note',
};
export const KPI_GROUP = [
  'K1',
  'K2',
  'K3',
  'K4',
  'K5',
  'K6',
  'K7',
  'K8',
  'K9',
  'K10',
  'K11',
  'K12',
];
export const PRODUCT_TYPE = {
  HARD_PRODUCT: {
    code: 'hardProduct',
    short: 'SPC',
    kpiGroups: KPI_GROUP,
  },
  SOFT_PRODUCT: {
    code: 'softProduct',
    short: 'SPM',
    kpiGroups: KPI_GROUP,
  },
  INTELLECTUAL_PROPERTY: {
    code: 'intellectualProperty',
    short: 'SHTT',
    kpiGroups: ['K7', 'K8', 'K9'],
  },
  TRAINING_RESULT: {
    code: 'trainingResult',
    short: 'SPDT',
    kpiGroups: ['K11', 'K12'],
  },
  TECHNOLOGY_TRANSFER: {
    code: 'technologyTransfer',
    short: 'CGCN',
    kpiGroups: ['K10'],
  },
  findKpiGroupsByShort(shortCode) {
    const product = Object.values(PRODUCT_TYPE).find((p) => p.short === shortCode);
    return product ? product.kpiGroups : null;
  },
};

export const SMR = {
  PRODUCT_DETAIL_FIELD,
  PRODUCT_TYPE,
  KPI_GROUP,
};
