import './style.scss';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import GeneralInfoForm from '../GeneralInfoForm';

function Form({
  control,
  errors,
  handleSubmit,
  // tab,
  checkKeyDown,
  footerConfirmationPrompt,
  // checkValidation,
  disabled,
  isDirty,
  dirtyFields,
  watch,
  setValue,
  getValues,
}) {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="p-5">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form onSubmit={handleSubmit} onKeyDown={checkKeyDown} autoComplete="off">
        <GeneralInfoForm
          errors={errors}
          control={control}
          footerConfirmationPrompt={footerConfirmationPrompt}
          isDirty={isDirty}
          dirtyFields={dirtyFields}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          disabled={disabled}
        />
        <div className="flex justify-content-end mt-2 gap-4">
          <Button
            className="w-10rem"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            label={t('contract.comeBack')}
            comeBack
            severity="danger"
            type="button"
            onClick={() => {
              navigate('/project');
            }}
          />
        </div>
      </form>
    </div>
  );
}

Form.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  // tab: PropTypes.number.isRequired,
  // checkValidation: PropTypes.func.isRequired,
  checkKeyDown: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  footerConfirmationPrompt: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  dirtyFields: PropTypes.object,
  isDirty: PropTypes.bool,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};

Form.defaultProps = {
  disabled: false,
  dirtyFields: {},
  isDirty: false,
  footerConfirmationPrompt: () => null,
  // data: {},
};

export default Form;
