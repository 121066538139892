/* eslint-disable no-param-reassign */
import { useEffect, useCallback } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useOutsideClick(notiContentRef, notiButtonRef, showNoti, setShowNoti) {
  const cachedCallback = useCallback((event) => {
    if (notiButtonRef.current && notiButtonRef.current.contains(event.target) && !showNoti) {
      setShowNoti(true);
      showNoti = true;
    } else if
    (showNoti && notiContentRef.current && !notiContentRef.current.contains(event.target)) {
      setShowNoti(false);
      showNoti = false;
    }
  }, []);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', cachedCallback);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', cachedCallback);
    };
  }, [notiContentRef, notiButtonRef]);
}
