import { Fieldset } from 'primereact/fieldset';
import { TabPanel, TabView } from 'primereact/tabview';
import { Avatar } from 'primereact/avatar';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  downloadAvatarById,
  getPublicAboutInfo,
  getPublicAwardsInfo,
  getPublicOthersInfo,
  getPublicResearchAndTeachingInfo,
  getPublicScientificWorksInfo,
} from 'apis/scientificProfiles.api';
import AppTopbar from 'layout/AppTopbar';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import './style.scss';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
// import { Card } from 'primereact/card';
import Loading from 'components/Loading';
import { mapError } from 'utils/func';
import {
  modifyPublicAwardsData,
  modifyPublicProfileAbout,
  modifyPublicProfileOthersInfo,
  modifyPublicProfileResearchAndTeaching,
  modifyPublicScientificWorksData,
} from 'utils/func/publicProfileModifyData';
import AboutTab from './About';
import ResearchAndTeachingTab from './ResearchAndTeaching';
import PublishedScientificWorksTab from './PublishedScientificWorks';
import AwadsTab from './Awards';
import OthersInfoTab from './OthersInfomation';
import CreatePublicProfileDialog from './Dialog/createPublicProfileDialog';

export default function PublicProfile({ idProfile }) {
  // #region Data

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [visibleDialog, setVisibleDialog] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState([false]);
  const [currentTab, setCurrentTab] = useState(0);

  const [bindingAboutData, setBindingAboutData] = useState(null);
  const [bindingResearchAndTeachingData, setResearchAndTeachingData] = useState(null);
  const [bindingPublishedScientficWorksData, setPublishedScientficWorksData] = useState(null);
  const [bindingAwardsData, setAwardsData] = useState(null);
  const [bindingOthersData, setOthersData] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const topbarRef = useRef(null);
  const user = useMemo(() => {
    const userLocal = localStorage.getItem('user');
    return userLocal ? JSON.parse(userLocal) : null;
  });

  const {
    data: aboutInfoData,
    isError: isAboutInfoError,
    error: aboutInfoError,
    isLoading: isAboutInfoLoading,
    isFetching: isAboutInfoFetching,
  } = useQuery('scientificProfileAboutInfo', () => getPublicAboutInfo(idProfile), {
    enabled: currentTab === 0 && !selectedTabs[0],
  });

  const {
    data: researchAndTeachingData,
    isError: isResearchAndTeachingError,
    error: researchAndTeachingError,
    isLoading: isResearchAndTeachingLoading,
    isFetching: isResearchAndTeachingFetching,
  } = useQuery(
    'scientificProfileResearchAndTeaching',
    () => getPublicResearchAndTeachingInfo(idProfile),
    {
      enabled: currentTab === 1 && !selectedTabs[1],
    }
  );

  const {
    data: publishedScientficWorksData,
    isError: isPublishedScientficWorksError,
    error: publishedScientficWorksError,
    isLoading: isPublishedScientficWorksLoading,
    isFetching: isPublishedScientficWorksFetching,
  } = useQuery(
    'scientificProfilePublishedScientficWorks',
    () => getPublicScientificWorksInfo(idProfile),
    {
      enabled: currentTab === 2 && !selectedTabs[2],
    }
  );

  const {
    data: awardsData,
    isError: isAwardsError,
    error: awardsError,
    isLoading: isAwardsLoading,
    isFetching: isAwardsFetching,
  } = useQuery('scientificProfileAwards', () => getPublicAwardsInfo(idProfile), {
    enabled: currentTab === 3 && !selectedTabs[3],
  });

  const {
    data: othersData,
    isError: isOthersError,
    error: othersError,
    isLoading: isOthersLoading,
    isFetching: isOthersFetching,
  } = useQuery('scientificProfileOthers', () => getPublicOthersInfo(idProfile), {
    enabled: currentTab === 4 && !selectedTabs[4],
  });

  async function convert2DataUrl(blobOrFile) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blobOrFile);
      reader.onload = () => resolve(reader.result);
    });
  }

  // get avatar
  useQuery('avatar', () => downloadAvatarById(idProfile), {
    // Modify this line
    enabled: imagePreview === null,
    onSuccess: async (res) => {
      if (res?.data) {
        const imageBlob = new Blob([res.data]);

        const imageUrl = await convert2DataUrl(imageBlob);
        setImagePreview(imageUrl);
      }
    },
    onError: () => {
      setImagePreview(
        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
      );
    },
  });

  // #endregion Data

  // #region Event

  useEffect(() => {
    document.title = bindingAboutData?.fullname || 'Default Title';
  }, [bindingAboutData]);

  useEffect(() => {
    if (aboutInfoData?.data && !selectedTabs[0] && !isAboutInfoFetching) {
      const dataBinding = modifyPublicProfileAbout(aboutInfoData?.data);
      const newSelectedTabs = [...selectedTabs];
      newSelectedTabs[0] = true;

      setBindingAboutData(dataBinding);
      setSelectedTabs(newSelectedTabs);
    }

    if (researchAndTeachingData?.data && !selectedTabs[1] && !isResearchAndTeachingFetching) {
      const dataBinding = modifyPublicProfileResearchAndTeaching(researchAndTeachingData?.data);
      const newSelectedTabs = [...selectedTabs];
      newSelectedTabs[1] = true;

      setResearchAndTeachingData(dataBinding);
      setSelectedTabs(newSelectedTabs);
    }

    if (
      publishedScientficWorksData?.data &&
      !selectedTabs[2] &&
      !isPublishedScientficWorksFetching
    ) {
      const dataBinding = modifyPublicScientificWorksData(publishedScientficWorksData?.data);
      const newSelectedTabs = [...selectedTabs];
      newSelectedTabs[2] = true;

      setPublishedScientficWorksData(dataBinding);
      setSelectedTabs(newSelectedTabs);
    }

    if (awardsData?.data && !selectedTabs[3] && !isAwardsFetching) {
      const dataBinding = modifyPublicAwardsData(awardsData?.data);
      const newSelectedTabs = [...selectedTabs];
      newSelectedTabs[3] = true;

      setAwardsData(dataBinding);
      setSelectedTabs(newSelectedTabs);
    }

    if (othersData?.data && !selectedTabs[4] && !isOthersFetching) {
      const dataBinding = modifyPublicProfileOthersInfo(othersData?.data);
      const newSelectedTabs = [...selectedTabs];
      newSelectedTabs[4] = true;

      setOthersData(dataBinding);
      setSelectedTabs(newSelectedTabs);
    }
  }, [
    isAboutInfoFetching,
    isResearchAndTeachingFetching,
    isPublishedScientficWorksFetching,
    isAwardsFetching,
    isOthersFetching,
  ]);

  // orcid format
  const formatORCID = (orcid) => {
    if (!orcid) return null;
    if (orcid.includes('https://orcid.org/')) {
      return orcid;
    }
    return 'https://orcid.org/'.concat(orcid);
  };

  const footerError = (
    <Button
      label={t('projectProposal.ok')}
      className="w-7rem"
      severity="danger"
      onClick={() => navigate(-1)}
      autoFocus
    />
  );

  const showErrorDialog = (error) => {
    <Dialog
      header={<p className="text-red-500">{t('toast.error')}</p>}
      visible
      closable={false}
      draggable={false}
      style={{ width: '400px' }}
      footer={footerError}
    >
      <p className="m-0 text-lg text-red-500">{mapError(t, error)}</p>
    </Dialog>;
  };

  if (isAboutInfoError) {
    return showErrorDialog(aboutInfoError);
  }

  if (isResearchAndTeachingError) {
    return showErrorDialog(researchAndTeachingError);
  }

  if (isPublishedScientficWorksError) {
    return showErrorDialog(publishedScientficWorksError);
  }

  if (isAwardsError) {
    return showErrorDialog(awardsError);
  }
  if (isOthersError) {
    return showErrorDialog(othersError);
  }

  // #endregion Event

  // #region Render

  const itemInfo = (icon, label) => (
    <div className="flex text-left text-white mt-4 mb-4">
      <span className={icon} style={{ fontSize: '1.5rem' }} />
      <span className="text-lg text-wrap font-semibold">{label}</span>
    </div>
  );

  return (
    <>
      {/* #region Loading */}
      <div className="sticky top-0 left-0 z-5">
        <div className="relative">
          {(isAboutInfoFetching ||
            isResearchAndTeachingFetching ||
            isPublishedScientficWorksFetching ||
            isAwardsFetching ||
            isOthersFetching ||
            isAboutInfoLoading ||
            isResearchAndTeachingLoading ||
            isPublishedScientficWorksLoading ||
            isAwardsLoading ||
            isOthersLoading) && <Loading />}
        </div>
      </div>
      {/* #endregion Loading */}

      <AppTopbar ref={topbarRef} />
      <div
        className="lg:flex lg:mr-8 lg:ml-8 justify-center"
        style={{ marginTop: '6rem', marginBottom: '2rem' }}
      >
        <Fieldset className="side-bar lg:mr-4 text-center lg:max-w-64">
          <div className="flex-column justify-center">
            <Avatar
              image={
                imagePreview ||
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
              }
              className="mr-2"
              style={{ width: '154px', height: '154px' }} // Customize the width and height here
              shape="circle"
            />
            <div className="mt-3 mb-2">
              <span style={{ color: 'var(--primary-color-text)' }}>
                {bindingAboutData?.academicRank
                  ? `${t(`academicRank.${bindingAboutData?.academicRank}`)}, `
                  : ''}
                {bindingAboutData?.academicDegree
                  ? t(`academicDegree.${bindingAboutData?.academicDegree}`)
                  : ''}
              </span>
            </div>
            <div>
              <span className="text-lg md:text-2xl xl:text-2xl font-semibold text-white">
                {bindingAboutData?.fullname}
              </span>
            </div>
            <hr
              className="border-1 text-primary-color"
              style={{
                marginTop: '1rem',
                width: '100%',
                backgroundColor: 'var(--primary-color-text)',
                borderColor: 'transparent',
                height: '1.5px',
              }}
            />

            {aboutInfoData?.data?.scientificProfile?.ORCIDcode && (
              <>
                <div className="flex text-left text-white mt-4 mb-4 justify-content-center content-center">
                  <div className="mr-2">
                    <img style={{ width: '1.5rem' }} src="/images/ORCID-logo.png" alt="" />
                  </div>
                  <a
                    className="text-lg text-wrap font-semibold text-white hover:underline cursor-pointer"
                    href={formatORCID(aboutInfoData?.data?.scientificProfile?.ORCIDcode) || '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {aboutInfoData?.data?.scientificProfile?.ORCIDcode}
                  </a>
                </div>

                <hr
                  style={{
                    width: '100%',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    height: '1.5px',
                    background: 'white',
                    opacity: '0.7',
                  }}
                />
              </>
            )}

            <div className="justify-center lg:justify-start">
              {bindingAboutData?.organizationName &&
                itemInfo(
                  'pi pi-home mr-2',
                  t('lang') === 'vi'
                    ? bindingAboutData?.organizationName
                    : bindingAboutData?.organizationEnglishName
                )}
              {bindingAboutData?.faculty &&
                itemInfo('pi pi-briefcase mr-2', bindingAboutData?.faculty)}
              {bindingAboutData?.officeAddress &&
                itemInfo('pi pi-map-marker mr-2', bindingAboutData?.officeAddress)}
              {/* {bindingAboutData?.personalPhone &&
                itemInfo('pi pi-phone mr-2', bindingAboutData?.personalPhone)} */}
              {bindingAboutData?.officeEmail &&
                itemInfo('pi pi-envelope mr-2', bindingAboutData?.officeEmail)}
              {}
            </div>
            {user?.email === aboutInfoData?.data?.email && (
              <>
                <hr
                  style={{
                    width: '100%',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    height: '1.5px',
                    background: 'white',
                    opacity: '0.7',
                  }}
                />
                <div
                  style={{
                    backgroundColor: 'var(--cyan-500)',
                    color: 'var(--highlight-text-color)',
                    borderRadius: 'var(--border-radius)',
                    padding: '1rem',
                    width: '100%',
                  }}
                  className="cursor-pointer hover:bg-cyan-400"
                >
                  <a href="/scientific-profile/edit">
                    <div className="flex">
                      {/* icon user edi */}
                      <div className="flex justify-center" style={{ alignItems: 'center' }}>
                        <i
                          className="pi pi-user-edit"
                          style={{ fontSize: '2rem', color: 'white' }}
                        />
                      </div>
                      <span
                        className="ml-4 text-base font-semibold"
                        style={{ color: 'white', alignContent: 'center' }}
                      >
                        Chỉnh sửa hồ sơ của bạn
                      </span>
                    </div>
                  </a>
                </div>
              </>
            )}

            {/* {user?.email === aboutInfoData?.data?.email && (
              <Button
                style={{
                  backgroundColor: 'var(--cyan-500)',
                  color: 'var(--highlight-text-color)',
                  borderRadius: 'var(--border-radius)',
                  padding: '1rem',
                  marginTop: '1rem',
                  borderColor: 'var(--cyan-500)',
                  width: '100%',
                }}
                className="cursor-pointer hover:bg-cyan-400"
                onClick={() => {
                  setVisibleDialog(true);
                }}
              >
                <div>
                  <div className="flex">
                    <div
                      className="flex justify-center items-centerm mr-2"
                      style={{ alignItems: 'center' }}
                    >
                      <i className="pi pi-key" style={{ fontSize: '1.5rem', color: 'white' }} />
                    </div>
                    <span style={{ color: 'white', maxWidth: '12rem' }}>
                      {' '}
                      Custom Public Profile ID
                    </span>
                  </div>
                </div>
              </Button>
            )} */}
          </div>
        </Fieldset>

        <div className="card info-card p-0">
          <TabView
            activeIndex={currentTab}
            onTabChange={(e) => {
              setCurrentTab(e.index);
            }}
          >
            <TabPanel header={t('scientificProfile.preview.about').toUpperCase()}>
              <AboutTab data={bindingAboutData} />
            </TabPanel>
            <TabPanel header={t('scientificProfile.preview.sectionII').toUpperCase()}>
              <ResearchAndTeachingTab data={bindingResearchAndTeachingData} />
            </TabPanel>
            <TabPanel header={t('scientificProfile.scientificWork.publicLabel').toUpperCase()}>
              <PublishedScientificWorksTab data={bindingPublishedScientficWorksData} />
            </TabPanel>
            <TabPanel header={t('scientificProfile.preview.sectionIV').toUpperCase()}>
              <AwadsTab data={bindingAwardsData} />
            </TabPanel>
            <TabPanel header={t('scientificProfile.preview.sectionV').toUpperCase()}>
              <OthersInfoTab data={bindingOthersData} />
            </TabPanel>
          </TabView>
        </div>
      </div>

      <CreatePublicProfileDialog
        visible={visibleDialog}
        setVisible={setVisibleDialog}
        publicProfileId={idProfile}
        scientificProfileId={bindingAboutData?.id}
      />
    </>
  );
}

PublicProfile.propTypes = {
  idProfile: PropTypes.string,
};

PublicProfile.defaultProps = {
  idProfile: '',
};
