import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { mapErrorDialog } from 'utils/func';

export default function ErrorDialog({ title, isError, error, visible, setVisible, isNavigate }) {
  const navigate = useNavigate();
  const [resError, setResError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const parseError = async (_error) => {
      if (_error) {
        if (
          _error.request.responseType === 'blob' &&
          _error.response.data instanceof Blob &&
          _error.response.data.type &&
          _error.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
          setResError({ ...JSON.parse(await _error.response.data.text()) });
        } else {
          setResError({ ...error.response.data });
        }
      }
    };
    parseError(error);
  }, [error]);

  return (
    <div>
      {isError && (
        <Dialog
          header={<p className="text-red-500">{title}</p>}
          visible={isNavigate || visible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => (isNavigate ? navigate(-1) : setVisible(false))}
          draggable={false}
        >
          {Array.isArray(error.response.data.message) ? (
            error.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapErrorDialog(t, resError)}</p>
          )}
        </Dialog>
      )}
    </div>
  );
}

ErrorDialog.propTypes = {
  title: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func,
  isNavigate: PropTypes.bool,
};

ErrorDialog.defaultProps = {
  title: '',
  error: null,
  setVisible: () => null,
  isNavigate: false,
};
