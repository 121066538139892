/* eslint-disable indent */
import { NumberInput } from 'components/FormControl';
import { maxPoints } from 'features/AcceptanceReview/_constant';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function EvaluateTable({ setValue, getValues, watch, control, errors }) {
  const { t } = useTranslation();
  // const [total, setTotal] = useState(0);

  const score = watch('score');
  const totals = useMemo(() => {
    if (score) {
      const sum =
        (score?.scoreResponse ?? 0) +
        (score?.scoreQuantityAndQuality ?? 0) +
        (score?.scoreReportQuality ?? 0);
      return sum;
    }
    return 0;
  }, [score, score?.scoreResponse, score?.scoreQuantityAndQuality, score?.scoreReportQuality]);

  const handleNumberInputChange = useCallback(
    (name) => {
      switch (name) {
        case 'score.scoreApplicationProduct':
        case 'score.scorePublishedProduct':
        case 'score.scoreTrainingProduct': {
          const scoreApplicationProduct = getValues('score.scoreApplicationProduct') ?? 0;
          const scorePublishedProduct = getValues('score.scorePublishedProduct') ?? 0;
          const scoreTrainingProduct = getValues('score.scoreTrainingProduct') ?? 0;
          const total = scoreApplicationProduct + scorePublishedProduct + scoreTrainingProduct;
          setValue('score.scoreQuantityAndQuality', total);
          break;
        }
        case 'score.scoreTarget':
        case 'score.scoreContent':
        case 'score.scoreApproach':
        case 'score.scoreProcess': {
          const scoreTarget = getValues('score.scoreTarget') ?? 0;
          const scoreContent = getValues('score.scoreContent') ?? 0;
          const scoreApproach = getValues('score.scoreApproach') ?? 0;
          const scoreProcess = getValues('score.scoreProcess') ?? 0;
          const total = scoreTarget + scoreContent + scoreApproach + scoreProcess;
          setValue('score.scoreResponse', total);
          break;
        }
        default:
          break;
      }
    },
    [setValue, getValues]
  );

  return (
    <div className="form-table">
      <div className="text-lg mt-3">
        <h5>{t('council.m08.b.label')}</h5>
        <p className="font-italic">{t('council.m08.b.note')}</p>
      </div>
      <table className="my-2">
        <thead>
          <tr>
            <th className="text-center" style={{ minWidth: 30, width: 50 }}>
              {t('projectProposal.expenditure.a5.fields.order')}
            </th>
            <th className="text-center" style={{ minWidth: 260, width: 460 }}>
              {t('council.m01.b.evaluationContent')}
            </th>
            <th className="text-center" style={{ minWidth: 150, width: 150 }}>
              {t('council.m01.b.evaluationMaxPoint')}
            </th>
            <th className="text-center" style={{ minWidth: 150, width: 180 }}>
              {t('council.m01.b.evaluationPoint')}
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Content 1 */}
          <tr>
            <td className="font-bold text-center">1.</td>
            <td className="font-bold">{t('council.m08.b.evaluation1')}</td>
            <td className="text-center font-bold">{`${maxPoints.scoreResponse}`}</td>
            <td>
              <NumberInput
                name="score.scoreResponse"
                disabled
                control={control}
                errors={errors}
                defaultValue={0}
              />
            </td>
          </tr>
          <tr>
            <td rowSpan={4} />
            <td className="font-italic">{t('council.m08.b.target')}</td>
            <td className="text-center font-italic">{`${maxPoints.scoreTarget}`}</td>
            <td>
              <NumberInput
                name="score.scoreTarget"
                control={control}
                errors={errors}
                defaultValue={0}
                onCustomChange={handleNumberInputChange}
              />
            </td>
          </tr>
          <tr>
            <td className="hidden" />
            <td className="font-italic">{t('council.m08.b.content')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scoreContent}`}</td>
            <td>
              <NumberInput
                name="score.scoreContent"
                control={control}
                errors={errors}
                defaultValue={0}
                onCustomChange={handleNumberInputChange}
              />
            </td>
          </tr>
          <tr>
            <td className="hidden" />
            <td className="font-italic">{t('council.m08.b.approach')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scoreApproach}`}</td>
            <td>
              <NumberInput
                name="score.scoreApproach"
                control={control}
                errors={errors}
                defaultValue={0}
                onCustomChange={handleNumberInputChange}
              />
            </td>
          </tr>
          <tr>
            <td className="hidden" />
            <td className="font-italic">
              {t('council.m08.b.timeAndPerformance.label')}
              <ul className="my-2">
                <li>{t('council.m08.b.timeAndPerformance.onTime')}</li>
                <li>{t('council.m08.b.timeAndPerformance.belowSixMonths')}</li>
                <li>{t('council.m08.b.timeAndPerformance.overSixMonths')}</li>
              </ul>
            </td>
            <td className="text-center font-italic">{`${maxPoints?.scoreProcess}`}</td>
            <td>
              <NumberInput
                name="score.scoreProcess"
                control={control}
                errors={errors}
                defaultValue={0}
                onCustomChange={handleNumberInputChange}
              />
            </td>
          </tr>
          {/* End content 1 */}
          {/* Content 2 */}
          <tr>
            <td className="font-bold text-center">2.</td>
            <td className="font-bold">{t('council.m08.b.evaluation2')}</td>
            <td className="text-center font-bold">{`${maxPoints?.scoreQuantityAndQuality}`}</td>
            <td>
              <NumberInput
                name="score.scoreQuantityAndQuality"
                control={control}
                errors={errors}
                defaultValue={0}
                disabled
              />
            </td>
          </tr>
          <tr>
            <td rowSpan={3} />
            <td className="font-italic">{t('council.m08.b.applicationProduct')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scoreApplicationProduct}`}</td>
            <td>
              <NumberInput
                name="score.scoreApplicationProduct"
                control={control}
                errors={errors}
                defaultValue={0}
                onCustomChange={handleNumberInputChange}
              />
            </td>
          </tr>
          <tr>
            <td className="hidden" />
            <td className="font-italic">{t('council.m08.b.announcedProduct')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scorePublishedProduct}`}</td>
            <td>
              <NumberInput
                name="score.scorePublishedProduct"
                control={control}
                errors={errors}
                defaultValue={0}
                onCustomChange={handleNumberInputChange}
              />
            </td>
          </tr>
          <tr>
            <td className="hidden" />
            <td className="font-italic">{t('council.m08.b.trainingProduct')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scoreTrainingProduct}`}</td>
            <td>
              <NumberInput
                name="score.scoreTrainingProduct"
                control={control}
                errors={errors}
                defaultValue={0}
                onCustomChange={handleNumberInputChange}
              />
            </td>
          </tr>
          {/* End content 2 */}
          {/* Content 3 */}
          <tr>
            <td className="font-bold text-center">3.</td>
            <td className="font-bold">{t('council.m08.b.evaluation3')}</td>
            <td className="text-center font-bold">{`${maxPoints.scoreReportQuality}`}</td>
            <td>
              <NumberInput
                name="score.scoreReportQuality"
                control={control}
                errors={errors}
                defaultValue={0}
              />
            </td>
          </tr>

          {/* End content 3 */}
          {/* totals */}
          <tr>
            <td className="font-bold text-center" colSpan={2}>
              {t('council.m01.b.total')}
            </td>
            <td className="text-center">
              <b>100</b>
            </td>
            <td className="text-center">
              <b>{totals}</b>
            </td>
          </tr>
          {/* End totals */}
        </tbody>
      </table>
    </div>
  );
}

EvaluateTable.propTypes = {
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};
