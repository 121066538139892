import { useTranslation } from 'react-i18next';

export default function Signature() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-column align-items-end py-5 pr-5" style={{ gap: '9rem' }}>
      <div className="flex justify-content-between" style={{ gap: '9rem' }}>
        <div className="text-center">
          <p className="m-0 font-italic text-xl">{t('signature.date')}</p>
          <p className="m-0 font-semibold text-xl">{t('signature.owner')}</p>
          <p className="m-0 font-italic text-xl">{t('signature.fullnameAndSignature')}</p>
        </div>
      </div>
    </div>
  );
}
