/* eslint-disable indent */
import { NumberInput } from 'components/FormControl';
import { maxPoints } from 'features/SpecializeReview/_constant';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export default function EvaluationForm({ setValue, watch, control, errors }) {
  const { t } = useTranslation();
  const score = watch('score');
  const totals = useMemo(() => {
    const scoreImportant = (score?.scoreSuitableness ?? 0) + (score?.scoreCreativity ?? 0);
    setValue('score.scoreImportance', scoreImportant);
    const scoreProduct = (score?.scoreTraining ?? 0) + (score?.scoreAnnouncement ?? 0);
    setValue('score.scoreProduct', scoreProduct);
    return (
      scoreImportant +
      (score?.scoreQuality ?? 0) +
      scoreProduct +
      (score?.scoreCapability ?? 0) +
      (score?.scoreSubCriteria ?? 0)
    );
  }, [
    score?.scoreSuitableness,
    score?.scoreCreativity,
    score?.scoreTraining,
    score?.scoreAnnouncement,
    score?.scoreQuality,
    score?.scoreCapability,
    score?.scoreSubCriteria,
  ]);

  return (
    <div className="form-table ">
      <table className="mb-2">
        <thead>
          <tr>
            <th className="text-center" style={{ minWidth: 30, width: 30 }}>
              {t('projectProposal.expenditure.a5.fields.order')}
            </th>
            <th className="text-center" style={{ minWidth: 260, width: 460 }}>
              {t('council.m01.b.evaluationContent')}
            </th>
            <th className="text-center" style={{ minWidth: 150, width: 150 }}>
              {t('council.m01.b.evaluationMaxPoint')}
            </th>
            <th className="text-center" style={{ minWidth: 150, width: 260 }}>
              {t('council.m01.b.evaluationPoint')}
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Content 1 */}
          <tr>
            <td className="font-bold text-center">1.</td>
            <td className="font-bold">{t('council.m01.b.evaluation1')}</td>
            <td className="text-center font-bold">
              {`${maxPoints.scoreCreativity}`} + {`${maxPoints.scoreSuitableness}`} ={' '}
              {`${maxPoints.scoreImportance}`}
            </td>
            <td>
              <div className="flex align-items-stretch">
                <div className="flex align-items-center justify-content-center">
                  <NumberInput
                    name="score.scoreCreativity"
                    control={control}
                    errors={errors}
                    defaultValue={0}
                  />
                </div>
                <div className="flex align-items-center justify-content-center mx-2">+</div>
                <div className="flex align-items-center justify-content-center">
                  <NumberInput
                    name="score.scoreSuitableness"
                    control={control}
                    errors={errors}
                    defaultValue={0}
                  />
                </div>
                <div className="flex align-items-center justify-content-center mx-2">=</div>
                <div className="flex align-items-center justify-content-center">
                  <NumberInput
                    name="score.scoreImportance"
                    disabled
                    control={control}
                    errors={errors}
                    defaultValue={0}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={2} />
            <td className="font-italic">{t('council.m01.b.evaluation11')}</td>
            <td className="text-center font-italic">{`${maxPoints.scoreCreativity}`}</td>
            <td rowSpan={2} />
          </tr>
          <tr>
            <td className="hidden" />
            <td className="font-italic">{t('council.m01.b.evaluation12')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scoreSuitableness}`}</td>
            <td className="hidden" />
          </tr>
          {/* End content 1 */}
          {/* Content 2 */}
          <tr>
            <td className="font-bold text-center">2.</td>
            <td className="font-bold">{t('council.m01.b.evaluation2')}</td>
            <td className="text-center font-bold">{`${maxPoints?.scoreQuality}`}</td>
            <td>
              <NumberInput
                name="score.scoreQuality"
                control={control}
                errors={errors}
                defaultValue={0}
              />
            </td>
          </tr>
          {/* End content 2 */}
          {/* Content 3 */}
          <tr>
            <td className="font-bold text-center">3.</td>
            <td className="font-bold">{t('council.m01.b.evaluation3')}</td>
            <td className="text-center font-bold">
              {`${maxPoints.scoreAnnouncement}`} + {`${maxPoints.scoreTraining}`} ={' '}
              {`${maxPoints.scoreProduct}`}
            </td>
            <td>
              <div className="flex align-items-stretch">
                <div className="flex align-items-center justify-content-center">
                  <NumberInput
                    name="score.scoreAnnouncement"
                    control={control}
                    errors={errors}
                    defaultValue={0}
                  />
                </div>
                <div className="flex align-items-center justify-content-center mx-2">+</div>
                <div className="flex align-items-center justify-content-center">
                  <NumberInput
                    name="score.scoreTraining"
                    control={control}
                    errors={errors}
                    defaultValue={0}
                  />
                </div>
                <div className="flex align-items-center justify-content-center mx-2">=</div>
                <div className="flex align-items-center justify-content-center">
                  <NumberInput
                    name="score.scoreProduct"
                    disabled
                    control={control}
                    errors={errors}
                    defaultValue={0}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={2} />
            <td className="font-italic">{t('council.m01.b.evaluation31')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scoreAnnouncement}`}</td>
            <td rowSpan={2} />
          </tr>
          <tr>
            <td className="hidden" />
            <td className="font-italic">{t('council.m01.b.evaluation33')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scoreTraining}`}</td>
            <td className="hidden" />
          </tr>
          {/* End content 3 */}
          {/* Content 4 */}
          <tr>
            <td className="font-bold text-center">4.</td>
            <td className="font-bold">{t('council.m01.b.evaluation4')}</td>
            <td className="text-center font-bold">{`${maxPoints?.scoreCapability}`}</td>
            <td>
              <NumberInput
                name="score.scoreCapability"
                control={control}
                errors={errors}
                defaultValue={0}
              />
            </td>
          </tr>
          {/* End content 4 */}
          {/* Content 5 */}
          <tr>
            <td className="font-bold text-center">5.</td>
            <td>
              <div className="font-bold">{t('council.m01.b.evaluation5')}</div>
              <div className="font-italic ml-3 text-red-600">
                - {t('council.m01.b.evaluation51')}
              </div>
              <div className="font-italic ml-3 text-red-600">
                - {t('council.m01.b.evaluation52')}
              </div>
              <div className="font-italic ml-3 text-red-600">
                - {t('council.m01.b.evaluation53')}
              </div>
            </td>
            <td className="text-center font-bold">{`${maxPoints?.scoreSubCriteria}`}</td>
            <td>
              <NumberInput
                name="score.scoreSubCriteria"
                control={control}
                errors={errors}
                defaultValue={0}
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold text-center" colSpan={2}>
              {t('council.m01.b.total')}
            </td>
            <td className="text-center">
              <b>100</b>
            </td>
            <td className="text-right font-bold">{totals}</td>
          </tr>
          {/* End content 5 */}
        </tbody>
      </table>
    </div>
  );
}

EvaluationForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};
