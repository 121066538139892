import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { getAcadamicLevelShortHand, getContactOrganizationNameV2 } from 'utils/func';

export default function DetailInfo({ generalProjectProposalInfo, reviewerInfo, isShowReviewer }) {
  const { t } = useTranslation();
  return (
    <section className="mx-0 md:mx-4 xl:mx-8 mb-3">
      <div className={`border-1 border-dashed ${isShowReviewer ? 'border-bottom-none' : ''} p-2`}>
        <p className="text-lg">
          <span className="font-semibold">{t('council.m01.info.vietnameseName')}: </span>
          {generalProjectProposalInfo?.vietnameseName}
        </p>
        <p className="text-lg">
          <span className="font-semibold">{t('council.m01.info.owner')}: </span>
          {/* {generalProjectProposalInfo?.researchType?.code &&
            t(`researchTypes.${generalProjectProposalInfo?.researchType?.code}`)} */}
          {`${getAcadamicLevelShortHand(
            generalProjectProposalInfo?.projectProposalContacts?.[0]?.academicRank,
            generalProjectProposalInfo?.projectProposalContacts?.[0]?.academicDegree,
            t,
            true
          )} ${generalProjectProposalInfo?.projectProposalContacts?.[0]?.fullname}`}
        </p>
      </div>
      {isShowReviewer && (
        <div className="border-1 border-dashed p-2">
          <p className="text-lg">
            <span className="font-semibold">{t('council.m01.info.reviewer.fullname')}: </span>
            {/* {reviewerInfo?.fullname} */}
            {`${getAcadamicLevelShortHand(
              reviewerInfo?.scientificProfile?.academicRank,
              reviewerInfo?.scientificProfile?.academicDegree,
              t,
              true
            )} ${reviewerInfo.fullname}`}
          </p>
          <p className="text-lg">
            {t('council.m01.info.reviewer.organization')}:{' '}
            {getContactOrganizationNameV2(reviewerInfo?.organization, reviewerInfo, t)}
          </p>
          <p className="text-lg flex flex-column sm:flex-row">
            <span className="pr-5">
              {t('council.m01.info.reviewer.personalPhone')}:{' '}
              {reviewerInfo?.scientificProfile?.personalPhone ?? ''}
            </span>
            <span>Email: {reviewerInfo?.email ?? ''}</span>
          </p>
          <p className="text-lg flex flex-column sm:flex-row">
            <span className="pr-5">
              {t('council.m01.info.reviewer.citizenId')}:{' '}
              {reviewerInfo?.scientificProfile?.citizenId ?? ''}
            </span>
            <span>
              {t('council.m01.info.reviewer.taxIdentificationNumber')}:{' '}
              {reviewerInfo?.scientificProfile?.taxIdentificationNumber ?? ''}
            </span>
          </p>
          <p className="text-lg flex flex-column sm:flex-row">
            <span className="pr-5">
              {t('council.m01.info.reviewer.bankAccount')}:{' '}
              {reviewerInfo?.scientificProfile?.bankAccount ?? ''}
            </span>
            <span>
              {t('council.m01.info.reviewer.bank')}: {reviewerInfo?.scientificProfile?.bank ?? ''}
            </span>
          </p>
        </div>
      )}
    </section>
  );
}

DetailInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  generalProjectProposalInfo: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  reviewerInfo: PropTypes.object,
  isShowReviewer: PropTypes.bool,
};

DetailInfo.defaultProps = {
  generalProjectProposalInfo: {},
  reviewerInfo: {},
  isShowReviewer: true,
};
