import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { currencyOptions } from 'constant';
import { TextareaInput, CurrencyInput } from 'components/FormControl';

export default function AdjustmentForm({ readOnly, control, errors, watch }) {
  // #region Data
  const { t } = useTranslation();
  const [total, setTotal] = useState({
    beforeExpenditureFromVNUHCM: 0,
    beforeExpenditureFromFunding: 0,
    afterExpenditureFromVNUHCM: 0,
    afterExpenditureFromFunding: 0,
  });

  const itemsWatch = watch('financialAdjustmentItems');

  useEffect(() => {
    if (itemsWatch?.length > 0) {
      setTotal(() => ({
        beforeExpenditureFromVNUHCM: itemsWatch.reduce(
          (acc, cur) =>
            cur?.subProposedExpenditureTypeId ? acc : acc + (cur?.beforeExpenditureFromVNUHCM || 0),
          0
        ),
        beforeExpenditureFromFunding: itemsWatch.reduce(
          (acc, cur) =>
            cur?.subProposedExpenditureTypeId
              ? acc
              : acc + (cur?.beforeExpenditureFromFunding || 0),
          0
        ),

        afterExpenditureFromVNUHCM: itemsWatch.reduce(
          (acc, cur) =>
            cur?.subProposedExpenditureTypeId ? acc : acc + (cur?.afterExpenditureFromVNUHCM || 0),
          0
        ),
        afterExpenditureFromFunding: itemsWatch.reduce(
          (acc, cur) =>
            cur?.subProposedExpenditureTypeId ? acc : acc + (cur?.afterExpenditureFromFunding || 0),
          0
        ),
      }));
    }
  }, [JSON.stringify(itemsWatch)]);

  return (
    <div className="form-table p-fluid">
      <table className="mb-2 w-full">
        <thead>
          <tr>
            <th className="text-center" style={{ minWidth: 50, width: 50 }}>
              {t('formLayout.numericalOrder')}
            </th>
            <th className="text-center" style={{ minWidth: 300, maxWidth: 300 }}>
              {t('financialAdjustment.table.name')}
            </th>
            <th style={{ border: 'none', padding: 0 }}>
              <table className="inner-table" style={{ minWidth: 300, height: 70 }}>
                <thead>
                  <tr>
                    <th colSpan={2}>{t('financialAdjustment.table.before')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 150 }}>Từ ĐHQG-HCM</td>
                    <td style={{ minWidth: 150 }}>Từ nguồn huy động</td>
                  </tr>
                </tbody>
              </table>
            </th>
            <th style={{ border: 'none', padding: 0 }}>
              <table className="inner-table" style={{ minWidth: 300, height: 70 }}>
                <thead>
                  <tr>
                    <th colSpan={2}>{t('financialAdjustment.table.after')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 150 }}>Từ ĐHQG-HCM</td>
                    <td style={{ minWidth: 150 }}>Từ nguồn huy động</td>
                  </tr>
                </tbody>
              </table>
            </th>

            <th className="text-center" style={{ minWidth: 200, width: '100%' }}>
              {t('financialAdjustment.table.reason')}
            </th>
          </tr>
        </thead>
        <tbody>
          {itemsWatch?.map((item, index) => (
            <tr key={item?.expenditureTypeId}>
              <td className="text-center">{index + 1}</td>
              <td>
                <TextareaInput
                  name={`financialAdjustmentItems.${index}.name`}
                  control={control}
                  errors={errors}
                  readOnly
                />
              </td>

              <td>
                <table style={{ border: 'none' }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          minWidth: 150,
                          padding: 0,
                          border: 'none',
                          paddingRight: 2,
                          width: '50%',
                        }}
                      >
                        <CurrencyInput
                          name={`financialAdjustmentItems.${index}.beforeExpenditureFromVNUHCM`}
                          control={control}
                          errors={errors}
                          readOnly
                          disabled={index === 1}
                        />
                      </th>
                      <th
                        style={{
                          minWidth: 150,
                          padding: 0,
                          border: 'none',
                          paddingLeft: 2,
                          width: '50%',
                        }}
                      >
                        <CurrencyInput
                          name={`financialAdjustmentItems.${index}.beforeExpenditureFromFunding`}
                          control={control}
                          errors={errors}
                          readOnly
                          disabled={index === 1}
                        />
                      </th>
                    </tr>
                  </thead>
                </table>
              </td>

              <td>
                <table style={{ border: 'none' }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          minWidth: 150,
                          padding: 0,
                          border: 'none',
                          paddingRight: 2,
                        }}
                      >
                        <CurrencyInput
                          name={`financialAdjustmentItems.${index}.afterExpenditureFromVNUHCM`}
                          control={control}
                          errors={errors}
                          disabled={index === 1}
                          readOnly={readOnly}
                        />
                      </th>
                      <th
                        style={{
                          minWidth: 150,
                          padding: 0,
                          border: 'none',
                          paddingLeft: 2,
                        }}
                      >
                        <CurrencyInput
                          name={`financialAdjustmentItems.${index}.afterExpenditureFromFunding`}
                          control={control}
                          errors={errors}
                          disabled={index === 1}
                          readOnly={readOnly}
                        />
                      </th>
                    </tr>
                  </thead>
                </table>
              </td>

              <td>
                <TextareaInput
                  name={`financialAdjustmentItems.${index}.reason`}
                  control={control}
                  errors={errors}
                  readOnly={readOnly}
                  disabled={index === 1}
                />
              </td>
            </tr>
          ))}

          <tr key={100} style={{ height: 36 }}>
            <td className="text-center" />
            <td className="font-bold">Tổng cộng</td>

            <td>
              <table style={{ border: 'none' }}>
                <thead>
                  <tr>
                    <th
                      className="text-right font-bold bg-white"
                      style={{
                        minWidth: 150,
                        padding: 0,
                        border: 'none',
                        paddingRight: 2,
                        borderRight: 1,
                      }}
                    >
                      {total.beforeExpenditureFromVNUHCM.toLocaleString('vi', currencyOptions)}
                    </th>
                    <th
                      className="text-right font-bold bg-white"
                      style={{
                        minWidth: 150,
                        padding: 0,
                        border: 'none',
                        paddingLeft: 2,
                      }}
                    >
                      {total.beforeExpenditureFromFunding.toLocaleString('vi', currencyOptions)}
                    </th>
                  </tr>
                </thead>
              </table>
            </td>

            <td>
              <table style={{ border: 'none', backgroundColor: '#fff' }}>
                <thead>
                  <tr>
                    <th
                      className="text-right font-bold bg-white"
                      style={{ minWidth: 150, padding: 0, border: 'none', paddingRight: 2 }}
                    >
                      {total.afterExpenditureFromVNUHCM.toLocaleString('vi', currencyOptions)}
                    </th>
                    <th
                      style={{
                        minWidth: 150,
                        padding: 0,
                        border: 'none',
                        paddingLeft: 2,
                      }}
                      className="text-right font-bold bg-white"
                    >
                      {total.afterExpenditureFromFunding.toLocaleString('vi', currencyOptions)}
                    </th>
                  </tr>
                </thead>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

AdjustmentForm.propTypes = {
  readOnly: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  watch: PropTypes.func.isRequired,
};

AdjustmentForm.defaultProps = {
  readOnly: false,
};
