import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ResearchField({ item, index }) {
  const { t } = useTranslation();

  return (
    <div className="flex ml-5 mb-2">
      <div className="mr-2 font-semibold">
        {t('projectProposal.general.a2.prio.researchField')} {item?.priority ?? index + 1}:{' '}
      </div>
      <div className="w-20rem" style={{ marginRight: '50px' }}>
        {item?.researchField?.code ? t(`researchFields.${item?.researchField?.code}`) : item?.researchField?.name}
      </div>
      <div className="font-semibold mr-2 ">{t('projectProposal.general.a2.researchLine')}: </div>
      <div className="flex-1">{item?.researchLine}</div>
    </div>
  );
}

export default function ResearchFields({ researchFields }) {
  return (
    <div>
      {researchFields
        ?.sort((a, b) => {
          if (a.priority === null) return 1;
          if (b.priority === null) return -1;
          if (a.priority === b) return 0;
          return a.priority < b.priority ? -1 : 1;
        })
        .map((item, index) => (
          <ResearchField item={item} index={index} key={item?.researchField.id} />
        ))}
    </div>
  );
}

ResearchField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
ResearchFields.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  researchFields: PropTypes.array.isRequired,
};
