import LanguageSelect from 'components/LanguageSelect';

import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { yupResolver } from '@hookform/resolvers/yup';

import TextInput from 'components/FormControl/TextInput';
import Loading from 'components/Loading';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { forgotPassword } from 'apis/users.api';
import { forgotPasswordValidationSchema } from '../validation';

const formOptions = { resolver: yupResolver(forgotPasswordValidationSchema) };

export default function ForgotPassword() {
  // #region Data
  const { t } = useTranslation();
  const toast = useRef(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(formOptions);

  const { mutate, isLoading, isSuccess } = useMutation(forgotPassword);
  // #endregion Data

  // #region Event
  const showSuccess = () => {
    toast.current.show({
      severity: 'success',
      summary: t('toast.success'),
      detail: t('forgotPassword.message.success'),
      life: 4000,
    });
  };

  const showError = () => {
    toast.current.show({
      severity: 'error',
      summary: t('toast.error'),
      detail: t('forgotPassword.message.error.emailNotFound'),
      life: 4000,
    });
  };

  const onSubmit = async (forgotPassParam) => {
    mutate(forgotPassParam, {
      onSuccess: () => showSuccess(),
      onError: () => showError(),
    });
  };
  // #endregion Event

  return (
    <>
      <div className="p-4 fixed right-0">
        <LanguageSelect />
      </div>
      <div className="flex align-items-center justify-content-center" style={{ height: '80vh' }}>
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: '400px' }}
        >
          <div className="text-center mb-5">
            <img src="/logo.png" alt="hyper" height={50} className="mb-3" />
            <div className="text-900 text-3xl font-medium mb-3">{t('forgotPassword.title')}</div>
            <p className="ml-5 mr-5">{t('forgotPassword.guide')}</p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <TextInput
              label="Email"
              name="email"
              type="text"
              autoFocus
              control={control}
              errors={errors}
              isRequired
            />

            <Button
              label={isSuccess ? t('forgotPassword.sent') : t('forgotPassword.send')}
              icon="pi pi-user"
              className="w-full bg-cyan-600 border-cyan-600 mt-6"
              type="submit"
              disabled={isSuccess}
            />
          </form>
        </div>
        {isLoading && <Loading />}
        <Toast ref={toast} />
      </div>
    </>
  );
}
