import * as Yup from 'yup';

export const totalExpenditureSchema = Yup.object().shape({
  min: Yup.number()
    .test({
      name: 'max',
      exclusive: false,
      params: {},
      test: (value, context) => {
        const { max } = context.parent;
        if (!max || !value) return true;
        return value <= max;
      },
    })
    .nullable(),
  max: Yup.string()
    .test({
      name: 'min',
      exclusive: false,
      params: {},
      test: (value, context) => {
        const { min } = context.parent;
        if (!min || !value) return true;
        return value >= min;
      },
    })
    .nullable(),
});

const mapRules = (map, rule) => {
  if (!map) return {};
  return Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});
};

const emailValidationNotRequired = Yup.string()
  .email('formControl.validation.emailInvalid')
  .nullable();

const emailValidationRequired = Yup.string()
  .email('formControl.validation.emailInvalid')
  .required('formControl.validation.required');

const organizationSchema = Yup.object({
  organizationId: Yup.string().required('formControl.validation.required'),
  name: Yup.string().required('formControl.validation.required'),
  specificName: Yup.string().when('englishName', {
    is: 'Other',
    then: Yup.string().required('formControl.validation.required'),
  }),
  email: emailValidationNotRequired,
  partnerContribution: Yup.string()
    .test(
      'noMoreThan500',
      'formControl.validation.noMoreThan500',
      (data) => !data || data?.split(' ').length <= 500
    )
    .nullable(),
  type: Yup.string().oneOf(['LEAD', 'PARTNER']).required(),
});

const partnerSchema = organizationSchema.concat(
  Yup.object().shape({
    specificName: Yup.string().required('formControl.validation.required'),
    chief: Yup.string().required('formControl.validation.required'),
    phone: Yup.string().required('formControl.validation.required'),
    fax: Yup.string().required('formControl.validation.required'),
    address: Yup.string().required('formControl.validation.required'),
    file: Yup.mixed().when('partnerConfirmationUrl', {
      is: (value) => !value,
      then: Yup.mixed().test('isRequired', 'formControl.validation.required', (value) => {
        if (value) return true;
        return false;
      }),
    }),
  })
);

const researcherSchema = Yup.object({
  projectRoleId: Yup.string().required('formControl.validation.required'),
  contact: Yup.object({
    fullname: Yup.string(),
    userId: Yup.string().nullable(),
    scientificProfile: Yup.object({
      academicRank: Yup.string().nullable(),
      academicDegree: Yup.string().nullable(),
    }).notRequired(),
  })
    .required('formControl.validation.required')
    .nullable(),
  organizationId: Yup.string().required('formControl.validation.required'),
  assignedTask: Yup.string().required('formControl.validation.required'),
});
const kpiSchema = Yup.lazy((obj) =>
  Yup.object(
    mapRules(
      obj,
      Yup.object({
        quantity: Yup.number().nullable(),
        content: Yup.string().test({
          name: 'quantity',
          exclusive: false,
          params: {},
          message: 'formControl.validation.required',
          test: (value, context) => {
            const { quantity } = context.parent;
            if (!quantity) return true;
            return value;
          },
        }),
      })
    )
  )
);
const createProjectProposalValidationSchema = Yup.object().shape({
  general: Yup.object().shape({
    // A1. Tên đề tài
    vietnameseName: Yup.string().required('formControl.validation.required'),
    englishName: Yup.string().required('formControl.validation.required'),
    projectType: Yup.string().required('formControl.validation.required'),
    // A2. Thuộc ngành / nhóm ngành (N/NN)
    projectProposalResearchFields: Yup.array().of(
      Yup.object().shape({
        researchFieldId: Yup.string(),
        researchLine: Yup.string().nullable(),
      })
    ),
    recommendedExperts: Yup.array().of(
      Yup.object().shape({
        fullname: Yup.string().required('formControl.validation.required'),
        researchLine: Yup.string(),
        workplace: Yup.string(),
        email: emailValidationRequired,
        phone: Yup.string(),
      })
    ),
    // A3. Loại hình nghiên cứu
    researchTypesId: Yup.string().required('formControl.validation.required'),
    // A4. Thời gian thực hiện
    implementationMonths: Yup.number().required('formControl.validation.required').nullable(),
    // A5. Tổng kinh phí
    researchExpense: Yup.object().shape({
      totalExpenditure: Yup.number().min(0).nullable(),
      nationalUniversityTotalExpenditure: Yup.number().min(0).nullable(),
      fundingExpenditure: Yup.number().min(0).nullable(),
      otherFunding: Yup.string(),
    }),
    // A6. Chủ nhiệm
    owner: Yup.object()
      .shape({
        name: Yup.string().nullable(),
        academicRank: Yup.string().nullable(),
        academicDegree: Yup.string().nullable(),
        researchLines: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string().required('formControl.validation.required'),
            })
          )
          .nullable(),
        dob: Yup.string().nullable(),
        citizenId: Yup.string().nullable(),
        dateOfIssue: Yup.string().nullable(),
        placeOfIssue: Yup.string().nullable(),
        taxIdentificationNumber: Yup.string().nullable(),
        bankAccount: Yup.string().nullable(),
        bank: Yup.string().nullable(),
        workplace: Yup.string().nullable(),
        mobile: Yup.string().nullable(),
        email: emailValidationNotRequired,
        experience: Yup.string()
          .test(
            'noMoreThan500',
            'formControl.validation.noMoreThan500',
            (data) => !data || data?.split(' ').length <= 500
          )
          .nullable(),
      })
      .required('Owner is required'),
    // A7. Cơ quan chủ trì
    lead: organizationSchema.required('Lead is required'),
    // A8. Đối tác có đóng góp cho nghiên cứu
    partners: Yup.array().of(partnerSchema),
    // A9. Nhân lực nghiên cứu
    owners: Yup.array().of(researcherSchema),
    scientificSecretaries: Yup.array().of(researcherSchema),
    mainParticipants: Yup.array().of(researcherSchema),
    participants: Yup.array().of(researcherSchema),
    technicians: Yup.array().of(researcherSchema),
    domesticExperts: Yup.array().of(researcherSchema),
    internationalExperts: Yup.array().of(researcherSchema),
  }),
  researchResult: Yup.object().shape({
    // A10.1 Mô tả sản phẩm / kết quả nghiên cứu (bắt buộc)
    softProducts: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('formControl.validation.required'),
        evaluativeCriteria: Yup.string().required('formControl.validation.required'),
        note: Yup.string().nullable(),
      })
    ),
    hardProducts: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('formControl.validation.required'),
        qualityCriteria: Yup.string().required('formControl.validation.required'),
        measure: Yup.string().required('formControl.validation.required'),
        evaluativeCriteria: Yup.string().required('formControl.validation.required'),
        domesticModelQuality: Yup.string(),
        internationalModelQuality: Yup.string(),
      })
    ),
    qualityComparison: Yup.string(),
    projectProposalResearchKpis: kpiSchema,
  }),
  researchDescription: Yup.object().shape({
    otherProofsAndAppendicesFiles: Yup.array().of(
      Yup.object().shape({
        file: Yup.mixed().when('fileName', {
          is: (value) => !value,
          then: Yup.mixed().test('isRequired', 'formControl.validation.required', (value) => {
            if (value) return true;
            return false;
          }),
        }),
      })
    ),
  }),
});

export default createProjectProposalValidationSchema;

export const completeExplanationValidationSchema = Yup.object().shape({
  ownerComment: Yup.string().required(
    'projectProposal.completeExplanationRequest.validationErrors.commentRequired'
  ),
});
