import { useEffect, useMemo, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { getAll as getAllOrganizations } from 'apis/organizations.api';
import { completeProfile } from 'apis/users.api';

import { Select, TextInput, TextareaInput } from 'components/FormControl';
import LanguageSelect from 'components/LanguageSelect';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import TokenService from 'utils/axios/token.axios';
import { getAcademicDegrees, getAcademicRanks, mapError } from 'utils/func';

import { completeProfileSchema } from '../validation';

const formOptions = { resolver: yupResolver(completeProfileSchema) };

function CompleteProfile() {
  // #region Data
  const { t } = useTranslation();
  const toast = useRef(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm(formOptions);

  const { mutate, isLoading } = useMutation(completeProfile);

  const { data: organizationsData } = useQuery(['organizations'], getAllOrganizations);
  const organizations = useMemo(
    () =>
      organizationsData?.data.map((e) => ({
        ...e,
        name: t('lang') === 'vi' ? e?.name : e?.englishName,
      })) ?? [],
    [organizationsData, t]
  );

  const organizationId = watch('organizationId');
  const currentOrganization = useMemo(() => {
    if (organizations?.length) {
      const result = organizations?.find((item) => item?.id === organizationId) ?? {};
      setValue('organizationEnglishName', result?.englishName ?? '');
      if (result?.englishName !== 'Other') {
        setValue('specificOrganizationName', null);
      }
      return result;
    }
    return null;
  }, [organizations, organizationId]);

  const localUser = useMemo(() => TokenService.getUser(), []);
  // #endregion

  // #region event
  useEffect(() => {
    if (localUser?.id) {
      setValue('fullname', localUser?.fullname ?? '');
    }
  }, []);

  const showError = (errorMessage) => {
    toast.current.show({
      severity: 'error',
      summary: t('toast.error'),
      detail: errorMessage,
      life: 4000,
    });
  };

  const onSubmit = async (submitData) => {
    const completeProfileParams = {
      fullname: submitData.fullname,
      password: submitData.password,
      organizationId: submitData.organizationId,
      specificOrganizationName: submitData.specificOrganizationName,
      academicRank: submitData.academicRank,
      academicDegree: submitData.academicDegree,
    };

    mutate(completeProfileParams, {
      onSuccess: () => {
        TokenService.setUser({
          ...localUser,
          fullname: submitData.fullname,
          organizationId: submitData.organizationId,
        });
        setIsSuccessModalOpen(true);
      },
      onError: (error) => {
        showError(mapError(t, error));
      },
    });
  };

  const checkValidation = () => {
    if (!isValid) {
      showError(t('errorMessage.validationErrorMessage'));
    }
  };
  // #endregion
  const dialogFooter = () => (
    <div className="flex justify-content-center">
      <Link to="/login">
        <Button
          className="bg-cyan-600 border-cyan-600 text-center block font-bold"
          style={{ width: 100 }}
        >
          OK
        </Button>
      </Link>
    </div>
  );
  return (
    <>
      <div className="p-4 fixed right-0">
        <LanguageSelect />
      </div>
      <div className="flex align-items-center justify-content-center" style={{ height: '100vh' }}>
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: '400px' }}
        >
          <div className="text-center mb-5">
            <img src="/logo.png" alt="hyper" height={50} />
            <p className="text-900 text-xl font-medium mb-3 uppercase">{t('websiteName')}</p>
            <h4 className="text-900 text-3xl font-medium mb-3 uppercase">
              {t('completeProfile.label')}
            </h4>
            <span className="text-600 font-medium line-height-3">
              {t('completeProfile.updateLater')}
            </span>
            <Link to="/" className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">
              {t('completeProfile.backHome')}
            </Link>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <TextInput
              label={t('signup.fullname')}
              name="fullname"
              type="text"
              autoFocus
              control={control}
              errors={errors}
              isRequired
            />

            <div className="grid">
              <div className="col-6">
                <Select
                  label={t('signup.academicRank')}
                  name="academicRank"
                  options={getAcademicRanks(t)}
                  inputField="value"
                  control={control}
                  errors={errors}
                />
              </div>
              <div className="col-6">
                <Select
                  label={t('signup.academicDegree')}
                  name="academicDegree"
                  options={getAcademicDegrees(t)}
                  inputField="value"
                  control={control}
                  errors={errors}
                  isRequired
                />
              </div>
            </div>

            <Select
              label={t('signup.workplace')}
              name="organizationId"
              options={organizations ?? []}
              control={control}
              errors={errors}
              isRequired
            />

            {currentOrganization?.englishName === 'Other' && (
              <div className="mb-2">
                <TextareaInput
                  label={t('signup.specificOrganizationName')}
                  name="specificOrganizationName"
                  type="text"
                  control={control}
                  errors={errors}
                  isRequired
                />
              </div>
            )}

            <TextInput
              label={t('signup.password')}
              name="password"
              type="password"
              control={control}
              errors={errors}
              isRequired
            />

            <TextInput
              label={t('signup.confirmPassword')}
              name="confirmPassword"
              type="password"
              control={control}
              errors={errors}
              isRequired
            />

            <Button
              label={t('completeProfile.update')}
              icon="pi pi-user"
              className="w-full bg-cyan-600 border-cyan-600"
              type="submit"
              onClick={checkValidation}
            />
          </form>
        </div>
        {isLoading && <Loading />}

        <Toast ref={toast} />

        <Dialog
          header={<h3 className="text-green-400 text-center">{t('completeProfile.success')}</h3>}
          visible={isSuccessModalOpen}
          style={{ width: '350px' }}
          closable={false}
          draggable={false}
          footer={dialogFooter}
        >
          <p className="text-center text-xl font-bold py-3">{t('completeProfile.updateSuccess')}</p>
        </Dialog>
      </div>
    </>
  );
}

export default CompleteProfile;
