import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('login.validationErrors.emailInvalid')
    .required('login.validationErrors.emailRequired'),
  password: Yup.string()
    .required('login.validationErrors.passwordRequired')
    .min(6, 'login.validationErrors.passwordLengthRule'),
});

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('forgotPassword.validationErrors.emailInvalid')
    .required('forgotPassword.validationErrors.emailRequired'),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('resetPassword.validationErrors.newPasswordRequired')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'resetPassword.validationErrors.passwordRule'
    ),
  confirmPassword: Yup.string()
    .required('resetPassword.validationErrors.confirmPasswordRequired')
    .oneOf(
      [Yup.ref('newPassword')],
      'resetPassword.validationErrors.confirmPasswordNotMatchNewPassword'
    ),
});

export const updatePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required('updatePassword.validationErrors.currentPasswordRequired')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'updatePassword.validationErrors.passwordRule'
    ),
  newPassword: Yup.string()
    .required('updatePassword.validationErrors.newPasswordRequired')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'updatePassword.validationErrors.passwordRule'
    )
    .notOneOf(
      [Yup.ref('currentPassword')],
      'updatePassword.validationErrors.newPasswordSameAsCurrent'
    ),
  confirmPassword: Yup.string()
    .required('updatePassword.validationErrors.confirmPasswordRequired')
    .oneOf(
      [Yup.ref('newPassword')],
      'updatePassword.validationErrors.confirmPasswordNotMatchNewPassword'
    ),
});

export const signupValidationSchema = Yup.object().shape({
  fullname: Yup.string().required('signup.validationErrors.fullnameRequired'),
  academicDegree: Yup.string().required('signup.validationErrors.academicDegreeRequired'),
  organizationId: Yup.string().required('signup.validationErrors.organizationRequired'),
  organizationEnglishName: Yup.string().nullable(),
  specificOrganizationName: Yup.string()
    .nullable()
    .when('organizationEnglishName', {
      is: 'Other',
      then: Yup.string().required('formControl.validation.required'),
    }),
  email: Yup.string()
    .email('signup.validationErrors.emailInvalid')
    .required('signup.validationErrors.emailRequired'),
  password: Yup.string()
    .required('signup.validationErrors.passwordRequired')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'signup.validationErrors.passwordRule'
    ),
  confirmPassword: Yup.string()
    .required('signup.validationErrors.confirmPasswordRequired')
    .oneOf([Yup.ref('password')], 'signup.validationErrors.passwordsNotMatch'),
});

export const completeProfileSchema = Yup.object().shape({
  fullname: Yup.string().required('signup.validationErrors.fullnameRequired'),
  academicDegree: Yup.string().required('signup.validationErrors.academicDegreeRequired'),
  organizationId: Yup.string().required('signup.validationErrors.organizationRequired'),
  organizationEnglishName: Yup.string().nullable(),
  specificOrganizationName: Yup.string()
    .nullable()
    .when('organizationEnglishName', {
      is: 'Other',
      then: Yup.string().required('formControl.validation.required'),
    }),
  password: Yup.string()
    .required('signup.validationErrors.passwordRequired')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'signup.validationErrors.passwordRule'
    ),
  confirmPassword: Yup.string()
    .required('signup.validationErrors.confirmPasswordRequired')
    .oneOf([Yup.ref('password')], 'signup.validationErrors.passwordsNotMatch'),
});
