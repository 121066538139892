/* eslint-disable indent */
// import { yupResolver } from '@hookform/resolvers/yup';
import { create as createMidtermReview } from 'apis/midtermReviews.api';
import { CreateForm } from 'features/MidtermReview/components';
// import { createMidtermReviewValidationSchema } from 'features/MidtermReview/validate';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import { toast } from 'layout';
import { TOAST } from 'constant';
// import { defaultMidtermReview } from '../../_constant';

export default function MidtermReviewFormCreate() {
  const { t } = useTranslation();
  const [isVisibleErrorDialog, setIsVisibleDialogError] = useState(false);

  const navigate = useNavigate();
  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: {
      errors,
      // , isValid
    },
  } = useForm({
    mode: 'onChange',
    // resolver: yupResolver(createMidtermReviewValidationSchema),
  });
  const { isDirty, dirtyFields } = useFormState({ control });

  const {
    mutate,
    error: errorRes,
    isError: isErrorRes,
    isLoading: isLoadingCreate,
  } = useMutation(createMidtermReview);

  const onSubmit = async (data) => {
    const evaluatesReview = {};

    Object.keys(data?.evaluate).forEach((key) => {
      evaluatesReview[key] = data?.evaluate[key].isQualified ?? false;
    });

    const submitData = {
      // ...defaultMidtermReview,
      projectProposalId,
      councilId,
      ...evaluatesReview,
      requestStop: data?.requestStop,
      requestStopReason: data?.requestStopReason,
      requestContinue: data?.requestContinue,
      requestContinueModify: data?.requestContinueModify,
      otherReview: data?.otherReview,
      reviewContinueAbility: data?.reviewContinueAbility,
      reviewExpenditureUsed: data?.reviewExpenditureUsed,
      reviewProgress: data?.reviewProgress,
      reviewProduct: data?.reviewProduct,
      reviewResearchPlan: data?.reviewResearchPlan,
    };

    mutate(submitData, {
      onSuccess: () => {
        reset({}, { keepValues: true });
        toast(TOAST.SUCCESS, t('council.review.createSuccess'));
        setTimeout(() => {
          const queryParams = `councilId=${councilId}&councilMemberId=${councilMemberId}`;

          navigate(`/midterm-review/edit/${projectProposalId}?${queryParams}`);
        }, 1500);
      },
      onError: () => {
        setIsVisibleDialogError(true);
      },
    });
  };

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('formLayout.action.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('formLayout.action.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );
  // #endregion

  return (
    <>
      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields).length}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>
      <CreateForm
        isLoadingCreate={isLoadingCreate}
        isErrorRes={isErrorRes}
        errorRes={errorRes}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        control={control}
        watch={watch}
        setValue={setValue}
        getValues={getValues}
        isDirty={isDirty}
        dirtyFields={dirtyFields}
        errors={errors}
        isValid
        isVisibleErrorDialog={isVisibleErrorDialog}
        setIsVisibleDialogError={setIsVisibleDialogError}
      />
    </>
  );
}
