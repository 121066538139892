import { useEffect, useState } from 'react';

import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';

import {
  createOrUpdateOrganization,
  createOrUpdatePartners,
  getExpenditureById,
  getFileUrls,
  getGeneralInfoById,
  getResearchDescriptionById,
  getResearchResultById,
  update,
  uploadFiles,
  uploadOtherProofsAndAppendicesFiles,
} from 'apis/projectProposals.api';

import Form from 'features/ProjectProposal/components/Form';
import formDefaultValues from 'features/ProjectProposal/defaultValues';
import createProjectProposalValidationSchema from 'features/ProjectProposal/validation';

import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';

import { TOAST } from 'constant';
import { toast } from 'layout';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ScrollTop } from 'primereact/scrolltop';
import { Steps } from 'primereact/steps';
import TokenService from 'utils/axios/token.axios';
import {
  isAuthorized,
  mapError,
  modifyBindingProjectProposalExpenditureData,
  modifyBindingProjectProposalGeneralData,
  modifyBindingProjectProposalResearchDescriptionData,
  modifyBindingProjectProposalResearchResultData,
  modifyProjectProposalExpenditureUpdateData,
  modifyProjectProposalGeneralUpdateData,
  modifyProjectProposalPartnersFormData,
  modifyProjectProposalResearchDescriptionProofsFormData,
  modifyProjectProposalResearchResultUpdateData,
} from 'utils/func';

const formOptions = { resolver: yupResolver(createProjectProposalValidationSchema) };

export default function ProjectProposalEdit() {
  // #region Data
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);
  const [selectedTabs, setSelectedTabs] = useState([false, false, false, false, false]);
  const [isNextTab, setIsNextTab] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [hasUpdateRequest, setHasUpdateRequest] = useState(false);
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const {
    data: generalInfoData,
    isError: isGeneralInfoError,
    error: generalInfoError,
    isLoading: isGeneralInfoLoading,
    isFetching: isGeneralInfoFetching,
  } = useQuery(['projectProposalGeneralInfo', id], () => getGeneralInfoById(id), {
    // Call back api when current tab = 0 or 4 (tab 4 need data to bind into review)
    // and tab 0 is not selected
    enabled: (currentTab === 0 || currentTab === 4) && !selectedTabs[0],
  });

  const {
    data: researchResultData,
    isError: isResearchResultError,
    error: researchResultError,
    isLoading: isResearchResultLoading,
    isFetching: isResearchResultFetching,
  } = useQuery(['projectProposalResearchResult', id], () => getResearchResultById(id), {
    enabled: (currentTab === 1 || currentTab === 4) && !selectedTabs[1],
  });

  const {
    data: expenditureData,
    isError: isExpenditureError,
    error: expenditureError,
    isLoading: isExpenditureLoading,
    isFetching: isExpenditureFetching,
  } = useQuery(['projectProposalExpenditure', id], () => getExpenditureById(id), {
    enabled: (currentTab === 2 || currentTab === 4) && !selectedTabs[2],
  });

  const {
    data: fileUrlsData,
    isError: isFileUrlsError,
    error: fileUrlsError,
    isLoading: isFileUrlsLoading,
    isFetching: isFileUrlsFetching,
  } = useQuery(['projectProposalFileUrls', id], () => getFileUrls(id), {
    enabled:
      (currentTab === 2 && !selectedTabs[2]) ||
      (currentTab === 3 && !selectedTabs[3]) ||
      (currentTab === 4 && !selectedTabs[4]),
  });

  const {
    data: researchDescriptionData,
    isError: isResearchDescriptionError,
    error: researchDescriptionError,
    isLoading: isResearchDescriptionLoading,
    isFetching: isResearchDescriptionFetching,
  } = useQuery(['projectProposalResearchDescription', id], () => getResearchDescriptionById(id), {
    enabled: (currentTab === 3 || currentTab === 4) && !selectedTabs[3],
  });

  useEffect(() => {
    if (
      searchParams.get('reviseLogId') &&
      generalInfoData?.data?.isEditable === 1 &&
      generalInfoData?.data?.submittedAt &&
      (TokenService.getUser()?.id === generalInfoData?.data?.projectOwnerId ||
        isAuthorized(TokenService.getUser()?.id, generalInfoData?.data?.projectProposalContacts))
    ) {
      setHasUpdateRequest(true);
    }
  }, [searchParams, generalInfoData]);

  const {
    mutate,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
  } = useMutation(update);

  const {
    mutate: mutateUploadFiles,
    isLoading: isUploadFilesLoading,
    isError: isUploadFilesError,
    error: uploadFilesError,
  } = useMutation(uploadFiles);

  const {
    mutate: mutateCreateOrUpdateOrganization,
    isLoading: isCreateOrUpdateOrganizationLoading,
    isError: isCreateOrUpdateOrganizationError,
    error: createOrUpdateOrganizationError,
  } = useMutation(createOrUpdateOrganization);

  const {
    mutate: mutateCreateOrUpdatePartners,
    isLoading: isCreateOrUpdatePartnersLoading,
    isError: isCreateOrUpdatePartnersError,
    error: createOrUpdatePartnersError,
  } = useMutation(createOrUpdatePartners);

  const {
    mutate: mutateUploadOtherProofsAndAppendicesFiles,
    isLoading: isUploadOtherProofsAndAppendicesFilesLoading,
    isError: isUploadOtherProofsAndAppendicesFilesError,
    error: uploadOtherProofsAndAppendicesFilesError,
  } = useMutation(uploadOtherProofsAndAppendicesFiles);

  const { control, handleSubmit, trigger, watch, reset, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: formDefaultValues,
    ...formOptions,
    shouldFocusError: true,
  });

  const { errors, isDirty, dirtyFields, isValid, isSubmitting } = useFormState({
    control,
  });

  const items = [
    {
      label: t('projectProposal.general.label'),
    },
    {
      label: t('projectProposal.researchResult.label'),
    },
    {
      label: t('projectProposal.expenditure.label'),
    },
    {
      label: t('projectProposal.researchDescription.label'),
    },
    {
      label: t('projectProposal.review.label'),
    },
  ];
  // #endregion Data

  // #region Event
  // Set value into form fields
  useEffect(
    () => {
      if (id) {
        setValue('id', id);
      }

      // Binding when has data, current tab = 0 or 4 (4 need data to bind into review)
      // tab 0 is not selected and has new fetched data
      if (
        id &&
        generalInfoData?.data &&
        (currentTab === 0 || currentTab === 4) &&
        !selectedTabs[0] &&
        !isGeneralInfoFetching
      ) {
        setValue('reference', generalInfoData?.data?.reference);
        setValue('submittedAt', generalInfoData?.data?.submittedAt);
        setValue('statusName', generalInfoData?.data?.status?.name);
        setValue('programId', generalInfoData?.data?.program?.id);
        setValue('version', generalInfoData?.data?.version);

        const bindingGeneralData = modifyBindingProjectProposalGeneralData(generalInfoData.data);

        setValue('general', bindingGeneralData);
        setValue('general.recommendedExperts', bindingGeneralData.recommendedExperts);
        setValue('general.researchExpense', bindingGeneralData.researchExpense);
        setValue('general.owner.researchLines', bindingGeneralData?.owner?.researchLines ?? []);
        setValue('general.partners', bindingGeneralData.partners);
        setValue('general.scientificSecretaries', bindingGeneralData.scientificSecretaries);
        setValue('general.mainParticipants', bindingGeneralData.mainParticipants);
        setValue('general.participants', bindingGeneralData.participants);
        setValue('general.domesticExperts', bindingGeneralData.domesticExperts);
        setValue('general.internationalExperts', bindingGeneralData.internationalExperts);

        // Mark selected tab when current tab = 0 and new fetched data
        // If the data has not been fetched, do not mark it because it will bind when has new data
        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[0] = true;
        setSelectedTabs(newSelectedTabs);
      }

      if (
        id &&
        researchResultData?.data &&
        (currentTab === 1 || currentTab === 4) &&
        !selectedTabs[1] &&
        !isResearchResultFetching
      ) {
        const bindingResearchResultData = modifyBindingProjectProposalResearchResultData(
          researchResultData.data
        );

        setValue(
          'researchResult.projectProposalResearchKpis',
          bindingResearchResultData.projectProposalResearchKpis
        );
        setValue('researchResult.softProducts', bindingResearchResultData.softProducts);
        setValue('researchResult.hardProducts', bindingResearchResultData.hardProducts);
        setValue('researchResult.qualityComparison', bindingResearchResultData.qualityComparison);
        setValue(
          'researchResult.proposedExpenditureByKpis',
          bindingResearchResultData.proposedExpenditureByKpis
        );
        setValue(
          'researchResult.knowledgeContributionLevel',
          bindingResearchResultData.knowledgeContributionLevel
        );
        setValue(
          'researchResult.practicalPolicyImpact',
          bindingResearchResultData.practicalPolicyImpact
        );
        setValue(
          'researchResult.teamDevelopmentLevel',
          bindingResearchResultData.teamDevelopmentLevel
        );
        setValue(
          'researchResult.researchTransferability',
          bindingResearchResultData.researchTransferability
        );

        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[1] = true;
        setSelectedTabs(newSelectedTabs);
      }

      if (
        id &&
        expenditureData?.data &&
        (currentTab === 2 || currentTab === 4) &&
        !selectedTabs[2] &&
        !isExpenditureFetching &&
        !isFileUrlsFetching
      ) {
        const bindingExpenditureData = modifyBindingProjectProposalExpenditureData(
          expenditureData.data
        );

        setValue(
          'expenditure.proposedExpenditureSummaries',
          bindingExpenditureData.proposedExpenditureSummaries
        );

        if (fileUrlsData?.data?.expenditureFileUrl) {
          setValue('expenditure.uploadedFileUrl', fileUrlsData?.data?.expenditureFileUrl);
        }

        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[2] = true;
        setSelectedTabs(newSelectedTabs);
      }

      if (
        (currentTab === 3 || currentTab === 4) &&
        !selectedTabs[3] &&
        (fileUrlsData?.data?.researchDescriptionFileUrl || researchDescriptionData?.data) &&
        id &&
        !isFileUrlsFetching &&
        !isResearchDescriptionFetching
      ) {
        if (fileUrlsData?.data?.researchDescriptionFileUrl) {
          setValue(
            'researchDescription.uploadedFileUrl',
            fileUrlsData?.data?.researchDescriptionFileUrl
          );
        }

        if (researchDescriptionData?.data) {
          const bindingResearchDescriptionData =
            modifyBindingProjectProposalResearchDescriptionData(researchDescriptionData.data);

          setValue(
            'researchDescription.otherProofsAndAppendicesFiles',
            bindingResearchDescriptionData?.otherProofsAndAppendicesFiles
          );
        }

        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[3] = true;
        setSelectedTabs(newSelectedTabs);
      }

      // When data is fetched, check current tab is being 4 then mark all 5 tab is selected
      // Reason: Being tab 4, all apis is fetched => if back any other tab, prevent call back api
      if (
        !(
          isGeneralInfoFetching ||
          isResearchResultFetching ||
          isExpenditureFetching ||
          isFileUrlsFetching
        ) &&
        currentTab === 4
      ) {
        const newSelectedTabs = [...selectedTabs];
        newSelectedTabs[0] = true;
        newSelectedTabs[1] = true;
        newSelectedTabs[2] = true;
        newSelectedTabs[3] = true;
        newSelectedTabs[4] = true;
        setSelectedTabs(newSelectedTabs);
      }
    },

    // rerender when fetch new data
    [
      id,
      isGeneralInfoFetching,
      isResearchResultFetching,
      isExpenditureFetching,
      isFileUrlsFetching,
      isResearchDescriptionFetching,
    ]
  );

  // eslint-disable-next-line no-unused-vars
  const createOrUpdatePartnersSequentially = (projectProposalId, partners, currentIndex) => {
    // all partners have been created/updated successfully
    if (currentIndex >= partners.length) {
      return;
    }

    const partner = partners[currentIndex];

    mutateCreateOrUpdateOrganization(
      {
        projectProposalId,
        data: partner,
      },
      {
        onSuccess: (partnerRes) => {
          if (partnerRes?.data?.partnerConfirmationUrl) {
            setValue(
              `general.partners.${currentIndex}.partnerConfirmationUrl`,
              partnerRes?.data?.partnerConfirmationUrl
            );
            setValue(`general.partners.${currentIndex}.file`, 'fileUploaded');
          }
          // move to the next partner
          createOrUpdatePartnersSequentially(projectProposalId, partners, currentIndex + 1);
        },
        onError: () => {
          setErrorDialogVisible(true);
        },
      }
    );
  };

  const onSubmit = (data) => {
    if (!(isDirty && Object.keys(dirtyFields).length)) {
      if (isNextTab) {
        setCurrentTab((cur) => cur + 1);
        setIsNextTab(false);
      }
      return;
    }

    // Check fundingExpenditureOther more than fundingExpenditure
    const fundingExpenditureOther =
      getValues('general.researchExpense.fundingExpenditureOther') ?? 0;
    const fundingExpenditure = getValues('general.researchExpense.fundingExpenditure') ?? 0;

    if (fundingExpenditureOther > fundingExpenditure && fundingExpenditureOther !== 0) {
      toast(TOAST.ERROR, t('projectProposal.fundingOtherMustSmallerThanFundingTotalMessage'));
      return;
    }

    const updatedGeneral = selectedTabs[0] ? modifyProjectProposalGeneralUpdateData(data) : {};
    const updatedResearchResult = selectedTabs[1]
      ? modifyProjectProposalResearchResultUpdateData(data)
      : {};
    const updatedExpenditure = selectedTabs[2]
      ? modifyProjectProposalExpenditureUpdateData(data)
      : {};
    const newData = { ...updatedGeneral, ...updatedResearchResult, ...updatedExpenditure };

    mutate(
      { id, data: newData },
      {
        onSuccess: () => {
          if (dirtyFields?.expenditure?.file || dirtyFields?.researchDescription?.file) {
            const formData = new FormData();
            if (data?.researchDescription?.file) {
              formData.append('fileB', data?.researchDescription.file);
            }
            if (data?.expenditure?.file) {
              formData.append('fileC', data?.expenditure.file);
            }

            mutateUploadFiles(
              {
                projectProposalId: id,
                files: formData,
              },
              {
                onSuccess: (fileRes) => {
                  if (fileRes?.data?.researchDescriptionFileUrl) {
                    setValue(
                      'researchDescription.uploadedFileUrl',
                      fileRes?.data?.researchDescriptionFileUrl
                    );
                    setValue('researchDescription.file', null);
                  }
                  if (fileRes?.data?.expenditureFileUrl) {
                    setValue('expenditure.uploadedFileUrl', fileRes?.data?.expenditureFileUrl);
                    setValue('expenditure.file', null);
                  }
                },
                onError: () => {
                  setErrorDialogVisible(true);
                },
              }
            );
          }

          if (dirtyFields?.general?.partners) {
            const projectProposalPartners = modifyProjectProposalPartnersFormData(
              data.general.partners
            );

            mutateCreateOrUpdatePartners(
              {
                projectProposalId: id,
                data: projectProposalPartners,
              },
              {
                onSuccess: (partnersRes) => {
                  if (partnersRes?.data) {
                    const partnerInfos = partnersRes?.data?.map((item) => ({
                      id: item?.id,
                      file: null,
                      specificName: item?.name,
                      organizationId: item?.organizationId,
                      name: item?.name,
                      englishName: item?.englishName,
                      chief: item?.chief,
                      fax: item?.fax,
                      phone: item?.phone,
                      email: item?.email,
                      address: item?.address,
                      bankAccount: item?.bankAccount,
                      bank: item?.bank,
                      partnerContribution: item?.partnerContribution,
                      type: item?.type,
                      partnerConfirmationUrl: item?.partnerConfirmationUrl,
                    }));
                    setValue('general.partners', partnerInfos);
                  }
                },
                onError: () => {
                  setErrorDialogVisible(true);
                },
              }
            );
          }

          if (selectedTabs[3] && dirtyFields?.researchDescription?.otherProofsAndAppendicesFiles) {
            const otherProofsAndAppendicesFiles =
              data?.researchDescription?.otherProofsAndAppendicesFiles;
            const formData = modifyProjectProposalResearchDescriptionProofsFormData(
              otherProofsAndAppendicesFiles
            );

            mutateUploadOtherProofsAndAppendicesFiles(
              {
                projectProposalId: id,
                data: formData,
              },
              {
                onSuccess: (res) => {
                  if (res?.data) {
                    const proofFileInfos = res?.data?.map((item) => ({
                      id: item?.id,
                      fileName: item?.fileName,
                      file: null,
                    }));
                    setValue('researchDescription.otherProofsAndAppendicesFiles', proofFileInfos);
                  }
                },
                onError: () => {
                  setErrorDialogVisible(true);
                },
              }
            );
          }

          reset({
            ...data,
            general: {
              ...data.general,
              owner: {
                ...data.general.owner,
                // Datatype date must be parse to date (not string)
                dob: data?.general?.owner?.dob ? new Date(data?.general?.owner?.dob) : null,
                dateOfIssue: data?.general?.owner?.dateOfIssue
                  ? new Date(data?.general?.owner?.dateOfIssue)
                  : null,
              },
            },
            expenditure: {
              ...data?.expenditure,
              file: data?.expenditure?.file?.name,
            },
            researchDescription: {
              ...data?.researchDescription,
              file: data?.researchDescription?.file?.name,
            },
          });

          if (currentTab === 4) {
            toast(TOAST.SUCCESS, t('projectProposal.saveSuccessMessage'));
            return;
          }

          if (isNextTab) {
            setCurrentTab((cur) => cur + 1);
            setIsNextTab(false);
          } else toast(TOAST.SUCCESS, t('projectProposal.saveSuccessMessage'));
        },
        onError: () => {
          setErrorDialogVisible(true);
        },
      }
    );
  };

  const nextTab = async () => {
    if (currentTab === 0) {
      const isGeneralInfoValid = await trigger('general', { shouldFocus: true });
      if (isGeneralInfoValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 1) {
      const isResearchResultValid = await trigger('researchResult', { shouldFocus: true });
      if (isResearchResultValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 2) {
      const isExpenditureValid = await trigger('expenditure', { shouldFocus: true });
      if (isExpenditureValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 3) {
      const isResearchDescriptionValid = await trigger('researchDescription', {
        shouldFocus: true,
      });
      if (isResearchDescriptionValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else {
      setCurrentTab((cur) => cur + 1);
    }
  };

  const handleTabChange = async (e) => {
    // Can not use switch because of eslint error (conflict Prettier)
    if (currentTab === 0) {
      const isGeneralInfoValid = await trigger('general', { shouldFocus: true });
      if (isGeneralInfoValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 1) {
      const isResearchResultValid = await trigger('researchResult', { shouldFocus: true });
      if (isResearchResultValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 2) {
      const isExpenditureValid = await trigger('expenditure', { shouldFocus: true });
      if (isExpenditureValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 3) {
      const isResearchDescriptionValid = await trigger('researchDescription', {
        shouldFocus: true,
      });
      if (isResearchDescriptionValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else {
      setCurrentTab(e.index);
    }
  };

  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  // #endregion Event

  const footerError = (
    <Button
      label={t('projectProposal.ok')}
      className="w-7rem"
      severity="danger"
      onClick={() => navigate(-1)}
      autoFocus
    />
  );

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );

  if (isGeneralInfoError && id) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, generalInfoError)}</p>
      </Dialog>
    );
  }
  if (isResearchResultError && id) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, researchResultError)}</p>
      </Dialog>
    );
  }
  if (isExpenditureError && id) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, expenditureError)}</p>
      </Dialog>
    );
  }

  if (isFileUrlsError && id) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, fileUrlsError)}</p>
      </Dialog>
    );
  }

  if (isResearchDescriptionError && id) {
    return (
      <Dialog
        header={<p className="text-red-500">{t('toast.error')}</p>}
        visible
        closable={false}
        draggable={false}
        style={{ width: '400px' }}
        footer={footerError}
      >
        <p className="m-0 text-lg text-red-500">{mapError(t, researchDescriptionError)}</p>
      </Dialog>
    );
  }

  return (
    <>
      {/* #region Loading */}
      <div className="sticky top-0 left-0 z-5">
        <div className="relative">
          {(isSubmitting ||
            isUpdateLoading ||
            isUploadFilesLoading ||
            isCreateOrUpdateOrganizationLoading ||
            isCreateOrUpdatePartnersLoading ||
            isUploadOtherProofsAndAppendicesFilesLoading ||
            ((isGeneralInfoLoading ||
              isResearchResultLoading ||
              isExpenditureLoading ||
              isFileUrlsLoading ||
              isResearchDescriptionLoading ||
              isGeneralInfoFetching ||
              isResearchResultFetching ||
              isExpenditureFetching ||
              isFileUrlsFetching ||
              isResearchDescriptionFetching) &&
              id)) && <Loading />}
        </div>
      </div>
      {/* #endregion Loading */}

      <BlockUI
        baseZIndex={3}
        blocked={
          (isSubmitting ||
            isUpdateLoading ||
            isUploadFilesLoading ||
            isCreateOrUpdateOrganizationLoading ||
            isCreateOrUpdatePartnersLoading ||
            isUploadOtherProofsAndAppendicesFilesLoading ||
            isGeneralInfoLoading ||
            isResearchResultLoading ||
            isExpenditureLoading ||
            isFileUrlsLoading ||
            isResearchDescriptionLoading ||
            isGeneralInfoFetching ||
            isResearchResultFetching ||
            isExpenditureFetching ||
            isFileUrlsFetching ||
            isResearchDescriptionFetching) &&
          id
        }
      >
        <Steps model={items} activeIndex={currentTab} onSelect={handleTabChange} readOnly={false} />

        <Form
          control={control}
          errors={errors}
          disabledSave={!(isDirty && Object.keys(dirtyFields).length)}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          handleSubmit={handleSubmit(onSubmit)}
          tab={currentTab}
          nextTab={nextTab}
          selectedTabs={selectedTabs}
          setSelectedTabs={setSelectedTabs}
          checkValidation={checkValidation}
          checkKeyDown={checkKeyDown}
          isDirty={isDirty}
          dirtyFields={dirtyFields}
          footerConfirmationPrompt={footerConfirmationPrompt}
          hasUpdateRequest={hasUpdateRequest}
          projectProposalResearchKpis={researchResultData?.data?.projectProposalResearchKpis}
        />
      </BlockUI>

      <ScrollTop target="parent" />
      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields).length && currentTab !== 4}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>

      {/* #region Update */}
      {isUpdateError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.updateErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(updateError?.response.data.message) ? (
            updateError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, updateError)}</p>
          )}
        </Dialog>
      )}
      {/* #endregion Update */}

      {/* #region Update file */}
      {isUploadFilesError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.createErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(uploadFilesError.response.data.message) ? (
            uploadFilesError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, uploadFilesError)}</p>
          )}
        </Dialog>
      )}
      {/* #endregion Update file */}

      {isCreateOrUpdateOrganizationError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.createErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(createOrUpdateOrganizationError.response.data.message) ? (
            createOrUpdateOrganizationError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">
              {mapError(t, createOrUpdateOrganizationError)}
            </p>
          )}
        </Dialog>
      )}

      {isCreateOrUpdatePartnersError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.createErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(createOrUpdatePartnersError.response.data.message) ? (
            createOrUpdatePartnersError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, createOrUpdatePartnersError)}</p>
          )}
        </Dialog>
      )}

      {isUploadOtherProofsAndAppendicesFilesError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.createErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(uploadOtherProofsAndAppendicesFilesError.response.data.message) ? (
            uploadOtherProofsAndAppendicesFilesError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">
              {mapError(t, uploadOtherProofsAndAppendicesFilesError)}
            </p>
          )}
        </Dialog>
      )}
    </>
  );
}
