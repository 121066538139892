import { useContext, useEffect, useLayoutEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import PrimeReact from 'primereact/api';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';

import AppSidebar from './AppSidebar';
import AppTopbar from './AppTopbar';
import { LayoutContext } from './context/layoutcontext';

// eslint-disable-next-line import/no-mutable-exports
export let toast;

function Layout({ children, style }) {
  const { t } = useTranslation();
  const toastRef = useRef(null);
  const { layoutConfig, layoutState, setLayoutState } = useContext(LayoutContext);
  const topbarRef = useRef(null);
  const sidebarRef = useRef(null);

  PrimeReact.ripple = true;

  const containerClass = classNames('layout-wrapper', {
    'layout-theme-light': layoutConfig.colorScheme === 'light',
    'layout-theme-dark': layoutConfig.colorScheme === 'dark',
    'layout-overlay': layoutConfig.menuMode === 'overlay',
    'layout-static': layoutConfig.menuMode === 'static',
    'layout-static-inactive':
      layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === 'static',
    'layout-overlay-active': layoutState.overlayMenuActive,
    'layout-mobile-active': layoutState.staticMenuMobileActive,
    'p-input-filled': layoutConfig.inputStyle === 'filled',
    'p-ripple-disabled': !layoutConfig.ripple,
  });

  useEffect(() => {
    document.title = t('websiteName');
  }, [t]);

  useLayoutEffect(() => {
    toast = (mode, detail) =>
      toastRef.current.show({
        severity: mode,
        summary: t(`toast.${mode}`),
        detail,
        life: 4000,
      });
  });

  return (
    <div className={containerClass}>
      <AppTopbar ref={topbarRef} />
      <div ref={sidebarRef} className="layout-sidebar-cyan layout-sidebar">
        <AppSidebar />
      </div>
      <div className="layout-main-container">
        <div className="layout-main" style={style}>
          {children}
        </div>
      </div>
      <div
        className="layout-mask"
        onClick={() => {
          setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            overlayMenuActive: false,
            staticMenuMobileActive: false,
            menuHoverActive: false,
          }));
        }}
      />

      <Toast ref={toastRef} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.shape({}),
};

Layout.defaultProps = {
  style: {},
};
export default Layout;
