import instance from 'utils/axios/instance.axios';
import { PROJECTS } from './_constant';

export const getAll = () => instance.get(PROJECTS.GET_ALL);
export const getProjectById = (id) => instance.get(PROJECTS.GET_PROJECT_BY_ID(id));
export const submitProfile = (id) => instance.post(PROJECTS.POST_SUBMIT_BY_ID(id));
export const getProjectByProjectProposalId = (projectProposalId) =>
  instance.get(PROJECTS.GET_PROJECT_BY_PROJECT_PROPOSAL_ID(projectProposalId));

export const downloadFile = (body) =>
  instance.post(PROJECTS.POST_DOWNLOAD_PDF(body.projectId), body, { responseType: 'blob' });
export const getProjectCode = (projectId) => instance.get(PROJECTS.GET_PROJECT_CODE(projectId));
