import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Editor } from 'primereact/editor';

export default function ResearchContributions({ data }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion

  return (
    <div className="mt-3">
      <h4>{t('projectProposal.researchResult.a11.label')}</h4>
      <div className="mt-3">
        <h4>{t('projectProposal.researchResult.a11.a11_1')}</h4>
        <Editor
          value={data?.knowledgeContributionLevel ?? ''}
          showHeader={false}
          style={{ height: '300px' }}
          readOnly
        />
      </div>

      <div className="mt-3">
        <h4>{t('projectProposal.researchResult.a11.a11_2')}</h4>
        <Editor
          value={data?.practicalPolicyImpact ?? ''}
          showHeader={false}
          style={{ height: '300px' }}
          readOnly
        />
      </div>

      <div className="mt-3">
        <h4 className="mb-0">{t('projectProposal.researchResult.a11.a11_3.label')}</h4>
        <p className="font-italic">{t('projectProposal.researchResult.a11.a11_3.note')}</p>
        <Editor
          value={data?.teamDevelopmentLevel ?? ''}
          showHeader={false}
          style={{ height: '300px' }}
          readOnly
        />
      </div>

      <div className="mt-3">
        <h4 className="mb-0">{t('projectProposal.researchResult.a11.a11_4.label')}</h4>
        <p className="font-italic">{t('projectProposal.researchResult.a11.a11_4.note')}</p>
        <Editor
          value={data?.researchTransferability ?? ''}
          showHeader={false}
          style={{ height: '300px' }}
          readOnly
        />
      </div>
    </div>
  );
}

ResearchContributions.propTypes = {
  data: PropTypes.shape(),
};

ResearchContributions.defaultProps = {
  data: {},
};
