/* eslint-disable */
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { getMyContact } from 'apis/contacts.api';
import { createPublicProfileId, downloadFile, downloadImage } from 'apis/scientificProfiles.api';

import { formatFileName } from 'utils/func';
import Loading from 'components/Loading';

import { TIMEZONE_VN } from 'constant';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';

import ScientificProfileUploadForm from './components/ScientificProfileUploadForm';
import ScientificProfileHome from './components/ScientificProfileHome';

import UploadAvatarDialog from './pages/Dialog/updateAvatarDialog';
import EditProfileForm from 'features/UserProfile/components/EditProfileForm';

const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: TIMEZONE_VN };

export default function ScientificProfile() {
  // #region Data
  const { t } = useTranslation();
  const toast = useRef(null);
  const location = useLocation();

  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUploadAvatarModalOpen, setIsUploadAvatarModalOpen] = useState(false);
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useState(false);

  const { data: _data, isLoading, refetch } = useQuery(['myContactInfo'], getMyContact);

  const data = useMemo(() => _data?.data ?? {}, [_data]);

  const { data: publicProfileData } = useQuery(
    'publicProfile',
    () => createPublicProfileId(data?.scientificProfileId),
    {
      enabled: data?.scientificProfileId !== undefined,
    }
  );

  const { refetch: refetchScientificProfileFile } = useQuery(
    'downloadScientificProfileFile',
    downloadFile,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download =
            formatFileName(data?.user?.scientificProfileUrl) ??
            `${t('scientificProfile.label')}.pdf`;

          link.click();
        }
      },
    }
  );

  async function convertDataToUrl(blobOrFile) {
    let reader = new FileReader();
    reader.readAsDataURL(blobOrFile);
    await new Promise(
      (resolve) =>
        (reader.onload = function () {
          resolve();
        })
    );
    return reader.result;
  }

  // get avatar
  const { data: avatarData } = useQuery('avatar', downloadImage, {
    enabled: imagePreview === null,
    onSuccess: async (res) => {
      if (res?.data) {
        const imageBlob = new Blob([res.data]);

        const imageUrl = await convertDataToUrl(imageBlob);
        setImagePreview(imageUrl);
      }
    },
  });

  // #endregion Data

  // region Event

  useEffect(() => {
    if (location.state?.isUploadFile) {
      setIsUploadFileModalOpen(true);
    }
  }, []);

  const showSuccess = (detail) => {
    toast.current.show({
      severity: 'success',
      summary: t('toast.success'),
      detail,
      life: 4000,
    });
  };

  const showError = (errorMessage) => {
    toast.current.show({
      severity: 'error',
      summary: t('toast.error'),
      detail: errorMessage,
      life: 4000,
    });
  };

  const onChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxSize) {
      toast.current.show({
        severity: 'error',
        summary: t('toast.success'),
        detail: 'File size is too large',
      });
      return;
    }
    if (file) {
      setIsUploadAvatarModalOpen(true);
      setImage(file);
    }
  };
  const handleMouseEnter = () => {
    const overlayImage = document.getElementById('overlay-image');
    if (overlayImage) {
      overlayImage.classList.remove('hidden');
      overlayImage.style.display = 'block';
    }
  };

  const handleMouseLeave = () => {
    const overlayImage = document.getElementById('overlay-image');
    if (overlayImage) {
      overlayImage.classList.add('hidden');
    }
  };
  // endregion Event

  if (isLoading) return <Loading />;

  return (
    <div className="bg-white h-full p-3 md:p-4 xl:p-6">
      <div className="flex">
        <div className="flex h-4rem md:h-12rem xl:h-14rem">
          <label htmlFor="photo-upload" className="cursor-pointer" title="Edit Photo">
            <div
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                id="user-profile"
                // src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                src={
                  imagePreview
                    ? imagePreview
                    : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                }
                alt="user profile"
                className="border-circle h-4rem sm:h-6rem md:h-8rem xl:h-10rem border-4 border-solid border-cyan-200"
              />
              <img
                id="overlay-image"
                src="/images/edit-image-overlay.png" // Đường dẫn tới ảnh overlay
                alt="overlay image"
                className="absolute top-0 left-0 inset-0 object-cover w-full h-full hidden border-circle h-4rem sm:h-6rem md:h-8rem xl:h-10rem border-4 border-solid border-cyan-200"
              />
            </div>
            <input
              id="photo-upload"
              type="file"
              accept="image/*"
              onChange={onChange}
              style={{ display: 'none' }}
            />
          </label>

          <div className="pl-3 md:pl-4 xl:pl-6 flex-1">
            <div className="flex mb-2">
              <span className="pr-4 text-lg md:text-2xl xl:text-3xl font-semibold">
                {data?.fullname}
              </span>
              <span className="text-gray-600 font-semibold pt-1 md:pt-2">
                <i className="pi pi-tag pr-1" />
                {data?.user?.userRoles?.length > 0
                  ? data.user.userRoles.map((item) => t(`userRole.${item?.role?.code}`))?.join(', ')
                  : t('userRole.user')}
              </span>
            </div>

            <span className="text-cyan-500 font-bold">
              {data?.scientificProfile?.academicRank
                ? `${t(`academicRank.${data?.scientificProfile?.academicRank}`)}, `
                : ''}
              {data?.scientificProfile?.academicDegree
                ? t(`academicDegree.${data?.scientificProfile?.academicDegree}`)
                : ''}
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-content-end column-gap-2 mt-4 mb-4 md:mb-0 md:mt-0">
        <Link to={`/profile/${publicProfileData?.data?.publicProfileId || ''}`} target="_blank">
          <Button
            className="bg-indigo-600 hover:bg-indigo-800 text-white "
            label={t('userProfile.viewPublicProfile')}
            icon="pi pi-eye"
          />
        </Link>
      </div>

      <div className="flex-1">
        <div className="w-full">
          <TabView>
            {/* Thông tin cá nhân */}
            <TabPanel header={t('userProfile.info')} leftIcon="pi pi-user mr-2" className="">
              <div className="flex justify-content-end">
                <Button
                  label={t('userProfile.editProfile')}
                  icon="pi pi-pencil"
                  className="bg-cyan-400 border-cyan-400 hover:bg-cyan-500"
                  onClick={() => setIsEditModalOpen(true)}
                />
              </div>

              <div className="flex flex-wrap gap-6 mt-4">
                <div>
                  <h6 className="text-gray-500 mb-4">{t('userProfile.contact.label')}</h6>
                  <div className="ml-0">
                    <div className="flex mt-3">
                      <span className="font-semibold mr-2 w-6rem lg:w-8rem">Email:</span>

                      {data?.email && (
                        <a
                          href={`mailto:${data.email}`}
                          className="max-w-20rem font-medium text-blue-600"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {data?.email.split('@')[0]}
                          <wbr />@{data?.email.split('@')[1]}
                        </a>
                      )}
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.contact.phone')}:
                      </span>
                      {data?.scientificProfile?.personalPhone && (
                        <a
                          href={`tel:${data.scientificProfile.personalPhone}`}
                          className="max-w-20rem font-medium text-blue-600"
                        >
                          {data?.scientificProfile?.personalPhone}
                        </a>
                      )}
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.contact.organization')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {data?.organization?.englishName === 'Other'
                          ? data?.specificOrganizationName
                          : t('lang') === 'vi'
                          ? data?.organization?.name
                          : data?.organization?.englishName}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.contact.address')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.officeAddress}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.contact.ORCIDcode')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.ORCIDcode}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <h6 className="text-gray-500 mb-4">{t('userProfile.basic.label')}</h6>
                  <div className="ml-0">
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.basic.gender')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.gender
                          ? t(`userProfile.gender.${data?.scientificProfile?.gender.toLowerCase()}`)
                          : ''}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.basic.dob')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.dob
                          ? new Date(data?.scientificProfile?.dob)?.toLocaleDateString(
                              t('lang'),
                              options
                            )
                          : ''}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.basic.citizenId')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.citizenId}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.basic.dateOfIssue')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.dateOfIssue
                          ? new Date(data?.scientificProfile?.dateOfIssue)?.toLocaleDateString(
                              t('lang'),
                              options
                            )
                          : ''}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.basic.placeOfIssue')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.placeOfIssue}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <h6 className="text-gray-500 mb-4">{t('userProfile.finance.label')}</h6>
                  <div className="ml-0 ">
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-10rem font-semibold mr-2">
                        {t('userProfile.finance.taxIdentificationNumber')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.taxIdentificationNumber}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-10rem font-semibold mr-2">
                        {t('userProfile.finance.bankAccount')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.bankAccount}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-10rem font-semibold mr-2">
                        {t('userProfile.finance.bank')}:
                      </span>
                      <p className="max-w-20rem font-medium">{data?.scientificProfile?.bank}</p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-10rem font-semibold mr-2">
                        {t('userProfile.finance.bankBranch')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.bankBranch}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            {/* Lý lịch khoa học */}
            <TabPanel header={t('scientificProfile.label')} leftIcon="pi pi-id-card mr-2">
              <div className="flex justify-content-end column-gap-2">
                <Link to="/scientific-profile/edit">
                  <Button
                    className="bg-cyan-400 border-cyan-400 hover:bg-cyan-500"
                    label={t('formLayout.action.edit')}
                    icon="pi pi-pencil"
                  />
                </Link>
                <Button
                  label={t('formLayout.action.upload')}
                  icon="pi pi-upload"
                  className="bg-blue-500 border-blue-500 hover:bg-blue-600"
                  onClick={() => setIsUploadFileModalOpen(true)}
                />
              </div>

              <ScientificProfileHome />
              <div className="flex justify-content-end column-gap-2">
                <Link to="/scientific-profile/edit">
                  <Button
                    className="bg-cyan-400 border-cyan-400 hover:bg-cyan-500"
                    label={t('formLayout.action.edit')}
                    icon="pi pi-pencil"
                  />
                </Link>
              </div>
            </TabPanel>
          </TabView>

          {/* <div className="mt-6 pb-6">
            <h6 className="text-gray-500 mb-4">{t('scientificProfile.label')}</h6>
            <div className="ml-0 md:ml-5">
              {data?.user?.scientificProfileUrl && (
                <div className="mb-2">
                  <span
                    className="p-button p-button-text p-button-sm p-0 mt-1"
                    onClick={() => refetchScientificProfileFile()}
                  >
                    <i className="pi pi-paperclip" />
                    <span className="text-blue-500 underline font-italic text-base ml-1">
                      {t('scientificProfile.downloadFile')}
                    </span>
                  </span>

                  {data?.lastScientificProfileUpdated && (
                    <span className="ml-2">
                      ({t('scientificProfile.updatedOn')}{' '}
                      {new Date(data?.lastScientificProfileUpdated)?.toLocaleDateString(
                        t('lang'),
                        options
                      )}
                      )
                    </span>
                  )}
                </div>
              )}
              <Button
                label={t('formLayout.action.upload')}
                icon="pi pi-upload"
                className="bg-blue-500 border-blue-500 hover:bg-blue-600"
                onClick={() => setIsUploadFileModalOpen(true)}
              />
            </div>
          </div> */}
        </div>
      </div>

      <EditProfileForm
        contact={data}
        visible={isEditModalOpen}
        setVisible={setIsEditModalOpen}
        refetch={refetch}
        showSuccess={showSuccess}
        showError={showError}
      />

      <ScientificProfileUploadForm
        fileUrl={data?.user?.scientificProfileUrl}
        visible={isUploadFileModalOpen}
        setVisible={setIsUploadFileModalOpen}
        refetch={refetch}
        handleDownloadFile={refetchScientificProfileFile}
        showSuccess={showSuccess}
        showError={showError}
      />

      <UploadAvatarDialog
        image={image}
        setImage={setImage}
        setImagePreview={setImagePreview}
        visible={isUploadAvatarModalOpen}
        setVisible={setIsUploadAvatarModalOpen}
        showSuccess={showSuccess}
        showError={showError}
      />

      <Toast ref={toast} />
    </div>
  );
}
