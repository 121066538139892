import instance from 'utils/axios/instance.axios';
import { LIQUIDATION_AGREEMENT } from './_constant';

export const postLiquidation = (body = {}) =>
  instance.post(LIQUIDATION_AGREEMENT.POST_LIQUIDATION, body);

export const getAllLiquidation = (first, rowsPerPage, showMode) =>
  instance.get(LIQUIDATION_AGREEMENT.GET_ALL_LIQUIDATION, {
    params: { first, rowsPerPage, showMode },
  });

export const getLiquidationById = (projectId) =>
  instance.get(LIQUIDATION_AGREEMENT.GET_LIQUIDATION_BY_ID, { params: { projectId } });

export const submitLiquidation = (id) =>
  instance.post(LIQUIDATION_AGREEMENT.SUBMIT_LIQUIDATION(id));

export const uploadLiquidationFile = (body = {}) =>
  instance.post(LIQUIDATION_AGREEMENT.UPLOAD_LIQUIDATION_FILES, body);

export const updateLiquidationFile = (body = {}) =>
  instance.patch(LIQUIDATION_AGREEMENT.UPDATE_LIQUIDATION_FILES, body);

export const deleteLiquidationFile = (body = {}) =>
  instance.post(LIQUIDATION_AGREEMENT.DELETE_LIQUIDATION_FILES, body);

export const downloadLiquidationFile = (body = {}) =>
  instance.post(LIQUIDATION_AGREEMENT.DOWNLOAD_FILE, body, { responseType: 'blob' });
