import PropTypes from 'prop-types';
import './style.scss';

import { useMemo, useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFinancialAdjustmentSchema } from 'features/Project/validation';
import { getProjectById } from 'apis/project.api';
import { getFinancialAdjustmentById } from 'apis/financialAdjustment.api';
import Loading from 'components/Loading';
import { defaultFinancialAdjustment } from 'features/Project/defaultValues';
import FinancialAdjustmentForm from '../../components/FinancialAdjustmentForm';

const formOptions = { resolver: yupResolver(createFinancialAdjustmentSchema) };

function FinancialAdjustment({ readOnly }) {
  // #region Data
  const { projectId, financialAdjustmentId } = useParams();

  const { watch, setValue, control } = useForm({
    mode: 'onChange',
    ...formOptions,
    shouldFocusError: true,
    defaultValues: defaultFinancialAdjustment,
  });

  const { errors } = useFormState({
    control,
  });

  const { data: projectData, isLoading: isProjectDataLoading } = useQuery(
    ['project', projectId],
    () => getProjectById(projectId)
  );
  const project = useMemo(() => projectData?.data, [projectData]);

  const { data: financialAdjustmentData, isLoading: isFinancialAdjustmentDataLoading } = useQuery(
    ['financialAdjustment', financialAdjustmentId],
    () => getFinancialAdjustmentById(financialAdjustmentId)
  );
  const financialAdjustment = useMemo(
    () => financialAdjustmentData?.data,
    [financialAdjustmentData]
  );

  useEffect(() => {
    setValue('commitment', financialAdjustment?.commitment);
    setValue('otherExpenditure', financialAdjustment?.otherExpenditure || 0);
    setValue(
      'financialAdjustmentItems',
      financialAdjustment?.financialAdjustmentItems?.map(
        ({ proposedExpenditureType, ...rest }) => ({
          ...rest,
          subProposedExpenditureTypeId: proposedExpenditureType?.subProposedExpenditureTypeId,
        })
      )
    );
  }, [financialAdjustment]);

  if (isProjectDataLoading || isFinancialAdjustmentDataLoading) {
    return <Loading />;
  }

  return (
    <div className="financial-adjustment-container">
      <FinancialAdjustmentForm
        watch={watch}
        project={project}
        readOnly={readOnly}
        control={control}
        errors={errors}
        financialAdjustmentId={financialAdjustmentId}
      />
    </div>
  );
}

FinancialAdjustment.propTypes = {
  readOnly: PropTypes.bool,
};

FinancialAdjustment.defaultProps = { readOnly: false };

export default FinancialAdjustment;
