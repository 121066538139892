import { ProjectProposalDefenseViewList } from 'features/ProjectProposalDefense/pages';
import Layout from 'layout';
import { LayoutProvider } from '../layout/context/layoutcontext';

function ProjectProposalDefenseViewListPage() {
  return (
    <LayoutProvider>
      <Layout>
        <ProjectProposalDefenseViewList />
      </Layout>
    </LayoutProvider>
  );
}

export default ProjectProposalDefenseViewListPage;
