/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import './style.scss';
import { useQuery } from 'react-query';

import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { PROJECT_ROLES, TOAST } from 'constant';
import { getAcadamicLevelShortHand, formatCurrency, autoDownloadFile } from 'utils/func';
import { toast } from 'layout';
import {
  downloadDocxFinancialAdjustment,
  downloadPdfFinancialAdjustment,
} from 'apis/financialAdjustment.api';

function GeneralInfo({ data, financialAdjustmentId }) {
  const { t } = useTranslation();
  const owner = useMemo(
    () =>
      data?.projectProposal?.projectProposalContacts?.find(
        (item) => item?.projectRole?.code === PROJECT_ROLES.OWNER
      ) ?? {},
    [data]
  );

  const {
    refetch: handleDownloadPdfFile,
    isLoading: isDownloadPdfFileLoading,
    isFetching: isDownloadPdfFileFetching,
  } = useQuery(
    ['downloadFinancialAdjustmentPdfFile'],
    () => downloadPdfFinancialAdjustment(financialAdjustmentId),
    {
      enabled: false,
      onSuccess: (res) => {
        autoDownloadFile(res);
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxFile,
    isLoading: isDownloadDocxFileLoading,
    isFetching: isDownloadDocxFileFetching,
  } = useQuery(
    ['downloadFinancialAdjustmentDocxFile'],
    () => downloadDocxFinancialAdjustment(financialAdjustmentId),
    {
      enabled: false,
      onSuccess: (res) => {
        autoDownloadFile(res);
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );

  return (
    <div className="relative">
      <div className="flex justify-content-end mb-2">
        <Button
          type="button"
          label={t('projectProposal.review.exportDocx')}
          icon={`pi ${
            isDownloadDocxFileLoading || isDownloadDocxFileFetching
              ? 'pi-spin pi-spinner'
              : 'pi-file-word'
          }`}
          className="block w-auto h-3rem mr-4"
          severity="info"
          onClick={handleDownloadDocxFile}
          disabled={
            !financialAdjustmentId || isDownloadDocxFileLoading || isDownloadDocxFileFetching
          }
        />
        <Button
          type="button"
          label={t('projectProposal.review.exportPdf')}
          icon={`pi ${
            isDownloadPdfFileLoading || isDownloadPdfFileFetching
              ? 'pi-spin pi-spinner'
              : 'pi-file-pdf'
          }`}
          className="block w-auto h-3rem border-none"
          style={{ backgroundColor: 'var(--red-400)' }}
          onClick={handleDownloadPdfFile}
          disabled={!financialAdjustmentId || isDownloadPdfFileLoading || isDownloadPdfFileFetching}
        />
      </div>
      <div className="p-5 flex justify-content-between align-items-center p-fluid">
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="VNU-HCM logo"
          height="60px"
          className="ml-3"
        />
        <div className="mr-8">
          <span>{t('r05.formR05')}:</span>
          <br />
          <span>{t('r05.projectCode')}:</span>
        </div>
      </div>
      <h3 className="mb-5 line-height-3 text-4xl text-center font-bold">
        {t('financialAdjustment.title')}
        <br />
        <span className="font-normal">{t('financialAdjustment.des')}</span>
      </h3>
      <div className="ml-5 text-lg">
        <h4>{t('financialAdjustment.projectName')}</h4>
        <div className="flex mb-1">
          <div className="mx-2 font-semibold">
            - {t('projectProposal.general.a1.vietnameseName')}:
          </div>
          <div className="flex-1">{data?.projectProposal?.vietnameseName}</div>
        </div>
        <div className="flex mb-1">
          <div className="mx-2 font-semibold">
            - {t('projectProposal.general.a1.englishName')}:{' '}
          </div>
          <div className="flex-1">{data?.projectProposal?.englishName}</div>
        </div>
        <div className="flex mb-1 align-items-center">
          <h4>{t('financialAdjustment.owner')}&nbsp;</h4>
          <p className="flex-1">
            {getAcadamicLevelShortHand(owner?.academicRank, owner?.academicDegree, t, true)}
            <span>{owner?.fullname}</span>
          </p>
        </div>

        <div className="flex mb-1 align-items-center">
          <h4>{t('financialAdjustment.totalExpenditure')}&nbsp;</h4>
          <p>
            {formatCurrency(data?.totalExpenditure)}, {t('financialAdjustment.expenditureFromVNU')}
            &nbsp;
            {formatCurrency(data?.expenditureFromVNU)}
          </p>
        </div>
        <div className="flex mb-1 align-items-center">
          <h4>{t('financialAdjustment.time')}&nbsp;</h4>
          <p className="flex-1">
            {moment(data?.endDate).diff(data?.startDate, 'month')} {t('financialAdjustment.month')}{' '}
            {moment(data?.startDate).month() + 1}/{moment(data?.startDate).year()}{' '}
            {t('financialAdjustment.toMonth')} {moment(data?.endDate).month() + 1}/
            {moment(data?.endDate).year()}
          </p>
        </div>
      </div>
    </div>
  );
}

GeneralInfo.propTypes = {
  data: PropTypes.object.isRequired,
  financialAdjustmentId: PropTypes.string,
};

export default GeneralInfo;
