export const USERS = {
  SIGNUP: '/users/signup',
  LOGIN: '/users/login',
  FORGOT_PASSWORD: '/users/identify',
  RESET_PASSWORD: '/users/reset-password',
  UPDATE_PASSWORD: '/users/update-password',
  UPDATE_PROFILE: '/users',
  VERIFY: '/users/verify',
  RESEND: '/users/resend-signup-activation',
  GET_ALL: '/users',
  GET_BY_ID: (id) => `/users/${id}`,
  GET_ME: '/users/me',
  GOOGLE_AUTH_CALLBACK: (queryString) => `/users/auth/google/callback${queryString}`,
  COMPLETE_PROFILE: '/users/complete-profile',
};

export const PROJECT_PROPOSALS = {
  GET_ALL: '/project-proposals',
  GET_ALL_VERSIONS: (id) => `/project-proposals/${id}/all-versions`,
  GET_GENERAL_INFO_BY_ID: (id) => `/project-proposals/general-info/${id}`,
  GET_RESEARCH_RESULT_BY_ID: (id) => `/project-proposals/research-result/${id}`,
  GET_EXPENDITURE_BY_ID: (id) => `/project-proposals/expenditure/${id}`,
  GET_RESEARCH_DESCRIPTION_BY_ID: (id) => `/project-proposals/research-description/${id}`,
  GET_THE_REST_BY_ID: (id) => `/project-proposals/file-urls/${id}`,
  GET_ALL_RESEARCH_TYPES: '/project-proposals/research-types',
  GET_ALL_RESEARCH_FIELDS: '/project-proposals/research-fields',
  GET_ALL_PROJECT_ROLES: '/project-proposals/project-roles',
  GET_OWNER: '/project-proposals/owner',
  GET_ALL_RESEARCH_KPIS: '/project-proposals/research-kpis',
  GET_ALL_RESEARCH_KPI_GROUPS: '/project-proposals/research-kpi-groups',
  GET_RETRIEVE_CONTACTS: '/project-proposals/contacts',
  GET_ALL_ORGANIZATIONS: '/project-proposals/organizations',
  GET_ALL_PROPOSED_EXPENDITURE_TYPES: '/project-proposals/proposed-expenditure-types',
  GET_GENERAL_INFO_FOR_COUNCIL: (id) => `/project-proposals/general-info-for-council/${id}`,
  GET_REVISE_LOGS: (id) => `/project-proposals/revise-log/${id}`,
  GET_REVISE_LOG_BY_ID: (projectProposalId, reviseLogId) =>
    `/project-proposals/revise-log/${projectProposalId}/${reviseLogId}`,
  CREATE: '/project-proposals',
  UPDATE: (id) => `/project-proposals/${id}`,
  DELETE: (id) => `/project-proposals/${id}`,
  UPDATE_STATUS: (id) => `/project-proposals/status/${id}`,
  COMPLETE_UPDATE_REQUESTS: '/project-proposals/revise-log/update-request',
  COMPLETE_EXPLANATION_REQUEST: '/project-proposals/revise-log/explanation-request',
  UPLOAD_FILES: (id) => `/project-proposals/${id}/files`,
  GET_FILE_URLS: (id) => `/project-proposals/file-urls/${id}`,
  DOWNLOAD_FILE: '/project-proposals/download-file',
  DOWNLOAD_DOCX_FILE: (id) => `/project-proposals/docx-file/${id}`,
  DOWNLOAD_PDF_FILE: (id) => `/project-proposals/pdf-file/${id}`,
  DOWNLOAD_ZIP: (id) => `/project-proposals/download-zip/${id}`,
  DOWNLOAD_EXPLANATION: (id) => `/project-proposals/download-explanation/${id}`,
  DOWNLOAD_SCIENTIFIC_PROFILE_FILE: '/project-proposals/download-scientific-profile',
  CREATE_OR_UPDATE_ORGANIZATION: (id) => `/project-proposals/${id}/organization`,
  CREATE_OR_UPDATE_PARTNERS: (id) => `/project-proposals/${id}/partners`,
  UPLOAD_RESEARCH_DESCRIPTION_PROOF_FILES: (id) =>
    `/project-proposals/${id}/research-description-proof-files`,
  GET_PRODUCTS: '/project-proposals/products',
  UPDATE_PROJECT_TYPE: '/project-proposals/revise-log/update-project-type',
};

export const PUB_PROJECT_PROPOSALS = {
  GET_ALL: '/pub-project-proposals',
  GET_BASIC_INFO_ALL: '/pub-project-proposals/basic-info',
  GET_ALL_VERSIONS: (id) => `/pub-project-proposals/${id}/all-versions`,
  GET_GENERAL_INFO_BY_ID: (id) => `/pub-project-proposals/general-info/${id}`,
  GET_RESEARCH_RESULT_BY_ID: (id) => `/pub-project-proposals/research-result/${id}`,
  GET_EXPENDITURE_BY_ID: (id) => `/pub-project-proposals/expenditure/${id}`,
  GET_ALL_RESEARCH_TYPES: '/pub-project-proposals/research-types',
  GET_ALL_RESEARCH_FIELDS: '/pub-project-proposals/research-fields',
  GET_ALL_STASTUSES: '/pub-project-proposals/statuses',
};

export const PROJECTS = {
  GET_ALL: '/projects',
  GET_PROJECT_BY_ID: (id) => `/projects/${id}`,
  GET_PROJECT_BY_PROJECT_PROPOSAL_ID: (projectProposalId) =>
    `/projects/projectProposalId/${projectProposalId}`,
  POST_SUBMIT_BY_ID: (id) => `/projects/${id}`,
  POST_DOWNLOAD_PDF: (id) => `/projects/download-file/${id}`,

  GET_PROJECT_CODE: (id) => `/projects/project-code/${id}`,
};

export const PROJECT_PROPOSALS_DEFENSE = {
  GET_ALL: '/project-proposals/defense',
};

export const ORGANIZATIONS = {
  GET_ALL: '/organizations',
  GET_BY_ID: (id) => `/organizations/${id}`,
};

export const SCIENTIFIC_PROFILES = {
  UPLOAD_FILE: '/users/upload-file',
  UPLOAD_IMAGE: '/users/upload-avatar',
  DOWNLOAD_FILE: '/users/download-file',
  DOWNLOAD_IMAGE: '/users/get-avatar',
  CHECK_FILE_FORMAT: '/scientific-profiles/check-format',
  CHECK_EMAIL_NAME: '/scientific-profiles/check-email-name',
  DOWNLOAD_AVATAR_BY_ID: (id) => `/users/get-avatar/${id}`,

  CREATE: '/scientific-profiles',
  UPDATE: '/scientific-profiles',

  GET_GENERAL_INFO: '/scientific-profiles/general-info',
  GET_ACADEMIC_HISTORY: '/scientific-profiles/academic-history',
  GET_WORK_HISTORY: '/scientific-profiles/work-history',
  GET_PROJECTS: '/scientific-profiles/projects',
  GET_THESIS_GUIDANCES: '/scientific-profiles/thesis-guidances',
  GET_SCIENTIFIC_WORKS: '/scientific-profiles/scientific-works',
  GET_OTHERS_INFO: '/scientific-profiles/others',

  GET_GENERAL_INFO_DOCX_FILE: '/scientific-profiles/general-info-from-docx',
  GET_ACADEMIC_HISTORY_DOCX_FILE: '/scientific-profiles/academic-history-from-docx',
  GET_WORK_HISTORY_DOCX_FILE: '/scientific-profiles/work-history-from-docx',
  GET_PROJECTS_DOCX_FILE: '/scientific-profiles/research-activities-from-docx',
  GET_THESIS_GUIDANCES_DOCX_FILE: '/scientific-profiles/guiding-activities-from-docx',
  GET_SCIENTIFIC_WORKS_DOCX_FILE: '/scientific-profiles/scientific-works-from-docx',
  GET_OTHERS_INFO_DOCX_FILE: '/scientific-profiles/others-info-from-docx',
  GET_PUBLIC_ABOUT_INFO: (id) => `/scientific-profiles/public/about/${id}`,
  GET_PUBLIC_RESEARCH_TEACHING_INFO: (id) =>
    `/scientific-profiles/public/researchAndTeaching/${id}`,
  GET_PUBLIC_SCIENTIFIC_WORKS_INFO: (id) => `/scientific-profiles/public/scientificWorks/${id}`,
  GET_PUBLIC_AWARDS_INFO: (id) => `/scientific-profiles/public/awards/${id}`,
  GET_PUBLIC_OTHERS_INFO: (id) => `/scientific-profiles/public/others/${id}`,
  CREATE_PUBLIC_PROFILE_ID: (id) => `/scientific-profiles/public-profile/${id}`,
  UPDATE_PUBLIC_PROFILE_ID: '/scientific-profiles/public-profile',
};

export const CONTACTS = {
  GET_ALL: '/contacts',
  GET_BY_EMAIL: '/contacts/email',
  GET_MY_CONTACT: '/contacts/me',
  GET_BY_SEARCH_EMAIL: '/contacts/search',
  CREATE: '/contacts',
  UPDATE: '/contacts',
};

export const PROGRAMS = {
  GET_ALL: '/programs',
};

export const COUNCILS = {
  GET_ALL: '/councils',
  GET_BY_ID: '/councils/detail',
  DOWNLOAD_FILE: '/councils/download-file',
  GET_ALL_BY_PROJECT_ID: (id) => `/councils/project-proposal/${id}`,
};

export const BOOKS = {
  CREATE: '/scientific-profiles/books',
  GET_BY_SEARCH_TITLE: '/scientific-profiles/books',
};
export const PAPERS = {
  CREATE: '/scientific-profiles/papers',
  GET_BY_SEARCH_TITLE: '/scientific-profiles/papers',
};

export const PREQUALIFICATION_REVIEW = {
  GET_PROJECT_PROPOSAL_INFO: '/prequalification-reviews/project-proposal',
  GET_REVIEWER_INFO: '/prequalification-reviews/reviewer',
  GET_DETAIL: '/prequalification-reviews/detail',
  CREATE: '/prequalification-reviews',
  UPDATE: '/prequalification-reviews',
  SUBMIT: '/prequalification-reviews/submit',

  GET_DETAIL_FILE: '/prequalification-reviews/detail/file-url',
  DOWNLOAD_FILE: '/prequalification-reviews/download-file',
  DOWNLOAD_PDF_FILE: '/prequalification-reviews/pdf-file',
  DOWNLOAD_PDF_TEMPLATE: '/prequalification-reviews/pdf-template',
  DOWNLOAD_DOCX_FILE: '/prequalification-reviews/docx-file',
  DOWNLOAD_DOCX_TEMPLATE: '/prequalification-reviews/docx-template',
};
export const SPECIALIZE_REVIEW = {
  GET_PROJECT_PROPOSAL_INFO: '/specialize-reviews/project-proposal',
  GET_REVIEWER_INFO: '/specialize-reviews/reviewer',
  GET_DETAIL: 'specialize-reviews/detail',
  CREATE: '/specialize-reviews',
  UPDATE: '/specialize-reviews',
  SUBMIT: '/specialize-reviews/submit',

  GET_DETAIL_FILE: '/specialize-reviews/detail/file-url',
  DOWNLOAD_FILE: '/specialize-reviews/download-file',
  DOWNLOAD_PDF_FILE: '/specialize-reviews/pdf-file',
  DOWNLOAD_PDF_TEMPLATE: '/specialize-reviews/pdf-template',
  DOWNLOAD_DOCX_FILE: '/specialize-reviews/docx-file',
  DOWNLOAD_DOCX_TEMPLATE: '/specialize-reviews/docx-template',
};

export const MIDTERM_REVIEW = {
  GET_PROJECT_PROPOSAL_INFO: '/midterm-reviews/project-proposal',
  GET_REVIEWER_INFO: '/midterm-reviews/reviewer',
  GET_DETAIL: 'midterm-reviews/detail',
  CREATE: '/midterm-reviews',
  UPDATE: '/midterm-reviews',
  SUBMIT: '/midterm-reviews/submit',

  GET_DETAIL_FILE: '/specialize-reviews/detail/file-url',
  DOWNLOAD_FILE: '/specialize-reviews/download-file',
  DOWNLOAD_PDF_FILE: '/midterm-reviews/pdf-file',
  DOWNLOAD_PDF_TEMPLATE: '/midterm-reviews/pdf-template',
  DOWNLOAD_DOCX_FILE: '/midterm-reviews/docx-file',
  DOWNLOAD_DOCX_TEMPLATE: '/midterm-reviews/docx-template',
};

export const FINANCIAL_REVIEW = {
  GET_INFO: '/financial-reviews/info',
  GET_DETAIL: 'financial-reviews/detail',
  CREATE: '/financial-reviews',
  UPDATE: (id) => `/financial-reviews/${id}`,
  SUBMIT: '/financial-reviews/submit',

  GET_DETAIL_FILE: '/financial-reviews/detail/file-url',
  DOWNLOAD_FILE: '/financial-reviews/download-file',
  DOWNLOAD_PDF_FILE: '/financial-reviews/pdf-file',
  DOWNLOAD_PDF_TEMPLATE: '/financial-reviews/pdf-template',
  DOWNLOAD_DOCX_FILE: '/financial-reviews/docx-file',
  DOWNLOAD_DOCX_TEMPLATE: '/financial-reviews/docx-template',
};

export const ACCEPTANCE_REVIEW = {
  CREATE_REVIEW: '/acceptance-reviews',
  UPDATE_REVIEW: '/acceptance-reviews',
  SUBMIT_REVIEW: '/acceptance-reviews/submit',

  GET_REVIEWER_INFO: '/acceptance-reviews/reviewer-information',
  GET_REVIEW_DETAIL: '/acceptance-reviews/detail',
  GET_REVIEW_DETAIL_FILE: '/acceptance-reviews/detail/file',

  DOWNLOAD_FILE: '/acceptance-reviews/download-file',
  DOWNLOAD_PDF_FILE: '/acceptance-reviews/download-pdf',
  DOWNLOAD_PDF_TEMPLATE: '/acceptance-reviews/download-pdf-template',
  DOWNLOAD_DOCX_FILE: '/acceptance-reviews/docx-file',
  DOWNLOAD_DOCX_TEMPLATE: '/acceptance-reviews/docx-template',
};

export const SUMMARY_REPORT = {
  CREATE_REPORT: '/summary-report',
  UPDATE_REPORT: (id) => `/summary-report/${id}`,
  CREATE_OR_UPDATE_EXPENDITURE_FILE: '/summary-report/upload-expenditure-file',
  UPLOAD_OR_UPDATE_PRODUCT_FILE: '/summary-report/upload-product-files',
  DELETE_PRODUCT_FILE: '/summary-report/delete-product-files',
  SUBMIT_SUMMARY_REPORT: '/summary-report/submit',
  UPDATE_EXPENDITURE: '/summary-report/expenditures',
  CREATE_OR_UPDATE_CONFERENCE: '/summary-report/conference',
  UPDATE_REVIEW_SUGGESTION: '/summary-report/review-suggestion',

  DOWNLOAD_REPORT_FILE: '/summary-report/download-file',
  GET_SUMMARY_REPORT: (id) => `/summary-report/${id}`,
  GET_TYPE_SUMMARY_REPORT: '/summary-report/summary-report-types',
  GET_EXPENDITURE_TYPE_SUMMARY_REPORT: '/summary-report/summary-report-expenditure-types',
  GET_SUMMARY_REPORT_PRODUCT_TYPE: '/summary-report/summary-report-product-types',
  GET_SUMMARY_REPORT_PRODUCT_GROUPS: '/summary-report/summary-report-product-groups',
  GET_EXPENDITURE: '/summary-report/expenditures',
  DOWNLOAD_REPORT_PDF_FILE: '/summary-report/download-pdf',
  DOWNLOAD_REPORT_DOCX_FILE: '/summary-report/download-docx',
};

export const REGISTER_RESULT = {
  CREATE_REGITTER_RESULT: '/register-results',
  UPDATE_REGITTER_RESULT: (registerResultId) => `/register-results/${registerResultId}`,
  UPDATE_SUBMIT_REGITTER_RESULT: (registerResultId) =>
    `/register-results/submit/${registerResultId}`,
  GET_REGITTER_RESULT: (projectId) => `/register-results/${projectId}`,
  GET_INSPECTION_RESULT: '/register-results/inspection',
};

export const NOTIFICATION = {
  GET_ALL: '/notifications',
  GET_BY_ID: (id) => `/notifications/${id}`,
  READ_NOTIFICATION: (id) => `/notifications/${id}/read`,
  UNREAD_NOTIFICATION: (id) => `/notifications/${id}/unread`,
  READ_ALL: '/notifications/read-all',
  GET_UNREAD: '/notifications/unread',
  MARK_AS_READ: '/notifications/mark-as-read',
  MARK_AS_UNREAD: '/notifications/mark-as-unread',
  DELETE_NOTIFICATION: (id) => `/notifications/${id}`,
};

// #region EXTEND_IMPLEMENTATION_TIME
export const EXTEND_IMPLEMENTATION_TIME = {
  GET_ALL: '/extend-implementation-time/all',
  GET_ALL_VERSIONS: '/extend-implementation-time/all-versions',
  GET_BY_ID: '/extend-implementation-time',
  CREATE: '/extend-implementation-time',
  UPDATE: '/extend-implementation-time',
  SUBMIT: (id) => `/extend-implementation-time/submit/${id}`,
  DOWNLOAD_DOCX_FILE: '/extend-implementation-time/docx-file',
  DOWNLOAD_DOCX_TEMPLATE: '/extend-implementation-time/docx-template',
  DOWNLOAD_PDF_FILE: '/extend-implementation-time/download-pdf',
  DOWNLOAD_PDF_TEMPLATE: '/extend-implementation-time/download-pdf-template',
  UPLOAD_EXTEND_FILE: '/extend-implementation-time/upload-extend-files',
  DELETE_EXTEND_FILE: '/extend-implementation-time/delete-extend-time-files',
  DOWNLOAD_FILE: '/extend-implementation-time/download-file',
};
// #endregion

export const FINANCIAL_ADJUSTMENT = {
  GET_ALL: (projectId) => `/financial-adjustments/${projectId}`,
  GET_BY_ID: (id) => `/financial-adjustments/${id}`,
  DOWNLOAD_PDF_FILE: (id) => `/financial-adjustments/pdf-file/${id}`,
  DOWNLOAD_DOCX_FILE: (id) => `/financial-adjustments/word-file/${id}`,
  DOWNLOAD_FILE: '/financial-adjustments/download-file',
};

// #region LIQUIDATION_AGREEMENT
export const LIQUIDATION_AGREEMENT = {
  POST_LIQUIDATION: '/liquidation',
  GET_ALL_LIQUIDATION: '/projects/liquidation',
  GET_LIQUIDATION_BY_ID: '/liquidation',
  SUBMIT_LIQUIDATION: (id) => `/liquidation/submit-liquidation/${id}`,
  UPLOAD_LIQUIDATION_FILES: '/liquidation/upload-liquidation-files',
  UPDATE_LIQUIDATION_FILES: '/liquidation/update-liquidation-files',
  DELETE_LIQUIDATION_FILES: '/liquidation/delete-liquidation-files',
  DOWNLOAD_FILE: '/liquidation/download-file',
};
// #endregion
