import './style.scss';

import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// import { FileInput } from 'components/FormControl';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { formatFileName, formatFileNameProduct } from 'utils/func';
import { Button } from 'primereact/button';
import CustomFileInput from 'components/FormControl/CustomFileInput';
import { useEffect } from 'react';

export default function UploadMultipleFile({
  disabled,
  control,
  errors,
  summaryReportAppendixeArray,
  handleDownloadFile,
  setAppendixFilesDelete,
  setSummaryReportAppendixeArray,
  projectCode,
  resetAppendixFiles,
  setResetAppendixFiles,
  reset,
}) {
  // #region Data
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'appendixFiles',
  });
  useEffect(() => {
    if (resetAppendixFiles) {
      fields.map((item, index) => remove(index));
      setResetAppendixFiles(false);
      reset({}, { KeepValues: true });
    }
  }, [JSON.stringify(resetAppendixFiles)]);

  // #endregion Data
  return (
    <>
      {!disabled && <h5>{t('projectProposal.researchDescription.otherProofsAndAppendices')}</h5>}
      {fields?.map((item, index) => (
        <div className="relative pl-4" key={item.id}>
          {!disabled && <hr className="border border-1 border-primary my-1" />}
          <span className="font-bold">
            {t('submitDocument.appendix')} {(summaryReportAppendixeArray?.length || 0) + index + 1}
          </span>

          {!disabled && (
            <CustomFileInput
              name={`appendixFiles.${index}.file`}
              accept=".pdf"
              defaultNameFile={`${formatFileNameProduct(
                'a.pdf',
                projectCode,
                'PL',
                (summaryReportAppendixeArray?.length || 0) + index + 1
              )}`}
              control={control}
              errors={errors}
              acceptOnly="pdfOnly"
            />
          )}

          {!disabled && (
            <button
              className="p-button p-button-text p-button-icon-only p-0 absolute top-0 right-0 mt-2"
              type="button"
              onClick={() => remove(index)}
              disabled={disabled}
            >
              <i type="button" className="pi pi-times text-red-500 text-xl" />
            </button>
          )}
        </div>
      ))}
      {!disabled && (
        <div className="pl-4">
          <hr className="border border-1 border-primary mt-1 mb-1" />
          <div className="flex justify-content-center">
            <button
              className="p-button p-button-text p-button-icon-only p-0"
              type="button"
              onClick={() => {
                append({
                  id: uuidv4(),
                  file: null,
                });
              }}
              disabled={disabled}
            >
              <i type="button" className="pi pi-fw pi-plus text-primary text-xl" />
            </button>
          </div>
        </div>
      )}
      <div className="file-appendix-table mt-4">
        {summaryReportAppendixeArray?.length !== 0 && <h5>{t('submitDocument.commentFile')}</h5>}
        <table>
          <tbody>
            {summaryReportAppendixeArray?.map((summaryReportAppendixe) => (
              <tr key={summaryReportAppendixe?.fileUrl}>
                <td>
                  <span
                    className="p-button p-button-text p-button-sm mt-3 p-0"
                    onClick={
                      () =>
                        handleDownloadFile(
                          summaryReportAppendixe?.fileUrl,
                          formatFileName(summaryReportAppendixe?.fileUrl)
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                    <span className="text-blue-500 underline font-italic text-base">
                      {summaryReportAppendixe?.fileUrl &&
                        formatFileName(summaryReportAppendixe?.fileUrl ?? '')}
                    </span>
                  </span>
                </td>
                <td>
                  {' '}
                  {!disabled && (
                    <Button
                      icon="pi pi-times"
                      rounded
                      text
                      severity="danger"
                      aria-label="Cancel"
                      type="button"
                      onClick={() => {
                        setAppendixFilesDelete((prev) => [...prev, summaryReportAppendixe?.id]);
                        setSummaryReportAppendixeArray((prev) =>
                          prev.filter((item) => item?.id !== summaryReportAppendixe?.id)
                        );
                      }}
                      disabled={disabled}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

UploadMultipleFile.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  summaryReportAppendixeArray: PropTypes.array.isRequired,
  handleDownloadFile: PropTypes.func,
  setAppendixFilesDelete: PropTypes.func,
  setSummaryReportAppendixeArray: PropTypes.func.isRequired,
  projectCode: PropTypes.string,
  resetAppendixFiles: PropTypes.bool,
  setResetAppendixFiles: PropTypes.func,
  reset: PropTypes.func,
};

UploadMultipleFile.defaultProps = {
  disabled: false,
  handleDownloadFile: () => null,
  setAppendixFilesDelete: () => null,
  projectCode: '',
  resetAppendixFiles: false,
  setResetAppendixFiles: () => null,
  reset: () => null,
};
