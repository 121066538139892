import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { formatFileName } from 'utils/func';

export default function Documents({ data, handleDownloadFile }) {
  // #region Data
  const { t } = useTranslation();

  // #endregion
  return (
    <div className="mt-4">
      <h4>{t('projectProposal.documents.label')}</h4>

      {/* eslint-disable indent */}
      {!data.researchDescriptionFileUrl &&
      !data.expenditureFileUrl &&
      !data?.researchDescriptionProofFiles?.length ? (
        <span>({t('projectProposal.documents.noAttachment')})</span>
      ) : (
        <ul className="list-disc text-lg">
          {data?.researchDescriptionFileUrl && (
            <li className="mb-2">
              <span
                className="p-link text-primary underline"
                onClick={() => handleDownloadFile(data?.researchDescriptionFileUrl)}
              >
                {t('projectProposal.documents.projectProposal')}
              </span>
            </li>
          )}
          {data?.expenditureFileUrl && (
            <li>
              <span
                className="p-link text-primary underline"
                onClick={() => handleDownloadFile(data?.expenditureFileUrl)}
              >
                {t('projectProposal.documents.explanatoryAppendix')}
              </span>
            </li>
          )}
          {data?.researchDescriptionProofFiles?.length > 0 && (
            <li>
              <span className="p-link" style={{ cursor: 'default', color: '#495057' }}>
                {t('projectProposal.documents.otherProofFilesAndAppendices')}:
              </span>
              <ul className="-ml-4 mt-1">
                {data?.researchDescriptionProofFiles?.map((item, index) => {
                  if (item?.fileName) {
                    return (
                      <li key={item.id ?? index} className="pl-0 ml-0 mt-1">
                        <span
                          className="p-link text-primary underline"
                          onClick={() => handleDownloadFile(item?.fileName)}
                        >
                          {formatFileName(item?.fileName)}
                        </span>
                      </li>
                    );
                  }

                  return null;
                })}
              </ul>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

Documents.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
};
