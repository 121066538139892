/* eslint-disable indent */
/* eslint-disable no-shadow */
import './style.scss';

import { getAll as getAllProjectProposalsDefense } from 'apis/projectProposalsDefenses.api';
import { COUNCIL_TYPE_CODE, TIMEZONE_VN } from 'constant';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
  getOneReviewProjectProposalCouncilMembers,
  isMemberAllowedReview,
  mapError,
} from 'utils/func';

function ProjectProposalDefenseViewList() {
  // #region Data
  const { t } = useTranslation();

  const { data, isLoading, isError, error } = useQuery(
    ['projectProposalsDefense'],
    getAllProjectProposalsDefense
  );
  // #endregion

  // #region Events
  const handleClick = (value, projectProposalCouncilMembers) => {
    const queryParams = `councilId=${value?.projectProposalCouncils?.[0].council?.id}&councilMemberId=${value?.projectProposalCouncils?.[0].council?.councilMembers[0]?.id}`;
    if (
      value?.projectProposalCouncils?.[0].council?.councilType?.code ===
      COUNCIL_TYPE_CODE.PRELIMINARY_SELECTION_COUNCIL
    ) {
      if (
        new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline) ||
        !isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0])
      ) {
        return `/prequalification-review/detail/${value?.id}?${queryParams}`;
      }
      if (projectProposalCouncilMembers?.[0]?.isReviewOptionForm) {
        return `/prequalification-review/edit/${value?.id}?${queryParams}`;
      }
      return `/prequalification-review/create/${value?.id}?${queryParams}`;
    }
    if (
      value?.projectProposalCouncils?.[0].council?.councilType?.code ===
      COUNCIL_TYPE_CODE.SPECIALIZATION_COUNCIL
    ) {
      if (
        new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline) ||
        !isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0])
      ) {
        return `/specialize-review/detail/${value?.id}?${queryParams}`;
      }
      if (projectProposalCouncilMembers?.[0]?.isReviewOptionForm) {
        return `/specialize-review/edit/${value?.id}?${queryParams}`;
      }
      return `/specialize-review/create/${value?.id}?${queryParams}`;
    }
    if (
      value?.projectProposalCouncils?.[0].council?.councilType?.code ===
      COUNCIL_TYPE_CODE.FINANCE_COUNCIL
    ) {
      if (
        new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline) ||
        !isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0])
      ) {
        return `/financial-review/detail/${value?.id}?${queryParams}`;
      }
      if (projectProposalCouncilMembers?.[0]?.isReviewOptionForm) {
        return `/financial-review/edit/${value?.id}?${queryParams}`;
      }
      return `/financial-review/create/${value?.id}?${queryParams}`;
    }
    if (
      value?.projectProposalCouncils?.[0].council?.councilType?.code ===
      COUNCIL_TYPE_CODE.MIDTERM_REPORT_COUNCIL
    ) {
      if (
        new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline) ||
        !isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0])
      ) {
        return `/midterm-review/detail/${value?.id}?${queryParams}`;
      }
      if (projectProposalCouncilMembers?.[0]?.isReviewOptionForm) {
        return `/midterm-review/edit/${value?.id}?${queryParams}`;
      }
      return `/midterm-review/create/${value?.id}?${queryParams}`;
    }
    if (
      value?.projectProposalCouncils?.[0].council?.councilType?.code ===
      COUNCIL_TYPE_CODE.ACCEPTANCE_COUNCIL
    ) {
      if (
        new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline) ||
        !isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0])
      ) {
        return `/acceptance-review/detail/${value?.id}?${queryParams}`;
      }
      if (projectProposalCouncilMembers?.[0]?.isReviewOptionForm) {
        return `/acceptance-review/edit/${value?.id}?${queryParams}`;
      }
      return `/acceptance-review/create/${value?.id}?${queryParams}`;
    }

    return '';
  };
  // #endregion

  const formatActions = (value) => {
    const projectProposalCouncilMembers = getOneReviewProjectProposalCouncilMembers(
      value?.projectProposalCouncils?.[0].council?.councilMembers?.[0]
        ?.projectProposalCouncilMembers,
      value?.reference
    );
    const isReviewDeadlinePassed =
      new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline);

    return (
      <div className="flex gap-2 justify-content-center">
        {isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0]) &&
          (isReviewDeadlinePassed ? (
            <Link to={handleClick(value, projectProposalCouncilMembers)}>
              <Button
                className="bg-green-600 border-green-600 hover:bg-green-700 hover:border-green-700"
                icon="pi pi-comment"
                severity="info"
                tooltip={t('council.view.viewReview')}
                tooltipOptions={{ position: 'left' }}
              />
            </Link>
          ) : (
            <Link to={handleClick(value, projectProposalCouncilMembers)}>
              <Button
                className="bg-orange-600 border-orange-600 hover:bg-orange-700 hover:border-orange-700"
                icon="pi pi-pencil"
                severity="info"
                tooltip={t('council.review.createOrUpdate')}
                tooltipOptions={{ position: 'left' }}
              />
            </Link>
          ))}
      </div>
    );
  };

  const formatName = (value) => {
    const projectProposalCouncilMembers = getOneReviewProjectProposalCouncilMembers(
      value?.projectProposalCouncils?.[0].council?.councilMembers?.[0]
        ?.projectProposalCouncilMembers,
      value?.reference
    );
    let toPage = '';
    const queryParams = `councilId=${value?.projectProposalCouncils?.[0].council?.id}&councilMemberId=${value?.projectProposalCouncils?.[0].council?.councilMembers[0]?.id}`;
    if (
      value?.projectProposalCouncils?.[0].council?.councilType?.code ===
      COUNCIL_TYPE_CODE.PRELIMINARY_SELECTION_COUNCIL
    ) {
      if (
        new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline) ||
        !isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0])
      ) {
        toPage = `/prequalification-review/detail/${value?.id}?${queryParams}`;
      } else if (projectProposalCouncilMembers?.[0]?.isReviewOptionForm) {
        toPage = `/prequalification-review/edit/${value?.id}?${queryParams}`;
      } else {
        toPage = `/prequalification-review/create/${value?.id}?${queryParams}`;
      }
    }
    if (
      value?.projectProposalCouncils?.[0].council?.councilType?.code ===
      COUNCIL_TYPE_CODE.SPECIALIZATION_COUNCIL
    ) {
      if (
        new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline) ||
        !isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0])
      ) {
        toPage = `/specialize-review/detail/${value?.id}?${queryParams}`;
      } else if (projectProposalCouncilMembers?.[0]?.isReviewOptionForm) {
        toPage = `/specialize-review/edit/${value?.id}?${queryParams}`;
      } else {
        toPage = `/specialize-review/create/${value?.id}?${queryParams}`;
      }
    }
    if (
      value?.projectProposalCouncils?.[0].council?.councilType?.code ===
      COUNCIL_TYPE_CODE.FINANCE_COUNCIL
    ) {
      if (
        new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline) ||
        !isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0])
      ) {
        toPage = `/financial-review/detail/${value?.id}?${queryParams}`;
      } else if (projectProposalCouncilMembers?.[0]?.isReviewOptionForm) {
        toPage = `/financial-review/edit/${value?.id}?${queryParams}`;
      } else {
        toPage = `/financial-review/create/${value?.id}?${queryParams}`;
      }
    }
    if (
      value?.projectProposalCouncils?.[0].council?.councilType?.code ===
      COUNCIL_TYPE_CODE.MIDTERM_REPORT_COUNCIL
    ) {
      if (
        new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline) ||
        !isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0])
      ) {
        toPage = `/midterm-review/detail/${value?.id}?${queryParams}`;
      } else if (projectProposalCouncilMembers?.[0]?.isReviewOptionForm) {
        toPage = `/midterm-review/edit/${value?.id}?${queryParams}`;
      } else {
        toPage = `/midterm-review/create/${value?.id}?${queryParams}`;
      }
    }
    if (
      value?.projectProposalCouncils?.[0].council?.councilType?.code ===
      COUNCIL_TYPE_CODE.ACCEPTANCE_COUNCIL
    ) {
      if (
        new Date() > new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline) ||
        !isMemberAllowedReview(value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0])
      ) {
        toPage = `/acceptance-review/detail/${value?.id}?${queryParams}`;
      } else if (projectProposalCouncilMembers?.[0]?.isReviewOptionForm) {
        toPage = `/acceptance-review/edit/${value?.id}?${queryParams}`;
      } else {
        toPage = `/acceptance-review/create/${value?.id}?${queryParams}`;
      }
    }

    return (
      <Link to={`${toPage}`} className="hover:underline">
        {value?.vietnameseName}
      </Link>
    );
  };

  const formatRole = (value) =>
    value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0]?.councilMemberRole?.code &&
    t(
      `council.role.${value?.projectProposalCouncils?.[0]?.council?.councilMembers?.[0]?.councilMemberRole?.code}`
    );

  const formatReviewDeadline = (value) => {
    if (value?.projectProposalCouncils?.[0]?.reviewDeadline < new Date().toISOString()) {
      return (
        <span className="text-red-600">
          {value?.projectProposalCouncils?.[0]?.reviewDeadline
            ? new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline).toLocaleDateString(
                'vi',
                { timeZone: TIMEZONE_VN }
              )
            : ''}
          <div>{t('projectProposalCouncil.viewList.expiredReviewDeadline')}</div>
        </span>
      );
    }
    return value?.projectProposalCouncils?.[0]?.reviewDeadline
      ? new Date(value?.projectProposalCouncils?.[0]?.reviewDeadline).toLocaleDateString('vi', {
          timeZone: TIMEZONE_VN,
        })
      : '';
  };

  const formatTimeMet = (council) =>
    council?.projectProposalCouncils?.[0]?.timeMet
      ? new Date(council?.projectProposalCouncils?.[0]?.timeMet).toLocaleDateString('vi', {
          timeZone: TIMEZONE_VN,
        })
      : '';

  const formatOwnerName = (value) => value?.projectProposalContacts?.[0]?.fullname;

  const formatCouncilType = (value) =>
    t(
      `council.view.councilType.${value?.projectProposalCouncils?.[0]?.council?.councilType?.code}`
    );
  // #endregion

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          {isError && <h3 className="text-red-600">{mapError(t, error)}</h3>}
          <div className="flex justify-content-between align-items-end mb-4 pb-2">
            <h5 className="p-0 m-0">{t('projectProposalCouncil.viewList.title')}</h5>
          </div>

          <DataTable
            value={data?.data ?? []}
            className="p-datatable-gridlines header-table"
            showGridlines
            dataKey="key"
            filterDisplay="menu"
            loading={isLoading}
            emptyMessage={t('projectProposal.viewList.emptyMessage')}
          >
            <Column
              field="key"
              header={t('council.viewList.order')}
              headerStyle={{ justifyContent: 'center' }}
              style={{ minWidth: '2rem', textAlign: 'center' }}
            />
            <Column
              header={t('projectProposal.viewList.nameVN')}
              style={{ minWidth: '12rem' }}
              body={formatName}
            />
            <Column
              header={t('projectProposal.viewList.owner')}
              headerStyle={{ justifyContent: 'center' }}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatOwnerName}
            />
            <Column
              header={t('council.fields.role')}
              headerStyle={{ justifyContent: 'center' }}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatRole}
            />
            <Column
              header={t('council.viewList.councilType')}
              headerStyle={{ justifyContent: 'center' }}
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatCouncilType}
            />
            <Column
              header={t('projectProposal.viewList.reviewDeadline')}
              dataType="numeric"
              style={{ minWidth: '8rem', textAlign: 'center' }}
              body={formatReviewDeadline}
            />
            <Column
              header={t('council.fields.meetingDate')}
              style={{ minWidth: '7rem', textAlign: 'center' }}
              body={formatTimeMet}
            />
            <Column
              header={t('projectProposal.action')}
              body={formatActions}
              style={{ width: '6.5rem', textAlign: 'center' }}
              frozen
              alignFrozen="right"
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export default ProjectProposalDefenseViewList;
