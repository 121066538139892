/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { getDetail, update as updateSpecializeReview } from 'apis/specializeReviews.api';
import { TOAST } from 'constant';
import { ProjectProposalDefenseDetail } from 'features/ProjectProposalDefense/pages';
import { EditForm } from 'features/SpecializeReview/components';
import { createSpecializeReviewValidationSchema } from 'features/SpecializeReview/validation';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';

export default function SpecializeReviewFormEdit() {
  const { t } = useTranslation();
  const [isVisibleErrorDialog, setIsVisibleDialogError] = useState(false);
  const [tab, setTab] = useState(0);
  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');

  // binding data specialize review
  const {
    data: dataSpecializeReview,
    isLoading: isLoadingSpecializeReview,
    refetch: refetchSpecializeReview,
  } = useQuery(
    ['specialize-review', 'detail', councilMemberId, councilId, projectProposalId],
    ({ queryKey: [, , _councilMemberId, _councilId, _projectProposalId] }) =>
      getDetail(_councilMemberId, _councilId, _projectProposalId)
  );

  const specializeReviewData = useMemo(
    () => dataSpecializeReview?.data ?? {},
    [dataSpecializeReview]
  );
  // #endregion

  // #region Events

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', resolver: yupResolver(createSpecializeReviewValidationSchema) });

  const { isDirty, dirtyFields } = useFormState({ control });

  const {
    mutate,
    error: errorRes,
    isError: isErrorRes,
    isLoading: isLoadingUpdate,
  } = useMutation(updateSpecializeReview);

  const onSubmit = async (data) => {
    const reviewExpenditure = Object.keys(data?.expenditure)?.reduce((newArr, key) => {
      newArr.push({ expenditureTypeId: key, ...data?.expenditure?.[key] });
      return newArr;
    }, []);

    const submitData = {
      id: dataSpecializeReview?.data?.specializeReview?.id,
      projectProposalId,
      councilId,
      councilMemberId,
      reviewImportance: data?.reviewImportance,
      reviewQuality: data?.reviewQuality,
      reviewPicapability: data?.reviewPicapability,
      reviewConclusion: data?.reviewConclusion,
      reviewExpenditure: JSON.stringify(reviewExpenditure),
      expenditureTotalSuggestion: data?.expenditureTotalSuggestion,
      // eslint-disable-next-line no-nested-ternary
      scoreImportance: data?.score?.scoreImportance
        ? data?.score?.scoreImportance
        : (data?.score?.scoreCreativity ?? 0) + (data?.score?.scoreSuitableness ?? 0) !== 0
        ? (data?.score?.scoreCreativity ?? 0) + (data?.score?.scoreSuitableness ?? 0)
        : null,
      scoreQuality: data?.score?.scoreQuality,
      // eslint-disable-next-line no-nested-ternary
      scoreProduct: data?.score?.scoreProduct
        ? data?.score?.scoreProduct
        : (data?.score?.scoreAnnouncement ?? 0) + (data?.score?.scoreTraining ?? 0) !== 0
        ? (data?.score?.scoreAnnouncement ?? 0) + (data?.score?.scoreTraining ?? 0)
        : null,
      scoreCapability: data?.score?.scoreCapability,
      scoreSubCriteria: data?.score?.scoreSubCriteria,
      scoreCreativity: data?.score?.scoreCreativity,
      scoreSuitableness: data?.score?.scoreSuitableness,
      scoreAnnouncement: data?.score?.scoreAnnouncement,
      scoreTraining: data?.score?.scoreTraining,
    };

    mutate(submitData, {
      onSuccess: () => {
        refetchSpecializeReview();
        reset({}, { keepValues: true });
        toast(TOAST.SUCCESS, t('council.review.updateSuccess'));
      },
      onError: () => {
        setIsVisibleDialogError(true);
      },
    });
  };

  useEffect(() => {
    if (specializeReviewData?.specializeReview) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { specializeReview } = specializeReviewData;
      // binding review data from specialize-review
      setValue('reviewImportance', specializeReview?.reviewImportance ?? null);
      setValue('reviewQuality', specializeReview?.reviewQuality ?? null);
      setValue('reviewPicapability', specializeReview?.reviewPicapability ?? null);
      setValue('reviewConclusion', specializeReview?.reviewConclusion ?? null);
      setValue('expenditureTotalSuggestion', specializeReview?.expenditureTotalSuggestion ?? null);

      // binding score data from specialize-review
      setValue('score.scoreCreativity', specializeReview?.scoreCreativity ?? 0);
      setValue('score.scoreSuitableness', specializeReview?.scoreSuitableness ?? 0);
      setValue(
        'score.scoreImportance',
        specializeReview?.scoreImportance ??
          (specializeReview?.scoreCreativity ?? 0) + (specializeReview?.scoreSuitableness ?? 0)
      );
      setValue('score.scoreQuality', specializeReview?.scoreQuality ?? 0);
      setValue('score.scoreAnnouncement', specializeReview?.scoreAnnouncement ?? 0);
      setValue('score.scoreTraining', specializeReview?.scoreTraining ?? 0);
      setValue(
        'score.scoreProduct',
        specializeReview?.scoreProduct ??
          (specializeReview?.scoreAnnouncement ?? 0) + (specializeReview?.scoreTraining ?? 0)
      );
      setValue('score.scoreCapability', specializeReview?.scoreCapability ?? 0);
      setValue('score.scoreSubCriteria', specializeReview?.scoreSubCriteria ?? 0);

      // binding expenditure data from specialize-review
      const expenditures = JSON.parse(specializeReview?.reviewExpenditure);
      // eslint-disable-next-line array-callback-return
      expenditures.map((item) => {
        setValue(`expenditure.${item?.expenditureTypeId}.high`, item?.high);
        setValue(`expenditure.${item?.expenditureTypeId}.low`, item?.low);
        setValue(`expenditure.${item?.expenditureTypeId}.suitable`, item?.suitable);
      });
      reset({}, { keepValues: true });
    }
  }, [specializeReviewData, specializeReviewData?.specializeReview, setValue]);

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('formLayout.action.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('formLayout.action.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );
  // #endregion

  return (
    <>
      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields).length}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>
      <TabView activeIndex={tab} onTabChange={(e) => setTab(e.index)}>
        <TabPanel header={t('council.review.createOrUpdate')} leftIcon="pi pi-file mr-2">
          <EditForm
            isLoadingUpdate={isLoadingUpdate}
            isErrorRes={isErrorRes}
            errorRes={errorRes}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            isDirty={isDirty}
            dirtyFields={dirtyFields}
            errors={errors}
            isValid={isValid}
            isVisibleErrorDialog={isVisibleErrorDialog}
            setIsVisibleDialogError={setIsVisibleDialogError}
            specializeReviewData={specializeReviewData}
            isLoadingSpecializeReview={isLoadingSpecializeReview}
            reset={reset}
          />
        </TabPanel>
        <TabPanel header={t('council.review.detailProjectProposal')} leftIcon="pi pi-list mr-2">
          <ProjectProposalDefenseDetail />
        </TabPanel>
      </TabView>
    </>
  );
}
