/* eslint-disable indent */
import './style.scss';

import {
  downloadFile,
  downloadScientificProfileFile,
  downloadZip,
  getAllOrganizations,
  getAllProposedExpenditureTypes,
  getAllVersions,
  getDocumentsById,
  getExpenditureById,
  getGeneralInfoById,
  getResearchResultById,
} from 'apis/projectProposals.api';
import ErrorDialog from 'components/ErrorDialog';
import Loading from 'components/Loading';
import { PROJECT_ROLES, TOAST } from 'constant';
import Documents from 'features/ProjectProposal/components/ReviewForm/Documents';
import GeneralInfo from 'features/ProjectProposal/components/ReviewForm/GeneralInfo';
import ResearchResult from 'features/ProjectProposal/components/ReviewForm/ResearchResult';
import Signature from 'features/ProjectProposal/components/ReviewForm/Signature';
import {
  defaultExpenditureValues,
  defaultGeneralInfoValues,
  defaultResearchResultValues,
} from 'features/ProjectProposalDefense/defaultValues';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  formatFileName,
  getAcadamicLevelShortHand,
  modifyProjectProposalDefenseDocuments,
  modifyProjectProposalDefenseGeneralData,
  toNonAccentVietnamese,
} from 'utils/func';

function ProjectProposalDefenseDetail() {
  const { t } = useTranslation();
  const { projectProposalId } = useParams(); // nho doi sang id

  const { mutate: mutateDownloadFile } = useMutation(downloadFile);
  const { mutate: mutateDownloadScientificProfileFile } = useMutation(
    downloadScientificProfileFile
  );

  const { data: organizationsData } = useQuery(['organizations'], getAllOrganizations, {
    staleTime: 1000 * 60 * 5,
  });

  const organizations = useMemo(
    () =>
      organizationsData?.data.map((e) => ({
        ...e,
        name: t('lang') === 'vi' ? e?.name : e?.englishName,
      })) ?? [],
    [organizationsData, t]
  );

  const { data: allProjectVersionsData } = useQuery(['allProjectVersions', projectProposalId], () =>
    getAllVersions(projectProposalId)
  );
  const latestVersion = useMemo(() => {
    if (allProjectVersionsData?.data?.length > 0) {
      return (
        allProjectVersionsData?.data?.reduce((lastest, current) =>
          current.version > lastest.version ? current : lastest
        ) ?? null
      );
    }
    return null;
  }, [allProjectVersionsData]);

  const {
    data: generalInfoData,
    isLoading: isGeneralInfoLoading,
    isError: isGeneralInfoError,
    error: generalInfoError,
  } = useQuery(
    ['projectProposal-generalInfo', latestVersion?.id],
    () => getGeneralInfoById(latestVersion?.id),
    {
      enabled: !!latestVersion?.id,
    }
  );
  const generalInfo = useMemo(
    () =>
      modifyProjectProposalDefenseGeneralData(generalInfoData?.data) ?? defaultGeneralInfoValues,
    [generalInfoData]
  );

  const {
    data: researchResultData,
    isLoading: isResearchResultLoading,
    isError: isResearchResultError,
    error: researchResultError,
  } = useQuery(
    ['projectProposal-researchResult', latestVersion?.id],
    () => getResearchResultById(latestVersion?.id),
    {
      enabled: !!latestVersion?.id,
    }
  );
  const researchResult = useMemo(
    () => researchResultData?.data ?? defaultResearchResultValues,
    [researchResultData]
  );

  const { data: expenditureTypesData } = useQuery(
    ['projectProposal-expenditure-types'],
    getAllProposedExpenditureTypes
  );

  const {
    data: expenditureData,
    isLoading: isExpenditureLoading,
    isError: isExpenditureError,
    error: expenditureError,
  } = useQuery(
    ['projectProposal-expenditure', latestVersion?.id],
    () => getExpenditureById(latestVersion?.id),
    {
      enabled: !!latestVersion?.id,
    }
  );
  const expenditure = useMemo(() => {
    if (expenditureData?.data && expenditureTypesData?.data) {
      return {
        ...expenditureData.data,
        proposedExpenditureSummaries:
          expenditureData.data.proposedExpenditureSummaries.length === 7
            ? expenditureData.data.proposedExpenditureSummaries
            : expenditureTypesData.data.map((_expenditure, index) => {
                const expenditureSummaries = expenditureData.data.proposedExpenditureSummaries.find(
                  (e) => e.proposedExpenditureTypeId === _expenditure.id
                );
                let id = index;
                let expenditureFromVNUHCM = 0;
                let expenditureFromFunding = 0;
                if (expenditureSummaries) {
                  id = expenditureSummaries.id;
                  expenditureFromVNUHCM = expenditureSummaries.expenditureFromVNUHCM;
                  expenditureFromFunding = expenditureSummaries.expenditureFromFunding;
                }
                return {
                  id,
                  expenditureFromVNUHCM,
                  expenditureFromFunding,
                  proposedExpenditureTypeId: _expenditure.id,
                  proposedExpenditureType: _expenditure,
                };
              }),
      };
    }
    return defaultExpenditureValues;
  }, [expenditureData?.data, expenditureTypesData?.data]);

  const {
    data: documentsData,
    isLoading: isDocumentsLoading,
    isError: isDocumentsError,
    error: documentsError,
  } = useQuery(
    ['projectProposal-documents', latestVersion?.id],
    () => getDocumentsById(latestVersion?.id),
    {
      enabled: !!latestVersion?.id,
    }
  );
  const documents = useMemo(
    () => modifyProjectProposalDefenseDocuments(documentsData?.data) ?? {},
    [documentsData]
  );
  // #endregion Data

  // #region Event
  const handleDownloadScientificProfileFile = (
    contactId,
    contactName = '',
    academicRank = '',
    academicDegree = ''
  ) => {
    if (contactId && contactName) {
      mutateDownloadScientificProfileFile(contactId, {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = `${getAcadamicLevelShortHand(
              academicRank,
              academicDegree,
              t,
              true
            )}${toNonAccentVietnamese(contactName)}.pdf`;

            link.click();
          }
        },
        onError: () => {
          toast(TOAST.ERROR, t('projectProposal.general.a9.downloadScientificProfileErrorTitle'));
        },
      });
    }
  };

  const handleDownloadFile = (path) => {
    mutateDownloadFile(
      {
        id: latestVersion?.id,
        path,
      },
      {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = formatFileName(path);

            link.click();
          }
        },
      }
    );
  };

  const {
    refetch: handleDownloadZip,
    isLoading: isDownloadZipLoading,
    isFetching: isDownloadZipFetching,
  } = useQuery(
    ['downloadProjectProposalZip', latestVersion?.id],
    () => downloadZip(latestVersion?.id),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const owner = generalInfo?.projectProposalContacts.find(
            (e) => PROJECT_ROLES.OWNER === e.projectRole.code
          );
          const academicLevelShorthand = getAcadamicLevelShortHand(
            owner?.academicRank,
            owner?.academicDegree,
            t,
            true
          );
          const filename = `De tai DHQG - ${academicLevelShorthand}${toNonAccentVietnamese(
            owner?.fullname
          )}.zip`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.downloadZipErrorTitle'));
      },
    }
  );

  // #endregion Event

  // #region Render
  if (isGeneralInfoError) {
    return (
      <ErrorDialog
        title={t('projectProposal.generalInfoErrorTitle')}
        isError={isGeneralInfoError}
        error={generalInfoError}
        visible
        isNavigate
      />
    );
  }
  if (isResearchResultError) {
    return (
      <ErrorDialog
        title={t('projectProposal.researchResultErrorTitle')}
        isError={isResearchResultError}
        error={researchResultError}
        visible
        isNavigate
      />
    );
  }
  if (isExpenditureError) {
    return (
      <ErrorDialog
        title={t('projectProposal.expenditureErrorTitle')}
        isError={isExpenditureError}
        error={expenditureError}
        visible
        isNavigate
      />
    );
  }
  if (isDocumentsError) {
    return (
      <ErrorDialog
        title={t('projectProposal.documentsErrorTitle')}
        isError={isDocumentsError}
        error={documentsError}
        visible
        isNavigate
      />
    );
  }

  return (
    <div className="bg-white p-5">
      <div className="flex justify-content-end">
        <Button
          type="button"
          label={t('projectProposal.review.downloadZip')}
          icon={`pi ${
            isDownloadZipLoading || isDownloadZipFetching ? 'pi-spin pi-spinner' : 'pi-download'
          }`}
          className="block w-auto h-3rem"
          severity="info"
          onClick={handleDownloadZip}
          disabled={isDownloadZipLoading || isDownloadZipFetching}
        />
      </div>

      {(isGeneralInfoLoading ||
        isResearchResultLoading ||
        isExpenditureLoading ||
        isDocumentsLoading) && <Loading />}
      <div className="m-0 bg-white p-5 p-fluid detail-info-project-proposal">
        <div className="mb-5 flex justify-content-between align-items-center">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="VNU-HCM logo"
            height="75px"
            className="ml-3"
          />
          <div>
            <table style={{ borderCollapse: 'collapse' }} className="text-black-alpha-90">
              <tbody>
                <tr>
                  <td colSpan="2" className="p-1">
                    {t('projectProposal.viewDetail.form')} R01 -{' '}
                    {t('projectProposal.viewDetail.update')} 2022
                  </td>
                </tr>
                <tr>
                  <td className="border-dotted border-1 p-1 w-10rem">
                    {t('projectProposal.viewDetail.dateOfReceivingFile')}
                  </td>
                  <td className="border-dotted border-1 p-1 w-8rem"> </td>
                </tr>
                <tr>
                  <td className="border-dotted border-1 p-1 w-10rem">
                    {t('projectProposal.viewDetail.projectId')}
                  </td>
                  <td className="border-dotted border-1 p-1 w-8rem"> </td>
                </tr>
                <tr>
                  <td colSpan="2" className="border-dotted border-1 p-1 text-center">
                    <span className="font-italic">
                      ({t('projectProposal.viewDetail.recordedBy')})
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h3 className="mb-5 line-height-3 text-4xl text-center font-bold">
          {t('projectProposal.viewDetail.application')}
          <br />
          <span className="font-normal">{t('projectProposal.viewDetail.sciTechProject')}</span>
        </h3>

        <div className="ml-4">
          <GeneralInfo
            generalInfoData={generalInfo}
            expenditureData={expenditure}
            organizations={organizations}
            handleDownloadFile={handleDownloadFile}
            handleDownloadScientificProfileFile={handleDownloadScientificProfileFile}
          />
          <ResearchResult data={researchResult} />
          <h4>{t('projectProposal.researchDescription.viewLabel')}</h4>
          <h4>
            B1 - B5.{' '}
            {documents?.researchDescriptionFileUrl && (
              <span
                className="p-link text-primary underline font-normal"
                onClick={() => handleDownloadFile(documents?.researchDescriptionFileUrl)}
              >
                {t('projectProposal.researchDescription.attachment')}
              </span>
            )}
          </h4>
          <Documents data={documents} handleDownloadFile={handleDownloadFile} />
        </div>

        <Signature />
      </div>
    </div>
  );
}

export default ProjectProposalDefenseDetail;
