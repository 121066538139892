import instance from 'utils/axios/instance.axios';
import { FINANCIAL_ADJUSTMENT } from './_constant';

export const getFinancialAdjustmentById = (id) => instance.get(FINANCIAL_ADJUSTMENT.GET_BY_ID(id));

export const downloadPdfFinancialAdjustment = (id) =>
  instance.get(FINANCIAL_ADJUSTMENT.DOWNLOAD_PDF_FILE(id), { responseType: 'blob' });

export const downloadDocxFinancialAdjustment = (id) =>
  instance.get(FINANCIAL_ADJUSTMENT.DOWNLOAD_DOCX_FILE(id), { responseType: 'blob' });

export const downloadFile = (id) =>
  instance.get(FINANCIAL_ADJUSTMENT.DOWNLOAD_FILE(id), { responseType: 'blob' });
