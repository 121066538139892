import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import DomesticBooksTable from './DomesticBooksTable';
import DomesticConferencePapersTable from './DomesticConferencePapersTable';
import DomesticPapersTable from './DomesticPapersTable';
import InternationalBooksTable from './InternationalBooksTable';
import InternationalConferencePapersTable from './InternationalConferencePapersTable';
import InternationalPapersTable from './InternationalPapersTable';

export default function ScientificWorksForm({ control, shouldUpdateFromDocx }) {
  // #region Data
  const { t } = useTranslation();

  // #endregion

  // #region Event
  // #endregion
  return (
    <div>
      <h4 className="text-center">{t('scientificProfile.scientificWork.label')}</h4>

      <label>1. {t('scientificProfile.scientificWork.book.label')}</label>
      <p className="underline font-italic mb-2">
        {t('scientificProfile.scientificWork.book.international.label')}
      </p>
      <InternationalBooksTable control={control} shouldUpdateFromDocx={shouldUpdateFromDocx} />
      <p className="underline font-italic mb-2 block">
        {t('scientificProfile.scientificWork.book.domestic.label')}
      </p>
      <DomesticBooksTable control={control} shouldUpdateFromDocx={shouldUpdateFromDocx} />

      <label>2. {t('scientificProfile.scientificWork.paper.label')}</label>
      <p className="underline font-italic mb-2">
        {t('scientificProfile.scientificWork.paper.international.label')}
      </p>
      <InternationalPapersTable control={control} shouldUpdateFromDocx={shouldUpdateFromDocx} />
      <p className="underline font-italic mb-2">
        {t('scientificProfile.scientificWork.paper.domestic.label')}
      </p>
      <DomesticPapersTable control={control} shouldUpdateFromDocx={shouldUpdateFromDocx} />
      <p className="underline font-italic mb-2">
        {t('scientificProfile.scientificWork.paper.internationalConference.label')}
      </p>
      <InternationalConferencePapersTable
        control={control}
        shouldUpdateFromDocx={shouldUpdateFromDocx}
      />
      <p className="underline font-italic mb-2">
        {t('scientificProfile.scientificWork.paper.domesticConference.label')}
      </p>
      <DomesticConferencePapersTable
        control={control}
        shouldUpdateFromDocx={shouldUpdateFromDocx}
      />
    </div>
  );
}

ScientificWorksForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  shouldUpdateFromDocx: PropTypes.bool,
};

ScientificWorksForm.defaultProps = {
  shouldUpdateFromDocx: false,
};
