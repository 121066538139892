import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export default function TableScholarDataDialog({ isOpen, onClose, onConfirm, scholarData }) {
  const [dialogValue, setDialogValue] = useState({});
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    setDialogValue(scholarData);
  }, [scholarData]);

  const handleConfirm = () => {
    // Pass the dialogValue to the parent component
    onConfirm(dialogValue);
    // Close the dialog
    onClose();
  };

  const dialogFooterTemplate = () => (
    <Button label="OK" icon="pi pi-check" onClick={handleConfirm} />
  );

  return (
    <Dialog
      header="Dữ liệu chưa rõ"
      visible={isOpen}
      style={{ width: '75vw' }}
      maximizable
      modal
      contentStyle={{ height: '300px' }}
      onHide={onClose}
      footer={dialogFooterTemplate}
    >
      <DataTable
        value={dialogValue}
        scrollable
        scrollHeight="flex"
        showGridlines
        selectionMode="radiobutton"
        selection={selectedData}
        onSelectionChange={(e) => setSelectedData(e.value)}
        tableStyle={{ minWidth: '100rem' }}
      >
        <Column selectionMode="single" headerStyle={{ width: '3rem' }} />
        <Column field="bib.title" header="Title" />
        <Column field="bib.author" header="Author" />
        <Column field="bib.venue" header="Venue" />
        <Column field="bib.pub_year" header="Year" />
        <Column field="pub_url" header="URL" style={{ width: '20%' }} />
        <Column field="bib.abstract" header="Abstract" />
      </DataTable>
    </Dialog>
  );
}

TableScholarDataDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  scholarData: PropTypes.objectOf.isRequired,
};
