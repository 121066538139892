import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

import ProjectProposalEdit from 'features/ProjectProposal/pages/Edit';

function ProjectProposalEditPage() {
  return (
    <LayoutProvider>
      <Layout
        style={{
          maxHeight: 'calc(100vh - 9rem)',
          overflowY: 'auto',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
          backgroundColor: 'white',
        }}
      >
        <ProjectProposalEdit />
      </Layout>
    </LayoutProvider>
  );
}

export default ProjectProposalEditPage;
