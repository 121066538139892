import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function ResearchAndGuidingActivitiesSection({ data }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion

  // #region Event
  // #endregion
  return (
    <div className="pb-2">
      <h4 className="mb-2">II. {t('scientificProfile.preview.sectionII').toUpperCase()}</h4>

      <div className="text-lg">
        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            1. {t('scientificProfile.researchActivity.project')}:
          </span>
          <div className="form-table mt-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ maxWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 250, width: '25%' }}>
                    {t('scientificProfile.researchActivity.projectName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                    {t('scientificProfile.researchActivity.projectCode')}
                  </th>
                  <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                    {t('scientificProfile.researchActivity.managementLevel')}
                  </th>
                  <th className="text-center" style={{ minWidth: 80, width: '8%' }}>
                    {t('scientificProfile.researchActivity.implementationMonths')}
                  </th>
                  <th className="text-center" style={{ minWidth: 90, width: '9%' }}>
                    {t('scientificProfile.researchActivity.expenditure')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                    {t('scientificProfile.researchActivity.ownOrParticipate')}
                  </th>
                  <th className="text-center" style={{ minWidth: 90, width: '9%' }}>
                    {t('scientificProfile.researchActivity.acceptanceDate')}
                  </th>
                  <th className="text-center" style={{ minWidth: 80, width: '8%' }}>
                    {t('scientificProfile.researchActivity.result')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.researchActivities?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.code}</td>
                    <td>
                      {item?.managementLevel ? t(`managementLevel.${item?.managementLevel}`) : ''}
                    </td>
                    <td>{item?.implementationMonths}</td>
                    <td>{item?.expenditure}</td>
                    <td>{item?.role ? t(`projectRole.${item?.role}`) : ''}</td>
                    <td>
                      {item?.acceptanceDate
                        ? new Date(item?.acceptanceDate).toLocaleDateString('en-GB')
                        : ''}
                    </td>
                    <td>{item?.result ? t(`result.${item?.result}`) : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            2. {t('scientificProfile.guidingActivity.guidingStudent')}:
          </span>
          <div className="form-table mt-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 230, width: '23%' }}>
                    {t('scientificProfile.guidingActivity.studentName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 300, width: '30%' }}>
                    {t('scientificProfile.guidingActivity.thesisName')}
                  </th>
                  <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                    {t('scientificProfile.guidingActivity.graduationYear')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                    {t('scientificProfile.guidingActivity.trainingLevel')}
                  </th>
                  <th className="text-center" style={{ minWidth: 200, width: '20%' }}>
                    {t('scientificProfile.guidingActivity.projectCode')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.guidingActivities?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.studentName}</td>
                    <td>{item?.thesisName}</td>
                    <td>{item?.graduationYear}</td>
                    <td>
                      {item?.academicDegree ? t(`academicDegree.${item?.academicDegree}`) : ''}
                    </td>
                    <td>{item?.projectCode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

ResearchAndGuidingActivitiesSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

ResearchAndGuidingActivitiesSection.defaultProps = {
  data: {},
};
