import ScientificProfile from 'features/ScientificProfile';
import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

function ScientificProfilePage() {
  return (
    <LayoutProvider>
      <Layout
        style={{
          backgroundColor: 'white',
          position: 'relative',
          height: '80vh',
          overflowY: 'auto',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
        }}
      >
        <ScientificProfile />
      </Layout>
    </LayoutProvider>
  );
}

export default ScientificProfilePage;
