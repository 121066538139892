import { NumberInput, TextareaInput } from 'components/FormControl';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function KPITable({ kpiTable, proposedExpenditure, control, errors }) {
  const { t } = useTranslation();

  return (
    <div>
      <h4>{t('projectProposal.researchResult.a10_2.label')}</h4>
      <div className="form-table">
        <table className="mb-2">
          <thead>
            <tr>
              <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                {t('projectProposal.researchResult.a10_2.fields.order')}
              </th>
              <th className="text-center" style={{ minWidth: 300, width: 400 }}>
                {t('projectProposal.researchResult.a10_2.fields.KPI')}
              </th>
              <th className="text-center" style={{ minWidth: 170, width: 170 }}>
                {t('projectProposal.researchResult.a10_2.fields.numberRegistration')}
              </th>
              <th className="text-center" style={{ minWidth: 300, width: '100%' }}>
                {t('projectProposal.researchResult.a10_2.fields.content')}
              </th>
            </tr>
          </thead>
          {kpiTable.map((kpiGroup) => (
            <tbody key={kpiGroup[1].id}>
              {kpiGroup[1].kpis.map((kpi, index) => (
                <tr key={kpi.id}>
                  {index === 0 ? (
                    <td className="text-center font-bold" rowSpan={kpiGroup[1].kpis.length}>
                      {kpiGroup[0]}
                    </td>
                  ) : (
                    <td className="hidden" />
                  )}
                  <td>
                    {t('lang') === 'vi'
                      ? kpi.name
                      : kpi.englishName ||
                        t(`projectProposal.researchResult.a10_2.kpi.${kpi.code}`)}
                  </td>
                  <td>
                    <NumberInput
                      name={`researchResult.projectProposalResearchKpis.${kpi.id}.quantity`}
                      control={control}
                      errors={errors}
                    />
                  </td>
                  <td>
                    <TextareaInput
                      name={`researchResult.projectProposalResearchKpis.${kpi.id}.content`}
                      control={control}
                      errors={errors}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          ))}
          <tfoot>
            <tr>
              <td className="font-bold text-center" colSpan={2}>
                {t('projectProposal.researchResult.a10_2.fields.proposedExpenditure')}
              </td>
              <td className="font-bold text-right" style={{ borderRight: 'none' }}>
                {!!proposedExpenditure && `${proposedExpenditure.toLocaleString('vi')} đ`}
              </td>
              <td className="font-medium text-red-500 font-italic" style={{ borderLeft: 'none' }}>
                {!!proposedExpenditure && '(Tổng kinh phí dự án không được vượt quá số này)'}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

KPITable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  kpiTable: PropTypes.array,
  proposedExpenditure: PropTypes.number,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};

KPITable.defaultProps = {
  kpiTable: [],
  proposedExpenditure: 0,
};
