import 'styles/layout/_catalogue.scss';

import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';
import Notification from 'features/Notification/pages/Viewlist';

function NotificationPage() {
  return (
    <LayoutProvider>
      <Layout
        style={{
          backgroundColor: 'white',
          position: 'relative',
          height: '80vh',
          overflowY: 'auto',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
        }}
      >
        <Notification />
      </Layout>
    </LayoutProvider>

  );
}

export default NotificationPage;
