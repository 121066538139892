import { useEffect, useMemo, useState } from 'react';

import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import ReactRouterPrompt from 'react-router-prompt';

import {
  create,
  createOrUpdateOrganization,
  createOrUpdatePartners,
  getAllProjectRoles,
  getOwner,
  update,
  uploadFiles,
  uploadOtherProofsAndAppendicesFiles,
} from 'apis/projectProposals.api';

import Loading from 'components/Loading';
import Form from 'features/ProjectProposal/components/Form';
import formDefaultValues from 'features/ProjectProposal/defaultValues';

import { yupResolver } from '@hookform/resolvers/yup';
import createProjectProposalValidationSchema from 'features/ProjectProposal/validation';

import { PROJECT_ROLES, TOAST } from 'constant';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import {
  mapError,
  modifyProjectProposalExpenditureUpdateData,
  modifyProjectProposalGeneralCreateData,
  modifyProjectProposalGeneralUpdateData,
  modifyProjectProposalPartnersFormData,
  modifyProjectProposalResearchDescriptionProofsFormData,
  modifyProjectProposalResearchResultUpdateData,
} from 'utils/func';

const formOptions = { resolver: yupResolver(createProjectProposalValidationSchema) };

export default function ProjectProposalCreate() {
  // #region Data
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);
  const [isCreated, setIsCreated] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [isNextTab, setIsNextTab] = useState(false);

  const { data: ownerData } = useQuery(['projectProposalOwner'], getOwner);
  const owner = useMemo(() => ownerData?.data ?? null, [ownerData]);

  const { control, handleSubmit, trigger, reset, watch, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: formDefaultValues,
    ...formOptions,
    shouldFocusError: true,
  });
  const { errors, isDirty, dirtyFields, isValid } = useFormState({
    control,
  });

  const {
    mutate: createMutate,
    isLoading: isCreateLoading,
    isError: isCreateError,
    error: createError,
  } = useMutation(create);

  const {
    mutate: updateMutate,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
  } = useMutation(update);

  const {
    mutate: mutateUploadFiles,
    isLoading: isUploadFilesLoading,
    isError: isUploadFilesError,
    error: uploadFilesError,
  } = useMutation(uploadFiles);

  const {
    mutate: mutateCreateOrUpdateOrganization,
    isLoading: isCreateOrUpdateOrganizationLoading,
    isError: isCreateOrUpdateOrganizationError,
    error: createOrUpdateOrganizationError,
  } = useMutation(createOrUpdateOrganization);

  const {
    mutate: mutateCreateOrUpdatePartners,
    isLoading: isCreateOrUpdatePartnersLoading,
    isError: isCreateOrUpdatePartnersError,
    error: createOrUpdatePartnersError,
  } = useMutation(createOrUpdatePartners);

  const {
    mutate: mutateUploadOtherProofsAndAppendicesFiles,
    isLoading: isUploadOtherProofsAndAppendicesFilesLoading,
    isError: isUploadOtherProofsAndAppendicesFilesError,
    error: uploadOtherProofsAndAppendicesFilesError,
  } = useMutation(uploadOtherProofsAndAppendicesFiles);

  const items = [
    {
      label: t('projectProposal.general.label'),
    },
    {
      label: t('projectProposal.researchResult.label'),
    },
    {
      label: t('projectProposal.expenditure.label'),
    },
    {
      label: t('projectProposal.researchDescription.label'),
    },
    {
      label: t('projectProposal.review.label'),
    },
  ];

  const { data: projectRolesData } = useQuery(['projectProposalProjectRoles'], getAllProjectRoles);
  const ownerProjectRole = useMemo(
    () => projectRolesData?.data?.find((role) => role.code === PROJECT_ROLES.OWNER) ?? null,
    [projectRolesData]
  );
  // #endregion Data

  // #region Event
  // Bind owner into form
  useEffect(() => {
    if (owner) {
      const fetchedOwner = {
        id: owner?.id ?? '', // contact id
        fullname: owner?.fullname ?? '',
        dob: owner?.scientificProfile?.dob ? new Date(owner?.scientificProfile?.dob) : null,
        gender: owner?.scientificProfile?.gender ?? '',
        citizenId: owner?.scientificProfile?.citizenId ?? '',
        dateOfIssue: owner?.scientificProfile?.dateOfIssue
          ? new Date(owner?.scientificProfile?.dateOfIssue)
          : null,
        placeOfIssue: owner?.scientificProfile?.placeOfIssue ?? '',
        taxIdentificationNumber: owner?.scientificProfile?.taxIdentificationNumber ?? '',
        academicDegree: owner?.scientificProfile?.academicDegree ?? '',
        academicRank: owner?.scientificProfile?.academicRank ?? '',
        bank: owner?.scientificProfile?.bank ?? '',
        bankAccount: owner?.scientificProfile?.bankAccount ?? '',
        workplace:
          owner?.organization?.englishName === 'Other'
            ? owner?.specificOrganizationName
            : owner?.organization?.name ?? '',
        mobile: owner?.scientificProfile?.personalPhone ?? '',
        email: owner?.user?.email ?? owner?.scientificProfile?.personalEmail ?? '',
        contact: { ...owner },
        organizationId: owner?.organizationId ?? '',
        organization: owner?.organization,
        specificOrganizationName:
          owner?.organization?.englishName === 'Other' ? owner?.specificOrganizationName : null,
        researchLines: owner?.scientificProfile?.researchLines ?? [],
        projectRole: ownerProjectRole,
        projectRoleId: ownerProjectRole?.id,
      };

      setValue('general.owner', fetchedOwner);
      setValue('general.owner.researchLines', fetchedOwner.researchLines);
      setValue('general.owner.scientificProfileUrl', owner?.user?.scientificProfileUrl);
    }
  }, [ownerData, ownerProjectRole]);

  // eslint-disable-next-line no-unused-vars
  const createOrUpdatePartnersSequentially = (projectProposalId, partners, currentIndex) => {
    // all partners have been created/updated successfully
    if (currentIndex >= partners.length) {
      return;
    }

    const partner = partners[currentIndex];

    mutateCreateOrUpdateOrganization(
      {
        projectProposalId,
        data: partner,
      },
      {
        onSuccess: (partnerRes) => {
          if (partnerRes?.data?.partnerConfirmationUrl) {
            setValue(
              `general.partners.${currentIndex}.partnerConfirmationUrl`,
              partnerRes?.data?.partnerConfirmationUrl
            );
            setValue(`general.partners.${currentIndex}.file`, 'fileUploaded');
          }
          // move to the next partner
          createOrUpdatePartnersSequentially(projectProposalId, partners, currentIndex + 1);
        },
        onError: () => {
          setErrorDialogVisible(true);
        },
      }
    );
  };

  const onSubmit = async (data) => {
    if (!(isDirty && Object.keys(dirtyFields).length) && currentTab !== 3) {
      if (isNextTab) {
        setCurrentTab((cur) => cur + 1);
        setIsNextTab(false);
      }
      return;
    }

    // Check fundingExpenditureOther more than fundingExpenditure
    const fundingExpenditureOther =
      getValues('general.researchExpense.fundingExpenditureOther') ?? 0;
    const fundingExpenditure = getValues('general.researchExpense.fundingExpenditure') ?? 0;

    if (fundingExpenditureOther > fundingExpenditure && fundingExpenditureOther !== 0) {
      toast(TOAST.ERROR, t('projectProposal.fundingOtherMustSmallerThanFundingTotalMessage'));
      return;
    }

    if (!isCreated) {
      // Project is not created => create new
      const newData = modifyProjectProposalGeneralCreateData(data);

      createMutate(newData, {
        onSuccess: (res) => {
          if (data?.researchDescription?.file || data?.expenditure?.file) {
            const formData = new FormData();
            if (data?.researchDescription?.file) {
              formData.append('fileB', data?.researchDescription.file);
            }
            if (data?.expenditure?.file) {
              formData.append('fileC', data?.expenditure.file);
            }

            mutateUploadFiles(
              {
                projectProposalId: res.data?.id,
                files: formData,
              },
              {
                onSuccess: (fileRes) => {
                  if (fileRes?.data?.researchDescriptionFileUrl) {
                    setValue(
                      'researchDescription.uploadedFileUrl',
                      fileRes?.data?.researchDescriptionFileUrl
                    );
                    setValue('researchDescription.file', null);
                  }
                  if (fileRes?.data?.expenditureFileUrl) {
                    setValue('expenditure.uploadedFileUrl', fileRes?.data?.expenditureFileUrl);
                    setValue('expenditure.file', null);
                  }
                },
                onError: () => {
                  setErrorDialogVisible(true);
                },
              }
            );
          }

          if (data?.general?.partners) {
            const projectProposalPartners = modifyProjectProposalPartnersFormData(
              data.general.partners
            );

            mutateCreateOrUpdatePartners(
              {
                projectProposalId: res?.data?.id,
                data: projectProposalPartners,
              },
              {
                onSuccess: (partnersRes) => {
                  if (partnersRes?.data) {
                    const partnerInfos = partnersRes?.data?.map((item) => ({
                      id: item?.id,
                      file: null,
                      specificName: item?.name,
                      organizationId: item?.organizationId,
                      name: item?.name,
                      englishName: item?.englishName,
                      chief: item?.chief,
                      fax: item?.fax,
                      phone: item?.phone,
                      email: item?.email,
                      address: item?.address,
                      bankAccount: item?.bankAccount,
                      bank: item?.bank,
                      partnerContribution: item?.partnerContribution,
                      type: item?.type,
                      partnerConfirmationUrl: item?.partnerConfirmationUrl,
                    }));
                    setValue('general.partners', partnerInfos);
                  }
                },
                onError: () => {
                  setErrorDialogVisible(true);
                },
              }
            );
          }

          if (data?.researchDescription?.otherProofsAndAppendicesFiles?.length > 0) {
            const otherProofsAndAppendicesFiles =
              data?.researchDescription?.otherProofsAndAppendicesFiles;
            const formData = modifyProjectProposalResearchDescriptionProofsFormData(
              otherProofsAndAppendicesFiles
            );

            mutateUploadOtherProofsAndAppendicesFiles(
              {
                projectProposalId: res?.data?.id,
                data: formData,
              },
              {
                onSuccess: (proofFileRes) => {
                  if (proofFileRes?.data) {
                    const proofFileInfos = proofFileRes?.data?.map((item) => ({
                      id: item?.id,
                      fileName: item?.fileName,
                      file: null,
                    }));
                    setValue('researchDescription.otherProofsAndAppendicesFiles', proofFileInfos);
                  }
                },
                onError: () => {
                  setErrorDialogVisible(true);
                },
              }
            );
          }

          reset({
            ...data,
            general: {
              ...data.general,
              owner: {
                ...data.general.owner,
                dob: data?.general?.owner?.dob ? new Date(data?.general?.owner?.dob) : null,
                dateOfIssue: data?.general?.owner?.dateOfIssue
                  ? new Date(data?.general?.owner?.dateOfIssue)
                  : null,
              },
            },
            expenditure: {
              ...data?.expenditure,
              file: data?.expenditure?.file?.name,
            },
            researchDescription: {
              ...data?.researchDescription,
              file: data?.researchDescription?.file?.name,
            },
          });
          if (currentTab === 4) {
            toast(TOAST.SUCCESS, t('projectProposal.saveSuccessMessage'));
            return;
          }
          if (isNextTab) {
            setCurrentTab((cur) => cur + 1);
            setIsNextTab(false);
          } else toast(TOAST.SUCCESS, t('projectProposal.saveSuccessMessage'));
          setValue('id', res.data.id);
          setIsCreated(true);
        },
        onError: () => {
          setErrorDialogVisible(true);
        },
      });
    } else {
      // Project is created => update it
      const updatedGeneral = modifyProjectProposalGeneralUpdateData(data);
      const updatedResearchResult = modifyProjectProposalResearchResultUpdateData(data);
      const updatedExpenditure = modifyProjectProposalExpenditureUpdateData(data);
      const newUpdateData = { ...updatedGeneral, ...updatedResearchResult, ...updatedExpenditure };

      updateMutate(
        { id: getValues('id'), data: newUpdateData },
        {
          onSuccess: () => {
            if (dirtyFields?.expenditure?.file || dirtyFields?.researchDescription?.file) {
              const formData = new FormData();
              if (data?.researchDescription?.file) {
                formData.append('fileB', data?.researchDescription.file);
              }
              if (data?.expenditure?.file) {
                formData.append('fileC', data?.expenditure.file);
              }
              mutateUploadFiles(
                {
                  projectProposalId: getValues('id'),
                  files: formData,
                },
                {
                  onSuccess: (fileRes) => {
                    if (fileRes?.data?.researchDescriptionFileUrl) {
                      setValue(
                        'researchDescription.uploadedFileUrl',
                        fileRes?.data?.researchDescriptionFileUrl
                      );
                      setValue('researchDescription.file', null);
                    }
                    if (fileRes?.data?.expenditureFileUrl) {
                      setValue('expenditure.uploadedFileUrl', fileRes?.data?.expenditureFileUrl);
                    }
                    setValue('researchDescription.file', null);
                  },
                  onError: () => {
                    setErrorDialogVisible(true);
                  },
                }
              );
            }

            if (dirtyFields?.general?.partners) {
              const projectProposalPartners = modifyProjectProposalPartnersFormData(
                data.general.partners
              );

              mutateCreateOrUpdatePartners(
                {
                  projectProposalId: getValues('id'),
                  data: projectProposalPartners,
                },
                {
                  onSuccess: (partnersRes) => {
                    if (partnersRes?.data) {
                      const partnerInfos = partnersRes?.data?.map((item) => ({
                        id: item?.id,
                        file: null,
                        specificName: item?.name,
                        organizationId: item?.organizationId,
                        name: item?.name,
                        englishName: item?.englishName,
                        chief: item?.chief,
                        fax: item?.fax,
                        phone: item?.phone,
                        email: item?.email,
                        address: item?.address,
                        bankAccount: item?.bankAccount,
                        bank: item?.bank,
                        partnerContribution: item?.partnerContribution,
                        type: item?.type,
                        partnerConfirmationUrl: item?.partnerConfirmationUrl,
                      }));
                      setValue('general.partners', partnerInfos);
                    }
                  },
                  onError: () => {
                    setErrorDialogVisible(true);
                  },
                }
              );
            }

            if (dirtyFields?.researchDescription?.otherProofsAndAppendicesFiles) {
              const otherProofsAndAppendicesFiles =
                data?.researchDescription?.otherProofsAndAppendicesFiles;
              const formData = modifyProjectProposalResearchDescriptionProofsFormData(
                otherProofsAndAppendicesFiles
              );

              mutateUploadOtherProofsAndAppendicesFiles(
                {
                  projectProposalId: getValues('id'),
                  data: formData,
                },
                {
                  onSuccess: (proofFileRes) => {
                    if (proofFileRes?.data) {
                      const proofFileInfos = proofFileRes?.data?.map((item) => ({
                        id: item?.id,
                        fileName: item?.fileName,
                        file: null,
                      }));
                      setValue('researchDescription.otherProofsAndAppendicesFiles', proofFileInfos);
                    }
                  },
                  onError: () => {
                    setErrorDialogVisible(true);
                  },
                }
              );
            }

            reset({
              ...data,
              general: {
                ...data.general,
                owner: {
                  ...data.general.owner,
                  dob: data?.general?.owner?.dob ? new Date(data?.general?.owner?.dob) : null,
                  dateOfIssue: data?.general?.owner?.dateOfIssue
                    ? new Date(data?.general?.owner?.dateOfIssue)
                    : null,
                },
              },
              expenditure: {
                ...data?.expenditure,
                file: data?.expenditure?.file?.name,
              },
              researchDescription: {
                ...data?.researchDescription,
                file: data?.researchDescription?.file?.name,
              },
            });
            if (currentTab === 4) {
              toast(TOAST.SUCCESS, t('projectProposal.saveSuccessMessage'));
              return;
            }
            if (isNextTab) {
              setCurrentTab((cur) => cur + 1);
              setIsNextTab(false);
            } else toast(TOAST.SUCCESS, t('projectProposal.saveSuccessMessage'));
          },
          onError: () => {
            setErrorDialogVisible(true);
          },
        }
      );
    }
  };

  const nextTab = async () => {
    if (currentTab === 0) {
      const isGeneralInfoValid = await trigger('general', { shouldFocus: true });
      if (isGeneralInfoValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 1) {
      const isResearchResultValid = await trigger('researchResult', { shouldFocus: true });
      if (isResearchResultValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 2) {
      const isExpenditureValid = await trigger('expenditure', { shouldFocus: true });
      if (isExpenditureValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 3) {
      const isResearchDescriptionValid = await trigger('researchDescription', {
        shouldFocus: true,
      });
      if (isResearchDescriptionValid) {
        setIsNextTab(true);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else {
      setCurrentTab((cur) => cur + 1);
    }
  };

  const handleTabChange = async (e) => {
    // Can not use switch because of eslint error (conflict Prettier)
    if (currentTab === 0) {
      const isGeneralInfoValid = await trigger('general', { shouldFocus: true });
      if (isGeneralInfoValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 1) {
      const isResearchResultValid = await trigger('researchResult', { shouldFocus: true });
      if (isResearchResultValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 2) {
      const isExpenditureValid = await trigger('expenditure', { shouldFocus: true });
      if (isExpenditureValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else if (currentTab === 3) {
      const isResearchDescriptionValid = await trigger('researchDescription', {
        shouldFocus: true,
      });
      if (isResearchDescriptionValid) {
        setCurrentTab(e.index);
      } else {
        toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      }
    } else {
      setCurrentTab(e.index);
    }
  };

  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
    }
  };
  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };
  // #endregion Event
  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );

  return (
    <>
      {/* #region Loading */}
      <div className="sticky top-0 left-0 z-5">
        <div className="relative">
          {(isCreateLoading ||
            isUpdateLoading ||
            isUploadFilesLoading ||
            isCreateOrUpdateOrganizationLoading ||
            isCreateOrUpdatePartnersLoading ||
            isUploadOtherProofsAndAppendicesFilesLoading) && <Loading />}
        </div>
      </div>

      <Steps model={items} activeIndex={currentTab} onSelect={handleTabChange} readOnly={false} />

      <Form
        control={control}
        errors={errors}
        disabledSave={!(isDirty && Object.keys(dirtyFields).length)}
        watch={watch}
        setValue={setValue}
        getValues={getValues}
        handleSubmit={handleSubmit(onSubmit)}
        tab={currentTab}
        nextTab={nextTab}
        checkValidation={checkValidation}
        checkKeyDown={checkKeyDown}
        isDirty={isDirty}
        dirtyFields={dirtyFields}
        footerConfirmationPrompt={footerConfirmationPrompt}
      />

      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields).length && currentTab !== 4}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>

      {isCreateError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.createErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(createError.response.data.message) ? (
            createError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, createError)}</p>
          )}
        </Dialog>
      )}

      {isUpdateError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.createErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(updateError.response.data.message) ? (
            updateError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, updateError)}</p>
          )}
        </Dialog>
      )}

      {isUploadFilesError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.createErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(uploadFilesError.response.data.message) ? (
            uploadFilesError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, uploadFilesError)}</p>
          )}
        </Dialog>
      )}

      {isCreateOrUpdateOrganizationError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.createErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(createOrUpdateOrganizationError.response.data.message) ? (
            createOrUpdateOrganizationError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">
              {mapError(t, createOrUpdateOrganizationError)}
            </p>
          )}
        </Dialog>
      )}

      {isCreateOrUpdatePartnersError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.createErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(createOrUpdatePartnersError.response.data.message) ? (
            createOrUpdatePartnersError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, createOrUpdatePartnersError)}</p>
          )}
        </Dialog>
      )}

      {isUploadOtherProofsAndAppendicesFilesError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.createErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(uploadOtherProofsAndAppendicesFilesError.response.data.message) ? (
            uploadOtherProofsAndAppendicesFilesError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">
              {mapError(t, uploadOtherProofsAndAppendicesFilesError)}
            </p>
          )}
        </Dialog>
      )}
    </>
  );
}
