/* eslint-disable no-nested-ternary */

import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { downloadFile, getAllVersions, getReviseLogs } from 'apis/projectProposals.api';

import CompleteExplanation from 'features/ProjectProposal/components/CompleteExplanation';
import ViewDetailOwnerExplanation from 'features/ProjectProposal/components/ViewDetailOwnerExplanation';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';

import {
  checkOverdueDatetime,
  checkPermissionEditProjectProposal,
  formatFileName,
  getMaxDate,
  mapError,
  modifyProjectProposalReviseLogReviewFiles,
  toCamelCaseString,
} from 'utils/func';

import { downloadPdfFile as downloadPdfSpecializeReview } from 'apis/specializeReviews.api';
import { downloadPdfFile as downloadPdfFinancialReview } from 'apis/financialReviews.api';
import { toast } from 'layout';
import { COUNCIL_TYPE_CODE, TIMEZONE_VN, TOAST } from 'constant';
import Loading from 'components/Loading';
import { Editor } from 'primereact/editor';

const versionMap = {
  REQUEST_UPDATE: 1,
  REQUEST_EXPLANATION_PREQUALIFICATION: 2,
  REQUEST_EXPLANATION_SPECIALIZATION: 3,
  REQUEST_EXPLANATION_FINANCE: 4,
};

const councilTypeCodeMap = {
  REQUEST_EXPLANATION_PREQUALIFICATION: 'preliminarySelectionCouncil',
  REQUEST_EXPLANATION_SPECIALIZATION: 'specializationCouncil',
  REQUEST_EXPLANATION_FINANCE: 'financeCouncil',
};

const reviseLogTypeCodesNotShowCouncilInfo = ['REQUEST_UPDATE'];

export default function ProjectProposalRequest() {
  // #region Data
  const { t } = useTranslation();
  const { id } = useParams();
  const dialogCompleteExplanationRef = useRef();
  const [selectedReviseLog, setSelectedReviseLog] = useState(null);

  const {
    data: requestsData,
    isLoading: isRequestsLoading,
    isError: isRequestsError,
    error: requestsError,
    refetch: refetchRequests,
  } = useQuery(
    ['projectProposalReviseLogs', id, 'ALL_OPEN'],
    () =>
      getReviseLogs({
        projectProposalId: id,
        types: [
          'REQUEST_UPDATE',
          'REQUEST_EXPLANATION_PREQUALIFICATION',
          'REQUEST_EXPLANATION_SPECIALIZATION',
          'REQUEST_EXPLANATION_FINANCE',
          'REQUEST_EXPLANATION_MIDTERM_REPORT',
          'REQUEST_EXPLANATION_ACCEPTANCE_REPORT',
        ],
        // isOpened: true,
      }),
    { enabled: !!id }
  );

  const {
    projectProposalReviseLogs,
    projectProposal,
    projectProposalCouncilMembers,
    projectProposalCouncils,
  } = useMemo(() => {
    if (requestsData?.data) {
      return {
        ...requestsData.data,
        projectProposalReviseLogs: requestsData.data?.projectProposalReviseLogs?.filter(
          (item) => item?.isOpened || item?.explanationTime || item?.type === 'REQUEST_UPDATE'
        ),
      };
    }
    return {};
  }, [requestsData]);

  const {
    data: projectVersionsData,
    isLoading: isProjectVersionsLoading,
    isError: isProjectVersionsError,
    error: projectVersionsError,
    refetch: refetchProjectVersions,
  } = useQuery(['allProjectProposalVersions', id], () => getAllVersions(id), {
    enabled: !!id,
  });

  const projectVersions = useMemo(() => projectVersionsData?.data ?? [], [projectVersionsData]);

  const { mutate: mutateDownloadFile } = useMutation(downloadFile);
  const { mutate: mutateDownloadPdfSpecializeReview } = useMutation(downloadPdfSpecializeReview);
  const { mutate: mutateDownloadPdfFinancialReview } = useMutation(downloadPdfFinancialReview);
  // #endregion Data

  // #region Event
  const handleDownloadFile = (path) => {
    mutateDownloadFile(
      {
        id,
        path,
      },
      {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = formatFileName(path);

            link.click();
          }
        },
      }
    );
  };

  const handleDownloadPdfReview = (
    _requestExplanationType,
    _projectProposalId,
    _councilId,
    _councilMemberId,
    _reviewer
  ) => {
    if (_requestExplanationType === 'REQUEST_EXPLANATION_SPECIALIZATION') {
      mutateDownloadPdfSpecializeReview(
        {
          projectProposalId: _projectProposalId,
          councilId: _councilId,
          councilMemberId: _councilMemberId,
          isReviewerHidden: true,
        },
        {
          onSuccess: (res) => {
            if (res?.data) {
              const file = new Blob([res.data]);

              const filename = `Phieu nhan xet danh gia tham dinh chuyen mon ${_reviewer}.pdf`;

              const link = document.createElement('a');
              link.href = URL.createObjectURL(file);
              link.download = filename;
              link.click();
            }
          },
          onError: () => {
            toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
          },
        }
      );
    }
    if (_requestExplanationType === 'REQUEST_EXPLANATION_FINANCE') {
      mutateDownloadPdfFinancialReview(
        {
          projectProposalId: _projectProposalId,
          councilId: _councilId,
          councilMemberId: _councilMemberId,
          isReviewerHidden: true,
        },
        {
          onSuccess: (res) => {
            if (res?.data) {
              const file = new Blob([res.data]);

              const filename = `Phieu nhan xet danh gia tham dinh kinh phi ${_reviewer}.pdf`;

              const link = document.createElement('a');
              link.href = URL.createObjectURL(file);
              link.download = filename;
              link.click();
            }
          },
          onError: () => {
            toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
          },
        }
      );
    }
  };

  // #region Render
  const formatHeader = (value, rowIndex) => {
    const requestType = value?.type
      ? t(`projectProposal.viewHistory.status.${toCamelCaseString(value.type)}`)
      : null;

    const correspondingProjectVersion = projectVersions?.find(
      (item) => item?.version === versionMap[value?.type]
    );

    let deadline = value?.deadline;
    if (value?.type === 'REQUEST_UPDATE') {
      deadline = getMaxDate(
        correspondingProjectVersion?.organizationPrequalificationDeadline,
        correspondingProjectVersion?.program?.organizationPrequalificationDeadline
      );
    }
    if (value?.type === 'REQUEST_EXPLANATION_PREQUALIFICATION') {
      deadline = getMaxDate(
        value?.deadline,
        correspondingProjectVersion?.program?.staffPrequalificationDeadline
      );
    }

    // const lastFeedback = (value?.feedbacks ?? [])?.at(-1);

    let statusJsx = null;
    if (!value?.isOpened && (value?.explanationTime || value?.type === 'REQUEST_UPDATE')) {
      statusJsx = (
        <span className="font-medium text-green-500 text-base font-italic">
          {t('projectProposal.requestExplanation.ownerAddedExplanation')}
        </span>
      );
    } else if (value?.isOpened && checkOverdueDatetime(deadline)) {
      statusJsx = (
        <span className="font-medium text-red-500 text-base font-italic">
          {t('projectProposal.requestExplanation.overdueDeadline')}
        </span>
      );
    } else {
      statusJsx = (
        <span className="font-medium text-red-500 text-base font-italic">
          {t('projectProposal.requestExplanation.deadline')}:{' '}
          {deadline
            ? new Date(deadline).toLocaleString('en-GB', { timeZone: TIMEZONE_VN })
            : t('projectProposal.requestExplanation.noDeadline')}
        </span>
      );
    }

    return (
      <div className="flex flex-column text-lg font-bold gap-1">
        <span>
          {rowIndex + 1}. {requestType}
        </span>
        <div>
          {statusJsx}{' '}
          {!value?.isOpened && value?.explanationTime && (
            <span
              className="text-blue-500 underline font-italic font-normal text-base text-left"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedReviseLog(value);
              }}
            >
              ({t('projectProposal.requestExplanation.viewDetailOwnerExplanation')})
            </span>
          )}
        </div>
      </div>
    );
  };
  const formatConclusion = (value, ppCouncil) => (
    <div className="mb-2">
      <div className="font-semibold mb-2">
        {t(
          `council.view.${
            ppCouncil?.councilType?.code === COUNCIL_TYPE_CODE.FINANCE_COUNCIL
              ? 'financeCouncilResultAndInstructionToCompleteDocuments'
              : 'conclusion'
          }`
        )}
        :
      </div>
      {ppCouncil?.conclusion && (
        <Editor value={ppCouncil?.conclusion} showHeader={false} readOnly />
      )}
    </div>
  );

  const formatComment = (value) => (
    <div className="mb-2">
      <span className="font-semibold">{t('projectProposal.requestExplanation.comment')}:</span>
      <p className="my-2 text-justify" style={{ whiteSpace: 'pre-wrap' }}>
        {value?.comment}
      </p>
    </div>
  );

  const formatCommentFileUrls = (value) => (
    <div className="mb-2">
      <span className="mr-2 font-semibold">
        {t('projectProposal.requestExplanation.commentFile')}:
      </span>
      {value?.commentFileUrls?.length > 0 && (
        <ul className="list-disc">
          {value?.commentFileUrls?.map((item, index) => (
            <li className="-ml-4" key={item?.id ?? index}>
              <span
                className="p-button p-button-text p-button-sm p-0"
                onClick={() => handleDownloadFile(item?.name)}
              >
                <span className="text-blue-500 underline font-italic text-base">
                  {formatFileName(item?.name)}
                </span>
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  const formatFeedbacks = (value) => {
    const newFeedbacks = (value?.feedbacks ?? [])?.filter(
      (item) => new Date(item?.createdAt) > new Date(value?.explanationTime)
    );

    const newFeedbacksJSX =
      newFeedbacks?.length > 1 ? (
        <ul className="list-disc">
          {newFeedbacks?.map((item, index) => (
            <li key={item?.id ?? index} className="-ml-4" style={{ whiteSpace: 'pre-wrap' }}>
              <span>{item?.content}</span>
            </li>
          ))}
        </ul>
      ) : newFeedbacks?.length === 1 ? (
        <p className="my-2 text-justify" style={{ whiteSpace: 'pre-wrap' }}>
          {newFeedbacks?.[0]?.content}
        </p>
      ) : null;

    return (
      <div className="mb-2">
        <span className="mr-2 font-semibold">
          {t('projectProposal.requestExplanation.feedback')}:
        </span>
        {newFeedbacksJSX}
      </div>
    );
  };

  const formatReportFile = (value, ppCouncil) => {
    const reportFile = ppCouncil?.reportFileUrl;

    return (
      <div className="mb-2">
        <span className="mr-2 font-semibold">
          {t('projectProposal.requestExplanation.reportFile')}:
        </span>
        {reportFile && (
          <div>
            <span
              className="p-button p-button-text p-button-sm px-0 text-blue-500 underline font-italic text-base text-left"
              onClick={() => handleDownloadFile(reportFile)}
            >
              {formatFileName(reportFile)}
            </span>
          </div>
        )}
      </div>
    );
  };

  const formatReviewFiles = (value) => {
    const reviewFiles = modifyProjectProposalReviseLogReviewFiles(
      value,
      projectProposalCouncilMembers,
      projectVersions
    );

    return (
      reviewFiles && (
        <div className="mb-2">
          <span className="mr-2 font-semibold">
            {t('projectProposal.requestExplanation.reviewFile')}:
          </span>
          {reviewFiles?.length > 0 && (
            <ul className="list-disc">
              {reviewFiles?.map((item) => (
                <li className="-ml-4" key={item?.key}>
                  <span
                    className="p-button p-button-text p-button-sm p-0"
                    onClick={() => {
                      handleDownloadPdfReview(
                        value?.type,
                        item?.projectProposalId,
                        item?.councilId,
                        item?.councilMemberId,
                        item?.reviewer
                      );
                    }}
                  >
                    <span className="text-blue-500 underline font-italic text-base">
                      {item?.reviewFileName}
                    </span>
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      )
    );
  };

  const formatActions = (value, ppCouncil) => {
    const correspondingProjectVersion = projectVersions?.find(
      (item) => item?.version === versionMap[value?.type]
    );

    let deadline = value?.deadline;
    if (value?.type === 'REQUEST_UPDATE') {
      deadline = getMaxDate(
        correspondingProjectVersion?.organizationPrequalificationDeadline,
        correspondingProjectVersion?.program?.organizationPrequalificationDeadline
      );
    }
    if (value?.type === 'REQUEST_EXPLANATION_PREQUALIFICATION') {
      deadline = getMaxDate(
        value?.deadline,
        correspondingProjectVersion?.program?.staffPrequalificationDeadline
      );
    }

    return (
      <div className="flex justify-content-end gap-1">
        {/* eslint-disable indent */}
        {checkPermissionEditProjectProposal(correspondingProjectVersion, value) && (
          <div className="border-round-lg" style={{ backgroundColor: '#F59E0B' }}>
            <Link
              className="edit-project-proposal flex align-items-center justify-content-center font-bold text-white border-round m-2 h-2rem"
              to={`/project-proposal/edit/${correspondingProjectVersion?.id}?reviseLogId=${value?.id}`}
            >
              {t(
                `projectProposal.completeExplanationRequest.${
                  ppCouncil?.councilType?.code === COUNCIL_TYPE_CODE.FINANCE_COUNCIL
                    ? 'completeDocument'
                    : 'editProjectProposal'
                }`
              )}
            </Link>
          </div>
        )}

        {value?.type !== 'REQUEST_UPDATE' && value?.isOpened && !checkOverdueDatetime(deadline) && (
          <Button
            onClick={() => {
              dialogCompleteExplanationRef.current.open({ value });
            }}
            className="font-bold w-auto"
          >
            {t('projectProposal.completeExplanationRequest.explain')}
          </Button>
        )}
      </div>
    );
  };

  // #endregion Render

  if (isRequestsError) {
    return <h4 className="text-red-500">{mapError(t, requestsError)}</h4>;
  }
  if (isProjectVersionsError) {
    return <h4 className="text-red-500">{mapError(t, projectVersionsError)}</h4>;
  }

  return (
    <div className="grid">
      {(isProjectVersionsLoading || isRequestsLoading) && <Loading />}

      <div className="col-12">
        <div className="card">
          <div className="flex justify-content-between align-items-end mb-1 pb-2">
            <h5 className="p-0 m-0">
              {t('projectProposal.requestExplanation.updateInfoRequests')}
            </h5>
          </div>
          <div className="mb-2">
            <span className="p-0 m-0 text-blue-500 font-bold text-lg">
              {t('projectProposal.label')}:{' '}
            </span>
            <span className="text-blue-500 font-bold text-lg">
              {projectProposal?.vietnameseName}
            </span>
          </div>
          <div className="my-4 border-double p-2">
            <p className="my-1">
              <b>{t('explanationGuideline.title')}:</b>
            </p>
            <p>
              <b className="underline">{t('guideline.step')} 1:</b>{' '}
              {t('explanationGuideline.step1.1')}
            </p>
            <p>
              <b className="underline">{t('guideline.step')} 2:</b>{' '}
              {t('explanationGuideline.step2.1')}{' '}
              <b className="text-orange-400">{t('explanationGuideline.step2.2')}</b>{' '}
              {t('explanationGuideline.step2.3')}{' '}
              <b>&quot;{t('explanationGuideline.step2.4')}&quot;</b>{' '}
              {t('explanationGuideline.step2.5')}
              <b> &quot;{t('explanationGuideline.step2.6')}&quot;</b>.
              <p className="my-1">
                <i>
                  {t('explanationGuideline.step2.7')}{' '}
                  <b className="text-orange-400">{t('explanationGuideline.step2.8')}</b>
                  {t('explanationGuideline.step2.9')}.
                </i>
              </p>
            </p>
            <p>
              <b className="underline">{t('guideline.step')} 3:</b>{' '}
              {t('explanationGuideline.step3.1')}{' '}
              <b className="text-indigo-500">{t('explanationGuideline.step3.2')}</b>{' '}
              {t('explanationGuideline.step3.3')}.
            </p>
          </div>

          {projectProposalReviseLogs?.length > 0 && (
            <Accordion
              multiple
              className="explanation-request-list"
              activeIndex={projectProposalReviseLogs
                .map((item, index) => (item?.isOpened ? index : null))
                .filter((index) => index !== null)}
            >
              {projectProposalReviseLogs?.map((rowData, rowIndex) => {
                const correspondingPpCouncil = projectProposalCouncils?.find(
                  (ppCouncil) =>
                    ppCouncil?.councilType?.code === councilTypeCodeMap?.[rowData?.type]
                );
                const councilTypeCode = correspondingPpCouncil?.councilType?.code;

                return (
                  <AccordionTab key={rowData.id} header={formatHeader(rowData, rowIndex)}>
                    {/* Kết luận và kiến nghị của Hội đồng */}
                    {reviseLogTypeCodesNotShowCouncilInfo.includes(rowData?.type) === false &&
                      formatConclusion(rowData, correspondingPpCouncil)}
                    {/* Ghi chú */}
                    {formatComment(rowData)}
                    {/* Các file đính kèm */}
                    {formatCommentFileUrls(rowData)}
                    {/* Nhận xét từ chuyên viên */}
                    {reviseLogTypeCodesNotShowCouncilInfo.includes(rowData?.type) === false &&
                      formatFeedbacks(rowData)}
                    {/* Biên bản họp hội đồng */}
                    {reviseLogTypeCodesNotShowCouncilInfo.includes(rowData?.type) === false &&
                      councilTypeCode !== COUNCIL_TYPE_CODE.FINANCE_COUNCIL &&
                      formatReportFile(rowData, correspondingPpCouncil)}
                    {/* Các phiếu nhận xét */}
                    {reviseLogTypeCodesNotShowCouncilInfo.includes(rowData?.type) === false &&
                      councilTypeCode !== COUNCIL_TYPE_CODE.FINANCE_COUNCIL &&
                      formatReviewFiles(rowData)}

                    {formatActions(rowData, correspondingPpCouncil)}
                  </AccordionTab>
                );
              })}
            </Accordion>
          )}

          <CompleteExplanation
            ref={dialogCompleteExplanationRef}
            refetchRequests={refetchRequests}
            refetchProjectVersions={refetchProjectVersions}
          />
          <ViewDetailOwnerExplanation
            reviseLog={selectedReviseLog}
            handleClose={() => setSelectedReviseLog(null)}
            handleDownloadFile={handleDownloadFile}
          />
        </div>
      </div>
    </div>
  );
}
