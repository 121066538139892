/* eslint-disable no-unused-vars */
import './style.scss';

import { Fieldset } from 'primereact/fieldset';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  createRegisterResult,
  updateRegisterResult,
  submitRegisterResult,
  getRegisterResult,
} from 'apis/registerResult';
import { getProjectById } from 'apis/project.api';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'layout';
import { TOAST } from 'constant';

import Loading from 'components/Loading';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import GeneralInfo from '../RegisterResultR09/GeneralInfo';
import ReviewForm from '../RegisterResultR09/ReviewForm';
import RegisterContent from '../RegisterResultR09/RegisterContent';

export default function RegisterResultForm({
  handleSubmit,
  control,
  reset,
  setValue,
  getValues,
  errors,
  tab,
  setTab,
  isDirty,
  dirtyFields,
  checkValidation,
}) {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const [nextEndTab, setNextEndTab] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  // # Region data

  // data project
  const { data: _dataProject } = useQuery({
    queryKey: ['dataProject'],
    queryFn: () => getProjectById(projectId),
  });
  const dataProject = useMemo(() => _dataProject?.data ?? {}, [_dataProject]);

  // data list member
  const listMember = dataProject?.projectProposal?.projectProposalContacts.map((item) => ({
    email: item.email,
    fullName: item.fullname,
    academicDegree: item.academicDegree,
    academicRank: item.academicRank,
    organizationId: item.organizationId,
  }));

  // data list organization
  const { data: _registerResult, refetch } = useQuery({
    queryKey: ['registerResult'],
    queryFn: () => getRegisterResult(projectId),
  });

  const registerResult = useMemo(() => _registerResult?.data ?? {}, [_registerResult]);

  const { mutate: mutateCreateRegisterResult } = useMutation(createRegisterResult);
  const { mutate: mutateUpdateRegisterResult } = useMutation(updateRegisterResult);
  const { mutate: mutateSubmitRegisterResult } = useMutation(submitRegisterResult);
  // # End region data

  // # Region Event
  const onSubmit = async (data) => {
    if (isDirty && Object.keys(dirtyFields).length) {
      const registerResultProducts = [];
      const {
        summaryReportQuantity,
        branchProposalReportQuantity,
        thematicReportQuantity,
        mapQuantity,
        filmQuantity,
        videoQuantity,
        imageQuantity,
        cdDiskQuantity,
        documentQuantity,
      } = data;

      const items = {
        summaryReportQuantity,
        branchProposalReportQuantity,
        thematicReportQuantity,
        mapQuantity,
        filmQuantity,
        videoQuantity,
        imageQuantity,
        cdDiskQuantity,
        documentQuantity,
      };

      Object.keys(items).forEach((key) => {
        if (items[key]) {
          registerResultProducts.push({
            key,
            value: items[key].value,
          });
        }
      });

      // eslint-disable-next-line no-param-reassign
      data.registerResultProducts = registerResultProducts;
      // eslint-disable-next-line no-param-reassign
      data.projectId = projectId;

      if (!registerResult?.id) {
        mutateCreateRegisterResult(data, {
          onSuccess: () => {
            refetch();
            reset({}, { KeepValues: true });
            toast(TOAST.SUCCESS, 'Lưu thành công!');
            if (tab === 3 && nextEndTab) {
              setTab((prev) => prev + 1);
              setNextEndTab(false);
            }
          },
          onError: () => {
            toast(TOAST.ERROR, 'Lưu thất bại!');
          },
        });
      } else {
        mutateUpdateRegisterResult(
          { data, id: registerResult?.id },
          {
            onSuccess: () => {
              refetch();
              reset({}, { KeepValues: true });
              toast(TOAST.SUCCESS, 'Lưu thành công!');
              if (tab === 3 && nextEndTab) {
                setTab((prev) => prev + 1);
                setNextEndTab(false);
              }
            },
            onError: () => {
              toast(TOAST.ERROR, 'Lưu thất bại!');
            },
          }
        );
      }
    }
  };

  // list authors default

  useEffect(() => {
    if (registerResult) {
      // un-file
      const listAuthors = [];
      const listProducts = [];

      // ParticipantTable
      if (listMember && !registerResult?.createdAt) {
        listAuthors.push(...listMember);
        setValue('registerResultAuthors', listAuthors ?? []);
      }
      if (registerResult?.registerResultAuthors) {
        setValue('registerResultAuthors', registerResult?.registerResultAuthors ?? []);
      }

      // SecurtityInformation
      setValue('securitySeverity', registerResult?.securitySeverity ?? '');

      // Product
      if (registerResult?.summaryReportQuantity > 0) {
        setValue('summaryReportQuantity', !!registerResult?.summaryReportQuantity);
        listProducts.push({ key: 'summaryReportQuantity' });
        setValue('summaryReportQuantity.value', registerResult?.summaryReportQuantity ?? '');
      }

      if (registerResult?.branchProposalReportQuantity > 0) {
        listProducts.push({ key: 'branchProposalReportQuantity' });
        setValue('branchProposalReportQuantity', !!registerResult?.branchProposalReportQuantity);
        setValue(
          'branchProposalReportQuantity.value',
          registerResult?.branchProposalReportQuantity ?? ''
        );
      }

      if (registerResult?.thematicReportQuantity > 0) {
        listProducts.push({ key: 'thematicReportQuantity' });
        setValue('thematicReportQuantity', !!registerResult?.thematicReportQuantity);
        setValue('thematicReportQuantity.value', registerResult?.thematicReportQuantity ?? '');
      }

      if (registerResult?.mapQuantity > 0) {
        listProducts.push({ key: 'mapQuantity' });
        setValue('mapQuantity', !!registerResult?.mapQuantity);
        setValue('mapQuantity.value', registerResult?.mapQuantity ?? '');
      }

      if (registerResult?.filmQuantity > 0) {
        listProducts.push({ key: 'filmQuantity' });
        setValue('filmQuantity', !!registerResult?.filmQuantity);
        setValue('filmQuantity.value', registerResult?.filmQuantity ?? '');
      }

      if (registerResult?.videoQuantity > 0) {
        listProducts.push({ key: 'videoQuantity' });
        setValue('videoQuantity', !!registerResult?.videoQuantity);
        setValue('videoQuantity.value', registerResult?.videoQuantity ?? '');
      }

      if (registerResult?.imageQuantity > 0) {
        listProducts.push({ key: 'imageQuantity' });
        setValue('imageQuantity', !!registerResult?.imageQuantity);
        setValue('imageQuantity.value', registerResult?.imageQuantity ?? '');
      }

      if (registerResult?.cdDiskQuantity > 0) {
        listProducts.push({ key: 'cdDiskQuantity' });
        setValue('cdDiskQuantity', !!registerResult?.cdDiskQuantity);
        setValue('cdDiskQuantity.value', registerResult?.cdDiskQuantity ?? '');
      }

      if (registerResult?.documentQuantity > 0) {
        listProducts.push({ key: 'documentQuantity' });
        setValue('documentQuantity', !!registerResult?.documentQuantity);
        setValue('documentQuantity.value', registerResult?.documentQuantity ?? '');
      }

      // Product Description
      setValue('productName', registerResult?.productName ?? '');
      setValue('productCapacity', registerResult?.productCapacity ?? '');
      setValue('productImplementation', registerResult?.productImplementation ?? '');
      setValue('productEconomy', registerResult?.productEconomy ?? '');
      setValue('productTransmission', registerResult?.productTransmission ?? '');
      setValue('productDocumentType', registerResult?.productDocumentType ?? '');
      setValue('productOther', registerResult?.productOther ?? '');
      setValue('productDescription', registerResult?.productDescription ?? '');
      setValue('productAddress', registerResult?.productAddress ?? '');

      setValue('listProducts', listProducts);
    }
  }, [registerResult]);

  const footerConfirmationSubmit = () => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={() => setIsSubmitModalOpen(false)}
        autoFocus
      />
      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={
          () =>
            mutateSubmitRegisterResult(registerResult?.id, {
              onSuccess: () => {
                setIsSubmitModalOpen(false);
                toast(TOAST.SUCCESS, 'Đăng ký kết quả thành công!');
                refetch();
              },
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
    </div>
  );

  const handleTabChange = async () => {
    if (tab !== 3 || !(isDirty && Object.keys(dirtyFields).length)) {
      setTab((prev) => prev + 1);
    } else {
      setNextEndTab(true);
    }
  };
  // # End region event

  return (
    <div className="project-proposal-form m-0 bg-white p-5 p-fluid">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="form-midterm-container">
        <Fieldset className="border-none p-0 p-fluid">
          <div className={tab !== 0 ? 'hidden' : ''}>
            <GeneralInfo />
          </div>
          <div className={tab !== 1 ? 'hidden' : ''}>
            <RegisterContent
              disabled={registerResult?.isSubmitted}
              control={control}
              errors={errors}
            />
          </div>
        </Fieldset>
        {tab !== 0 && tab !== 2 && !registerResult?.isSubmitted && (
          <div className="flex justify-content-end mt-2">
            <Button
              type="submit"
              className="btn-control font-semibold mr-3"
              severity="success"
              onClick={checkValidation}
              disabled={!(isDirty && Object.keys(dirtyFields).length)}
            >
              {t('formLayout.save')}
            </Button>
            <Button
              type="submit"
              className="p-button-next btn-control"
              onClick={() => {
                if (!checkValidation()) {
                  handleTabChange();
                }
              }}
            >
              {t('formLayout.next')}
            </Button>
          </div>
        )}
      </form>
      <div className={tab !== 2 ? 'hidden' : 'ml-4'}>
        <ReviewForm control={control} errors={errors} />
        <div className="flex justify-content-end mt-6">
          <Link to="/project">
            <Button type="button" className="btn-submit  font-semibold mr-3" severity="danger">
              {t('projectProposal.cancel')}
            </Button>
          </Link>
          {!registerResult?.isSubmitted && (
            <Button
              type="button"
              className="btn-submit font-semibold mr-3"
              severity="success"
              onClick={() => setIsSubmitModalOpen(true)}
              disabled={registerResult?.isSubmitted === true || !registerResult?.createdAt}
            >
              {t('r09.submit')}
            </Button>
          )}
        </div>
      </div>
      <Dialog
        header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
        visible={isSubmitModalOpen}
        position="center"
        style={{ width: '400px' }}
        onHide={() => setIsSubmitModalOpen(false)}
        draggable={false}
        footer={footerConfirmationSubmit}
      >
        <Message className="mt-4 w-full" severity="warn" text={t('r09.confirm')} />
      </Dialog>
    </div>
  );
}

RegisterResultForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  tab: PropTypes.number,
  setTab: PropTypes.func,
  isDirty: PropTypes.bool.isRequired,
  dirtyFields: PropTypes.shape({}).isRequired,
  checkValidation: PropTypes.func.isRequired,
};

RegisterResultForm.defaultProps = {
  tab: 0,
  setTab: () => null,
};
