import { forwardRef, useImperativeHandle, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NumberInput, TextInput, TextareaInput } from 'components/FormControl';

import { yupResolver } from '@hookform/resolvers/yup';
import { TOAST } from 'constant';
import { transferredSolutionSchema } from 'features/ScientificProfile/validation';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const formOptions = { resolver: yupResolver(transferredSolutionSchema) };

const TransferredSolutionDialog = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [transferredSolutionControl, setTransferredSolutionControl] = useState();
  const [visible, setVisible] = useState(false);

  const {
    control,
    watch,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({ ...formOptions, mode: 'onChange' });
  // #region Event

  useImperativeHandle(
    ref,
    () => ({
      open: (_transferredSolutionControl) => {
        setTransferredSolutionControl(_transferredSolutionControl);
        setVisible(true);
        if (_transferredSolutionControl.type === 'INSERT') {
          reset({});
        } else if (_transferredSolutionControl.type === 'UPDATE') {
          reset(_transferredSolutionControl.value);
        }
      },
    }),
    []
  );

  const handleAddTransferredSolution = async () => {
    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = watch();

    const { insert, update, index, type } = transferredSolutionControl;

    if (type === 'INSERT' && insert) {
      insert(index, data);
    } else if (type === 'UPDATE' && update) {
      update(index, data);
    }
    reset({});
    setVisible(false);
  };
  // #endregion Event

  return (
    <Dialog
      header={
        transferredSolutionControl?.type === 'INSERT'
          ? t('scientificProfile.otherInfo.transferredSolution.add')
          : t('scientificProfile.otherInfo.transferredSolution.update')
      }
      visible={visible}
      onHide={() => {
        setVisible(false);
      }}
      draggable={false}
      className="w-full sm:w-7 lg:w-6"
    >
      <div className="grid p-fluid">
        <div className="col-12">
          <TextareaInput
            label={t('scientificProfile.otherInfo.transferredSolution.name')}
            name="name"
            control={control}
            errors={errors}
            isRequired
          />
        </div>

        <div className="col-6">
          <TextareaInput
            label={t('scientificProfile.otherInfo.transferredSolution.form')}
            name="form"
            control={control}
            errors={errors}
            isRequired
          />
        </div>
        <div className="col-6">
          <TextareaInput
            label={t('scientificProfile.otherInfo.transferredSolution.scale')}
            name="scale"
            control={control}
            errors={errors}
            isRequired
          />
        </div>

        <div className="col-12">
          <TextareaInput
            label={t('scientificProfile.otherInfo.transferredSolution.address')}
            name="address"
            control={control}
            errors={errors}
            isRequired
          />
        </div>

        <div className="col-6">
          <NumberInput
            label={t('scientificProfile.otherInfo.transferredSolution.yearOfTransfer')}
            name="yearOfTransfer"
            control={control}
            errors={errors}
            min={0}
            isRequired
          />
        </div>
        <div className="col-6">
          <TextInput
            label={t('scientificProfile.otherInfo.transferredSolution.projectCode')}
            name="projectCode"
            control={control}
            errors={errors}
            isRequired
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-content-end mt-4">
        <Button
          label={t('formLayout.action.cancel')}
          severity="danger"
          onClick={() => setVisible(false)}
          className="mr-1 w-8rem"
        />
        <Button
          label={
            transferredSolutionControl?.type === 'INSERT'
              ? t('formLayout.action.add')
              : t('formLayout.action.update')
          }
          severity="info"
          type="submit"
          className="w-8rem"
          onClick={handleAddTransferredSolution}
          disabled={!Object.keys(dirtyFields).length}
        />
      </div>
    </Dialog>
  );
});

TransferredSolutionDialog.propTypes = {};

TransferredSolutionDialog.defaultProps = {};

export default TransferredSolutionDialog;
