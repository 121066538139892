import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Fieldset } from 'primereact/fieldset';
import '../style.scss';
import OtherInfoCard from './Cards/otherInfoCard';

export default function OthersInfoTab({ data }) {
  // #region Data
  const { t } = useTranslation();

  // #endregion Data

  // #region Event

  // #endregion Event

  // #region Render
  const legendTemplate = (icon, label) => (
    <div className="flex align-items-center">
      <span className={icon} />
      <span className="font-bold text-base">{label}</span>
    </div>
  );

  return (
    <>
      {data?.programAttendances?.length === 0 &&
        data?.conferenceAttendances?.length === 0 &&
        data?.universityVisitingStaff?.length === 0 && <div>Chưa cập nhật</div>}
      {data?.programAttendances?.length > 0 && (
        <Fieldset
          legend={legendTemplate(
            'pi pi-book mr-2',
            t('scientificProfile.otherInfo.programAttendance.label')
          )}
        >
          {data?.programAttendances.length > 0 &&
            data?.programAttendances.map((item) => <OtherInfoCard otherInfoData={item} />)}
        </Fieldset>
      )}

      {data?.conferenceAttendances?.length > 0 && (
        <Fieldset
          className="mt-4"
          legend={legendTemplate(
            'pi pi-book mr-2',
            t('scientificProfile.otherInfo.conferenceAttendance.label')
          )}
        >
          {data?.conferenceAttendances.length > 0 &&
            data?.conferenceAttendances.map((item) => <OtherInfoCard otherInfoData={item} />)}
        </Fieldset>
      )}

      {data?.universityVisitingStaff?.length > 0 && (
        <Fieldset
          className="mt-4"
          legend={legendTemplate(
            'pi pi-book mr-2',
            t('scientificProfile.otherInfo.universityVisitingStaff.label')
          )}
        >
          {data?.universityVisitingStaff.length > 0 &&
            data?.universityVisitingStaff.map((item) => <OtherInfoCard otherInfoData={item} />)}
        </Fieldset>
      )}
    </>
  );
}

OthersInfoTab.propTypes = {
  data: PropTypes.shape(),
};

OthersInfoTab.defaultProps = {
  data: {},
};
