// import { useNavigate, useSearchParams } from 'react-router-dom';
// import { ScrollTop } from 'primereact/scrolltop';
// import { TabPanel, TabView } from 'primereact/tabview';
import {
  CreateContract,
  // , DetailContract, SubmitAcceptanceDocuments
} from 'features/Project/pages';
import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

function SubmitProjectProposalDetailPage() {
  // const { state } = useLocation();
  // const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  // const tab = searchParams.get('tab') || 0;

  return (
    <LayoutProvider>
      <Layout
        style={{
          maxHeight: 'calc(100vh - 9rem)',
          overflowY: 'auto',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
          backgroundColor: 'white',
        }}
      >
        {/* <>
          <TabView
            activeIndex={parseInt(tab, 10)}
            onTabChange={(e) => navigate(`.?tab=${e.index}`)}
            className="sticky"
          >
            <TabPanel header="Hợp đồng" leftIcon="pi pi-file mr-2" className="sticky">
              <DetailContract />
            </TabPanel>

            <TabPanel header="Nộp hồ sơ nghiệm thu" leftIcon="pi pi-list mr-2" className="sticky">
              <SubmitAcceptanceDocuments />
            </TabPanel>
          </TabView>
          <ScrollTop target="parent" />
        </> */}

        <CreateContract />
      </Layout>
    </LayoutProvider>
  );
}

export default SubmitProjectProposalDetailPage;
