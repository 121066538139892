import * as Yup from 'yup';
import { maxPoints } from './_constant';

const scoreSchema = Yup.object({
  scoreTarget: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkScoreTarget',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreTarget } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreTarget) return false;
        return true;
      },
    }),

  scoreContent: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkscoreContent',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreContent } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreContent) return false;
        return true;
      },
    }),

  scoreApproach: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkscoreApproach',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreApproach } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreApproach) return false;
        return true;
      },
    }),

  scoreProcess: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkscoreProcess',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreProcess } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreProcess) return false;
        return true;
      },
    }),

  scoreApplicationProduct: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkscoreApplicationProduct',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreApplicationProduct } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreApplicationProduct) return false;
        return true;
      },
    }),

  scorePublishedProduct: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkscorePublishedProduct',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scorePublishedProduct } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scorePublishedProduct) return false;
        return true;
      },
    }),

  scoreTrainingProduct: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkscoreTrainingProduct',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreTrainingProduct } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreTrainingProduct) return false;
        return true;
      },
    }),

  scoreReportQuality: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkscoreReportQuality',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreReportQuality } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreReportQuality) return false;
        return true;
      },
    }),

});

// eslint-disable-next-line import/prefer-default-export
export const createAcceptanceReviewValidationSchema = Yup.object().shape({
  reviewMeet: Yup.string().nullable(),
  reviewProduct: Yup.string().nullable(),
  reviewReportQuality: Yup.string().nullable(),
  // expenditure: expenditureSchema,
  score: scoreSchema,
});
