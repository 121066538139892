import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EditorInput } from 'components/FormControl';
import { RESEARCH_TYPES } from 'constant';

export default function ResearchContributions({ control, errors, disabled, watch, researchTypes }) {
  // #region Data
  const { t } = useTranslation();

  const researchTypesId = watch('general.researchTypesId');
  const researchTypeCode = useMemo(
    () => researchTypes?.find((item) => item?.id === researchTypesId)?.code,
    [researchTypes, researchTypesId]
  );
  // #endregion

  return (
    <div className="mt-3">
      <h4>{t('projectProposal.researchResult.a11.label')}</h4>
      <div className="mt-3">
        <h4>{t('projectProposal.researchResult.a11.a11_1')}</h4>
        <EditorInput
          name="researchResult.knowledgeContributionLevel"
          control={control}
          errors={errors}
          readOnly={disabled}
        />
      </div>

      <div className="mt-3">
        <h4>{t('projectProposal.researchResult.a11.a11_2')}</h4>
        <EditorInput
          name="researchResult.practicalPolicyImpact"
          control={control}
          errors={errors}
          readOnly={disabled}
        />
      </div>

      <div className="mt-3">
        <h4 className="mb-0">{t('projectProposal.researchResult.a11.a11_3.label')}</h4>
        <p className="font-italic">{t('projectProposal.researchResult.a11.a11_3.note')}</p>
        <EditorInput
          name="researchResult.teamDevelopmentLevel"
          control={control}
          errors={errors}
          readOnly={disabled}
        />
      </div>

      <div className="mt-3">
        <h4 className="mb-0">{t('projectProposal.researchResult.a11.a11_4.label')}</h4>
        <p className="font-italic">{t('projectProposal.researchResult.a11.a11_4.note')}</p>
        {researchTypeCode === RESEARCH_TYPES.IMPLEMENTATION && (
          <EditorInput
            name="researchResult.researchTransferability"
            control={control}
            errors={errors}
            readOnly={disabled || researchTypeCode !== RESEARCH_TYPES.IMPLEMENTATION}
          />
        )}
      </div>
    </div>
  );
}

ResearchContributions.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  watch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  researchTypes: PropTypes.array.isRequired,
};
