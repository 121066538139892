/* eslint-disable react/no-danger */
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { getRegisterResult } from 'apis/registerResult';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import GeneralInfo from 'features/Project/components/RegisterResultR09/GeneralInfo';
import RegisterContent from '../RegisterContent';
import './styles.scss';

export default function ReviewForm() {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { control, setValue } = useForm({ mode: 'onChange' });
  const { errors } = useFormState({ control });

  const { data: _registerResult } = useQuery({
    queryKey: ['registerResult'],
    queryFn: () => getRegisterResult(projectId),
  });

  const registerResult = useMemo(() => _registerResult?.data ?? {}, [_registerResult]);

  useEffect(() => {
    if (registerResult) {
      // un-file
      const listProducts = [];

      // ParticipantTable
      if (registerResult?.registerResultAuthors) {
        setValue('registerResultAuthors', registerResult?.registerResultAuthors ?? []);
      }

      // SecurtityInformation
      setValue('securitySeverity', registerResult?.securitySeverity ?? '');

      // Product
      if (registerResult?.summaryReportQuantity > 0) {
        setValue('summaryReportQuantity', !!registerResult?.summaryReportQuantity);
        listProducts.push({ key: 'summaryReportQuantity' });
        setValue('summaryReportQuantity.value', registerResult?.summaryReportQuantity ?? '');
      }

      if (registerResult?.branchProposalReportQuantity > 0) {
        listProducts.push({ key: 'branchProposalReportQuantity' });
        setValue('branchProposalReportQuantity', !!registerResult?.branchProposalReportQuantity);
        setValue(
          'branchProposalReportQuantity.value',
          registerResult?.branchProposalReportQuantity ?? ''
        );
      }

      if (registerResult?.thematicReportQuantity > 0) {
        listProducts.push({ key: 'thematicReportQuantity' });
        setValue('thematicReportQuantity', !!registerResult?.thematicReportQuantity);
        setValue('thematicReportQuantity.value', registerResult?.thematicReportQuantity ?? '');
      }

      if (registerResult?.mapQuantity > 0) {
        listProducts.push({ key: 'mapQuantity' });
        setValue('mapQuantity', !!registerResult?.mapQuantity);
        setValue('mapQuantity.value', registerResult?.mapQuantity ?? '');
      }

      if (registerResult?.filmQuantity > 0) {
        listProducts.push({ key: 'filmQuantity' });
        setValue('filmQuantity', !!registerResult?.filmQuantity);
        setValue('filmQuantity.value', registerResult?.filmQuantity ?? '');
      }

      if (registerResult?.videoQuantity > 0) {
        listProducts.push({ key: 'videoQuantity' });
        setValue('videoQuantity', !!registerResult?.videoQuantity);
        setValue('videoQuantity.value', registerResult?.videoQuantity ?? '');
      }

      if (registerResult?.imageQuantity > 0) {
        listProducts.push({ key: 'imageQuantity' });
        setValue('imageQuantity', !!registerResult?.imageQuantity);
        setValue('imageQuantity.value', registerResult?.imageQuantity ?? '');
      }

      if (registerResult?.cdDiskQuantity > 0) {
        listProducts.push({ key: 'cdDiskQuantity' });
        setValue('cdDiskQuantity', !!registerResult?.cdDiskQuantity);
        setValue('cdDiskQuantity.value', registerResult?.cdDiskQuantity ?? '');
      }

      if (registerResult?.documentQuantity > 0) {
        listProducts.push({ key: 'documentQuantity' });
        setValue('documentQuantity', !!registerResult?.documentQuantity);
        setValue('documentQuantity.value', registerResult?.documentQuantity ?? '');
      }

      // Product Description
      setValue('productName', registerResult?.productName ?? '');
      setValue('productCapacity', registerResult?.productCapacity ?? '');
      setValue('productImplementation', registerResult?.productImplementation ?? '');
      setValue('productEconomy', registerResult?.productEconomy ?? '');
      setValue('productTransmission', registerResult?.productTransmission ?? '');
      setValue('productDocumentType', registerResult?.productDocumentType ?? '');
      setValue('productOther', registerResult?.productOther ?? '');
      setValue('productDescription', registerResult?.productDescription ?? '');
      setValue('productAddress', registerResult?.productAddress ?? '');

      setValue('listProducts', listProducts);
    }
  }, [registerResult]);
  return (
    <div className="review-form">
      <div className="mb-5">
        <div className="mb-5 px-5 flex justify-content-between align-items-center">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="VNU-HCM logo"
            height="60px"
            className="ml-3"
          />
          <div className="mr-8">
            <span>{t('r09.formR09')}</span>
            <br />
            <span>{t('r09.projectID')}</span>
          </div>
        </div>
        <div className="text-center">
          <p className="font-bold text-2xl my-0">{t('r09.title1')}</p>
          <p className=" text-2xl my-0">{t('r09.title2')}</p>
        </div>
      </div>
      <form autoComplete="off" className="p-fluid form-midterm-container">
        <GeneralInfo />
        <div className="mt-4">
          <RegisterContent disabled control={control} errors={errors} />
        </div>
      </form>
    </div>
  );
}

ReviewForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
};

ReviewForm.defaultProps = {
  summaryReport: {},
};
