import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

export default function ParticipantFields({
  participants,
  formatIndex,
  formatFullname,
  formatGender,
  formatOrganizationName,
}) {
  // #region Data
  const { t } = useTranslation();
  // #endregion Data

  return (
    participants?.length > 0 && (
      <>
        <h4>{t('projectProposal.general.a9.participantFields.label')}</h4>
        <div className="grid">
          <div className="col-12">
            <DataTable
              value={participants}
              className="p-datatable-gridlines"
              showGridlines
              dataKey="contact.id"
              responsiveLayout="scroll"
            >
              <Column
                header={t('formLayout.numericalOrder')}
                body={formatIndex}
                style={{ textAlign: 'center', width: '5%' }}
              />
              <Column
                header={`${t('projectProposal.general.a9.fields.academicRank')}, ${t(
                  'projectProposal.general.a9.fields.academicDegree'
                )}, ${t('projectProposal.general.a9.fields.name')}`}
                body={formatFullname}
                style={{ minWidth: 100, width: '22%' }}
              />
              <Column
                header={t('projectProposal.general.a9.fields.gender')}
                body={formatGender}
                style={{ minWidth: 100, width: '5%' }}
              />
              <Column
                header={t('projectProposal.general.a9.fields.organization')}
                body={formatOrganizationName}
                style={{ minWidth: 200, width: '28%' }}
              />
              <Column
                field="assignedTask"
                header={t('projectProposal.general.a9.assignedTaskParticipant')}
                style={{ minWidth: 200, width: '40%' }}
              />
            </DataTable>
          </div>
        </div>
      </>
    )
  );
}

ParticipantFields.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  participants: PropTypes.array.isRequired,
  formatIndex: PropTypes.func.isRequired,
  formatFullname: PropTypes.func.isRequired,
  formatGender: PropTypes.func.isRequired,
  formatOrganizationName: PropTypes.func.isRequired,
};
