import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import LanguageSelect from 'components/LanguageSelect';
import TokenService from 'utils/axios/token.axios';

import { OverlayPanel } from 'primereact/overlaypanel';
import { classNames } from 'primereact/utils';
import { getNotification, readNotification, readAllNotification } from 'apis/notification.api';
import { useQuery, useMutation } from 'react-query';
import useOutsideClick from 'utils/hook';
import { Tooltip } from 'primereact/tooltip';
import { timeAgo } from 'utils/func';
import instance from 'utils/axios/instance.axios';
import { LayoutContext } from './context/layoutcontext';

const AppTopbar = forwardRef((props, ref) => {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { layoutState, onMenuToggle, showProfileSidebar } = useContext(LayoutContext);
  const user = useMemo(() => {
    const userLocal = localStorage.getItem('user');
    return userLocal ? JSON.parse(userLocal) : null;
  });
  const [showNoti, setShowNoti] = useState(false);
  const [allNotification, setAllNotification] = useState(true);
  const [showScrollbar, setShowScrollbar] = useState(false);

  const { data, refetch } = useQuery(['notification', 'all', user?.id], () =>
    getNotification(0, 10, 'all')
  );
  const { data: _data, refetch: _refetch } = useQuery(['notification', 'unread', user?.id], () =>
    getNotification(0, 10, 'unread')
  );
  const notifications = useMemo(() => {
    if (allNotification) return data?.data?.data ?? [];
    return _data?.data?.data ?? [];
  }, [data, _data, allNotification]);

  const { mutate: readNotificationMutate } = useMutation(readNotification);
  const { mutate: readAllNotificationMutate } = useMutation(readAllNotification);

  const menubuttonRef = useRef(null);
  const topbarmenuRef = useRef(null);
  const topbarmenubuttonRef = useRef(null);
  const settingRef = useRef(null);
  const profileRef = useRef(null);
  const notiContentRef = useRef(null);
  const notiButtonRef = useRef(null);
  useOutsideClick(notiContentRef, notiButtonRef, showNoti, setShowNoti);

  // #endregion Data

  // #region Event
  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
    topbarmenu: topbarmenuRef.current,
    topbarmenubutton: topbarmenubuttonRef.current,
  }));

  const showSettingModal = (event) => {
    settingRef.current.toggle(event);
  };
  const showProfileModal = (event) => {
    profileRef.current.toggle(event);
  };

  const handleLogout = async () => {
    try {
      await instance.post('/users/logout');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error during logout:', err);
    }
    TokenService.removeUser();
    navigate('/login');
  };

  const handleReadNotification = (noti) => {
    readNotificationMutate(noti?.id, {
      onSuccess: () => {
        _refetch();
        navigate(noti?.path);
      },
    });
  };

  const handleReadAllNotification = () => {
    readAllNotificationMutate(null, {
      onSuccess: () => {
        _refetch();
        refetch();
      },
    });
  };

  useEffect(() => {
    const divHeight = notiContentRef.current.clientHeight;
    setShowScrollbar(divHeight > window.innerHeight * 0.8);
  }, [showNoti, allNotification]);
  // #endregion Event
  return (
    <div className="layout-topbar">
      <button
        ref={menubuttonRef}
        type="button"
        className="p-link layout-menu-button layout-topbar-button"
        onClick={onMenuToggle}
      >
        <i className="pi pi-bars" />
      </button>

      <Link to="/" className="layout-topbar-logo">
        <span className="text-xl sm:text-2xl">{t('websiteName')}</span>
      </Link>

      <button
        ref={topbarmenubuttonRef}
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={showProfileSidebar}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <div
        ref={topbarmenuRef}
        className={classNames('layout-topbar-menu', {
          'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible,
        })}
      >
        {user && (
          <>
            <div className="relative">
              <button ref={notiButtonRef} type="button" className="p-link layout-topbar-button">
                <i className="pi pi-bell p-overlay-badge" />
                <span>{t('notification.title')}</span>
                <div
                  ref={notiContentRef}
                  className={`notify-badge px-2 pb-2 ${
                    showScrollbar ? 'overflow-y-scroll' : ''
                  } z-5 ${
                    !showNoti ? 'hidden' : ''
                  } lg:w-24rem md:w-22rem w-20rem scroll-noti cursor-auto`}
                >
                  <div className="grid m-2">
                    <div className="col-10">
                      <h4 className=" m-0 font-semibold">{t('notification.title')}</h4>
                    </div>
                    <div
                      className={`col-2 text-right ${
                        !allNotification && notifications.length > 0 ? '' : 'hidden'
                      }`}
                    >
                      <button
                        type="button"
                        className="p-link mark-read hover:bg-gray-200"
                        data-pr-tooltip={t('notification.markAllAsRead')}
                        data-pr-position="right"
                        data-pr-at="center bottom"
                        data-pr-my="right top"
                        onClick={handleReadAllNotification}
                      >
                        <i className="pi pi-check px-2" data-pr-tooltip="ABC" />
                        <Tooltip target=".mark-read" />
                      </button>
                    </div>
                  </div>
                  <div className="flex justify-content-between mb-2">
                    <div className="flex gap-2">
                      <button
                        type="button"
                        className={`p-link text-right p-2 ${
                          allNotification ? 'bg-gray-200 border-round-lg font-semibold' : ''
                        }`}
                        onClick={() => setAllNotification(true)}
                      >
                        {t('notification.all')}
                      </button>
                      <button
                        type="button"
                        className={`p-link text-right p-2 ${
                          !allNotification ? 'bg-gray-200 border-round-lg font-semibold' : ''
                        }`}
                        onClick={() => setAllNotification(false)}
                      >
                        {t('notification.unRead')}
                      </button>
                    </div>
                    <div className="font-semibold text-base text-center mr-3 mt-2">
                      <Link to="/notifications">{t('notification.viewAll')}</Link>
                    </div>
                  </div>
                  <hr className="w-full hr-custom" />
                  {notifications.length > 0 ? (
                    notifications?.map((noti) => (
                      <Link
                        to={noti?.path}
                        key={noti?.id}
                        onClick={(e) => {
                          e.preventDefault();
                          handleReadNotification(noti);
                        }}
                      >
                        <div className="p-3 cursor-pointer hover:bg-gray-200 border-round-lg">
                          <div className="flex justify-content-between">
                            <h5 className="mb-2 md:text-lg text-sm font-medium ">
                              {t(`notification.${noti?.type}`)}
                            </h5>
                            <p className="font-medium md:text-sm text-xs text-purple-600">
                              {`  ${timeAgo(noti?.createdAt)}`}
                            </p>
                          </div>
                          <p className="font-semibold md:text-sm text-xs mb-0">{noti?.message}</p>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <div className="flex flex-column justify-content-center">
                      <img
                        src="/layout/images/notifications_dark_mode.svg"
                        alt="no-noti"
                        className="w-1/2 h-8rem mx-auto"
                      />
                      <h5 className="mb-4 mt-0 md:text-xl text-base text-center">
                        {t('notification.noNoti')}
                      </h5>
                    </div>
                  )}
                </div>
              </button>
            </div>

            <button
              type="button"
              className="p-link layout-topbar-button"
              onClick={showProfileModal}
            >
              <i className="pi pi-user" />
              <span>{t('topbar.profile')}</span>
              <OverlayPanel
                ref={profileRef}
                appendTo={typeof window !== 'undefined' ? document.body : null}
                showCloseIcon={false}
                id="overlay_panel_profile"
              >
                <div className="flex align-items-center p-3">
                  <i className="pi pi-user text-2xl" />
                  <div className="ml-4">
                    <h6 className="m-0 text-lg">{user.fullname}</h6>
                    <p>
                      {user.email.split('@')[0]}
                      <wbr />@{user.email.split('@')[1]}
                    </p>
                  </div>
                </div>
                <hr className="m-0 bg-gray-300" style={{ height: 0.5 }} />
                <ul className="profile-menu list-none p-0 m-0">
                  <li>
                    <Link to="/me" className="text-color py-3">
                      <i className="pi pi-user mr-3" />
                      {t('topbar.profile')}
                    </Link>
                  </li>
                  <hr className="m-0 bg-gray-300" style={{ height: 0.5 }} />
                  <li>
                    <Link to="/update-password" className="text-color py-3">
                      <i className="pi pi-key mr-3" />
                      {t('topbar.updatePassword')}
                    </Link>
                  </li>
                  <hr className="m-0 bg-gray-300" style={{ height: 0.5 }} />
                  <span onClick={handleLogout} className="py-3">
                    <i className="pi pi-sign-out mr-3" />
                    {t('topbar.logout')}
                  </span>
                </ul>
              </OverlayPanel>
            </button>
          </>
        )}

        <button type="button" className="p-link layout-topbar-button" onClick={showSettingModal}>
          <i className="pi pi-cog" />
          <span>{t('topbar.setting')}</span>
        </button>
        <OverlayPanel
          ref={settingRef}
          appendTo={typeof window !== 'undefined' ? document.body : null}
          showCloseIcon={false}
          id="overlay_panel_setting"
          style={{ width: '210px' }}
        >
          <LanguageSelect />
        </OverlayPanel>
      </div>
    </div>
  );
});

export default AppTopbar;
