import { useFieldArray, useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import TableScholarDataDialog from '../Dialog/tableScholarData';
import DomesticPaperDialog from './DomesticPaperDialog';

export default function DomesticPapersTable({ control, shouldUpdateFromDocx }) {
  // #region Data
  const { t } = useTranslation();

  const domesticPaperRef = useRef();

  const { dirtyFields } = useFormContext();
  const { fields, insert, update, remove } = useFieldArray({
    control,
    name: 'scientificWorks.domesticPapers',
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [scholarData, setScholarData] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);

  // #endregion

  // #region Event
  // #endregion

  // #region Render
  const formatIndex = (_, { rowIndex }) => rowIndex + 1;

  const tagPaperScholar = (item) => {
    // Initialize jsxElement with null or any default value
    let jsxElement = null;

    if (item?.paper?.scholarVerification?.status === 'VERIFIED') {
      jsxElement = (
        <>
          <Tooltip target=".verification-tag" mouseTrack mouseTrackLeft={10} />
          <a
            href={item?.paper?.scholarVerification?.data[0]?.pub_url}
            target="_blank"
            rel="noreferrer"
            data-pr-tooltip={t('scientificProfile.scientificWork.viewVerifyPaper')}
            data-pr-position="left"
            className={classNames({
              'verification-tag': true,
            })}
          >
            {t('scientificProfile.scientificWork.verifiedLabel')}
            <i
              className="pi pi-check-circle text-white text-center ml-1"
              style={{ fontSize: '0.7rem' }}
            />
          </a>
        </>
      );
    }

    if (item?.paper?.scholarVerification?.status === 'UNCERTAIN') {
      jsxElement = (
        <>
          <Tooltip target=".uncertain-tag" mouseTrack mouseTrackLeft={10} />
          <span
            onClick={() => {
              setScholarData(item?.paper?.scholarVerification?.data);
              setIsDialogOpen(true);
            }}
            data-pr-tooltip={t('scientificProfile.scientificWork.viewVerifyPaper')}
            data-pr-position="left"
            className={classNames({
              'uncertain-tag': true,
            })}
          >
            {t('scientificProfile.scientificWork.uncertainLabel')}
            <i
              className="pi pi-external-link text-white text-center ml-1"
              style={{ fontSize: '0.7rem' }}
            />
          </span>
          <TableScholarDataDialog
            isOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            scholarData={scholarData}
          />
        </>
      );
    }

    if (item?.paper?.scholarVerification?.status === 'NOT_FOUND') {
      jsxElement = (
        <>
          <Tooltip target=".not-found-tag" mouseTrack mouseTrackLeft={10} />
          <span
            data-pr-tooltip={t('scientificProfile.scientificWork.viewVerifyPaper')}
            data-pr-position="left"
            className={classNames({
              'not-found-tag': true,
            })}
          >
            {t('scientificProfile.scientificWork.notFoundLabel')}
            <i
              className="pi pi-search text-white text-center ml-1"
              style={{ fontSize: '0.7rem' }}
            />
          </span>
        </>
      );
    }

    // Return jsxElement or null if no conditions are met
    return jsxElement;
  };

  const formatActions = (rowData, { rowIndex }) => (
    <div className="flex flex-column float text-center p-0 border-none">
      <Tooltip target=".insert-icon-domestic-paper" />
      <i
        onClick={() => {
          domesticPaperRef.current.open({
            insert,
            index: rowIndex + 1,
            type: 'INSERT',
          });
        }}
        className="insert-icon-domestic-paper pi pi-plus text-primary cursor-pointer my-1"
        data-pr-tooltip={t('formLayout.action.insert')}
        data-pr-position="left"
        style={{ cursor: 'pointer' }}
      />
      <Tooltip target=".edit-icon-domestic-paper" />

      <i
        onClick={() => {
          domesticPaperRef.current.open({
            update,
            index: rowIndex,
            value: fields[rowIndex],
            type: 'UPDATE',
          });
        }}
        className="edit-icon-domestic-paper pi pi-pencil text-primary cursor-pointer mb-1"
        data-pr-tooltip={t('formLayout.action.edit')}
        data-pr-position="left"
        style={{ cursor: 'pointer' }}
      />

      <Tooltip target=".remove-icon-domestic-paper" />

      <i
        onClick={() => remove(rowIndex)}
        className="remove-icon-domestic-paper pi pi-times text-red-700 cursor-pointer mb-1"
        data-pr-tooltip={t('formLayout.action.remove')}
        data-pr-position="left"
        style={{ cursor: 'pointer' }}
      />
      {tagPaperScholar(rowData)}
    </div>
  );

  const rowExpansionTemplate = (item) => (
    <div className="m-2 text-lg">
      <div className="grid">
        <div className="col-12">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.title')}
            {': '}
          </strong>
          {item?.paper?.title}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.authors')}
            {': '}
          </strong>
          {item?.paper?.authors}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.journal')}
            {': '}
          </strong>
          {item?.paper?.journal}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.journalAbbrev')}
            {': '}
          </strong>
          {item?.paper?.journalAbbrev}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.pages')}
            {': '}
          </strong>
          <a href={item?.paper?.pages}>{item?.paper?.pages}</a>
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.year')}
            {': '}
          </strong>
          {item?.paper?.year}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.projectCode')}
            {': '}
          </strong>
          {item?.paper?.projectCode}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.issn')}
            {': '}
          </strong>
          {item?.paper?.issn}
        </div>

        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.note')}
            {': '}
          </strong>
          {item?.note}
        </div>
      </div>
    </div>
  );

  // #endregion Render

  return (
    <>
      <DataTable
        value={fields}
        showGridlines
        filterDisplay="menu"
        loading={typeof fields?.length !== 'number'}
        scrollable
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        rowClassName={classNames({
          'highlighted-uncertain':
            shouldUpdateFromDocx &&
            dirtyFields.scientificWorks?.internationalPapers?.[0]?.paper?.title,
        })}
        emptyMessage=" "
      >
        <Column expander style={{ width: '2rem' }} />
        <Column
          header={t('formLayout.numericalOrder')}
          headerClassName="text-center  "
          className="text-center"
          body={formatIndex}
          style={{ minWidth: 50, width: 50 }}
          expander
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.title')}
          headerClassName="text-center"
          field="paper.title"
        />

        <Column
          header={t('scientificProfile.scientificWork.paper.authors')}
          headerClassName="text-center"
          field="paper.authors"
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.journal')}
          headerClassName="text-center"
          field="paper.journal"
        />

        <Column
          header={t('scientificProfile.scientificWork.paper.year')}
          headerClassName="text-center"
          field="paper.year"
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.projectCode')}
          headerClassName="text-center"
          field="paper.projectCode"
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.issn')}
          headerClassName="text-center"
          style={{ minWidth: 100 }}
          field="paper.issn"
        />
        <Column
          header={t('scientificProfile.scientificWork.paper.note')}
          headerClassName="text-center"
          field="note"
        />
        <Column
          header={t('formLayout.action.label')}
          headerClassName="text-center"
          style={{ minWidth: 80, width: 80 }}
          body={formatActions}
          frozen
          alignFrozen="right"
        />
      </DataTable>

      {fields?.length === 0 && (
        <div className="flex justify-content-center my-2">
          <i
            onClick={() => {
              domesticPaperRef.current.open({
                insert,
                index: 0,
                type: 'INSERT',
              });
            }}
            className="add-domestic-paper pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle"
            data-pr-tooltip={t('formLayout.action.add')}
            data-pr-position="left"
          />
          <Tooltip target=".add-domestic-paper" />
        </div>
      )}

      <DomesticPaperDialog ref={domesticPaperRef} />
    </>
  );
}

DomesticPapersTable.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  shouldUpdateFromDocx: PropTypes.bool,
};

DomesticPapersTable.defaultProps = {
  shouldUpdateFromDocx: false,
};
