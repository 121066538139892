import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import VerifyAccount from 'features/Authentication/VerifyAccount';

function VerifyAccountPage() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('websiteName');
  }, [t]);

  return <VerifyAccount />;
}

export default VerifyAccountPage;
