/* eslint-disable indent */
import { EditorInput, Tickbox } from 'components/FormControl';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { getAllProposedExpenditureTypes } from 'apis/projectProposals.api';
import PropTypes from 'prop-types';

export default function ExpenditureForm({ setValue, getValues, control, errors }) {
  const { t } = useTranslation();

  const { data: expenditureTypesData } = useQuery(
    ['projectProposalProposedExpenditureTypes'],
    getAllProposedExpenditureTypes,
    { staleTime: 1000 * 60 * 5 }
  );

  const expenditureTypes = useMemo(() => {
    const types = expenditureTypesData?.data ?? [];
    return types?.sort((a, b) => a.order - b.order);
  }, [expenditureTypesData]);

  // eslint-disable-next-line no-unused-vars
  const handleCheck = (name, value) => {
    const token = name.split('.');
    const [, id, key] = token;
    let otherKey = [];

    switch (key) {
      case 'high': {
        otherKey = ['low', 'suitable'];
        break;
      }
      case 'low': {
        otherKey = ['high', 'suitable'];
        break;
      }
      case 'suitable': {
        otherKey = ['high', 'low'];
        break;
      }
      default: {
        otherKey = [];
        break;
      }
    }

    if (otherKey?.length) {
      const otherValue1 = getValues(`expenditure.${id}.${otherKey[0]}`);
      const otherValue2 = getValues(`expenditure.${id}.${otherKey[1]}`);
      if (otherValue1) {
        setValue(`expenditure.${id}.${otherKey[0]}`, false);
      }
      if (otherValue2) {
        setValue(`expenditure.${id}.${otherKey[1]}`, false);
      }
    }
  };

  return (
    <div className="form-table">
      <table className="mb-2">
        <thead>
          <tr>
            <th className="text-center" rowSpan={2} style={{ minWidth: 260, width: 360 }}>
              {t('council.m01.a.a4.reviewContent')} <br />{' '}
              <span className="font-italic font-medium">
                {t('council.m01.a.a4.reviewDescription')}
              </span>
            </th>
            <th className="text-center" colSpan={3}>
              {t('council.m01.a.a4.review')}
              <span className="font-italic font-medium"> {t('council.m01.a.a4.markX')}</span>
            </th>
          </tr>
          <tr>
            <th className="text-center" style={{ width: 100 }}>
              {t('council.m01.a.a4.hight')}
            </th>
            <th className="text-center" style={{ width: 100 }}>
              {t('council.m01.a.a4.low')}
            </th>
            <th className="text-center" style={{ width: 100 }}>
              {t('council.m01.a.a4.suitable')}
            </th>
          </tr>
        </thead>
        <tbody>
          {expenditureTypes &&
            expenditureTypes.map((type) => (
              <tr key={type.id}>
                <td
                  className={classNames({
                    'font-bold': type?.subProposedExpenditureTypeId === null,
                  })}
                >
                  {t('lang') === 'vi' ? type?.name : type?.englishName}
                </td>
                <td>
                  <div className="flex justify-content-center">
                    <Tickbox
                      icon="pi pi-times"
                      name={`expenditure.${type.id}.high`}
                      control={control}
                      errors={errors}
                      onCustomCheck={handleCheck}
                    />
                  </div>
                </td>
                <td>
                  <div className="flex justify-content-center">
                    <Tickbox
                      icon="pi pi-times"
                      name={`expenditure.${type.id}.low`}
                      control={control}
                      errors={errors}
                      onCustomCheck={handleCheck}
                    />
                  </div>
                </td>
                <td>
                  <div className="flex justify-content-center">
                    <Tickbox
                      icon="pi pi-times"
                      name={`expenditure.${type.id}.suitable`}
                      control={control}
                      errors={errors}
                      onCustomCheck={handleCheck}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="mt-3">
        <div className="mb-2 font-bold text-lg">{t('council.m01.a.a4.total')}</div>
        <EditorInput
          name="expenditureTotalSuggestion"
          isRequired
          control={control}
          errors={errors}
        />
      </div>
    </div>
  );
}

ExpenditureForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};
