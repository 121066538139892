/* eslint-disable indent */
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getMyContact } from 'apis/contacts.api';

import Loading from 'components/Loading';

import { TIMEZONE_VN } from 'constant';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';

import EditProfileForm from './components/EditProfileForm';

const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: TIMEZONE_VN };

export default function UserProfile() {
  // #region Data
  const { t } = useTranslation();
  const toast = useRef(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { data: _data, isLoading, refetch } = useQuery(['myContactInfo'], getMyContact);
  const data = useMemo(() => _data?.data ?? {}, [_data]);
  // #endregion Data

  if (isLoading) return <Loading />;

  return (
    <div className="bg-white h-full p-3 md:p-4 xl:p-6">
      <div className="flex h-8rem md:h-12rem xl:h-14rem">
        <div>
          <img
            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            alt="user profile"
            className="border-circle h-6rem sm:h-8rem md:h-10rem xl:h-12rem"
          />
        </div>
        <div className="pl-3 md:pl-4 xl:pl-6 flex-1">
          <div className="flex mb-2">
            <span className="pr-4 text-lg md:text-2xl xl:text-3xl font-semibold">
              {data?.fullname}
            </span>
            <span className="text-gray-600 font-semibold pt-1 md:pt-2">
              <i className="pi pi-tag pr-1" />
              {data?.user?.userRoles?.length > 0
                ? data.user.userRoles.map((item) => t(`userRole.${item?.role?.code}`))?.join(', ')
                : t('userRole.user')}
            </span>
          </div>

          <span className="text-cyan-500 font-bold">
            {data?.scientificProfile?.academicRank
              ? `${t(`academicRank.${data?.scientificProfile?.academicRank}`)}, `
              : ''}
            {data?.scientificProfile?.academicDegree
              ? t(`academicDegree.${data?.scientificProfile?.academicDegree}`)
              : ''}
          </span>
        </div>
      </div>
      <div className="flex justify-content-end column-gap-2">
        <Button
          label={t('userProfile.editProfile')}
          icon="pi pi-pencil"
          className="bg-cyan-400 border-cyan-400 hover:bg-cyan-500"
          onClick={() => setIsEditModalOpen(true)}
        />
      </div>

      <div className="mt-3 flex-1">
        <div className="w-full">
          <TabView>
            <TabPanel
              header={t('userProfile.info')}
              leftIcon="pi pi-user mr-2"
              className="flex flex-wrap gap-6"
            >
              <div className="flex flex-wrap gap-6">
                <div>
                  <h6 className="text-gray-500 mb-4">{t('userProfile.contact.label')}</h6>
                  <div className="ml-0 md:ml-5">
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">Email:</span>

                      {data?.email && (
                        <a
                          href={`mailto:${data.email}`}
                          className="max-w-20rem font-medium text-blue-600"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {data?.email.split('@')[0]}
                          <wbr />@{data?.email.split('@')[1]}
                        </a>
                      )}
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.contact.phone')}:
                      </span>
                      {data?.scientificProfile?.personalPhone && (
                        <a
                          href={`tel:${data.scientificProfile.personalPhone}`}
                          className="max-w-20rem font-medium text-blue-600"
                        >
                          {data?.scientificProfile?.personalPhone}
                        </a>
                      )}
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.contact.organization')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {data?.organization?.englishName === 'Other'
                          ? data?.specificOrganizationName
                          : t('lang') === 'vi'
                          ? data?.organization?.name
                          : data?.organization?.englishName}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.contact.address')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.officeAddress}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.contact.ORCIDcode')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.ORCIDcode}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <h6 className="text-gray-500 mb-4">{t('userProfile.basic.label')}</h6>
                  <div className="ml-0 md:ml-5">
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.basic.gender')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.gender
                          ? t(`userProfile.gender.${data?.scientificProfile?.gender.toLowerCase()}`)
                          : ''}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.basic.dob')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.dob
                          ? new Date(data?.scientificProfile?.dob)?.toLocaleDateString(
                              t('lang'),
                              options
                            )
                          : ''}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.basic.citizenId')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.citizenId}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.basic.dateOfIssue')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.dateOfIssue
                          ? new Date(data?.scientificProfile?.dateOfIssue)?.toLocaleDateString(
                              t('lang'),
                              options
                            )
                          : ''}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-8rem font-semibold mr-2">
                        {t('userProfile.basic.placeOfIssue')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.placeOfIssue}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <h6 className="text-gray-500 mb-4">{t('userProfile.finance.label')}</h6>
                  <div className="ml-0 md:ml-5">
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-10rem font-semibold mr-2">
                        {t('userProfile.finance.taxIdentificationNumber')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.taxIdentificationNumber}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-10rem font-semibold mr-2">
                        {t('userProfile.finance.bankAccount')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.bankAccount}
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-10rem font-semibold mr-2">
                        {t('userProfile.finance.bank')}:
                      </span>
                      <p className="max-w-20rem font-medium">{data?.scientificProfile?.bank}</p>
                    </div>
                    <div className="flex mt-3">
                      <span className="w-6rem lg:w-10rem font-semibold mr-2">
                        {t('userProfile.finance.bankBranch')}:
                      </span>
                      <p className="max-w-20rem font-medium">
                        {data?.scientificProfile?.bankBranch}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>

      <EditProfileForm
        contact={data}
        visible={isEditModalOpen}
        setVisible={setIsEditModalOpen}
        refetch={refetch}
      />

      <Toast ref={toast} />
    </div>
  );
}
