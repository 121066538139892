import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { update as updateContact } from 'apis/contacts.api';
import { getAll as getAllOrganizations } from 'apis/organizations.api';
import { update as updateScientificProfile } from 'apis/scientificProfiles.api';

import {
  DatePicker,
  MaskInput,
  NumberInput,
  Select,
  TextareaInput,
  TextInput,
} from 'components/FormControl';
import Loading from 'components/Loading';

import { yupResolver } from '@hookform/resolvers/yup';
import updateUserProfileValidationSchema from 'features/UserProfile/validation';
import ResearchLinesTable from 'features/ProjectProposal/components/GeneralInfoForm/ResearchLinesTable';

import { TOAST } from 'constant';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import TokenService from 'utils/axios/token.axios';
import { getAcademicDegrees, getAcademicRanks, getEndingTimeOfDate, mapError } from 'utils/func';

const formOptions = { resolver: yupResolver(updateUserProfileValidationSchema) };

export default function EditProfileForm({ contact, visible, setVisible, refetch }) {
  // #region Data
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, dirtyFields, isValid },
  } = useForm(formOptions);

  const { mutate: mutateUpdateContact, isLoading: isUpdateContactLoading } =
    useMutation(updateContact);

  const { mutate: mutateUpdateScientificProfile, isLoading: isUpdateScientificProfileLoading } =
    useMutation(updateScientificProfile);

  const { data: organizationsData } = useQuery(['organizations'], getAllOrganizations);
  const organizations = useMemo(
    () =>
      organizationsData?.data.map((e) => ({
        ...e,
        name: t('lang') === 'vi' ? e?.name : e?.englishName,
      })) ?? [],
    [organizationsData, t]
  );

  const organizationId = watch('organizationId');

  const currentOrganization = useMemo(() => {
    if (organizations?.length) {
      const result = organizations?.find((item) => item?.id === organizationId) ?? {};
      setValue('organizationEnglishName', result?.englishName ?? '');
      if (result?.englishName !== 'Other') {
        setValue('specificOrganizationName', null);
      }
      return result;
    }
    return null;
  }, [organizations, organizationId]);

  const genders = [
    { value: 'male', name: t('gender.male') },
    { value: 'female', name: t('gender.female') },
  ];
  // #endregion Data

  // #region Event
  // Binding user into form
  useEffect(() => {
    if (contact?.id) {
      reset({
        fullname: contact?.fullname ?? '',
        email: contact?.email ?? '',
        organizationId: contact?.organizationId ?? '',
        specificOrganizationName: contact?.specificOrganizationName ?? '',

        scientificProfile: {
          academicRank: contact?.scientificProfile?.academicRank ?? null,
          academicRankYear: contact?.scientificProfile?.academicRankYear ?? null,
          academicDegree: contact?.scientificProfile?.academicDegree ?? null,
          academicDegreeYear: contact?.scientificProfile?.academicDegreeYear ?? null,
          officeAddress: contact?.scientificProfile?.officeAddress ?? '',
          officePhone: contact?.scientificProfile?.officePhone ?? '',
          officeEmail: contact?.scientificProfile?.officeEmail ?? '',
          personalAddress: contact?.scientificProfile?.personalAddress ?? '',
          personalPhone: contact?.scientificProfile?.personalPhone ?? '',
          personalEmail: contact?.scientificProfile?.personalEmail ?? '',
          gender: contact?.scientificProfile?.gender ?? '',
          dob: contact?.scientificProfile?.dob ? new Date(contact?.scientificProfile?.dob) : null,
          citizenId: contact?.scientificProfile?.citizenId ?? '',
          dateOfIssue: contact?.scientificProfile?.dateOfIssue
            ? new Date(contact?.scientificProfile?.dateOfIssue)
            : null,
          placeOfIssue: contact?.scientificProfile?.placeOfIssue ?? '',
          ORCIDcode: contact?.scientificProfile?.ORCIDcode ?? '',

          taxIdentificationNumber: contact?.scientificProfile?.taxIdentificationNumber ?? '',
          bankAccount: contact?.scientificProfile?.bankAccount ?? '',
          bank: contact?.scientificProfile?.bank ?? '',
          bankBranch: contact?.scientificProfile?.bankBranch ?? '',

          faculty: contact?.scientificProfile?.faculty ?? '',
          department: contact?.scientificProfile?.department ?? '',
          laboratory: contact?.scientificProfile?.laboratory ?? '',
          position: contact?.scientificProfile?.position ?? '',
          researchLines: contact?.scientificProfile?.researchLines ?? [],
        },
      });
    }
  }, [contact]);

  const onSubmit = async (data) => {
    const { scientificProfile, ...contactData } = data;

    const scientificProfileData = {
      ...scientificProfile,
      academicRankYear: data?.scientificProfile?.academicRankYear ?? null,
      academicDegreeYear: data?.scientificProfile?.academicDegreeYear ?? null,
      citizenId: data?.scientificProfile?.citizenId
        ? data?.scientificProfile?.citizenId?.replace(/\s/g, '')
        : '',
      taxIdentificationNumber: data?.scientificProfile?.taxIdentificationNumber
        ? data?.scientificProfile?.taxIdentificationNumber?.replace(/\s/g, '')
        : '',
      bankAccount: data?.scientificProfile?.bankAccount
        ? data?.scientificProfile?.bankAccount?.replace(/\s/g, '')
        : '',
      officePhone: data?.scientificProfile?.officePhone
        ? data?.scientificProfile?.officePhone?.replace(/\s/g, '')
        : '',
      personalPhone: data?.scientificProfile?.personalPhone
        ? data?.scientificProfile?.personalPhone?.replace(/\s/g, '')
        : '',
      dob: data?.scientificProfile?.dob
        ? getEndingTimeOfDate(new Date(data?.scientificProfile?.dob))
        : null,
      dateOfIssue: data?.scientificProfile?.dateOfIssue
        ? getEndingTimeOfDate(new Date(data?.scientificProfile?.dateOfIssue))
        : null,
      researchLines: data?.scientificProfile?.researchLines ?? [],
    };

    mutateUpdateContact(contactData, {
      onSuccess: () => {
        mutateUpdateScientificProfile(scientificProfileData, {
          onSuccess: () => {
            toast(TOAST.SUCCESS, t('userProfile.updateSuccess'));
            // Set new user profile
            const oldProfile = TokenService.getUser();
            const newProfile = { ...oldProfile, fullname: data?.fullname };
            delete newProfile.organizationId;
            TokenService.setUser(newProfile);

            refetch();
            setVisible(false);
          },
          onError: (error) => {
            toast(TOAST.ERROR, mapError(t, error));
          },
        });
      },
      onError: (error) => {
        toast(TOAST.ERROR, mapError(t, error));
      },
    });
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
    }
  };
  // #endregion Event

  return (
    <Dialog
      header={t('userProfile.editProfile')}
      visible={visible}
      style={{ maxWidth: '700px' }}
      onHide={() => {
        reset();
        setVisible(false);
      }}
      draggable={false}
    >
      <div className="relative">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={checkKeyDown}
          className="p-fluid"
          autoComplete="off"
        >
          <TextInput
            label={`${t('userProfile.fullname')} ${t('signup.noteFullName')}`}
            name="fullname"
            autoFocus
            control={control}
            errors={errors}
            isRequired
          />

          <div className="grid">
            <div className="col-6">
              <Select
                label={t('userProfile.academicRank')}
                name="scientificProfile.academicRank"
                control={control}
                errors={errors}
                options={getAcademicRanks(t)}
                inputField="value"
              />
            </div>
            <div className="col-6">
              <NumberInput
                label={t('userProfile.academicRankYear')}
                name="scientificProfile.academicRankYear"
                min={0}
                max={new Date().getFullYear()}
                control={control}
                errors={errors}
              />
            </div>
          </div>

          <div className="grid">
            <div className="col-6">
              <Select
                label={t('userProfile.academicDegree')}
                name="scientificProfile.academicDegree"
                control={control}
                errors={errors}
                options={getAcademicDegrees(t)}
                inputField="value"
                isRequired
              />
            </div>
            <div className="col-6">
              <NumberInput
                label={t('userProfile.academicDegreeYear')}
                name="scientificProfile.academicDegreeYear"
                min={0}
                max={new Date().getFullYear()}
                control={control}
                errors={errors}
              />
            </div>
          </div>

          <div className="grid">
            <div className="col-6">
              <Select
                label={t('userProfile.basic.gender')}
                name="scientificProfile.gender"
                options={genders}
                inputField="value"
                control={control}
                errors={errors}
              />
            </div>
            <div className="col-6">
              <DatePicker
                label={t('userProfile.basic.dob')}
                name="scientificProfile.dob"
                control={control}
                errors={errors}
              />
            </div>
          </div>

          <div className="grid">
            <div className="col-6">
              <MaskInput
                label={t('userProfile.basic.citizenId')}
                name="scientificProfile.citizenId"
                control={control}
                errors={errors}
                isRequired
                mask={''.padStart(20, '9')}
              />
            </div>
            <div className="col-6">
              <DatePicker
                label={t('userProfile.basic.dateOfIssue')}
                name="scientificProfile.dateOfIssue"
                isRequired
                control={control}
                errors={errors}
              />
            </div>
          </div>

          <div className="mb-3">
            <TextareaInput
              label={t('userProfile.basic.placeOfIssue')}
              name="scientificProfile.placeOfIssue"
              isRequired
              control={control}
              errors={errors}
            />
          </div>

          <MaskInput
            label={t('userProfile.finance.taxIdentificationNumber')}
            name="scientificProfile.taxIdentificationNumber"
            isRequired
            control={control}
            errors={errors}
            mask={''.padStart(20, '9')}
          />

          <div className="grid">
            <div className="col-4">
              <MaskInput
                label={t('userProfile.finance.bankAccount')}
                name="scientificProfile.bankAccount"
                isRequired
                control={control}
                errors={errors}
                mask={''.padStart(30, '9')}
              />
            </div>
            <div className="col-8">
              <TextareaInput
                label={t('userProfile.finance.bank')}
                name="scientificProfile.bank"
                isRequired
                control={control}
                errors={errors}
              />
            </div>
          </div>

          <div className="mb-3">
            <TextareaInput
              label={t('userProfile.finance.bankBranch')}
              name="scientificProfile.bankBranch"
              control={control}
              errors={errors}
            />
          </div>

          <div className="mb-3">
            <TextareaInput
              label={t('userProfile.contact.ORCIDcode')}
              name="scientificProfile.ORCIDcode"
              control={control}
              errors={errors}
            />
          </div>

          <div>
            <label>{t('scientificProfile.I.workingAddress')}</label>
            <div className="mt-2">
              <label className="underline font-italic mb-2 block">
                {t('scientificProfile.I.institute')}{' '}
                <span className="text-red-500" style={{ fontWeight: 900 }}>
                  *
                </span>
              </label>
              <Select
                name="organizationId"
                options={organizations ?? []}
                control={control}
                errors={errors}
                isRequired
              />
              {currentOrganization?.englishName === 'Other' && (
                <>
                  <label className="font-italic mb-2 block">
                    <span className="underline">
                      {t('scientificProfile.I.specificInstituteName')}
                    </span>
                    <span className="text-red-500" style={{ fontWeight: 900 }}>
                      *
                    </span>
                  </label>
                  <TextareaInput
                    name="specificOrganizationName"
                    control={control}
                    errors={errors}
                    isRequired
                  />
                </>
              )}
            </div>
            <div className="mt-2">
              <label className="underline font-italic mb-2 block">
                {t('scientificProfile.I.faculty')}
              </label>
              <TextareaInput name="scientificProfile.faculty" control={control} errors={errors} />
            </div>
            <div className="mt-2">
              <label className="underline font-italic mb-2 block">
                {t('scientificProfile.I.department')}
              </label>
              <TextareaInput
                name="scientificProfile.department"
                control={control}
                errors={errors}
              />
            </div>
            <div className="mt-2">
              <label className="underline font-italic mb-2 block">
                {t('scientificProfile.I.laboratory')}
              </label>
              <TextareaInput
                name="scientificProfile.laboratory"
                control={control}
                errors={errors}
              />
            </div>
            <div className="mt-2">
              <label className="underline font-italic mb-2 block">
                {t('scientificProfile.I.position')}
              </label>
              <TextareaInput name="scientificProfile.position" control={control} errors={errors} />
            </div>
          </div>

          <div className="form-table mt-3">
            <label className="inline-block mb-2">{t('userProfile.contact.label')}</label>
            <table className="mb-2">
              <thead>
                <tr className="font-bold">
                  <th className="text-center" style={{ minWidth: 100, width: 120 }}>
                    {' '}
                  </th>
                  <th className="text-center" style={{ width: '45%' }}>
                    {t('userProfile.contact.office')}
                  </th>
                  <th className="text-center float" style={{ width: '45%' }}>
                    {t('userProfile.contact.personal')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center font-bold">
                    {t('scientificProfile.I.contact.address')}
                  </td>
                  <td>
                    <TextareaInput
                      name="scientificProfile.officeAddress"
                      control={control}
                      errors={errors}
                    />
                  </td>
                  <td>
                    <TextareaInput
                      name="scientificProfile.personalAddress"
                      control={control}
                      errors={errors}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center font-bold">
                    {t('scientificProfile.I.contact.phone')}
                  </td>
                  <td>
                    <MaskInput
                      name="scientificProfile.officePhone"
                      control={control}
                      errors={errors}
                      mask={''.padStart(10, '9')}
                    />
                  </td>
                  <td>
                    <MaskInput
                      name="scientificProfile.personalPhone"
                      control={control}
                      errors={errors}
                      mask={''.padStart(10, '9')}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-center font-bold">Email</td>
                  <td>
                    <TextInput
                      name="scientificProfile.officeEmail"
                      control={control}
                      errors={errors}
                    />
                  </td>
                  <td>
                    <TextInput
                      name="scientificProfile.personalEmail"
                      control={control}
                      errors={errors}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-3">
            <label className="inline-block mb-2">
              {t('projectProposal.general.a9.fields.researchLines')}
            </label>
            <ResearchLinesTable
              name="scientificProfile.researchLines"
              control={control}
              errors={errors}
            />
          </div>

          <div className="flex flex-column sm:flex-row align-items-center sm:column-gap-3">
            <Button
              label={t('projectProposal.cancel')}
              type="button"
              className="mt-4 w-full sm:w-19rem"
              severity="danger"
              onClick={() => {
                reset();
                setVisible(false);
              }}
            />
            <Button
              label={t('userProfile.editProfile')}
              icon="pi pi-pencil"
              className="bg-cyan-400 border-cyan-400 hover:bg-cyan-500 mt-2 sm:mt-4 w-full sm:w-19rem"
              type="submit"
              onClick={checkValidation}
              disabled={!Object.keys(dirtyFields).length > 0}
            />
          </div>
        </form>
        {(isUpdateContactLoading || isUpdateScientificProfileLoading) && <Loading />}
      </div>
    </Dialog>
  );
}

EditProfileForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contact: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};
