/* eslint-disable indent */
import {
  update as updateMidtermReview,
  getDetail,
} from 'apis/midtermReviews.api';
import { EditForm } from 'features/MidtermReview/components';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  useParams,
  useSearchParams,
} from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import { toast } from 'layout';
import { TOAST } from 'constant';
import Loading from 'components/Loading';
import ErrorDialog from 'components/ErrorDialog';

export default function MidtermReviewFormEdit() {
  const { t } = useTranslation();

  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');

  const [isVisibleErrorDialog, setIsVisibleDialogError] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: {
      errors,
      // , isValid
    },
  } = useForm({
    mode: 'onChange',
  });
  const { isDirty, dirtyFields } = useFormState({ control });

  const {
    mutate: mutateUpdate,
    error: errorRes,
    isError: isErrorRes,
    isLoading: isLoadingCreate,
  } = useMutation(updateMidtermReview);

  const {
    data: midtermReviewData,
    isLoading: isLoadingMidtermReview,
    refetch: refetchMidtermReview,
  } = useQuery(['midterm-review'], () => getDetail(councilMemberId, councilId, projectProposalId));

  const midtermReviews = useMemo(() => midtermReviewData?.data?.midtermReview, [midtermReviewData]);

  useEffect(() => {
    setValue('reviewResearchPlan', midtermReviews?.reviewResearchPlan);
    setValue('reviewProduct', midtermReviews?.reviewProduct);
    setValue('reviewProgress', midtermReviews?.reviewProgress);
    setValue('reviewExpenditureUsed', midtermReviews?.reviewExpenditureUsed);
    setValue('reviewContinueAbility', midtermReviews?.reviewContinueAbility);
    setValue('otherReview', midtermReviews?.otherReview);
    setValue('requestStop', midtermReviews?.requestStop);
    setValue('requestStopReason', midtermReviews?.requestStopReason);
    setValue('requestContinue', midtermReviews?.requestContinue);
    setValue('requestContinueModify', midtermReviews?.requestContinueModify);
    setValue('evaluate.evaluateResearchPlan.isQualified', midtermReviews?.evaluateResearchPlan);
    setValue('evaluate.evaluateResearchResult.isQualified', midtermReviews?.evaluateResearchResult);
    setValue('evaluate.evaluateProgress.isQualified', midtermReviews?.evaluateProgress);
    setValue(
      'evaluate.evaluateExpenditureUsed.isQualified',
      midtermReviews?.evaluateExpenditureUsed
    );
    setValue('evaluate.evaluateGeneral.isQualified', midtermReviews?.evaluateGeneral);
    setValue('evaluate.evaluateResearchPlan.isNotQualified', !midtermReviews?.evaluateResearchPlan);
    setValue(
      'evaluate.evaluateResearchResult.isNotQualified',
      !midtermReviews?.evaluateResearchResult
    );
    setValue('evaluate.evaluateProgress.isNotQualified', !midtermReviews?.evaluateProgress);
    setValue(
      'evaluate.evaluateExpenditureUsed.isNotQualified',
      !midtermReviews?.evaluateExpenditureUsed
    );
    setValue('evaluate.evaluateGeneral.isNotQualified', !midtermReviews?.evaluateGeneral);
  }, [midtermReviews]);

  const onSubmit = async (data) => {
    const evaluatesReview = {};

    Object.keys(data?.evaluate).forEach((key) => {
      evaluatesReview[key] = data?.evaluate[key].isQualified ?? false;
    });

    const submitData = {
      // ...defaultMidtermReview,
      id: midtermReviews?.id,
      councilMemberId,
      projectProposalId,
      councilId,
      ...evaluatesReview,
      requestStop: data?.requestStop,
      requestStopReason: data?.requestStopReason,
      requestContinue: data?.requestContinue,
      requestContinueModify: data?.requestContinueModify,
      otherReview: data?.otherReview,
      reviewContinueAbility: data?.reviewContinueAbility,
      reviewExpenditureUsed: data?.reviewExpenditureUsed,
      reviewProgress: data?.reviewProgress,
      reviewProduct: data?.reviewProduct,
      reviewResearchPlan: data?.reviewResearchPlan,
    };

    mutateUpdate(submitData, {
      onSuccess: () => {
        reset({}, { keepValues: true });
        toast(TOAST.SUCCESS, t('council.review.updateSuccess'));
        refetchMidtermReview();
      },
      onError: () => {
        setIsVisibleDialogError(true);
      },
    });
  };

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('formLayout.action.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />
      <Button
        label={t('formLayout.action.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );
  // #endregion

  return (
    <>
      {isLoadingCreate && isLoadingMidtermReview && <Loading />}
      {isErrorRes && (
        <ErrorDialog
          title={t('council.review.update')}
          isError={isErrorRes}
          error={errorRes}
          visible={isVisibleErrorDialog}
          setVisible={setIsVisibleDialogError}
        />
      )}

      <ReactRouterPrompt when={isDirty && Object.keys(dirtyFields).length}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            visible={isActive}
            header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
            position="center"
            style={{ width: '300px' }}
            onHide={onCancel}
            draggable={false}
            footer={() => footerConfirmationPrompt(onConfirm, onCancel)}
          >
            <p className="text-lg py-3">{t('projectProposal.confirmationPromptMessage')}</p>
          </Dialog>
        )}
      </ReactRouterPrompt>

      <EditForm
        isLoadingCreate={isLoadingCreate}
        isErrorRes={isErrorRes}
        errorRes={errorRes}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        control={control}
        watch={watch}
        setValue={setValue}
        getValues={getValues}
        isDirty={isDirty}
        dirtyFields={dirtyFields}
        errors={errors}
        isValid
        reset={reset}
        isVisibleErrorDialog={isVisibleErrorDialog}
        setIsVisibleDialogError={setIsVisibleDialogError}
      />
    </>
  );
}
