import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { classNames } from 'primereact/utils';

export default function Expenditure({ data }) {
  // #region Data
  const { t } = useTranslation();
  // #endregion Data

  // #region Function
  const expenditureFromVNUHCMTotal = useMemo(() => {
    if (data?.proposedExpenditureSummaries === undefined) return 0;
    return data?.proposedExpenditureSummaries?.reduce((total, item) => {
      if (item.proposedExpenditureType?.subProposedExpenditureTypeId === null) {
        return total + (item?.expenditureFromVNUHCM ?? 0);
      }
      return total;
    }, 0);
  });
  const expenditureFromFundingTotal = useMemo(() => {
    if (data?.proposedExpenditureSummaries === undefined) return 0;
    return data?.proposedExpenditureSummaries?.reduce((total, item) => {
      if (item.proposedExpenditureType?.subProposedExpenditureTypeId === null) {
        return total + (item?.expenditureFromFunding ?? 0);
      }
      return total;
    }, 0);
  });
  // #endregion

  return (
    <div>
      <div className="font-semibold ml-5 mb-2">{t('projectProposal.expenditure.a5.label')}: </div>
      <div
        className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines"
        data-scrollselectors=".p-datatable-wrapper"
      >
        <div className="p-datatable-wrapper">
          <table className="p-datatable-table">
            <thead className="p-datatable-thead">
              <tr>
                <th style={{ minWidth: '12rem', textAlign: 'center' }}>
                  <span className="p-column-title">
                    {t('projectProposal.expenditure.a5.fields.term')}
                  </span>
                </th>
                <th style={{ minWidth: '12rem', textAlign: 'center' }}>
                  <span className="p-column-title">
                    {t('projectProposal.expenditure.a5.fields.from_vnu')}
                  </span>
                </th>
                <th style={{ minWidth: '12rem', textAlign: 'center' }}>
                  <span className="p-column-title">
                    {t('projectProposal.expenditure.a5.fields.from_funding')}
                  </span>
                </th>
                <th style={{ minWidth: '12rem', textAlign: 'center' }}>
                  <span className="p-column-title">
                    {t('projectProposal.expenditure.a5.fields.total')}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="p-datatable-tbody">
              {data?.proposedExpenditureSummaries.map((proposedExpenditure, index) => (
                <tr
                  key={proposedExpenditure?.id || index}
                  className={classNames({
                    'font-bold':
                      proposedExpenditure?.proposedExpenditureType?.subProposedExpenditureTypeId ===
                      null,
                  })}
                >
                  <td>
                    {t('lang') === 'vi'
                      ? proposedExpenditure?.proposedExpenditureType?.name
                      : proposedExpenditure?.proposedExpenditureType?.englishName}
                  </td>
                  <td className="text-right">
                    {(proposedExpenditure?.expenditureFromVNUHCM || '')?.toLocaleString('vi')}
                  </td>
                  <td className="text-right">
                    {(proposedExpenditure?.expenditureFromFunding || '')?.toLocaleString('vi')}
                  </td>
                  <td className="text-right">
                    {(
                      (proposedExpenditure.expenditureFromVNUHCM || 0) +
                        (proposedExpenditure?.expenditureFromFunding || 0) || ''
                    )?.toLocaleString('vi')}
                  </td>
                </tr>
              ))}

              <tr className="font-bold">
                <td>{t('projectProposal.expenditure.a5.total')}</td>
                <td className="text-right">
                  {(expenditureFromVNUHCMTotal || '')?.toLocaleString('vi')}
                </td>
                <td className="text-right">
                  {(expenditureFromFundingTotal || '')?.toLocaleString('vi')}
                </td>
                <td className="text-right">
                  {(
                    (expenditureFromVNUHCMTotal || 0) + (expenditureFromFundingTotal || 0) || ''
                  )?.toLocaleString('vi')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

Expenditure.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};
