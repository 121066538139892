import { yupResolver } from '@hookform/resolvers/yup';
import { TextareaInput, TextInput } from 'components/FormControl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import hardProductSchema from '../validation';

const formOptions = { resolver: yupResolver(hardProductSchema) };

const HardProductDialog = forwardRef((props, ref) => {
  // #region Data
  const { t } = useTranslation();
  const toast = useRef(null);

  const [hardProductControl, setHardProductControl] = useState();
  const [visible, setVisible] = useState(false);

  const {
    control,
    getValues,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({ ...formOptions, mode: 'onChange' });
  // #endregion Data

  // #region Event
  const showError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: t('toast.error'),
      detail: message,
      life: 4000,
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      open: (_hardProductControl) => {
        setHardProductControl(_hardProductControl);
        setVisible(true);
        if (_hardProductControl.type === 'INSERT') {
          reset({});
        } else if (_hardProductControl.type === 'UPDATE') {
          reset(_hardProductControl.value);
        }
      },
    }),
    []
  );

  const handleAddHardProduct = async () => {
    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      showError(t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = getValues();

    const { insert, update, index, type } = hardProductControl;

    if (type === 'INSERT' && insert) {
      insert(index, data);
    } else if (type === 'UPDATE' && update) {
      update(index, data);
    }
    reset({});
    setVisible(false);
  };

  // #endregion Event

  return (
    <>
      <Dialog
        header={
          hardProductControl?.type === 'INSERT'
            ? t('projectProposal.researchResult.a10_1.hardProduct.addHardProduct')
            : t('projectProposal.researchResult.a10_1.hardProduct.updateHardProduct')
        }
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        draggable={false}
        className="w-full sm:w-7 md:w-6 lg:w-5"
      >
        <div className="grid p-fluid">
          <div className="col-12">
            <TextareaInput
              name="name"
              label={t('projectProposal.researchResult.a10_1.hardProduct.fields.productName')}
              isRequired
              control={control}
              errors={errors}
            />
          </div>
          <div className="col-12">
            <TextareaInput
              name="qualityCriteria"
              label={t('projectProposal.researchResult.a10_1.hardProduct.fields.qualityCriteria')}
              isRequired
              control={control}
              errors={errors}
            />
          </div>
          <div className="col-12">
            <TextInput
              name="measure"
              label={t('projectProposal.researchResult.a10_1.hardProduct.fields.measure')}
              isRequired
              control={control}
              errors={errors}
            />
          </div>
          <div className="col-12">
            <TextareaInput
              name="evaluativeCriteria"
              label={t('projectProposal.researchResult.a10_1.hardProduct.fields.evaluativeCriteria')}
              isRequired
              control={control}
              errors={errors}
            />
          </div>
          <div className="col-12">
            <TextareaInput
              name="domesticModelQuality"
              label={t(
                'projectProposal.researchResult.a10_1.hardProduct.fields.similarDomesticModelQualityLevel'
              )}
              control={control}
              errors={errors}
            />
          </div>
          <div className="col-12">
            <TextareaInput
              name="internationalModelQuality"
              label={t(
                'projectProposal.researchResult.a10_1.hardProduct.fields.similarInternationalModelQualityLevel'
              )}
              control={control}
              errors={errors}
            />
          </div>
          <div className="col-12">
            <TextareaInput
              name="expectedQuantity"
              label={t('projectProposal.researchResult.a10_1.hardProduct.fields.expectedQuantity')}
              control={control}
              errors={errors}
            />
          </div>
        </div>

        <div className="flex justify-content-end mt-4">
          <Button
            label={t('formLayout.action.cancel')}
            severity="danger"
            onClick={() => {
              setVisible(false);
            }}
            className="mr-1 w-8rem"
          />
          <Button
            label={
              hardProductControl?.type === 'INSERT'
                ? t('formLayout.action.add')
                : t('formLayout.action.update')
            }
            type="submit"
            severity="info"
            onClick={handleAddHardProduct}
            className="w-8rem"
            disabled={!Object.keys(dirtyFields)?.length}
          />
        </div>
      </Dialog>
      <Toast ref={toast} />
    </>
  );
});

HardProductDialog.propTypes = {};

export default HardProductDialog;
