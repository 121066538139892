/* eslint-disable react/prop-types */
import './style.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  getGeneralProjectProposalInfo,
  getReviewerInfo,
  downloadPdfTemplate,
  downloadDocxTemplate,
  downloadPdfFile,
  downloadDocxFile,
} from 'apis/midtermReviews.api';

import ErrorDialog from 'components/ErrorDialog';
import { EditorInput, Tickbox } from 'components/FormControl';
import Loading from 'components/Loading';

import { TOAST } from 'constant';
import { toast } from 'layout';

import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
// import { useForm, useFormState } from 'react-hook-form';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle, import/named
import { DetailInfo, EvaluationForm } from '..';

export default function CreateForm({
  isLoadingCreate,
  isErrorRes,
  errorRes,
  handleSubmit,
  onSubmit,
  control,
  setValue,
  getValues,
  isDirty,
  dirtyFields,
  errors,
  isValid,
  isVisibleErrorDialog,
  setIsVisibleDialogError,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');
  const downloadTemplateButtonRef = useRef();
  const downloadButtonRef = useRef();
  // #region Data
  // general info projectProposal
  const { data: dataGeneralProjectProposalInfo, isLoading: isLoadingProjectProposal } = useQuery(
    ['midterm-review', 'project-proposal', councilId, projectProposalId],
    ({ queryKey: [, , _councilId, _projectProposalId] }) =>
      getGeneralProjectProposalInfo(_councilId, _projectProposalId)
  );
  const generalProjectProposalInfo = useMemo(
    () => dataGeneralProjectProposalInfo?.data,
    [dataGeneralProjectProposalInfo]
  );

  // reviewer info
  const { data: dataReviewerInfo, isLoading: isLoadingReviewer } = useQuery(
    ['midterm-review', 'reviewer', councilId, projectProposalId],
    ({ queryKey: [, , _councilId, _projectProposalId] }) =>
      getReviewerInfo(_councilId, _projectProposalId)
  );
  const reviewerInfo = useMemo(() => dataReviewerInfo?.data, [dataReviewerInfo]);

  // #end region Data

  const [requestState, setRequestState] = useState({ requestStop: false, requestContinue: false });

  const onIngredientsChange = (name) => {
    if (name === 'requestStop') {
      setRequestState({ requestStop: true, requestContinue: false });
    } else {
      setRequestState({ requestStop: false, requestContinue: true });
    }
  };

  useEffect(() => {
    setValue('requestStop', requestState.requestStop);
    setValue('requestContinue', requestState.requestContinue);
  }, [requestState]);
  // doan code nay danh cho C. ket luan
  // #region Events

  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
    }
  };

  const {
    refetch: handleDownloadPdfTemplate,
    isLoading: isDownloadPdfTemplateLoading,
    isFetching: isDownloadPdfTemplateFetching,
  } = useQuery(
    ['downloadPdfTemplateMidtermReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M06 - Phieu nhan xet danh gia giua ky.pdf';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxTemplate,
    isLoading: isDownloadDocxTemplateLoading,
    isFetching: isDownloadDocxTemplateFetching,
  } = useQuery(
    ['downloadDocxTemplateMidtermReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M06 - Phieu nhan xet danh gia giua ky.docx';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadPdfFile,
    isLoading: isDownloadPdfFileLoading,
    isFetching: isDownloadPdfFileFetching,
  } = useQuery(
    ['downloadPdfMidtermReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Phieu nhan xet danh gia giua ky.pdf';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxFile,
    isLoading: isDownloadDocxFileLoading,
    isFetching: isDownloadDocxFileFetching,
  } = useQuery(
    ['downloadDocxMidtermReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);
          const filename = 'Phieu nhan xet danh gia giua ky.docx';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );

  return (
    <div className="bg-white p-5">
      {(isLoadingCreate || isLoadingReviewer || isLoadingProjectProposal) && <Loading />}
      {isErrorRes && (
        <ErrorDialog
          title={t('council.review.create')}
          isError={isErrorRes}
          error={errorRes}
          visible={isVisibleErrorDialog}
          setVisible={setIsVisibleDialogError}
        />
      )}

      <div className="flex justify-content-end">
        <Button
          label={t('council.review.exportFile')}
          icon={`pi ${
            isDownloadPdfFileLoading ||
            isDownloadPdfFileFetching ||
            isDownloadDocxFileLoading ||
            isDownloadDocxFileFetching
              ? 'pi-spin pi-spinner'
              : 'pi-file'
          }`}
          className="block w-auto h-3rem border-none"
          severity="info"
          onClick={(event) => downloadButtonRef.current.toggle(event)}
          disabled={
            isDownloadPdfFileLoading ||
            isDownloadPdfFileFetching ||
            isDownloadDocxFileLoading ||
            isDownloadDocxFileFetching
          }
        />
        <Button
          // type="button"
          label={t('council.review.downloadTemplate')}
          icon={`pi ${
            isDownloadPdfTemplateLoading ||
            isDownloadPdfTemplateFetching ||
            isDownloadDocxTemplateLoading ||
            isDownloadDocxTemplateFetching
              ? 'pi-spin pi-spinner'
              : 'pi-file'
          }`}
          className="block w-auto h-3rem border-none ml-1"
          severity="info"
          onClick={(event) => downloadTemplateButtonRef.current.toggle(event)}
          disabled={
            isDownloadPdfTemplateLoading ||
            isDownloadPdfTemplateFetching ||
            isDownloadDocxTemplateLoading ||
            isDownloadDocxTemplateFetching
          }
        />
      </div>
      <Menu
        ref={downloadButtonRef}
        model={[
          {
            label: t('council.review.exportPdf'),
            icon: 'pi pi-file-pdf',
            command: handleDownloadPdfFile,
            disabled: isDownloadPdfFileLoading || isDownloadPdfFileFetching,
          },
          {
            label: t('council.review.exportDocx'),
            icon: 'pi pi-file-word',
            command: handleDownloadDocxFile,
            disabled: isDownloadDocxFileLoading || isDownloadDocxFileFetching,
          },
        ]}
        popup
      />
      <Menu
        ref={downloadTemplateButtonRef}
        model={[
          {
            label: t('council.review.downloadPdfTemplate'),
            icon: 'pi pi-file-pdf',
            command: handleDownloadPdfTemplate,
            disabled: isDownloadPdfTemplateLoading || isDownloadPdfTemplateFetching,
          },
          {
            label: t('council.review.downloadDocxTemplate'),
            icon: 'pi pi-file-word',
            command: handleDownloadDocxTemplate,
            disabled: isDownloadDocxTemplateLoading || isDownloadDocxTemplateFetching,
          },
        ]}
        popup
      />

      <div className="p-fluid specialize-review-create">
        <div className="m-0 bg-white p-5 p-fluid">
          <h3 className="mb-5 text-4xl text-center">
            <span className="line-height-3 font-semibold">{t('council.m06.title')} </span>
            <br />
            <span className="font-normal">{t('council.m06.sciTechProject')}</span>
          </h3>
        </div>
        <DetailInfo
          generalProjectProposalInfo={generalProjectProposalInfo}
          reviewerInfo={reviewerInfo}
        />
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-8 relative">
            <h4>{t('council.m06.a.label')}</h4>
            <h5>
              {t('council.m06.a.a1')}
              <span className="text-red-500" style={{ fontWeight: 900 }}>
                {' '}
                *
              </span>
            </h5>
            <div className="mt-3">
              <EditorInput name="reviewResearchPlan" control={control} errors={errors} />
            </div>
            <h5>
              {t('council.m06.a.a2')}
              <span className="text-red-500" style={{ fontWeight: 900 }}>
                {' '}
                *
              </span>
            </h5>
            <div className="mt-3">
              <EditorInput name="reviewProduct" isRequired control={control} errors={errors} />
            </div>
            <h5>
              {t('council.m06.a.a3')}
              <span className="text-red-500" style={{ fontWeight: 900 }}>
                {' '}
                *
              </span>
            </h5>
            <div className="mt-3">
              <EditorInput name="reviewProgress" isRequired control={control} errors={errors} />
            </div>
            <h5>
              {t('council.m06.a.a4')}
              <span className="text-red-500" style={{ fontWeight: 900 }}>
                {' '}
                *
              </span>
            </h5>
            <div className="mt-3">
              <EditorInput
                name="reviewExpenditureUsed"
                isRequired
                control={control}
                errors={errors}
              />
            </div>
            <h5>
              {t('council.m06.a.a5')}
              <span className="text-red-500" style={{ fontWeight: 900 }}>
                {' '}
                *
              </span>
            </h5>
            <div className="mt-3">
              <EditorInput
                name="reviewContinueAbility"
                isRequired
                control={control}
                errors={errors}
              />
            </div>
            <h5>
              {t('council.m06.a.a6')}
              <span className="text-red-500" style={{ fontWeight: 900 }}>
                {' '}
                *
              </span>
            </h5>
            <div className="mt-3">
              <EditorInput name="otherReview" isRequired control={control} errors={errors} />
            </div>
            <h5>
              {/* {t('council.m01.a.a4.label')} */}
              {/* <span className="text-red-500" style={{ fontWeight: 900 }}>
                {' '}
                *
              </span> */}
            </h5>

            <h5>
              {t('council.m06.b.lable')}
              {/* <span className="text-red-500" style={{ fontWeight: 900 }}>
                {' '}
                *
              </span> */}
            </h5>
            <EvaluationForm
              setValue={setValue}
              getValues={getValues}
              control={control}
              errors={errors}
            />
            <h5>
              {t('council.m06.c.conclusion')}{' '}
              {/* <span className="text-red-500" style={{ fontWeight: 900 }}>
                {' '}
                *
              </span> */}
            </h5>

            {/* C */}
            <div className="flex flex-column justify-content-center gap-2">
              <div className="flex align-items-center">
                <Tickbox
                  icon="pi pi-check"
                  name="requestStop"
                  control={control}
                  errors={errors}
                  onCustomCheck={onIngredientsChange}
                />
                <label htmlFor="ingredient1" className="ml-2">
                  {t('council.m06.c.stop')}
                </label>
              </div>
              <label htmlFor="ingredient1" className="ml-2 mt-2">
                {t('council.m06.c.reason')}
              </label>
              <div className="mt-3">
                <EditorInput
                  name="requestStopReason"
                  isRequired
                  control={control}
                  errors={errors}
                />
              </div>
              <div className="flex align-items-center">
                <Tickbox
                  icon="pi pi-check"
                  name="requestContinue"
                  control={control}
                  errors={errors}
                  onCustomCheck={onIngredientsChange}
                />
                <label htmlFor="ingredient2" className="ml-2">
                  {t('council.m06.c.continute')}
                </label>
              </div>
              <div className="mt-3">
                <EditorInput
                  name="requestContinueModify"
                  isRequired
                  control={control}
                  errors={errors}
                />
              </div>
            </div>

            {/* // day la footer */}
            <div className="grid text-lg mt-8">
              <div className="col-7 px-8">
                <span>{t('council.m01.c.commitment')}</span>
              </div>
              <div className="col-5 text-center">
                <p className="mb-0">{t('signature.date')}</p>
                <p className="font-semibold mb-0">{t('signature.reviewer')}</p>
                <p className="mb-7">{t('signature.fullnameAndSignature')}</p>
                <p className="font-bold">{reviewerInfo?.fullname}</p>
              </div>
            </div>

            <div className="flex justify-content-end mt-8">
              <Button
                label={t('formLayout.action.close')}
                severity="danger"
                type="button"
                // eslint-disable-next-line quotes
                onClick={() => navigate(`/project-proposal-defense`)}
                className="mr-2 w-8rem"
              />
              <Button
                label={t('formLayout.action.save')}
                severity="info"
                type="submit"
                className="w-8rem"
                onClick={checkValidation}
                disabled={!(isDirty && Object.keys(dirtyFields).length)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

CreateForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  isLoadingCreate: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isErrorRes: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  errorRes: PropTypes.shape({}),
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  watch: PropTypes.func,
  // setValue: PropTypes.func.isRequired,
  // getValues: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  dirtyFields: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  isValid: PropTypes.bool.isRequired,
  isVisibleErrorDialog: PropTypes.bool.isRequired,
  setIsVisibleDialogError: PropTypes.func.isRequired,
};

CreateForm.defaultProps = {
  watch: null,
};
