/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';
import { TIMEZONE_VN } from 'constant';

function GeneralInfoCheckResult({ owner, generalInfoData }) {
  const { t } = useTranslation();
  return (
    <>
      {/* A1 */}
      <h4 className="mb-2">A1. {t('checkResult.A.A1')}</h4>
      <div className="ml-5 text-lg">
        <div className="flex mb-1">
          <div className="mr-2 font-semibold">
            {t('projectProposal.general.a1.vietnameseName')}:
          </div>
          <div className="flex-1">{generalInfoData?.vietnameseName}</div>
        </div>
        <div className="flex mb-1">
          <div className="mr-2 font-semibold">{t('projectProposal.general.a1.englishName')}: </div>
          <div className="flex-1">{generalInfoData?.englishName}</div>
        </div>
      </div>
      {/* A2 */}
      <div className="flex text-lg mt-2 flex-column">
        <h4>A2. {t('checkResult.A.A2')}</h4>
        <div className="flex ml-5 flex-column">
          <div className="mb-2">
            <span className="font-semibold">{t('projectProposal.general.a6.owner.name')}: </span>
            <span>{owner?.fullname ?? generalInfoData?.owner?.fullname}</span>
          </div>
          <div className="flex flex-wrap">
            <div className="w-30rem mb-2">
              <span className="font-semibold">{t('projectProposal.general.a6.owner.dob')}: </span>
              <span>
                {owner?.dob
                  ? new Date(owner?.dob).toLocaleDateString('en-GB', { timeZone: TIMEZONE_VN })
                  : null}
              </span>
            </div>
            <div className="mb-2">
              <span className="font-semibold">{t('userProfile.basic.gender')}: </span>
              <span>{owner.gender}</span>
            </div>
          </div>

          <div className="flex flex-wrap">
            <div className="mb-2">
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.workplace')}:{' '}
              </span>
              <span>{generalInfoData?.workplace}</span>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-30rem mb-2">
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.mobile')}:{' '}
              </span>
              <span>{owner?.mobile ?? generalInfoData?.mobile}</span>
            </div>
            <div className="mb-2">
              <span className="font-semibold">Email: </span>
              <span>{owner?.email ?? generalInfoData?.email}</span>
            </div>
          </div>
        </div>
      </div>

      {/* A3 */}
      <div className="flex text-lg mt-2 flex-column">
        <h4>A3. {t('checkResult.A.A3')}</h4>
        {generalInfoData?.projectProposalOrganizations
          ?.filter((org) => org.type === 'LEAD')
          ?.map((org) => (
            <div className="flex ml-5 flex-column" key={org.id}>
              <div className="mb-2">
                <span className="font-semibold">
                  {t('projectProposal.general.organization.name')}:{' '}
                </span>
                <span>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {org?.englishName === 'Other'
                    ? org?.name
                    : t('lang') === 'vi'
                    ? org?.name
                    : org?.englishName}
                </span>
              </div>
              <div className="mb-2">
                <span className="font-semibold">
                  {t('projectProposal.general.organization.chief')}:{' '}
                </span>
                <span>{org?.chief}</span>
              </div>
              <div className="flex flex-wrap">
                <div className="w-30rem mb-2">
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.phone')}:{' '}
                  </span>
                  <span>{org?.phone}</span>
                </div>
                <div className="w-20rem mb-2">
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.fax')}:{' '}
                  </span>
                  <span>{org?.fax}</span>
                </div>
              </div>
              <div className="mb-2">
                <span className="font-semibold">
                  {t('projectProposal.general.organization.email')}:{' '}
                </span>
                <span>{org?.email}</span>
              </div>
              <div className="flex flex-wrap">
                <div className="w-30rem mb-2">
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.bankAccount')}:{' '}
                  </span>
                  <span>{org?.bankAccount}</span>
                </div>
                <div className="mb-2">
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.bank')}:{' '}
                  </span>
                  <span>{org?.bank}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

GeneralInfoCheckResult.propTypes = {
  owner: Proptypes.object,
  generalInfoData: Proptypes.object,
};

GeneralInfoCheckResult.defaultProps = {
  owner: {},
  generalInfoData: {},
};

export default GeneralInfoCheckResult;
