import './style.scss';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { maxPoints } from 'features/SpecializeReview/_constant';
import PropTypes from 'prop-types';

function EvaluationTable({ evaluations }) {
  const { t } = useTranslation();

  const totalEvaluation = useMemo(
    () =>
      evaluations?.reduce(
        (sum, cur) => sum + (cur?.point && !Number.isNaN(cur?.point) ? cur.point : 0),
        0
      ) ?? 0,
    [evaluations]
  );

  return (
    <div className="form-table text-lg">
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }} rowSpan={2}>
              {t('projectProposal.expenditure.a5.fields.order')}
            </th>
            <th style={{ width: 'auto', minWidth: '24rem', textAlign: 'center' }} rowSpan={2}>
              {t('council.m01.b.evaluationContent')}
            </th>
            <th className="text-center" style={{ minWidth: 150, width: 150 }}>
              {t('council.m01.b.evaluationMaxPoint')}
            </th>
            <th style={{ minWidth: '8rem', textAlign: 'center' }} colSpan={3}>
              {t('council.m01.b.evaluationPoint')}
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Content 1 */}
          <tr>
            <td className="text-center">1</td>
            <td className="font-semibold">{t('council.m01.b.evaluation1')}</td>
            <td className="text-center font-bold">
              {`${maxPoints.scoreCreativity}`} + {`${maxPoints.scoreSuitableness}`} ={' '}
              {`${maxPoints.scoreImportance}`}
            </td>
            <td className="text-center font-semibold">
              {evaluations?.[0]?.point ? evaluations?.[0]?.point.toLocaleString('vi') : 0}
            </td>
          </tr>
          <tr>
            <td className="text-center" rowSpan={2} />
            <td className="font-italic">- {t('council.m01.b.evaluation11')}</td>
            <td className="text-center font-italic">{`${maxPoints.scoreCreativity}`}</td>
            <td className="text-center font-italic">
              {evaluations?.[0]?.evaluations.creativity ?? ''}
            </td>
          </tr>
          <tr>
            <td className="font-italic">- {t('council.m01.b.evaluation12')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scoreSuitableness}`}</td>
            <td className="text-center font-italic">
              {evaluations?.[0]?.evaluations.suitability ?? ''}
            </td>
          </tr>
          {/* End Content 1 */}
          {/* Content 2 */}
          <tr>
            <td className="text-center">2</td>
            <td className="font-semibold">{t('council.m01.b.evaluation2')}</td>
            <td className="text-center font-bold">{`${maxPoints?.scoreQuality}`}</td>
            <td className="text-center font-semibold">
              {evaluations?.[1]?.point ? evaluations?.[1]?.point.toLocaleString('vi') : 0}
            </td>
          </tr>
          {/* End Content 2 */}
          {/* Content 3 */}
          <tr>
            <td className="text-center">3</td>
            <td className="font-semibold">{t('council.m01.b.evaluation3')}</td>
            <td className="text-center font-bold">
              {`${maxPoints.scoreAnnouncement}`} + {`${maxPoints.scoreTraining}`} ={' '}
              {`${maxPoints.scoreProduct}`}
            </td>
            <td className="text-center font-semibold">
              {evaluations?.[2]?.point ? evaluations?.[2]?.point.toLocaleString('vi') : 0}
            </td>
          </tr>
          <tr>
            <td className="text-center" rowSpan={2} />
            <td className="font-italic">- {t('council.m01.b.evaluation31')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scoreAnnouncement}`}</td>
            <td className="text-center font-italic">
              {evaluations?.[2]?.evaluations.scientificPulication ?? ''}
            </td>
          </tr>
          <tr>
            <td className="font-italic">- {t('council.m01.b.evaluation33')}</td>
            <td className="text-center font-italic">{`${maxPoints?.scoreTraining}`}</td>
            <td className="text-center font-italic">
              {evaluations?.[2]?.evaluations.training ?? ''}
            </td>
          </tr>
          {/* End Content 3 */}
          {/* Content 4 */}
          <tr>
            <td className="text-center">4</td>
            <td className="font-semibold">{t('council.m01.b.evaluation4')}</td>
            <td className="text-center font-bold">{`${maxPoints?.scoreCapability}`}</td>
            <td className="text-center font-semibold">
              {evaluations?.[3]?.point ? evaluations?.[3]?.point.toLocaleString('vi') : 0}
            </td>
          </tr>
          {/* End Content 4 */}
          {/* Content 5 */}
          <tr>
            <td className="text-center">5</td>
            <td className="font-semibold">
              {t('council.m01.b.evaluation5')}
              <div className="font-italic ml-3 text-red-600">
                - {t('council.m01.b.evaluation51')}
              </div>
              <div className="font-italic ml-3 text-red-600">
                - {t('council.m01.b.evaluation52')}
              </div>
              <div className="font-italic ml-3 text-red-600">
                - {t('council.m01.b.evaluation53')}
              </div>
            </td>
            <td className="text-center font-bold">{`${maxPoints?.scoreSubCriteria}`}</td>
            <td className="text-center font-semibold">
              {evaluations?.[4]?.point ? evaluations?.[4]?.point.toLocaleString('vi') : 0}
            </td>
          </tr>
          {/* End Content 5 */}
          <tr>
            <td className="text-center font-semibold" colSpan={2}>
              {t('council.m01.b.total')}
            </td>
            <td className="text-center">
              <b>100</b>
            </td>
            <td className="text-center font-semibold">{totalEvaluation.toLocaleString('vi')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EvaluationTable;

EvaluationTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  evaluations: PropTypes.array.isRequired,
};

EvaluationTable.defaulProps = {
  point: [],
};
