import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useMutation } from 'react-query';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import {
  DOWNLOAD_DOCX_TEMPLATE,
  DOWNLOAD_PDF_TEMPLATE,
  DOWNLOAD_DOCX_FILE,
  DOWNLOAD_PDF_FILE,
} from 'apis/extendImplementationTime.api';

export default function DownloadFile({ id, projectId }) {
  const { t } = useTranslation();

  const downloadTemplateButtonRef = useRef(null);
  const downloadFileButtonRef = useRef(null);

  const { mutate: mutateDownloadPdfTemplate, isLoading: isDownloadPdfTemplateLoading } =
    useMutation(DOWNLOAD_PDF_TEMPLATE);
  const { mutate: mutateDownloadDocxTemplate, isLoading: isDownloadDocxTemplateLoading } =
    useMutation(DOWNLOAD_DOCX_TEMPLATE);
  const { mutate: mutateDownloadPdfFile, isLoading: isDownloadPdfFileLoading } =
    useMutation(DOWNLOAD_PDF_FILE);
  const { mutate: mutateDownloadDocxFile, isLoading: isDownloadDocxFileLoading } =
    useMutation(DOWNLOAD_DOCX_FILE);

  const handleDownloadDocxTemplate = () => {
    mutateDownloadDocxTemplate(
      { projectId },
      {
        onSuccess: (res) => {
          const file = new Blob([res.data]);

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = 'Phieu-de-nghi-gia-han.docx';

          link.click();
        },
      }
    );
  };

  const handleDownloadPdfTemplate = () => {
    mutateDownloadPdfTemplate(
      { projectId },
      {
        onSuccess: (res) => {
          const file = new Blob([res.data]);

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = 'Phieu-de-nghi-gia-han.pdf';

          link.click();
        },
      }
    );
  };

  const handleDownloadPdfFile = () => {
    mutateDownloadPdfFile(
      { id },
      {
        onSuccess: (res) => {
          const file = new Blob([res.data]);

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = 'Phieu-de-nghi-gia-han.pdf';

          link.click();
        },
      }
    );
  };

  const handleDownloadDocxFile = () => {
    mutateDownloadDocxFile(
      { id },
      {
        onSuccess: (res) => {
          const file = new Blob([res.data]);

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = 'Phieu-de-nghi-gia-han.docx';

          link.click();
        },
      }
    );
  };
  return (
    <>
      <div className="flex justify-content-end gap-2">
        {id && (
          <Button
            type="button"
            label={t('extendImplementationTime.downloadFile')}
            icon={`pi ${
              isDownloadDocxFileLoading || isDownloadPdfFileLoading
                ? 'pi-spin pi-spinner'
                : 'pi-file'
            }`}
            className="block w-auto h-3rem border-none bg-red-400"
            severity="info"
            onClick={(event) => downloadFileButtonRef.current.toggle(event)}
            disabled={isDownloadDocxFileLoading || isDownloadPdfFileLoading}
          />
        )}

        <Button
          type="button"
          label={t('extendImplementationTime.downloadTemplate')}
          icon={`pi ${
            isDownloadPdfTemplateLoading || isDownloadDocxTemplateLoading
              ? 'pi-spin pi-spinner'
              : 'pi-file'
          }`}
          className="block w-auto h-3rem border-none ml-1"
          severity="info"
          onClick={(event) => downloadTemplateButtonRef.current.toggle(event)}
          disabled={isDownloadPdfTemplateLoading || isDownloadDocxTemplateLoading}
        />
      </div>
      <Menu
        ref={downloadFileButtonRef}
        model={[
          {
            label: t('extendImplementationTime.downloadPdfFile'),
            icon: 'pi pi-file-pdf',
            command: handleDownloadPdfFile,
            disabled: isDownloadPdfFileLoading,
          },
          {
            label: t('extendImplementationTime.downloadDocxFile'),
            icon: 'pi pi-file-word',
            command: handleDownloadDocxFile,
            disabled: isDownloadDocxFileLoading,
          },
        ]}
        popup
      />
      <Menu
        ref={downloadTemplateButtonRef}
        model={[
          {
            label: t('extendImplementationTime.downloadPdfTemplate'),
            icon: 'pi pi-file-pdf',
            command: handleDownloadPdfTemplate,
            disabled: isDownloadPdfTemplateLoading,
          },
          {
            label: t('extendImplementationTime.downloadDocxTemplate'),
            icon: 'pi pi-file-word',
            command: handleDownloadDocxTemplate,
            disabled: isDownloadDocxTemplateLoading,
          },
        ]}
        popup
      />
    </>
  );
}

DownloadFile.propTypes = {
  id: PropTypes.string,
  projectId: PropTypes.string,
};

DownloadFile.defaultProps = {
  id: undefined,
  projectId: '',
};
