import { PubProjectProposalViewList } from 'features/PubProjectProposal/pages';
import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';

function PubProjectProposalListPage() {
  return (
    <LayoutProvider>
      <Layout>
        <PubProjectProposalViewList />
      </Layout>
    </LayoutProvider>
  );
}

export default PubProjectProposalListPage;
