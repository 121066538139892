import { useRef } from 'react';

import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/FormControl';

import { PROJECT_ROLES } from 'constant';

import { Tooltip } from 'primereact/tooltip';

import PropTypes from 'prop-types';
import { getAcadamicLevelShortHand, getContactOrganizationName } from 'utils/func';
import ResearcherDialog from './ResearcherDialog';
import DownloadScientificProfile from '../../DownloadScientificProfile';
import Warning from '../../Warning';

export default function ResearchersTable({
  organizations,
  projectRoles,
  disabled,
  watch,
  control,
  errors,
  onBindingIntoContactFieldClick,
  handleDownloadScientificProfileFile,
}) {
  // #region Data
  const { t } = useTranslation();

  const owner = watch('general.owner');

  const researcherRef = useRef();

  const {
    fields: scientificSecretaryFields,
    append: appendScientificSecretary,
    remove: removeScientificSecretary,
    update: updateScientificSecretary,
  } = useFieldArray({
    control,
    name: 'general.scientificSecretaries',
  });

  const {
    fields: mainParticipantFields,
    append: appendMainParticipant,
    remove: removeMainParticipant,
    update: updateMainParticipant,
  } = useFieldArray({
    control,
    name: 'general.mainParticipants',
  });

  const {
    fields: participantFields,
    append: appendParticipant,
    remove: removeParticipant,
    update: updateParticipant,
  } = useFieldArray({
    control,
    name: 'general.participants',
  });

  const {
    fields: technicianFields,
    append: appendTechnician,
    remove: removeTechnician,
    update: updateTechnician,
  } = useFieldArray({
    control,
    name: 'general.technicians',
  });

  const {
    fields: domesticExpertFields,
    append: appendDomesticExpert,
    remove: removeDomesticExpert,
    update: updateDomesticExpert,
  } = useFieldArray({
    control,
    name: 'general.domesticExperts',
  });

  const {
    fields: internationalExpertFields,
    append: appendInternationalExpert,
    remove: removeInternationalExpert,
    update: updateInternationalExpert,
  } = useFieldArray({
    control,
    name: 'general.internationalExperts',
  });
  // #endregion Data

  // #region Event
  const getProjectRoleId = (roleCode) => {
    const projectRole = projectRoles.find((role) => role.code === roleCode);
    if (projectRole) return projectRole.id;
    return '';
  };
  // #endregion Event

  return (
    <div className="form-table">
      <table className="mb-2">
        <thead>
          <tr>
            <th className="text-center" style={{ minWidth: 50, width: 50 }}>
              {t('formLayout.numericalOrder')}
            </th>
            <th className="text-center" style={{ minWidth: 200, width: '30%' }}>
              {`${t('projectProposal.general.a9.fields.academicRank')}, ${t(
                'projectProposal.general.a9.fields.academicDegree'
              )}, ${t('projectProposal.general.a9.fields.name')}`}
            </th>
            <th className="text-center" style={{ minWidth: 100, width: '5%' }}>
              {t('projectProposal.general.a9.fields.gender')}
            </th>
            <th className="text-center" style={{ minWidth: 200, width: '30%' }}>
              {t('projectProposal.general.a9.fields.organization')}
            </th>
            <th className="text-center" style={{ minWidth: 200, width: '30%' }}>
              {t('projectProposal.general.a9.fields.assignedTask')}
            </th>
            {!disabled && (
              <th className="float text-center" style={{ minWidth: 80, width: 80 }}>
                {t('projectProposal.action')}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr className="font-bold">
            <td colSpan={6}>
              <div>
                <span>{t('projectProposal.general.a9.ownerFields.label')}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-center"> </td>
            <td>
              {`${getAcadamicLevelShortHand(owner?.academicRank, owner?.academicDegree, t, true)}${
                owner?.fullname
              }`}
              {owner?.scientificProfileUrl ? (
                <DownloadScientificProfile
                  contactId={owner?.id || owner?.contact?.id || owner?.contactId}
                  contactName={owner?.fullname}
                  academicRank={owner?.academicRank}
                  academicDegree={owner?.academicDegree}
                  handleDownloadFile={handleDownloadScientificProfileFile}
                />
              ) : (
                <Warning
                  message={t('projectProposal.general.a9.noScientificProfileUrl')}
                  tooltipTarget="researcher-owner"
                />
              )}
            </td>
            <td className="text-center">{t(`userProfile.gender.${owner?.gender}`)}</td>
            <td>{getContactOrganizationName(organizations, owner?.contact, t)}</td>
            <td>
              <TextInput
                name="general.owner.assignedTask"
                type="text"
                control={control}
                errors={errors}
              />
            </td>
            <td className="float" />
          </tr>

          <tr className="font-bold">
            <td colSpan={6}>
              <div>
                <span>{t('projectProposal.general.a9.scientificSecretaryFields.label')}</span>
                {!disabled && (
                  <>
                    <i
                      onClick={() => {
                        researcherRef.current.open({
                          projectRoleId: getProjectRoleId(PROJECT_ROLES.SECRETARY),
                          append: appendScientificSecretary,
                          type: 'APPEND',
                        });
                      }}
                      className="add-scientific-secretary pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle ml-1"
                      data-pr-tooltip={t('formLayout.action.add')}
                      data-pr-position="left"
                    />
                    <Tooltip target=".add-scientific-secretary" />
                  </>
                )}
              </div>
            </td>
          </tr>
          {scientificSecretaryFields?.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center">{index + 1}</td>
              <td>
                {`${getAcadamicLevelShortHand(item?.academicRank, item?.academicDegree, t, true)}${
                  item?.fullname
                }`}
                {item?.contact?.user?.scientificProfileUrl ? (
                  <DownloadScientificProfile
                    contactId={item?.contact?.id || item?.contactId}
                    contactName={item?.fullname}
                    academicRank={item?.academicRank}
                    academicDegree={item?.academicDegree}
                    handleDownloadFile={handleDownloadScientificProfileFile}
                  />
                ) : (
                  <Warning
                    message={t('projectProposal.general.a9.noScientificProfileUrl')}
                    tooltipTarget={`researcher-scientific-secretary-${index}`}
                  />
                )}
                {item?.isAuthorized && (
                  <>
                    <i
                      className="tick-icon pi pi-pencil border-primary-900 ml-2"
                      data-pr-tooltip={t('projectProposal.general.a9.fields.authority')}
                      data-pr-position="right"
                    />
                    <Tooltip target=".tick-icon" />
                  </>
                )}
              </td>
              <td className="text-center">{t(`userProfile.gender.${item?.gender}`)}</td>
              <td>{getContactOrganizationName(organizations, item?.contact, t)}</td>
              <td>{item?.assignedTask}</td>
              {!disabled && (
                <td className="flex flex-column float text-center p-0 border-none border-bottom-1">
                  <i
                    onClick={() => {
                      onBindingIntoContactFieldClick(
                        item.email,
                        updateScientificSecretary,
                        index,
                        item
                      );
                    }}
                    className="refresh-icon pi pi-replay text-green-500 cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.refresh')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".refresh-icon" />

                  <i
                    onClick={() => {
                      researcherRef.current.open({
                        projectRoleId: getProjectRoleId(PROJECT_ROLES.SECRETARY),
                        update: updateScientificSecretary,
                        index,
                        value: item,
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil bg-red text-primary cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => removeScientificSecretary(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              )}
            </tr>
          ))}
          <tr className="font-bold">
            <td colSpan={6}>
              <div>
                <span>{t('projectProposal.general.a9.mainParticipantFields.label')}</span>
                {!disabled && (
                  <>
                    <i
                      onClick={() => {
                        researcherRef.current.open({
                          projectRoleId: getProjectRoleId(PROJECT_ROLES.MAIN_PARTICIPANT),
                          append: appendMainParticipant,
                          type: 'APPEND',
                        });
                      }}
                      className="add-main-participant pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle ml-1"
                      data-pr-tooltip={t('formLayout.action.add')}
                      data-pr-position="left"
                    />
                    <Tooltip target=".add-main-participant" />
                  </>
                )}
              </div>
            </td>
          </tr>
          {mainParticipantFields?.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center">{index + 1}</td>
              <td>
                {`${getAcadamicLevelShortHand(item?.academicRank, item?.academicDegree, t, true)}${
                  item?.fullname
                }`}
                {item?.contact?.user?.scientificProfileUrl ? (
                  <DownloadScientificProfile
                    contactId={item?.contact?.id || item?.contactId}
                    contactName={item?.fullname}
                    academicRank={item?.academicRank}
                    academicDegree={item?.academicDegree}
                    handleDownloadFile={handleDownloadScientificProfileFile}
                  />
                ) : (
                  <Warning
                    message={t('projectProposal.general.a9.noScientificProfileUrl')}
                    tooltipTarget={`researcher-main-participant-${index}`}
                  />
                )}
                {item?.isAuthorized && (
                  <>
                    <i
                      className="tick-icon pi pi-pencil border-primary-900 ml-2"
                      data-pr-tooltip={t('projectProposal.general.a9.fields.authority')}
                      data-pr-position="right"
                    />
                    <Tooltip target=".tick-icon" />
                  </>
                )}
              </td>
              <td className="text-center">{t(`userProfile.gender.${item?.gender}`)}</td>
              <td>{getContactOrganizationName(organizations, item?.contact, t)}</td>
              <td>{item?.assignedTask}</td>
              {!disabled && (
                <td className="flex flex-column float text-center p-0 border-none border-bottom-1">
                  <i
                    onClick={() => {
                      onBindingIntoContactFieldClick(
                        item.email,
                        updateMainParticipant,
                        index,
                        item
                      );
                    }}
                    className="refresh-icon pi pi-replay text-green-500 cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.refresh')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".refresh-icon" />

                  <i
                    onClick={() => {
                      researcherRef.current.open({
                        projectRoleId: getProjectRoleId(PROJECT_ROLES.MAIN_PARTICIPANT),
                        update: updateMainParticipant,
                        index,
                        value: item,
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil text-primary cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => removeMainParticipant(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              )}
            </tr>
          ))}

          <tr className="font-bold">
            <td colSpan={6}>
              <div>
                <span>{t('projectProposal.general.a9.participantFields.label')}</span>
                {!disabled && (
                  <>
                    <i
                      onClick={() => {
                        researcherRef.current.open({
                          projectRoleId: getProjectRoleId(PROJECT_ROLES.PARTICIPANT),
                          append: appendParticipant,
                          type: 'APPEND',
                        });
                      }}
                      className="add-participant pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle ml-1"
                      data-pr-tooltip={t('formLayout.action.add')}
                      data-pr-position="left"
                    />
                    <Tooltip target=".add-participant" />
                  </>
                )}
              </div>
            </td>
          </tr>
          {participantFields?.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center">{index + 1}</td>
              <td>
                {`${getAcadamicLevelShortHand(item?.academicRank, item?.academicDegree, t, true)}${
                  item?.fullname
                }`}
                {item?.contact?.user?.scientificProfileUrl ? (
                  <DownloadScientificProfile
                    contactId={item?.contact?.id || item?.contactId}
                    contactName={item?.fullname}
                    academicRank={item?.academicRank}
                    academicDegree={item?.academicDegree}
                    handleDownloadFile={handleDownloadScientificProfileFile}
                  />
                ) : (
                  <Warning
                    message={t('projectProposal.general.a9.noScientificProfileUrl')}
                    tooltipTarget={`researcher-participant-${index}`}
                  />
                )}
                {item?.isAuthorized && (
                  <>
                    <i
                      className="tick-icon pi pi-pencil border-primary-900 ml-2"
                      data-pr-tooltip={t('projectProposal.general.a9.fields.authority')}
                      data-pr-position="right"
                    />
                    <Tooltip target=".tick-icon" />
                  </>
                )}
              </td>
              <td className="text-center">{t(`userProfile.gender.${item?.gender}`)}</td>
              <td>{getContactOrganizationName(organizations, item?.contact, t)}</td>
              <td>{item?.assignedTask}</td>
              {!disabled && (
                <td className="flex flex-column float text-center p-0 border-none border-bottom-1">
                  <i
                    onClick={() => {
                      onBindingIntoContactFieldClick(item.email, updateParticipant, index, item);
                    }}
                    className="refresh-icon pi pi-replay text-green-500 cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.refresh')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".refresh-icon" />

                  <i
                    onClick={() => {
                      researcherRef.current.open({
                        projectRoleId: getProjectRoleId(PROJECT_ROLES.PARTICIPANT),
                        update: updateParticipant,
                        index,
                        value: item,
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil text-primary cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => removeParticipant(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              )}
            </tr>
          ))}
          <tr className="font-bold">
            <td colSpan={6}>
              <div>
                <span>{t('projectProposal.general.a9.technicianFields.label')}</span>
                {!disabled && (
                  <>
                    <i
                      onClick={() => {
                        researcherRef.current.open({
                          projectRoleId: getProjectRoleId(PROJECT_ROLES.TECHNICIAN),
                          append: appendTechnician,
                          type: 'APPEND',
                        });
                      }}
                      className="add-technician pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle ml-1"
                      data-pr-tooltip={t('formLayout.action.add')}
                      data-pr-position="left"
                    />
                    <Tooltip target=".add-technician" />
                  </>
                )}
              </div>
            </td>
          </tr>
          {technicianFields?.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center">{index + 1}</td>
              <td>
                {`${getAcadamicLevelShortHand(item?.academicRank, item?.academicDegree, t, true)}${
                  technicianFields[index]?.fullname
                }`}
                {technicianFields[index]?.contact?.user?.scientificProfileUrl ? (
                  <DownloadScientificProfile
                    contactId={item?.contact?.id || item?.contactId}
                    contactName={technicianFields[index]?.fullname}
                    academicRank={item?.academicRank}
                    academicDegree={item?.academicDegree}
                    handleDownloadFile={handleDownloadScientificProfileFile}
                  />
                ) : (
                  <Warning
                    message={t('projectProposal.general.a9.noScientificProfileUrl')}
                    tooltipTarget={`researcher-technician-${index}`}
                  />
                )}
                {item?.isAuthorized && (
                  <>
                    <i
                      className="tick-icon pi pi-pencil border-primary-900 ml-2"
                      data-pr-tooltip={t('projectProposal.general.a9.fields.authority')}
                      data-pr-position="right"
                    />
                    <Tooltip target=".tick-icon" />
                  </>
                )}
              </td>
              <td className="text-center">{t(`userProfile.gender.${item?.gender}`)}</td>
              <td>{getContactOrganizationName(organizations, item?.contact, t)}</td>
              <td>{technicianFields[index]?.assignedTask}</td>
              {!disabled && (
                <td className="flex flex-column float text-center p-0 border-none border-bottom-1">
                  <i
                    onClick={() => {
                      onBindingIntoContactFieldClick(item.email, updateTechnician, index, item);
                    }}
                    className="refresh-icon pi pi-replay text-green-500 cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.refresh')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".refresh-icon" />

                  <i
                    onClick={() => {
                      researcherRef.current.open({
                        projectRoleId: getProjectRoleId(PROJECT_ROLES.TECHNICIAN),
                        update: updateTechnician,
                        index,
                        value: item,
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil text-primary cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => removeTechnician(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              )}
            </tr>
          ))}

          <tr className="font-bold">
            <td colSpan={6}>
              <div>
                <span>{t('projectProposal.general.a9.domesticExpertFields.label')}</span>
                {!disabled && (
                  <>
                    <i
                      onClick={() => {
                        researcherRef.current.open({
                          projectRoleId: getProjectRoleId(PROJECT_ROLES.DOMESTIC_EXPERT),
                          append: appendDomesticExpert,
                          type: 'APPEND',
                        });
                      }}
                      className="add-domestic-expert pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle ml-1"
                      data-pr-tooltip={t('formLayout.action.add')}
                      data-pr-position="left"
                    />
                    <Tooltip target=".add-domestic-expert" />
                  </>
                )}
              </div>
            </td>
          </tr>
          {domesticExpertFields?.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center">{index + 1}</td>
              <td>
                {`${getAcadamicLevelShortHand(item?.academicRank, item?.academicDegree, t, true)}${
                  item?.fullname
                }`}
                {item?.contact?.user?.scientificProfileUrl ? (
                  <DownloadScientificProfile
                    contactId={item?.contact?.id || item?.contactId}
                    contactName={item?.fullname}
                    academicRank={item?.academicRank}
                    academicDegree={item?.academicDegree}
                    handleDownloadFile={handleDownloadScientificProfileFile}
                  />
                ) : (
                  <Warning
                    message={t('projectProposal.general.a9.noScientificProfileUrl')}
                    tooltipTarget={`researcher-domestic-expert-${index}`}
                  />
                )}
                {item?.isAuthorized && (
                  <>
                    <i
                      className="tick-icon pi pi-pencil border-primary-900 ml-2"
                      data-pr-tooltip={t('projectProposal.general.a9.fields.authority')}
                      data-pr-position="right"
                    />
                    <Tooltip target=".tick-icon" />
                  </>
                )}
              </td>
              <td className="text-center">{t(`userProfile.gender.${item?.gender}`)}</td>
              <td>{getContactOrganizationName(organizations, item?.contact, t)}</td>
              <td>{item?.assignedTask}</td>
              {!disabled && (
                <td className="flex flex-column float text-center p-0 border-none border-bottom-1">
                  <i
                    onClick={() => {
                      onBindingIntoContactFieldClick(item.email, updateDomesticExpert, index, item);
                    }}
                    className="refresh-icon pi pi-replay text-green-500 cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.refresh')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".refresh-icon" />

                  <i
                    onClick={() => {
                      researcherRef.current.open({
                        projectRoleId: getProjectRoleId(PROJECT_ROLES.DOMESTIC_EXPERT),
                        update: updateDomesticExpert,
                        index,
                        value: item,
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil text-primary cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => removeDomesticExpert(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              )}
            </tr>
          ))}
          <tr className="font-bold">
            <td colSpan={6}>
              <div>
                <span>{t('projectProposal.general.a9.internationalExpertFields.label')}</span>
                {!disabled && (
                  <>
                    <i
                      onClick={() => {
                        researcherRef.current.open({
                          projectRoleId: getProjectRoleId(PROJECT_ROLES.INTERNATIONAL_EXPERT),
                          append: appendInternationalExpert,
                          type: 'APPEND',
                        });
                      }}
                      className="add-international-expert pi pi-plus text-primary cursor-pointer bg-primary p-1 border-circle ml-1"
                      data-pr-tooltip={t('formLayout.action.add')}
                      data-pr-position="left"
                    />
                    <Tooltip target=".add-international-expert" />
                  </>
                )}
              </div>
            </td>
          </tr>
          {internationalExpertFields?.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center">{index + 1}</td>
              <td>
                {`${getAcadamicLevelShortHand(item?.academicRank, item?.academicDegree, t, true)}${
                  item?.fullname
                }`}
                {item?.contact?.user?.scientificProfileUrl ? (
                  <DownloadScientificProfile
                    contactId={item?.contact?.id || item?.contactId}
                    contactName={item?.fullname}
                    academicRank={item?.academicRank}
                    academicDegree={item?.academicDegree}
                    handleDownloadFile={handleDownloadScientificProfileFile}
                  />
                ) : (
                  <Warning
                    message={t('projectProposal.general.a9.noScientificProfileUrl')}
                    tooltipTarget={`researcher-international-expert-${index}`}
                  />
                )}
                {item?.isAuthorized && (
                  <>
                    <i
                      className="tick-icon pi pi-pencil border-primary-900 ml-2"
                      data-pr-tooltip={t('projectProposal.general.a9.fields.authority')}
                      data-pr-position="right"
                    />
                    <Tooltip target=".tick-icon" />
                  </>
                )}
              </td>
              <td className="text-center">{t(`userProfile.gender.${item?.gender}`)}</td>
              <td>{getContactOrganizationName(organizations, item?.contact, t)}</td>
              <td>{item?.assignedTask}</td>
              {!disabled && (
                <td className="flex flex-column float text-center p-0 border-none border-bottom-1">
                  <i
                    onClick={() => {
                      onBindingIntoContactFieldClick(
                        item.email,
                        updateInternationalExpert,
                        index,
                        item
                      );
                    }}
                    className="refresh-icon pi pi-replay text-green-500 cursor-pointer my-1"
                    data-pr-tooltip={t('formLayout.action.refresh')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".refresh-icon" />

                  <i
                    onClick={() => {
                      researcherRef.current.open({
                        projectRoleId: getProjectRoleId(PROJECT_ROLES.INTERNATIONAL_EXPERT),
                        update: updateInternationalExpert,
                        index,
                        value: item,
                        type: 'UPDATE',
                      });
                    }}
                    className="edit-icon pi pi-pencil text-primary cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.edit')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".edit-icon" />

                  <i
                    onClick={() => removeInternationalExpert(index)}
                    className="remove-icon pi pi-times text-red-700 cursor-pointer mb-1"
                    data-pr-tooltip={t('formLayout.action.remove')}
                    data-pr-position="left"
                  />
                  <Tooltip target=".remove-icon" />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      <ResearcherDialog
        ref={researcherRef}
        organizations={organizations}
        ownerId={owner?.contact?.userId}
      />
    </div>
  );
}

ResearchersTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organizations: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  projectRoles: PropTypes.array,
  disabled: PropTypes.bool,
  watch: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  onBindingIntoContactFieldClick: PropTypes.func.isRequired,
  handleDownloadScientificProfileFile: PropTypes.func.isRequired,
};

ResearchersTable.defaultProps = {
  organizations: [],
  projectRoles: [],
  disabled: false,
};
