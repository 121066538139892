import './style.scss';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Loading from 'components/Loading';

import PropTypes from 'prop-types';

import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { classNames } from 'primereact/utils';
import { ProgressSpinner } from 'primereact/progressspinner';

import { getAllOrganizations } from 'apis/projectProposals.api';
import { useQuery } from 'react-query';
import {
  AcademicHistoryForm,
  GeneralInfoForm,
  GuidingActivitiesForm,
  OtherInfoForm,
  ResearchActivitiesForm,
  ScientificWorksForm,
  WorkHistoryForm,
} from '..';
import PreviewForm from '../PreviewForm';

export default function Form({
  control,
  errors,
  disabledSubmit,
  watch,
  setValue,
  handleSubmit,
  getValues,
  tab,
  nextTab,
  checkValidation,
  checkKeyDown,
  disabled,
  isDirty,
  footerConfirmationPrompt,
  shouldUpdateFromDocx,
  handleLoading,
  handleLoadedData,
}) {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: organizationsData } = useQuery(
    ['scientificProfileOrganizations'],
    getAllOrganizations,
    { enabled: tab === 0, staleTime: 1000 * 60 * 5 }
  );

  const organizations = useMemo(
    () =>
      organizationsData?.data.map((e) => ({
        ...e,
        name: t('lang') === 'vi' ? e?.name : e?.englishName,
      })) ?? [],
    [organizationsData, t]
  );
  // #endregion Data

  // #region Event
  // #endregion Event

  // #region Render
  return (
    <div
      className={classNames('scientific-profile-form m-0 bg-white p-5 p-fluid', {
        'form-disabled': disabled,
      })}
    >
      {/* #region Loading */}
      <div className="sticky top-0 left-0 z-5">
        <div className="relative">{false && <Loading />}</div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form onSubmit={handleSubmit} onKeyDown={checkKeyDown} autoComplete="off">
        <Fieldset disabled={disabled} className="border-none p-0">
          {/* {tab !== 7 && (
            <div className="flex justify-content-end">
              <Button
                type="button"
                label={t('formLayout.action.getFromDocx')}
                onClick={handleGetDataFromDocx}
                icon="pi pi-pencil"
                className="block w-auto"
              />
            </div>
          )} */}
          {tab === 0 && (!shouldUpdateFromDocx || handleLoading()) && (
            <GeneralInfoForm
              control={control}
              errors={errors}
              isDirty={isDirty}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              disabled={disabled}
              footerConfirmationPrompt={footerConfirmationPrompt}
              organizations={organizations}
              shouldUpdateFromDocx={shouldUpdateFromDocx}
            />
          )}
          {tab === 1 && (!shouldUpdateFromDocx || handleLoading()) && (
            <AcademicHistoryForm control={control} shouldUpdateFromDocx={shouldUpdateFromDocx} />
          )}
          {tab === 2 && (!shouldUpdateFromDocx || handleLoading()) && (
            <WorkHistoryForm
              errors={errors}
              control={control}
              shouldUpdateFromDocx={shouldUpdateFromDocx}
            />
          )}
          {tab === 3 && (!shouldUpdateFromDocx || handleLoading()) && (
            <ResearchActivitiesForm
              control={control}
              errors={errors}
              shouldUpdateFromDocx={shouldUpdateFromDocx}
            />
          )}
          {tab === 4 && (!shouldUpdateFromDocx || handleLoading()) && (
            <GuidingActivitiesForm
              control={control}
              errors={errors}
              shouldUpdateFromDocx={shouldUpdateFromDocx}
            />
          )}
          {tab === 5 && (!shouldUpdateFromDocx || handleLoading()) && (
            <ScientificWorksForm
              control={control}
              errors={errors}
              shouldUpdateFromDocx={shouldUpdateFromDocx}
            />
          )}
          {tab === 6 && (!shouldUpdateFromDocx || handleLoading()) && (
            <OtherInfoForm
              control={control}
              errors={errors}
              shouldUpdateFromDocx={shouldUpdateFromDocx}
            />
          )}
        </Fieldset>

        {tab === 7 && (!shouldUpdateFromDocx || handleLoading()) && <PreviewForm watch={watch} />}

        {!handleLoading() && shouldUpdateFromDocx && (
          <div
            className="card"
            title="Đang tải dữ liệu ...."
            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
          >
            <ProgressSpinner
              style={{ width: '50px', height: '50px' }}
              strokeWidth="5"
              animationDuration=".7s"
            />
            <div className="text-xl mt-4" style={{ alignSelf: 'center' }}>
              Đang phân tích và trích xuất dữ liệu . . .
            </div>
          </div>
        )}
        {!disabled && (!shouldUpdateFromDocx || handleLoadedData()) && (
          <div className="flex justify-content-end mt-2">
            {tab !== 7 && (
              <>
                <Button
                  type="submit"
                  className="btn-control font-semibold mr-3"
                  severity="success"
                  onClick={checkValidation}
                  disabled={disabledSubmit}
                >
                  {t('formLayout.save')}
                </Button>
                <Button type="submit" className="p-button-next btn-control" onClick={nextTab}>
                  {t('formLayout.next')}
                </Button>
              </>
            )}
            {tab === 7 && (
              <>
                <Button
                  type="button"
                  className="btn-control font-semibold mr-3"
                  severity="danger"
                  onClick={() => navigate('/scientific-profile')}
                >
                  {t('formLayout.close')}
                </Button>
                <Button
                  type="submit"
                  className="btn-control font-semibold mr-3"
                  severity="success"
                  onClick={checkValidation}
                  disabled={disabledSubmit}
                >
                  {t('formLayout.save')}
                </Button>
              </>
            )}
          </div>
        )}
      </form>
    </div>
  );
}

Form.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  disabledSubmit: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired,
  nextTab: PropTypes.func.isRequired,
  // handleGetDataFromDocx: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  checkValidation: PropTypes.func.isRequired,
  checkKeyDown: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  isDirty: PropTypes.bool,
  footerConfirmationPrompt: PropTypes.func,
  shouldUpdateFromDocx: PropTypes.bool,
  handleLoading: PropTypes.func,
  handleLoadedData: PropTypes.func,
};

Form.defaultProps = {
  disabled: false,
  isDirty: false,
  footerConfirmationPrompt: () => null,
  shouldUpdateFromDocx: false,
  handleLoading: () => null,
  handleLoadedData: () => null,
};
