/* eslint-disable indent */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PROJECT_PROPOSAL_ORGANIZATION_TYPES, TIMEZONE_VN, currencyOptions } from 'constant';
import PropTypes from 'prop-types';
import { getAcadamicLevelShortHand, getContactOrganizationName } from 'utils/func';

import { Tooltip } from 'primereact/tooltip';
import { Editor } from 'primereact/editor';
import DownloadScientificProfile from '../../DownloadScientificProfile';
import Warning from '../../Warning';
import DomesticExpertFields from './DomesticExpertFields';
import InternationalExpertFields from './InternationalExpertFields';
import MainParticipantFields from './MainParticipantFields';
import OwnerFields from './OwnerFields';
import ParticipantFields from './ParticipantFields';
import RecommendedExpertsFields from './RecommendedExpertsFields';
import ScientificSecretaryFields from './ScientificSecretaryFields';
import TechnicianFields from './TechnicianFields';
import Expenditure from '../Expenditure';

export default function GeneralInfo({
  generalInfoData,
  expenditureData,
  handleDownloadFile,
  organizations,
  handleDownloadScientificProfileFile,
}) {
  // #region Data
  const { t } = useTranslation();

  const partners = useMemo(
    () =>
      generalInfoData?.projectProposalOrganizations.filter(
        (org) => org.type === PROJECT_PROPOSAL_ORGANIZATION_TYPES.PARTNER
      ) ?? [],
    [generalInfoData?.projectProposalOrganizations]
  );

  // #endregion

  // #region Event
  // eslint-disable-next-line react/prop-types
  const formatIndex = (_, props) => props.rowIndex + 1;

  const formatFullname = (value) => (
    <span>
      {getAcadamicLevelShortHand(value.academicRank, value.academicDegree, t, true)}
      {value.fullname}
      {value?.contact?.user?.scientificProfileUrl ? (
        <DownloadScientificProfile
          contactId={value?.contact?.id || value?.contactId}
          contactName={value?.fullname}
          academicRank={value?.academicRank}
          academicDegree={value?.academicDegree}
          handleDownloadFile={handleDownloadScientificProfileFile}
        />
      ) : (
        <Warning
          message={t('projectProposal.general.a9.noScientificProfileUrl')}
          tooltipTarget="researcher-scientific-profile-warning"
        />
      )}
      {value?.isAuthorized && (
        <>
          <i
            className="edit-icon pi pi-pencil border-primary-900 ml-2"
            data-pr-tooltip={t('projectProposal.general.a9.fields.authority')}
            data-pr-position="right"
          />
          <Tooltip target=".edit-icon" />
        </>
      )}
    </span>
  );
  const formatGender = (value) => t(`userProfile.gender.${value?.gender}`);

  const formatOrganizationName = (value) =>
    getContactOrganizationName(organizations, value?.contact, t);
  // #endregion Event

  return (
    <div className="pb-2">
      <h4>{t('projectProposal.general.viewLabel')}</h4>
      {/* A1 */}
      <h4 className="mb-2">{t('projectProposal.general.a1.label')}</h4>
      <div className="ml-5 text-lg">
        <div className="flex mb-1">
          <div className="mr-2 font-semibold">
            {t('projectProposal.general.a1.vietnameseName')}:
          </div>
          <div className="flex-1">{generalInfoData?.vietnameseName}</div>
        </div>
        <div className="flex mb-1">
          <div className="mr-2 font-semibold">{t('projectProposal.general.a1.englishName')}: </div>
          <div className="flex-1">{generalInfoData?.englishName}</div>
        </div>
        <div className="flex mb-1">
          <div className="mr-2 font-semibold">{t('projectProposal.general.a1.projectType')}: </div>
          <div className="flex-1">{t(`projectTypes.${generalInfoData?.projectType}`)}</div>
        </div>
        {/* eslint-disable-next-line no-restricted-globals */}
        {((location?.state && location?.state?.pubProjectProposalName) ||
          // eslint-disable-next-line no-undef
          (generalInfoData?.pubProjectProposalId && generalInfoData?.pubProjectProposal)) && (
          <div className="flex mb-1">
            <div className="mr-2 font-semibold">
              {t('projectProposal.general.a1.pubProposalVietnameName')}:{' '}
            </div>
            {/* eslint-disable-next-line no-undef, no-restricted-globals */}
            <div className="flex-1">
              {generalInfoData?.pubProjectProposal?.vietnameseName ||
                // eslint-disable-next-line no-restricted-globals
                location?.state?.pubProjectProposalName}
            </div>
          </div>
        )}
      </div>

      {/* A2 */}
      <h4>{t('projectProposal.general.a2.label')}</h4>
      {generalInfoData?.projectProposalResearchFields?.map((item, index) => (
        <div className="flex ml-5 text-lg mb-2" key={item?.researchField?.id || index}>
          <div className="mr-2 font-semibold">
            {t('projectProposal.general.a2.prio.researchField')} {index + 1}:{' '}
          </div>
          <div className="w-10rem" style={{ marginRight: '150px' }}>
            {item?.researchField?.name}
          </div>
          <div className="font-semibold mr-2 ">
            {t('projectProposal.general.a2.researchLine')}:{' '}
          </div>
          <div className="flex-1">{item?.researchLine}</div>
        </div>
      ))}
      {generalInfoData?.recommendedExperts?.length > 0 && (
        <RecommendedExpertsFields
          recommendedExperts={generalInfoData?.recommendedExperts}
          isLoading={false}
        />
      )}

      {/* A3 */}
      <div className="text-lg mt-3">
        <h4>{t('projectProposal.general.a3.label')}</h4>
        <p className="flex-1 ml-5">
          <span className="font-semibold">{t('projectProposal.general.a3.researchType')}: </span>
          {generalInfoData?.researchType?.name}
        </p>
      </div>

      {/* A4 */}
      <div className="text-lg mt-2">
        <h4>{t('projectProposal.general.a4.label')}</h4>
        <div className="flex mb-1 ml-5">
          <div className="mr-2 font-semibold">{t('projectProposal.general.a4.researchTime')}:</div>
          {generalInfoData?.implementationMonths}
        </div>
        <div className="flex mb-1 ml-5">
          <div className="mr-2 font-semibold">{t('projectProposal.general.a4.yearProposed')}: </div>
          {generalInfoData?.yearProposed}
        </div>
      </div>

      {/* A5 */}
      <div className="flex text-lg mt-2 flex-column">
        <h4>{t('projectProposal.general.a5.label')}</h4>
        <div className="flex ml-5 flex-column">
          <div>
            <span className="font-semibold">{t('projectProposal.general.a5.total')}: </span>
            {generalInfoData?.totalExpenditure?.toLocaleString('vi', currencyOptions)},{' '}
            {t('projectProposal.general.a5.includes')}:
          </div>
          <div className="mt-1 ml-4">
            <span className="font-semibold">{t('projectProposal.general.a5.fromVnu')}: </span>
            {generalInfoData?.nationalUniversityTotalExpenditure?.toLocaleString(
              'vi',
              currencyOptions
            )}
          </div>
          <div className="mt-1 ml-4 font-italic">
            {t('projectProposal.general.a5.inParticular')}:
          </div>
          <div className="ml-5 font-italic">
            <div className="mt-1 ml-2">
              <span className="font-semibold">
                {t('projectProposal.general.a5.allocatedAdministrative')}
              </span>
              [
              <span style={{ color: 'red' }}>
                {t('projectProposal.general.a5.item')} 1 + {t('projectProposal.general.a5.item')} 3
                + {t('projectProposal.general.a5.item')} 4
              </span>
              ]:{' '}
              {generalInfoData?.nationalUniversityAllocatedExpenditure?.toLocaleString(
                'vi',
                currencyOptions
              )}
            </div>
            <div className="mt-1 ml-2">
              <span className="font-semibold">
                {t('projectProposal.general.a5.nonAllocatedAdministrative')}
              </span>
              [{t('projectProposal.general.a5.item')} 2]:{' '}
              {generalInfoData?.nationalUniversityNonallocatedExpenditure?.toLocaleString(
                'vi',
                currencyOptions
              )}{' '}
            </div>
          </div>
          <div className="mt-1 ml-4">
            <span className="font-semibold">{t('projectProposal.general.a5.fromFunding')}: </span>
            {generalInfoData?.fundingExpenditure?.toLocaleString('vi', currencyOptions)},{' '}
          </div>
          <div className="mt-1 ml-4 font-italic">
            {t('projectProposal.general.a5.inParticular')}:
          </div>
          <div className="flex text-lg mr-5 ml-2 font-italic">
            <div className="mt-1 ml-5 w-15rem">
              <span className="font-semibold">{t('projectProposal.general.a5.ownCapital')}: </span>
              {generalInfoData?.fundingExpenditureSelf?.toLocaleString('vi', currencyOptions)}
            </div>
          </div>
          <div className="flex text-lg mb-2 mr-5 ml-2 font-italic">
            <div className="mt-1 ml-5 w-15rem">
              <span className="font-semibold">
                {t('projectProposal.general.a5.otherCapital')}:{' '}
              </span>
              {generalInfoData?.fundingExpenditureOther?.toLocaleString('vi', currencyOptions)}
            </div>
          </div>
          {generalInfoData?.bonusExpenditure > 0 && (
            <div className="mt-1">
              <span className="font-semibold">
                {t('projectProposal.general.a5.bonusExpenditure')}:{' '}
              </span>
              {generalInfoData?.bonusExpenditure?.toLocaleString('vi', currencyOptions)}
            </div>
          )}
          <div className="mt-1 mb-2">
            <div>
              <span className="font-semibold">
                {t('projectProposal.general.a5.otherFunding')}:{' '}
              </span>
            </div>
            <p>{generalInfoData?.otherSponsorOrganization}</p>
          </div>
        </div>
        <Expenditure data={expenditureData} />
      </div>

      {/* A6 */}
      <div className="flex text-lg mt-2 flex-column">
        <h4>{t('projectProposal.general.a6.label')}</h4>
        <div className="flex ml-5 flex-column">
          <div className="flex flex-wrap">
            <div className="w-30rem mb-2">
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.academicRank')}:{' '}
              </span>
              <span>
                {generalInfoData?.owner?.academicRank
                  ? t(`academicRank.${generalInfoData?.owner?.academicRank}`)
                  : ''}
              </span>
            </div>
            <div className="mb-2">
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.academicDegree')}:{' '}
              </span>
              <span>
                {generalInfoData?.owner?.academicDegree
                  ? t(`academicDegree.${generalInfoData?.owner?.academicDegree}`)
                  : ''}
              </span>
            </div>
          </div>
          <div className="mb-2">
            <span className="font-semibold">{t('projectProposal.general.a6.owner.name')}: </span>
            <span>{generalInfoData?.owner?.fullname}</span>
          </div>
          <div className="mb-2">
            <span className="font-semibold">{t('projectProposal.general.a6.owner.dob')}: </span>
            <span>
              {generalInfoData?.owner?.dob
                ? new Date(generalInfoData?.owner?.dob).toLocaleDateString('en-GB', {
                    timeZone: TIMEZONE_VN,
                  })
                : ''}
            </span>
          </div>
          <div className="flex flex-wrap">
            <div className="w-20rem mb-2">
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.citizenId')}:{' '}
              </span>
              <span>{generalInfoData?.owner?.citizenId}</span>
            </div>
            <div className="w-15rem mb-2">
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.dateOfIssue')}:{' '}
              </span>
              <span>
                {generalInfoData?.owner?.dateOfIssue
                  ? new Date(generalInfoData?.owner?.dateOfIssue).toLocaleDateString('en-GB', {
                      timeZone: TIMEZONE_VN,
                    })
                  : ''}
              </span>
            </div>
            <div className="w-40rem mb-2">
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.placeOfIssue')}:{' '}
              </span>
              <span>{generalInfoData?.owner?.placeOfIssue}</span>
            </div>
          </div>
          <div className="mb-2">
            <span className="font-semibold">
              {t('projectProposal.general.a6.owner.taxIdentificationNumber')}:{' '}
            </span>
            <span>{generalInfoData?.owner?.taxIdentificationNumber}</span>
          </div>
          <div className="flex flex-wrap">
            <div className="w-30rem mb-2">
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.bankAccount')}:{' '}
              </span>
              <span>{generalInfoData?.owner?.bankAccount}</span>
            </div>
            <div className="mb-2">
              <span className="font-semibold">{t('projectProposal.general.a6.owner.bank')}: </span>
              <span>{generalInfoData?.owner?.bank}</span>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="mb-2">
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.workplace')}:{' '}
              </span>
              <span>{generalInfoData?.owner?.workplace}</span>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-30rem mb-2">
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.mobile')}:{' '}
              </span>
              <span>{generalInfoData?.owner?.mobile}</span>
            </div>
            <div className="mb-2">
              <span className="font-semibold">Email: </span>
              <span>{generalInfoData?.owner?.email}</span>
            </div>
          </div>
          <div className="mb-2">
            <div>
              <span className="font-semibold">
                {t('projectProposal.general.a6.owner.summarizedExperience')}:{' '}
              </span>
            </div>
            <Editor
              style={{ minHeight: '200px', width: '100%' }}
              value={generalInfoData?.owner?.experience}
              showHeader={false}
              readOnly
            />
          </div>
        </div>
      </div>

      {/* A7 */}
      <div className="flex text-lg mt-2 flex-column">
        <h4>{t('projectProposal.general.a7.label')}</h4>
        {generalInfoData?.projectProposalOrganizations
          .filter((org) => org.type === 'LEAD')
          .map((org, index) => (
            <div className="flex ml-5 flex-column" key={org?.id || index}>
              <div className="mb-2">
                <span className="font-semibold">
                  {t('projectProposal.general.organization.name')}:{' '}
                </span>
                <span>{org?.name}</span>
              </div>
              <div className="mb-2">
                <span className="font-semibold">
                  {t('projectProposal.general.organization.chief')}:{' '}
                </span>
                <span>{org?.chief}</span>
              </div>
              <div className="flex flex-wrap">
                <div className="w-30rem mb-2">
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.phone')}:{' '}
                  </span>
                  <span>{org?.phone}</span>
                </div>
                <div className="w-20rem mb-2">
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.fax')}:{' '}
                  </span>
                  <span>{org?.fax}</span>
                </div>
              </div>
              <div className="mb-2">
                <span className="font-semibold">
                  {t('projectProposal.general.organization.email')}:{' '}
                </span>
                <span>{org?.email}</span>
              </div>
              <div className="flex flex-wrap">
                <div className="w-30rem mb-2">
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.bankAccount')}:{' '}
                  </span>
                  <span>{org?.bankAccount}</span>
                </div>
                <div className="mb-2">
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.bank')}:{' '}
                  </span>
                  <span>{org?.bank}</span>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* A8 */}
      <div className="flex text-lg mt-2 flex-column">
        <h4>{t('projectProposal.general.a8.label')}</h4>
        {partners?.length > 0 ? (
          partners.map((org, index) => (
            <div className="flex ml-5 mb-2 flex-column" key={org?.id || index}>
              <div className="mb-2">
                <span className="font-bold">
                  {t('projectProposal.general.organization.name')} {index + 1}:{' '}
                </span>
                <span>{org?.name}</span>
              </div>
              <div className="mb-2">
                <span className="font-semibold">
                  {t('projectProposal.general.a8.confirmationDocument')}:{' '}
                </span>
                {org?.partnerConfirmationUrl && (
                  <span
                    className="p-link text-primary underline"
                    onClick={() => handleDownloadFile(org?.partnerConfirmationUrl)}
                  >
                    {t('projectProposal.general.a8.attachment')}
                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">
                  {t('projectProposal.general.organization.chief')}:{' '}
                </span>
                <span>{org?.chief}</span>
              </div>
              <div className="flex flex-wrap">
                <div className="w-30rem mb-2">
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.phone')}:{' '}
                  </span>
                  <span>{org?.phone}</span>
                </div>
                <div className="w-20rem mb-2">
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.fax')}:{' '}
                  </span>
                  <span>{org?.fax}</span>
                </div>
              </div>
              <div className="mb-2">
                <span className="font-semibold">
                  {t('projectProposal.general.organization.address')}:{' '}
                </span>
                <span>{org?.address}</span>
              </div>
              <div
                className={
                  index === generalInfoData.projectProposalOrganizations.length - 2 ? '' : 'mb-3'
                }
              >
                <div>
                  <span className="font-semibold">
                    {t('projectProposal.general.organization.partnerContribution')}:{' '}
                  </span>
                </div>
                <p>{org?.partnerContribution}</p>
              </div>
            </div>
          ))
        ) : (
          <span>({t('projectProposal.general.a8.noPartner')})</span>
        )}
      </div>

      {/* A9 */}
      <h4>{t('projectProposal.general.a9.label')}</h4>
      <OwnerFields
        owners={generalInfoData?.projectProposalContacts.filter((item) =>
          item?.projectRole?.code.toLowerCase().includes('owner')
        )}
        formatIndex={formatIndex}
        formatFullname={formatFullname}
        formatGender={formatGender}
        formatOrganizationName={formatOrganizationName}
      />
      <ScientificSecretaryFields
        scientificSecretaries={generalInfoData?.projectProposalContacts.filter((item) =>
          item?.projectRole?.code.toLowerCase().includes('secretary')
        )}
        formatIndex={formatIndex}
        formatFullname={formatFullname}
        formatGender={formatGender}
        formatOrganizationName={formatOrganizationName}
      />
      <MainParticipantFields
        mainParticipants={generalInfoData?.projectProposalContacts.filter((item) =>
          item?.projectRole?.code.toLowerCase().includes('main')
        )}
        formatIndex={formatIndex}
        formatFullname={formatFullname}
        formatGender={formatGender}
        formatOrganizationName={formatOrganizationName}
      />
      <ParticipantFields
        participants={generalInfoData?.projectProposalContacts.filter(
          (item) => item?.projectRole?.code.toLowerCase() === 'participant'
        )}
        formatIndex={formatIndex}
        formatFullname={formatFullname}
        formatGender={formatGender}
        formatOrganizationName={formatOrganizationName}
      />
      <TechnicianFields
        technicians={generalInfoData?.projectProposalContacts.filter(
          (item) =>
            item?.projectRole?.code.toLowerCase().includes('technician') ||
            item?.projectRole?.code.toLowerCase().includes('support')
        )}
        formatIndex={formatIndex}
        formatFullname={formatFullname}
        formatGender={formatGender}
        formatOrganizationName={formatOrganizationName}
      />
      <DomesticExpertFields
        experts={generalInfoData?.projectProposalContacts.filter((item) =>
          item?.projectRole?.code.toLowerCase().includes('domestic')
        )}
        formatIndex={formatIndex}
        formatFullname={formatFullname}
        formatGender={formatGender}
        formatOrganizationName={formatOrganizationName}
      />
      <InternationalExpertFields
        experts={generalInfoData?.projectProposalContacts.filter((item) =>
          item?.projectRole?.code.toLowerCase().includes('international')
        )}
        formatIndex={formatIndex}
        formatFullname={formatFullname}
        formatGender={formatGender}
        formatOrganizationName={formatOrganizationName}
      />
    </div>
  );
}

GeneralInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  generalInfoData: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  expenditureData: PropTypes.object.isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organizations: PropTypes.array.isRequired,
  handleDownloadScientificProfileFile: PropTypes.func.isRequired,
};
