import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Login from 'features/Authentication/Login';

import TokenService from 'utils/axios/token.axios';

function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t('websiteName');
  }, [t]);

  // Check user is logged
  useEffect(() => {
    const user = TokenService.getUser();
    if (user?.id) {
      navigate('/');
    }
  }, []);

  return <Login />;
}

export default LoginPage;
