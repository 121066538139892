import { Controller, get } from 'react-hook-form';

import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputMask } from 'primereact/inputmask';

export default function MaskInput({
  label,
  name,
  placeholder,
  isRequired,
  disabled,
  control,
  errors,
  mask,
  isHighlighted,
}) {
  const { t } = useTranslation();
  return (
    <div className={classNames('field', { highlighted: isHighlighted })}>
      <label htmlFor={name}>
        {label}{' '}
        {isRequired && (
          <span className="text-red-500" style={{ fontWeight: 900 }}>
            *
          </span>
        )}
      </label>
      <Controller
        control={control}
        name={name}
        defaultValue={undefined}
        render={({ field: { onChange, value, ref } }) => (
          <InputMask
            id={name}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            mask={mask}
            placeholder={placeholder}
            ref={ref}
            autoClear={false}
            slotChar=" "
            className={classNames({ 'p-invalid': !!get(errors, name), 'surface-200': disabled })}
            tooltip={t(get(errors, name)?.message)}
            tooltipOptions={{ position: 'bottom' }}
            disabled={disabled}
          />
        )}
      />
    </div>
  );
}

MaskInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  mask: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
};

MaskInput.defaultProps = {
  label: '',
  placeholder: '',
  isRequired: false,
  disabled: false,
  isHighlighted: false,
};
