/* eslint-disable */
import { forwardRef, useImperativeHandle, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// import { create, getBySearchTitle } from 'apis/books.api';

import { Combobox, NumberInput, Select, TextInput, TextareaInput } from 'components/FormControl';

import { yupResolver } from '@hookform/resolvers/yup';
import { TOAST } from 'constant';
import { toast } from 'layout';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { domesticBookSchema } from 'features/ScientificProfile/validation';

const formOptions = { resolver: yupResolver(domesticBookSchema) };

const DomesticBookDialog = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [domesticBookControl, setDomesticBookControl] = useState();
  const [visible, setVisible] = useState(false);

  const contributorRoles = [
    { value: 'author', name: t('contributorRole.author') },
    { value: 'coAuthor', name: t('contributorRole.coAuthor') },
  ];

  const {
    control,
    watch,
    setValue,
    reset,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({
    ...formOptions,
    mode: 'onChange',
  });

  useImperativeHandle(
    ref,
    () => ({
      open: (_domesticBookControl) => {
        setDomesticBookControl(_domesticBookControl);
        setVisible(true);
        if (_domesticBookControl.type === 'INSERT') {
          reset({});
        } else if (_domesticBookControl.type === 'UPDATE') {
          reset(_domesticBookControl.value);
        }
      },
    }),
    []
  );

  const handleAddDomesticBook = async () => {
    setValue('book.publicationType', 'DOMESTIC');

    const isValidTrigger = await trigger();
    if (!isValidTrigger) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
      return;
    }

    const data = watch();

    // const { role, pseudonym, book } = data;

    const { insert, update, index, type } = domesticBookControl;

    if (type === 'INSERT' && insert) {
      insert(index, data);
    } else if (type === 'UPDATE' && update) {
      update(index, data);
    }
    reset({});
    setVisible(false);
  };

  // #endregion Event

  return (
    <>
      <Dialog
        header={
          domesticBookControl?.type === 'INSERT'
            ? t('scientificProfile.scientificWork.book.domestic.add')
            : t('scientificProfile.scientificWork.book.domestic.update')
        }
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        draggable={false}
        className="w-full sm:w-7 lg:w-6"
      >
        <div className="grid p-fluid">
          <div className="col-12">
            <TextInput
              placeholder={t('scientificProfile.scientificWork.enterTitle')}
              label={t('scientificProfile.scientificWork.book.title')}
              name="book.title"
              control={control}
              errors={errors}
              isRequired
            />
          </div>
          <div className="col-12">
            <TextInput
              label={t('scientificProfile.scientificWork.book.projectCode')}
              name="book.projectCode"
              control={control}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <TextareaInput
              label={t('scientificProfile.scientificWork.book.publisher')}
              name="book.publisher"
              control={control}
              errors={errors}
              isRequired
            />
          </div>

          <div className="col-6">
            <NumberInput
              label={t('scientificProfile.scientificWork.book.year')}
              name="book.year"
              control={control}
              errors={errors}
              min={0}
              isRequired
            />
          </div>

          <div className="col-6">
            <Select
              label={t('scientificProfile.scientificWork.book.authorOrCoauthor')}
              name="role"
              options={contributorRoles}
              control={control}
              errors={errors}
              inputField="value"
              isRequired
            />
          </div>
          <div className="col-6">
            <TextareaInput
              label={t('scientificProfile.scientificWork.book.pseudonym')}
              name="pseudonym"
              control={control}
              errors={errors}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-content-end mt-4">
          <Button
            label={t('formLayout.action.cancel')}
            severity="danger"
            onClick={() => {
              setVisible(false);
            }}
            className="mr-1 w-8rem"
          />
          <Button
            label={
              domesticBookControl?.type === 'INSERT'
                ? t('formLayout.action.add')
                : t('formLayout.action.update')
            }
            severity="info"
            type="submit"
            className="w-8rem"
            onClick={handleAddDomesticBook}
            disabled={!Object.keys(dirtyFields).length}
          />
        </div>
      </Dialog>

      {/* {<Loading />} */}
    </>
  );
});

DomesticBookDialog.propTypes = {};

DomesticBookDialog.defaultProps = {};

export default DomesticBookDialog;
