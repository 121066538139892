import PublicProfile from 'features/ScientificProfile/pages/PublicProfilePage';
import { LayoutProvider } from 'layout/context/layoutcontext';
import { useParams } from 'react-router-dom';

function PublicProfilePage() {
  const { idProfile } = useParams();
  return (
    <LayoutProvider>
      <PublicProfile idProfile={idProfile} />
    </LayoutProvider>
  );
}

export default PublicProfilePage;
