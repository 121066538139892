import './style.scss';

import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { getByEmail as getContactByEmail } from 'apis/contacts.api';
import { getById as getOrganizationById } from 'apis/organizations.api';
import { getAll as getAllPrograms } from 'apis/programs.api';
import {
  completeUpdateRequests,
  downloadFile,
  downloadScientificProfileFile,
  getAllOrganizations,
  getAll as getAllProjectProposals,
  getAllProjectRoles,
  getAllProposedExpenditureTypes,
  getAllResearchFields,
  getAllResearchKpis,
  getAllResearchTypes,
  getOwner,
  updateStatus,
} from 'apis/projectProposals.api';
import { getMe } from 'apis/users.api';
import { getAllBasicInfo as getAllPubProjectProposals } from 'apis/pubProjectProposals.api';

import Loading from 'components/Loading';

import {
  PROJECT_PROPOSAL_STATUSES,
  PROJECT_ROLES,
  PROJECT_TYPES,
  RESEARCH_TYPES,
  TOAST,
} from 'constant';
import { toast } from 'layout';
import PropTypes from 'prop-types';
import {
  formatFileName,
  getAcadamicLevelShortHand,
  mapError,
  setOrganizationValues,
  toNonAccentVietnamese,
} from 'utils/func';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { Message } from 'primereact/message';
import { classNames } from 'primereact/utils';

import {
  ExpenditureForm,
  GeneralInfoForm,
  ResearchDescriptionForm,
  ResearchResultForm,
  ReviewForm,
} from '..';
import Confirm from '../Confirm';

const statusColorMapping = {
  revise: 'var(--orange-500)',
  new: 'var(--blue-500)',
  submit: 'var(--green-500)',
  approved: 'var(--green-500)',
};

export default function Form({
  control,
  errors,
  watch,
  disabledSave,
  setValue,
  handleSubmit,
  getValues,
  tab,
  nextTab,
  selectedTabs,
  checkValidation,
  checkKeyDown,
  disabled,
  isDirty,
  dirtyFields,
  footerConfirmationPrompt,
  hasUpdateRequest,
  projectProposalResearchKpis,
}) {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [successSubmitDialogVisible, setSuccessSubmitDialogVisible] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const projectProposalId = useMemo(() => id ?? getValues('id'), [id, getValues('id')]);
  const user = JSON.parse(localStorage.getItem('user'));
  const dialogCompleteUpdateRequestsRef = useRef();

  const {
    mutate: mutateUpdateStatus,
    isSuccess: isUpdateStatusSuccess,
    isLoading: isUpdateStatusLoading,
    isError: isUpdateStatusError,
    error: updateStatusError,
  } = useMutation(updateStatus);

  const { mutate: mutateCompleteUpdateRequests } = useMutation(completeUpdateRequests);

  const { data: programsData, isLoading: isProgramsLoading } = useQuery(
    'programs-for-request-update',
    () => getAllPrograms({ forRequestUpdate: hasUpdateRequest }),
    { enabled: tab === 4, staleTime: 1000 * 60 * 5 }
  );
  const programs = useMemo(() => programsData?.data ?? [], [programsData]);

  const { data: researchFieldsData } = useQuery(
    ['projectProposalResearchFields'],
    getAllResearchFields,
    { enabled: tab === 0 || tab === 4, staleTime: 1000 * 60 * 5 }
  );
  const { data: researchTypesData } = useQuery(
    ['projectProposalResearchTypes'],
    getAllResearchTypes,
    { enabled: tab === 0 || tab === 4, staleTime: 1000 * 60 * 5 }
  );
  const { data: organizationsData, refetch: refetchOrganizations } = useQuery(
    ['projectProposalOrganizations'],
    getAllOrganizations,
    { enabled: tab === 0 || tab === 4, staleTime: 1000 * 60 * 5 }
  );
  const { data: projectRolesData } = useQuery(['projectProposalProjectRoles'], getAllProjectRoles, {
    enabled: tab === 0 || tab === 4,
    staleTime: 1000 * 60 * 5,
  });
  const { data: pubProjectData } = useQuery(['pubProjectProposals'], getAllPubProjectProposals, {
    enabled: tab === 0,
    staleTime: 1000 * 60 * 5
  });
  const { data: researchKpisData } = useQuery(['projectProposalResearchKpis'], getAllResearchKpis, {
    enabled: tab === 1 || tab === 4,
    staleTime: 1000 * 60 * 5,
  });
  const { data: expenditureTypesData } = useQuery(
    ['projectProposalProposedExpenditureTypes'],
    getAllProposedExpenditureTypes,
    {
      enabled: tab === 2 || tab === 4,
      staleTime: 1000 * 60 * 5,
    }
  );

  // Get me to check constraint required scientific profile url
  const { data: me } = useQuery('me', getMe, { enabled: tab === 4 && !selectedTabs[4] });
  // Get projectList to check constraint submit more than one project per year
  const { data: projectList } = useQuery('projectProposals', getAllProjectProposals, {
    enabled: tab === 4 && !selectedTabs[4],
  });

  const researchFields = useMemo(
    () =>
      researchFieldsData?.data.map((e) => ({
        id: e.id,
        name: e.code ? t(`researchFields.${e.code}`) : e.name,
        code: e.code,
      })) ?? [],
    [researchFieldsData, t]
  );

  const researchTypes = useMemo(
    () =>
      researchTypesData?.data.map((e) => ({
        id: e.id,
        name: e.code ? t(`researchTypes.${e.code}`) : e.name,
        code: e.code,
      })) ?? [],
    [researchTypesData, t]
  );

  const organizations = useMemo(
    () =>
      organizationsData?.data.map((e) => ({
        ...e,
        name: t('lang') === 'vi' ? e?.name : e?.englishName,
      })) ?? [],
    [organizationsData, t]
  );

  const projectRoles = useMemo(() => projectRolesData?.data ?? [], [projectRolesData]);

  const researchKpis = useMemo(() => researchKpisData?.data ?? [], [researchKpisData]);

  const pubProjects = useMemo(() => pubProjectData?.data ?? [], [pubProjectData]);

  const expenditureTypes = useMemo(() => {
    const types = expenditureTypesData?.data ?? [];
    return types?.sort((a, b) => a.order - b.order);
  }, [expenditureTypesData]);

  const { mutate: bindOwner } = useMutation(getOwner);

  const { mutate: bindOrganization } = useMutation(getOrganizationById);

  const { mutate: bindContact } = useMutation(getContactByEmail);

  const { mutate: mutateDownloadFile } = useMutation(downloadFile);

  const { mutate: mutateDownloadScientificProfileFile } = useMutation(
    downloadScientificProfileFile
  );
  // #endregion Data

  // #region Event
  const handleBindingIntoOwnerFieldClick = () => {
    bindOwner(null, {
      onSuccess: (res) => {
        if (res?.data) {
          const ownerRole = projectRoles.find((e) => e.code === PROJECT_ROLES.OWNER);
          if (ownerRole) {
            const fetchedOwner = {
              id: res?.data?.id ?? '', // contact id
              fullname: res?.data?.fullname ?? '',
              dob: res?.data?.scientificProfile?.dob
                ? new Date(res?.data?.scientificProfile?.dob)
                : null,
              gender: res?.data?.scientificProfile?.gender ?? '',
              citizenId: res?.data?.scientificProfile?.citizenId ?? '',
              dateOfIssue: res?.data?.scientificProfile?.dateOfIssue
                ? new Date(res?.data?.scientificProfile?.dateOfIssue)
                : null,
              placeOfIssue: res?.data?.scientificProfile?.placeOfIssue ?? '',
              taxIdentificationNumber: res?.data?.scientificProfile?.taxIdentificationNumber ?? '',
              academicDegree: res?.data?.scientificProfile?.academicDegree ?? '',
              academicRank: res?.data?.scientificProfile?.academicRank ?? '',
              researchLines: res?.data?.scientificProfile?.researchLines ?? [],
              bank: res?.data?.scientificProfile?.bank ?? '',
              bankAccount: res?.data?.scientificProfile?.bankAccount ?? '',
              workplace:
                res?.data?.organization?.englishName === 'Other'
                  ? res?.data?.specificOrganizationName
                  : res?.data?.organization?.name ?? '',
              mobile: res?.data?.scientificProfile?.personalPhone ?? '',
              email: res?.data?.user?.email ?? res?.data?.scientificProfile?.personalEmail ?? '',
              contact: { ...res?.data },
              organizationId: res?.data?.organizationId ?? '',
              organization: res?.data?.organization,
              specificOrganizationName:
                res?.data?.organization.englishName === 'Other'
                  ? res?.data?.specificOrganizationName
                  : null,
              scientificProfileUrl: res?.data?.user?.scientificProfileUrl ?? '',
              projectRole: ownerRole,
              projectRoleId: ownerRole?.id,
            };

            setValue('general.owner', fetchedOwner, { shouldDirty: true });
            setValue(
              'general.owner.researchLines',
              res?.data?.scientificProfile?.researchLines ?? [],
              { shouldDirty: true }
            );
            toast(TOAST.SUCCESS, t('projectProposal.getDataSuccessMessage'));
          }
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.getDataErrorMessage'));
      },
    });
  };

  const handleBindingIntoLeadOrganizationFieldClick = (_organizationId) => {
    bindOrganization(_organizationId, {
      onSuccess: (res) => {
        if (res?.data) {
          setOrganizationValues(setValue, 'general.lead', res?.data, 'LEAD');
          setValue('general.lead.specificName', '', { shouldDirty: true });

          // refetch to update organization list, so that the Select formcontrol render proper name
          refetchOrganizations();

          toast(TOAST.SUCCESS, t('projectProposal.getDataSuccessMessage'));
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.getDataErrorMessage'));
      },
    });
  };

  const handleBindingIntoContactFieldClick = (email, update, index, value) => {
    bindContact(
      { email },
      {
        onSuccess: (res) => {
          if (res?.data) {
            update(index, {
              ...value,
              email: res.data.email,
              contact: res.data,
              fullname: res?.data?.fullname,
              gender: res?.data?.scientificProfile?.gender ?? '',
              academicDegree: res?.data?.scientificProfile?.academicDegree ?? '',
              academicRank: res?.data?.scientificProfile?.academicRank ?? '',
              researchLines: res?.data?.scientificProfile?.researchLines ?? [],
              organizationId: res?.data?.organizationId,
              specificOrganizationName: res?.data?.specificOrganizationName,
            });
            toast(TOAST.SUCCESS, t('projectProposal.getDataSuccessMessage'));
          }
        },
        onError: () => {
          toast(TOAST.ERROR, t('projectProposal.getDataErrorMessage'));
        },
      }
    );
  };

  const handleDownloadFile = (path) => {
    mutateDownloadFile(
      {
        id: projectProposalId,
        path,
      },
      {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = formatFileName(path);

            link.click();
          }
        },
      }
    );
  };

  const handleDownloadScientificProfileFile = (
    contactId,
    contactName = '',
    academicRank = '',
    academicDegree = ''
  ) => {
    if (contactId && contactName) {
      mutateDownloadScientificProfileFile(contactId, {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = `${getAcadamicLevelShortHand(
              academicRank,
              academicDegree,
              t,
              true
            )}${toNonAccentVietnamese(contactName)}.pdf`;

            link.click();
          }
        },
        onError: () => {
          toast(TOAST.ERROR, t('projectProposal.general.a9.downloadScientificProfileErrorTitle'));
        },
      });
    }
  };

  const handleSubmitClick = () => {
    if (isDirty && Object.keys(dirtyFields).length) {
      toast(TOAST.WARN, t('projectProposal.unsavedErrorMessage'));
      return;
    }

    // Check constraint required scientific profile url
    if (me?.data?.scientificProfileUrl === null) {
      toast(TOAST.WARN, t('errorMessage.requiredScientificProfile'));
      return;
    }

    const projectType = getValues('general.projectType');
    const yearProposed = getValues('general.yearProposed');
    // Check constraint submit more than one project per year
    const userOwnerProjectList = projectList?.data?.filter(
      (project) =>
        project.owner.id === user.id &&
        project.projectType !== PROJECT_TYPES.CQG &&
        project.projectType !== PROJECT_TYPES.C &&
        project.projectType !== PROJECT_TYPES.REGULAR_TASKS_ACCORDING_TO_FUNCTION &&
        project.projectType !== PROJECT_TYPES.STRONG_RESEARCH_TEAM &&
        project.projectType !== PROJECT_TYPES.MAINTAIN_PROJECT
    );
    if (
      projectType !== PROJECT_TYPES.CQG &&
      projectType !== PROJECT_TYPES.C &&
      projectType !== PROJECT_TYPES.REGULAR_TASKS_ACCORDING_TO_FUNCTION &&
      projectType !== PROJECT_TYPES.STRONG_RESEARCH_TEAM &&
      projectType !== PROJECT_TYPES.MAINTAIN_PROJECT &&
      userOwnerProjectList?.length > 0
    ) {
      // eslint-disable-next-line no-restricted-syntax
      for (const p of userOwnerProjectList) {
        if (
          p.status.name === PROJECT_PROPOSAL_STATUSES.SUBMIT &&
          p.id !== projectProposalId &&
          p?.yearProposed &&
          p?.yearProposed === yearProposed
        ) {
          toast(TOAST.WARN, t('errorMessage.submittedOne'));
          return;
        }
      }
    }

    // Check constraint totalExpenditure > 0 (A5 & A12)
    const totalExpenditure = getValues('general.researchExpense.totalExpenditure');
    if (totalExpenditure === 0) {
      toast(TOAST.WARN, t('errorMessage.totalExpenditureMoreThanZero'));
      return;
    }

    // check constraint totalExpenditure <= proposedExpenditureByKpis
    // if projectType is 'A', 'B' and 'C'
    const proposedExpenditureByKpis = getValues('researchResult.proposedExpenditureByKpis');
    if (
      (yearProposed >= 2024) &&
      (projectType === PROJECT_TYPES.A ||
        projectType === PROJECT_TYPES.B ||
        projectType === PROJECT_TYPES.C) &&
      (totalExpenditure || 0) > (proposedExpenditureByKpis || 0)
    ) {
      toast(TOAST.WARN, t('errorMessage.totalExpenditureNoGreaterThanProposedExpenditureByKpis'));
      return;
    }

    // Check constraint owner (A6)
    const currentOwner = getValues('general.owner');
    const constraintsField = {
      academicDegree: currentOwner.academicDegree,
      fullname: currentOwner.fullname,
      dob: currentOwner.dob,
      citizenId: currentOwner.citizenId,
      dateOfIssue: currentOwner.dateOfIssue,
      placeOfIssue: currentOwner.placeOfIssue,
      taxIdentificationNumber: currentOwner.taxIdentificationNumber,
      bankAccount: currentOwner.bankAccount,
      bank: currentOwner.bank,
    };
    if (Object.values(constraintsField).some((e) => !e)) {
      toast(TOAST.WARN, t('projectProposal.ownerRequiredMessage'));
      return;
    }

    // Check constraint research results tab (A10)
    const researchResult = getValues('researchResult');
    const softProductsLength = researchResult?.softProducts?.length ?? 0;
    const hardProductsLength = researchResult?.hardProducts?.length ?? 0;
    const researchKpiValues = researchResult?.projectProposalResearchKpis
      ? Object.values(researchResult?.projectProposalResearchKpis)
      : [];
    const isResearchKpisDirty = researchKpiValues.findIndex((e) => e?.quantity > 0) > -1;
    if ((softProductsLength === 0 && hardProductsLength === 0) || !isResearchKpisDirty) {
      toast(TOAST.WARN, t('projectProposal.researchResultRequiredMessage'));
      return;
    }

    // Check constraint research description tab (A11)
    const researchTypesId = getValues('general.researchTypesId');
    const isImplementationResearchType =
      researchTypes.find((e) => e.id === researchTypesId)?.code === RESEARCH_TYPES.IMPLEMENTATION;
    if (
      (!researchResult?.knowledgeContributionLevel ||
        !researchResult?.practicalPolicyImpact ||
        !researchResult?.teamDevelopmentLevel) &&
      !isImplementationResearchType
    ) {
      toast(TOAST.WARN, t('projectProposal.researchResultA11RequiredMessage'));
      return;
    }
    if (
      (!researchResult?.knowledgeContributionLevel ||
        !researchResult?.practicalPolicyImpact ||
        !researchResult?.teamDevelopmentLevel) &&
      isImplementationResearchType
    ) {
      toast(TOAST.WARN, t('projectProposal.researchResultA11ImplementationRequiredMessage'));
      return;
    }

    // Check constraint expenditure detail (C)
    const expenditureDetailFileUrl = getValues('expenditure.uploadedFileUrl');
    if (!expenditureDetailFileUrl) {
      toast(TOAST.WARN, t('projectProposal.expenditureDetailRequiredMessage'));
      return;
    }
    // Pass all constraints
    setIsSubmitModalOpen(true);
  };

  const handleCompleteUpdateRequests = async () => {
    if (isDirty && Object.keys(dirtyFields).length) {
      toast(TOAST.WARN, t('projectProposal.unsavedErrorMessage'));
      return;
    }

    // Check constraint required scientific profile url
    if (me?.data?.scientificProfileUrl === null) {
      toast(TOAST.WARN, t('errorMessage.requiredScientificProfile'));
      return;
    }

    // Check constraint totlExpenditure > 0 (A5 & A12)
    const totalExpenditure = getValues('general.researchExpense.totalExpenditure');
    if (totalExpenditure === 0) {
      toast(TOAST.WARN, t('errorMessage.totalExpenditureMoreThanZero'));
      return;
    }

    // Check constraint owner (A6)
    const currentOwner = getValues('general.owner');
    const constraintsField = {
      academicDegree: currentOwner.academicDegree,
      fullname: currentOwner.fullname,
      dob: currentOwner.dob,
      citizenId: currentOwner.citizenId,
      dateOfIssue: currentOwner.dateOfIssue,
      placeOfIssue: currentOwner.placeOfIssue,
      taxIdentificationNumber: currentOwner.taxIdentificationNumber,
      bankAccount: currentOwner.bankAccount,
      bank: currentOwner.bank,
    };
    if (Object.values(constraintsField).some((e) => !e)) {
      toast(TOAST.WARN, t('projectProposal.ownerRequiredMessage'));
      return;
    }

    // Check constraint research results tab (A10)
    const researchResult = getValues('researchResult');
    const softProductsLength = researchResult?.softProducts?.length ?? 0;
    const hardProductsLength = researchResult?.hardProducts?.length ?? 0;
    const researchKpiValues = researchResult?.projectProposalResearchKpis
      ? Object.values(researchResult?.projectProposalResearchKpis)
      : [];
    const isResearchKpisDirty = researchKpiValues.findIndex((e) => e?.quantity > 0) > -1;
    if ((softProductsLength === 0 && hardProductsLength === 0) || !isResearchKpisDirty) {
      toast(TOAST.WARN, t('projectProposal.researchResultRequiredMessage'));
      return;
    }

    // Check constraint expenditure detail (C)
    const expenditureDetailFileUrl = getValues('expenditure.uploadedFileUrl');
    if (!expenditureDetailFileUrl) {
      toast(TOAST.WARN, t('projectProposal.expenditureDetailRequiredMessage'));
      return;
    }

    // If pass all constraints
    dialogCompleteUpdateRequestsRef.current.open({
      mode: 'completeUpdateRequest',
      mutate: mutateCompleteUpdateRequests,
      value: {
        projectProposalId: id,
        projectProposalReviseLogId: searchParams.get('reviseLogId'),
        reference: getValues('reference'),
      },
    });
  };
  // #endregion Event

  // #region Render
  const programItemTemplate = (option) => (
    <div className="flex align-items-center gap-1" style={{ width: '300px', whiteSpace: 'normal' }}>
      <span>{option.name}</span>
      {option.id === getValues('programId') && (
        <span
          className="font-bold font-italic"
          style={{ color: statusColorMapping[getValues('statusName')] }}
        >
          ({t(`projectProposal.viewList.statuses.${getValues('statusName')}`)})
        </span>
      )}
    </div>
  );

  const footerConfirmationSubmit = () => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={() => setIsSubmitModalOpen(false)}
        autoFocus
        disabled={isUpdateStatusLoading}
      />
      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={() => {
          if (!selectedProgramId) {
            toast(TOAST.ERROR, t('program.viewList.unselectProjectErrorMessage'));
          } else if (selectedProgramId) {
            mutateUpdateStatus(
              { projectId: getValues('id'), programId: selectedProgramId },
              {
                onSuccess: () => {
                  if (hasUpdateRequest) {
                    setIsSubmitModalOpen(false);
                    setValue('programId', selectedProgramId);
                    setSelectedProgramId();
                    toast(TOAST.SUCCESS, t('projectProposal.changeProgramSuccessMessage'));
                  } else {
                    setSuccessSubmitDialogVisible(true);
                  }
                },
                onError: () => {
                  setErrorDialogVisible(true);
                },
              }
            );
          }
        }}
        disabled={isUpdateStatusLoading}
      />
    </div>
  );

  return (
    <div
      className={classNames('project-proposal-form m-0 bg-white p-5 p-fluid', {
        'form-disabled': disabled,
      })}
    >
      {/* #region Loading */}
      <div className="sticky top-0 left-0 z-5">
        <div className="relative">{isProgramsLoading && <Loading />}</div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form onSubmit={handleSubmit} onKeyDown={checkKeyDown} autoComplete="off">
        <Fieldset disabled={disabled} className="border-none p-0">
          <div className={tab !== 0 ? 'hidden' : ''}>
            <GeneralInfoForm
              pubProjectProposals={pubProjects}
              researchFields={researchFields}
              researchTypes={researchTypes}
              organizations={organizations}
              projectRoles={projectRoles}
              errors={errors}
              control={control}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              disabled={disabled}
              isDirty={isDirty}
              dirtyFields={dirtyFields}
              footerConfirmationPrompt={footerConfirmationPrompt}
              onBindingIntoOwnerFieldClick={handleBindingIntoOwnerFieldClick}
              onBindingIntoLeadOrganizationFieldClick={handleBindingIntoLeadOrganizationFieldClick}
              onBindingIntoContactFieldClick={handleBindingIntoContactFieldClick}
              handleDownloadFile={handleDownloadFile}
              handleDownloadScientificProfileFile={handleDownloadScientificProfileFile}
            />
          </div>

          <div className={tab !== 1 ? 'hidden' : ''}>
            <ResearchResultForm
              researchTypes={researchTypes}
              researchKpis={researchKpis}
              researchFields={researchFields}
              projectProposalResearchKpis={projectProposalResearchKpis}
              watch={watch}
              setValue={setValue}
              errors={errors}
              control={control}
              disabled={disabled}
            />
          </div>

          <div className={tab !== 2 ? 'hidden' : ''}>
            <ExpenditureForm
              expenditureTypes={expenditureTypes}
              errors={errors}
              control={control}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
              handleDownloadFile={handleDownloadFile}
              disabled={disabled}
            />
          </div>

          <div className={tab !== 3 ? 'hidden' : ''}>
            <ResearchDescriptionForm
              errors={errors}
              control={control}
              getValues={getValues}
              handleDownloadFile={handleDownloadFile}
              disabled={disabled}
            />
          </div>
        </Fieldset>

        {tab === 4 && (
          <ReviewForm
            researchFields={researchFields}
            researchTypes={researchTypes}
            organizations={organizations}
            projectRoles={projectRoles}
            researchKpis={researchKpis}
            expenditureTypes={expenditureTypes}
            projectProposalResearchKpis={projectProposalResearchKpis}
            watch={watch}
            getValues={getValues}
            handleDownloadFile={handleDownloadFile}
            handleDownloadScientificProfileFile={handleDownloadScientificProfileFile}
          />
        )}

        {!disabled && (
          <div className="flex justify-content-end mt-2">
            {tab !== 4 && (
              <>
                <Button
                  type="submit"
                  className="btn-control font-semibold mr-3"
                  severity="success"
                  onClick={checkValidation}
                  disabled={disabledSave}
                >
                  {t('formLayout.save')}
                </Button>
                <Button type="submit" className="p-button-next btn-control" onClick={nextTab}>
                  {t('formLayout.next')}
                </Button>
              </>
            )}
            {tab === 4 && (
              <>
                {disabledSave ? (
                  <Button
                    type="button"
                    className="btn-control font-semibold mr-3"
                    severity="danger"
                    onClick={() => navigate('/')}
                  >
                    {t('formLayout.close')}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="btn-control font-semibold mr-3"
                    severity="success"
                    onClick={checkValidation}
                    disabled={disabledSave}
                  >
                    {t('formLayout.save')}
                  </Button>
                )}

                {hasUpdateRequest && getValues('version') === 1 && (
                  <Button
                    type="button"
                    className="btn-control font-semibold mr-3 w-auto"
                    severity="success"
                    onClick={handleSubmitClick}
                  >
                    {t('projectProposal.changeProgram')}
                  </Button>
                )}

                {hasUpdateRequest ? (
                  <Button
                    type="button"
                    className="btn-control font-semibold mr-3 w-auto"
                    severity="success"
                    onClick={handleCompleteUpdateRequests}
                  >
                    {t('projectProposal.finishUpdate')}
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className="btn-control font-semibold mr-3"
                    severity="success"
                    onClick={handleSubmitClick}
                  >
                    {t('formLayout.submit')}
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </form>

      {/* #region Update status */}
      <Dialog
        header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
        visible={isSubmitModalOpen}
        position="center"
        style={{ width: '400px' }}
        onHide={() => setIsSubmitModalOpen(false)}
        draggable={false}
        closable={!isUpdateStatusLoading}
        footer={footerConfirmationSubmit}
      >
        {isUpdateStatusLoading && <Loading />}

        <label htmlFor="programId" className="inline-block mb-2">
          {t('projectProposal.review.program')}{' '}
          <span className="text-red-500" style={{ fontWeight: 900 }}>
            *
          </span>
        </label>

        <Dropdown
          id="programId"
          value={selectedProgramId}
          options={programs}
          onChange={(e) => {
            setSelectedProgramId(e.value);
          }}
          optionLabel="name"
          optionValue="id"
          itemTemplate={programItemTemplate}
          showClear
          placeholder={
            hasUpdateRequest
              ? t('projectProposal.review.selectProgram')
              : t('projectProposal.review.selectProgramToSubmit')
          }
          style={{ width: '100%' }}
          disabled={isUpdateStatusLoading}
        />
        {!hasUpdateRequest && (
          <Message
            className="mt-4 w-full"
            severity="warn"
            text={t('program.confirmationSubmitMessage')}
          />
        )}
      </Dialog>
      {isUpdateStatusSuccess && (
        <Dialog
          header={<p className="text-green-500">{t('projectProposal.confirmationTitle')}</p>}
          visible={successSubmitDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => navigate('/')}
          draggable={false}
          // eslint-disable-next-line react/no-unstable-nested-components
          footer={() => (
            <Button
              label={t('projectProposal.ok')}
              severity="success"
              onClick={() => navigate('/')}
            />
          )}
        >
          <p className="text-lg py-3">{t('projectProposal.submitSuccessMessage')}</p>
        </Dialog>
      )}
      {isUpdateStatusError && (
        <Dialog
          header={<p className="text-red-500">{t('projectProposal.updateErrorTitle')}</p>}
          visible={errorDialogVisible}
          position="center"
          style={{ width: '400px' }}
          onHide={() => setErrorDialogVisible(false)}
          draggable={false}
        >
          {Array.isArray(updateStatusError?.response.data.message) ? (
            updateStatusError.response.data.message.map((message) => (
              <p className="text-lg text-red-500">{message}</p>
            ))
          ) : (
            <p className="text-lg text-red-500 py-3">{mapError(t, updateStatusError)}</p>
          )}
        </Dialog>
      )}
      {/* #endregion Update status */}

      <Confirm ref={dialogCompleteUpdateRequestsRef} />
    </div>
  );
}

Form.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  disabledSave: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired,
  nextTab: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedTabs: PropTypes.array,
  checkValidation: PropTypes.func.isRequired,
  checkKeyDown: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  dirtyFields: PropTypes.object,
  isDirty: PropTypes.bool,
  footerConfirmationPrompt: PropTypes.func,
  hasUpdateRequest: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  projectProposalResearchKpis: PropTypes.array,
};

Form.defaultProps = {
  disabled: false,
  selectedTabs: [],
  dirtyFields: {},
  isDirty: false,
  footerConfirmationPrompt: () => null,
  hasUpdateRequest: false,
  projectProposalResearchKpis: [],
};
