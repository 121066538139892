import Layout from 'layout';
import ProjectProposalRequest from 'features/ProjectProposal/pages/Request';
import { LayoutProvider } from '../layout/context/layoutcontext';

function ProjectProposalRequestPage() {
  return (
    <LayoutProvider>
      <Layout>
        <ProjectProposalRequest />
      </Layout>
    </LayoutProvider>
  );
}

export default ProjectProposalRequestPage;
