import PropTypes from 'prop-types';

import { useForm, useFormState } from 'react-hook-form';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { getLiquidationById } from 'apis/liquidation.api';
import { getProjectById } from 'apis/project.api';

import LiquidationForm from '../../components/LiquidationForm';

function LiquidationAgreement({ readOnly }) {
  // #region Data
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { control, watch, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const { errors, isDirty, dirtyFields } = useFormState({
    control,
  });

  const [appendixFilesDelete, setAppendixFilesDelete] = useState([]);

  const { data: _data, refetch } = useQuery({
    queryKey: ['liquidation', projectId],
    queryFn: () => getLiquidationById(projectId),
  });

  const liquidation = useMemo(() => _data?.data ?? null, [_data]);

  const { data: projectData } = useQuery({
    queryKey: ['project', projectId],
    queryFn: () => getProjectById(projectId),
  });

  const liquidationStatus = useMemo(
    () => projectData?.data?.acceptanceCouncilStatus ?? null,
    [projectData]
  );

  useEffect(() => {
    if (liquidation) {
      setValue('vnuExpenditure', liquidation?.vnuExpenditure || 0);
      setValue('suggestedExpenditure', liquidation?.suggestedExpenditure || 0);
      setValue('acceptedExpenditure', liquidation?.acceptedExpenditure || 0);
      setValue('stateReimbursementExpenditure', liquidation?.stateReimbursementExpenditure || 0);
      setValue('liquidationDate', new Date(liquidation?.liquidationDate));
      setValue('code', liquidation?.code);
    }
  }, [liquidation]);

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const footerConfirmationPrompt = (onConfirm, onCancel) => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={onCancel}
        autoFocus
      />

      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirm}
      />
    </div>
  );

  return (
    <div className="tab-view-create-contract">
      <LiquidationForm
        control={control}
        errors={errors}
        watch={watch}
        refetch={refetch}
        setValue={setValue}
        checkKeyDown={checkKeyDown}
        isDirty={isDirty}
        dirtyFields={dirtyFields}
        footerConfirmationPrompt={footerConfirmationPrompt}
        liquidation={liquidation}
        readOnly={readOnly}
        appendixFilesDelete={appendixFilesDelete}
        setAppendixFilesDelete={setAppendixFilesDelete}
        isCompleted={!!liquidationStatus}
      />
    </div>
  );
}

LiquidationAgreement.propTypes = {
  readOnly: PropTypes.bool,
};

LiquidationAgreement.defaultProps = { readOnly: false };

export default LiquidationAgreement;
