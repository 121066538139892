// import './style.scss';

/* eslint-disable indent */
// import { yupResolver } from '@hookform/resolvers/yup';
import {
  // update as updateMidtermReview,
  // submit as submitMidtermReview,
  getDetail,
} from 'apis/midtermReviews.api';
// eslint-disable-next-line import/no-cycle
import { Detail } from 'features/MidtermReview/components';
// import { updateMidtermReviewValidationSchema } from 'features/MidtermReview/validate';
// import { Button } from 'primereact/button';
// import { Dialog } from 'primereact/dialog';
// import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import {
  // useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Loading from 'components/Loading';
// import ReactRouterPrompt from 'react-router-prompt';
// import { toast } from 'layout';
// import { TOAST } from 'constant';
// import ErrorDialog from 'components/ErrorDialog';
// import { defaultMidtermReview } from '../../_constant';

export default function MidtermReviewFormDetail() {
  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');

  const [isVisibleErrorDialog, setIsVisibleDialogError] = useState(false);
  // const [isVisibleSubmitDialog, setIsVisibleSubmitDialog] = useState(false);

  const {
    // handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: {
      errors,
      // , isValid
    },
  } = useForm({
    mode: 'onChange',
    // resolver: yupResolver(updateMidtermReviewValidationSchema),
  });
  const { isDirty, dirtyFields } = useFormState({ control });

  const {
    // mutate: mutateUpdate,
    error: errorRes,
    isError: isErrorRes,
    isLoading: isLoadingCreate,
  } = useMutation(() => true);

  const {
    data: midtermReviewData,
    isLoading: isLoadingMidtermReview,
    // refetch: refetchMidtermReview,
  } = useQuery(['midterm-review'], () => getDetail(councilMemberId, councilId, projectProposalId));

  const midtermReviews = useMemo(() => midtermReviewData?.data?.midtermReview, [midtermReviewData]);

  useEffect(() => {
    setValue('reviewResearchPlan', midtermReviews?.reviewResearchPlan);
    setValue('reviewProduct', midtermReviews?.reviewProduct);
    setValue('reviewProgress', midtermReviews?.reviewProgress);
    setValue('reviewExpenditureUsed', midtermReviews?.reviewExpenditureUsed);
    setValue('reviewContinueAbility', midtermReviews?.reviewContinueAbility);
    setValue('otherReview', midtermReviews?.otherReview);
    setValue('requestStop', midtermReviews?.requestStop);
    setValue('requestStopReason', midtermReviews?.requestStopReason);
    setValue('requestContinue', midtermReviews?.requestContinue);
    setValue('requestContinueModify', midtermReviews?.requestContinueModify);
    setValue('evaluate.evaluateResearchPlan.isQualified', midtermReviews?.evaluateResearchPlan);
    setValue('evaluate.evaluateResearchResult.isQualified', midtermReviews?.evaluateResearchResult);
    setValue('evaluate.evaluateProgress.isQualified', midtermReviews?.evaluateProgress);
    setValue(
      'evaluate.evaluateExpenditureUsed.isQualified',
      midtermReviews?.evaluateExpenditureUsed
    );
    setValue('evaluate.evaluateGeneral.isQualified', midtermReviews?.evaluateGeneral);
    setValue('evaluate.evaluateResearchPlan.isNotQualified', !midtermReviews?.evaluateResearchPlan);
    setValue(
      'evaluate.evaluateResearchResult.isNotQualified',
      !midtermReviews?.evaluateResearchResult
    );
    setValue('evaluate.evaluateProgress.isNotQualified', !midtermReviews?.evaluateProgress);
    setValue(
      'evaluate.evaluateExpenditureUsed.isNotQualified',
      !midtermReviews?.evaluateExpenditureUsed
    );
    setValue('evaluate.evaluateGeneral.isNotQualified', !midtermReviews?.evaluateGeneral);
  }, [midtermReviews]);

  // #endregion

  return (
    <>
      {isLoadingCreate && isLoadingMidtermReview && <Loading />}
      <Detail
        isLoadingCreate={isLoadingCreate}
        isErrorRes={isErrorRes}
        errorRes={errorRes}
        // handleSubmit={handleSubmit}
        // onSubmit={onSubmit}
        control={control}
        watch={watch}
        setValue={setValue}
        getValues={getValues}
        isDirty={isDirty}
        dirtyFields={dirtyFields}
        errors={errors}
        // isValid
        reset={reset}
        isVisibleErrorDialog={isVisibleErrorDialog}
        setIsVisibleDialogError={setIsVisibleDialogError}
      />
    </>
  );
}
