import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function Expenditure({ data }) {
  // #region Data
  const { t } = useTranslation();
  return (
    <div>
      <h4>{t('pubProjectProposal.expenditure.viewLabel')}</h4>
      <div
        className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines"
        data-scrollselectors=".p-datatable-wrapper"
      >
        <div className="p-datatable-wrapper">
          <table className="p-datatable-table">
            <thead className="p-datatable-thead">
              <tr>
                <th style={{ minWidth: '12rem', textAlign: 'center' }}>
                  <span className="p-column-title">
                    {t('pubProjectProposal.expenditure.a12.fields.term')}
                  </span>
                </th>
                <th style={{ minWidth: '12rem', textAlign: 'center' }}>
                  <span className="p-column-title">
                    {t('pubProjectProposal.expenditure.a12.fields.total')}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="p-datatable-tbody">
              <tr>
                <td className="font-bold">{t('pubProjectProposal.expenditure.a12.total')}</td>
                <td className="text-center">
                  {data?.proposedExpenditureSummaries?.totalExpenditure?.toLocaleString('vi')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

Expenditure.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};
