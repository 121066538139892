import * as Yup from 'yup';
import { maxPoints } from './_constant';

const mapRules = (map, rule) => {
  if (!map) return {};
  return Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});
};

const allowIdExpeditureTypeNull = [
  '2acuely5-t11i-3286-53kb-69316ba3c50c',
  '693f6feb-5467-4cd3-a26c-4eee9fc052cd',
  'd0044360-c1c6-499d-8b53-491217e638b9',
];

// eslint-disable-next-line no-unused-vars
const expenditureSchema = Yup.lazy((obj) =>
  Yup.object(
    mapRules(
      obj,
      Yup.object({
        high: Yup.boolean()
          .nullable()
          .test({
            message: 'formControl.validation.chooseOneOfThree',
            test: (value, context) => {
              const idExpenditureType = context.path.split('.')[1];
              // allow null term 1.3, 2, 3
              if (allowIdExpeditureTypeNull.includes(idExpenditureType)) {
                return true;
              }
              const { high, low, suitable } = context.parent;
              if (high === true || low === true || suitable === true) {
                return true;
              }
              return false;
            },
          }),
        low: Yup.boolean()
          .nullable()
          .test({
            message: 'formControl.validation.chooseOneOfThree',
            test: (value, context) => {
              const idExpenditureType = context.path.split('.')[1];
              // allow null term 1.3, 2, 3
              if (allowIdExpeditureTypeNull.includes(idExpenditureType)) {
                return true;
              }
              const { high, low, suitable } = context.parent;
              if (high === true || low === true || suitable === true) {
                return true;
              }
              return false;
            },
          }),
        suitable: Yup.boolean()
          .nullable()
          .test({
            message: 'formControl.validation.chooseOneOfThree',
            test: (value, context) => {
              const idExpenditureType = context.path.split('.')[1];
              // allow null term 1.3, 2, 3
              if (allowIdExpeditureTypeNull.includes(idExpenditureType)) {
                return true;
              }
              const { high, low, suitable } = context.parent;
              if (high === true || low === true || suitable === true) {
                return true;
              }
              return false;
            },
          }),
      })
    )
  )
);

const scoreSchema = Yup.object({
  scoreCreativity: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkScoreCreativity',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreCreativity } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreCreativity) return false;
        return true;
      },
    }),

  scoreSuitableness: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkScoreSuitableness',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreSuitableness } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreSuitableness) return false;
        return true;
      },
    }),

  scoreQuality: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkScoreQuality',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreQuality } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreQuality) return false;
        return true;
      },
    }),

  scoreAnnouncement: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkScoreAnnouncement',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreAnnouncement } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreAnnouncement) return false;
        return true;
      },
    }),

  scoreTraining: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkScoreTraining',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreTraining } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreTraining) return false;
        return true;
      },
    }),

  scoreCapability: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkScoreCapability',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreCapability } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreCapability) return false;
        return true;
      },
    }),

  scoreSubCriteria: Yup.number()
    .nullable()
    .min(0, 'formControl.specializeReview.minScore')
    .test({
      name: 'checkScoreSubCriteria',
      message: 'formControl.specializeReview.maxScore',
      // eslint-disable-next-line no-unused-vars
      test: (value, context) => {
        if (!value) {
          return true;
        }
        const { scoreSubCriteria } = maxPoints;
        if (parseInt(value, 10) < 0 || parseInt(value, 10) > scoreSubCriteria) return false;
        return true;
      },
    }),
});

// eslint-disable-next-line import/prefer-default-export
export const createSpecializeReviewValidationSchema = Yup.object().shape({
  reviewImportance: Yup.string().nullable(),
  reviewQuality: Yup.string().nullable(),
  reviewPicapability: Yup.string().nullable(),
  reviewConclusion: Yup.string().nullable(),
  expenditureTotalSuggestion: Yup.string().nullable(),
  score: scoreSchema,
});
