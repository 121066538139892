/* eslint-disable react/jsx-curly-newline */
import './style.scss';

import { Fieldset } from 'primereact/fieldset';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { getAllResearchKpiGroups, getAllResearchKpis } from 'apis/projectProposals.api';
import { useQuery, useMutation } from 'react-query';
import {
  formatFileName,
  getExpenditureForExpenditureTableOfSummaryReport,
  setExpenditureTableOfSummaryReport,
} from 'utils/func';

import {
  getSummaryReportProductType,
  getSummaryReportProductGroup,
  createSummaryReport,
  updateSummaryReport,
  getSummaryReportType,
  uploadProductFile,
  getSummaryReportExpenditureType,
  updateProductFile,
  submitSummaryReport,
  downloadFileReport,
  updateExpenditureFile,
  getSummaryReport,
  deleteProductFile,
} from 'apis/summaryReport';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'layout';
import {
  SUMMARY_REPORT_PRODUCT_GROUP_ENUM,
  SUMMARY_REPORT_PRODUCT_TYPE_ENUM,
  SUMMARY_REPORT_TYPE,
  TOAST,
} from 'constant';

import Loading from 'components/Loading';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { getProjectCode } from 'apis/project.api';
import CustomFileInput from 'components/FormControl/CustomFileInput';
import GeneralInfo from '../../ReportFormR05/GeneralInfo';
import ExpenditureTable from '../../ReportFormR05/ExpenditureTable';
import Review from '../../ReportFormR05/Review';
import Product from '../../Product';
import ContentCompletedTable from '../../ReportFormR05/ContentCompletedTable';
import ContentNotCompletedTable from '../../ReportFormR05/ContentNotCompletedTable';
import ReviewForm from '../../ReviewForm';
import WorkshopTable from '../../ReportFormR05/WorkshopTable';
import ParticipantsTable from '../../ReportFormR05/ParticipantsTable';
import UploadMultipleFile from '../../ReportFormR05/UploadMultipleFile';

export default function SummaryReportCreateForm({
  isAcceptanceReport,
  handleSubmit,
  control,
  reset,
  setValue,
  getValues,
  errors,
  tab,
  setTab,
  isDirty,
  dirtyFields,
  checkValidation,
}) {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const [isWillNext, setIsWillNext] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [summaryReportAppendixeArray, setSummaryReportAppendixeArray] = useState([]);
  const [appendixFilesDelete, setAppendixFilesDelete] = useState([]);
  const [resetAppendixFiles, setResetAppendixFiles] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isCreated, setIsCreated] = useState(false);
  const { mutate: mutateCreateSummary, isLoading: isCreateSummaryLoading } =
    useMutation(createSummaryReport);
  const { mutate: mutateUploadProductFile, isLoading: isUploadProductFileLoading } =
    useMutation(uploadProductFile);
  const { mutate: mutateUpdateSummary, isLoading: isUpdateSummaryLoading } =
    useMutation(updateSummaryReport);
  const { mutateAsync: mutateUpdateExpenditureFile, isLoading: isUpdateExpenditureFileLoading } =
    useMutation(updateExpenditureFile);
  const { mutateAsync: mutateUpdateProductFile, isLoading: isUpdateProductFileLoading } =
    useMutation(updateProductFile);
  const { mutate: mutateSubmitSummaryReport, isLoading: isSubmitSummaryReportLoading } =
    useMutation(submitSummaryReport);
  const { mutate: mutateDownloadFile, isLoading: isDownloadFileLoading } =
    useMutation(downloadFileReport);

  const { mutate: mutateDeleteFileAppendix, isLoading: isDeleteFileAppendix } =
    useMutation(deleteProductFile);

  // # Region data

  const { data: summaryReportTypesData } = useQuery(['summaryReportType'], getSummaryReportType);

  const summaryReportTypeId = useMemo(
    () =>
      summaryReportTypesData?.data?.find(
        (item) =>
          item.code ===
          (!isAcceptanceReport
            ? SUMMARY_REPORT_TYPE.MIDTERM_DOCUMENT
            : SUMMARY_REPORT_TYPE.ACCEPTANCE_DOCUMENT)
      )?.id,
    [summaryReportTypesData]
  );

  const { data: _projectCode } = useQuery({
    queryKey: () => ['projectCode'],
    queryFn: () => getProjectCode(projectId),
  });

  const projectCode = useMemo(() => _projectCode?.data?.projectCode ?? '', [_projectCode]);

  const { data: summaryReportExpenditureTypeData } = useQuery(
    ['summaryReportExpenditureType'],
    getSummaryReportExpenditureType
  );

  const summaryReportExpenditureType = useMemo(
    () => summaryReportExpenditureTypeData?.data ?? null,
    [summaryReportExpenditureTypeData]
  );

  const { data: _data } = useQuery({
    queryKey: ['researchKpis'],
    queryFn: getAllResearchKpis,
  });
  const researchKpis = useMemo(() => _data?.data ?? [], [_data]);

  const { data: researchKpiGroupsData } = useQuery({
    queryKey: ['researchKpiGroups'],
    queryFn: getAllResearchKpiGroups,
  });
  const researchKpiGroups = useMemo(
    () => researchKpiGroupsData?.data ?? [],
    [researchKpiGroupsData]
  );

  const { data: _summaryReportProductType } = useQuery({
    queryKey: ['summaryReportProductType'],
    queryFn: getSummaryReportProductType,
  });

  const summaryReportProductType = useMemo(
    () => _summaryReportProductType?.data ?? [],
    [_summaryReportProductType]
  );

  const { data: _summaryReportProductGroup } = useQuery({
    queryKey: ['summaryReportProductGroup'],
    queryFn: getSummaryReportProductGroup,
  });

  const summaryReportProductGroup = useMemo(
    () => _summaryReportProductGroup?.data ?? [],
    [_summaryReportProductGroup]
  );

  const summaryReportId = useMemo(
    () => getValues('summaryReportId'),
    [getValues('summaryReportId')]
  );
  const { data: _summaryReport, refetch } = useQuery({
    queryKey: ['midtermSummaryReport', summaryReportId],
    queryFn: () => getSummaryReport(summaryReportId),
    enabled: summaryReportId !== undefined,
  });

  const summaryReport = useMemo(() => _summaryReport?.data, [_summaryReport]);
  // # End region data

  // # Region Event
  const handleTabChange = () => {
    if (tab === 4) {
      return;
    }
    if (tab < 4 && isWillNext) {
      setTab((prev) => prev + 1);
    }
  };

  const onSubmit = async (data) => {
    if (isDirty && Object.keys(dirtyFields).length) {
      const newData = getExpenditureForExpenditureTableOfSummaryReport(
        summaryReportExpenditureType,
        data
      );

      const createOrUpdateSummaryDataSender = { ...newData };
      createOrUpdateSummaryDataSender.projectId = projectId;
      createOrUpdateSummaryDataSender.summaryReportTypeId = summaryReportTypeId;

      const expenditureFileFormData = new FormData();
      expenditureFileFormData.append('expenditureFile', data?.expenditureFile);

      const productFileFormData = new FormData();

      const softProductType = summaryReportProductType.find(
        (productType) => productType.code === SUMMARY_REPORT_PRODUCT_TYPE_ENUM.SOFT_PRODUCT
      );
      const hardProductType = summaryReportProductType.find(
        (productType) => productType.code === SUMMARY_REPORT_PRODUCT_TYPE_ENUM.HARD_PRODUCT
      );
      const scientificPublicationGroup = summaryReportProductGroup.find(
        (productGroup) =>
          productGroup.code === SUMMARY_REPORT_PRODUCT_GROUP_ENUM.SCIENTIFIC_PUBLICATION
      );
      const trainingResultGorup = summaryReportProductGroup.find(
        (productGroup) => productGroup.code === SUMMARY_REPORT_PRODUCT_GROUP_ENUM.TRAINING_RESULT
      );
      const intellectualPropertyGroup = summaryReportProductGroup.find(
        (productGroup) =>
          productGroup.code === SUMMARY_REPORT_PRODUCT_GROUP_ENUM.INTELLECTUAL_PROPERTY
      );

      if (data?.researchResult?.softProducts) {
        // eslint-disable-next-line no-restricted-syntax, no-unsafe-optional-chaining
        for (const softProduct of data?.researchResult?.softProducts) {
          const softProductSender = {
            summaryReportProductTypeId: softProductType?.id,
            summaryReportProductGroupId: scientificPublicationGroup?.id,
            researchKpiGroupId: softProduct?.researchKpiGroupId,
            researchKpiId: softProduct?.researchKpiId,
            productDetail: softProduct?.productDetail,
          };
          if (softProduct?.file) {
            productFileFormData.append('productFiles', softProduct?.file);
            softProductSender.hasFile = true;
          }
          if (softProduct?.id) {
            softProductSender.id = softProduct?.id;
          }
          productFileFormData.append('summaryReportProducts', JSON.stringify(softProductSender));
        }
      }

      if (data?.researchResult?.hardProducts) {
        // eslint-disable-next-line no-restricted-syntax, no-unsafe-optional-chaining
        for (const hardProduct of data?.researchResult?.hardProducts) {
          const hardProductSender = {
            summaryReportProductTypeId: hardProductType?.id,
            summaryReportProductGroupId: scientificPublicationGroup?.id,
            researchKpiGroupId: hardProduct?.researchKpiGroupId,
            researchKpiId: hardProduct?.researchKpiId,
            productDetail: hardProduct?.productDetail,
          };
          if (hardProduct?.file) {
            productFileFormData.append('productFiles', hardProduct?.file);
            hardProductSender.hasFile = true;
          }
          if (hardProduct?.id) {
            hardProductSender.id = hardProduct?.id;
          }
          productFileFormData.append('summaryReportProducts', JSON.stringify(hardProductSender));
        }
      }

      if (data?.researchResult?.intellectualProperty) {
        // eslint-disable-next-line no-restricted-syntax, no-unsafe-optional-chaining
        for (const intellectualProperty of data?.researchResult?.intellectualProperty) {
          const intellectualPropertySender = {
            summaryReportProductGroupId: intellectualPropertyGroup?.id,
            researchKpiGroupId: intellectualProperty?.researchKpiGroupId,
            researchKpiId: intellectualProperty?.researchKpiId,
            productDetail: intellectualProperty?.productDetail,
          };
          if (intellectualProperty?.file) {
            productFileFormData.append('productFiles', intellectualProperty?.file);
            intellectualPropertySender.hasFile = true;
          }
          if (intellectualProperty?.id) {
            intellectualPropertySender.id = intellectualProperty?.id;
          }
          productFileFormData.append(
            'summaryReportProducts',
            JSON.stringify(intellectualPropertySender)
          );
        }
      }

      if (data?.researchResult?.transferTechnology) {
        // eslint-disable-next-line no-restricted-syntax, no-unsafe-optional-chaining
        for (const transferTechnology of data?.researchResult?.transferTechnology) {
          const transferTechnologySender = {
            summaryReportProductGroupId: intellectualPropertyGroup?.id,
            researchKpiGroupId: transferTechnology?.researchKpiGroupId,
            researchKpiId: transferTechnology?.researchKpiId,
            name: transferTechnology?.name,
            transferredYear: transferTechnology?.transferredYear,
            partner: transferTechnology?.partner,
            contractedDate: transferTechnology?.contractedDate,
            revenue: transferTechnology?.revenue,
            scale: transferTechnology?.scale,
            hasFile: false,
          };
          if (transferTechnology?.file) {
            productFileFormData.append('transferredTechnologyFiles', transferTechnology?.file);
            transferTechnologySender.hasFile = true;
          }
          if (transferTechnology?.id) {
            transferTechnologySender.id = transferTechnology?.id;
          }
          productFileFormData.append(
            'summaryReportTransferredTechnologies',
            JSON.stringify(transferTechnologySender)
          );
        }
      }

      if (data?.researchResult?.trainingResult) {
        // eslint-disable-next-line no-restricted-syntax, no-unsafe-optional-chaining
        for (const trainingResult of data?.researchResult?.trainingResult) {
          const trainingResultSender = {
            summaryReportProductGroupId: trainingResultGorup?.id,
            researchKpiGroupId: trainingResult?.researchKpiGroupId,
            researchKpiId: trainingResult?.researchKpiId,
            productDetail: trainingResult?.productDetail,
          };
          if (trainingResult?.file) {
            productFileFormData.append('productFiles', trainingResult?.file);
            trainingResultSender.hasFile = true;
          }
          if (trainingResult?.id) {
            trainingResultSender.id = trainingResult?.id;
          }
          productFileFormData.append('summaryReportProducts', JSON.stringify(trainingResultSender));
        }
      }

      if (summaryReport?.summaryReport) {
        if (data?.appendixFiles?.length > 0) {
          const summaryReportAppendixIds = [];
          // eslint-disable-next-line no-restricted-syntax, no-unsafe-optional-chaining
          for (const appendixFile of data?.appendixFiles) {
            productFileFormData.append('appendixFiles', appendixFile?.file);
            summaryReportAppendixIds.push('');
          }
          productFileFormData.append(
            'summaryReportAppendixIds',
            JSON.stringify(summaryReportAppendixIds)
          );
        }
        if (data?.summaryReportFile !== '') {
          productFileFormData.append('summaryReportFile', data?.summaryReportFile);
        }
      }

      if (!isCreated) {
        // if no exist midterm summary report, create new -> only tab 1 product
        mutateCreateSummary(createOrUpdateSummaryDataSender, {
          onSuccess: (dataRes) => {
            setValue('summaryReportId', dataRes.data.id);
            setValue('summaryReport', dataRes.data);
            if (
              productFileFormData.has('summaryReportProducts') ||
              productFileFormData.has('productFiles') ||
              productFileFormData.has('summaryReportTransferredTechnologies') ||
              productFileFormData.has('transferredTechnologyFiles') ||
              productFileFormData.has('appendixFiles') ||
              productFileFormData.has('summaryReportAppendixIds') ||
              productFileFormData.has('summaryReportFile')
            ) {
              productFileFormData.append('summaryReportId', dataRes.data.id);
              mutateUploadProductFile(productFileFormData, {
                onSuccess: () => {
                  reset({ summaryReportId: dataRes.data.id });
                  refetch();
                  toast(TOAST.SUCCESS, t('submitDocument.toast.saveSuccess'));
                },
                onError: () => {
                  toast(TOAST.ERROR, t('submitDocument.toast.saveFail'));
                },
              });
            }
            expenditureFileFormData.append('summaryReportId', dataRes.data.id);
            mutateUpdateExpenditureFile(expenditureFileFormData, {
              onSuccess: () => {
                reset({ summaryReportId: dataRes.data.id });
                setIsCreated(true);
                refetch();
                handleTabChange();
              },
            });
          },
        });
      } else {
        productFileFormData.append('summaryReportId', summaryReportId);
        expenditureFileFormData.append('summaryReportId', summaryReportId);
        if (data?.researchResult) {
          mutateUpdateSummary(
            {
              id: summaryReportId,
              body: createOrUpdateSummaryDataSender,
            },
            {
              onSuccess: () => {
                mutateUpdateProductFile(productFileFormData, {
                  onSuccess: () => {
                    mutateUpdateExpenditureFile(expenditureFileFormData, {
                      onSuccess: () => {
                        if (appendixFilesDelete?.length > 0) {
                          mutateDeleteFileAppendix(
                            {
                              summaryReportId,
                              summaryReportAppendixIds: appendixFilesDelete,
                            },
                            {
                              onSuccess: () => {
                                reset({ summaryReportId: getValues('summaryReportId') });

                                setResetAppendixFiles(true);
                                refetch();
                                toast(TOAST.SUCCESS, t('submitDocument.toast.updateSuccess'));
                                handleTabChange();
                              },
                            }
                          );
                        } else {
                          reset({ summaryReportId: getValues('summaryReportId') });
                          refetch();
                          toast(TOAST.SUCCESS, t('submitDocument.toast.updateSuccess'));
                          handleTabChange();
                        }
                      },
                      onError: () => {
                        toast(TOAST.ERROR, t('submitDocument.toast.updateFail'));
                      },
                    });
                  },
                });
              },
            }
          );
        }
      }
    }
  };

  const footerConfirmationSubmit = () => (
    <div>
      <Button
        label={t('projectProposal.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={() => setIsSubmitModalOpen(false)}
        autoFocus
      />
      <Button
        label={t('projectProposal.ok')}
        className="w-7rem"
        severity="info"
        onClick={
          () =>
            mutateSubmitSummaryReport(
              { summaryReportId },
              {
                onSuccess: () => {
                  setIsSubmitModalOpen(false);
                  refetch();
                  if (!isAcceptanceReport) {
                    toast(TOAST.SUCCESS, t('submitDocument.toast.midterm'));
                  } else {
                    toast(TOAST.SUCCESS, t('submitDocument.toast.acceptance'));
                  }
                },
              }
            )
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
    </div>
  );

  const handleDownloadFile = (filename, fileNameFormat = '') => {
    mutateDownloadFile(
      {
        summaryReportId,
        filename,
      },
      {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = fileNameFormat || filename;

            link.click();
          }
        },
      }
    );
  };

  // # End region event

  // #region UseEffect
  useEffect(() => {
    const data = summaryReport?.summaryReport || getValues('summaryReport'); // chỗ này là lấy từ db là chính, nếu không có thì lấy trong getValues(chạy lần đầu tiên)
    if (data) {
      // only acceptance
      setSummaryReportAppendixeArray(summaryReport?.summaryReportAppendixes);

      // un-file
      setValue('summaryReportFinishedTasks', data?.summaryReportFinishedTasks ?? []);
      setValue('summaryReportUnfinishedTasks', data?.summaryReportUnfinishedTasks ?? []);
      setValue('summaryReportConferences', data?.summaryReportConferences ?? []);
      setValue('summaryReportParticipants', data?.summaryReportParticipants ?? []);

      for (let i = 0; i < data?.summaryReportParticipants.length; i += 1) {
        setValue(
          `summaryReportParticipants.${i}.joiningDate`,
          new Date(data?.summaryReportParticipants[i]?.joiningDate)
        );
      }
      for (let i = 0; i < data?.summaryReportConferences.length; i += 1) {
        setValue(
          `summaryReportConferences.${i}.organizedDate`,
          new Date(data?.summaryReportConferences[i]?.organizedDate)
        );
      }

      setValue(
        'researchResult.softProducts',
        summaryReport?.summaryReportProducts?.scientificPublications?.softProducts
      );
      setValue(
        'researchResult.hardProducts',
        summaryReport?.summaryReportProducts?.scientificPublications?.hardProducts
      );
      setValue(
        'researchResult.intellectualProperty',
        summaryReport?.summaryReportProducts?.intellectualPropertys
      );
      setValue(
        'researchResult.trainingResult',
        summaryReport?.summaryReportProducts?.trainingResults
      );
      setValue('researchResult.transferTechnology', data?.summaryReportTransferredTechnologies);
      for (let i = 0; i < data?.summaryReportTransferredTechnologies.length; i += 1) {
        setValue(
          `researchResult.transferTechnology.${i}.contractedDate`,
          new Date(data?.summaryReportTransferredTechnologies[i]?.contractedDate)
        );
      }
      const summaryReportExpenditure =
        data?.summaryReportExpenditures?.filter((item) => item?.no === 0) ?? [];
      const subSummaryReportExpenditures =
        data?.summaryReportExpenditures?.filter((item) => item?.no !== 0) ?? [];
      setValue('summaryReportExpenditures', subSummaryReportExpenditures ?? []);
      setValue(
        'expenditureFromVNU',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Funding for the project is allocated by VNU',
          'expenditure'
        )
      );
      setValue(
        'expenditureAllocated',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Allocated expenditure',
          'expenditure'
        )
      );
      setValue(
        'expenditureUsed',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Used expenditure',
          'expenditure'
        )
      );
      setValue(
        'expenditurePropose',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Suggested expenditures',
          'expenditure'
        )
      );
      setValue(
        'noteForExpenditureFromVNU',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Funding for the project is allocated by VNU',
          'notes'
        )
      );
      setValue(
        'noteForExpenditureAllocated',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Allocated expenditure',
          'notes'
        )
      );
      setValue(
        'noteForExpenditureUsed',
        setExpenditureTableOfSummaryReport(summaryReportExpenditure, 'Used expenditure', 'notes')
      );
      setValue(
        'noteForExpenditurePropose',
        setExpenditureTableOfSummaryReport(
          summaryReportExpenditure,
          'Suggested expenditures',
          'notes'
        )
      );

      setValue('suggestion', data?.suggestion ?? '');
      setValue('contentReview', data?.contentReview ?? '');
      setValue('productReview', data?.productReview ?? '');
      setValue('processReview', data?.processReview ?? '');
    }
  }, [summaryReport || getValues('summaryReport')]); // chỗ này là do lúc getValues có thể bị null vào lần sau, nên không đặt dấu , mà để dấu ||

  // #region Render
  return (
    <div className="project-proposal-form m-0 bg-white p-5 p-fluid">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="form-midterm-container">
        <Fieldset className="border-none p-0 p-fluid">
          {/* Tab 1 */}
          <div className={tab !== 0 ? 'hidden' : ''}>
            <GeneralInfo />
          </div>
          {/* Tab 2 */}
          <div className={tab !== 1 ? 'hidden' : ''}>
            <div>
              <h4 className="my-1">{t('r05.b.b1.title')}</h4>
              <h6 className="my-2 font-bold">{t('r05.b.b1.no1.title')}</h6>
              <ContentCompletedTable
                control={control}
                errors={errors}
                disabled={!!summaryReport?.summaryReport?.isSubmit}
              />
              <h6 className="my-2">{t('r05.b.b1.no2.title')}</h6>
              <ContentNotCompletedTable
                control={control}
                errors={errors}
                disabled={!!summaryReport?.summaryReport?.isSubmit}
              />
            </div>
            <h4 className="my-1">{t('r05.b.b2.title')}</h4>
            <Product
              projectCode={projectCode}
              researchKpis={researchKpis}
              researchKpiGroups={researchKpiGroups}
              control={control}
              errors={errors}
              disabled={!!summaryReport?.summaryReport?.isSubmit}
              handleDownloadFile={handleDownloadFile}
            />
            <h4>{t('r05.b.b3.title')}</h4>
            <WorkshopTable
              control={control}
              errors={errors}
              disabled={!!summaryReport?.summaryReport?.isSubmit}
            />
            <p className="mt-4">{t('r05.b.b3.no2.description')}</p>
            <ParticipantsTable
              control={control}
              errors={errors}
              disabled={!!summaryReport?.summaryReport?.isSubmit}
            />
          </div>
          {/* Tab 3 */}
          <div className={tab !== 2 ? 'hidden' : ''}>
            <h4 className="my-1">{t('r05.b.b4.title')}</h4>
            <ExpenditureTable
              control={control}
              errors={errors}
              disabled={!!summaryReport?.summaryReport?.isSubmit}
            />
            <h5 className="mb-0">
              {t('r05.b.b4.expenditureProof')}
              <span className="font-bold text-red-500"> *</span>
            </h5>
            <CustomFileInput
              name="expenditureFile"
              disabled={!!summaryReport?.summaryReport?.isSubmit}
              defaultNameFile={`${projectCode}-MinhChungKinhPhi-${summaryReport?.summaryReport?.version}.xlsx`}
              control={control}
              errors={errors}
              accept=".xlsx"
            />
            {getValues('expenditureFileUrl') && (
              <span
                className="p-button p-button-text p-button-sm mt-1 p-0"
                onClick={() => handleDownloadFile(getValues('expenditureFileUrl'))}
              >
                <i className="pi pi-file-pdf text-green-500 text-xl" />{' '}
                <span className="text-blue-500 underline font-italic text-base">
                  {formatFileName(getValues('expenditureFileUrl'))}
                </span>
              </span>
            )}
          </div>
          {/* Tab 4 Review Suggest */}
          <div className={tab !== 3 ? 'hidden' : ''}>
            <Review
              control={control}
              errors={errors}
              readOnly={!!summaryReport?.summaryReport?.isSubmit}
            />
            {isAcceptanceReport && (
              <>
                <div className="mt-4">
                  <h5 className="m-0">
                    {t('r05.uploadSummaryReport')} <span className="text-red-500">*</span>{' '}
                    <a
                      className="text-blue-500 font-italic text-lg font-medium"
                      href="/files/R08-BAO-CAO-TONG-KET.docx"
                      download
                    >
                      {t('formLayout.action.download')} {t('r05.fileTemplate')}{' '}
                      <i className="pi pi-download text-blue-500" />
                    </a>
                  </h5>
                  <CustomFileInput
                    name="summaryReportFile"
                    defaultNameFile={`${projectCode}-BaoCaoTongKet.pdf`}
                    control={control}
                    errors={errors}
                    accept=".pdf"
                    acceptFileMessage="Chỉ chấp nhận file PDF"
                    acceptOnly="pdfOnly"
                  />
                </div>
                {summaryReport?.summaryReport?.summaryReportFileUrl && (
                  <span
                    className="p-button p-button-text p-button-sm mt-1 p-0"
                    onClick={() =>
                      handleDownloadFile(
                        summaryReport?.summaryReport?.summaryReportFileUrl,
                        formatFileName(summaryReport?.summaryReport?.summaryReportFileUrl)
                      )
                    }
                  >
                    <i className="pi pi-file-pdf text-red-500 text-xl" />{' '}
                    <span className="text-blue-500 underline font-italic text-base">
                      {formatFileName(summaryReport?.summaryReport?.summaryReportFileUrl)}
                    </span>
                  </span>
                )}
                <UploadMultipleFile
                  control={control}
                  errors={errors}
                  summaryReportAppendixeArray={summaryReportAppendixeArray}
                  handleDownloadFile={handleDownloadFile}
                  setAppendixFilesDelete={setAppendixFilesDelete}
                  disabled={summaryReport?.summaryReport?.isSubmit}
                  setSummaryReportAppendixeArray={setSummaryReportAppendixeArray}
                  resetAppendixFiles={resetAppendixFiles}
                  setResetAppendixFiles={setResetAppendixFiles}
                  projectCode={summaryReport?.summaryReport?.projectCode}
                  reset={reset}
                />
              </>
            )}
          </div>
        </Fieldset>
        {/* Tab 5 */}
        {tab !== 0 && tab !== 4 && (
          <div className="flex justify-content-end mt-2">
            <Button
              type="submit"
              className="btn-control font-semibold mr-3"
              severity="success"
              onClick={() => {
                setIsWillNext(false);
                checkValidation();
              }}
              disabled={
                !(isDirty && Object.keys(dirtyFields).length) ||
                !!summaryReport?.summaryReport?.isSubmit
              }
            >
              {t('formLayout.save')}
            </Button>
            <Button
              type="submit"
              className="p-button-next btn-control"
              onClick={() => {
                if (!(isDirty && Object.keys(dirtyFields).length)) {
                  setTab((prev) => prev + 1);
                } else {
                  setIsWillNext(true);
                  checkValidation();
                }
              }}
            >
              {t('formLayout.next')}
            </Button>
          </div>
        )}
        {(isUploadProductFileLoading ||
          isDownloadFileLoading ||
          isSubmitSummaryReportLoading ||
          isUpdateProductFileLoading ||
          isUpdateExpenditureFileLoading ||
          isUpdateSummaryLoading ||
          isCreateSummaryLoading ||
          isDeleteFileAppendix) && <Loading />}
      </form>
      {/* Tab 5 Xem lại */}
      <div className={tab !== 4 ? 'hidden' : 'ml-4'}>
        <ReviewForm
          summaryReport={summaryReport}
          handleDownloadFile={handleDownloadFile}
          control={control}
          errors={errors}
        />
        <div className="flex justify-content-end mt-6">
          <Link to="/project">
            <Button type="button" className="btn-submit  font-semibold mr-3" severity="danger">
              {t('submitDocument.back')}
            </Button>
          </Link>
          <Button
            type="button"
            className="btn-submit font-semibold mr-3"
            severity="success"
            onClick={() => setIsSubmitModalOpen(true)}
            disabled={!!summaryReport?.summaryReport?.isSubmit}
          >
            {t('submitDocument.submit')}
          </Button>
        </div>
      </div>
      <Dialog
        header={<p className="text-blue-500">{t('projectProposal.confirmationTitle')}</p>}
        visible={isSubmitModalOpen}
        position="center"
        style={{ width: '400px' }}
        onHide={() => setIsSubmitModalOpen(false)}
        draggable={false}
        footer={footerConfirmationSubmit}
      >
        <Message
          className="mt-4 w-full"
          severity="warn"
          text={t('submitDocument.confirmationMessage')}
        />
      </Dialog>
    </div>
  );
}

SummaryReportCreateForm.propTypes = {
  isAcceptanceReport: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  tab: PropTypes.number,
  setTab: PropTypes.func,
  isDirty: PropTypes.bool.isRequired,
  dirtyFields: PropTypes.shape({}).isRequired,
  checkValidation: PropTypes.func.isRequired,
};

SummaryReportCreateForm.defaultProps = {
  isAcceptanceReport: false,
  tab: 0,
  setTab: () => null,
};
