import './style.scss';

import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';

import { getAllProposedExpenditureTypes } from 'apis/projectProposals.api';
import {
  downloadDocxFile,
  downloadDocxTemplate,
  downloadPdfFile,
  downloadPdfTemplate,
  getDetail,
  getGeneralProjectProposalInfo,
  getReviewerInfo,
} from 'apis/specializeReviews.api';

import { ProjectProposalDefenseDetail } from 'features/ProjectProposalDefense/pages';
import { DetailInfo } from 'features/SpecializeReview/components';
import { EvaluationTable, ExpenditureTable } from 'features/SpecializeReview/components/DetailM01';

import Loading from 'components/Loading';

import { TOAST } from 'constant';
import { toast } from 'layout';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';
import { TabPanel, TabView } from 'primereact/tabview';

import {
  getAcadamicLevelShortHand,
  modifyReviewExpenditure,
  toNonAccentVietnamese,
} from 'utils/func';
import { Menu } from 'primereact/menu';

function DetailM01() {
  // #region Data
  const { t } = useTranslation();
  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');
  const downloadButtonRef = useRef();
  const downloadTemplateButtonRef = useRef();

  const [isOpenGuideline, setIsOpenGuideLine] = useState(false);
  const [tab, setTab] = useState(0);

  const { data: projectProposalData, isLoading: isProjectProposalLoading } = useQuery(
    ['specialize-review', 'project-proposal', councilId, projectProposalId],
    ({ queryKey: [, , _councilId, _projectProposalId] }) =>
      getGeneralProjectProposalInfo(_councilId, _projectProposalId)
  );
  const projectProposal = useMemo(() => projectProposalData?.data ?? {});

  const { data: reviewerData, isLoading: isReviewerLoading } = useQuery(
    ['specialize-review', 'reviewer', councilId, projectProposalId],
    ({ queryKey: [, , _councilId, _projectProposalId] }) =>
      getReviewerInfo(_councilId, _projectProposalId)
  );

  const reviewer = useMemo(() => reviewerData?.data ?? {});

  const { data: specializeReviewData, isLoading: isSpecializeReviewLoading } = useQuery(
    ['specialize-review', 'detail', councilMemberId, councilId, projectProposalId],
    ({ queryKey: [, , _councilMemberId, _councilId, _projectProposalId] }) =>
      getDetail(_councilMemberId, _councilId, _projectProposalId)
  );

  const { data: expenditureTypesData } = useQuery(
    ['expenditure-types'],
    getAllProposedExpenditureTypes,
    { staleTime: 1000 * 60 * 5 }
  );

  const expenditureTypes = useMemo(() => {
    const types = expenditureTypesData?.data ?? [];
    return types?.sort((a, b) => a.order - b.order);
  }, [expenditureTypesData]);

  const specializeReview = useMemo(() => {
    if (specializeReviewData?.data?.specializeReview) {
      return {
        reviewImportance: specializeReviewData.data.specializeReview.reviewImportance,
        reviewPicapability: specializeReviewData.data.specializeReview.reviewPicapability,
        reviewQuality: specializeReviewData.data.specializeReview.reviewQuality,
        reviewExpenditure: modifyReviewExpenditure(
          specializeReviewData.data.specializeReview.reviewExpenditure,
          expenditureTypes
        ),
        expenditureTotalSuggestion:
          specializeReviewData.data.specializeReview.expenditureTotalSuggestion,
        evaluations: [
          {
            order: 1,
            point: specializeReviewData.data.specializeReview.scoreImportance,
            evaluations: {
              creativity: specializeReviewData.data.specializeReview.scoreCreativity,
              suitability: specializeReviewData.data.specializeReview.scoreSuitableness,
            },
          },
          {
            order: 2,
            point: specializeReviewData.data.specializeReview.scoreQuality,
          },
          {
            order: 3,
            point: specializeReviewData.data.specializeReview.scoreProduct,
            evaluations: {
              scientificPulication: specializeReviewData.data.specializeReview.scoreAnnouncement,
              training: specializeReviewData.data.specializeReview.scoreTraining,
            },
          },
          {
            order: 4,
            point: specializeReviewData.data.specializeReview.scoreCapability,
          },
          {
            order: 5,
            point: specializeReviewData.data.specializeReview.scoreSubCriteria,
          },
        ],
        reviewConclusion: specializeReviewData.data.specializeReview.reviewConclusion,
      };
    }
    return {
      reviewImportance: '',
      reviewPicapability: '',
      reviewQuality: '',
      reviewExpenditure: [],
      evaluations: [],
      reviewConclusion: '',
    };
  }, [specializeReviewData?.data?.specializeReview, expenditureTypes]);
  // #endregion Data

  // #region Event
  const {
    refetch: handleDownloadPdfFile,
    isLoading: isDownloadPdfFileLoading,
    isFetching: isDownloadPdfFileFetching,
  } = useQuery(
    ['downloadPdfFileSpecializationReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const academicLevelShorthand = getAcadamicLevelShortHand(
            reviewer?.scientificProfile?.academicRank,
            reviewer?.scientificProfile?.academicDegree,
            t,
            true
          );
          const filename = `Phieu nhan xet danh gia tham dinh chuyen mon - ${academicLevelShorthand}${toNonAccentVietnamese(
            reviewer?.fullname
          )}.pdf`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxFile,
    isLoading: isDownloadDocxFileLoading,
    isFetching: isDownloadDocxFileFetching,
  } = useQuery(
    ['downloadDocxFileSpecializationReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const academicLevelShorthand = getAcadamicLevelShortHand(
            reviewer?.scientificProfile?.academicRank,
            reviewer?.scientificProfile?.academicDegree,
            t,
            true
          );
          const filename = `Phieu nhan xet danh gia tham dinh chuyen mon - ${academicLevelShorthand}${toNonAccentVietnamese(
            reviewer?.fullname
          )}.docx`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadPdfTemplate,
    isLoading: isDownloadPdfTemplateLoading,
    isFetching: isDownloadPdfTemplateFetching,
  } = useQuery(
    ['downloadPdfTemplateSpecializationReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M01 - Phieu nhan xet danh gia tham dinh chuyen mon.pdf';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxTemplate,
    isLoading: isDownloadDocxTemplateLoading,
    isFetching: isDownloadDocxTemplateFetching,
  } = useQuery(
    ['downloadDocxTemplateSpecializationReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M01 - Phieu nhan xet danh gia tham dinh chuyen mon.docx';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );
  // #endregion Event

  return (
    <TabView activeIndex={tab} onTabChange={(e) => setTab(e.index)}>
      <TabPanel header={t('council.review.viewOrPrint')} leftIcon="pi pi-file mr-2">
        <div className="bg-white p-5">
          <div className="flex justify-content-end">
            <Button
              label={t('council.review.exportFile')}
              icon={`pi ${
                isDownloadPdfFileLoading ||
                isDownloadPdfFileFetching ||
                isDownloadDocxFileLoading ||
                isDownloadDocxFileFetching
                  ? 'pi-spin pi-spinner'
                  : 'pi-file'
              }`}
              className="block w-auto h-3rem border-none"
              severity="info"
              onClick={(event) => downloadButtonRef.current.toggle(event)}
              disabled={
                isDownloadPdfFileLoading ||
                isDownloadPdfFileFetching ||
                isDownloadDocxFileLoading ||
                isDownloadDocxFileFetching
              }
            />
            <Button
              label={t('council.review.downloadTemplate')}
              icon={`pi ${
                isDownloadPdfTemplateLoading ||
                isDownloadPdfTemplateFetching ||
                isDownloadDocxTemplateLoading ||
                isDownloadDocxTemplateFetching
                  ? 'pi-spin pi-spinner'
                  : 'pi-file'
              }`}
              className="block w-auto h-3rem border-none ml-1"
              severity="info"
              onClick={(event) => downloadTemplateButtonRef.current.toggle(event)}
              disabled={
                isDownloadPdfTemplateLoading ||
                isDownloadPdfTemplateFetching ||
                isDownloadDocxTemplateLoading ||
                isDownloadDocxTemplateFetching
              }
            />
          </div>

          <Menu
            ref={downloadButtonRef}
            model={[
              {
                label: t('council.review.exportPdf'),
                icon: 'pi pi-file-pdf',
                command: handleDownloadPdfFile,
                disabled: isDownloadPdfFileLoading || isDownloadPdfFileFetching,
              },
              {
                label: t('council.review.exportDocx'),
                icon: 'pi pi-file-word',
                command: handleDownloadDocxFile,
                disabled: isDownloadDocxFileLoading || isDownloadDocxFileFetching,
              },
            ]}
            popup
          />
          <Menu
            ref={downloadTemplateButtonRef}
            model={[
              {
                label: t('council.review.downloadPdfTemplate'),
                icon: 'pi pi-file-pdf',
                command: handleDownloadPdfTemplate,
                disabled: isDownloadPdfTemplateLoading || isDownloadPdfTemplateFetching,
              },
              {
                label: t('council.review.downloadDocxTemplate'),
                icon: 'pi pi-file-word',
                command: handleDownloadDocxTemplate,
                disabled: isDownloadDocxTemplateLoading || isDownloadDocxTemplateFetching,
              },
            ]}
            popup
          />

          <Dialog
            visible={isOpenGuideline}
            style={{ maxWidth: '750px' }}
            onHide={() => setIsOpenGuideLine(false)}
          >
            <div className="relative">
              <video
                id="printGuideline"
                width="700"
                height="380"
                preload="auto"
                src="/layout/videos/print-guideline.m4v"
                type="video/mp4"
                autoPlay
                loop
              >
                <track kind="captions" />
              </video>
            </div>
          </Dialog>

          {(isSpecializeReviewLoading || isReviewerLoading || isProjectProposalLoading) && (
            <Loading />
          )}
          <div className="m-0 bg-white p-fluid specialize-review-detail relative">
            <div className="mb-5 flex justify-content-between align-items-center">
              <img
                src={`${process.env.PUBLIC_URL}/logo.png`}
                alt="VNU-HCM logo"
                className="ml-3 h-4rem md:h-5rem"
              />
              <div className="pl-2">{t('projectProposal.viewDetail.form')} M01</div>
            </div>
            <div className="text-center">
              <h4 className="font-bold mb-0">{t('council.m01.title')}</h4>
              <h4 className="mt-0">{t('council.m01.sciTechProject')}</h4>
            </div>
            {/* Info */}
            <DetailInfo generalProjectProposalInfo={projectProposal} reviewerInfo={reviewer} />
            {/* End Info */}
            {/* Section A */}
            <section className="mx-0 md:mx-4 xl:mx-8 mt-4">
              <h5 className="font-semibold">{t('council.m01.a.label')}</h5>
              {/* A1 */}
              <h6 className="font-semibold text-lg">{t('council.m01.a.a1.label')}</h6>
              <p className="font-italic">{t('council.m01.a.a1.note')}</p>
              <Editor
                style={{ minHeight: '200px', width: '100%' }}
                value={specializeReview.reviewImportance}
                showHeader={false}
                readOnly
              />
              {/* A2 */}
              <h6 className="font-semibold text-lg">{t('council.m01.a.a2.label')}</h6>
              <p className="font-italic">{t('council.m01.a.a2.note')}</p>
              <Editor
                style={{ minHeight: '200px', width: '100%' }}
                value={specializeReview.reviewQuality}
                showHeader={false}
                readOnly
              />
              {/* A3 */}
              <h6 className="font-semibold text-lg">{t('council.m01.a.a3.label')}</h6>
              <p className="font-italic">{t('council.m01.a.a3.note')}</p>
              <Editor
                style={{ minHeight: '200px', width: '100%' }}
                value={specializeReview.reviewPicapability}
                showHeader={false}
                readOnly
              />
              {/* A4 */}
              <h6 className="font-semibold text-lg">{t('council.m01.a.a4.label')}</h6>
              <p className="font-italic">{t('council.m01.a.a4.note')}</p>
              <ExpenditureTable reviewExpenditure={specializeReview.reviewExpenditure} />
              <div className="mt-3">
                <div className="mb-2 font-bold text-lg">{t('council.m01.a.a4.total')}</div>
                <Editor
                  style={{ minHeight: '150px', width: '100%' }}
                  value={specializeReview.expenditureTotalSuggestion}
                  showHeader={false}
                  readOnly
                />
              </div>
            </section>
            {/* End Section A */}
            {/* Section B */}
            <section className="mx-0 md:mx-4 xl:mx-8 mt-4">
              <h5 className="font-semibold">{t('council.m01.b.label')}</h5>
              <EvaluationTable evaluations={specializeReview.evaluations} />
            </section>
            {/* End Section B */}
            {/* Section C */}
            <section className="mx-0 md:mx-4 xl:mx-8 mt-4">
              <h5 className="font-semibold">{t('council.m01.c.label')}</h5>
              <Editor
                style={{ minHeight: '200px', width: '100%' }}
                value={specializeReview.reviewConclusion}
                showHeader={false}
                readOnly
              />
            </section>
            <section className="mx-0 md:mx-4 xl:mx-8 my-6 text-lg">
              <div className="grid">
                <div className="col-7 px-8">
                  <span>{t('council.m01.c.commitment')}</span>
                </div>
                <div className="col-5 text-center">
                  <p className="mb-0">{t('signature.date')}</p>
                  <p className="font-semibold mb-0">{t('signature.reviewer')}</p>
                  <p className="mb-7">{t('signature.fullnameAndSignature')}</p>
                  <p className="font-bold">{reviewer?.fullname}</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </TabPanel>
      <TabPanel header={t('council.review.detailProjectProposal')} leftIcon="pi pi-list mr-2">
        <ProjectProposalDefenseDetail />
      </TabPanel>
    </TabView>
  );
}

export default DetailM01;
