import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CurrencyInput, TextareaInput } from 'components/FormControl';
import SubExpenditureTable from '../SubExpenditureTable';

export default function ExpenditureTable({ disabled, control, errors }) {
  const { t } = useTranslation();
  return (
    <div className="form-table">
      <table>
        <thead>
          <tr>
            <th style={{ minWidth: 440, width: 440 }}>{t('r05.b.b4.expenditure')}</th>
            <th style={{ minWidth: 110, width: 160 }}>{t('r05.b.b4.amountOfMoney')}</th>
            <th style={{ minWidth: 220, width: 440 }}>{t('r05.b.b4.note')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="font-bold">{t('r05.b.b4.expenditureFromVNU')}</td>
            <td>
              <CurrencyInput
                name="expenditureFromVNU"
                control={control}
                errors={errors}
                disabled={disabled}
              />
            </td>
            <td>
              <TextareaInput
                name="noteForExpenditureFromVNU"
                control={control}
                errors={errors}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold">{t('r05.b.b4.expenditureAllocated')}</td>
            <td>
              <CurrencyInput
                name="expenditureAllocated"
                control={control}
                errors={errors}
                disabled={disabled}
              />
            </td>
            <td>
              <TextareaInput
                name="noteForExpenditureAllocated"
                control={control}
                errors={errors}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td>
              <p className="font-bold">{t('r05.b.b4.expenditureUsed.description')}</p>
              <p>{t('r05.b.b4.expenditureUsed.note')}</p>
            </td>
            <td>
              <CurrencyInput
                name="expenditureUsed"
                control={control}
                errors={errors}
                disabled={disabled}
              />
            </td>
            <td>
              <TextareaInput
                name="noteForExpenditureUsed"
                control={control}
                errors={errors}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <SubExpenditureTable control={control} errors={errors} disabled={disabled} />
            </td>
          </tr>
          <tr>
            <td>
              <p className="font-bold">{t('r05.b.b4.expenditurePropose')}</p>
            </td>
            <td>
              <CurrencyInput
                name="expenditurePropose"
                control={control}
                errors={errors}
                disabled={disabled}
              />
            </td>
            <td>
              <TextareaInput
                name="noteForExpenditurePropose"
                control={control}
                errors={errors}
                disabled={disabled}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

ExpenditureTable.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
};

ExpenditureTable.defaultProps = {
  disabled: false,
};
