import './style.scss';

import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Button } from 'primereact/button';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { getById, DOWNLOAD_FILE } from 'apis/extendImplementationTime.api';

import { formatFileName, getMonth, getYear } from 'utils/func';
import { getProjectById } from 'apis/project.api';
import moment from 'moment';
import { DownloadFile, GeneralInfo, HeaderInfo, FooterInfo } from '../Shared';

export default function ExtendImplementationDetail() {
  const { t } = useTranslation();
  const { projectId, id } = useParams();
  const navigate = useNavigate();

  const [appendixeArray, setAppendixeArray] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);

  const { data: project } = useQuery(['getProjectById', projectId], () =>
    getProjectById(projectId)
  );

  const { data: _data } = useQuery(['getExtendImplementationTimeById', id], () => getById(id));
  const extendImplementationTimeData = useMemo(() => _data?.data ?? [], [_data]);

  const { mutate: mutateDownloadFile } = useMutation(DOWNLOAD_FILE);

  const handleDownloadFile = (path) => {
    mutateDownloadFile(
      { path },
      {
        onSuccess: async (res) => {
          if (res?.data) {
            const file = new Blob([res.data]);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = formatFileName(path);

            link.click();
          }
        },
      }
    );
  };

  useEffect(() => {
    if (extendImplementationTimeData) {
      setFileUrl(extendImplementationTimeData?.fileUrl);
      if (extendImplementationTimeData?.extendImplementationTimeAppendixes?.length > 0) {
        setAppendixeArray(extendImplementationTimeData?.extendImplementationTimeAppendixes);
      }
    }
  }, [extendImplementationTimeData]);

  return (
    <div className="p-6 detail-extend-implementation-time-form">
      <DownloadFile id={id} projectId={projectId} />
      <div className="p-5">
        <HeaderInfo />

        {/* info project */}
        <div className="mt-4">
          <GeneralInfo isDetail />

          <h5 className="font-semibold my-2">
            {t('extendImplementationTime.R07.implementationTimeSuggestExtend.title')}
            <span className="font-normal">
              {Math.ceil(
                moment(extendImplementationTimeData?.extendDate).diff(
                  project?.data?.endDate,
                  'months',
                  true
                )
              )}{' '}
              {t('extendImplementationTime.R07.implementationTimeFollowContract.sub')}{' '}
              {`${getMonth(project?.data?.endDate)}/${getYear(project?.data?.endDate)}`}{' '}
              {t('extendImplementationTime.R07.implementationTimeFollowContract.to')}{' '}
              {`${getMonth(extendImplementationTimeData?.extendDate)}/${getYear(
                extendImplementationTimeData?.extendDate
              )}`}
            </span>
          </h5>
          <h5 className="font-semibold my-2">
            {t('extendImplementationTime.R07.reason')}
            <span className="font-normal">{extendImplementationTimeData?.reason}</span>
          </h5>
          <h5 className="font-semibold my-2">
            {t('extendImplementationTime.R07.commit.title')}:{' '}
            <span className="font-normal">{t('extendImplementationTime.R07.commit.content')}</span>
          </h5>
        </div>

        {fileUrl && (
          <h5 className="font-semibold my-2">
            {t('extendImplementationTime.file')}:{' '}
            <span
              className="underline"
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => handleDownloadFile(fileUrl)}
            >
              {formatFileName(typeof fileUrl === 'string' ? fileUrl : fileUrl?.name)}
            </span>
          </h5>
        )}

        {appendixeArray?.length > 0 && (
          <h5 className="font-semibold my-2">{t('extendImplementationTime.attachedFiles')}:</h5>
        )}
        {appendixeArray?.map((appendixe) => (
          <div
            key={appendixe.id}
            className="mt-2 flex justify-content-between align-content-center"
          >
            <span
              key={appendixe?.fileUrl}
              className="py-2"
              style={{ minWidth: '16rem', cursor: 'pointer' }}
              onClick={() => handleDownloadFile(appendixe?.fileUrl)}
            >
              <i className="pi pi-file-pdf text-xl mr-2" style={{ color: 'red' }} />
              <span className="text-blue-500 underline font-italic text-base">
                {formatFileName(appendixe?.fileUrl)}
              </span>
            </span>
            <span className="py-4 flex-auto" />
          </div>
        ))}

        <FooterInfo />

        <div className="flex justify-content-end mt-6 gap-4">
          <Button
            className="w-10rem"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            label={t('extendImplementationTime.comeBack')}
            comeBack
            severity="danger"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
      </div>
    </div>
  );
}
