import * as Yup from 'yup';

const hardProductSchema = Yup.object().shape({
  name: Yup.string().required('formControl.validation.required'),
  qualityCriteria: Yup.string().required('formControl.validation.required'),
  measure: Yup.string().required('formControl.validation.required'),
  evaluativeCriteria: Yup.string().required('formControl.validation.required'),
  domesticModelQuality: Yup.string().nullable(),
  internationalModelQuality: Yup.string().nullable(),
  expectedQuantity: Yup.string().nullable(),
});

export default hardProductSchema;
