import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ScientificWorksSection({ data }) {
  // #region Data
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState(null);

  // #endregion
  const formatIndex = (_, { rowIndex }) => rowIndex + 1;

  const formatIsIsi = (rowData) =>
    rowData?.paper.isIsi
      ? t('scientificProfile.scientificWork.paper.international.yes')
      : t('scientificProfile.scientificWork.paper.international.no');

  const rowExpansionInternationalPapers = (item) => (
    <div className="m-2 text-lg">
      <div className="grid">
        <div className="col-12">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.title')}
            {': '}
          </strong>
          {item?.paper?.title}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.authors')}
            {': '}
          </strong>
          {item?.paper?.authors}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.journal')}
            {': '}
          </strong>
          {item?.paper?.journal}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.journalAbbrev')}
            {': '}
          </strong>
          {item?.paper?.journalAbbrev}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>{'DOI: '}</strong>
          <a href={'https://doi.org/'.concat(item?.paper?.doi)}>{item?.paper?.doi}</a>
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.year')}
            {': '}
          </strong>
          {item?.paper?.year}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.projectCode')}
            {': '}
          </strong>
          {item?.paper?.projectCode}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.issn')}
            {': '}
          </strong>
          {item?.paper?.issn}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.isIsi')}
            {': '}
          </strong>
          {item?.paper?.isIsi
            ? t('scientificProfile.scientificWork.paper.international.yes')
            : t('scientificProfile.scientificWork.paper.international.no')}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.if')}
            {': '}
          </strong>
          {item?.paper?.if}
        </div>
      </div>
    </div>
  );

  const rowExpansionDomesticPapers = (item) => (
    <div className="m-2 text-lg">
      <div className="grid">
        <div className="col-12">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.title')}
            {': '}
          </strong>
          {item?.paper?.title}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.authors')}
            {': '}
          </strong>
          {item?.paper?.authors}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.journal')}
            {': '}
          </strong>
          {item?.paper?.journal}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.journalAbbrev')}
            {': '}
          </strong>
          {item?.paper?.journalAbbrev}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.pages')}
            {': '}
          </strong>
          <a href={item?.paper?.pages}>{item?.paper?.pages}</a>
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.year')}
            {': '}
          </strong>
          {item?.paper?.year}
        </div>
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.projectCode')}
            {': '}
          </strong>
          {item?.paper?.projectCode}
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.issn')}
            {': '}
          </strong>
          {item?.paper?.issn}
        </div>

        <div className="col-6">
          <strong style={{ color: '#0000ff' }}>
            {t('scientificProfile.scientificWork.paper.note')}
            {': '}
          </strong>
          {item?.note}
        </div>
      </div>
    </div>
  );

  // #region Event
  // #endregion
  return (
    <div className="pb-2">
      <h4 className="mb-2">III. {t('scientificProfile.preview.sectionIII').toUpperCase()}</h4>

      <div className="text-lg">
        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            1. {t('scientificProfile.scientificWork.book.label')}:
          </span>
          <p className="underline font-italic mb-2">
            1.1 {t('scientificProfile.scientificWork.book.international.label')}:
          </p>
          <div className="form-table my-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 260, width: '26%' }}>
                    {t('scientificProfile.scientificWork.book.title')}
                  </th>
                  <th className="text-center" style={{ minWidth: 150, width: '15%' }}>
                    {t('scientificProfile.scientificWork.book.projectCode')}
                  </th>
                  <th className="text-center" style={{ minWidth: 150, width: '15%' }}>
                    {t('scientificProfile.scientificWork.book.publisher')}
                  </th>
                  <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                    {t('scientificProfile.scientificWork.book.year')}
                  </th>
                  <th className="text-center" style={{ minWidth: 180, width: '18%' }}>
                    {t('scientificProfile.scientificWork.book.authorOrCoauthor')}
                  </th>
                  <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                    {t('scientificProfile.scientificWork.book.pseudonym')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.internationalBooks?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.book?.title}</td>
                    <td>{item?.book?.projectCode}</td>
                    <td>{item?.book?.publisher}</td>
                    <td>{item?.book?.year}</td>
                    <td>{item?.role ? t(`contributorRole.${item?.role}`) : ''}</td>
                    <td>{item?.pseudonym}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <p className="underline font-italic mb-2">
            1.2. {t('scientificProfile.scientificWork.book.domestic.label')}:
          </p>
          <div className="form-table my-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 260, width: '26%' }}>
                    {t('scientificProfile.scientificWork.book.title')}
                  </th>
                  <th className="text-center" style={{ minWidth: 150, width: '15%' }}>
                    {t('scientificProfile.scientificWork.book.projectCode')}
                  </th>
                  <th className="text-center" style={{ minWidth: 150, width: '15%' }}>
                    {t('scientificProfile.scientificWork.book.publisher')}
                  </th>
                  <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                    {t('scientificProfile.scientificWork.book.year')}
                  </th>
                  <th className="text-center" style={{ minWidth: 180, width: '18%' }}>
                    {t('scientificProfile.scientificWork.book.authorOrCoauthor')}
                  </th>
                  <th className="text-center" style={{ minWidth: 120, width: '12%' }}>
                    {t('scientificProfile.scientificWork.book.pseudonym')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.domesticBooks?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.book?.title}</td>
                    <td>{item?.book?.projectCode}</td>
                    <td>{item?.book?.publisher}</td>
                    <td>{item?.book?.year}</td>
                    <td>{item?.role ? t(`contributorRole.${item?.role}`) : ''}</td>
                    <td>{item?.pseudonym}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-column mb-1">
          <span className="mr-2 font-semibold">
            2. {t('scientificProfile.scientificWork.paper.label')}:
          </span>
          <p className="underline font-italic mb-2">
            2.1. {t('scientificProfile.scientificWork.paper.international.label')}:
          </p>

          <DataTable
            value={data?.internationalPapers}
            showGridlines
            filterDisplay="menu"
            loading={typeof data?.internationalPapers?.length !== 'number'}
            scrollable
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionInternationalPapers}
            emptyMessage=" "
          >
            <Column expander style={{ width: '2rem' }} />
            <Column
              header={t('formLayout.numericalOrder')}
              headerClassName="text-center  "
              className="text-center"
              body={formatIndex}
              style={{ minWidth: 50, width: 50 }}
              expander
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.title')}
              headerClassName="text-center"
              field="paper.title"
            />

            <Column
              header={t('scientificProfile.scientificWork.paper.authors')}
              headerClassName="text-center"
              field="paper.authors"
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.journal')}
              headerClassName="text-center"
              field="paper.journal"
            />

            <Column
              header={t('scientificProfile.scientificWork.paper.year')}
              headerClassName="text-center"
              field="paper.year"
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.projectCode')}
              headerClassName="text-center"
              field="paper.projectCode"
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.issn')}
              headerClassName="text-center"
              style={{ minWidth: 100 }}
              field="paper.issn"
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.isIsi')}
              headerClassName="text-center"
              field="paper.isIsi"
              body={formatIsIsi}
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.if')}
              headerClassName="text-center"
              field="paper.if"
            />
          </DataTable>
          <p className="underline font-italic mb-2">
            2.2. {t('scientificProfile.scientificWork.paper.domestic.label')}:
          </p>

          <DataTable
            value={data?.domesticPapers}
            showGridlines
            filterDisplay="menu"
            loading={typeof data?.domesticPapers?.length !== 'number'}
            scrollable
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionDomesticPapers}
            emptyMessage=" "
          >
            <Column expander style={{ width: '2rem' }} />
            <Column
              header={t('formLayout.numericalOrder')}
              headerClassName="text-center  "
              className="text-center"
              body={formatIndex}
              style={{ minWidth: 50, width: 50 }}
              expander
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.title')}
              headerClassName="text-center"
              field="paper.title"
            />

            <Column
              header={t('scientificProfile.scientificWork.paper.authors')}
              headerClassName="text-center"
              field="paper.authors"
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.journal')}
              headerClassName="text-center"
              field="paper.journal"
            />

            <Column
              header={t('scientificProfile.scientificWork.paper.year')}
              headerClassName="text-center"
              field="paper.year"
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.projectCode')}
              headerClassName="text-center"
              field="paper.projectCode"
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.issn')}
              headerClassName="text-center"
              style={{ minWidth: 100 }}
              field="paper.issn"
            />
            <Column
              header={t('scientificProfile.scientificWork.paper.note')}
              headerClassName="text-center"
              field="note"
            />
          </DataTable>
          <p className="underline font-italic mb-2">
            2.3. {t('scientificProfile.scientificWork.paper.internationalConference.label')}:
          </p>
          <div className="form-table my-1">
            <table>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                    {t('formLayout.numericalOrder')}
                  </th>
                  <th className="text-center" style={{ minWidth: 260, width: '26%' }}>
                    {t('scientificProfile.scientificWork.paper.title')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                    {t('scientificProfile.scientificWork.paper.authors')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                    {t('scientificProfile.scientificWork.paper.conference')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                    {t('scientificProfile.scientificWork.paper.timePeriod')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                    {t('scientificProfile.scientificWork.paper.venue')}
                  </th>
                  <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                    {t('scientificProfile.scientificWork.paper.projectCode')}
                  </th>
                  <th className="text-center" style={{ minWidth: 70, width: '7%' }}>
                    {t('scientificProfile.scientificWork.paper.isbn')}
                  </th>
                  <th className="text-center" style={{ minWidth: 150, width: '15%' }}>
                    {t('scientificProfile.scientificWork.paper.note')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.internationalConferencePapers?.map((item, index) => (
                  <tr key={item.id ?? index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{item?.paper?.title}</td>
                    <td>{item?.paper?.authors}</td>
                    <td>{item?.paper?.conference}</td>
                    <td className="text-center">
                      {item?.paper?.start
                        ? new Date(item?.paper?.start).toLocaleDateString('en-GB')
                        : ''}{' '}
                      -{' '}
                      {item?.paper?.end
                        ? new Date(item?.paper?.end).toLocaleDateString('en-GB')
                        : ''}
                    </td>
                    <td>{item?.paper?.venue}</td>
                    <td>{item?.paper?.projectCode}</td>
                    <td>{item?.paper?.isbn}</td>
                    <td>{item?.note}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <p className="underline font-italic mb-2">
          2.4. {t('scientificProfile.scientificWork.paper.domesticConference.label')}:
        </p>
        <div className="form-table my-1">
          <table>
            <thead>
              <tr>
                <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                  {t('formLayout.numericalOrder')}
                </th>
                <th className="text-center" style={{ minWidth: 260, width: '26%' }}>
                  {t('scientificProfile.scientificWork.paper.title')}
                </th>
                <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                  {t('scientificProfile.scientificWork.paper.authors')}
                </th>
                <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                  {t('scientificProfile.scientificWork.paper.conference')}
                </th>
                <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                  {t('scientificProfile.scientificWork.paper.timePeriod')}
                </th>
                <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                  {t('scientificProfile.scientificWork.paper.venue')}
                </th>
                <th className="text-center" style={{ minWidth: 100, width: '10%' }}>
                  {t('scientificProfile.scientificWork.paper.projectCode')}
                </th>
                <th className="text-center" style={{ minWidth: 70, width: '7%' }}>
                  {t('scientificProfile.scientificWork.paper.isbn')}
                </th>
                <th className="text-center" style={{ minWidth: 150, width: '15%' }}>
                  {t('scientificProfile.scientificWork.paper.note')}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.domesticConferencePapers?.map((item, index) => (
                <tr key={item.id ?? index}>
                  <td className="text-center">{index + 1}</td>
                  <td>{item?.paper?.title}</td>
                  <td>{item?.paper?.authors}</td>
                  <td>{item?.paper?.conference}</td>
                  <td className="text-center">
                    {item?.paper?.start
                      ? new Date(item?.paper?.start).toLocaleDateString('en-GB')
                      : ''}{' '}
                    -{' '}
                    {item?.paper?.end ? new Date(item?.paper?.end).toLocaleDateString('en-GB') : ''}
                  </td>
                  <td>{item?.paper?.venue}</td>
                  <td>{item?.paper?.projectCode}</td>
                  <td>{item?.paper?.isbn}</td>
                  <td>{item?.note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

ScientificWorksSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

ScientificWorksSection.defaultProps = {
  data: {},
};
