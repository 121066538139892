import {
  DatePicker,
  MaskInput,
  NumberInput,
  Select,
  TextInput,
  TextareaInput,
} from 'components/FormControl';
import ResearchLinesTable from 'features/ProjectProposal/components/GeneralInfoForm/ResearchLinesTable';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import ForeignLanguagesTable from './ForeignLanguagesTable';

export default function GeneralInfoForm({ control, errors, organizations, shouldUpdateFromDocx }) {
  // #region Data
  const { t } = useTranslation();
  const { dirtyFields } = useFormContext(); // retrieve all hook methods

  const academicRanks = [
    { value: 'professor', name: t('academicRank.professor') },
    { value: 'associateProfessor', name: t('academicRank.associateProfessor') },
  ];
  const academicDegrees = [
    { value: 'doctorate', name: t('academicDegree.doctorate') },
    { value: 'master', name: t('academicDegree.master') },
    { value: 'bachelor', name: t('academicDegree.bachelor') },
    { value: 'university', name: t('academicDegree.university') },
    { value: 'associateDiploma', name: t('academicDegree.associateDiploma') },
    { value: 'highSchoolDiploma', name: t('academicDegree.highSchoolDiploma') },
  ];
  const genders = [
    { value: 'male', name: t('gender.male') },
    { value: 'female', name: t('gender.female') },
  ];
  // #endregion
  // #region Event
  // #endregion
  return (
    <div className="relative">
      <h4 className="text-center mb-4">{t('scientificProfile.generalInfo.label')}</h4>
      <div className="grid">
        <div className="col-12 lg:col-4">
          <TextInput
            label={`1. ${t('scientificProfile.generalInfo.fullname')}`}
            name="general.fullname"
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.fullname && shouldUpdateFromDocx}
          />
        </div>
        <div className="col-12 lg:col-4">
          <DatePicker
            label={`2. ${t('scientificProfile.generalInfo.dob')}`}
            name="general.dob"
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.dob && shouldUpdateFromDocx}
          />
        </div>
        <div className="col-12 lg:col-4">
          <Select
            label={`3. ${t('scientificProfile.generalInfo.gender')}`}
            name="general.gender"
            options={genders}
            control={control}
            errors={errors}
            inputField="value"
            isHighlighted={dirtyFields?.general?.gender && shouldUpdateFromDocx}
          />
        </div>
      </div>

      <label>4. {t('scientificProfile.generalInfo.workingAddress.label')}</label>
      <div className="grid mb-3">
        <div className="col-12 lg:col-6">
          <label className="underline font-italic mb-2 block">
            {t('scientificProfile.generalInfo.workingAddress.institute')}
          </label>
          <Select
            name="general.organizationId"
            options={organizations ?? []}
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.organizationId && shouldUpdateFromDocx}
          />
        </div>
        <div className="col-12 lg:col-6">
          <label className="underline font-italic mb-2 block">
            {t('scientificProfile.generalInfo.workingAddress.faculty')}
          </label>
          <TextareaInput
            name="general.faculty"
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.faculty && shouldUpdateFromDocx}
          />
        </div>
        <div className="col-12 lg:col-6">
          <label className="underline font-italic mb-2 block">
            {t('scientificProfile.generalInfo.workingAddress.department')}
          </label>
          <TextareaInput
            name="general.department"
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.department && shouldUpdateFromDocx}
          />
        </div>
        <div className="col-12 lg:col-6">
          <label className="underline font-italic mb-2 block">
            {t('scientificProfile.generalInfo.workingAddress.laboratory')}
          </label>
          <TextareaInput
            name="general.laboratory"
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.laboratory && shouldUpdateFromDocx}
          />
        </div>
        <div className="col-12 lg:col-6">
          <label className="underline font-italic mb-2 block">
            {t('scientificProfile.generalInfo.workingAddress.position')}
          </label>
          <TextareaInput
            name="general.position"
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.position && shouldUpdateFromDocx}
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-4">
          <Select
            label={`5. ${t('scientificProfile.generalInfo.academicDegree')}`}
            name="general.academicDegree"
            options={academicDegrees}
            control={control}
            errors={errors}
            inputField="value"
            isHighlighted={dirtyFields?.general?.academicDegree && shouldUpdateFromDocx}
          />
        </div>
        <div className="col-4">
          <NumberInput
            label={t('scientificProfile.generalInfo.academicDegreeYear')}
            name="general.academicDegreeYear"
            min={0}
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.academicDegreeYear && shouldUpdateFromDocx}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-4">
          <Select
            label={`6. ${t('scientificProfile.generalInfo.academicRank')}`}
            name="general.academicRank"
            options={academicRanks}
            control={control}
            errors={errors}
            inputField="value"
            isHighlighted={dirtyFields?.general?.academicRank && shouldUpdateFromDocx}
          />
        </div>
        <div className="col-4">
          <NumberInput
            label={t('scientificProfile.generalInfo.academicRankYear')}
            name="general.academicRankYear"
            min={0}
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.academicRankYear && shouldUpdateFromDocx}
          />
        </div>
        <div className="col-4" />
      </div>

      <label>7. {t('scientificProfile.generalInfo.contact.label')}</label>
      <div className="form-table mt-2 mb-3">
        <table className="mb-2">
          <thead>
            <tr className="font-bold">
              <th className="text-center" style={{ minWidth: 50, width: 50 }}>
                {t('formLayout.numericalOrder')}
              </th>
              <th className="text-center" style={{ minWidth: 140, width: 140 }}>
                {' '}
              </th>
              <th className="text-center" style={{ width: '45%' }}>
                {t('scientificProfile.generalInfo.contact.office')}
              </th>
              <th className="text-center float" style={{ width: '45%' }}>
                {t('scientificProfile.generalInfo.contact.personal')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">1</td>
              <td className="text-center font-bold">
                {t('scientificProfile.generalInfo.contact.address')}
              </td>
              <td>
                <TextareaInput
                  name="general.officeAddress"
                  control={control}
                  errors={errors}
                  isHighlighted={dirtyFields?.general?.officeAddress && shouldUpdateFromDocx}
                />
              </td>
              <td>
                <TextareaInput
                  name="general.personalAddress"
                  control={control}
                  errors={errors}
                  isHighlighted={dirtyFields?.general?.personalAddress && shouldUpdateFromDocx}
                />
              </td>
            </tr>
            <tr>
              <td className="text-center">2</td>
              <td className="text-center font-bold">
                {t('scientificProfile.generalInfo.contact.phone')}
              </td>
              <td>
                <MaskInput
                  name="general.officePhone"
                  control={control}
                  errors={errors}
                  mask={''.padStart(10, '9')}
                  isHighlighted={dirtyFields?.general?.officePhone && shouldUpdateFromDocx}
                />
              </td>
              <td>
                <MaskInput
                  name="general.personalPhone"
                  control={control}
                  errors={errors}
                  mask={''.padStart(10, '9')}
                  isHighlighted={dirtyFields?.general?.personalPhone && shouldUpdateFromDocx}
                />
              </td>
            </tr>
            <tr>
              <td className="text-center">3</td>
              <td className="text-center font-bold">Email</td>
              <td>
                <TextInput
                  name="general.officeEmail"
                  control={control}
                  errors={errors}
                  isHighlighted={dirtyFields?.general?.officeEmail && shouldUpdateFromDocx}
                />
              </td>
              <td>
                <TextInput
                  name="general.personalEmail"
                  control={control}
                  errors={errors}
                  isHighlighted={dirtyFields?.general?.personalEmail && shouldUpdateFromDocx}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <label>8. {t('scientificProfile.generalInfo.foreignLanguage.label')}</label>
      <ForeignLanguagesTable
        control={control}
        errors={errors}
        isHighlighted={dirtyFields?.general?.foreignLanguages?.[0]?.name && shouldUpdateFromDocx}
      />

      <label className="block mt-3">
        11. {t('scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.label')}
      </label>
      <div className="grid">
        <div className="col-12">
          <label className="underline font-italic mb-2 block">
            {t(
              'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.label'
            )}
          </label>
          <TextareaInput
            label={t(
              'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.field'
            )}
            name="general.researchField"
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.researchField && shouldUpdateFromDocx}
          />
          <TextareaInput
            label={t(
              'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.major'
            )}
            name="general.researchMajor"
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.researchMajor && shouldUpdateFromDocx}
          />
          <TextareaInput
            label={t(
              'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.fieldsOfSpecialization.specialty'
            )}
            name="general.researchSpecialty"
            control={control}
            errors={errors}
            isHighlighted={dirtyFields?.general?.researchSpecialty && shouldUpdateFromDocx}
          />
        </div>
        <div className="col-12">
          <label className="underline font-italic mb-2 block">
            {t(
              'scientificProfile.generalInfo.fieldsOfSpecializationAndResearchLines.researchLines.label'
            )}
          </label>
          <ResearchLinesTable name="general.researchLines" control={control} errors={errors} />
        </div>
        <div className="col-12">
          <label className="underline font-italic mb-2 block">
            {t('userProfile.contact.ORCIDcode')}
          </label>
          <TextInput name="general.ORCIDcode" control={control} errors={errors} row={1} />
        </div>
        <div className="col-12">
          <label className="underline font-italic mb-2 block">
            {t('scientificProfile.preview.about')}
          </label>
          <TextareaInput name="general.description" control={control} errors={errors} row={3} />
        </div>
      </div>
    </div>
  );
}

GeneralInfoForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organizations: PropTypes.array.isRequired,
  shouldUpdateFromDocx: PropTypes.bool,
};

GeneralInfoForm.defaultProps = {
  shouldUpdateFromDocx: false,
};
