import 'styles/layout/_guideline.scss';
import Layout from 'layout';
import { LayoutProvider } from 'layout/context/layoutcontext';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import viVN from '@react-pdf-viewer/locales/lib/vi_VN.json';
import enUS from '@react-pdf-viewer/locales/lib/en_US.json';
import { useTranslation } from 'react-i18next';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function GuidelinePage() {
  const { t } = useTranslation();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <LayoutProvider>
      <Layout
        style={{
          backgroundColor: 'white',
          borderRadius: '12px',
          border: '1px solid var(--surface-border)',
          overflow: 'hidden',
        }}
      >
        <div className="pdf-container">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={
                t('language') === 'vi'
                  ? '/files/HUONG-DAN-DANG-KY-NHIEM-VU-KHCN.pdf'
                  : '/files/HUONG-DAN-DANG-KY-NHIEM-VU-KHCN-EN.pdf'
              }
              plugins={[defaultLayoutPluginInstance]}
              localization={t('language') === 'vi' ? viVN : enUS}
              pageLayout={{
                transformSize: ({ size }) => ({ height: size.height + 30, width: size.width + 30 }),
              }}
              defaultScale={1.3}
            />
          </Worker>
        </div>
      </Layout>
    </LayoutProvider>
  );
}

export default GuidelinePage;
