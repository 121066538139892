import { Tickbox, NumberInput } from 'components/FormControl';
import { useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function Products({ disabled, control, errors }) {
  // #region Data
  const { t } = useTranslation();
  // fields
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'listProducts',
  });

  // console.log('fieldsTrong', fields);
  // handleCustomCheck
  const handleCustomCheck = (key) => {
    const field = fields.find((e) => e.key === key);
    if (field) {
      const index = fields.indexOf(field);
      remove(index);
    } else {
      append({ key });
    }
  };

  // #endregion Data
  return (
    <div className="ml-4">
      <div className="grid col-12">
        {/* left */}
        <div className="col-4">
          {/* finalReport */}
          <div className="flex">
            <Tickbox
              icon="pi pi-check"
              name="summaryReportQuantity"
              control={control}
              errors={errors}
              onCustomCheck={() => handleCustomCheck('summaryReportQuantity')}
              disabled={disabled}
              checked="fields"
            />
            <span className="ml-1">{t('r09.form.finalReport')}</span>
          </div>
          {fields.some((field) => field.key === 'summaryReportQuantity') && (
            <div style={{ marginRight: '10rem' }}>
              <NumberInput
                control={control}
                errors={errors}
                disabled={disabled}
                defaultValue={0}
                name="summaryReportQuantity.value"
              />
            </div>
          )}

          {/* reportBranchTopic */}
          <div className="flex">
            <Tickbox
              icon="pi pi-check"
              name="branchProposalReportQuantity"
              control={control}
              errors={errors}
              onCustomCheck={() => handleCustomCheck('branchProposalReportQuantity')}
              disabled={disabled}
            />
            <span className="ml-1">{t('r09.form.reportBranchTopic')}</span>
          </div>
          {fields.some((field) => field.key === 'branchProposalReportQuantity') && (
            <div style={{ marginRight: '10rem' }}>
              <NumberInput
                control={control}
                errors={errors}
                disabled={disabled}
                name="branchProposalReportQuantity.value"
                defaultValue={0}
              />
            </div>
          )}

          {/* reportThematic */}
          <div className="flex">
            <Tickbox
              icon="pi pi-check"
              name="thematicReportQuantity"
              control={control}
              errors={errors}
              onCustomCheck={() => handleCustomCheck('thematicReportQuantity')}
              disabled={disabled}
            />
            <span className="ml-1">{t('r09.form.reportThematic')}</span>
          </div>
          {fields.some((field) => field.key === 'thematicReportQuantity') && (
            <div style={{ marginRight: '10rem' }}>
              <NumberInput
                control={control}
                errors={errors}
                disabled={disabled}
                name="thematicReportQuantity.value"
                defaultValue={0}
              />
            </div>
          )}

          {/* map */}
          <div className="flex">
            <Tickbox
              icon="pi pi-check"
              name="mapQuantity"
              control={control}
              errors={errors}
              onCustomCheck={() => handleCustomCheck('mapQuantity')}
              disabled={disabled}
            />
            <span className="ml-1">{t('r09.form.map')}</span>
          </div>
          {fields.some((field) => field.key === 'mapQuantity') && (
            <div style={{ marginRight: '10rem' }}>
              <NumberInput
                control={control}
                errors={errors}
                disabled={disabled}
                name="mapQuantity.value"
                defaultValue={0}
              />
            </div>
          )}

          {/* film */}
          <div className="flex">
            <Tickbox
              icon="pi pi-check"
              name="filmQuantity"
              control={control}
              errors={errors}
              onCustomCheck={() => handleCustomCheck('filmQuantity')}
              disabled={disabled}
            />
            <span className="ml-1">{t('r09.form.film')}</span>
          </div>
          {fields.some((field) => field.key === 'filmQuantity') && (
            <div style={{ marginRight: '10rem' }}>
              <NumberInput
                control={control}
                errors={errors}
                disabled={disabled}
                name="filmQuantity.value"
                defaultValue={0}
              />
            </div>
          )}
        </div>

        {/* right */}
        <div className="col-4">
          {/* videoTape */}
          <div className="flex">
            <Tickbox
              icon="pi pi-check"
              name="videoQuantity"
              control={control}
              errors={errors}
              onCustomCheck={() => handleCustomCheck('videoQuantity')}
              disabled={disabled}
            />
            <span className="ml-1">{t('r09.form.videoTape')}</span>
          </div>
          {fields.some((field) => field.key === 'videoQuantity') && (
            <div style={{ marginRight: '10rem' }}>
              <NumberInput
                control={control}
                errors={errors}
                disabled={disabled}
                name="videoQuantity.value"
                defaultValue={0}
              />
            </div>
          )}

          {/* Picture */}
          <div className="flex">
            <Tickbox
              icon="pi pi-check"
              name="imageQuantity"
              control={control}
              errors={errors}
              onCustomCheck={() => handleCustomCheck('imageQuantity')}
              disabled={disabled}
            />
            <span className="ml-1">{t('r09.form.picture')}</span>
          </div>
          {fields.some((field) => field.key === 'imageQuantity') && (
            <div style={{ marginRight: '10rem' }}>
              <NumberInput
                control={control}
                errors={errors}
                disabled={disabled}
                name="imageQuantity.value"
                defaultValue={0}
              />
            </div>
          )}

          {/* cd */}
          <div className="flex">
            <Tickbox
              icon="pi pi-check"
              name="cdDiskQuantity"
              control={control}
              errors={errors}
              onCustomCheck={() => handleCustomCheck('cdDiskQuantity')}
              disabled={disabled}
            />
            <span className="ml-1">{t('r09.form.cd')}</span>
          </div>
          {fields.some((field) => field.key === 'cdDiskQuantity') && (
            <div style={{ marginRight: '10rem' }}>
              <NumberInput
                control={control}
                errors={errors}
                disabled={disabled}
                name="cdDiskQuantity.value"
                defaultValue={0}
              />
            </div>
          )}

          {/* document */}
          <div className="flex">
            <Tickbox
              icon="pi pi-check"
              name="documentQuantity"
              control={control}
              errors={errors}
              onCustomCheck={() => handleCustomCheck('documentQuantity')}
              disabled={disabled}
            />
            <span className="ml-1">{t('r09.form.document')}</span>
          </div>
          {fields.some((field) => field.key === 'documentQuantity') && (
            <div style={{ marginRight: '10rem' }}>
              <NumberInput
                control={control}
                errors={errors}
                disabled={disabled}
                name="documentQuantity.value"
                defaultValue={0}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Products.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
};

Products.defaultProps = {
  disabled: false,
};
