import './style.scss';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getProjectById } from 'apis/project.api';
import { useMemo } from 'react';
import { formatCurrency } from 'utils/func';
import { TIMEZONE_VN } from 'constant';
import ResearchFields from './ResearchFields';

export default function GeneralInfo() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { data: _data } = useQuery(['projectDetailInfo'], () => getProjectById(projectId));
  const data = useMemo(() => _data?.data ?? [], [_data]);

  const projectProposalOrganizations = data?.projectProposal?.projectProposalOrganizations[0];
  const projectOwner = data?.projectProposal?.projectProposalContacts?.find(
    (item) => item.projectRole.code === 'OWNER'
  );

  const partners = data?.projectProposal?.projectProposalOrganizations?.slice(1);

  const removeHtmlTag = (str) => str.replace(/<\/?[^>]+(>|$)/g, '');

  return (
    <div className="general-container">
      {/* A */}
      <h4 className="font-bold">{t('r05.a.title')} </h4>
      {/* A1 */}
      <section>
        <h4 className="my-2">{t('r05.a.a1.title')}</h4>
        <p className="my-2">
          &emsp;- &ensp;
          <span className="font-bold inline-block">{t('r05.a.a1.vietnameseName')}&nbsp;</span>
          {data?.projectProposal?.vietnameseName}
        </p>
        <p className="my-2">
          &emsp;- &ensp;
          <span className="font-bold inline-block">{t('r05.a.a1.englishName')}&nbsp;</span>
          {data?.projectProposal?.englishName}
        </p>
      </section>
      {/* A2 */}
      <section>
        <h4>{t('r05.a.a2.title')}</h4>
        <ResearchFields researchFields={data?.projectProposal?.projectProposalResearchFields} />
      </section>
      {/* A3  */}
      <section>
        <h4>{t('r05.a.a3.title')}</h4>
        <p className="my-2">
          &emsp; <span className="font-bold">{t('r05.a.a3.name')}&nbsp;</span>
          {data?.projectProposal?.researchType?.name}
        </p>
      </section>
      {/* A4  */}
      <section>
        <h4>{t('r05.a.a4.title')}</h4>
        <p className="my-2">
          &emsp;- &ensp;{t('r05.a.a4.timeFrom')} {new Date(data?.startDate).getMonth() + 1}/
          {new Date(data?.startDate).getFullYear()} {t('r05.a.a4.timeTo')}{' '}
          {new Date(data?.endDate).getMonth() + 1}/{new Date(data?.endDate).getFullYear()}
        </p>
        <p className="my-2">
          &emsp;- &ensp;{t('r05.a.a4.renewFrom')} ............ {t('r05.a.a4.renewTo')} ...........
        </p>
      </section>
      {/* A5  */}
      <section>
        <h4>{t('r05.a.a5.title')}</h4>
        <p className="my-2">
          &emsp;- &ensp;{t('r05.a.a5.totalExpenditure')}{' '}
          <span className="font-italic">{formatCurrency(data?.totalExpenditure)}</span>{' '}
          {t('r05.a.a5.include')}
        </p>
        <p className="my-2">
          &emsp;&emsp;- &ensp;{t('r05.a.a5.expenditureFromVNU')}{' '}
          <span className="font-italic">{formatCurrency(data?.totalExpenditure)}</span>
        </p>
        <p className="my-2">
          &emsp;&emsp;&emsp; + {t('r05.a.a5.expenditure1st')} ......... {t('r05.a.a5.followUp')}{' '}
          .....
          {t('r05.a.a5.day')}
        </p>
        <p className="my-2">
          &emsp;&emsp;&emsp; + {t('r05.a.a5.expenditure2nd')} ......... {t('r05.a.a5.followUp')}{' '}
          .....
          {t('r05.a.a5.day')}
        </p>
        <p className="my-2">
          &emsp;&emsp;- &ensp;{t('r05.a.a5.expenditureFromFunding')}{' '}
          <span className="font-italic">{formatCurrency(data?.expenditureFromFunding)}</span>
        </p>
      </section>
      {/* A6  */}
      <section>
        <h4>{t('r05.a.a6.title')}</h4>
        <p className="my-2">
          <span className="font-bold inline-block">{t('r05.a.a6.name')}</span>{' '}
          {projectOwner?.contact?.fullname}
        </p>
        <p className="my-2">
          <span className="font-bold inline-block">{t('r05.a.a6.birthDate')}</span>{' '}
          {new Date(projectOwner?.dob).toLocaleDateString('en-GB', { timeZone: TIMEZONE_VN })}
          <span className="font-bold inline-block ml-6">{t('r05.a.a6.sex')}</span>{' '}
          {projectOwner?.gender ? t(`gender.${projectOwner?.gender}`) : '.......'}
        </p>
        <p className="my-2">
          <span className="font-bold inline-block">{t('r05.a.a6.company')}</span>{' '}
          {projectOwner?.organization?.name}
        </p>
        <p className="my-2">
          <span className="inline-block font-bold">{t('r05.a.a6.phone')}</span>{' '}
          {projectOwner?.mobile}
          <span className="font-bold ml-6">{t('r05.a.a6.email')}</span>{' '}
          {projectOwner?.contact?.email}
        </p>
      </section>
      {/* A7  */}
      <section>
        <h4>{t('r05.a.a7.title')}</h4>
        <p className="my-2">
          <span className="font-bold">{t('r05.a.a7.companyName')}</span>{' '}
          {projectProposalOrganizations?.name}
        </p>
        <p className="my-2">
          <span className="font-bold">{t('r05.a.a7.leaderName')}</span>{' '}
          {projectProposalOrganizations?.chief}
        </p>
        <p className="my-2">
          <span className="font-bold">{t('r05.a.a7.phone')}</span>
          {projectProposalOrganizations?.phone} &emsp;&emsp; {t('r05.a.a7.fax')}
          {projectProposalOrganizations?.fax}
        </p>
        <p className="my-2">
          <span className="font-bold">{t('r05.a.a7.email')}</span>{' '}
          {projectProposalOrganizations?.email}
        </p>
      </section>
      {/* A8  */}
      <section>
        <h4>{t('r05.a.a8.title')}</h4>
        <div className="form-table">
          <table className="mb-2">
            <thead>
              <tr>
                <th className="text-center" style={{ minWidth: 50, width: '5%' }}>
                  {t('r05.a.a8.order')}
                </th>
                <th className="text-center" style={{ minWidth: 180, width: '20%' }}>
                  {t('r05.a.a8.fullName')}
                </th>
                <th className="text-center" style={{ minWidth: 160, width: '20%' }}>
                  {t('r05.a.a8.workingUnit')}
                </th>
                <th className="text-center" style={{ minWidth: 220, width: '20%' }}>
                  {t('r05.a.a8.contentWorking')}
                </th>
              </tr>
            </thead>
            <tbody>
              {partners?.length > 0 ? (
                partners.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.chief}</td>
                    <td>{item.name}</td>
                    <td>{removeHtmlTag(item?.partnerContribution)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center py-2">
                    {t('r05.a.a8.noPartner')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}
