import { useTranslation } from 'react-i18next';
// import { yupResolver } from '@hookform/resolvers/yup';
// import updateUserProfileValidationSchema from 'features/UserProfile/validation';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { updatePublicProfileId } from 'apis/scientificProfiles.api';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  value: yup
    .string()
    .required('Public Profile ID is required.')
    .matches(/^[a-zA-Z0-9_.-]*$/, 'Không chứa ký tự đặc biệt.'),
});

export default function CreatePublicProfileDialog({
  visible,
  setVisible,
  publicProfileId,
  scientificProfileId,
}) {
  // #region Data
  /* eslint-disable */
  const { t } = useTranslation();

  /* eslint-disable */

  const toast = useRef(null);

  const show = (response) => {
    if (response.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Đã chỉnh thay đổi Public Profile ID thành công. ',
        detail: 'Reload lại trang sau 2s.',
      });

      const currentUrl = window.location.href;
      setTimeout(() => {
        window.location.href =
          currentUrl.substring(0, currentUrl.lastIndexOf('/')) + '/' + getValues('value');
      }, 2000);
    }
  };

  const defaultValues = {
    value: publicProfileId,
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  //   const {
  //     data: profileExists,
  //     error,
  //     refetch,
  //     isFetching,
  //   } = useQuery(
  //     ['checkPublicProfileId', getValues('value')],
  //     () => checkExistPublicProfileId(getValues('value')),
  //     { enabled: loadData } // Disable the query by default
  //   );

  const onSubmit = async (data) => {
    if (data.value) {
      try {
        const response = await updatePublicProfileId(scientificProfileId, getValues('value'));

        // Reset the form
        show(response);
        //reset();
      } catch (error) {
        // Handle the error
        //console.error('Failed to update public profile ID:', error);
        toast.current.show({
          severity: 'error',
          summary: 'Đã tồn tại',
          detail: 'Vui lòng thử lại bằng Public Profile ID khác.',
        });
      }
    }
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <Dialog
      header={'Chỉnh sửa Public Profile ID'}
      style={{ maxWidth: '700px' }}
      draggable={false}
      visible={visible}
      onHide={() => {
        reset();
        setVisible(false);
      }}
    >
      <div className="card flex justify-content-center">
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <Toast ref={toast} />
          <Controller
            name="value"
            control={control}
            rules={{ required: 'Public Profile ID is required.' }}
            render={({ field, fieldState }) => (
              <>
                <label
                  htmlFor={field.name}
                  className={classNames({ 'p-error': errors.value })}
                ></label>
                <span className="p-float-label">
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={classNames({ 'p-invalid': fieldState.error })}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                  <label htmlFor={field.name}>Public Profile ID</label>
                </span>
                {getFormErrorMessage(field.name)}
              </>
            )}
          />
          <div className="flex flex-column sm:flex-row align-items-center sm:column-gap-3">
            <Button
              label={t('projectProposal.cancel')}
              type="button"
              className="mt-4 w-full sm:w-19rem"
              severity="danger"
              onClick={() => {
                reset();
                setVisible(false);
              }}
            />
            <Button
              label={t('userProfile.editProfile')}
              icon="pi pi-pencil"
              className="bg-cyan-400 border-cyan-400 hover:bg-cyan-500 mt-2 sm:mt-4 w-full sm:w-19rem"
              type="submit"
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
}

CreatePublicProfileDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  publicProfileId: PropTypes.string,
  scientificProfileId: PropTypes.string,
};

// defaultProps
CreatePublicProfileDialog.defaultProps = {
  publicProfileId: '',
  scientificProfileId: '',
};
