import { useTranslation } from 'react-i18next';
import AppMenuItem from './AppMenuitem';
import { MenuProvider } from './context/menucontext';

function AppMenu() {
  const { t } = useTranslation();
  const model = [
    {
      label: '',
      items: [
        {
          label: t('menu.home.label'),
          icon: 'pi pi-fw pi-server',
          to: '/',
        },
        {
          label: t('menu.features.items.projectProposalViewList'),
          icon: 'pi pi-fw pi-list',
          to: '/project-proposal',
        },
        {
          label: t('menu.features.items.topicViewList'),
          icon: 'pi pi-fw pi-list',
          to: '/project',
        },
        {
          label: t('menu.features.items.topicCompleted'),
          icon: 'pi pi-fw pi-list',
          to: '/project-completed',
        },
        {
          label: t('menu.features.items.projectProposalCouncil'),
          icon: 'pi pi-fw pi-list',
          to: '/project-proposal-defense',
        },
        {
          label: t('menu.features.items.pubProjectProposalViewList'),
          icon: 'pi pi-fw pi-list',
          to: '/pub-project-proposal',
        },
        {
          label: t('menu.features.items.scientificProfile'),
          icon: 'pi pi-fw pi-id-card',
          to: '/scientific-profile',
        },
        {
          label: t('menu.features.items.guideline'),
          icon: 'pi pi-fw pi-question-circle',
          to: '/guideline',
        },
        {
          label: t('menu.features.items.forms'),
          icon: 'pi pi-fw pi-file',
          to: '/forms',
        },
      ],
    },
  ];
  return (
    <MenuProvider>
      <ul className="layout-menu">
        {model.map((item, index) =>
          !item.seperator ? (
            <AppMenuItem key={item.label} item={item} root index={index} />
          ) : (
            <li className="menu-separator" />
          )
        )}
      </ul>
    </MenuProvider>
  );
}

export default AppMenu;
